import React from 'react';
//TableIcons
import singleTable from '../../../../../../assets/images/booking/tables/single-table.png';
import twoSingleTable from '../../../../../../assets/images/booking/tables/two-single-table.png';
import threeSingleTable from '../../../../../../assets/images/booking/tables/three-single-table.png';
import singlePairTable from '../../../../../../assets/images/booking/tables/single-pair-table.png';
import twoSinglePairTable from '../../../../../../assets/images/booking/tables/two-single-pair-table.png';
import threeSinglePairTable from '../../../../../../assets/images/booking/tables/three-single-pair-table.png';
import fourSinglePairTable from '../../../../../../assets/images/booking/tables/four-single-pair-table.png';
import fourPersonSingleTable from '../../../../../../assets/images/booking/tables/four-person-single-table.png';
import fourPersonRoundTable from '../../../../../../assets/images/booking/tables/four-person-round-table.png';
import sixPersonRoundTable from '../../../../../../assets/images/booking/tables/six-person-round-table.png';
import eightPersonRoundTable from '../../../../../../assets/images/booking/tables/eight-person-round-table.png';
import tenPersonRoundTable from '../../../../../../assets/images/booking/tables/ten-person-round-table.png';
import halfSeatRoundTable from '../../../../../../assets/images/booking/tables/half-seat-round-table.png';
import twoPersonRoundTable from '../../../../../../assets/images/booking/tables/two-person-round-table.png';

export const TableIcons = [
	{
		title: 'Single Table',
		icon: (
			<img
				src={`${singleTable}`}
				width={'auto'}
				height={'auto'}
				style={{ margin: 0, padding: 0 }}
			/>
		),

		name: '',
		table_type: 1,
		can_rotate: 1,
		capacity_min: 1,
		capacity_max: 1,
		floor_id: 0,
		rotate_deg: 0,
		pos_x: 0,
		pos_y: 0,
	},
	{
		title: 'Two Singe Table',
		icon: (
			<img
				src={`${twoSingleTable}`}
				width={'auto'}
				height={'auto'}
				style={{ margin: 0, padding: 0 }}
			/>
		),
		name: '',
		table_type: 2,
		can_rotate: 1,
		capacity_min: 1,
		capacity_max: 2,
		floor_id: 0,
		rotate_deg: 0,
		pos_x: 0,
		pos_y: 0,
	},
	{
		title: 'Three Single Table',
		icon: (
			<img
				src={`${threeSingleTable}`}
				width={'auto'}
				height={'auto'}
				style={{ margin: 0, padding: 0 }}
			/>
		),
		name: '',
		table_type: 3,
		can_rotate: 1,
		capacity_min: 1,
		capacity_max: 3,
		floor_id: 0,
		rotate_deg: 0,
		pos_x: 0,
		pos_y: 0,
	},
	{
		title: 'Single Pair Table',
		icon: (
			<img
				src={`${singlePairTable}`}
				width={'auto'}
				height={'auto'}
				style={{ margin: 0, padding: 0 }}
			/>
		),
		name: '',
		table_type: 4,
		can_rotate: 1,
		capacity_min: 1,
		capacity_max: 2,
		floor_id: 0,
		rotate_deg: 0,
		pos_x: 0,
		pos_y: 0,
	},
	{
		title: 'Two Single Pair Table',
		icon: (
			<img
				src={`${twoSinglePairTable}`}
				width={'auto'}
				height={'auto'}
				style={{ margin: 0, padding: 0 }}
			/>
		),
		name: '',
		table_type: 5,
		can_rotate: 1,
		capacity_min: 2,
		capacity_max: 4,
		floor_id: 0,
		rotate_deg: 0,
		pos_x: 0,
		pos_y: 0,
	},
	{
		title: 'Three Single Pair Table',
		icon: (
			<img
				src={`${threeSinglePairTable}`}
				width={'auto'}
				height={'auto'}
				style={{ margin: 0, padding: 0 }}
			/>
		),
		name: '',
		table_type: 6,
		can_rotate: 1,
		capacity_min: 3,
		capacity_max: 6,
		floor_id: 0,
		rotate_deg: 0,
		pos_x: 0,
		pos_y: 0,
	},
	{
		title: 'Four Single Pair Table',
		icon: (
			<img
				src={`${fourSinglePairTable}`}
				width={'auto'}
				height={'auto'}
				style={{ margin: 0, padding: 0 }}
			/>
		),
		name: '',
		table_type: 7,
		can_rotate: 1,
		capacity_min: 4,
		capacity_max: 8,
		floor_id: 0,
		rotate_deg: 0,
		pos_x: 0,
		pos_y: 0,
	},
	{
		title: 'Four Person Single Table',
		icon: (
			<img
				src={`${fourPersonSingleTable}`}
				width={'auto'}
				height={'auto'}
				style={{ margin: 0, padding: 0 }}
			/>
		),
		name: '',
		table_type: 8,
		can_rotate: 0,
		capacity_min: 2,
		capacity_max: 4,
		floor_id: 0,
		rotate_deg: 0,
		pos_x: 0,
		pos_y: 0,
	},
	{
		title: 'Four Person Round Table',
		icon: (
			<img
				src={`${fourPersonRoundTable}`}
				width={'auto'}
				height={'auto'}
				style={{ margin: 0, padding: 0 }}
			/>
		),
		name: '',
		table_type: 9,
		can_rotate: 0,
		capacity_min: 2,
		capacity_max: 4,
		floor_id: 0,
		rotate_deg: 0,
		pos_x: 0,
		pos_y: 0,
	},
	{
		title: 'Six Person Round Table',
		icon: (
			<img
				src={`${sixPersonRoundTable}`}
				width={'auto'}
				height={'auto'}
				style={{ margin: 0, padding: 0 }}
			/>
		),
		name: '',
		table_type: 10,
		can_rotate: 0,
		capacity_min: 3,
		capacity_max: 6,
		floor_id: 0,
		rotate_deg: 0,
		pos_x: 0,
		pos_y: 0,
	},
	{
		title: 'Eight Person Round Table',
		icon: (
			<img
				src={`${eightPersonRoundTable}`}
				width={'auto'}
				height={'auto'}
				style={{ margin: 0, padding: 0 }}
			/>
		),
		name: '',
		table_type: 11,
		can_rotate: 0,
		capacity_min: 4,
		capacity_max: 8,
		floor_id: 0,
		rotate_deg: 0,
		pos_x: 0,
		pos_y: 0,
	},
	{
		title: 'Ten Person Round Table',
		icon: (
			<img
				src={`${tenPersonRoundTable}`}
				width={'auto'}
				height={'auto'}
				style={{ margin: 0, padding: 0 }}
			/>
		),
		name: '',
		table_type: 12,
		can_rotate: 0,
		capacity_max: 10,
		capacity_min: 1,
		floor_id: 0,
		rotate_deg: 0,
		pos_x: 0,
		pos_y: 0,
	},
	{
		title: 'Half Seat Round Table',
		icon: (
			<img
				src={`${halfSeatRoundTable}`}
				width={'auto'}
				height={'auto'}
				style={{ margin: 0, padding: 0 }}
			/>
		),
		name: '',
		table_type: 13,
		can_rotate: 1,
		capacity_min: 4,
		capacity_max: 8,
		floor_id: 0,
		rotate_deg: 0,
		pos_x: 0,
		pos_y: 0,
	},
	{
		title: 'Two Person Round Table',
		icon: (
			<img
				src={`${twoPersonRoundTable}`}
				width={'auto'}
				height={'auto'}
				style={{ margin: 0, padding: 0 }}
			/>
		),
		name: '',
		table_type: 14,
		can_rotate: 1,
		capacity_min: 1,
		capacity_max: 2,
		floor_id: 0,
		rotate_deg: 0,
		pos_x: 0,
		pos_y: 0,
	},
];
