import React, { useState } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import axios from 'axios';

import {
	apiUrl,
	getRadWidth,
	getRadHeight,
	tableNamePositionLeft,
	tableNamePositionLeftTranslate,
	tableNamePositionMarginTop,
	getBookingOnTable,
	tableNamePositionMarginLeft,
	getLabelBookingColor,
	hasConflictBooking,
	getFullname,
	getTimeOnly,
	getBookingStatusColor,
	getBookingProgressStatusColo,
	getBookingTimeOnly,
	getBookingOnTableByIndex,
	getTableWidth,
	getTableHeight,
	getPositionTop,
	getPositionLeft,
	radToDeg,
	getTableImg,
} from 'utils/Utility';

import conflictIcon from 'assets/images/booking/conflict-icon.png';
import btnTimelineIcon from 'assets/images/booking/btn-timeline-icon.png';
import btnTimelineSelectedIcon from 'assets/images/booking/btn-timeline-selected-icon.png';

import FloorDropDown from '../FloorDropDown';

const MainContainer = styled.div`
	position: relative;
	display: inline-flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: white;
	height: 624px;
	width: 100%;
	overflow: hidden;
	user-select: none;
	font-size: 20px;
	background: repeating-linear-gradient(
		45deg,
		#f5c99d00,
		#f5c99d00 10px,
		#222a2f 10px,
		#3a444b 20px
	);
`;

function WaitList(props) {
	const [tableList, setTableList] = useState(props.tableList);
	const [bookingsList, setBookingsList] = useState(props.bookingsList);

	return (
		<Row style={{ marginRight: 0, marginLeft: 0 }}>
			<Col sm={4} md={4} lg={4}>
				<MainContainer>
					<h3> Component Under Construction</h3> &nbsp; Coming Soon
				</MainContainer>
			</Col>
			<Col sm={8} md={8} lg={8}>
				<div className="table-floor-container">
					<div className="table-floor-separator"></div>
					<div className="table-floor">
						{tableList.length > 0
							? tableList.map((table) => (
									<div
										key={Math.random()}
										className="table-container"
										style={{
											position: 'absolute',
											top: table.pos_y,
											left: table.pos_x,
											color: 'white',
											fontSize: 10,
										}}
									>
										<div
											// onClick={(e) => showTableDetailsHandler(table)}
											key={Math.random()}
											style={{
												position: 'relative',
												width: getRadWidth(table.rotate_deg, table.table_type),
												height: getRadHeight(table.rotate_deg, table.table_type),
											}}
										>
											<span
												key={Math.random()}
												style={{
													position: 'absolute',
													zIndex: 1,
													left: tableNamePositionLeft(table.table_type),
													transform: `translateX(${tableNamePositionLeftTranslate(
														table.table_type,
													)})`,
													marginLeft: tableNamePositionMarginLeft(
														table.table_type,
														table.rotate_deg,
													),
													marginTop: tableNamePositionMarginTop(table.table_type, table.rotate_deg),
												}}
											>
												{table.name}
											</span>
											{hasConflictBooking(
												null,
												tableList,
												getBookingOnTable(table.id, bookingsList),
											) ? (
												<img
													src={conflictIcon}
													width="18"
													height="18"
													className="d-inline"
													alt="checker"
													style={{
														position: 'absolute',
														marginLeft: tableNamePositionMarginLeft(
															table.table_type,
															table.rotate_deg,
														),
														left: tableNamePositionLeft(table.table_type),
														transform: `translateX(${tableNamePositionLeftTranslate(
															table.table_type,
														)})`,
														marginTop:
															tableNamePositionMarginTop(table.table_type, table.rotate_deg) + 15,
														zIndex: 1,
													}}
												/>
											) : (
												''
											)}

											{!props.showTimeline ? (
												getBookingOnTable(table.id, bookingsList) == null ? (
													''
												) : (
													<div
														key={Math.random()}
														style={{
															position: 'absolute',
															zIndex: 1,
															left: tableNamePositionLeft(table.table_type),
															transform: `translateX(${tableNamePositionLeftTranslate(
																table.table_type,
															)})`,
															marginLeft: tableNamePositionMarginLeft(
																table.table_type,
																table.rotate_deg,
															),
															bottom:
																tableNamePositionMarginTop(table.table_type, table.rotate_deg) + 25,
															maxWidth: 'min-content',
															color: getLabelBookingColor(
																getBookingOnTable(table.id, bookingsList) === null
																	? null
																	: getBookingOnTable(table.id, bookingsList).status,
															),
														}}
													>
														{getFullname(
															getBookingOnTable(table.id, bookingsList) === null
																? null
																: getBookingOnTable(table.id, bookingsList).guest,
														)}
													</div>
												)
											) : (
												''
											)}

											{!props.showTimeline ? (
												getBookingOnTable(table.id, bookingsList) == null ? (
													''
												) : (
													<div
														key={Math.random()}
														style={{
															position: 'absolute',
															zIndex: 1,
															left: tableNamePositionLeft(table.table_type),
															transform: `translateX(${tableNamePositionLeftTranslate(
																table.table_type,
															)})`,
															marginLeft: tableNamePositionMarginLeft(
																table.table_type,
																table.rotate_deg,
															),
															bottom:
																tableNamePositionMarginTop(table.table_type, table.rotate_deg) + 10,
															color: getLabelBookingColor(
																getBookingOnTable(table.id, bookingsList) === null
																	? null
																	: getBookingOnTable(table.id, bookingsList).status,
															),
														}}
													>
														{getTimeOnly(getBookingOnTable(table.id, bookingsList).start_date)}
													</div>
												)
											) : (
												''
											)}
											{!props.showTimeline ? (
												getBookingOnTable(table.id, bookingsList) == null ? (
													''
												) : (
													<div
														key={Math.random()}
														style={{
															position: 'absolute',
															zIndex: 1,
															width: '100%',
															left: 0, //tableNamePositionLeft(table.table_type),
															// transform: `translateX(${ tableNamePositionLeftTranslate(table.table_type) })`,
															marginLeft: 0, //tableNamePositionMarginLeft(table.table_type, table.rotate_deg) - 5,
															bottom: 2,
															height: 6,
															background: getBookingStatusColor(
																getBookingOnTable(table.id, bookingsList) === null
																	? null
																	: getBookingOnTable(table.id, bookingsList).status,
															),
															borderRadius: 3,
														}}
													>
														<div
															key={Math.random()}
															style={{
																background: getBookingProgressStatusColo(
																	getBookingOnTable(table.id, bookingsList) === null
																		? null
																		: getBookingOnTable(table.id, bookingsList).status,
																),
																width: '45%',
																height: 6,
																borderRadius: 3,
															}}
														></div>
													</div>
												)
											) : (
												''
											)}
											{props.showTimeline ? (
												<div
													key={Math.random()}
													style={{
														position: 'absolute',
														zIndex: 2,
														height: '100%',
														width: 60,
														top: '50%',
														left: '50%',
														transform: `translate(-50%, -23%)`,
													}}
												>
													<div
														key={Math.random()}
														style={{
															background: 'rgba(0, 0, 0, 0.6)',
															height: 15,
															marginBottom: 5,
															borderRadius: 2,
															textAlign: 'center',
														}}
													>
														{getBookingTimeOnly(
															getBookingOnTableByIndex(table.id, bookingsList, 0),
														) ?? ''}
													</div>
													<div
														key={Math.random()}
														style={{
															background: 'rgba(0, 0, 0, 0.6)',
															height: 15,
															marginBottom: 5,
															borderRadius: 2,
															textAlign: 'center',
														}}
													>
														{getBookingTimeOnly(
															getBookingOnTableByIndex(table.id, bookingsList, 1),
														) ?? ''}
													</div>
													<div
														key={Math.random()}
														style={{
															background: 'rgba(0, 0, 0, 0.6)',
															height: 15,
															marginBottom: 5,
															borderRadius: 2,
															textAlign: 'center',
														}}
													>
														{getBookingTimeOnly(
															getBookingOnTableByIndex(table.id, bookingsList, 2),
														) ?? ''}
													</div>
												</div>
											) : (
												''
											)}
											<div
												key={Math.random()}
												style={{
													width: getTableWidth(table.table_type),
													height: getTableHeight(table.table_type),
													transformOrigin: 'top left',
													position: 'absolute',
													top: getPositionTop(table.rotate_deg, table.table_type),
													left: getPositionLeft(table.rotate_deg, table.table_type),
													transform: `rotate(${radToDeg(table.rotate_deg)}deg)`,
												}}
											>
												<img
													draggable="false"
													key={Math.random()}
													src={getTableImg(table.table_type)}
													width={`${getTableWidth(table.table_type)}`}
													height={`${getTableHeight(table.table_type)}`}
													className={
														props.selectedTableId.length >= 1 &&
														props.selectedTableId.includes(table.id)
															? 'table-floor table-container img-filter'
															: 'table-floor table-container'
													}
													alt="checker"
												/>
											</div>
										</div>
									</div>
							  ))
							: ''}
					</div>
					<div className="table-floor-bottom">
						{props.selectedFloor !== null ? (
							<FloorDropDown
								selectedFloor={props.selectedFloor}
								floors={props.floorList}
								selectFloorCallBack={props.selectFloorCallBack}
							></FloorDropDown>
						) : (
							''
						)}
						<div
							className="btn-timeline"
							onClick={(e) => props.setShowTimeline(!props.showTimeline)}
						>
							<img
								src={!props.showTimeline ? btnTimelineIcon : btnTimelineSelectedIcon}
								width="25"
								height="25"
								className="d-inline"
								alt="checker"
							/>
						</div>
					</div>
				</div>
			</Col>
		</Row>
	);
}

export default WaitList;
