import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import moment from 'moment';
import scheduleImg from 'assets/images/booking/user-roles/schedule-img.png';
import CreateEmployeeSchedule from './CreateEmployeeScheduleDate';
import RosterItem from './RosterItem/RosterItem';
import DotLoader from 'pages/Dashboard/Booking/TableLayoutPlanning/pages/SubComponents/DotLoader';

const useStyles = makeStyles((theme) => ({
	paper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: 170,
		background: '#f7f7f7',
		color: '#818181',
		cursor: 'pointer',
		userSelect: 'none',
		fontSize: 16,
		fontWeight: 700,
		boxShadow: '3px 3px 10px #1c1c1e4d',
		borderRadius: 10,
	},
}));

const Wrapper = styled.div`
	position: absolute;
	height: 100%;
	width: 100%;
	background: #f7f7f7;
	right: ${(props) => props.right};
	top: 0;
	transition: ease 200ms;

	img {
		-webkit-user-drag: none;
		-khtml-user-drag: none;
		-moz-user-drag: none;
		-o-user-drag: none;
		user-drag: none;
	}
`;

const AddButton = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background: white;
	width: 230px;
	height: 45px;
	color: #818181;
	font-size: 14px;
	font-weight: 700;
	padding: 5px 25px;
	border-radius: 2em;
	box-shadow: 0px 1px 10px #1c1c1e4d;
	margin: 15px 15px 15px 0;
	white-space: nowrap;
	user-select: none;
	cursor: pointer;
	z-index: 1;

	&:active {
		background: #f2f2f2;
	}
`;

const BackButton = styled(AddButton)`
	position: absolute;
	left: 15px;
	font-size: 12px;
`;

const CurrentButton = styled(AddButton)`
	background: #f98080;
	color: #e8dfdb;

	&:active {
		background: #f97777;
	}
`;

const DateContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	color: #818181;
	font-size: 16px;
	font-weight: 700;
	margin-bottom: 10px;
`;

const TextContainer = styled.div`
	position: absolute;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	color: #818181;
	font-size: 14px;
	font-weight: 700;
	user-select: none;
`;

function Schedule(props) {
	const classes = useStyles();
	const [showDeleted, setshowDeleted] = useState(false);
	const [showCreateScheduleModal, setShowCreateScheduleModal] = useState(false);
	const [openScheduleDate, setOpenScheduleDate] = useState(false);
	const [selectedScheduleDate, setSelectedScheduleDate] = useState([]);

	const goBack = () => {
		props.selectGroup();
	};

	const toggleShowDeleted = () => {
		if (!showDeleted) {
			setshowDeleted(!showDeleted);
			props.getDeletedSchedule(1);
		} else {
			setshowDeleted(!showDeleted);
		}
	};

	const toggleCreateScheduleModal = () => {
		setShowCreateScheduleModal(!showCreateScheduleModal);
	};

	const newEmployeeScheduleCallback = (data) => {
		let params = {
			no_of_weeks: data.no_of_weeks,
			employee_group_schedule_id: props.selectedGroup.id,
			start_date: data.date,
		};
		console.log(params);
		props.createScheduleDate(params);
	};

	const selectScheduleDate = (data) => {
		let params = {
			employee_schedule_date_id: data.id,
		};
		setSelectedScheduleDate(data);
		props.getEmployeeSchedule(params);
		setOpenScheduleDate(!openScheduleDate);
	};

	return (
		<Wrapper right={props.isGroupSelected ? '0' : '-100%'}>
			<div
				style={{
					position: 'relative',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-end',
					marginBottom: 10,
				}}
			>
				<BackButton onClick={goBack}>Back to Group</BackButton>

				{showDeleted ? (
					<CurrentButton onClick={toggleShowDeleted}>Show current schedules</CurrentButton>
				) : (
					<AddButton onClick={toggleShowDeleted}>Show deleted schedules</AddButton>
				)}

				<AddButton onClick={toggleCreateScheduleModal}>Create Schedule</AddButton>
			</div>
			{props.isUpdating ? <DotLoader /> : ''}

			<Grid container spacing={2} style={{ padding: '0 15px' }}>
				{props.groupScheduleDate && props.groupScheduleDate.length > 0 ? (
					props.groupScheduleDate.map((e, key) => {
						return (
							<Grid
								item
								xs={3}
								key={key}
								style={{ cursor: 'pointer' }}
								onClick={() => selectScheduleDate(e)}
							>
								<DateContainer>
									{moment(e.start_date).format('MMM DD YYYY')} -{' '}
									{moment(e.end_date).format('MMM DD YYYY')}
								</DateContainer>
								<div className={classes.paper}>
									<img src={scheduleImg} />
								</div>
							</Grid>
						);
					})
				) : (
					<TextContainer>No available rooster group</TextContainer>
					// <div></div>
				)}
			</Grid>
			<CreateEmployeeSchedule
				showCreateScheduleModal={showCreateScheduleModal}
				setShowCreateScheduleModal={setShowCreateScheduleModal}
				newEmployeeScheduleCallback={newEmployeeScheduleCallback}
			/>
			<RosterItem
				isUpdating={props.isUpdating}
				stations={props.stations}
				employees={props.employees}
				openScheduleDate={openScheduleDate}
				selectedScheduleDate={selectedScheduleDate}
				employeeShifts={props.employeeShifts}
				setOpenScheduleDate={setOpenScheduleDate}
				groupSchedule={props.groupSchedule}
				employmentRoles={props.employmentRoles}
				createStations={props.createStations}
				createEmployeeSchedule={props.createEmployeeSchedule}
			/>
		</Wrapper>
	);
}

export default Schedule;
