import React, { useState, useEffect, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
// import { DndProvider } from 'react-dnd';
// import { HTML5Backend } from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';
import { apiUrl } from 'utils/Utility';
import { createTableParameters, createFloorParameters } from 'utils/RequestBuilder';
import axios from 'axios';

//Utilities

import {
	tableNamePositionLeft,
	tableNamePositionLeftTranslate,
	tableNamePositionMarginLeft,
	tableNamePositionMarginTop,
	getTableImg,
} from '../../../../../utils/Utility';

//Components
import DragNdrop from './SubComponents/DragNdrop';
import DragCloneItem from './SubComponents/DragCloneItem';
import { TableIcons } from './SubComponents/TableIconData';
import FloorPlanDropDown from './SubComponents/FloorPlanDropDown';
import TableCapacityPicker from './SubComponents/TableCapacityPicker';
import FloorPlanToggleSwitch from './SubComponents/FloorPlanToggleSwitch';
import Spinner from 'react-bootstrap';

//Icon
import TLPButton from './SubComponents/TLPButton';
import addIcon from '../../../../../assets/images/booking/create-booking/icon-add-white.png';
import rotateIcon from '../../../../../assets/images/booking/table-layout-planning/rotate-icon.png';
import deleteIcon from '../../../../../assets/images/booking/table-layout-planning/delete-icon.png';
import DeleteSectionModal from './SubComponents/DeleteSectionModal';
import AddSectionModal from './SubComponents/AddSectionModal';
import ModalSpinner from './SubComponents/ModalSpinner';

function TableFloorPlan(props) {
	const reducers = useSelector((state) => state.userReducers);
	const [isLoading, setIsLoading] = useState(false);
	const [monitor, setmonitor] = useState(false);
	const [floors, setFloors] = useState([]);
	const [floorList, setfloorList] = useState(props.floors);
	const [selectedFloor, setselectedFloor] = useState(floorList[0]);
	const [tableList, settableList] = useState(floorList.length === 0 ? [] : selectedFloor.tables);
	const [selectedTableType, setselectedTableType] = useState(0);
	const [currentTable, setcurrentTable] = useState(tableList[0]);
	const [tableName, settableName] = useState(currentTable !== undefined ? currentTable.name : '');
	const [selectedTableId, setselectedTableId] = useState(-1);
	const [isTyping, setisTyping] = useState(false);
	const [openCapacityPicker, setopenCapacityPicker] = useState(false);
	const [openDelFloorPrompt, setopenDelFloorPrompt] = useState(false);
	const [openAddSection, setopenAddSection] = useState(false);
	const [nonRestoggleSwitch, setnonRestoggleSwitch] = useState(false);
	const [nonResStartDate, setnonResStartDate] = useState('');
	const [nonResEndDate, setnonResEndDate] = useState('');
	const tableListState = useRef();
	tableListState.current = tableList;
	const selectedFloorState = useRef();
	selectedFloorState.current = selectedFloor;
	const floorListState = useRef();
	floorListState.current = floorList;

	const selectFloorCallBack = (floor) => {
		settableList(floor.tables);
		setselectedFloor(floor);
		if (floor.tables.length !== 0) {
			setcurrentTable(floor.tables[0]);
			settableName(floor.tables[0].name);
		}
	};

	const selectedTableIdCallBack = (id) => {
		if (selectedTableId === -1) {
			setselectedTableId(id);
			if (tableListState.current.length > 0) {
				if (Number.isInteger(id)) {
					const idx = tableListState.current.findIndex((e) => e.id === id);
					let table = tableListState.current[idx];
					setcurrentTable(table);
					settableName(table.name);
				} else {
					const idx = tableListState.current.findIndex((e) => e.uuid === id);
					let table = tableListState.current[idx];
					setcurrentTable(table);
					settableName(table.name);
				}
			}
		} else {
			if (selectedTableId === id) {
				setselectedTableId(-1);
			} else {
				if (tableListState.current.length > 0) {
					if (Number.isInteger(id)) {
						const idx = tableListState.current.findIndex((e) => e.id === id);
						let table = tableListState.current[idx];
						setcurrentTable(table);
						settableName(table.name);
						setselectedTableId(id);
					} else {
						const idx = tableListState.current.findIndex((e) => e.uuid === id);
						let table = tableListState.current[idx];
						setcurrentTable(table);
						settableName(table.name);
						setselectedTableId(id);
					}
				}
			}
		}
	};

	const selectedTableTypeCallBack = (id) => {
		if (selectedTableType === 0) {
			setselectedTableType(id);
		} else {
			if (selectedTableType === id) {
				setselectedTableType(0);
			} else {
				setselectedTableType(id);
			}
		}
	};

	const handleAddTable = (id) => {
		let current = TableIcons.find((item) => id === item.table_type);
		let list = tableListState.current;
		if (floorListState.current.length > 0) {
			if (id !== 0) {
				let newTable = {
					id: 0,
					uuid: Math.random().toString(36).slice(2),
					can_rotate: current.can_rotate,
					name: current.name,
					capacity_max: current.capacity_max,
					capacity_min: current.capacity_min,
					floor_id: selectedFloorState.current.id,
					pos_x: current.pos_x,
					pos_y: current.pos_y,
					rotate_deg: current.rotate_deg,
					table_type: current.table_type,
					widget_is_non_reservable: 0,
				};
				list.push(newTable);
				settableList(list);
				updateFloorlist(list);
				setselectedTableType(0);
				selectedTableIdCallBack(newTable.uuid);
				setmonitor(!monitor);
			}
		} else {
			return;
		}
	};

	const handleRotate = () => {
		if (tableListState.current.length > 0) {
			let idx = 0;
			if (currentTable.uuid === undefined) {
				idx = tableListState.current.findIndex((e) => e.id === currentTable.id);
			} else {
				idx = tableListState.current.findIndex((e) => e.uuid === currentTable.uuid);
			}
			let list = tableListState.current;
			let val = 1.5699999999999998;
			let table = list[idx];
			if (table.can_rotate > 0) {
				let deg = table.rotate_deg;
				if (deg === 0) {
					table = { ...list[idx], rotate_deg: val };
				} else {
					if (deg === val) {
						table = { ...list[idx], rotate_deg: val * 2 };
					}
					if (deg === val * 2) {
						table = { ...list[idx], rotate_deg: val * 3 };
					}

					if (deg === val * 3) {
						table = { ...list[idx], rotate_deg: 0 };
					}
				}
				list.splice(idx, 1, table);
				setcurrentTable(table);
				settableList(list);
				updateFloorlist(list);
			} else {
				return;
			}
		} else {
			return;
		}

		setmonitor(!monitor);
	};

	const handleDeleteSection = (data) => {
		if (data !== null) {
			let list = floorListState.current.filter((e) => e.id !== data.id);
			if (list.length > 0) {
				setfloorList(list);
				selectFloorCallBack(list[0]);
			} else {
				setFloors(list);
				setfloorList(list);
				settableList([]);
				setselectedFloor('');
				settableName('');
			}
		}
		setopenDelFloorPrompt(false);
		setmonitor(!monitor);
	};

	const handleChangeTableName = (e) => {
		settableName(e);
	};

	const handleIsTyping = () => {
		if (isTyping) {
			setisTyping(false);
			if (tableListState.current.length !== 0) {
				if (currentTable.uuid === undefined) {
					const idx = tableList.findIndex((table) => table.id === currentTable.id);
					let list = tableList;
					let table = { ...list[idx], name: tableName };
					let newList = [...list];
					newList.splice(idx, 1, table);
					settableList(newList);
					updateFloorlist(newList);
				} else {
					const idx = tableList.findIndex((table) => table.uuid === currentTable.uuid);
					let list = tableList;
					let table = { ...list[idx], name: tableName };
					let newList = [...list];
					newList.splice(idx, 1, table);
					settableList(newList);
					updateFloorlist(newList);
				}
				setmonitor(!monitor);
			} else {
				return;
			}
		} else {
			setisTyping(true);
		}
	};

	const handleDuplicateTable = () => {
		let list = tableListState.current;
		if (tableListState.current.length > 0) {
			if (selectedTableId !== -1) {
				let newTable = {
					id: 0,
					uuid: Math.random().toString(36).slice(2),
					can_rotate: currentTable.can_rotate,
					name: currentTable.name + '  (COPY)',
					capacity_max: currentTable.capacity_max,
					capacity_min: currentTable.capacity_min,
					floor_id: selectedFloorState.current.id,
					pos_x: currentTable.pos_x / 2,
					pos_y: currentTable.pos_y / 2,
					rotate_deg: currentTable.rotate_deg,
					table_type: currentTable.table_type,
					widget_is_non_reservable: currentTable.widget_is_non_reservable,
				};
				list.push(newTable);
				settableList(list);
				updateFloorlist(list);
				setselectedTableType(0);
				selectedTableIdCallBack(newTable.uuid);
				setmonitor(!monitor);
			} else {
				alert('Please select a table');
			}
		} else {
			return;
		}
	};

	const handleDeleteTable = () => {
		let list = [];
		if (tableListState.current.length > 0) {
			if (Number.isInteger(selectedTableId)) {
				list = tableListState.current.filter((item) => selectedTableId !== item.id);
			} else {
				list = tableListState.current.filter((item) => selectedTableId !== item.uuid);
			}
			if (tableListState.current.length > 1) {
				settableList(list);
				updateFloorlist(list);
				setcurrentTable(list[0]);
				settableName(list[0].name);
			} else {
				settableList(list);
				updateFloorlist(list);
				setcurrentTable('');
				console.log('yes!');
			}

			setmonitor(!monitor);
		} else {
			return;
		}
	};

	const handleTableCapacityPicker = () => {
		if (tableListState.current.length > 0) {
			if (currentTable === null) {
				return;
			} else {
				if (openCapacityPicker) {
					setopenCapacityPicker(!openCapacityPicker);
				} else {
					setopenCapacityPicker(!openCapacityPicker);
				}
			}
		} else {
			return;
		}
	};

	const tableCapacityUpdate = (capacity) => {
		let idx = 0;
		if (currentTable.uuid === undefined) {
			idx = tableListState.current.findIndex((e) => e.id === currentTable.id);
		} else {
			idx = tableListState.current.findIndex((e) => e.uuid === currentTable.uuid);
		}
		let list = tableListState.current;
		let table = {
			...list[idx],
			capacity_max: parseInt(capacity.max),
			capacity_min: parseInt(capacity.min),
		};

		list.splice(idx, 1, table);
		settableList(list);
		setcurrentTable(table);
		updateFloorlist(list);
		setmonitor(!monitor);
	};

	const handleAddSection = (name) => {
		if (name.length > 0) {
			let list = floorListState.current;
			let newSection = {
				id: 0,
				uuid: Math.random().toString(36).slice(2),
				floor_name: name,
				combined_tables: [],
				tables: [],
			};
			list.push(newSection);
			const idx = floorListState.current.findIndex((e) => e.uuid === newSection.uuid);
			let floor = floorListState.current[idx];
			setfloorList(list);
			setFloors(list);
			selectFloorCallBack(floor);
			setmonitor(!monitor);
		}
		setopenAddSection(false);
	};

	const handleOpenAddsection = () => {
		setopenAddSection(true);
	};

	const handleMoveTable = (list) => {
		updateFloorlist(list);
	};

	const updateFloorlist = (value) => {
		let f_idx = 0;
		let t_idx = 0;
		let floors = floorListState.current;
		let floor = selectedFloorState.current;
		if (selectedFloorState.current.uuid !== undefined) {
			f_idx = floorListState.current.findIndex((e) => e.uuid === selectedFloorState.current.uuid);
		} else {
			f_idx = floorListState.current.findIndex((e) => e.id === selectedFloorState.current.id);
		}

		if (currentTable !== undefined) {
			if (currentTable.uuid !== undefined) {
				t_idx = tableList.findIndex((e) => e.uuid === currentTable.uuid);
			} else {
				t_idx = tableList.findIndex((e) => e.id === currentTable.id);
			}
		}

		floor = { ...floor, tables: value };
		floors.splice(f_idx, 1, floor);
		setfloorList(floors);
		setFloors(floors);
	};

	const updateTables = async (floor) => {
		try {
			const response = await axios({
				url: `${apiUrl}/api/set_tables`,
				method: 'POST',
				data: floor,
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${reducers.token}`,
				},
			});

			if (response) {
				const { status } = response;
				const { data } = response.data;
				if (status === 200) {
					console.log(data);
					setIsLoading(false);
				}
			}
		} catch (err) {
			console.log('Error: ', err);
		}
	};

	const updateFloors = async (floors) => {
		if (!props.isSaving) {
			return;
		}

		try {
			const response = await axios({
				url: `${apiUrl}/api/set_floors`,
				method: 'POST',
				data: floors,
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${reducers.token}`,
				},
			});

			if (response) {
				const { status } = response;
				const { data } = response.data;
				const { floors } = data;
				if (status === 200) {
					getFloor();
				}
			}
		} catch (err) {
			console.log('Error: ', err);
		}
	};

	const getFloor = async () => {
		try {
			const response = await axios({
				url: `${apiUrl}/api/get_floors`,
				method: 'POST',
				data: {},
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${reducers.token}`,
				},
			});

			if (response) {
				const { status } = response;
				const { data } = response.data;
				const { floors } = data;
				if (status === 200) {
					setfloorList(floors);
					props.setfloors(floors);
					handleSubmit();
				}
			}
		} catch (err) {
			console.log('Error: ', err);
		}
	};

	const handleSubmit = () => {
		if (floors.length === 0) {
			setIsLoading(false);
			return;
		} else {
			let length = floors.length;
			for (let idx = 0; idx <= length - 1; idx++) {
				console.log(floors);
				let _floors = floors;
				let current = floorListState.current.find((e) => e.floor_name === _floors[idx].floor_name);
				let id = current.id;
				let tables = _floors[idx].tables;
				let params = createTableParameters(id, tables);
				updateTables(params);
				if (idx === length - 1) {
					props.setisSaving(false);
				}
			}
		}
	};

	useEffect(() => {
		if (props.isSaving) {
			let floors = floorListState.current;
			let _params = createFloorParameters(floors);
			updateFloors(_params);
			setIsLoading(true);
		}
	}, [props.isSaving]);

	// useEffect(() => {
	// 	if (!isInit) {
	// 		setIsInit(true);
	// 	}
	// }, [isInit]);

	return (
		<Row className="tfp-container">
			{/* <DndProvider backend={HTML5Backend}> */}
			<Col sm={1} md={1} lg={1} style={{ margin: '0 0 0 5px', padding: 0 }}>
				<div className="tfp-table-container" id="board-1">
					{TableIcons.map((item) => {
						return (
							// <div className="tfp-card-item">{item.icon}</div>
							<DragCloneItem
								selectedTableTypeCallBack={selectedTableTypeCallBack}
								key={item.table_type}
								id={item.table_type}
								className="tfp-card-item"
								draggable="true"
								style={{
									position: 'relative',
									margin: '10px',
									fontSize: 10,
								}}
							>
								<span
									key={Math.random()}
									style={{
										position: 'absolute',
										zIndex: 1,
										left: tableNamePositionLeft(0),
										transform: `translateX(${tableNamePositionLeftTranslate(item.table_type)})`,
										marginLeft: tableNamePositionMarginLeft(item.table_type, 0),
										marginTop: tableNamePositionMarginTop(item.table_type, 0),
									}}
								>
									{item.name}
								</span>
								<div>
									<img
										src={getTableImg(item.table_type)}
										width="auto"
										height="auto"
										className={selectedTableType === item.table_type ? 'img-filter' : ''}
										alt="checker"
									/>
								</div>
							</DragCloneItem>
						);
					})}
				</div>
			</Col>
			<Col sm={7} md={7} lg={7} style={{ margin: 0, padding: 0, width: 'fit-content' }}>
				<DragNdrop
					stateRef={tableListState}
					tableList={tableList}
					settableList={settableList}
					selectedTableIdCallBack={selectedTableIdCallBack}
					handleAddTable={handleAddTable}
					tableListState={tableListState}
					handleMoveTable={handleMoveTable}
					// selectedId={selectedId}
					monitor={monitor}
					id="board-2"
					className="tfp-grid"
				/>
				<div className="tfp-footer">
					<TLPButton
						height={'40px'}
						width={'40px'}
						margin={'0 7px 0 0'}
						icon={addIcon}
						onClick={() => handleAddTable(selectedTableType)}
					/>
					<TLPButton
						height={'40px'}
						width={'40px'}
						margin={'0 7px 0 0'}
						icon={rotateIcon}
						onClick={() => handleRotate()}
					/>
					<TLPButton
						height={'40px'}
						width={'40px'}
						margin={'0 7px 0 0'}
						icon={deleteIcon}
						onClick={() => setopenDelFloorPrompt(true)}
						style={{ position: 'absolute', right: '260px' }}
					/>
					{/* data={selectedFloor !== null ? selectedFloor.name : null} */}
					<DeleteSectionModal
						data={selectedFloor !== undefined ? selectedFloor : ''}
						openDelFloorPrompt={openDelFloorPrompt}
						handleDeleteSection={handleDeleteSection}
					/>
					<span style={{ position: 'absolute', right: 0 }}>
						<AddSectionModal openAddSection={openAddSection} handleAddSection={handleAddSection} />
					</span>
					<FloorPlanDropDown
						selectedFloor={selectedFloor}
						floors={floorListState.current}
						selectFloorCallBack={selectFloorCallBack}
						handleOpenAddsection={handleOpenAddsection}
					/>
				</div>
			</Col>
			{/* </DndProvider> */}

			<Col sm={3} md={3} lg={3} style={{ margin: '0', padding: '0' }}>
				<div className="tfp-table-properties">
					<h4>Table Properties</h4>
					<div className="line-separator" />
					<span>
						{!isTyping ? <label htmlFor={'table-name'}>Table Name</label> : ''}
						<input
							type="Text"
							onFocus={handleIsTyping}
							onBlur={handleIsTyping}
							onChange={(e) => handleChangeTableName(e.target.value)}
							value={tableListState.current.length === 0 ? '' : tableName}
							// placeholder={!isTyping ? 'Add new table' : ''}
						/>
					</span>
					<span>
						<label>Capacity</label>
						{tableList.length > 0 ? (
							<button onClick={handleTableCapacityPicker}>
								{currentTable !== undefined ? currentTable.capacity_min : ''} - &nbsp;
								{currentTable !== undefined ? currentTable.capacity_max : ''} guests
							</button>
						) : (
							<button
								onClick={handleTableCapacityPicker}
								style={currentTable === undefined ? { color: '#707070' } : {}}
							>
								Add new table
							</button>
						)}

						<TableCapacityPicker
							currentTable={currentTable}
							openCapacityPicker={openCapacityPicker}
							tableCapacityCallback={tableCapacityUpdate}
							handleTableCapacityPicker={handleTableCapacityPicker}
						/>
					</span>

					<br />
					<TLPButton
						height={'50px'}
						minWidth={'211px'}
						margin={'15px 0'}
						label={'Duplicate'}
						onClick={() => handleDuplicateTable()}
					/>
					<TLPButton
						height={'50px'}
						minWidth={'211px'}
						margin={'15px 0'}
						label={'Delete'}
						onClick={() => handleDeleteTable()}
					/>
					<h4>Widget Option</h4>
					<div className="line-separator" />
					<p>
						Widget option will only be applicable on the widget, if you set it as 'Non-Reservable',
						this table will not be available on the widget option.
					</p>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}
					>
						<h4 style={{ color: '#707070' }}>Non-Reservable</h4>

						<div>
							<FloorPlanToggleSwitch
								onChange={(event) => setnonRestoggleSwitch(event.target.checked)}
								checked={nonRestoggleSwitch}
							/>
						</div>
					</div>
					{nonRestoggleSwitch ? (
						<div className="non-res-footer">
							<label>Start Date</label>
							<input
								type="Text"
								onChange={(e) => setnonResStartDate(e.target.value)}
								value={nonResStartDate}
								placeholder="yyyy-MM-dd hh:mm a"
							/>
							<br />
							<label>End Date</label>
							<input
								type="Text"
								onChange={(e) => setnonResEndDate(e.target.value)}
								value={nonResEndDate}
								placeholder="yyyy-MM-dd hh:mm a"
							/>
						</div>
					) : (
						''
					)}
				</div>
				<ModalSpinner isLoading={isLoading} />
			</Col>
		</Row>
	);
}

export default TableFloorPlan;
