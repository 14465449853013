import React, { useState, useEffect } from 'react';
import { formatDate } from 'react-day-picker/moment';
import moment from 'moment';

import { filterShifts, getAllTime } from 'utils/Utility';

import CreateBooking from '../CreatBooking/Createbooking';

function MakeBooking(props) {
	const reducers = props.reducers;
	const history = props.history.location.state;
	const [selectedShift, setSelectedShift] = useState(history.find((e) => e.selectedShift));
	const [floors, setFloors] = useState(history.find((e) => e.floors));
	const [shifts, setShifts] = useState(history.find((e) => e.shifts));
	const [employeesData, setEmployeesData] = useState(history.find((e) => e.employeesData));
	const [experienceList, setExperienceList] = useState(history.find((e) => e.experienceList));
	const [selectedDate, setSelectedDate] = useState(formatDate(moment(), 'YYYY-MM-DD'));
	const [timeList, setTimeList] = useState([]);
	const [experienceShiftId, setExperienceShiftId] = useState(0);

	const selectedBookingDate = (date) => {
		setSelectedDate(moment(date).format('yyyy-MM-DD'));
		const filteredShiftsData = filterShifts(shifts.shifts, moment(date).isoWeekday());
		if (experienceShiftId == 0) {
			setTimeList(getAllTime(filteredShiftsData, moment(date).format('yyyy-MM-DD')));
		} else {
			setTimeList(
				getAllTime(
					filteredShiftsData.find((e) => e.id == experienceShiftId),
					moment(date).format('yyyy-MM-DD'),
				),
			);
		}
	};

	const handleCloseCreateBooking = () => {
		props.makeNewBookingHandler();
	};

	const createBookingSuccess = () => {
		alert(JSON.stringify('Booking Created!'));
	};

	useEffect(() => {
		if (shifts.shifts.length > 0) {
			selectedBookingDate(selectedDate);
		}
	}, []);

	return (
		<CreateBooking
			guest={props.guest}
			selectedShift={selectedShift.selectedShift}
			employeesData={employeesData.employeesData}
			timeList={timeList}
			reducers={reducers}
			floor={floors.floors}
			shifts={shifts.shifts}
			experienceList={experienceList == undefined ? false : experienceList.experienceList}
			createBookingOpen={props.createBookingOpen}
			createBookingSuccess={createBookingSuccess}
			selectedDate={selectedDate}
			setSelectedDate={setSelectedDate}
			selectedBookingDate={selectedBookingDate}
			handleCloseCreateBooking={handleCloseCreateBooking}
			setExperienceShiftId={setExperienceShiftId}
		/>
	);
}

export default MakeBooking;
