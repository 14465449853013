import React, { useState } from 'react';
import moment from 'moment';
import { Container, Col } from 'react-bootstrap';
import styled from 'styled-components';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { apiUrl } from 'utils/Utility';

//Icons
import addIcon from 'assets/images/booking/create-booking/icon-add-white.png';
import rightArrow from 'assets/images/booking/right-arrow.png';

//Utilities & Data
import { turnTimeData } from './SubComponents/PickerComponentData';

//SubComponents
import ScheduleTimeTable from './SubComponents/ScheduleTimeTable';
import PickerComponent from './SubComponents/PickerComponent';
import TLPButton from './SubComponents/TLPButton';
import TableScheduleSwitch from './SubComponents/TableScheduleSwitch';
import ShiftColourPicker from './SubComponents/ShiftColourPicker';

const Wrapper = styled.div`
	display: flex;
	overflow: hidden;
	// justify-content: center;
	height: 100%;
	width: 100%;
	padding: 0;
`;

const StyledContainer = styled.div`
	display: flex;
	// justify-content: center;
	// height: 98%;
	width: 100%;
	overflow: hidden;
	margin: 10px 30px 30px 30px;
	padding: 0;
	box-shadow: 5px 5px 10px #1a2024, -5px -5px 10px #2a343a;
	border-radius: 0.8em;
	border: 12px solid #222a2f;
	::-webkit-scrollbar {
		width: 5px;
		height: 5px;
		background-color: #222a2f;
	}
	::-webkit-scrollbar-thumb {
		width: 1px;
		height: 1px;
		border-radius: 20px;
		background-color: #2a343a;
	}
`;

const StyledCol = styled(Col)`
	display: flex;
	flex-direction: column;
	padding: 0;
	margin: 0;
	min-width: fit-content;
`;

const ShiftDetailsContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 270px;
	// max-width: fit-content;
	height: 100%;
	// box-shadow: 5px 5px 10px #1a2024, -5px -5px 10px #2a343a;
	z-index: 1;
	user-select: none;

	#fragment {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
		color: #bcbcbc;
		padding: 0 15px;
		width: 100%;
		height: 20px;
		margin-bottom: 10px;
		h4 {
			font-size: 16px;
			font-weight: 700;
		}

		label {
			margin: 0px;
			color: #707070;
			font-size: 1em;
			font-weight: bold;
			padding: 5px;
		}

		input {
			text-align: end;
			width: 100%;
			background: transparent;
			color: white;
			padding: 5px;
			font-size: 1em;
			transition: 2ms;
			cursor: default;
		}

		input:focus {
			text-align: start;
			box-shadow: inset 5px 5px 10px #1a2024, inset -5px -5px 10px #2a343a;
			border-radius: 5px;
			padding: 5px;
			transition: 2ms;
		}
	}

	p {
		padding: 5px 20px;
		font-size: 12px;
	}
`;

const LineSeparator = styled.div`
	width: 249px;
	height: 3px;
	border-radius: 25px;
	background: #222a2f;
	box-shadow: inset 1px 1px 2px #1a2024, inset -1px -1px 2px #2a343a;
`;

const SwitchWrapper = styled.div`
	max-height: 165px;
	overflow-y: auto;
	::-webkit-scrollbar {
		width: 3px;
		height: 3px;
		background-color: #222a2f;
	}
	::-webkit-scrollbar-thumb {
		width: 1px;
		height: 1px;
		border-radius: 20px;
		background-color: #2a343a;
	}
`;

const SwitchContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin: 15px 20px;
	label {
		width: 69px;
		height: 30px;
	}
	span {
		width: 69px;
		height: 30px;
		box-shadow: 0px 0px 4px #1a2024, -3px -1px 5px #2a343a,
			inset 0px 0px 20px 2px rgb(255 255 255 / 11%);
		border-radius: 50px;
		:before {
			width: 20px;
			height: 19px;
			top: -0.3px;
			left: 1.9px;
		}
	}
	input:checked + span:before {
		transform: translateX(38px);
	}
	h4 {
		font-size: 14px;
		font-weight: 700;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: 15px 10px 0px 15px;
	span {
		height: 40px;
		width: 100%;
		margin: 10px 0;
	}
`;

const PickerWrapper = styled.div`
	min-width: 249px;
	justify-content: center;
	margin: 0 15px;

	select {
		background: #222a2f;
	}
`;

const AddBtnContainer = styled.div`
	position: absolute;
	top: 0px;
	right: 75px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 5px 5px 10px #1a2024, -5px -5px 10px #2a343a;
	border-radius: 50px;
	height: 50px;
	width: 50px;
	margin: 30px 0;
	cursor: pointer;
	user-select: none;
	&:active {
		background: #1f262b;
		color: #ffffff;
		outline: none;
		outline-offset: none;
	}
`;

const SaveButton = styled.div`
	position: absolute;
	top: 0px;
	right: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: transparent;
	// box-shadow: 1px 1px 10px #1a2024, 1px 1px 10px #2a343a;
	border-radius: 50px;
	height: 50px;
	width: 50px;
	margin: 30px 0;
	cursor: pointer;
	user-select: none;
	&:active {
		background: #1f262b;
		color: #ffffff;
		outline: none;
		outline-offset: none;
	}
`;

function TableSchedule(props) {
	const reducers = useSelector((state) => state.userReducers);
	const [floors, setFloors] = useState(props.floors);
	const [shifts, setShifts] = useState(props.shifts);
	const [selectedShift, setSelectedShift] = useState([]);
	const [isShiftselected, setIsShiftselected] = useState(false);
	const [shiftName, setShiftName] = useState('');
	const [isTyping, setIsTyping] = useState(false);
	const [turnTime, setTurnTime] = useState(0);
	const [openColorPicker, setOpenColorPicker] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	// const isSelected = useRef();
	// isSelected.current = isShiftselected;

	const newShiftDefault = {
		shift_color: '#FE7D86',
		end_time: 28800,
		floors: [1],
		turn_time: 7200,
		name: 'New Shift',
		day_of_week: [1],
		id: 0,
		flow_controls: [],
		start_time: 21600,
	};

	const seletectShiftCallback = (shift) => {
		if (shift !== undefined) {
			setShiftName(shift.name);
			setTurnTime(shift.turn_time);
			isShiftSelectedHandler(shift);
			setSelectedShift(shift);
		} else {
			isShiftSelectedHandler(shift);
		}
	};

	const isShiftSelectedHandler = (shift) => {
		if (shift !== undefined) {
			setIsShiftselected(true);
		} else {
			setIsShiftselected(false);
		}
	};

	const handleNameUpdate = () => {
		if (isTyping) {
			setIsTyping(false);
			if (selectedShift !== undefined) {
				let shift = selectedShift;
				shift = { ...shift, name: shiftName };
				setSelectedShift(shift);
				updateShifts(shift);
			}
		} else {
			setIsTyping(true);
		}
	};

	const handleInput = (name) => {
		setShiftName(name);
	};

	const toggleSwitchCallback = (check, id) => {
		let shift = selectedShift;
		let list = shift.floors;
		if (check) {
			list.push(id);
			shift = { ...shift, floors: list };
		} else {
			let idx = shift.floors.findIndex((e) => e === id);
			list.splice(idx, 1);
			shift = { ...shift, floors: list };
		}
		setSelectedShift(shift);
		updateShifts(shift);
	};

	const handleTurnTime = (int) => {
		setTurnTime(int);
		// add setShifts here!
	};

	const colorPickerCallback = (color) => {
		if (color !== undefined) {
			let shift = selectedShift;
			shift = { ...shift, shift_color: color };
			setSelectedShift(shift);
			updateShifts(shift);
			setOpenColorPicker(false);
		} else {
			setOpenColorPicker(false);
		}
	};

	const handleDeleteShift = () => {
		let idx = shifts.findIndex((e) => e.id === selectedShift.id);
		let _shifts = shifts;
		_shifts.splice(idx, 1);
		setShifts(_shifts);
		setIsShiftselected(false);
		setSelectedShift([]);
		console.log(_shifts);
	};

	const handleAddShift = () => {
		let _shifts = shifts;
		_shifts.push(newShiftDefault);
		setShifts(_shifts);
		setIsLoading(true);
		saveShift(_shifts);
	};

	const handleSave = () => {
		saveShift(shifts);
	};

	const updateShifts = (shift) => {
		setIsShiftselected(false);
		let idx = shifts.findIndex((e) => e.id === selectedShift.id);
		let _shifts = shifts;
		_shifts.splice(idx, 1, shift);
		setSelectedShift(shift);
		setShifts(_shifts);
	};
	// console.log(selectedShift);

	const getShift = async () => {
		try {
			const response = await axios({
				url: `${apiUrl}/api/get_shifts`,
				method: 'POST',
				data: {},
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${reducers.token}`,
				},
			});

			if (response) {
				const { status } = response;
				const { data } = response.data;
				if (status === 200) {
					let _shifts = data.shifts;
					setShifts(_shifts);
					console.log(_shifts);
				}
			}
		} catch (err) {
			console.log('Error: ', err);
		}
	};

	const saveShift = async (shifts) => {
		try {
			const response = await axios({
				url: `${apiUrl}/api/set_shifts`,
				method: 'POST',
				data: { shifts },
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${reducers.token}`,
				},
			});

			if (response) {
				const { status } = response;
				const { data } = response.data;
				if (status === 200) {
					console.log(data);
					getShift();
				}
			}
		} catch (err) {
			console.log('Error: ', err);
		}
	};

	return (
		<Wrapper>
			{/* <div>
				<p style={{ font: 'white', fontSize: '5em', textAlign: 'center' }}> COMING SOON</p>
			</div> */}
			<AddBtnContainer onClick={handleAddShift}>
				<img src={addIcon} width={24} height={24} />
			</AddBtnContainer>
			<SaveButton onClick={handleSave} />

			{/* <div style={{ zIndex: 1 }}> */}
			<StyledContainer style={isShiftselected ? { borderRight: 'none' } : {}}>
				<ScheduleTimeTable
					shifts={shifts}
					isShiftselected={isShiftselected ? true : false}
					updateShifts={updateShifts}
					seletectShiftCallback={seletectShiftCallback}
					isShiftSelectedHandler={isShiftSelectedHandler}
					setSelectedShift={setSelectedShift}
					// handleCloseProperties={handleCloseProperties}
				/>

				{isShiftselected ? (
					<StyledCol>
						<ShiftDetailsContainer>
							<span id="fragment">
								<h4>SHIFT DETAILS</h4>
								<div
									style={{ position: 'absolute', right: '10px', cursor: 'pointer' }}
									onClick={(e) => seletectShiftCallback()}
								>
									<img src={rightArrow} width={9.2} height={16} />
								</div>
							</span>

							<span style={{ display: 'flex', justifyContent: 'center', height: '5px' }}>
								<LineSeparator />
							</span>

							<span id="fragment">
								{!isTyping ? <label>NAME</label> : ''}

								<input
									type="Text"
									onFocus={handleNameUpdate}
									onBlur={handleNameUpdate}
									onChange={(e) => handleInput(e.target.value)}
									value={shiftName}
									style={{ cursor: 'pointer' }}
								/>
							</span>
							{selectedShift.start_time !== undefined ? (
								<>
									<p>
										Shift time:{' '}
										{moment(new Date())
											.startOf('day')
											.seconds(selectedShift.start_time)
											.format('HH:mm')}{' '}
										to{' '}
										{moment(new Date())
											.startOf('day')
											.seconds(selectedShift.end_time)
											.format('HH:mm')}{' '}
									</p>
									<p>
										Applies to:{' '}
										{selectedShift.day_of_week.map((d, i) => moment().day(d).format('ddd') + ' ')}
									</p>
								</>
							) : (
								''
							)}

							<span
								id="fragment"
								style={{
									display: 'flex',
									justifyContent: 'center',
									margin: '20px 0 10px 0',
									height: '5px',
								}}
							>
								<LineSeparator />
							</span>

							<span id="fragment" style={{ display: 'flex', flexDirection: 'column' }}>
								<h4 style={{ color: '#707070' }}>DINNING AREA</h4>
							</span>
							<SwitchWrapper>
								{floors.length > 0
									? floors.map((e) => {
											return (
												<SwitchContainer key={e.id}>
													<h4>{e.floor_name}</h4>
													<TableScheduleSwitch
														selectedShift={selectedShift}
														identifier={e.id}
														toggleSwitchCallback={toggleSwitchCallback}
														// checked={shiftFloors.includes(e.id) ? true : false}
													/>
												</SwitchContainer>
											);
									  })
									: ''}
							</SwitchWrapper>

							<span
								style={{
									display: 'flex',
									justifyContent: 'center',
									margin: '0 0 5px 0',
									height: '5px',
								}}
							>
								<LineSeparator />
							</span>

							<span id="fragment" yle={{ flexDirection: 'column' }}>
								<h4>TURN TIME</h4>
							</span>
							<PickerWrapper>
								<PickerComponent
									data={turnTimeData}
									updateValue={handleTurnTime}
									initValue={turnTime}
									style={{ borderRadius: '10px', width: '100%' }}
								/>
							</PickerWrapper>
							<ButtonContainer>
								<span>
									<TLPButton
										onClick={(e) => setOpenColorPicker(true)}
										height={'40px'}
										width={'249px'}
										margin={'0 7px 0 0'}
										label={'Shift Colour'}
										style={{
											border: '5px solid #222A2F',
											background: `${selectedShift.shift_color}`,
											color: '#FFFFFF',
											fontSize: '14px',
											fontWeight: 700,
										}}
									/>
								</span>

								<span>
									<TLPButton
										onClick={handleDeleteShift}
										height={'40px'}
										width={'249px'}
										margin={'0 7px 0 0'}
										label={'Delete Shift'}
										style={{
											fontSize: '14px',
											fontWeight: 700,
										}}
									/>
								</span>
							</ButtonContainer>
						</ShiftDetailsContainer>
					</StyledCol>
				) : (
					''
				)}
			</StyledContainer>
			<ShiftColourPicker
				openColorPicker={openColorPicker}
				initValue={selectedShift.shift_color}
				colorPickerCallback={colorPickerCallback}
			/>
			{/* </div> */}
		</Wrapper>
	);
}

export default TableSchedule;
