import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';

//Utilities | Constants
import { BOOKINGTYPE, BOOKINGSTATUS } from 'constants/enum';

//Icons
import clockIcon from 'assets/images/booking/guest-book/clock-icon.png';
import partyIcon from 'assets/images/booking/guest-book/party-icon.png';
import phoneIcon from 'assets/images/booking/reservation-btn-phone-icon.png';
import widgetIcon from 'assets/images/booking/widget-icon.png';
import walkInIcon from 'assets/images/booking/create-booking/booking-status-walk-in.png';
import expIcon from 'assets/images/booking/create-booking/booking-option-experience-icon.png';
import cancelledIcon from 'assets/images/booking/booking-details/booking-status-cancelled-icon.png';
import noShowIcon from 'assets/images/booking/booking-details/booking-status-no-show-icon.png';

const Container = styled.div`
	display: inline-flex;
	flex-direction: column;
	margin-top: 20px;
	margin-right: 10px;

	h4 {
		margin: 15px 0;
		font-size: 14px;
		font-weight: 700;
		color: #bcbcbc;
	}

	span {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-top: 20px 0;
		width: 100%;
	}
`;

const AnalyticsBox = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 120px;
	width: 23%;
	border: 1px solid #cccccc;
	border-radius: 5px;

	label {
		font-size: 14px;
		font-weight: 700;
		color: #707070;
	}

	h5 {
		font-size: 24px;
		color: #ffffff;
	}
`;

const GuestBookingDetails = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	height: 80px;
	width: 100%;
	background: #3a444b;
	border-radius: 5px;

	#guest-booking-details-column1 {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 56px;
		img {
			filter: brightness(3.5);
		}
	}

	#guest-booking-details-column2 {
		display: flex;
		flex-direction: column;
		position: relative;
		padding: 10px 0;
		margin-right: 30px;

		h4 {
			font-size: 18px;
			font-weight: 700;
			color: #ffffff;
			margin: 0 10px 10px 0;
		}

		p {
			font-size: 16px;
			font-weight: 400;
			color: #bcbcbc;
			margin: 0;
		}

		#table-no {
			position: absolute;
			display: flex;
			justify-content: center;
			align-items: center;
			left: 75px;
			bottom: 17px;
			width: auto;
			height: 20px;
			border-radius: 5px;
			font-size: 14px;
			padding: 5px;
		}
	}

	#guest-booking-details-column3 {
		position: absolute;
		right: 15px;
		display: inline-flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		margin: 5px 0 0 0;
		height: 30px;

		img {
			margin-right: 10px;
		}

		P {
			margin: 0 30px 0 0;
		}
	}
`;

function GuestAnalytics(props) {
	const [bookings, setBookings] = useState({ upcoming: [], history: [] });

	console.log(props);

	const upcomingChecker = (data) => {
		let int;
		data.map((d) => {
			if (d.sectionName === 'Upcoming') {
				int = d.bookings.length;
			} else {
				int = 0;
			}
		});

		if (data.length === 0) {
			int = 0;
		}

		return int;
	};

	const visitsChecker = (data) => {
		let int;
		data.map((d) => {
			if (d.sectionName === 'Finished') {
				int = d.bookings.length;
			} else {
				int = 0;
			}
		});
		if (data.length === 0) {
			int = 0;
		}

		return int;
	};

	const cancelledChecker = (data) => {
		let int;
		data.map((d) => {
			if (d.sectionName === 'Cancelled') {
				int = d.bookings.length;
			} else {
				int = 0;
			}
		});
		if (data.length === 0) {
			int = 0;
		}

		return int;
	};

	const noShowChecker = (data) => {
		let int;
		data.map((d) => {
			if (d.sectionName === 'No Show') {
				int = d.bookings.length;
			} else {
				int = 0;
			}
		});
		if (data.length === 0) {
			int = 0;
		}

		return int;
	};

	useEffect(() => {
		if (props.filteredGuestHistory.length > 0) {
			props.filteredGuestHistory.forEach((data) => {
				let _bookings = bookings;
				let upcoming = [];
				let history = [];
				if (data.sectionName !== 'Upcoming') {
					data.bookings.map((e) => {
						history.push(e);
					});
				} else {
					data.bookings.map((e) => {
						upcoming.push(e);
					});
				}
				_bookings = { ..._bookings, upcoming: upcoming, history: history };
				setBookings(_bookings);
			});
		}
	}, [props.filteredGuestHistory]);

	return (
		<Container>
			<span>
				<AnalyticsBox>
					<label>UPCOMING</label>
					<h5>{upcomingChecker(props.filteredGuestHistory)}</h5>
				</AnalyticsBox>
				<AnalyticsBox>
					<label>VISITS</label>
					<h5>{visitsChecker(props.filteredGuestHistory)}</h5>
				</AnalyticsBox>
				<AnalyticsBox>
					<label>CANCELLATIONS</label>
					<h5>{cancelledChecker(props.filteredGuestHistory)}</h5>
				</AnalyticsBox>
				<AnalyticsBox>
					<label>NO-SHOWS</label>
					<h5>{noShowChecker(props.filteredGuestHistory)}</h5>
				</AnalyticsBox>
			</span>

			{bookings.upcoming.length > 0
				? bookings.upcoming.map((data, key) => {
						return (
							<div key={key}>
								<h4>UPCOMING</h4>
								<GuestBookingDetails>
									<div id="guest-booking-details-column1"></div>
									<div id="guest-booking-details-column2">
										<h4>{moment(data.start_date).format('dddd, DD MMM YYYY')}</h4>
										<p>Booked</p>{' '}
										<div id="table-no" style={{ color: '#FFFFFF', border: '1px solid #ffffff' }}>
											{data.table.length > 1
												? data.table[0].name + '+'
												: data.table.length > 0
												? data.table[0].name
												: ''}
										</div>
									</div>
									<div id="guest-booking-details-column3">
										<img src={clockIcon} width={18} height={18} />
										<p>{moment(data.start_date, 'YYYY-MM-DD hh:mm:ss').format('hh:mm A')}</p>

										<img src={partyIcon} width={18} height={18} />
										<p>{data.party_size} guests</p>

										{data.type === BOOKINGTYPE.inhouse ? (
											<img src={walkInIcon} width={18} height={18} />
										) : data.type === BOOKINGTYPE.phone ? (
											<img src={phoneIcon} width={18} height={18} />
										) : data.type === BOOKINGTYPE.experience ? (
											<img src={expIcon} width={18} height={18} />
										) : data.type === BOOKINGTYPE.widget ? (
											<img src={widgetIcon} width={18} height={18} />
										) : (
											''
										)}
									</div>
								</GuestBookingDetails>
							</div>
						);
				  })
				: ''}

			{bookings.history.length > 0
				? bookings.history.map((data, key) => {
						return (
							<div key={key}>
								<h4>HISTORY</h4>
								<GuestBookingDetails key={key}>
									<div id="guest-booking-details-column1">
										{data.status === BOOKINGSTATUS.cancelled ? (
											<img src={cancelledIcon} width={30} height={30} />
										) : data.status === BOOKINGSTATUS.noShow ? (
											<img src={noShowIcon} width={30} height={30} />
										) : (
											''
										)}
									</div>
									<div id="guest-booking-details-column2">
										<h4>{moment(data.start_date).format('dddd, DD MMM YYYY')}</h4>
										{data.status === BOOKINGSTATUS.finished ? (
											<>
												<p>Finished</p>
												<div
													id="table-no"
													style={{ color: '#BCBCBC', border: '1px solid #BCBCBC' }}
												>
													{data.table.length > 1
														? data.table[0].name + '+'
														: data.table.length > 0
														? data.table[0].name
														: ''}
												</div>
											</>
										) : data.status === BOOKINGSTATUS.cancelled ? (
											<>
												<p>Cancelled</p>
												<div
													id="table-no"
													style={{ color: '#BCBCBC', border: '1px solid #BCBCBC' }}
												>
													{data.table.length > 1
														? data.table[0].name + '+'
														: data.table.length > 0
														? data.table[0].name
														: ''}
												</div>
											</>
										) : data.status === BOOKINGSTATUS.noShow ? (
											<p>No Show</p>
										) : (
											''
										)}
									</div>
									<div id="guest-booking-details-column3">
										<img src={clockIcon} width={18} height={18} />
										<p>{moment(data.start_date, 'YYYY-MM-DD hh:mm:ss').format('hh:mm A')}</p>

										<img src={partyIcon} width={18} height={18} />
										<p>{data.party_size} guests</p>

										{data.type === BOOKINGTYPE.inhouse ? (
											<img src={walkInIcon} width={18} height={18} />
										) : data.type === BOOKINGTYPE.phone ? (
											<img src={phoneIcon} width={18} height={18} />
										) : data.type === BOOKINGTYPE.experience ? (
											<img src={expIcon} width={18} height={18} />
										) : data.type === BOOKINGTYPE.widget ? (
											<img src={widgetIcon} width={18} height={18} />
										) : (
											''
										)}
									</div>
								</GuestBookingDetails>
							</div>
						);
				  })
				: ''}
		</Container>
	);
}

export default GuestAnalytics;
