import axios from 'axios';
import { isError } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Form, Spinner } from 'react-bootstrap';
import { apiUrl } from 'utils/Utility';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import { Link, withRouter } from 'react-router-dom';

const Wrap = {
	LeftWrap: styled.div`
		background: transparent linear-gradient(148deg, #f5dba2 0%, #fa327e 100%) 0% 0% no-repeat
			padding-box;
		border-radius: 35px;
		height: auto;
		padding: 0rem;
		text-align: left;
	`,
	InWrap: styled.div`
		padding: 5rem;
	`,
	InnerWrap: styled.div`
		// background: hsla(0, 0%, 100%, 0.3); //white translucent background
		border-radius: 15px;
		height: 100%;
		position: relative;
	`,
	BotomAbs: styled.div`
		padding: 2rem;
		position: absolute;
		bottom: 0;
	`,
	TopAbs: styled.div`
		padding: 2rem;
		position: absolute;
		top: 25%;
		left: 0;
		bottom: 25%;
		right: 0;
	`,
	RightWrap: styled.div`
		padding: 4rem;
		${down('sm')} {
			padding: 2rem;
		}
	`,
	ImgLogo: styled.img`
		width: 200px;
		margin: 0 auto;
	`,
	Text: styled.div`
		color: ${(props) => props.propsColor};
		font-size: ${(props) => props.propsFontSize};
		text-align: ${(props) => props.propsAlign};
		font-weight: ${(props) => props.propsWeight};
	`,
	SubmitBtn: styled.button`
		background: transparent
			linear-gradient(119.22deg, #f76b1c 24.06%, #f9af46 77.87%, #fbda61 112.36%) 0% 0% no-repeat
			padding-box;
		border-radius: 9px;
		width: 100%;
		padding: 1rem;
		color: #ffffff;
		font-size: 20px;
	`,
	InputWrap: styled.div`
		border-radius: 9px;
		// padding: 1rem;
		background: #f6f6f6 !important;
		display: flex;
		align-items: center;
	`,
	IconWrap: styled.div`
		margin-right: 20px;
		margin-left: 20px;
	`,
	CustomInput: styled(Form.Control)`
		border: 0;
		background: inherit;
		&:focus {
			outline: 0;
			box-shadow: none;
			border-color: #ced4da;
			background: inherit;
		}
	`,
	SocialWrap: styled.div`
		display: flex;
		align-items: center;
	`,
	SocialBtn: styled.button`
		border: 1px solid #cccccc;
		border-radius: 9px;
		width: 100%;
		padding: 1rem;
		display: flex;
		align-items: center;
		justify-content: center;
	`,
};

const EmailVerification = (props) => {
	const params = props.match.params;

	const [verified, setVerified] = useState(false);
	const [errMessage, setErrMessage] = useState(null);

	const verifyEmail = async () => {
		try {
			const response = await axios({
				method: 'POST',
				url: `${apiUrl}/api/verify-account`,
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${atob(params.id)}`,
				},
				data: {
					verif_token: atob(params.key),
				},
			});

			if (response) {
				const { status } = response;
				const data = response.data.data;

				if (status == 200) {
					setVerified(true);
				}
			}
		} catch (err) {
			const { status, data } = err.response;
			if (status == 412) {
				setErrMessage(data.message);
			}
			console.log('Error: ', err);
		}
	};

	useEffect(() => {
		verifyEmail();
	}, []);

	return (
		<Container fluid>
			<Row>
				<Col
					style={{
						display: !verified ? '' : 'none',
						textAlign: 'center',
					}}
				>
					<Wrap.Text propsColor="#1D1D1F" propsFontSize="30px">
						Verifying Email
					</Wrap.Text>
					<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
				</Col>
				<Col
					style={{
						display: verified ? '' : 'none',
						textAlign: 'center',
					}}
				>
					<Wrap.Text propsColor="#1D1D1F" propsFontSize="30px">
						Email Verified
					</Wrap.Text>
					<br />
					<Wrap.Text propsColor="#000000" propsFontSize="16px">
						Already have an account?
						<br />
						<Link to="/login">
							<span className="font-weight-bold">Sign in</span>
						</Link>
					</Wrap.Text>
				</Col>
			</Row>
		</Container>
	);
};

export default EmailVerification;
