import React, { useState } from 'react';
import { List, ListItem } from '@material-ui/core';
import {
	getTimeOnly,
	getNameStatusColor,
	getBookingStatusColor,
	getFullname,
	getGuestPhoneNum,
	getTableSize,
	getFloorName,
	sortBookingBySection,
	hasConflictBooking,
	getAllTablesOnFloors,
} from '../../../utils/Utility';
import { BOOKINGSTATUS, BOOKINGTYPE } from '../../../constants/enum';
import NumberFormat from 'react-number-format';

import sectionTableIcon from '../../../assets/images/booking/section-table-icon.png';
import sectionPartySizeIcon from '../../../assets/images/booking/section-party-size-icon.png';
import billedIcon from '../../../assets/images/booking/billed-icon.png';
import noteIcon from '../../../assets/images/booking/note-icon.png';
import experienceIcon from '../../../assets/images/booking/create-booking/booking-option-experience-icon.png';
import widgetIcon from '../../../assets/images/booking/widget-icon.png';
import conflictIcon from '../../../assets/images/booking/conflict-icon.png';

function BookingList(props) {
	const selectedBooking = (booking) => {
		if (props.selectedBooking === null) {
			props.selectedBookingHandler(booking);
		} else {
			if (props.selectedBooking.id === booking.id) {
				props.selectedBookingHandler(null);
			} else {
				props.selectedBookingHandler(booking);
			}
		}
	};

	return (
		<div className="booking-list">
			{parseInt(props.selectedOption) !== 3 ? (
				<List style={{ paddingTop: 0 }}>
					{props.filteredBookingList.length > 0
						? props.filteredBookingList.map((item) => (
								<ListItem
									onClick={() => selectedBooking(item)}
									className={
										props.selectedBooking !== null
											? props.selectedBooking.id == item.id
												? 'booking-list-item booking-list-item--selected'
												: 'booking-list-item'
											: 'booking-list-item'
									}
									key={Math.random()}
									alignItems="flex-start"
								>
									<div className="booking-list-item-container">
										<div className={item.status == 8 ? 'booking-list-item-time booking-list-item-time--cancelled' : 'booking-list-item-time'}>
											<span >{getTimeOnly(item.start_date)}</span>
										</div>
										<div className="booking-list-item-guest-name">
											<div>
												<span style={{ color: getNameStatusColor(item.status) }}>
													{getFullname(item.guest)}
												</span>
											</div>
											<div className="booking-list-item-guest-bottom">
												<NumberFormat
													value={getGuestPhoneNum(item.guest)}
													displayType={'text'}
													format="#### #### ####"
												/>
											</div>
											<div className="booking-list-item-guest-bottom">
												{item.status === BOOKINGSTATUS.partiallySeated ? (
													<span style={{ color: getBookingStatusColor(item.status) }}>
														( {item.partial_seated} ) &nbsp;
													</span>
												) : (
													''
												)}
												<span>
													{getTableSize(item) +
														' / ' +
														getFloorName(item.floor_id, props.floorList)}
												</span>
											</div>
										</div>
										<div className="booking-list-item-table">
											<div>
												<span
													style={{
														color: getBookingStatusColor(item.status),
														border: '1px solid ' + getBookingStatusColor(item.status),
													}}
												>
													{item.table.length > 1 ? item.table[0].name + '+' : (item.table.length > 0 ? item.table[0].name : "")}
												</span>
											</div>
											<div className="booking-list-item-icons">
												{hasConflictBooking(
													null,
													getAllTablesOnFloors(props.floorList),
													props.filteredBookingList,
												) ? (
													<img
														src={conflictIcon}
														width="18"
														height="18"
														className="d-inline"
														alt="checker"
													/>
												) : (
													''
												)}

												{item.reservation_note !== null ? (
													<img
														src={noteIcon}
														width="18"
														height="18"
														className="d-inline"
														alt="checker"
													/>
												) : (
													''
												)}

												{item.status === BOOKINGSTATUS.billed ? (
													<img
														src={billedIcon}
														width="18"
														height="18"
														className="d-inline"
														alt="checker"
													/>
												) : (
													''
												)}

												{item.type === BOOKINGTYPE.widget ? (
													<img
														src={widgetIcon}
														width="18"
														height="18"
														className="d-inline"
														alt="checker"
													/>
												) : (
													''
												)}

												{item.type === BOOKINGTYPE.experience ? (
													<img
														src={experienceIcon}
														width="18"
														height="18"
														className="d-inline"
														alt="checker"
													/>
												) : (
													''
												)}
											</div>
										</div>
									</div>
								</ListItem>
						  ))
						: ''}
				</List>
			) : (
				<List style={{ paddingTop: 0 }}>
					{sortBookingBySection(props.bookingsList, props.keyword).length > 0
						? sortBookingBySection(props.bookingsList, props.keyword).map((section) => (
								<div>
									<div className="booking-list-item-section">
										<div style={{ width: '50%' }}>
											<div>
												<span>{section.sectionName}</span>
											</div>
											<div style={{ fontSize: 10, marginTop: 4, marginLeft: 1 }}>
												<span>By schedule time</span>
											</div>
										</div>
										<div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
											<div style={{ marginTop: 8 }}>
												<img
													src={sectionPartySizeIcon}
													width="24"
													height="24"
													className="d-inline"
													style={{ marginTop: -4, marginLeft: 15 }}
													alt="checker"
												/>
												<span style={{ marginLeft: 9, fontSize: 18 }}>
													{section.totalPartySize}
												</span>
											</div>
											<div style={{ marginTop: 8 }}>
												<img
													src={sectionTableIcon}
													width="24"
													height="24"
													className="d-inline"
													style={{ marginTop: -4, marginLeft: 15 }}
													alt="checker"
												/>
												<span style={{ marginLeft: 9, fontSize: 18 }}>{section.totalTable}</span>
											</div>
										</div>
									</div>
									{section.bookings.map((item, idx) => (
										<ListItem
											onClick={() => selectedBooking(item)}
											className="booking-list-item"
											key={Math.random()}
											alignItems="flex-start"
										>
											<div className="booking-list-item-container">
												<div className={item.status == 8 ? 'booking-list-item-time booking-list-item-time--cancelled' : 'booking-list-item-time'}>
													<span>{getTimeOnly(item.start_date)}</span>
												</div>
												<div className="booking-list-item-guest-name">
													<div>
														<span style={{ color: getNameStatusColor(item.status) }}>
															{getFullname(item.guest)}
														</span>
													</div>
													<div className="booking-list-item-guest-bottom">
														<NumberFormat
															value={getGuestPhoneNum(item.guest)}
															displayType={'text'}
															format="#### #### ####"
														/>
													</div>
													<div className="booking-list-item-guest-bottom">
														{item.status === BOOKINGSTATUS.partiallySeated ? (
															<span style={{ color: getNameStatusColor(item.status) }}>
																( {item.partial_seated} )
															</span>
														) : (
															''
														)}
														<span>
															{getTableSize(item) +
																' / ' +
																getFloorName(item.floor_id, props.floorList)}
														</span>
													</div>
												</div>
												<div className="booking-list-item-table">
													<span
														style={{
															color: getBookingStatusColor(item.status),
															border: '1px solid ' + getBookingStatusColor(item.status),
														}}
													>
														{item.table.length > 1 ? item.table[0].name + '+' : item.table[0].name}
													</span>
												</div>
												<div className="booking-list-item-icons">
													{hasConflictBooking(
														null,
														getAllTablesOnFloors(props.floorList),
														props.bookingsList,
													) ? (
														<img
															src={conflictIcon}
															width="18"
															height="18"
															className="d-inline"
															alt="checker"
														/>
													) : (
														''
													)}

													{item.reservation_note !== null ? (
														<img
															src={noteIcon}
															width="18"
															height="18"
															className="d-inline"
															alt="checker"
														/>
													) : (
														''
													)}

													{item.status === BOOKINGSTATUS.billed ? (
														<img
															src={billedIcon}
															width="18"
															height="18"
															className="d-inline"
															alt="checker"
														/>
													) : (
														''
													)}

													{item.type === BOOKINGTYPE.widget ? (
														<img
															src={widgetIcon}
															width="18"
															height="18"
															className="d-inline"
															alt="checker"
														/>
													) : (
														''
													)}

													{item.type === BOOKINGTYPE.experience ? (
														<img
															src={experienceIcon}
															width="18"
															height="18"
															className="d-inline"
															alt="checker"
														/>
													) : (
														''
													)}
												</div>
											</div>
										</ListItem>
									))}
								</div>
						  ))
						: ''}
				</List>
			)}
		</div>
	);
}

export default BookingList;
