import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import cookie from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';

import Home from 'pages/Guest/Home2';
import RevEnquiry from 'pages/Guest/Enquiry';
import Terms from 'pages/Guest/Terms';
import Privacy from 'pages/Guest/Privacy';
// import Home from '../src/pages/Home';
// import Login from '../src/pages/Login';
// import SignUp from '../src/pages/SignUp';
import ForgotPassword from '../src/pages/ForgotPassword';
import Plan from '../src/pages/Plan';
import Profile from '../src/pages/Dashboard/Profile';
import Floor from '../src/pages/Dashboard/Floor';
import Shift from '../src/pages/Dashboard/Shift';
import Widget from '../src/pages/Dashboard/Widget';
import EditProfile from '../src/pages/Dashboard/EditProfile';
import EditPlan from '../src/pages/Dashboard/EditPlan';
import Booking from '../src/pages/Dashboard/Booking/Booking';
import POSSettings from 'pages/Dashboard/POSSettings/POSSettings';
import Message from '../src/pages/Dashboard/Message';
import { userActions } from './actions/userActions';
import Loader from 'components/Loader';
import TableLayoutandPlanning from '../src/pages/Dashboard/Booking/TableLayoutPlanning/TableLayoutandPlanning';
import GuestBook from 'pages/Dashboard/Booking/GuestBook/GuestBook';
import Reports from 'pages/Dashboard/Booking/Reports/Reports';
import RestaurantProfile from 'pages/Dashboard/Booking/RestaurantProfile/RestaurantProfile';
import UserRoles from 'pages/Dashboard/Booking/UserRoles/UserRoles';
import EditBooking from 'pages/EditBooking/EditBooking';
import LiteCardLandingPage from 'pages/LitecardLandingPage/LiteCardLandingPage';
import InstagramSuccess from 'pages/Dashboard/Instagram/InstagramSuccess';
import InstagramDeauthorize from 'pages/Dashboard/Instagram/InstagramDeauthorize';
import EmailVerification from 'pages/EmailVerification/EmailVerification';
import ResetPassword from 'pages/ResetPassword/ResetPassword';
// import TableDetails from './pages/Dashboard/Booking/TableDetails/TableDetails';

function App() {
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();
	const reducers = useSelector((state) => state.userReducers);

	useEffect(() => {
		const token = cookie.get('token');
		if (token) {
			// authenticate({ token });
			dispatch(userActions.getUser({ token }));
			dispatch(userActions.getBusinessProfile({ token }));
			dispatch(userActions.getAllShifts({ token }));
		} else {
			dispatch(userActions.setLoading());
		}
	}, [dispatch]);

	useEffect(() => {
		setLoading(reducers.loading);
	}, [reducers]);

	// FIXME: better loading pls, gross
	if (loading) return <Loader isFixed />;

	return (
		<Router>
			<Switch>
				<Route exact path="/" component={Home} />
				<Route exact path="/enquire" component={RevEnquiry} />
				<Route exact path="/forgot-password" component={ForgotPassword} />
				<Route exact path="/terms-and-conditions" component={Terms} />
				<Route exact path="/privacy-policy" component={Privacy} />
				<Route exact path="/cancel-booking/:id/:bookingId" component={EditBooking} />
				<Route exact path="/lite-card/:id/:type/:downloadId" component={LiteCardLandingPage} />
				<Route exact path="/instagram/success" component={InstagramSuccess} />
				<Route exact path="/instagram/deauthorize/success" component={InstagramDeauthorize} />
				<Route exact path="/verify-account/:id/:key" component={EmailVerification} />
				<Route exact path="/reset-password/:token" component={ResetPassword} />
				{/*<PrivateRoute
					exact
					path="/plan"
					component={Plan}
					token={reducers.token}
					// isSubscribed={reducers.isSubscribed}
				/>
                                */}
				<IsSubscribedRoute
					exact
					path="/dashboard"
					component={Profile}
					token={reducers.token}
					isSubscribed={reducers.isSubscribed}
				/>
				<IsSubscribedRoute
					exact
					path="/dashboard/edit-profile"
					component={EditProfile}
					token={reducers.token}
					isSubscribed={reducers.isSubscribed}
				/>
				<IsSubscribedRoute
					exact
					path="/dashboard/edit-plan"
					component={EditPlan}
					token={reducers.token}
					isSubscribed={reducers.isSubscribed}
				/>
				<IsSubscribedRoute
					exact
					path="/dashboard/widget"
					component={Widget}
					token={reducers.token}
					isSubscribed={reducers.isSubscribed}
				/>
				<IsSubscribedRoute
					exact
					path="/dashboard/floor"
					component={Floor}
					token={reducers.token}
					isSubscribed={reducers.isSubscribed}
				/>
				<IsSubscribedRoute
					exact
					path="/dashboard/shift"
					component={Shift}
					token={reducers.token}
					isSubscribed={reducers.isSubscribed}
				/>
				<IsSubscribedRoute
					exact
					path="/dashboard/booking"
					component={Booking}
					token={reducers.token}
					isSubscribed={reducers.isSubscribed}
				/>
				<IsSubscribedRoute
					exact
					path="/dashboard/pos-settings"
					component={POSSettings}
					token={reducers.token}
					isSubscribed={reducers.isSubscribed}
				/>
				<IsSubscribedRoute
					exact
					path="/dashboard/message"
					component={Message}
					token={reducers.token}
					isSubscribed={reducers.isSubscribed}
				/>
				<IsSubscribedRoute
					exact
					path="/dashboard/booking/table-planning"
					component={TableLayoutandPlanning}
					token={reducers.token}
					isSubscribed={reducers.isSubscribed}
				/>
				<IsSubscribedRoute
					exact
					path="/dashboard/booking/guest-book"
					component={GuestBook}
					token={reducers.token}
					isSubscribed={reducers.isSubscribed}
				/>
				<IsSubscribedRoute
					exact
					path="/dashboard/booking/report"
					component={Reports}
					token={reducers.token}
					isSubscribed={reducers.isSubscribed}
				/>
				<IsSubscribedRoute
					exact
					path="/dashboard/booking/restaurant-profile"
					component={RestaurantProfile}
					token={reducers.token}
					isSubscribed={reducers.isSubscribed}
				/>
				<IsSubscribedRoute
					exact
					path="/dashboard/booking/user-roles"
					component={UserRoles}
					token={reducers.token}
					isSubscribed={reducers.isSubscribed}
				/>
			</Switch>
		</Router>
	);
}

function IsSubscribedRoute({ component: Component, token, isSubscribed, ...rest }) {
	isSubscribed = true;
	return (
		<Route
			{...rest}
			render={(props) =>
				isSubscribed ? (
					<PrivateRoute component={Component} token={token} {...props} />
				) : (
					<Redirect to={{ pathname: '/plan' }} push={true} />
				)
			}
		/>
	);
}

function PrivateRoute({ component: Component, token, ...rest }) {
	return (
		<Route
			{...rest}
			render={(props) =>
				token ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: '/',
						}}
						push={true}
					/>
				)
			}
		/>
	);
}

export default App;
