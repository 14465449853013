import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Media } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import axios from 'axios';

//Icons
import saveIcon from '../../../../assets/images/booking/restaurant-profile/save-icon.png';
import testPic from '../../../../assets/images/booking/restaurant-profile/test-profile-pic.png';
import cameraIcon from '../../../../assets/images/booking/restaurant-profile/camer-icon.png';

//Components & Data
import { apiUrl, imgUrl } from '../../../../utils/Utility';
import styled from 'styled-components';
import withDashboardApp from 'components/withDashboardApp';
import BookingNavBar from '../BookingNavBar/BookingNavBar';
import { profileData } from './RestoProfileNavData';
import DotLoader from '../TableLayoutPlanning/pages/SubComponents/DotLoader';

const Wrapper = styled.div`
	position: relative;
	display: inline-block;
	height: 380px;
	width: 98%;
	box-shadow: 5px 5px 10px #1a2024, -5px -5px 10px #2a343a;
	border-radius: 0.8em;
	margin: 0 15px;
	padding-top: 25px;
	user-select: none;

	#sub-container {
		display: flex;
		flex-direction: row;
	}

	img {
		-webkit-user-drag: none;
		-khtml-user-drag: none;
		-moz-user-drag: none;
		-o-user-drag: none;
		user-drag: none;
	}
`;

const NavContainer = styled.div`
	display: flex;
	color: white;
	font-family: sans-serif;
	font-weight: 800;
	margin: 30px 0 0 0px;
	user-select: none;
`;

const NavUL = styled.ul`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	z-index: 2;
`;

const NavLI = styled.li`
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2;
`;

const NavButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 5px 5px 10px #1a2024, -5px -5px 10px #2a343a;
	border-radius: 5px;
	padding: 0 12px;
	height: 50px;
	width: ${(props) => (props.selected ? 'auto' : 50)}px;
	transition: 250ms;
	cursor: pointer;
	font-size: 15px;
	font-weight: bold;

	&:active {
		background: #1f262b;
		color: #ffffff;
		outline: none;
		outline-offset: none;
	}

	span {
		margin-left: 10px;
		white-space: nowrap;
		overflow: hidden;
	}
`;

const ProfilePhotoWrapper = styled.div`
	position: relative;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	height: 180px;
	width: 180px;
	box-shadow: 5px 5px 10px #1a2024, -5px -5px 10px #2a343a;
	border-radius: 50%;
	margin: 0 41px;

	input {
		display: none;
	}

	#api-img {
		// border: 1px solid transparent;
		border-radius: 50%;
		max-width: 140px;
		max-height: 140px;
		aspect-ratio: auto 4/3;
	}
`;

const UploadButton = styled.label`
	position: absolute;
	height: 50px
	width: 50px;
	top: 70px;
	left: 70px;
	filter: drop-shadow(2px 4px 6px black);
	cursor: pointer;

`;

const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	// justify-content: center;
	// align-items: center;
	width: 73%;
	height: 100%;
	// border: 1px solid white;

	span {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		height: 50px;
		width: 379px;
		margin-top: 10px;

		label {
			color: #707070;
			font-weight: bold;
		}
	}
`;

const Styledh6 = styled.h6`
	font-size: 16px;
	font-weight: 700;
	color: #ffffff;
	margin-bottom: 25px;
`;

// const SubH6 = styled(Styledh6)`
// 	font-size: 14px;
// 	margin: 20px 0 15px 5px;
// `;

const BusinessNameInput = styled.input`
	font-size: 14px;
	font-weight: 700;
	background-color: #222a2f;
	color: #ffffff;
	margin: 20px 0 15px 5px;
	&:focus {
		text-align: start;
		box-shadow: inset 5px 5px 10px #1a2024, inset -5px -5px 10px #2a343a;
		border-radius: 5px;
		padding: 5px;
		transition: 2ms;
	}
`;

const AddressInput = styled(BusinessNameInput)`
	font-size: 14px;
	color: #ffffff;
	margin: 10px 0 10px 5px;
`;

const NumberInput = styled(AddressInput)`
	font-size: 16px;
	font-weight: 700;
	color: #ffffff;
	// margin-bottom: 25px;
`;

const Styledh4 = styled.h4`
	font-size: 14px;
	color: #ffffff;
	margin: 10px 0 10px 5px;
`;

const SubH4 = styled(Styledh4)`
	font-size: 12px;
	color: #bcbcbc;
`;

const LineSeparator = styled.div`
	width: 100%;
	height: 3px;
	border-radius: 25px;
	background: #222a2f;
	box-shadow: inset 1px 1px 2px #1a2024, inset -1px -1px 2px #2a343a;
`;

function RestaurantProfile() {
	const history = useHistory();
	const reducers = useSelector((state) => state.userReducers);
	const [showNavbar, setShowNavbar] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isSelected, setIsSelected] = useState(profileData[0]);
	const [profilePic, setProfilePic] = useState(null);
	const [businessName, setBusinessName] = useState('');
	const [address, setAddress] = useState('');
	const [phoneNo, setPhoneNo] = useState('');
	const [extension, setExtension] = useState(null);

	const toggleBookingNavBar = () => setShowNavbar(!showNavbar);

	const toggleSelected = (item) => {
		if (item !== isSelected) {
			setIsSelected(item);
		}
	};

	const handleChangeProfilePic = (e) => {
		if (e.target.files[0]) {
			setProfilePic(URL.createObjectURL(e.target.files[0]));
			// const blob = URL.createObjectURL(e.target.files[0]).blob();
			const formData = new FormData();
			formData.append('image', e.target.files[0], e.target.files[0].type);
			console.log(formData);
			setBusinessProfilePic(formData);
		}
	};

	const setBusinessProfilePic = async (params) => {
		setIsLoading(true);
		try {
			const response = await axios({
				url: `${apiUrl}/api/set_business_profile_pic`,
				method: 'POST',
				data: params,
				headers: {
					'Content-Type': 'multipart/form-data',
					Authorization: `Bearer ${reducers.token}`,
				},
			});

			if (response) {
				const { status } = response;
				const { data } = response.data;
				if (status === 200) {
					console.log(data);
					setIsLoading(false);
					getBusinessProfile();
				}
			}
		} catch (err) {
			console.log('Error: ', err);
		}
	};

	const changeBusinessName = (value) => {
		setBusinessName(value);
	};

	const changeAddress = (value) => {
		setAddress(value);
	};

	const changePhoneNo = (value) => {
		setPhoneNo(value);
	};

	const changeExtension = (value) => {
		setExtension(value);
	};

	const getBusinessProfile = async () => {
		setIsLoading(true);
		try {
			const response = await axios({
				url: `${apiUrl}/api/get_business_profile`,
				method: 'POST',
				data: '',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${reducers.token}`,
				},
			});

			if (response) {
				const { status } = response;
				const { data } = response.data;
				if (status === 200) {
					console.log(data);
					setIsLoading(false);
					setBusinessName(data.business_profile.business_name);
					setAddress(data.business_profile.address);
					setPhoneNo(data.business_profile.phone_no);
					if (data.business_profile.extension !== null) {
						setExtension(data.business_profile.extension);
					}
					if (data.business_profile.profile_pic !== null) {
						setProfilePic(imgUrl + data.business_profile.profile_pic);
					}
				}
			}
		} catch (err) {
			console.log('Error: ', err);
		}
	};

	const handleSubmit = () => {
		console.log('submit');
		let params = {
			business_name: businessName,
			address: address,
			phone_no: phoneNo,
			extension: extension,
			business_type: 'restaurant',
		};
		setBusinessProfile(params);
	};

	const setBusinessProfile = async (params) => {
		setIsLoading(true);
		try {
			const response = await axios({
				url: `${apiUrl}/api/set_business_profile`,
				method: 'POST',
				data: params,
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${reducers.token}`,
				},
			});

			if (response) {
				const { status } = response;
				const { data } = response.data;
				if (status === 200) {
					console.log(data);
					setIsLoading(false);
				}
			}
		} catch (err) {
			console.log('Error: ', err);
		}
	};

	useEffect(() => {
		getBusinessProfile();
	}, []);

	return (
		<Fragment>
			<BookingNavBar
				showNavbar={showNavbar}
				showNavbarHandler={setShowNavbar}
				data={history.location.state}
			/>
			<Row style={{ marginRight: 0, marginLeft: 0, justifyContent: 'space-between' }}>
				<Col
					className="menu-btn-container"
					style={{ marginTop: 30, marginBottom: 15 }}
					sm={1}
					md={1}
					lg={1}
				>
					<div
						style={{ marginLeft: 10 }}
						className="btn-menu btn-menu--margin-top"
						onClick={toggleBookingNavBar}
					>
						<Media className="list-db" as="li">
							<svg
								style={{ marginTop: 12, marginLeft: 11, marginRight: 11 }}
								xmlns="http://www.w3.org/2000/svg"
								width="18"
								height="15"
								viewBox="0 0 18 15"
							>
								<g id="Group_347" data-name="Group 347" transform="translate(-0.333)">
									<rect
										id="Rectangle_42"
										data-name="Rectangle 42"
										width="18"
										height="3"
										rx="1.5"
										transform="translate(0.333)"
										fill="#fff"
									/>
									<rect
										id="Rectangle_43"
										data-name="Rectangle 43"
										width="18"
										height="3"
										rx="1.5"
										transform="translate(0.333 6)"
										fill="#fff"
									/>
									<rect
										id="Rectangle_44"
										data-name="Rectangle 44"
										width="18"
										height="3"
										rx="1.5"
										transform="translate(0.333 12)"
										fill="#fff"
									/>
								</g>
							</svg>
						</Media>
					</div>
				</Col>
				<Col sm={7} md={7} lg={7} style={{ padding: 0, margin: 0 }}>
					<NavContainer>
						{profileData.map((item, key) => {
							return (
								<NavUL key={key}>
									<NavLI onClick={() => toggleSelected(item)}>
										<NavButtonWrapper selected={isSelected === item ? true : false}>
											{item.icon}
											{isSelected === item ? <span>{item.title}</span> : ''}
										</NavButtonWrapper>
									</NavLI>
								</NavUL>
							);
						})}
					</NavContainer>
				</Col>
				<Col sm={4} md={4} lg={4}>
					<div className="tlp-saveIcon-wrapper" onClick={handleSubmit}>
						<img src={saveIcon} width={22} height={22} alt="save" />
					</div>
				</Col>
			</Row>
			<Container style={{ background: 'transparent' }} fluid>
				<Wrapper>
					{isLoading ? <DotLoader /> : ''}
					<div id="sub-container">
						<ProfilePhotoWrapper>
							<input
								id="file-input"
								style={{ color: 'white' }}
								type="file"
								accept="image/"
								onChange={handleChangeProfilePic}
							/>
							{profilePic ? (
								<img id="api-img" src={profilePic} height={140} width={140} alt="pic" />
							) : (
								''
							)}
							<UploadButton htmlFor="file-input">
								<img src={cameraIcon} height={42} width={42} alt="button" />
							</UploadButton>
						</ProfilePhotoWrapper>
						<InputContainer>
							<Styledh6>Contact Information</Styledh6>
							<LineSeparator />
							{/* <SubH6>Santoni Pizza</SubH6> */}
							<BusinessNameInput
								type="text"
								value={businessName}
								onChange={(e) => changeBusinessName(e.target.value)}
							/>
							{/* <Styledh4>634 Glenferrie Road, Hawthorn, AU-VIC 3122</Styledh4> */}
							<AddressInput
								type="text"
								value={address}
								onChange={(e) => changeAddress(e.target.value)}
							/>
							<SubH4>
								*Your restaurant name and address needs to be authenticated, please call XXXX XXXX
								XXXX for assistance.
							</SubH4>
							<br />
							<Styledh4>Tap to edit your contact numbers:</Styledh4>
							<span>
								<label>Reservation Phone</label>
								<NumberInput
									type="text"
									value={phoneNo}
									onChange={(e) => changePhoneNo(e.target.value)}
								/>
							</span>
							<span style={{ margin: 0 }}>
								<label>Extension</label>
								<NumberInput
									type="text"
									value={extension !== null ? extension : '---'}
									onChange={(e) => changeExtension(e.target.value)}
								/>
							</span>
						</InputContainer>
					</div>
				</Wrapper>
			</Container>
		</Fragment>
	);
}

export default withDashboardApp(RestaurantProfile);
