import React, { useEffect, useState } from 'react';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import exitBtnIcon from '../../../../../../assets/images/booking/exit-btn-table-details.png';
import partyIcon from '../../../../../../assets/images/booking/section-party-size-icon.png';
import PickerComponent from './PickerComponent';
import { bookingSizeOption, bookingSizeOption2 } from './PickerComponentData';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		width: '100%',
		height: '100%',
		backdropFilter: 'blur(10px)',
		outline: 'none',
	},
}));
const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
`;
const Content = styled.div`
	width: 468px;
	height: 204px;
	background: rgba(0, 0, 0, 0.6);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 10px;
	border-color: none;
	padding: 20px;
	color: white;
`;
const Header = styled.label`
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 16px;
	font-weight: bold;
	color: white;
	width: 100%;
	margin-bottom: 30px;
`;
const ImgWrapper = styled.div`
	display: inline-flex;
	align-content: center;
	justify-content: center;
	align-items: center;
	position: absolute;
	background-color: #ffffffd9;
	border-radius: 27%;
	top: 15px;
	right: 15px;
	cursor: pointer;
	width: 36px;
	height: 36px;
	margin: 5px;
`;
const PickerWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	width: 100%;
	height: 80px;
	span {
		width: 205px;
		text-align: center;
		p {
			font-family: sans-serif;
			font-size: 12px;
			margin-bottom: 10px;
		}
	}
`;

function TableCapacityPicker(props) {
	const classes = useStyles();
	const [tableMaxCapacity, settableMaxCapacity] = useState(
		props.currentTable !== undefined ? props.currentTable.capacity_max : 0,
	);
	const [tableMinCapacity, settableMinCapacity] = useState(
		props.currentTable !== undefined ? props.currentTable.capacity_min : 0,
	);

	const handleCloseTableCapacityPicker = () => {
		let capacity = {
			min: tableMinCapacity,
			max: tableMaxCapacity,
		};
		props.tableCapacityCallback(capacity);
		props.handleTableCapacityPicker();
	};

	// console.log(props.currentTable);
	return (
		<Modal
			className={classes.modal}
			open={props.openCapacityPicker}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={props.openCapacityPicker}>
				<div className={classes.paper}>
					<Wrapper>
						<Content>
							<ImgWrapper onClick={handleCloseTableCapacityPicker}>
								<img src={exitBtnIcon} width="12" height="12" alt="checker" />
							</ImgWrapper>
							<Header>
								<img
									src={partyIcon}
									width="26"
									height="26"
									alt="Icon"
									style={{ marginTop: 5, marginRight: 5, marginBottom: 5 }}
								/>
								TABLE CAPACITY
							</Header>

							<PickerWrapper>
								<span>
									<p>MINIMUM</p>
									<PickerComponent
										data={bookingSizeOption}
										initValue={
											props.currentTable !== undefined ? props.currentTable.capacity_min : 0
										}
										updateValue={settableMinCapacity}
									/>
								</span>
								<span>
									<p>MAXIMUM</p>
									<PickerComponent
										data={bookingSizeOption2}
										initValue={
											props.currentTable !== undefined ? props.currentTable.capacity_max : 0
										}
										updateValue={settableMaxCapacity}
									/>
								</span>
							</PickerWrapper>
						</Content>
					</Wrapper>
				</div>
			</Fade>
		</Modal>
	);
}
export default TableCapacityPicker;
