import React from 'react';
import { useDispatch } from 'react-redux';
import { Spinner } from 'react-bootstrap';

// Material-UI Components
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

// React Icons
import { HiOutlineLockClosed, HiOutlineMail } from 'react-icons/hi';

// Formik and Yup Imports
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage } from 'formik';

// Other Custom Imports
import { apiUrl } from 'utils/Utility';
import { userActions } from 'actions/userActions';
import cookie from 'js-cookie';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
	focusedInput: {
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: 'pink',
		},
	},
}));

const loginSchema = Yup.object().shape({
	email: Yup.string().email('Invalid email address').required('Email address is required'),
	password: Yup.string().required('Password is required'),
});

const Signin = () => {
	const dispatch = useDispatch();

	const onSubmit = async (values, { setFieldError, setSubmitting }) => {
		try {
			const response = await axios({
				url: `${apiUrl}/api/auth`,
				method: 'POST',
				data: {
					email: values.email,
					password: values.password,
					device_name: 'web',
				},
			});

			if (response) {
				const {
					data: { token },
				} = response.data;

				dispatch(userActions.getUser({ token }));
				dispatch(userActions.getBusinessProfile({ token }));
				cookie.set('token', token, { path: '/' });
				window.location.href = '/dashboard';
			}
		} catch (err) {
			const { status, data } = err.response;
			const errorStatusCodes = [401, 404, 422];
			setSubmitting(false);

			if (errorStatusCodes.includes(status)) {
				setFieldError('password', data.message);
			}
		}
	};

	const classes = useStyles();

	return (
		<Container>
			<Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
				<Formik
					initialValues={{ email: '', password: '' }}
					validationSchema={loginSchema}
					onSubmit={onSubmit}
				>
					{({ isSubmitting }) => (
						<Form>
							<Typography variant="h5" style={{ color: '#000000', marginTop: 20 }}>
								Login to your account
							</Typography>
							<Typography
								style={{
									color: 'gray',
									wordWrap: 'break-word',
									fontSize: 12,
									marginTop: 20,
									marginBottom: 20,
								}}
							>
								Hello, Friend! Follow the instructions to make it easier to register and you will be
								able to explore inside.
							</Typography>

							<Field
								type="text"
								name="email"
								as={TextField}
								margin="normal"
								required
								fullWidth
								id="email"
								label="Email Address"
								autoComplete="email"
								autoFocus
								variant="outlined"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<HiOutlineMail />
										</InputAdornment>
									),
								}}
								InputLabelProps={{
									classes: {
										focused: classes.focusedInput,
									},
								}}
							/>
							<ErrorMessage name="email" component="div" style={{ color: 'red' }} />

							<Field
								type="password"
								name="password"
								as={TextField}
								margin="normal"
								required
								fullWidth
								label="Password"
								id="password"
								autoComplete="current-password"
								variant="outlined"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<HiOutlineLockClosed />
										</InputAdornment>
									),
								}}
								InputLabelProps={{
									classes: {
										focused: classes.focusedInput,
									},
								}}
							/>
							<ErrorMessage name="password" component="div" style={{ color: 'red' }} />

							<Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								style={{ marginTop: 20, marginBottom: 20, padding: 20 }}
								disabled={isSubmitting}
							>
								{isSubmitting ? (
									<Spinner
										as="span"
										animation="border"
										size="sm"
										role="status"
										aria-hidden="true"
									/>
								) : (
									'Login'
								)}
							</Button>
						</Form>
					)}
				</Formik>
			</Box>
		</Container>
	);
};

export default Signin;
