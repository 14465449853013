import React, { useState, useEffect } from 'react';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { filterTableByFloor, buildRequestHeader } from '../../../../../utils/Utility';
import axios from 'axios';

// Components
import TableFilter from '../table-filter/TableFilter';
import PartySizeSelection from '../party-size-selection/PartySizeSelection';
import TableList from '../table-list/TableList';
import BookingButton from '../booking-button/BookingButton';

// Icons
import exitBtnTableIcon from '../../../../../assets/images/booking/exit-btn-table-details.png';
import moment from 'moment';

// import TimeTable from '../time-table/TimeTable';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		width: '100%',
		height: '100%',
		backdropFilter: 'blur(10px)',
		outline: 'none',
	},
}));

function EditPartySizeTableModal(props) {
	const reducers = props.reducers;

	const classes = useStyles();
	// const [selectedPartySize, setSelectedPartySize] = useState(null);
	const [selectedTable, setSelectedTable] = useState(null);
	const [partySize, setPartySize] = useState();
	const [tableList, setTableList] = useState([]);
	const [tablesData, setTablesData] = useState([]);
	const [toFilterFloor, setToFilterFloor] = useState(props.floor[0]);
	const [hasError, setHasError] = useState(false);
	const [isSuggested, setIsSuggested] = useState(false);
	const [showAllTables, setShowAllTables] = useState(false);
	const [selectedTables, setSelectedtables] = useState(null);

	let _floor = props.floor[0];
	let _partySize = props.partySize;

	const handleOpenEditPartySize = props.partySizeOpen;
	// const handleCloseEditPartySize = props.handleCloseEditPartySize;

	const handleCloseEditPartySize = () => {
		props.editPartySizeTableHandle(partySize, selectedTables);
		props.handleCloseEditPartySize(false);
	};

	const selectedOption = (floor) => {
		_floor = floor;
		setToFilterFloor(floor);
		setTableList(filterTableByFloor(floor.id, tablesData));
		// searchTables();
	};

	const selectedPartySize = (value) => {
		_partySize = value;
		setPartySize(value);
		searchTables();
	};

	const setIsSuggestedHandler = () => {
		setIsSuggested(!isSuggested);
		searchTables();
	};

	const setShowAllTablesHandler = () => {
		setShowAllTables(!showAllTables);
		searchTables();
	};

	// Search Tables
	const searchTables = async () => {
		if (partySize === 0 && props.startTime === 0) {
			setHasError(true);
			return;
		}
		try {
			const param = {
				party_size: _partySize,
				booking_time: props.startTime,
				booking_date: moment(props.startDate).format('yyyy-MM-DD'),
				show_suggested: isSuggested,
				show_all: showAllTables,
			};
			const requestHeader = buildRequestHeader('search_tables', param, props.token);
			const response = await axios(requestHeader);

			if (response) {
				const { status } = response;
				const { data } = response.data;
				const { tables } = data;
				if (status === 200) {
					setTablesData(tables);
					setTableList(
						filterTableByFloor(toFilterFloor == undefined ? _floor.id : toFilterFloor.id, tables),
					);
				}
			}
		} catch (err) {
			console.log('Error: ', err);
		}
	};

	useEffect(() => {
		if (props.partySize && props.startTime && props.token && props.selectedTables) {
			setPartySize(props.partySize);
			setSelectedtables(props.selectedTables);
			searchTables();
		}
	}, []);

	return (
		<div>
			<Modal
				className={classes.modal}
				open={props.partySizeOpen}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={handleOpenEditPartySize}>
					<div className={classes.paper}>
						<div className="modal-content-wrapper">
							<div className="editPartySize-content-container">
								<div className="img-wrapper" onClick={handleCloseEditPartySize}>
									<img src={exitBtnTableIcon} width="12" height="12" alt="checker" />
								</div>
								<label className="modal-title-header">
									<img
										src={props.sectionPartyIcon}
										width="26"
										height="26"
										alt="Icon"
										style={{ marginTop: 5, marginRight: 5, marginBottom: 5 }}
									/>
									{props.modalTitle}
								</label>
								<div className="table-btn-wrapper">
									<BookingButton
										titleName="Suggested"
										isSelectedHandler={() => setIsSuggestedHandler()}
										isSelected={isSuggested}
										width={120}
										height={50}
									/>
									<BookingButton
										titleName="Show all tables"
										isSelectedHandler={() => setShowAllTablesHandler()}
										isSelected={showAllTables}
										width={140}
										height={50}
									/>
								</div>
								<TableFilter selectedOption={selectedOption} floor={props.floor} padRight={0} />
								<div className="party-size-input">
									<input
										style={{
											background: 'transparent',
											color: 'white',
											width: '100%',
											height: '100%',
										}}
										placeholder="Party Size"
										value={props.partySize ?? ''}
										pattern="[0-9]*"
										onChange={(e) => selectedPartySize(parseInt(e.target.value))}
									/>
								</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
										marginTop: 5,
										marginBottom: 24,
									}}
								>
									<PartySizeSelection selectedPartySize={selectedPartySize} partySize={12} />
									<TableList
										isMultipleTable={true}
										selectedTables={selectedTables}
										tables={tableList}
										floors={props.floor}
										selectedTableHandler={setSelectedtables}
									/>
								</div>
							</div>
						</div>
					</div>
				</Fade>
			</Modal>
		</div>
	);
}
export default EditPartySizeTableModal;
