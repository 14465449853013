import React, { useState, useEffect, useRef } from 'react';
import { Chart, PieController, ArcElement } from 'chart.js';
import styled from 'styled-components';

Chart.register(PieController, ArcElement);

const Shadow = styled.div`
	position: absolute;
	height: 155px;
	width: 155px;
	border-radius: 50%;
	background: transparent;
	box-shadow: 5px 5px 10px #1a2024, -5px -5px 10px #2a343a;
`;

function DonutGraph(props) {
	const chartContainer = useRef(null);
	const [chartInstance, setChartInstance] = useState(null);
	const [chartConfig, setChartConfig] = useState(null);
	const [restartChart, setrestartChart] = useState(false);

	const createChartConfig = (reports) => {
		setChartConfig({
			type: 'pie',
			data: {
				labels: ['booking', 'walk-ins'],
				datasets: [
					{
						label: '# of Votes',
						data: [0],
						backgroundColor: '#222a2f',
						weight: 0.8,
					},
					{
						label: 'booking',
						data: [reports.booking.actual, reports.walkIns.actual],
						backgroundColor: ['#C76467', '#FF778D'],
						borderWidth: 0,
						borderAlign: 'inner',
						weight: 2,
					},
					{
						label: '# of Votes',
						data: [0],
						backgroundColor: '#222a2f',
						weight: 1,
					},
					{
						label: 'walk-ins',
						data: [reports.booking.avg, reports.walkIns.avg],
						backgroundColor: ['#C76467', '#FF778D'],
						borderColor: ['#C76467', '#FF778D'],
						weight: 0.1,
					},
					{
						label: '# of Votes',
						data: [0],
						backgroundColor: '#222a2f',
						weight: 1,
					},
				],
			},
			options: {
				defaults: {},
			},
		});
	};

	useEffect(() => {
		if (chartContainer && chartContainer.current) {
			setChartInstance(null);
			if (chartInstance !== null) {
				chartInstance.destroy();
				setrestartChart(true);
			}
			if (props.bookingRangeReports) {
				if (chartConfig !== null) {
					if (restartChart) {
						createChartConfig(props.bookingRangeReports);
						setrestartChart(false);
					} else {
						let newChartInstance = new Chart(chartContainer.current, chartConfig);
						setChartInstance(newChartInstance);
					}
				} else {
					createChartConfig(props.bookingRangeReports);
				}
			}
		}
	}, [props.bookingRangeReports, props.selectedDate, props.isLoading, chartConfig]);

	return (
		<div style={{ width: 155, height: 155, paddingLeft: 26 }}>
			<Shadow>
				<canvas ref={chartContainer} />
			</Shadow>
		</div>
	);
}

export default DonutGraph;

// if (chartInstance !== null) {
// 	chartInstance.destroy();
// }
// if (props.reportsBooking && props.reportsWalkIns) {
// 	if (chartConfig !== null) {
// 		const newChartInstance = new Chart(chartContainer.current, chartConfig);
// 		setChartInstance(newChartInstance);
// 	} else {
// 		createChartConfig(props.reportsBooking, props.reportsWalkIns);
// 	}
// }
