import React, { Fragment, useEffect } from 'react';
import { Container, Row, Col, Form, Spinner } from 'react-bootstrap';
import { FaRegUser, FaFacebook, FaMobileAlt } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';
import { BsBriefcaseFill } from 'react-icons/bs';
import { HiOutlineMail } from 'react-icons/hi';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import axios from 'axios';
import cookie from 'js-cookie';
import { apiUrl } from 'utils/Utility';
import { Link, useHistory, withRouter } from 'react-router-dom';

// hooks
import { useBreakpoints } from 'hooks/useBreakpoints';

// redux
import { userActions } from 'actions/userActions';

// assets
import logoBlack from 'assets/images/logo.png';

const loginSchema = Yup.object().shape({
	email: Yup.string().email('invalid email address').required('email address required'),
	fullName: Yup.string().required('username required'),
	company: Yup.string().required('company required'),
	contact: Yup.string().required('contact no. required'),
});

const MainWrapper = styled.div`
	background-color: #ffffff;
	border-radius: 35px;
	display: flex;
	// align-items: center;
	${down('sm')} {
		border-radius: 20px;
	}
`;

const MainPageWrap = styled.main`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background: transparent linear-gradient(131deg, #fb247e 0%, #f5cf9e 100%) 0% 0% no-repeat
		padding-box;
	padding: 3rem;
	${down('sm')} {
		padding: 0;
	}
`;

const PageWrapper = styled.div`
	display: flex;
	height: 85vh;
	margin: 0;
	align-items: center;
`;

const Spacer = styled.div`
	margin-top: ${(props) => props.propsMtop || 0};
	margin-bottom: ${(props) => props.propsMbottom || 0};
	margin-right: ${(props) => props.propsMright || 0};
	margin-left: ${(props) => props.propsMleft || 0};
	height: ${(props) => props.propsHeight};
`;

const Wrap = {
	LeftWrap: styled.div`
		background: transparent linear-gradient(148deg, #f5dba2 0%, #fa327e 100%) 0% 0% no-repeat
			padding-box;
		border-radius: 35px;
		height: auto;
		padding: 5rem;
	`,
	InWrap: styled.div`
		padding: 5rem;
	`,
	InnerWrap: styled.div`
		background: hsla(0, 0%, 100%, 0.3);
		border-radius: 15px;
		height: 100%;
		position: relative;
	`,
	BotomAbs: styled.div`
		padding: 2rem;
		position: absolute;
		bottom: 0;
	`,
	TopAbs: styled.div`
		padding: 2rem;
		position: absolute;
		top: 25%;
		left: 0;
		bottom: 25%;
		right: 0;
	`,
	RightWrap: styled.div`
		padding: 4rem;
		${down('sm')} {
			padding: 2rem;
		}
	`,
	ImgLogo: styled.img`
		width: 200px;
		margin: 0 auto;
	`,
	Text: styled.div`
		color: ${(props) => props.propsColor};
		font-size: ${(props) => props.propsFontSize};
		text-align: ${(props) => props.propsAlign};
	`,
	SubmitBtn: styled.button`
		background: transparent linear-gradient(104deg, #fb247e 0%, #f5cf9e 100%) 0% 0% no-repeat
			padding-box;
		border-radius: 9px;
		width: 100%;
		padding: 1rem;
		color: #ffffff;
		font-size: 20px;
	`,
	InputWrap: styled.div`
		border-radius: 9px;
		padding: 1rem;
		background: #f6f6f6 !important;
		display: flex;
		align-items: center;
	`,
	IconWrap: styled.div`
		margin-right: 20px;
		margin-left: 20px;
	`,
	CustomInput: styled(Form.Control)`
		border: 0;
		background: inherit;
		&:focus {
			outline: 0;
			box-shadow: none;
			border-color: #ced4da;
			background: inherit;
		}
	`,
	SocialWrap: styled.div`
		display: flex;
		align-items: center;
	`,
	SocialBtn: styled.button`
		border: 1px solid #cccccc;
		border-radius: 9px;
		width: 100%;
		padding: 1rem;
		display: flex;
		align-items: center;
		justify-content: center;
	`,
};

const Enquiry = () => {
	const bp = useBreakpoints();

	const history = useHistory();

	const onSubmit = async (values, { setStatus, setSubmitting }) => {

		 try {
		 	const response = await axios({
		 		url: `${apiUrl}/api/enquire`,
		 		method: 'POST',
		 		data: {
                                    'name' : values.fullName,
                                    'email' : values.email,
                                    'company' : values.company,
                                    'contact' : values.contact
		 		}
		 	});
                        console.log(response);
		 	if (response.data.status_code === 200 ) {
                            alert("Enquiry is sent. Please wait with our customer service to contact you. Thank you.");
                            window.location.reload();
		 	}
		 } catch (err) {
		 	const { status, data } = err.response;
		 	setSubmitting(false);

		 	if (status === 401) {
		 		setStatus({
		 			success: false,
		 			message: data.message,
		 		});
		 	}
		 }
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<MainPageWrap>
			<PageWrapper>
				<Container>
					<Row>
						<Col>
							<MainWrapper>
								{(bp.isMd || bp.isLg || bp.isXl) && (
									<Wrap.LeftWrap style={{ flex: '50%' }}>
										<Wrap.InnerWrap>
											<Wrap.TopAbs>
												<Wrap.Text propsColor="#ffffff" propsFontSize="25px" propsAlign="center">
													Hello, Friend!
												</Wrap.Text>

												<Spacer propsMbottom="2rem" />

												<Wrap.Text propsColor="#ffffff" propsFontSize="16px" propsAlign="center">
													Follow the instructions to make it easier to register and you will be able
													to explore inside.
												</Wrap.Text>
											</Wrap.TopAbs>

											<Wrap.BotomAbs>
												<Wrap.Text propsColor="#ffffff" propsFontSize="16px">
													Already have an account?
													<br />
													<span className="font-weight-bold">Sign in</span>
												</Wrap.Text>
											</Wrap.BotomAbs>
										</Wrap.InnerWrap>
									</Wrap.LeftWrap>
								)}

								<Wrap.RightWrap style={{ flex: '50%' }}>
									<div>
										<Link to="/revamp">
											<Wrap.ImgLogo src={logoBlack} alt="Wabi" />
										</Link>
									</div>

									<Spacer propsMbottom="35px" />

									<div>
										<Wrap.Text propsColor="#1D1D1F" propsFontSize="30px">
											Enquiry
										</Wrap.Text>

										<Spacer propsMbottom="15px" />
									</div>

									<div>
										<Formik
											initialValues={{
												fullName: '',
												email: '',
												company: '',
												contact: '',
											}}
											validationSchema={loginSchema}
											onSubmit={onSubmit}
										>
											{({
												status,
												errors,
												touched,
												values,
												handleSubmit,
												handleChange,
												isSubmitting,
											}) => {
												const errMessage = [];
												if (Object.keys(errors).length > 0 && Object.keys(touched).length > 0) {
													Object.values(errors).forEach((v) => {
														errMessage.push(v);
													});
												}

												return (
													<Form onSubmit={handleSubmit}>
														<Form.Row>
															<Form.Group as={Col} controlId="formBasicFullName">
																<Wrap.InputWrap>
																	<Wrap.IconWrap>
																		<FaRegUser />
																	</Wrap.IconWrap>
																	<Wrap.CustomInput
																		type="text"
																		name="fullName"
																		value={values.fullName}
																		placeholder="Full name"
																		onChange={handleChange}
																	/>
																</Wrap.InputWrap>
															</Form.Group>
														</Form.Row>

														<Form.Row>
															<Form.Group as={Col} controlId="formBasicEmail">
																<Wrap.InputWrap>
																	<Wrap.IconWrap>
																		<HiOutlineMail />
																	</Wrap.IconWrap>
																	<Wrap.CustomInput
																		type="text"
																		name="email"
																		value={values.email}
																		placeholder="Email"
																		onChange={handleChange}
																	/>
																</Wrap.InputWrap>
															</Form.Group>
														</Form.Row>

														<Form.Row>
															<Form.Group as={Col} controlId="formBasicCompany">
																<Wrap.InputWrap>
																	<Wrap.IconWrap>
																		<BsBriefcaseFill />
																	</Wrap.IconWrap>
																	<Wrap.CustomInput
																		type="text"
																		name="company"
																		value={values.company}
																		placeholder="Company"
																		onChange={handleChange}
																	/>
																</Wrap.InputWrap>
															</Form.Group>
														</Form.Row>

														<Form.Row>
															<Form.Group as={Col} controlId="formBasicContact">
																<Wrap.InputWrap>
																	<Wrap.IconWrap>
																		<FaMobileAlt />
																	</Wrap.IconWrap>
																	<Wrap.CustomInput
																		type="text"
																		name="contact"
																		value={values.contact}
																		placeholder="Contact"
																		onChange={handleChange}
																	/>
																</Wrap.InputWrap>
															</Form.Group>
														</Form.Row>
														{errMessage.length > 0 ? (
															<Form.Row>
																<Form.Group as={Col} className="form-errors">
																	<span className="text-danger">
																		{`Error/s: ${errMessage.join(', ')}.`}
																	</span>
																</Form.Group>
															</Form.Row>
														) : (
															<Fragment>
																{status && (
																	<Form.Row>
																		<Form.Group as={Col} className="text-center">
																			<span
																				className={status.success ? 'text-success' : 'text-danger'}
																			>
																				{status.message}
																			</span>
																		</Form.Group>
																	</Form.Row>
																)}
															</Fragment>
														)}
														<Wrap.SubmitBtn type="submit" disabled={isSubmitting}>
															{isSubmitting ? (
																<Spinner
																	as="span"
																	animation="border"
																	size="sm"
																	role="status"
																	aria-hidden="true"
																/>
															) : (
																'Submit'
															)}
														</Wrap.SubmitBtn>
													</Form>
												);
											}}
										</Formik>
									</div>
								</Wrap.RightWrap>
							</MainWrapper>
						</Col>
					</Row>
				</Container>
			</PageWrapper>
		</MainPageWrap>
	);
};

export default withRouter(Enquiry);
