import React, { useState, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import { Navbar, Nav, Container, Row, Col, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import _, { uniqueId } from 'lodash';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import TopBar from '../components/TopBar';
// import PaymentForm from '../components/PaymentForm';

import checkmark from '../assets/images/checkmark.png';
import { apiUrl } from '../utils/Utility';
import { userActions } from '../actions';
import Loader from '../components/Loader';
import SweetAlert from 'react-bootstrap-sweetalert';
import CustomModal from '../components/CustomModal';
import PaymentForm from '../components/PaymentForm';
import CheckoutForm from '../components/CheckoutForm';

const Content = styled.div`
	display: block;
	background: var(--text-primary);
	padding-bottom: 40px;
	padding-top: 40px;
`;

// const Box = styled.div`
// 	border-radius: 36px;
// 	background: var(--text-primary);
// 	box-shadow: 5px 5px 15px #00000080;
// 	padding: 46px;
// `;

// const Shadow = styled.div`
// 	border-radius: 36px;
// 	box-shadow: -5px -5px 15px #ffffff0d;
// 	width: 375px;
// 	margin: auto;
// `;

// const Highlight = styled.div`
// 	border-radius: 21px;
// 	box-shadow: -5px -5px 15px #ffffff14;
// 	margin-top: 26px;
// 	border: 2px solid var(--text-primary);
// `;

const LoaderWrap = styled.div`
	width: 100%;
	text-align: center;
	height: 200px;
	padding: 50px;
`;

const LoaderWait = styled.div`
	position: fixed;
	background: rgba(255, 255, 255, 0.25);
	height: 100vh;
	width: 100%;
	top: 0;
	right: 0;
	z-index: 1;
`;

const Footer = styled.div`
	display: block;
	background: var(--text-primary);
	padding-top: 42px;
`;

// const stripePromise = loadStripe(stripeKey);

const Plan = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const reducers = useSelector((state) => state.userReducers);

	const [btnTab1, setBtnTab1] = useState('btn-tab1 active-tab');
	const [btnTab2, setBtnTab2] = useState('btn-tab2');
	const [pck, setPck] = useState('monthly');
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [respSuccess, setRespSuccess] = useState(null);
	// const [paymentModalShow, setPaymentModalShow] = useState(false);
	// const [stripePlan, setStripePlan] = useState('plan_IVYkwDhrr7rl05');

	const [monthlyPlans, setMonthlyPlans] = useState(null);
	const [annualPlans, setAnnualPlans] = useState(null);

	const handleTab1 = () => {
		disableTab();
		setPck('monthly');
		// setStripePlan('plan_IVYrzjKtHiMZXn');
		setBtnTab1('btn-tab1 active-tab');
	};

	const handleTab2 = () => {
		disableTab();
		setPck('annualy');
		// setStripePlan('plan_IVYkwDhrr7rl05');
		setBtnTab2('btn-tab2 active-tab');
	};

	const disableTab = () => {
		setBtnTab1('btn-tab1');
		setBtnTab2('btn-tab2');
	};

	// const handleStart = () => {
	// 	setPaymentModalShow(true);
	// };

	// get plans
	const getPlans = async () => {
		await axios
			.get(`${apiUrl}/api/plans`)
			.then((resp) => {
				// console.log(resp);
				const {
					status,
					data: { plans },
				} = resp;

				if (status === 200) {
					const monthly = _.filter(plans, (plan) => plan.interval === 'month');
					const annual = _.filter(plans, (plan) => plan.interval === 'year');

					setMonthlyPlans(monthly);
					setAnnualPlans(annual);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// submit plan
	const submitPlan = async ({ planId }) => {
		const { userId } = reducers.user;
		setLoading(true);

		await axios
			.post(`${apiUrl}/api/subscribe`, {
				user_id: userId,
				plan_id: planId,
			})
			.then((resp) => {
				console.log(resp);
				const { status_code, user } = resp.data;

				if (status_code === 200) {
					// dispatch(userActions.setSubscribed());
					setRespSuccess(user);
					setLoading(false);
					setSuccess(true);
				}
			})
			.catch((error) => {
				setLoading(false);
				console.log(error);
			});
	};

	useEffect(() => {
		if (reducers.token && reducers.isSubscribed) {
			history.push('/dashboard');
		}

		if (reducers.token && !reducers.isSubscribed) {
			history.push('/plan');
		}
	}, [reducers]);

	useEffect(() => {
		getPlans();
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Container fluid>
			{/* <PaymentForm
				show={paymentModalShow}
				plan={stripePlan}
				onHide={() => setPaymentModalShow(false)}
			/> */}
			<TopBar />

			{loading && (
				<LoaderWait>
					<Loader isFixed />
				</LoaderWait>
			)}

			<Content id="pricing" className="plan-signup content">
				<Container className="seventh-content why-title">
					{/* <Nav variant="tabs" defaultActiveKey="/home"> */}
					{/* <Nav.Item>
							<Button onClick={handleTab1} className={btnTab1}>
								Monthly Plan
							</Button>
						</Nav.Item> */}
					{/* <Nav.Item>
							<Button onClick={handleTab2} className={btnTab2}>
								Annual Plan
							</Button>
						</Nav.Item> */}
					{/* </Nav> */}

					{/* from plan state */}

					<Row id="row-p">
						{monthlyPlans === null && annualPlans === null ? (
							<LoaderWrap>
								<Loader />
							</LoaderWrap>
						) : (
							<Fragment>
								{monthlyPlans !== null && pck === 'monthly' && (
									<PlanBox selectedPlan={monthlyPlans} priceText="/month" submitPlan={submitPlan} />
								)}

								{annualPlans !== null && pck === 'annualy' && (
									<PlanBox selectedPlan={annualPlans} priceText="+ Free Tablet" />
								)}
							</Fragment>
						)}
					</Row>
				</Container>
			</Content>
			<Footer>
				<Container>
					<Navbar expand="lg" className="footer main-navbar" style={{ marginTop: 100 }}>
						<Navbar id="basic-navbar-nav" style={{ margin: 'auto' }}>
							<Nav className="ml-auto mr-auto">
								<Nav.Link className="navbar-link" href="/">
									T & C
								</Nav.Link>
								<Nav.Link className="navbar-link" href="/">
									Privacy Policy
								</Nav.Link>
								<Nav.Link className="navbar-link" href="/">
									Contact
								</Nav.Link>
								<Nav.Link className="navbar-link" href="/">
									©2020 WABI
								</Nav.Link>
							</Nav>
						</Navbar>
					</Navbar>
				</Container>
			</Footer>

			<SweetAlert
				success
				show={success}
				title="Your plan has been saved!"
				onConfirm={() => {
					if (respSuccess !== null) {
						dispatch(
							userActions.updateUser({
								userId: respSuccess.id,
								email: respSuccess.email,
								stripeId: respSuccess.stripe_id,
								planId: respSuccess.plan_id,
							}),
						);
						dispatch(userActions.setSubscribed());
					}

					// props.onHide();
					// setHideAlert(false);
					// history.push('/dashboard');
				}}
			/>
		</Container>
	);
};

const PlanBox = ({ selectedPlan, priceText, submitPlan }) => {
	// state
	const [isOpen, setIsOpen] = useState(false);
        const [coupon, setCoupon] = useState('');
        const [showCouponModal, setShowCouponModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const reducers = useSelector((state) => state.userReducers);
        
        const submitCoupon = async ({ planId }) => {
		const { userId } = reducers.user;
		setLoading(true);

                await axios({
                    url: `${apiUrl}/api/coupon`,
                    method: 'POST',
                    data: {
                        coupon: coupon
                    },
                    headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${reducers.token}`,
                    }
                    }).then((resp) => {
                    if ( resp.status === 200 ) {
                        window.location.reload();
                    };
                });
	};
        
        
        
	return (
		<Fragment>
			{_.map(selectedPlan, (plan) => {
				return (
					<Col key={uniqueId()}>
						<div className="price-cont">
							<div className="price-plan">
								{/* <h4>{plan.title}</h4> */}

								<img src="/static/media/logo-white.cf348b86.png" className="img-md" alt="" />
								<div className="plan-description">{plan.description}</div>

								{plan.content_list.length > 0 &&
									_.map(plan.content_list, (list) => {
										return (
											<ul key={uniqueId()}>
												<li>
													<img
														src={checkmark}
														width="18"
														height="18"
														className="d-inline"
														style={{ marginRight: 7 }}
														alt="checker"
													/>
													{list}
												</li>
											</ul>
										);
									})}
								{/* <hr /> */}
								<span>
									<span className="price-tag">{`$${plan.price}`}</span> {priceText && priceText}
								</span>

								<Button
									onClick={() => setIsOpen(!isOpen)}
									// onClick={() => submitPlan({ planId: plan.id })}
									className="btn-start custom-button"
									style={{ paddingTop: 16, paddingBottom: 16, marginTop: 20 }}
								>
									GET STARTED
								</Button>
							</div>
						</div>
					</Col>
				);
			})}

			<CustomModal isOpen={isOpen} handleClose={setIsOpen} hasClose>
				<CheckoutForm addCouponModal={() => {setShowCouponModal(true); setIsOpen(false);}}/>
			</CustomModal>
                        <CustomModal isOpen={showCouponModal} handleClose={setShowCouponModal} hasHeader header="Coupon Code">
				<Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <input
                                                type="text"
                                                value={coupon}
                                                onChange={(e) => setCoupon(e.target.value)}
                                                placeholder="Input Coupon code..."
                                                className="form-control"
                                        />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                        <Button
                                                onClick={submitCoupon}
                                                className="btn-start custom-button"
                                                style={{ paddingTop: 16, paddingBottom: 16, marginTop: 20 }}
                                        >
                                                PROCEED
                                        </Button>
                                    </Col>
                                </Row>
			</CustomModal>
		</Fragment>
	);
};

export default Plan;
