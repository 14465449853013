import React, { useState, useRef } from 'react';
import { Modal, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useColorSlider } from '@react-aria/color';
import { useColorSliderState } from '@react-stately/color';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { useLocale } from '@react-aria/i18n';
import { useFocusRing } from '@react-aria/focus';
import { parseColor } from '@react-stately/color';

import styled from 'styled-components';

import pickerIcon from 'assets/images/booking/color-picker-icon.png';
import xIcon from 'assets/images/booking/exit-btn-table-details.png';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		outline: 'none',
	},
}));

const Wrapper = styled.div`
	width: 467px;
	height: 412px;
	background: #5b5b5b;
	border-radius: 10px;
	padding: 20px;
`;

const StyledRow = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	align-items: center;

	h4 {
		color: #ffffff;
		font-size: 16px;
		font-weight: 700;
		margin-left: 10px;
	}
`;

const CloseIcon = styled.div`
	position: absolute;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 36px;
	width: 36px;
	background: #cccccc;
	border-radius: 10px;
	cursor: pointer;
`;

const ColourOutput = styled.div`
	width: 100%;
	height: 171px;
	background: ${(props) => props.color};
	border-radius: 10px;
	margin: 20px 0;
`;

function ColorSlider(props) {
	let { locale } = useLocale();
	let state = useColorSliderState({ ...props, locale });
	let trackRef = useRef();
	let inputRef = useRef();

	// Default label to the channel name in the current locale
	let label = props.label || state.value.getChannelName(props.channel, locale);

	let { trackProps, thumbProps, inputProps, labelProps, outputProps } = useColorSlider(
		{
			...props,
			label,
			trackRef,
			inputRef,
		},
		state,
	);

	let { focusProps, isFocusVisible } = useFocusRing();

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				width: '100%',
				margin: '30px 0',
			}}
		>
			<div
				{...trackProps}
				ref={trackRef}
				style={{
					...trackProps.style,
					height: '16px',
					width: '100%',
					borderRadius: 10,
				}}
			>
				<div
					{...thumbProps}
					style={{
						...thumbProps.style,
						// top: '16px' / 2,
						border: '2px solid white',
						width: isFocusVisible ? '16px' + 4 : 30,
						height: isFocusVisible ? '16px' + 4 : 30,
						borderRadius: '50%',
						boxSizing: 'border-box',
						background: state.getDisplayColor().toString('css'),
						bottom: '-22px',
					}}
				>
					<VisuallyHidden>
						<input ref={inputRef} {...inputProps} {...focusProps} />
					</VisuallyHidden>
				</div>
			</div>
		</div>
	);
}

function ColorPickerModal(props) {
	const classes = useStyles();
	let [color, setColor] = useState(parseColor('hsl(180, 100%, 50%)'));

	const handleClose = () => {
		props.setOpenColorPicker(!props.openColorPicker);
		props.changeColorCallback(color);
	};

	return (
		<Modal
			aria-labelledby="create-wage-pay-item"
			aria-describedby="create-wage-pay-item-description"
			className={classes.modal}
			open={props.openColorPicker}
			onClose={handleClose}
			hideBackdrop
		>
			<Fade in={props.openColorPicker}>
				<div className={classes.paper}>
					<Wrapper>
						<StyledRow>
							<img src={pickerIcon} width={26} height={26} alt="colour-picker-icon" />
							<h4>CHOOSE A COLOUR</h4>
							<CloseIcon onClick={handleClose}>
								<img src={xIcon} width={12} height={12} alt="close-icon" />
							</CloseIcon>
						</StyledRow>
						<ColourOutput
							style={{ background: `hsl(${color.hue}, ${color.saturation}%, ${color.lightness}%)` }}
						/>
						<ColorSlider channel="hue" value={color} onChange={setColor} />
						<ColorSlider channel="lightness" value={color} onChange={setColor} />
						<ColorSlider channel="alpha" value={color} onChange={setColor} />
					</Wrapper>
				</div>
			</Fade>
		</Modal>
	);
}

export default ColorPickerModal;
