import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import xIcon from 'assets/images/booking/xIcon.png';

const Div = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: ${(props) => props.width}px;
	margin: 0 15px;
	border-radius: 0.5em;
	background: rgba(56, 63, 67, 0.48);
	border: 5px solid #222a2f;
	box-shadow: 5px 5px 10px #1a2024, -5px -5px 10px #2a343a;
	cursor: pointer;
	overflow: auto;
	::-webkit-scrollbar {
		width: 1px;
		height: 5px;
		background-color: #222a2f;
	}
	::-webkit-scrollbar-thumb {
		width: 1px;
		height: 1px;
		border-radius: 20px;
		background-color: #2a343a;
	}
	::-webkit-scrollbar-corner {
		display: none;
	}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: ${(props) => props.width}px;
	height: 100%;
	border-radius: 0.5em;
	background: ${(props) => props.color};
	// cursor: pointer;
	resize: verical;
	// z-index: 1;

	#del-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		background: #ffffffa6;
		border-radius: 0.8em;
		border: 1px solid #fffff;
		cursor: pointer;
		z-index: 1;
		&:hover {
			background: #ffffffde;
		}
	}

	img {
		-webkit-user-drag: none;
		-khtml-user-drag: none;
		-moz-user-drag: none;
		-o-user-drag: none;
		user-drag: none;
	}

	#n-resizer-container${(props) => props.identifier} {
		display: flex;
		justify-content: center;
		position: absolute;
		top: 0px;
		cursor: n-resize;
		height: 10px;
		width: 100%;
		z-index: 1;
	}

	#s-resizer-container${(props) => props.identifier} {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		position: absolute;
		bottom: 0px;
		cursor: s-resize;
		height: 10px;
		width: 100%;
		z-index: 1;
	}

	#nresizer {
		border-radius: 0.8em;
		background: white;
		width: 22px;
		height: 4px;
		cursor: grab;
	}

	#sresizer {
		border-radius: 0.8em;
		background: white;
		width: 22px;
		height: 4px;
	}

	#dragging {
		position: absolute;
		height: 70%;
		width: 100%;
		cursor: grab;
	}
`;

function ShiftItem(props) {
	const toggleChange = (id, e) => {
		e.stopPropagation();
		if (props.item !== undefined) {
			props.handleChangeDay(id);
		} else {
			props.handleChangeDay(id);
		}
	};

	// for resize
	let resizing = false;
	let isNorth = true;
	let prevY = 0;
	let prevBot = 0;
	let prevTop = 0;
	let prevComputedpxl = 0;
	const elem1 = document.getElementById(props.id + 'item-container1');
	const elem2 = document.getElementById(props.id + 'item-container2');
	const elem3 = document.getElementById(props.id + 'item-container3');
	const elem4 = document.getElementById(props.id + 'item-container4');
	const elem5 = document.getElementById(props.id + 'item-container5');
	const elem6 = document.getElementById(props.id + 'item-container6');
	const elem7 = document.getElementById(props.id + 'item-container7');
	const parent = document.getElementById('field');
	// console.log(elem1, elem2, elem3, elem4, elem5, elem6, elem7);

	let pixel = [];
	let n = 10;
	for (let i = 0; i <= 922; i += n) {
		pixel.push(i);
	}

	function mouseDown(e) {
		e.preventDefault();
		e.stopPropagation();
		resizing = true;
		document.addEventListener('mousemove', mouseMove);
		document.addEventListener('mouseup', mouseUp);
		if (e.target.id.includes('n-resize')) {
			// console.log('yes');
			isNorth = true;
		} else if (e.target.id.includes('s-resize')) {
			// console.log('no');
			isNorth = false;
		}
		let container = parent.getBoundingClientRect();
		prevY = e.clientY;
		prevBot = props.top + props.height;
		prevTop = props.top;
		// console.log(e);
		// console.log(props.id);
	}

	function mouseMove(e) {
		if (resizing) {
			// e.preventDefault();
			// e.stopPropagation();
			let newY = e.clientY;
			if (isNorth) {
				resizeNorth(newY);
			} else {
				resizeSouth(newY);
			}
		} else if (isDragging) {
			return;
		}
	}

	function resizeNorth(newY) {
		let value = newY - prevY;
		let int = Math.abs(value);
		let pxl;
		pixel.filter((v) => {
			if (v === int) {
				if (value > 0) {
					pxl = -v;
				} else if (value < 0) {
					pxl = v;
				} else if (value === 0) {
					pxl = 0;
				}
			}
		});
		if (pxl !== undefined) {
			prevComputedpxl = pxl;
		}
		// elem1.style.bottom = prevBot - prevContainerTop + 'px';

		if (elem1 !== null) {
			elem1.style.top = prevTop - prevComputedpxl + 'px';
			elem1.style.height = props.height + prevComputedpxl + 'px';
		}
		if (elem2 !== null) {
			elem2.style.top = prevTop - prevComputedpxl + 'px';
			elem2.style.height = props.height + prevComputedpxl + 'px';
		}
		if (elem3 !== null) {
			elem3.style.top = prevTop - prevComputedpxl + 'px';
			elem3.style.height = props.height + prevComputedpxl + 'px';
		}
		if (elem4 !== null) {
			elem4.style.top = prevTop - prevComputedpxl + 'px';
			elem4.style.height = props.height + prevComputedpxl + 'px';
		}
		if (elem5 !== null) {
			elem5.style.top = prevTop - prevComputedpxl + 'px';
			elem5.style.height = props.height + prevComputedpxl + 'px';
		}
		if (elem6 !== null) {
			elem6.style.top = prevTop - prevComputedpxl + 'px';
			elem6.style.height = props.height + prevComputedpxl + 'px';
		}
		if (elem7 !== null) {
			elem7.style.top = prevTop - prevComputedpxl + 'px';
			elem7.style.height = props.height + prevComputedpxl + 'px';
		}

		let top = prevTop - prevComputedpxl;
		let height = props.height + prevComputedpxl;
		props.resizeCallbackHandler(top, top + height);
	}

	function resizeSouth(newY) {
		let value = newY - prevY;
		let int = Math.abs(value);
		let top;
		let pxl;
		pixel.filter((v) => {
			if (v === int) {
				if (value > 0) {
					pxl = -v;
				} else if (value < 0) {
					pxl = v;
				} else if (value === 0) {
					pxl = 0;
				}
			}
		});
		// elem1.style.top = prevBot - prevContainerTop + 'px';
		if (pxl !== undefined) {
			prevComputedpxl = pxl;
		}
		if (elem1 !== null) {
			elem1.style.height = props.height - prevComputedpxl + 'px';
			elem1.style.bottom = prevBot - prevComputedpxl + 'px';
		}
		if (elem2 !== null) {
			elem2.style.height = props.height - prevComputedpxl + 'px';
			elem2.style.bottom = prevBot - prevComputedpxl + 'px';
		}
		if (elem3 !== null) {
			elem3.style.height = props.height - prevComputedpxl + 'px';
			elem3.style.bottom = prevBot - prevComputedpxl + 'px';
		}
		if (elem4 !== null) {
			elem4.style.height = props.height - prevComputedpxl + 'px';
			elem4.style.bottom = prevBot - prevComputedpxl + 'px';
		}
		if (elem5 !== null) {
			elem5.style.height = props.height - prevComputedpxl + 'px';
			elem5.style.bottom = prevBot - prevComputedpxl + 'px';
		}
		if (elem6 !== null) {
			elem6.style.height = props.height - prevComputedpxl + 'px';
			elem6.style.bottom = prevBot - prevComputedpxl + 'px';
		}
		if (elem7 !== null) {
			elem7.style.height = props.height - prevComputedpxl + 'px';
			elem7.style.bottom = prevBot - prevComputedpxl + 'px';
		}

		let bottom = prevBot - prevComputedpxl;

		props.resizeCallbackHandler(props.top, bottom);
	}

	function mouseUp(e) {
		document.removeEventListener('mousemove', mouseMove);
		document.removeEventListener('mouseup', mouseUp);
		resizing = false;
	}

	// on drag

	let isDragging = false;
	let prevDy;

	function onDragStart(e) {
		document.addEventListener('mousemove', onDragMove);
		document.addEventListener('mouseup', onDragEnd);
		// console.log(e.target.id);
		isDragging = true;
		prevDy = e.pageY;
		e.target.style.cursor = 'grabbing';
	}

	function onDragMove(e) {
		if (isDragging) {
			let value = prevDy - e.clientY;
			let int = Math.abs(value);
			let pxl;
			pixel.filter((v) => {
				if (v === int) {
					if (value > 0) {
						pxl = -v;
					} else if (value < 0) {
						pxl = v;
					} else if (value === 0) {
						pxl = 0;
					}
				}
			});
			if (pxl !== undefined) {
				prevComputedpxl = pxl;
			}
			if (elem1 !== null) {
				elem1.style.top = props.top + prevComputedpxl + 'px';
			}
			if (elem2 !== null) {
				elem2.style.top = props.top + prevComputedpxl + 'px';
			}
			if (elem3 !== null) {
				elem3.style.top = props.top + prevComputedpxl + 'px';
			}
			if (elem4 !== null) {
				elem4.style.top = props.top + prevComputedpxl + 'px';
			}
			if (elem5 !== null) {
				elem5.style.top = props.top + prevComputedpxl + 'px';
			}
			if (elem6 !== null) {
				elem6.style.top = props.top + prevComputedpxl + 'px';
			}
			if (elem7 !== null) {
				elem7.style.top = props.top + prevComputedpxl + 'px';
			}
			props.dragShiftCallback(
				props.top + prevComputedpxl,
				props.top + props.height + prevComputedpxl,
			);
		}
	}

	function onDragEnd(e) {
		document.removeEventListener('mousemove', onDragMove);
		document.removeEventListener('mouseup', onDragEnd);
		e.target.style.cursor = 'grab';
		// console.log('stop');
	}

	return (
		<Div
			width={props.columnWidth - 30}
			id={props.id + props.containerId}
			style={
				props.zIndex !== undefined
					? {
							position: 'absolute',
							textAlign: 'center',
							top: `${props.top}px`,
							height: `${props.height}px`,
							zIndex: props.zIndex,
							// resize: 'vertical',
					  }
					: {
							position: 'absolute',
							textAlign: 'center',
							top: `${props.top}px`,
							height: `${props.height}px`,
							// resize: 'vertical',
					  }
			}
		>
			<Wrapper
				width={props.columnWidth - 30}
				color={props.color}
				style={props.isShiftselected ? { border: '1px solid white' } : {}}
				identifier={props.id}
				// tabIndex="0"
				// onFocus={props.onFocus}
			>
				{props.isShiftselected ? (
					<>
						<div id={'n-resizer-container' + props.id} onMouseDown={mouseDown}>
							<div id="nresizer" />
						</div>

						<span
							id="del-icon"
							style={{ width: '40px', height: '40px' }}
							onClick={(e) => toggleChange(props.containerId, e)}
						>
							<img
								src={xIcon}
								width={18}
								height={18}
								style={props.day ? { transform: 'rotate(45deg)' } : {}}
							/>
						</span>
						<div
							id="dragging"
							style={{ position: 'absolute', height: '60%', width: '100%', cursor: 'grab' }}
							onMouseDown={onDragStart}
						></div>
						<div id={'s-resizer-container' + props.id} onMouseDown={mouseDown}>
							<div id="sresizer" />
						</div>
					</>
				) : (
					props.name
				)}
			</Wrapper>
		</Div>
	);
}

export default ShiftItem;
