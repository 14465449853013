import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import styled from 'styled-components';
import { imgUrl } from 'utils/Utility';

import xIcon from 'assets/images/booking/exit-btn-table-details.png';
import wabiIcon from 'assets/images/booking/user-roles/wabi-icon.png';
import DotLoader from '../../../TableLayoutPlanning/pages/SubComponents/DotLoader';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		outline: 'none',
	},
}));

const StyledBackdrop = withStyles({
	root: {
		background: 'rgb(255 255 255 / 30%);',
		backdropFilter: 'blur(10px)',
	},
})(Backdrop);

const Wrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 807px;
	height: 596px;
	background: #f5f5f5;
	border-radius: 10px;
	box-shadow: 0px 1px 10px #1c1c1e4d;

	#empl-modal-row1 {
		display: flex;
		flex-direction: row;
		width: 750px;
		margin: 25px 25px 15px 25px;
	}

	#empl-modal-row2 {
		display: flex;
		flex-direction: row;
		width: 750px;
		margin: 5px 25px 0 25px;
	}
`;

const CloseButton = styled.div`
	position: absolute;
	right: 15px;
	top: 15px;
	cursor: pointer;
	z-index: 1;

	img {
		-webkit-user-drag: none;
		-khtml-user-drag: none;
		-moz-user-drag: none;
		-o-user-drag: none;
		user-drag: none;

		&:hover {
			filter: opacity(0.5);
		}
	}
`;

const ConentContainer = styled.div`
	position: relative;
	display: flex;
	width: 433px;
	height: 234px;
	background: #ffffff;
	border-radius: 10px;
	box-shadow: 0px 1px 10px #1c1c1e4d;
	padding: 10px 5px;
	cursor: pointer;

	label {
		font-size: 14px;
		font-weight: 700;
		color: #1c1c1e;
	}

	h4 {
		font-size: 12px;
		font-weight: 700;
		color: #1c1c1e;
	}
`;

const ContentContainer2 = styled(ConentContainer)`
	width: 292px;
	height: 233px;
	margin-left: 25px;
	cursor: default;
`;

const ContentContainer3 = styled(ConentContainer)`
	width: 434px;
	height: 287px;
	cursor: default;
`;

const ContentContainer4 = styled(ConentContainer)`
	flex-direction: column;
	width: 287px;
	height: 285.5px;
	margin-left: 25px;
	cursor: default;
	padding: 20px 30px;

	span {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 80%;
		margin: 20px 0;
	}
	label {
		margin-bottom: 20px;
	}
`;

const StyledRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	width: 215px;
	margin: 0;
	padding: 0;
`;

const LogoContainer = styled.div`
	position: absolute;
	right: 30px;
	top: 20px;
`;

const PicContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 148px;
	width: 148px;
	border-radius: 50%;
	box-shadow: 0px 1px 10px #1c1c1e4d;
	font-size: 33px;
	font-weight: 800;
	box-shadow: 0px 1px 2px #000000;
	cursor: pointer;

	img {
		border-radius: 50%;
		aspect-ratio: auto 4/3;
		z-index: 1;
		user-select: none;
		-webkit-user-drag: none;
		-khtml-user-drag: none;
		-moz-user-drag: none;
		-o-user-drag: none;
		user-drag: none;
	}
`;

const NavListButton = styled.ul`
	margin: 0;
	padding: 0;
	position: absolute;
	bottom: 3px;
	left: 210px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 30px;
	list-style: none;
	transition: ease 200ms;

	li {
		height: 10px;
		width: 10px;
		border-radius: 50%;
		background: #7c7c7c;
		margin: 5px;
		cursor: pointer;
	}

	#emplM-selected {
		background: #4594ff;
		transition: ease 500ms;
	}
`;

const Hcontainer = styled.div`
	padding: 85px 0 15px 0;

	h4 {
		font-size: 15px;
		font-weight: bold;
		color: #505050;
	}
`;

const DateHired = styled.div`
	position: absolute;
	right: 15px;
	bottom: 20px;
	font-size: 10px;
	font-weight: bold;
`;

function EmployeeModal(props) {
	const classes = useStyles();

	const handleClose = () => {
		props.toggleEmployeeModal(!props.isEmployeeModalOpen);
	};

	const openEmployeeDetails = () => {
		handleClose();
		props.toggleEmployeeDetailsModal();
		props.setIsAdding(false);
	};

	return (
		<div>
			<Modal
				aria-labelledby="employee-modal"
				aria-describedby="employee-modal-description"
				className={classes.modal}
				open={props.isEmployeeModalOpen}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={StyledBackdrop}
			>
				<Fade in={props.isEmployeeModalOpen}>
					<div className={classes.paper}>
						<Wrapper>
							<CloseButton onClick={handleClose}>
								<img src={xIcon} width={12} height={12} alt="close-icon" />
							</CloseButton>
							<div id="empl-modal-row1">
								<ConentContainer onClick={openEmployeeDetails}>
									<LogoContainer>
										<img src={wabiIcon} width={57} height={16} alt="close-icon" />
									</LogoContainer>
									{props.selectedEmployee ? (
										<StyledRow>
											{props.selectedEmployee.photo ? (
												<PicContainer>
													<img
														src={imgUrl + props.selectedEmployee.photo}
														width={148}
														height={148}
														alt="profile-pic"
													/>
												</PicContainer>
											) : (
												<PicContainer style={{ background: `${props.selectedEmployee.color}` }}>
													{props.selectedEmployee.first_name.charAt(0).toUpperCase()}
													{props.selectedEmployee.last_name.charAt(0).toUpperCase()}
												</PicContainer>
											)}
										</StyledRow>
									) : (
										''
									)}

									<Hcontainer>
										<h4>
											{props.selectedEmployee.first_name} {props.selectedEmployee.last_name}
										</h4>
										<h4>{props.selectedEmployee.address}</h4>
										<h4>{props.selectedEmployee.mobile_no}</h4>
									</Hcontainer>
									<DateHired>Hired august 7th 2021</DateHired>
								</ConentContainer>
								<ContentContainer2></ContentContainer2>
							</div>
							<div id="empl-modal-row2">
								<ContentContainer3></ContentContainer3>
								<ContentContainer4>
									<label>All sold items</label>
									{props.isCardUpdating ? (
										<div style={{ position: 'relative', width: '100%', height: '100%' }}>
											<DotLoader />
										</div>
									) : (
										<>
											{props.employeeReports.sold_items
												? props.employeeReports.sold_items.map((e, key) => {
														return (
															<div className="employee-sold-items" key={key}>
																<span>
																	<h4>Aperol Sprits</h4>
																	<h4>41</h4>
																	<h4>$902</h4>
																</span>
																<span>
																	<h4>Aperol Sprits</h4>
																	<h4>41</h4>
																	<h4>$902</h4>
																</span>
																<span>
																	<h4>Aperol Sprits</h4>
																	<h4>41</h4>
																	<h4>$902</h4>
																</span>
															</div>
														);
												  })
												: ''}

											{props.employeeReports.deleted_items
												? props.employeeReports.deleted_items.map((e, key) => {
														return (
															<div className="employee-deleted-items" key={key}>
																<h4 style={{ marginTop: 5 }}>Deleted Items</h4>
																<span style={{ paddingLeft: 10 }}>
																	<h4>Corrections</h4>
																	<h4>41</h4>
																	<h4>$902</h4>
																</span>
															</div>
														);
												  })
												: ''}
										</>
									)}
								</ContentContainer4>
							</div>
							<NavListButton>
								<li id="emplM-selected" />
								<li />
								<li />
							</NavListButton>
						</Wrapper>
					</div>
				</Fade>
			</Modal>
		</div>
	);
}

export default EmployeeModal;
