import { Box, TextField } from '@material-ui/core';
import axios from 'axios';
import moment from 'moment';
import SelectBookingCalendar from 'pages/Dashboard/Booking/BookingCalendar/SelectBookingCalendar';
import BookingCalendarArrow from 'pages/Dashboard/Booking/BookingCalendarArrow/BookingCalendarArrow';
import ShiftIndicator from 'pages/Dashboard/Booking/CreatBooking/shift-indicator/shift-indicator';
import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { formatDate } from 'react-day-picker/moment';
import { useSelector } from 'react-redux';
import { cancel } from 'redux-saga/effects';

import {
	filterShifts,
	getCurrentShift,
	apiUrl,
	stringReplaced,
	capitalized,
	toCurrency,
} from 'utils/Utility';

import logoblack from '../../../../assets/images/logo.png';

function DailyViewSales() {
	const useMountEffect = (fun) => useEffect(fun, []);
	const [isEdit, setIsEdit] = useState(false);
	const reducers = useSelector((state) => state.userReducers);

	const isFirstRender = useRef(true);

	const shifts = reducers.shifts;
	const [filteredShifts, setFilteredShifts] = useState([]);
	const [selectedShift, setSelectedShift] = useState(null);
	const [selectedDateChanged, setSelectedDateChanged] = useState(false);
	const [selectedDate, setSelectedDate] = useState(formatDate(moment(), 'YYYY-MM-DD'));
	const [dateChangeHandler, setDateChangeHandler] = useState(false);
	const [salesData, setSalesData] = useState(null);
	const [openBookingCalendar, setOpenBookingCalendar] = useState(false);

	const [copySales, setCopySales] = useState(null);
	const [editSales, setEditedSales] = useState({});

	const beginEdit = (e) => {
		setIsEdit(!isEdit);
	};

	const cancelEdit = (e) => {
		setSalesData({
			...salesData,
			sales: Object.assign({}, copySales), // hard copy
		});
		setEditedSales({});

		setIsEdit(false);
	};

	const handleOpenSelectedBookingCalendar = () => {
		if (!openBookingCalendar) {
			setOpenBookingCalendar(true);
		}
	};

	const handleCloseSelectedBookingCalendar = () => {
		if (openBookingCalendar) {
			setOpenBookingCalendar(false);
		}
	};

	const saveEdit = (e) => {
		console.log('Begin Save Edit');
		beginSave();
	};

	const changeShift = () => {
		let idx = filteredShifts.findIndex((e) => e.id === selectedShift.id);
		if (idx + 1 >= filteredShifts.length) {
			idx = 0;
		} else {
			idx += 1;
		}

		let shift = filteredShifts[idx];
		setSelectedShift(shift);
		getDailySales(shift.id);
	};

	const beginSave = async () => {
		try {
			const response = await axios({
				url: `${apiUrl}/api/pos/save_daily_sales`,
				method: 'POST',
				data: {
					shift_id: selectedShift.id,
					date: formatDate(selectedDate, 'YYYY-MM-DD'),
					device_id: 0,
					data: editSales,
				},
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${reducers.token}`,
				},
			});

			if (response) {
				const { status } = response;
				const { data } = response.data;

				if (status === 200) {
					setEditedSales({});
					setIsEdit(false);

					getDailySales(selectedShift.id);
				}
			}
		} catch (err) {
			console.log('Error: ', err);
		}
	};

	const currentShift = () => {
		const filteredShiftsData = filterShifts(shifts, moment(selectedDate).isoWeekday());
		const shift = getCurrentShift(
			filteredShiftsData.length == 0 ? [] : filteredShiftsData,
			selectedDate,
		);
		setFilteredShifts(filteredShiftsData);
		setSelectedShift(shift);
		getDailySales(shift.id);
	};

	const getDailySales = async (id) => {
		try {
			const response = await axios({
				url: `${apiUrl}/api/pos/daily_sales`,
				method: 'POST',
				data: {
					date: formatDate(selectedDate, 'YYYY-MM-DD'),
					shift_id: id,
					device_id: 0,
				},
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${reducers.token}`,
				},
			});

			if (response) {
				const { status } = response;
				const { data } = response.data;
				if (status == 200) {
					console.log(data);
					setSalesData(data);

					setCopySales(Object.assign({}, data.sales));
				}
			}
		} catch (err) {
			console.log('Error: ', err);
		}
	};

	// Text Field on Change
	const onChangeValue = (value, key) => {
		const val = value.target.value;
		const data = salesData;

		data.sales[key] = parseFloat(val);
		setSalesData(data);

		editSales[key] = {
			from_amount: copySales[key],
			to_amount: parseFloat(val),
		};
		setEditedSales(editSales);
		console.log(editSales);
	};

	useMountEffect(() => {
		console.log('Effect: Daily View Sales');
		currentShift();
	}, []);

	useEffect(() => {
		if (isFirstRender && selectedShift == null) {
			isFirstRender.current = false;
			return;
		}
		console.log('Selected Shift');
		currentShift();
	}, [selectedDate]);

	return (
		<Fragment>
			<Container>
				<div className="date-shift-selector">
					<BookingCalendarArrow
						arrowType="left"
						setSelectedDateChanged={setSelectedDateChanged}
						selectedDate={selectedDate}
						setSelectedDate={setSelectedDate}
						setDateChangeHandler={setDateChangeHandler}
					/>
					<div onClick={() => handleOpenSelectedBookingCalendar()} className="date-selector">
						{formatDate(selectedDate, 'dddd, DD MMM')}
					</div>
					<SelectBookingCalendar
						selectedDate={selectedDate}
						openBookingCalendar={openBookingCalendar}
						setSelectedDateHandler={setSelectedDate}
						setSelectedDateChanged={setSelectedDateChanged}
						handleCloseSelectedBookingCalendar={handleCloseSelectedBookingCalendar}
						setDateChangeHandler={setDateChangeHandler}
					/>
					<ShiftIndicator
						selectedShift={selectedShift}
						setSelectedDateChanged={setSelectedDateChanged}
						selectedDate={selectedDate}
						setSelectedDate={setSelectedDate}
					/>
					<div onClick={changeShift} className="shift-selector">
						{selectedShift !== null ? selectedShift.name : 'No Shift'}
					</div>
					<BookingCalendarArrow
						arrowType="right"
						setSelectedDateChanged={setSelectedDateChanged}
						selectedDate={selectedDate}
						setSelectedDate={setSelectedDate}
						setDateChangeHandler={setDateChangeHandler}
					/>
				</div>
				<div className="daily-view-sales">
					<div className="main-container">
						<div className="logo-header">
							<img
								alt="logo"
								src={logoblack}
								width="80"
								height="24"
								className="d-inline-block align-top"
								style={{ verticalAlign: 'middle' }}
							/>
						</div>
						<div className="sales-header">Sales</div>

						<div
							style={{
								overflowY: 'auto',
								height: 200,
							}}
						>
							{salesData !== null
								? Object.keys(salesData.sales).map((key) => {
										if (key !== 'total_sales')
											if (key === 'customers' || key === 'sales_per_customer') {
												return (
													<div key={key} className="main-items">
														<Row>
															<Col>{capitalized(stringReplaced(key))}</Col>
															<Col
																style={{
																	textAlign: 'right',
																}}
															>
																{key !== 'customers'
																	? toCurrency(salesData.sales[key])
																	: salesData.sales[key]}
															</Col>
														</Row>
													</div>
												);
											} else {
												return (
													<div key={key} className="main-items">
														<Row>
															<Col>{capitalized(stringReplaced(key))}</Col>
															{!isEdit ? (
																<Col
																	style={{
																		textAlign: 'right',
																	}}
																>
																	{key !== 'customers'
																		? toCurrency(salesData.sales[key])
																		: salesData.sales[key]}
																</Col>
															) : (
																<Box>
																	<TextField
																		onChange={(e) => onChangeValue(e, key)}
																		hiddenLabel
																		type="text"
																		inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
																		defaultValue={salesData.sales[key]}
																	></TextField>
																</Box>
															)}
														</Row>
													</div>
												);
											}
								  })
								: `Loading Data`}
						</div>
						<div className="total-items">
							<Row>
								<Col>Total Sales</Col>
								<Col
									style={{
										textAlign: 'right',
									}}
								>
									{salesData !== null ? toCurrency(salesData.sales.total_sales) : toCurrency(0)}
								</Col>
							</Row>
						</div>
					</div>
				</div>
				<div
					onClick={!isEdit ? beginEdit : saveEdit}
					className={!isEdit ? `check-btn` : `check-btn--save`}
				>
					{!isEdit ? `Check` : 'Save'}
				</div>
				<div onClick={cancelEdit} className={`check-btn--cancel ${!isEdit ? 'hide-btn' : ''}`}>
					Cancel
				</div>
			</Container>
		</Fragment>
	);
}

export default DailyViewSales;
