import Header from 'components/Revamp/Header';
import Footer from 'components/Revamp/Footer';
import { Col, Container, Row } from 'react-bootstrap';

import styled from 'styled-components';
import { down } from 'styled-breakpoints';

const HomePageHero = {
	Wrapper: styled.div`
		background-color: #f5f5f7;
		padding-top: 5.5rem;
		padding-bottom: 5.5rem;
	`,

	TextWrap: styled.div`
		position: relative;
		${down('sm')} {
			padding: 2rem;
		}
	`,
	Button: styled.div`
		position: absolute;
		right: 45%;
		bottom: 10px;
		${down('sm')} {
			position: unset;
			text-align: center;
			margin-top: 1rem;
			margin-bottom: 1rem;
		}
	`,
	Text: styled.div`
		font-family: sofia-pro;
		font-size: 45px;
		font-weight: 600;
		margin-top: 2rem;
		color: #1d1d1f;
	`,
	GradientWrap: styled.div`
		font-size: 50px;
		font-weight: 600;
		font-family: sofia-pro;
		text-align: center;
		margin-top: 15rem;
		margin-bottom: 10rem;
	`,
};

const Terms = () => {
	return (
		<div>
			<Header isHome />
			<HomePageHero.Wrapper>
				<Container className="fluid-max-width" style={{ padding: '0 2rem' }} fluid>
					<p>
						<h1>Terms of Use</h1>
					</p>
					<p>&nbsp;</p>
					<p>
						<span style={{ fontWeight: '400' }}>These Wabi Terms of Use (this &ldquo;</span>
						<strong>Agreement</strong>
						<span style={{ fontWeight: '400' }}>
							&rdquo;) apply to your access or use of the Wabi websites, mobile sites, and
							applications (collectively, the &ldquo;
						</span>
						<strong>Wabi Sites</strong>
						<span style={{ fontWeight: '400' }}>
							&rdquo;) and the content, features, and services (the &ldquo;
						</span>
						<strong>Services</strong>
						<span style={{ fontWeight: '400' }}>&rdquo;) made available by Wabi.</span>
					</p>
					<p>
						<span style={{ fontWeight: '400' }}>In this Agreement, &ldquo;</span>
						<strong>Wabi</strong>
						<span style={{ fontWeight: '400' }}>&rdquo; and &ldquo;</span>
						<strong>we</strong>
						<span style={{ fontWeight: '400' }}>
							&rdquo; mean Wabi Pty Ltd, the company that is providing the Services to you, and with
							whom you are entering into this Agreement, which depends on the country in which you
							reside.
						</span>
					</p>
					<p>
						<span style={{ fontWeight: '400' }}>The terms &ldquo;</span>
						<strong>User</strong>
						<span style={{ fontWeight: '400' }}>&rdquo; and &ldquo;</span>
						<strong>you</strong>
						<span style={{ fontWeight: '400' }}>
							&rdquo; mean any user of the Services. This Agreement incorporates Wabi&rsquo;s
							standard policies, procedures, and terms and conditions for use of the Services that
							are referenced by name or by links in this Agreement (collectively, the &ldquo;
						</span>
						<strong>Wabi Policies</strong>
						<span style={{ fontWeight: '400' }}>&rdquo;).</span>
					</p>
					<p>
						<span style={{ fontWeight: '400' }}>
							By accessing or using the Services or by clicking &ldquo;accept&rdquo; or
							&ldquo;agree&rdquo; to this Agreement, (1) you acknowledge that you have read,
							understand, and agree to be bound by this Agreement, and (2) you represent and warrant
							that you are of legal age and not prohibited by law from accessing or using the
							Services (including, if applicable, purchasing, giving, using, or redeeming any Wabi
							Gift Card or Merchant Gift Card (as such terms are defined herein)).&nbsp;
						</span>
					</p>
					<p>
						<span style={{ fontWeight: '400' }}>
							Wabi may update or revise this Agreement (including any Wabi Policies) from time to
							time. You agree that you will review this Agreement periodically. You are free to
							decide whether or not to accept a modified version of this Agreement, but accepting
							this Agreement, as modified, is required for you to continue using the Services. You
							may have to click &ldquo;accept&rdquo; or &ldquo;agree&rdquo; to show your acceptance
							of any modified version of this Agreement. If you do not agree to the terms of this
							Agreement or any modified version of this Agreement, you must terminate your use of
							the Services, in which case you will no longer have access to your Account (as defined
							below). Except as otherwise expressly stated by Wabi, any use of the Services (e.g.,
							the use of the Reservation Services (as defined herein), Waitlist Services (as defined
							herein), Payment Services (as defined herein), or the purchase of Wabi Gift Card or
							Merchant Gift Card (each, as defined herein)) is subject to the version of this
							Agreement in effect at the time of use.
						</span>
					</p>
					<p>&nbsp;</p>
					<p>
						<strong>Part I - Reservation Services</strong>
					</p>
					<hr></hr>
					<ol>
						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Restaurant Reservations</strong>
							<span style={{ fontWeight: '400' }}>
								. Wabi makes available restaurant reservation services (the &ldquo;
							</span>
							<strong>Reservation Services</strong>
							<span style={{ fontWeight: '400' }}>&rdquo;) and waitlist services (the &ldquo;</span>
							<strong>Waitlist Services</strong>
							<span style={{ fontWeight: '400' }}>
								&rdquo;) through the Wabi Sites to User for the purpose of assisting User in
								securing dining reservations or joining a waitlist at participating third-party
								restaurants (each, a &ldquo;
							</span>
							<strong>Restaurant</strong>
							<span style={{ fontWeight: '400' }}>
								&rdquo;). In response to a User&rsquo;s online request for a Restaurant reservation
								or to join a waitlist through the Wabi Sites, Wabi contacts the Restaurant&rsquo;s
								computerised database of reservations and waitlists, as applicable. The availability
								of reservations, estimated wait time or place in line on a waitlist is determined at
								the time of User&rsquo;s query and based on information provided to Wabi by the
								Restaurant. Once a reservation or waitlist request is made by User through the Wabi
								Sites, Wabi will provide confirmation of the reservation or waitlist status to User
								by email or other electronic messages as has been agreed to by User. By using the
								Reservation Services or Waitlist Services, User agrees to receive reservation and
								waitlist confirmations, updates, modifications and/or cancellations by email or
								other electronic messages.
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>No-Show Policy</strong>
							<span style={{ fontWeight: '400' }}>
								. Wabi is committed to providing superior quality services to Users and Restaurants.
								To assist us in maintaining a consistently high level of service for the Restaurants
								and their patrons, Users must cancel any reservations that they will be unable to
								honour at least 30 minutes in advance of the reservation. You may cancel your
								reservation via the Wabi Sites or by calling the Restaurant directly. Some
								Restaurants may require a debit or credit card number to finalise your
								reservation.&nbsp; In order to use the Reservation Services for these Restaurants,
								you must provide valid debit or credit card information. To confirm that the debit
								or credit card information you have provided is accurate, we may place a temporary
								authorisation on your debit or credit card at the time you provide your debit or
								credit card information. After we verify that your debit or credit card information
								is accurate, usually within a few days, the authorisation will be removed. You may
								be required to cancel your reservation in accordance with the Restaurant&rsquo;s
								cancellation policy, which is disclosed at the time the reservation is made. Wabi
								uses this debit or credit card information as described in our&nbsp;privacy
								policy&nbsp;and shall have no liability for any charges made to the debit or credit
								card account for any failure to cancel your reservation in accordance with a
								Restaurant&rsquo;s cancellation policy.
							</span>
						</li>

						<p>
							<span style={{ fontWeight: '400' }}>
								If you are unable to keep your reservation and you fail to cancel at least 30
								minutes in advance of the reservation, Wabi will send you an email letting you know
								that our records indicate that you were a no-show. Upon arriving at the Restaurant,
								it is the User&rsquo;s responsibility to notify the host that the User has a
								reservation. By using the Reservation Services, User agrees to receive no-show
								notifications by email after a report that your reservation was not honoured,
								whether or not that was in fact the case. Your Account will be suspended if you are
								a no-show for four reservations within a 12-month period. User agrees that all final
								no-show determinations will be made by Wabi in its sole discretion.
							</span>
						</p>
						<p>
							<span style={{ fontWeight: '400' }}>
								If you wish to remove yourself from a waitlist you have joined through the Waitlist
								Services, you can do so by managing your place in line through the Wabi Sites or by
								calling the Restaurant. Failure to appear at the Restaurant in a timely manner may
								result in the Restaurant bypassing your place on the waitlist for other guests or
								removing you from the waitlist entirely.
							</span>
						</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Usage Guidelines</strong>
							<span style={{ fontWeight: '400' }}>
								. User agrees to use the Reservation Services or Waitlist Services only to book
								reservations or join waitlists at Restaurants and then honour those reservations or
								waitlist requests by arriving at the Restaurants on time and ordering and paying for
								meals. User further agrees not to book more than one reservation or join more than
								one waitlist for User&rsquo;s personal use during any one meal time (e.g., lunch,
								dinner, etc.). Administrative assistant and/or concierges may be able to book
								multiple reservations through Wabi&rsquo;s Administrative Assistant and/or Concierge
								programs, subject to any applicable program terms. Resale or attempted resale of
								reservations or waitlist spots is prohibited and is grounds for, among other things,
								cancellation of your reservations, removal from the waitlist or termination of your
								access to the Services.&nbsp;
							</span>
						</li>
					</ol>
					<p>&nbsp;</p>
					<p>
						<strong>Part II &ndash; Payment, Gift Card and Other Services</strong>
					</p>
					<hr></hr>
					<ol start="4">
						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Payment, Gift Card and Other Services</strong>
							<span style={{ fontWeight: '400' }}>
								. Depending on your country of residency, Wabi may offer additional Services such as
								payment services made available by Wabi through the Wabi Sites (the &ldquo;
							</span>
							<strong>Payment Services</strong>
							<span style={{ fontWeight: '400' }}>
								&rdquo;), electronic and physical gift cards and gift certificates issued by Wabi
								(each, an &ldquo;
							</span>
							<strong>Wabi Gift Card</strong>
							<span style={{ fontWeight: '400' }}>
								&rdquo;), electronic gift cards and gift certificates issued by participating
								third-party restaurants (each, a &ldquo;
							</span>
							<strong>Merchant Gift Card</strong>
							<span style={{ fontWeight: '400' }}>&rdquo;) and other services.&nbsp;</span>
						</li>
					</ol>
					<p>&nbsp;</p>
					<p>
						<strong>Part III &ndash; Terms for All Services</strong>
					</p>
					<hr></hr>
					<ol start="5">
						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Privacy Policy</strong>
							<span style={{ fontWeight: '400' }}>
								. Wabi is committed to helping you safeguard your privacy online. Please review
								our&nbsp;privacy policy&nbsp;for details about how we collect, use, and disclose
								information in connection with the Services.
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Your Account</strong>
							<span style={{ fontWeight: '400' }}>
								. You may (but are not required to) create an account with Wabi through the Wabi
								Sites (&ldquo;
							</span>
							<strong>Account</strong>
							<span style={{ fontWeight: '400' }}>
								&rdquo;) in order to use the Reservation Services, Waitlist Services or to purchase,
								gift, or redeem (as applicable) Wabi Gift Cards and Merchant Gift Cards. However,
								you must have an Account in order to use the Payment Services. When registering for
								an Account, you must provide true, accurate, current, and complete data about
								yourself on the Wabi registration form (&ldquo;
							</span>
							<strong>Registration Data</strong>
							<span style={{ fontWeight: '400' }}>
								&rdquo;). You also agree to promptly update the Registration Data to keep it true,
								accurate, current, and complete. You are solely responsible for maintaining the
								confidentiality of your Account and the information in your Account, and, except as
								otherwise required by applicable law, you are solely responsible for all use of your
								Account, whether or not authorized by you. You agree to immediately notify Wabi of
								any unauthorized use of your Account or any other breach of security related to your
								use of the Services.
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Communications from Wabi</strong>
							<span style={{ fontWeight: '400' }}>
								.&nbsp;If you use our Sites and Services, Wabi may communicate with you via
								electronic messages, including email, text message/SMS, or mobile push notifications
								in accordance with our privacy policy.
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Technical Requirements</strong>
							<span style={{ fontWeight: '400' }}>
								. Use of the Services requires Internet access through your computer or mobile
								device. You are responsible for all mobile carrier data or text message charges
								resulting from your use of the Services, including from any notifications provided
								by the Services. In order to use the text message based services, you must maintain
								an active account with a carrier of electronic communications through mobile devices
								and you may not use a prepaid cellular phone to access such text message services.
								Wabi does not guarantee that the Services will be compatible with all devices or
								will be supported by all mobile carriers. You may be required to have JavaScript (or
								similar technologies) enabled to use the Wabi Site, and some features and portions
								of the Wabi Site (including, but not limited to, making, modifying, or cancelling
								reservations) may not be accessible with JavaScript disabled.
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Modifications to Services</strong>
							<span style={{ fontWeight: '400' }}>
								. Wabi reserves the right, in its sole discretion, to modify the Services from time
								to time and without notice, including, without limitation, by removing, adding, or
								modifying portions of the Wabi Sites, Restaurants, and/or Merchants. Wabi shall have
								no liability to you for any of the foregoing actions. If you object to any such
								changes, your sole recourse shall be to cease using the Services. Continued use of
								the Services following any such changes shall indicate your acknowledgment of such
								changes and satisfaction with all the Services.
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Intellectual Property Rights and Grant of Rights to User</strong>
							<span style={{ fontWeight: '400' }}>
								. The features, information, and materials provided and depicted through the
								Services are protected by copyright, trademark, patent, and other intellectual
								property laws. All text, graphical content, video, data, and other content made
								available through the Services (collectively, the &ldquo;
							</span>
							<strong>Wabi Content</strong>
							<span style={{ fontWeight: '400' }}>
								&rdquo;) are provided to User by Wabi or its partners or licensors solely to support
								User&rsquo;s permitted use of the Services. The Wabi Content may be modified from
								time to time by Wabi in its sole discretion. Except as expressly set forth herein,
								no license is granted to User for any other purpose, and any other use of the
								Services or the Wabi Content by User shall constitute a material breach of this
								Agreement. Wabi and its partners or licensors retain all rights in the Services and
								Wabi Content and any associated patents, trademarks, copyrights, mask work rights,
								trade secrets, or other intellectual property rights. No license, right, or interest
								in any trademarks of Wabi or any third party is granted under this Agreement.
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Application License</strong>
							<span style={{ fontWeight: '400' }}>
								. Subject to the terms and conditions of this Agreement, Wabi grants User a
								non-exclusive, non-transferable, revocable license to use the Wabi mobile
								applications, in object code form only, on User&rsquo;s compatible mobile devices,
								solely to support User&rsquo;s permitted use of the Services.
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Use Restrictions</strong>
							<span style={{ fontWeight: '400' }}>
								. The Services and Wabi Content are offered solely for User&rsquo;s personal use for
								the purposes described in this Agreement. Any and all other uses are prohibited.
								Wabi expressly reserves all its rights and remedies under applicable laws (including
								state, federal, and provincial/territorial laws). Wabi reserves the right, in its
								sole discretion, to refuse service, terminate Accounts, remove or edit content,
								cancel reservations, or deny access to the Services. You agree not to (and not to
								allow any third party to): (1) use any deep-link, robot, spider, scraper, or other
								automatic or manual device, process, or means to access, copy, search, or monitor
								any portion of the Services or Wabi Content, except as expressly authorized by Wabi;
								(2) take any action that imposes or may impose (in Wabi&rsquo;s sole determination)
								an unreasonable or a disproportionately large load on the Services or Wabi&rsquo;s
								infrastructure; (3) utilise any device, software, or routine that will interfere or
								attempt to interfere with the functionality of the Services; (4) rent, lease, copy,
								provide access to or sublicense any portion of the Services or Wabi Content to a
								third party; (5) use any portion of the Services or Wabi Content to provide, or
								incorporate any portion of the Services or Wabi Content into, any product or service
								provided to a third party; (6) reverse engineer, decompile, disassemble, or
								otherwise seek to obtain the source code or non-public APIs to the Services, except
								to the extent expressly permitted by applicable law (and then only upon advance
								notice to Wabi); (7) modify any Services or Wabi Content or create any derivative
								product from any of the foregoing; (8) remove or obscure any proprietary or other
								notices contained in the Services or Wabi Content; (9) use the Services or Wabi
								Content for any illegal purpose; or (10) publicly disseminate information regarding
								the performance of the Services or Wabi Content or access or use the Services or
								Wabi Content for competitive analysis or benchmarking purposes. Wabi reserves the
								right to limit, in its sole discretion, the provision and quantity of any feature or
								service to any person or geographic area.&nbsp;
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Export Control</strong>
							<span style={{ fontWeight: '400' }}>
								. You may not use, export, or re-export any Wabi Sites or other aspects of the
								Services (or any copy or adaptation of the foregoing) in violation of applicable
								law, including, without limitation, United States and foreign export laws and
								regulations.&nbsp;
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Termination</strong>
							<span style={{ fontWeight: '400' }}>
								. Wabi may suspend your ability to use all or any element of the Services or may
								terminate this Agreement effective immediately, without notice or explanation.
								Without limiting the foregoing, Wabi may suspend your access to the Services if we
								believe you to be in violation of any part of this Agreement (including any Wabi
								Policies). You agree that Wabi shall not be liable to you for any termination of
								this Agreement or for any effects of any termination of this Agreement. You are
								always free to discontinue your use of the Services at any time. You understand that
								any termination of your Account may involve deletion of any content stored in your
								Account for which Wabi will have no liability whatsoever.
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Reviews, Comments, Communications, and Other Content</strong>
							<span style={{ fontWeight: '400' }}>
								. The Services may permit you to submit, transmit, post or otherwise provide
								content, including, but not limited to, reviews, comments, ratings, photos, images,
								videos, sounds, text, data, links and location information; send emails and other
								communications; and submit suggestions, ideas, comments, questions, or other
								information (&ldquo;
							</span>
							<strong>User Content</strong>
							<span style={{ fontWeight: '400' }}>
								&rdquo;). Any such User Content must not be illegal, threatening, obscene, racist,
								defamatory, libellous, pornographic, infringing of intellectual property rights,
								promoting of illegal activity or harm to groups and/or individuals, invasive of a
								third party&rsquo;s publicity or privacy rights, purposely false or otherwise
								injurious to third parties, or objectionable and must not consist of or contain
								software, computer viruses, commercial solicitation, political campaigning, chain
								letters, mass mailings, any form of &ldquo;spam&rdquo; or references to illegal
								activity, malpractice, purposeful overcharging, false advertising, or health code
								violations (e.g., foreign objects in food, food poisoning, etc.). Your User Content
								should be unbiased and objective. You may not submit reviews, comments or ratings
								for which you are being compensated in any manner, or for your own restaurant or any
								restaurant of your employer, friend, relative or a competitor. You may not use a
								false email address, impersonate any person or entity, or otherwise mislead as to
								the origin of User Content. The name or handle you choose to provide to Wabi may be
								displayed publicly with such User Content. Wabi reserves the right (but has no
								obligation) to monitor, remove, or edit User Content in Wabi&rsquo;s sole
								discretion, including if User Content violates this Agreement (including any Wabi
								Policies), but you acknowledge that Wabi may not regularly review submitted User
								Content. If you do submit User Content, and unless we indicate otherwise, you grant
								Wabi a nonexclusive, perpetual, royalty-free, irrevocable, and fully sublicensable
								(through multiple tiers, including to restaurants, partners and other third party
								websites and feeds) right to use, modify, reproduce, adapt, translate, publish,
								create derivative works from, distribute, display, and otherwise exploit such User
								Content throughout the world in any media, and, where applicable, you hereby waive
								any privacy or publicity rights &nbsp;or any similar rights in an individual&rsquo;s
								name or likeness in addition to any moral or other rights you may have in the User
								Content you submit in favour of Wabi. You represent that you own, or have the
								necessary permissions to use and authorize the use of User Content as described
								herein. Wabi takes no responsibility and assumes no liability for any User Content
								submitted by you or any other User or third party, nor do we guarantee any
								confidentiality with respect to User Content.
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Your Representations and Indemnity</strong>
							<span style={{ fontWeight: '400' }}>
								. You represent and warrant that you own or otherwise control all of the rights to
								any User Content submitted by you; that all User Content submitted by you is
								accurate; and that exploitation of such User Content by Wabi and its other Users,
								partners, and licensees will not violate this Agreement, cause injury to any person
								or entity, or infringe any third-party rights (including, without limitation,
								intellectual property rights and rights of privacy or publicity). You will
								indemnify, hold harmless, and (at Wabi&rsquo;s request) defend Wabi, its affiliates,
								and its and their representatives, agents, directors, managers, officers, employees,
								and shareholders (collectively, the &ldquo;
							</span>
							<strong>Wabi Parties</strong>
							<span style={{ fontWeight: '400' }}>
								&rdquo;) from and against all claims resulting from (1) any User Content submitted
								by you, (2) your use of the Services, or (3) any breach or alleged breach by you of
								this Agreement.
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Liability Limitations</strong>
							<span style={{ fontWeight: '400' }}>
								. EXCEPT AS EXPRESSLY SPECIFIED HEREIN, IN NO EVENT SHALL THE WABI PARTIES BE LIABLE
								FOR ANY INJURIES, LOSSES, CLAIMS, OR DIRECT DAMAGES OR ANY SPECIAL, EXEMPLARY,
								PUNITIVE, INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY KIND, WHETHER BASED IN
								CONTRACT, TORT, OR OTHERWISE, AND EVEN IF ADVISED OF THE POSSIBILITY OF SUCH
								DAMAGES, WHICH ARISE OUT OF OR ARE ANY WAY CONNECTED WITH (1) THIS AGREEMENT
								(INCLUDING ANY CHANGES THERETO), (2) ANY USE OF THE WABI SITES, SERVICES, THE WABI
								CONTENT, OR THE USER CONTENT, (3) ANY FAILURE OR DELAY (INCLUDING, BUT NOT LIMITED
								TO, THE USE OR INABILITY TO USE ANY COMPONENT OF ANY OF THE SERVICES), OR (4) YOUR
								VISIT TO ANY RESTAURANT OR THE PERFORMANCE, NON-PERFORMANCE, CONDUCT, OR POLICIES OF
								ANY RESTAURANT OR MERCHANT IN CONNECTION WITH THE SERVICES. IN ADDITION, YOU
								SPECIFICALLY UNDERSTAND AND AGREE THAT ANY THIRD PARTY DIRECTING YOU TO THE WABI
								SITE BY REFERRAL, LINK, OR ANY OTHER MEANS IS NOT LIABLE TO USER FOR ANY REASON
								WHATSOEVER, INCLUDING, BUT NOT LIMITED TO, DAMAGES OR LOSS ASSOCIATED WITH THE USE
								OF THE SERVICES OR THE WABI CONTENT. WABI IS NEITHER AN AGENT OF NOR OTHERWISE
								ASSOCIATED WITH ANY RESTAURANT FOR WHICH A USER HAS MADE A RESERVATION, CLAIMED AN
								OFFER OR PROMOTION, OR PAID A BILL USING THE PAYMENT SERVICES OR ANY MERCHANT THAT
								ISSUES A MERCHANT GIFT CARD.&nbsp; IF THE DISCLAIMER OF DIRECT DAMAGES ABOVE IS NOT
								ENFORCEABLE AT LAW, EXCEPT AS OTHERWISE EXPRESSLY SET FORTH IN THE GIFT CARD TERMS,
								YOU EXPRESSLY AGREE THAT OUR LIABILITY TO YOU (FOR ANY CAUSE WHATSOEVER AND
								REGARDLESS OF THE FORM OF THE ACTION) WILL AT ALL TIMES BE LIMITED TO FIFTY AU
								DOLLARS ($50).
							</span>
						</li>

						<p>
							<span style={{ fontWeight: '400' }}>
								You and Wabi understand and agree that the disclaimers, exclusions, and limitations
								in this Section 19 and in Section 20 are essential elements of this Agreement and
								that they represent a reasonable allocation of risk. In particular, you understand
								that Wabi would be unable to make the Services available to you except on these
								terms and agree&nbsp;that this Agreement will survive and apply even if any limited
								remedy specified in this Agreement is found to have failed of its essential purpose.
							</span>
						</p>
						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Disclaimer of Warranties</strong>
							<span style={{ fontWeight: '400' }}>
								. YOU UNDERSTAND THAT USE OF THE SERVICES IS AT YOUR OWN RISK AND WABI CANNOT
								GUARANTEE THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE.&nbsp;&nbsp;THE
								SERVICES, ALL WABI CONTENT, AND ANY OTHER INFORMATION, PRODUCTS, AND MATERIALS
								CONTAINED IN OR ACCESSED THROUGH THE SERVICES, ARE PROVIDED TO USER ON AN &ldquo;AS
								IS&rdquo; BASIS AND WITHOUT WARRANTY OF ANY KIND. WABI EXPRESSLY DISCLAIMS ALL
								REPRESENTATIONS, WARRANTIES, CONDITIONS, OR INDEMNITIES, EXPRESS OR IMPLIED,
								INCLUDING, WITHOUT LIMITATION, ANY WARRANTY OF MERCHANTABILITY, FITNESS FOR A
								PARTICULAR PURPOSE, TITLE, OR NON-INFRINGEMENT, OR ANY WARRANTY ARISING FROM A
								COURSE OF DEALING, PERFORMANCE, OR TRADE USAGE. WABI DOES NOT WARRANT THAT YOUR USE
								OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, THAT WABI WILL REVIEW THE
								INFORMATION OR MATERIALS MADE AVAILABLE THROUGH THE SERVICES FOR ACCURACY OR THAT IT
								WILL PRESERVE OR MAINTAIN ANY SUCH INFORMATION OR MATERIALS WITHOUT LOSS. WABI SHALL
								NOT BE LIABLE FOR DELAYS, INTERRUPTIONS, SERVICE FAILURES, OR OTHER PROBLEMS
								INHERENT IN USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS OR OTHER SYSTEMS
								OUTSIDE THE REASONABLE CONTROL OF WABI.
							</span>
						</li>

						<p>
							<span style={{ fontWeight: '400' }}>
								THE FOREGOING DISCLAIMERS APPLY TO THE MAXIMUM EXTENT PERMITTED BY LAW. YOU MAY HAVE
								OTHER STATUTORY RIGHTS. HOWEVER, THE DURATION OF STATUTORILY REQUIRED WARRANTIES, IF
								ANY, SHALL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW.
							</span>
						</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Third-Party Websites, Applications and Services</strong>
							<span style={{ fontWeight: '400' }}>
								. The Services may contain hypertext links to websites and applications operated by
								parties other than Wabi. Such hypertext links are provided for User&rsquo;s
								reference only, and Wabi does not control such websites and is not responsible for
								their content. Wabi&rsquo;s inclusion of any hypertext links to such websites or
								applications does not imply any endorsement of the material on such websites or
								applications or any association with their operators. Wabi assumes no liability
								whatsoever for any such third-party websites, applications or any content, features,
								products, or services made available through such third-party websites or
								applications.&nbsp;
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Release</strong>
							<span style={{ fontWeight: '400' }}>
								. Restaurants and Merchants are solely responsible for their interactions with you
								and any and all claims, injuries, illnesses, damages, liabilities, and costs
								(&ldquo;
							</span>
							<strong>Claims</strong>
							<span style={{ fontWeight: '400' }}>
								&rdquo;) suffered by you (or, if applicable, any recipient of an Wabi Gift Card or a
								Merchant Gift Card) as a result of your (or such recipient&rsquo;s) interaction with
								or visit to any Restaurant or Merchant or from any promotion, offer, product or
								service of any Restaurant or Merchant. Users must resolve all disputes directly with
								Restaurants. To the maximum extent permitted by applicable law, you hereby release
								the Wabi Parties from any and all such Claims. You hereby expressly waive and
								relinquish all rights and benefits under that section and any law of any
								jurisdiction of similar effect with respect to the release of any unknown or
								unsuspected claims you may have against the Wabi Parties pertaining to the subject
								matter of this Section 22.
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Notify Us of Infringers</strong>
							<span style={{ fontWeight: '400' }}>
								. If you believe any of the Services violate your copyright, notify our copyright
								agent in writing.&nbsp;The contact information for our copyright agent is at the
								bottom of this Section.
							</span>
						</li>
					</ol>
					<p>
						<span style={{ fontWeight: '400' }}>
							In order for us to take action, you must do the following in your notice:
						</span>
					</p>
					<ol type="a">
						<li style={{ fontWeight: '400' }} aria-level="1">
							<span style={{ fontWeight: '400' }}>
								provide your physical or electronic signature;
							</span>
						</li>
						<li style={{ fontWeight: '400' }} aria-level="1">
							<span style={{ fontWeight: '400' }}>
								identify the copyrighted work that you believe is being infringed;
							</span>
						</li>
						<li style={{ fontWeight: '400' }} aria-level="1">
							<span style={{ fontWeight: '400' }}>
								identify the item that you think is infringing your work and include sufficient
								information about where the material is located so that we can find it;
							</span>
						</li>
						<li style={{ fontWeight: '400' }} aria-level="1">
							<span style={{ fontWeight: '400' }}>
								provide us with a way to contact you, such as your address, telephone number, or
								email;
							</span>
						</li>
						<li style={{ fontWeight: '400' }} aria-level="1">
							<span style={{ fontWeight: '400' }}>
								provide a statement that you believe in good faith that the item you have identified
								as infringing is not authorized by the copyright owner, its agent, or the law to be
								used in connection with the Services; and
							</span>
						</li>
						<li style={{ fontWeight: '400' }} aria-level="1">
							<span style={{ fontWeight: '400' }}>
								provide a statement that the information you provide in your notice is accurate, and
								that (under penalty of perjury) you are authorized to act on behalf of the copyright
								owner whose work is being infringed.
							</span>
						</li>
					</ol>
					<p>
						<span style={{ fontWeight: '400' }}>
							Here is the contact information for our copyright agent:
						</span>
					</p>
					<p>
						<span style={{ fontWeight: '400' }}>Copyright Enforcement</span>
					</p>
					<p>
						<span style={{ fontWeight: '400' }}>Wabi Pty Ltd&nbsp;</span>
					</p>
					<p>
						<span style={{ fontWeight: '400' }}>
							634 glenferrie road Hawthorn, 3122, Melbourne, VIC, Australia
						</span>
					</p>
					<p>
						<span style={{ fontWeight: '400' }}>wabi@wabify.com</span>
					</p>
					<p>
						<span style={{ fontWeight: '400' }}>
							Again, we cannot take action unless you give us all the required information.&nbsp;
						</span>
					</p>
					<p>&nbsp;</p>
					<ol start="22">
						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Severability</strong>
							<span style={{ fontWeight: '400' }}>
								. If any of the provisions, or portions thereof, of this Agreement are found to be
								invalid under any applicable statute or rule of law, then, that provision (or
								portion thereof) notwithstanding, this Agreement shall remain in full force and
								effect and such provision or portion thereof shall be deemed omitted.
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Assignment</strong>
							<span style={{ fontWeight: '400' }}>
								. This Agreement and the rights granted and obligations undertaken hereunder may not
								be transferred, assigned, or delegated in any manner by User, but may be freely
								transferred, assigned, or delegated by Wabi.
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Waiver</strong>
							<span style={{ fontWeight: '400' }}>
								. Any waiver of any provision of this Agreement, or a delay by any party in the
								enforcement of any right hereunder, shall neither be construed as a continuing
								waiver nor create an expectation of non-enforcement of that or any other provision
								or right.
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Choice of Law</strong>
							<span style={{ fontWeight: '400' }}>
								. This Agreement is made under and shall be governed by and construed in accordance
								with the laws of the Victoria, Australia consistent with the Competition and
								Consumer Act 2010 (to the extent permitted by applicable law), without giving effect
								to any principles that provide for the application of the law of another
								jurisdiction.&nbsp;&nbsp;You may also be entitled to certain consumer protection
								rights under the laws of your local jurisdiction.
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Currency Conversion.</strong>
							<span style={{ fontWeight: '400' }}>
								{' '}
								Because in certain instances we receive the price from our suppliers in a foreign
								currency, the price displayed in search results may vary by a nominal amount from
								the price displayed at checkout due to the effect of foreign currency conversion.
								This amount will not exceed 10 Australian cents. The actual amount charged to your
								credit or debit card is the amount displayed at checkout. If a currency convertor is
								available on the Website, the following terms and conditions apply: Currency rates
								are based on various publicly available sources and should be used as guidelines
								only. Rates are not verified as accurate, and actual rates may vary. Currency quotes
								are not updated every day. Check the date on the currency converter feature for the
								day that currency was last updated. The information supplied by this application is
								believed to be accurate, but Wabi and/or our respective suppliers do not warrant or
								guarantee such accuracy. When using this information for any financial purpose, we
								advise you to consult a qualified professional to verify the accuracy of the
								currency rates. We do not authorise the use of this information for any purpose
								other than your personal use and you are expressly prohibited from the resale,
								redistribution, and use of this information for commercial purposes.
							</span>
						</li>
					</ol>
				</Container>
			</HomePageHero.Wrapper>
			<Footer />
		</div>
	);
};
export default Terms;
