import React, { useState, useEffect } from 'react';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import closeButton from '../../../../../assets/images/booking/create-booking/close-button.png';
import exitBtnTableIcon from '../../../../../assets/images/booking/exit-btn-table-details.png';
import { getTimeStr } from '../../../../../utils/Utility';
// import TimeTable from '../time-table/TimeTable';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		width: '100%',
		height: '100%',
		backdropFilter: 'blur(10px)',
		outline: 'none',
	},
}));

function EditBookingTimeModal(props) {
	const classes = useStyles();
	const [selectedTime, setSelectedTime] = useState(null);

	const handleCloseEditTimeBooking = () => {
		props.setBookingTimeOpen();
	};
	const handleOpenEditTimeBooking = props.bookingTimeOpen;

	const selectTime = (time) => {
		// setSelectedTime(time);
		// props.selectedTimeHandler(time);
		setSelectedTime(time);
		props.selectedTimeHandler(time);
		props.setBookingTimeOpen(false);
	};

	// console.log(handleOpenEditTimeBooking);

	return (
		<div>
			<Modal
				className={classes.modal}
				open={props.bookingTimeOpen}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={handleOpenEditTimeBooking}>
					<div className={classes.paper}>
						<div className="modal-content-wrapper">
							<div className="modal-content-container">
								<div className="img-wrapper" onClick={() => handleCloseEditTimeBooking()}>
									<img src={exitBtnTableIcon} width="12" height="12" alt="checker" />
								</div>
								<label
									style={{
										fontSize: 16,
										fontWeight: 'bold',
										color: 'white',
										display: 'inline-flex',
										flexDirection: 'row',
										alignItems: 'center',
										padding: '17px',
									}}
								>
									<img
										src={props.btnTimelineIcon}
										width="26"
										height="26"
										alt="Icon"
										style={{ margin: '10px' }}
									/>
									{props.modalTitle}
								</label>
								<div className="scrollable-container">
									<div className="list-item-container">
										{props.timeList.length > 0
											? props.timeList.map((time) => {
													return (
														<div
															key={Math.random()}
															onClick={() => selectTime(time)}
															className={
																selectedTime === time
																	? 'time-booking-list-item time-booking-list-item--selected'
																	: 'time-booking-list-item'
															}
														>
															{getTimeStr(time)}
														</div>
													);
											  })
											: ''}
									</div>
								</div>
							</div>
						</div>
					</div>
				</Fade>
			</Modal>
		</div>
	);
}
export default EditBookingTimeModal;
