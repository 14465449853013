import React, { Component } from 'react';
import FrontOfHouseLogo from '../../../../assets/images/booking/booking-navbar/front-of-house.png';
import GuestBookLogo from '../../../../assets/images/booking/booking-navbar/guest-book.png';
import TableLayoutPlanningLogo from '../../../../assets/images/booking/booking-navbar/table-layout-planning.png';
import BookingPoliciesLogo from '../../../../assets/images/booking/booking-navbar/booking-policies.png';
import ReportLogo from '../../../../assets/images/booking/booking-navbar/report.png';
import RestaurantProfileLogo from '../../../../assets/images/booking/booking-navbar/restaurant-profile.png';
import UserRolesLogo from '../../../../assets/images/booking/booking-navbar/user-profile.png';
import SubscriptionLogo from '../../../../assets/images/booking/booking-navbar/subscription.png';

export const BookingNavBarData = [
	{
		title: 'Front of House',
		icon: <img src={`${FrontOfHouseLogo}`} width={24} height={24} />,
		link: '/dashboard/booking',
	},
	{
		title: 'Guest Book',
		icon: <img src={`${GuestBookLogo}`} width={24} height={24} />,
		link: '/dashboard/booking/guest-book',
	},
	{
		title: 'Table Layout and Planning',
		icon: <img src={`${TableLayoutPlanningLogo}`} width={24} height={24} />,
		link: '/dashboard/booking/table-planning',
	},
	// {
	// 	title: 'Booking Policies',
	// 	icon: <img src={`${BookingPoliciesLogo}`} width={24} height={24} />,
	// 	link: '/dashboard/booking/booking-policies',
	// },
	{
		title: 'Report',
		icon: <img src={`${ReportLogo}`} width={24} height={24} />,
		link: '/dashboard/booking/report',
	},
	{
		title: 'Restaurant Profile',
		icon: <img src={`${RestaurantProfileLogo}`} width={24} height={24} />,
		link: '/dashboard/booking/restaurant-profile',
	},
	{
		title: 'User Roles',
		icon: <img src={`${UserRolesLogo}`} width={24} height={24} />,
		link: '/dashboard/booking/user-roles',
	},
	{
		title: 'Subscription',
		icon: <img src={`${SubscriptionLogo}`} width={24} height={24} />,
		// link: '/dashboard/booking/subscription',
	},
];
