const getMeData = ({ token }) => {
	return {
		type: 'GET_ME_DATA',
		payload: token,
	};
};

const getUser = ({ token }) => {
	return {
		type: 'GET_USER',
		payload: { token },
	};
};

const getBusinessProfile = ({ token }) => {
	return {
		type: 'GET_BUSINESS_PROFILE',
		payload: { token },
	};
};

const getAllShifts = ({ token }) => {
	return {
		type: 'GET_ALL_SHIFTS',
		payload: { token },
	};
};

const setLoading = () => {
	return {
		type: 'SET_LOADING',
	};
};

const setSubscribed = () => {
	return {
		type: 'SET_IS_SUBSCRIBED',
	};
};

const updateUser = ({ userId, email, stripeId, planId }) => {
	return {
		type: 'UPDATE_USER',
		payload: {
			userId,
			email,
			stripeId,
			planId,
		},
	};
};

const logOutUser = () => {
	return {
		type: 'LOGOUT',
	};
};

// const signUpUser = ({ email, password }) => {
// 	return {
// 		type: 'SIGN_UP_USER',
// 		payload: {
// 			email,
// 			password,
// 		},
// 	};
// };

export const userActions = {
	getMeData,
	// signUpUser,
	getUser,
	setLoading,
	setSubscribed,
	updateUser,
	logOutUser,
	getBusinessProfile,
	getAllShifts,
};
