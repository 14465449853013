import React from 'react';
import { useDrag } from 'react-dnd';
import { ITEMTYPE } from '../../../../../../constants/enum';

function DraggableItem(props) {
	const [{ isDragging }, drag] = useDrag(() => ({
		type: 'existingTable',
		item: {
			id: props.id,
			type: ITEMTYPE.existingTable,
		},
		collect: (monitor) => ({
			isDragging: !!monitor.isDragging(),
		}),
	}));

	return (
		<div id={props.id} ref={drag} style={props.style}>
			{props.children}
		</div>
	);
}

export default DraggableItem;
