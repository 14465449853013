import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import styled from 'styled-components';

import xIcon from 'assets/images/booking/exit-btn-table-details.png';
import EmployeeSegmentedInputs from './EmployeeSegmentedInputs';

import { imgUrl } from 'utils/Utility';
import AddDialog from './AddDialog';
import DotLoader from '../../../TableLayoutPlanning/pages/SubComponents/DotLoader';
import CreateWagePayItem from './CreateWagePayItem';
import WageItemListContainer from './WageItemListContainer';
import ColorPickerModal from '../ColorPickerModal';
import AlertDialog from '../../../CreatBooking/booking-experience/AlertDialog';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		outline: 'none',
	},
}));

const StyledBackdrop = withStyles({
	root: {
		background: 'rgb(255 255 255 / 30%);',
		backdropFilter: 'blur(10px)',
	},
})(Backdrop);

const Wrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	width: 807px;
	height: 596px;
	background: #f5f5f5;
	border-radius: 10px;
	box-shadow: 0px 1px 10px #1c1c1e4d;
`;

const StyledCol = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	min-width: 200px;
	padding: 20px;

	span {
		position: absolute;
		display: flex;
		flex-direction: column;
		bottom: 30px;
	}

	#file-input {
		display: none;
	}
`;

const ProfilePickContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 148px;
	height: 148px;
	box-shadow: 0px 1px 10px #1c1c1e4d;
	border-radius: 50%;
	margin-bottom: 15px;
	font-size: 50px;
	font-weight: 700;

	img {
		border-radius: 50%;
		aspect-ratio: auto 4/3;
		z-index: 1;
	}
`;

const StyledCol2 = styled(StyledCol)`
	width: 100%;
	padding: 50px 30px 30px 0;
`;

const OpenFileButton = styled.label`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 150px;
	height: 40px;
	background: #ffffff;
	box-shadow: 0px 1px 10px #1c1c1e4d;
	border-radius: 1.5em;
	margin: 10px 0;
	color: #818181;
	font-size: 14px;
	font-weight: 700;
	cursor: pointer;

	&:hover {
		background: #ffffff00;
	}
`;

const StyledButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 150px;
	height: 40px;
	background: #ffffff;
	box-shadow: 0px 1px 10px #1c1c1e4d;
	border-radius: 1.5em;
	margin: 10px 0;
	color: #818181;
	font-size: 14px;
	font-weight: 700;

	&:hover {
		background: #ffffff00;
	}

	&:active {
		background: #ffffff;
	}
`;

const StyledButton2 = styled(StyledButton)`
	width: 125px;
	border-radius: 10px;
	border: 1px solid ${(props) => props.color};
	color: ${(props) => props.color};
`;

const StyledNameInput = styled.div`
	width: 95%;
	height: 40px;
	background: #ffffff;
	box-shadow: 2px 3px 10px #1c1c1e4d;
	border-radius: 1em;
	margin: 5px 0;
	padding-right: 50px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	label {
		margin-left: 20px;
		font-size: 14px;
		font-weight: 700;
		color: #1c1c1e;
	}

	input {
		text-align: end;
		width: 50%;
		margin-left: 20px;
		font-size: 14px;
		font-weight: 500;

		::placeholder,
		::-webkit-input-placeholder {
			color: #7d7d7d9e;
		}
		:-ms-input-placeholder {
			color: #7d7d7d9e;
		}

		&:focus {
			text-align: start;
		}
	}
`;

const SegmentedContainer = styled.div`
	width: 95%;
	height: 365px;
	box-shadow: 0px 1px 10px #1c1c1e4d;
	border-radius: 10px;
	margin: 40px 0 5px 0;
	background: #ffffff;
`;

function EmployeeDetailsModal(props) {
	const classes = useStyles();
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [profilePic, setProfilePic] = useState('');
	const [color, setColor] = useState('#85ffff');
	const [staff, setStaff] = useState([]);
	const [addContract, setAddContract] = useState(false);
	const [addRole, setAddRole] = useState(false);
	const [showAddWageButton, setShowAddWageButton] = useState(false);
	const [isAddingWageItem, setIsAddingWageItem] = useState(false);
	const [openColorPicker, setOpenColorPicker] = useState(false);
	const [hasError, setHasError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	const changeFirstName = (e) => {
		setFirstName(e.target.value);
	};

	const changeLastName = (e) => {
		setLastName(e.target.value);
	};

	const handleClose = (e) => {
		// e.preventDefault();
		// e.stopPropagation();
		clearStates();
		props.toggleEmployeeDetailsModal();
	};

	const clearStates = () => {
		setFirstName('');
		setLastName('');
		setProfilePic('');
		setShowAddWageButton(false);
		setStaff([]);
	};

	const handleChangeProfilePic = (e) => {
		setProfilePic(URL.createObjectURL(e.target.files[0]));
		const formData = new FormData();
		formData.append('id', staff.id);
		formData.append('image', e.target.files[0], e.target.files[0].type);
		props.uploadStaffAvatar(formData);
		let employee = { ...props.selectedEmployee };
		employee = { ...employee, photo: formData };
		setStaff(employee);
	};

	const changeColorCallback = (color) => {
		let newColor = hslToHex(color.hue, color.saturation, color.lightness);
		let _staff = { ...staff, color: newColor };
		setColor(newColor);
		setStaff(_staff);
	};

	const hslToHex = (h, s, l) => {
		s /= 100;
		l /= 100;

		let c = (1 - Math.abs(2 * l - 1)) * s,
			x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
			m = l - c / 2,
			r = 0,
			g = 0,
			b = 0;

		if (0 <= h && h < 60) {
			r = c;
			g = x;
			b = 0;
		} else if (60 <= h && h < 120) {
			r = x;
			g = c;
			b = 0;
		} else if (120 <= h && h < 180) {
			r = 0;
			g = c;
			b = x;
		} else if (180 <= h && h < 240) {
			r = 0;
			g = x;
			b = c;
		} else if (240 <= h && h < 300) {
			r = x;
			g = 0;
			b = c;
		} else if (300 <= h && h < 360) {
			r = c;
			g = 0;
			b = x;
		}
		// Having obtained RGB, convert channels to hex
		r = Math.round((r + m) * 255).toString(16);
		g = Math.round((g + m) * 255).toString(16);
		b = Math.round((b + m) * 255).toString(16);

		// Prepend 0s, if necessary
		if (r.length == 1) r = '0' + r;
		if (g.length == 1) g = '0' + g;
		if (b.length == 1) b = '0' + b;

		return '#' + r + g + b;
	};

	const addContractHandler = () => {
		setAddContract(true);
	};

	const addRoleHandler = () => {
		setAddRole(true);
	};

	const saveAddContractCallback = (data) => {
		let params = {
			description: '',
			contract: data,
		};
		// console.log(params);
		props.addEmploymentContract(params);
	};

	const saveAddRoleCallback = (data) => {
		let params = {
			title: data,
			description: '',
		};
		// console.log(params);
		props.addEmployementRole(params);
	};

	const newWagePayItemCallback = (data) => {
		props.addWageItem(data);
	};

	const wageItemClickCallback = (item) => {
		let _staff = { ...staff };
		let payWageItem = _staff.pay_wages_item;
		let payWageItemIds = _staff.pay_wages_item.map((e) => e.id);
		if (payWageItemIds.includes(item.id)) {
			let idx = payWageItem.findIndex((e) => e.id === item.id);
			payWageItem.splice(idx, 1);
		} else {
			payWageItem.push(item);
		}

		_staff = { ..._staff, pay_wages_item: payWageItem };
		console.log(_staff);
		setStaff(_staff);
	};

	const handleSave = () => {
		if (props.isUpdating) {
			console.log('Currently updating please wait');
			return;
		}
		let _staff = { ...staff };
		let randomPin = Math.floor(10000 + Math.random() * 9000);
		if (!firstName) {
			setHasError(true);
			setErrorMessage({
				title: 'Error',
				body: 'First name field is required',
			});
		} else if (!lastName) {
			setHasError(true);
			setErrorMessage({
				title: 'Error',
				body: 'Last name field is required',
			});
		} else if (!staff.role) {
			setHasError(true);
			setErrorMessage({
				title: 'Error',
				body: 'Employement info is required',
			});
			console.log('put error here role');
		} else {
			let payWageItemIds = _staff.pay_wages_item.map((e) => e.id);
			_staff = {
				...staff,
				first_name: firstName,
				last_name: lastName,
				pay_wages_item: payWageItemIds,
				// photo: profilePic,
				pin:
					Math.random().toString(36).substring(2, 5) + Math.random().toString(36).substring(2, 5),
				// pin_confirmation: randomPin,
				color: color,
			};
			if (props.isAdding) {
				// console.log(_staff);
				props.addNewStaff(_staff);
				handleClose();
			} else {
				_staff = {
					...staff,
					first_name: firstName,
					last_name: lastName,
					pay_wages_item: payWageItemIds,
					// photo: profilePic,
					pin:
						Math.random().toString(36).substring(2, 5) + Math.random().toString(36).substring(2, 5),
					// pin_confirmation: randomPin,
					color: color,
				};
				console.log(JSON.stringify(_staff, null, 4));
				props.updateEmployeeDetails(_staff);
				handleClose();
			}
		}
	};

	const handleDeleteStaff = () => {
		let params = {
			id: staff.id,
		};
		props.deleteStaff(params);
		handleClose();
	};

	useEffect(() => {
		if (props.selectedEmployee) {
			if (!props.isAdding) {
				setFirstName(props.selectedEmployee.first_name);
				setLastName(props.selectedEmployee.last_name);
				setColor(props.selectedEmployee.color);
				setStaff(props.selectedEmployee);
			} else {
				clearStates();
				setColor('#85ffff');
			}
		}
	}, [props.selectedEmployee, props.isAdding, props.isEmployeeDetailsModalOpen]);

	return (
		<Modal
			aria-labelledby="employee-details-modal"
			aria-describedby="employee-details-modal-description"
			className={classes.modal}
			open={props.isEmployeeDetailsModalOpen}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={StyledBackdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={props.isEmployeeDetailsModalOpen}>
				<div className={classes.paper}>
					<AlertDialog
						alertHandler={hasError}
						dialogAlertMessage={errorMessage}
						setAlertHandler={setHasError}
					/>
					{openColorPicker ? (
						<ColorPickerModal
							openColorPicker={openColorPicker}
							setOpenColorPicker={setOpenColorPicker}
							changeColorCallback={changeColorCallback}
						/>
					) : isAddingWageItem ? (
						<CreateWagePayItem
							isAddingWageItem={isAddingWageItem}
							setIsAddingWageItem={setIsAddingWageItem}
							employeeList={props.employeeList}
							newWagePayItemCallback={newWagePayItemCallback}
						/>
					) : (
						<Wrapper>
							{props.isUpdating ? <DotLoader /> : ''}

							<div
								onClick={handleClose}
								style={{ position: 'absolute', right: 20, top: 20, zIndex: 1, cursor: 'pointer' }}
							>
								<img src={xIcon} height={15} width={15} alt="close-icon" />
							</div>
							<StyledCol>
								{props.isAdding ? (
									<ProfilePickContainer style={{ background: `${color}` }}>
										{profilePic ? <img src={profilePic} width={148} height={148} /> : 'AA'}
									</ProfilePickContainer>
								) : props.selectedEmployee.photo ? (
									<ProfilePickContainer style={{ background: `${color}` }}>
										<img
											src={profilePic ? profilePic : imgUrl + props.selectedEmployee.photo}
											width={148}
											height={148}
										/>
									</ProfilePickContainer>
								) : (
									<ProfilePickContainer style={{ background: `${color}` }}>
										{profilePic ? (
											<img src={profilePic} width={148} height={148} />
										) : props.selectedEmployee ? (
											<>
												{firstName.charAt(0).toUpperCase()}
												{lastName.charAt(0).toUpperCase()}
											</>
										) : (
											''
										)}
									</ProfilePickContainer>
								)}

								<input
									id="file-input"
									style={{ color: 'white' }}
									type="file"
									accept="image/"
									onChange={handleChangeProfilePic}
								/>
								<OpenFileButton htmlFor="file-input">Upload Image</OpenFileButton>
								<StyledButton onClick={() => setOpenColorPicker(true)}>Change Color</StyledButton>

								<span>
									<StyledButton2 color="#ff9090" onClick={handleDeleteStaff}>
										Delete
									</StyledButton2>
									<StyledButton2 color="#6affc6" onClick={handleSave}>
										Save
									</StyledButton2>
								</span>
							</StyledCol>
							<StyledCol2>
								<StyledNameInput>
									<label>Firstname</label>
									<input
										placeholder="Enter Firstname"
										value={firstName}
										onChange={changeFirstName}
									/>
								</StyledNameInput>
								<StyledNameInput>
									<label>Lastname</label>
									<input placeholder="Enter Lastname" value={lastName} onChange={changeLastName} />
								</StyledNameInput>
								<SegmentedContainer>
									<EmployeeSegmentedInputs
										employmentContract={props.employmentContract}
										employmentRoles={props.employmentRoles}
										selectedEmployee={props.selectedEmployee}
										isAdding={props.isAdding}
										staff={staff}
										setStaff={setStaff}
										addContractHandler={addContractHandler}
										addRoleHandler={addRoleHandler}
										showAddWageButton={showAddWageButton}
										setShowAddWageButton={setShowAddWageButton}
										wageItems={props.wageItems}
									/>
								</SegmentedContainer>
							</StyledCol2>
							<AddDialog
								title="Add New Contract"
								label="Contract Title"
								placeholder="Enter contract title"
								isOpen={addContract}
								handler={setAddContract}
								apiHandler={saveAddContractCallback}
							/>
							<AddDialog
								title="Add New Role"
								label="Role Title"
								placeholder="Enter role title"
								isOpen={addRole}
								handler={setAddRole}
								apiHandler={saveAddRoleCallback}
							/>

							{showAddWageButton ? (
								<WageItemListContainer
									wageItems={props.wageItems}
									selectedItem={staff.pay_wages_item.map((e) => e.id)}
									setShowAddWageButton={setShowAddWageButton}
									setIsAddingWageItem={setIsAddingWageItem}
									wageItemClickCallback={wageItemClickCallback}
								/>
							) : (
								''
							)}
						</Wrapper>
					)}
				</div>
			</Fade>
		</Modal>
	);
}

export default EmployeeDetailsModal;
