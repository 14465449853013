import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const StyledBtnGroup = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 70px;
	overflow: hidden;

	label {
		font-size: 14px;
		font-weight: 700;
		color: #505050;
	}

	#drg-btn-row${(props) => props.identifier} {
		position: absolute;
		display: flex;
		flex-direction: row;
		margin-top: 20px;
		// cursor: pointer;
	}
`;

const StyledButton = styled.button`
	display: flex;
	min-width: 130px;
	height: 35px;
	justify-content: center;
	align-items: center;
	color: #505050;
	font-size: 12px;
	font-weight: 500;
	box-shadow: 2px 3px 10px #1c1c1e4d;
	border-radius: 1.5em;
	margin: 5px;
	transition: ease 500ms;

	&:hover {
		background: #1c1c1e05;
	}

	&:active {
		background: #f46587;
		color: white;
	}

	&:focus {
		background: #1c1c1e05;
	}
`;

function DraggableButton(props) {
	let pressed = false;
	let startX;
	let btnRow;
	let btnGroup;

	const onMouseDown = (e) => {
		e.preventDefault();
		e.stopPropagation();
		pressed = true;
		btnRow = document.getElementById(`drg-btn-row${props.identifier}`);
		window.addEventListener('mousemove', onMouseMove);
		window.addEventListener('mouseup', onMouseUp);
		startX = e.clientX - btnRow.offsetLeft;
		btnRow.style.cursor = 'grabbing';
	};

	const onMouseMove = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (!pressed) return;
		btnRow.style.left = `${e.clientX - startX}px`;
		checkBoundary();
	};

	const checkBoundary = () => {
		btnGroup = document.getElementById(`styled-drg-btnGroup${props.identifier}`);
		const outer = btnGroup.getBoundingClientRect();
		const inner = btnRow.getBoundingClientRect();
		if (parseInt(btnRow.style.left) > 0) {
			btnRow.style.left = '0px';
		} else if (inner.width < outer.width) {
			btnRow.style.left = '0px';
		} else if (inner.right < outer.right) {
			btnRow.style.left = `${outer.width - (props.contents.length + 1) * 140.5}px`;
		}
	};

	const onMouseUp = () => {
		pressed = false;
		btnRow.style.cursor = 'pointer';
		window.removeEventListener('mousemove', onMouseMove);
		window.removeEventListener('mouseup', onMouseUp);
	};

	return (
		<StyledBtnGroup
			id={`styled-drg-btnGroup${props.identifier}`}
			identifier={props.identifier}
			key={props.identifier}
		>
			<label>{props.label}</label>
			<div id={`drg-btn-row${props.identifier}`}>
				{props.contents.length > 0
					? props.contents.map((item, key) => {
							if (props.identifier !== 2) {
								return (
									<StyledButton
										key={key}
										onMouseDown={onMouseDown}
										style={
											props.selected
												? props.selected === item.id
													? { background: '#f46587', color: 'white' }
													: {}
												: {}
										}
										onClick={() => props.onClick(item.id)}
									>
										{item.title}
									</StyledButton>
								);
							} else {
								return (
									<StyledButton
										key={key}
										onMouseDown={onMouseDown}
										style={
											props.selected
												? props.selected === item.id
													? { background: '#f46587', color: 'white' }
													: {}
												: {}
										}
										onClick={() => props.onClick(item.id)}
									>
										{item.contract}
									</StyledButton>
								);
							}
					  })
					: ''}
				{props.isDefault ? '' : <StyledButton onClick={() => props.addClick()}>+</StyledButton>}
			</div>
		</StyledBtnGroup>
	);
}

export default DraggableButton;
