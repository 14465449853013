import React, { useEffect, useState } from 'react';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import exitBtnIcon from '../../../../../assets/images/booking/exit-btn-table-details.png';
import { last } from 'lodash';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		width: '100%',
		height: '100%',
		backdropFilter: 'blur(10px)',
		outline: 'none',
	},
}));

function EditNameModal(props) {
	const classes = useStyles();
	const [guestName, setGuestName] = useState(props.guest);

	const handleOpenEditName = props.openEditName;

	const handleCloseEditName = () => {
		props.handleCloseEditName(guestName);
	};

	const handleOnChange = (value, type) => {
		if (type === 1) {
			setGuestName({ ...guestName, first_name: value });
		} else {
			setGuestName({ ...guestName, last_name: value });
		}
	};

	useEffect(() => {
		setGuestName(props.guest);
	}, [props.guest]);

	return (
		<Modal
			className={classes.modal}
			open={props.openEditName}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={handleOpenEditName}>
				<div className={classes.paper}>
					<div className="modal-content-wrapper">
						<div className="editName-content-container">
							<div className="img-wrapper" onClick={handleCloseEditName}>
								<img src={exitBtnIcon} width="12" height="12" alt="checker" />
							</div>
							<label className="modal-title-header">
								<img
									src={props.personIcon}
									width="26"
									height="26"
									alt="Icon"
									style={{ marginTop: 5, marginRight: 5, marginBottom: 5 }}
								/>
								{props.modalTitle}
							</label>

							<label className="edit-name-label">First Name </label>

							<input
								value={guestName.first_name}
								onChange={(e) => handleOnChange(e.target.value, 1)}
								placeholder="Enter text here"
							/>
							<div className="line-break" />
							<label className="edit-name-label">Last Name</label>
							<input
								value={guestName.last_name}
								onChange={(e) => handleOnChange(e.target.value, 2)}
								placeholder="Enter text here"
							/>
							{/* <input type="submit" value="submit" /> */}
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}
export default EditNameModal;
