import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	position: relative;
	label {
		position: relative;
		display: inline-block;
		width: 110px;
		height: 40px;
	}

	span {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #222a2f;
		transition: 0.5s;
		border: 5px solid #222a2f;
	}

	span:before {
		//Circle bg & position
		position: absolute;
		content: '';
		top: 0.5px;
		left: 3px;
		background-color: #222a2f;
		// box-shadow: 0px 0px 1px 1px #1a2024, 0px 0px 1px 1px #2a343a;
		transition: 0.5s;
	}

	label input {
		display: none;
	}

	input:checked + span {
		background-color: #85ffbd;
	}

	input:checked + span:before {
		transform: translateX(67px);
	}

	span {
		box-shadow: 5px 5px 10px #1a2024, -5px -5px 10px #2a343a,
			inset 0px 0px 20px 2px rgba(255, 255, 255, 0.1132);
		border-radius: 50px;
	}

	span:before {
		//Circle size
		width: 30px;
		height: 29px;
		border-radius: 50%;
	}
`;

function SettingToggleSwitch(props) {
	return (
		<Container
			labelH={props.labelH}
			labelW={props.labelW}
			roundSize={props.roundSize}
			spanL={props.spanL}
			transform={props.transform}
		>
			<label>
				<input type="checkbox" onChange={props.onChange} checked={props.checked} />
				<span />
			</label>
		</Container>
	);
}
export default SettingToggleSwitch;
