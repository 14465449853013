import React from 'react';
import { useDrag } from 'react-dnd';
import { ITEMTYPE } from '../../../../../../constants/enum';

function DragCloneItem(props) {
	const ID = props.id;
	const [{ isDragging }, drag] = useDrag(() => ({
		type: 'newTable',
		item: {
			id: props.id,
			type: ITEMTYPE.newTable,
		},
		collect: (monitor) => ({
			isDragging: !!monitor.isDragging(),
		}),
	}));

	const selectedTableHandler = (id) => {
		props.selectedTableTypeCallBack(id);
	};

	return (
		<div
			ref={drag}
			id={props.id}
			className={props.className}
			style={props.style}
			onClick={(e) => selectedTableHandler(props.id)}
		>
			{props.children}
		</div>
	);
}

export default DragCloneItem;

// const dragStart = (e) => {
// 	const target = e.currentTarget;
// 	e.dataTransfer.setData('table_cardId', target.id);
// e.preventDefault();

// const table_cardId = e.dataTransfer.getData('table_cardId');
// const tableCard = document.getElementById(table_cardId);
// tableCard.cloneNode(true);
// };

// const dragOver = (e) => {
// 	e.stopPropagation();
// };

// const drop = (e) => {
// 	console.log(e);
// 	const table_cardId = e.dataTransfer.getData('table_cardId');
// 	const tableCard = document.getElementById(table_cardId);
// 	const newElem = tableCard.cloneNode(true);
// 	e.target.appendChild(newElem);
// 	console.log(newElem);
// 	e.preventDefault();
// };
