import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
	display: flex;
	position: relative;
	// margin: 5px;
	width: 160px;
	height: 40px;
	align-items: center;
	justify-content: center;

	label {
		position: relative;
		display: inline-block;
		width: 150px;
		height: 40px;
		cursor: pointer;
	}

	span {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #fe7d86;
		transition: 0.5s;
		border: 5px solid #222a2f;
		cursor: pointer;
	}

	span:before {
		//Circle bg & position
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		background-color: #222a2f;
		// box-shadow: 0px 0px 1px 1px #1a2024, 0px 0px 1px 1px #2a343a;
		transition: 400ms;
		cursor: pointer;
	}

	label input {
		display: none;
	}

	input:checked + span {
		background-color: #85ffbd;
	}

	input:checked + span:before {
		transform: translateX(110px);
	}

	span {
		box-shadow: 5px 5px 10px #1a2024, -5px -5px 10px #2a343a,
			inset 0px 0px 20px 2px rgba(255, 255, 255, 0.1132);
		border-radius: 50px;
	}

	span:before {
		//Circle size
		width: 30px;
		height: 30.5px;
		border-radius: 50%;
	}
`;

const TextContainer = styled.div`
	position: relative;
	display: inline-flex;
	align-items: center;
	// opacity: ${(props) => (props.checked ? '' : '0')};
	height: 100%;
	width: 130px;
	margin-left: 10px;
	user-select: none;
	overflow: hidden;

	#text {
		font-size: 16px;
		font-weight: 700;
		color: #ffffff;
	}
`;

function SpecialDaysToggleSwitch(props) {
	return (
		<Container>
			<label>
				<input id="comp" type="checkbox" onChange={props.onChange} checked={props.checked} />

				<span />
				<TextContainer checked={props.checked}>
					<div
						style={
							props.checked
								? {
										display: 'inline-block',
										position: 'absolute',
										left: 20,
										transition: 'ease 400ms',
								  }
								: {
										display: 'inline-block',
										position: 'absolute',
										left: '-100%',
										transition: 'ease 400ms',
								  }
						}
						id="text"
					>
						{props.tvalue}
					</div>

					<div
						style={
							props.checked
								? {
										display: 'inline-block',
										position: 'absolute',
										right: '-100%',
										transition: 'ease 400ms',
								  }
								: {
										display: 'inline-block',
										position: 'absolute',
										right: 20,
										transition: 'ease 400ms',
								  }
						}
						id="text"
					>
						{props.fvalue}
					</div>
				</TextContainer>
			</label>
		</Container>
	);
}
export default SpecialDaysToggleSwitch;
