import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import axios from 'axios';
import { apiUrl } from 'utils/Utility';

//Components
import TableDetails from '../TableDetails/TableDetails';
import CollapsableFloor from './CollapsableFloor';
import DotLoader from '../TableLayoutPlanning/pages/SubComponents/DotLoader';

const MainContainer = styled.div`
	position: relative;
	display: inline-flex;
	// flex-direction: ro;
	color: white;
	min-height: 670px;
	height: auto;
	width: 100%;
	min-width: 670px;
	overflow: hidden;
	user-select: none;
`;

const SubContainer = styled.div`
	display: inline-flex;
	flex-direction: column;
	color: white;
	max-height: 652px;
	width: 100%;
	user-select: none;
	overflow: auto;

	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
		background-color: #222a2f;
	}
	::-webkit-scrollbar-thumb {
		width: 10px;
		height: 10px;

		border-radius: 20px;
		background-color: #2a343a;
	}

	::-webkit-scrollbar-corner {
		display: none;
	}
`;

const HeaderRow = styled.div`
	display: inline-flex;
	height: 41px;
	width: fit-content;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	background: #394044;
	border-bottom: 1px solid #222a2f;

	span {
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		min-width: 150px;
		border-right: 1px solid #9a9a9a;
		background: #394044;
	}

	h4 {
		font-size: 18px;
		font-weight: 500;
		margin-right: 10px;
	}
`;

const HeaderText = styled.p`
	position: absolute;
	right: 20px;
	font-size: 14px;
	font-weight: 400;
	color: #9d9d9d;
`;

const HeaderTime = styled.p`
	position: absolute;
	left: -18px;
	font-size: 14px;
	font-weight: 400;
	color: #9d9d9d;
	z-index: 1;
`;

const HeaderBlock = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	height: 40px;
	min-width: 160px;
	background: #394044;
	overflow: visible;
`;

const LineIndicator = styled.div`
	position: absolute;
	left: -1px;
	bottom: 1px;
	height: 12px;
	width: 2px;
	background: #9d9d9d;
	z-index: 1;
`;

const TableDetailsWrapper = styled.div`
	position: relative;
	width: ${(props) => (props.isOpen ? 540 : 0)}px;
	height: fit-content;
	// background: blue;
	margin-right: ${(props) => (props.isOpen ? 20 : 0)}px;
	transition: width 200ms ease;
`;

function TimeLine(props) {
	const [showTableDetails, setShowTableDetails] = useState(false);
	const [bookingsOnTable, setbookingsOnTable] = useState([]);
	const [tableDetailsData, setTableDetailsData] = useState('');
	const [selectedBooking, setSelectedBooking] = useState('');
	const [isSaving, setIsSaving] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [bookingList, setBookingList] = useState([]);
	const [floors, setFloors] = useState([]);
	const hours = [];

	for (let i = -2; i <= 22; i++) {
		hours.push(moment(i * 3600 * 1000).format('HH'));
	}

	useEffect(() => {
		// console.log(props.bookingList);
		if (props.bookingList.length > 0) {
			setBookingList([...props.bookingList]);
		} else {
			setBookingList([]);
		}
		setFloors(props.floorList);
	}, [props.floorList, props.bookingList]);

	const toggleTableDetails = (booking) => {
		if (booking.id === undefined) {
			setShowTableDetails(!showTableDetails);
		} else {
			if (showTableDetails) {
				if (selectedBooking.id === booking.id) {
					setShowTableDetails(!showTableDetails);
				}
			} else {
				setShowTableDetails(!showTableDetails);
				// const SrcrollableContainer = document.getElementById('sub-container');
			}
		}
	};

	const handleBookingClick = (booking, table) => {
		let data = [booking];
		setSelectedBooking(booking);
		setbookingsOnTable(data);
		setTableDetailsData(table);
	};

	const changeTable = async (booking) => {
		if (isSaving) {
			return;
		}
		setIsSaving(true);
		setIsLoading(true);
		try {
			const response = await axios({
				url: `${apiUrl}/api/booking/update`,
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${props.token}`,
				},
				data: booking,
			});

			if (response) {
				const { status } = response;
				const { data } = response.data;
				if (status === 200) {
					setIsLoading(false);
					setIsSaving(false);
				}
			}
		} catch (err) {}
	};

	return (
		<Row style={{ marginRight: 0, marginLeft: 0 }}>
			{/* <Col sm={4} md={4} lg={4}>
				
			</Col> */}
			<Col sm={12} md={12} lg={12}>
				<MainContainer>
					<TableDetailsWrapper isOpen={showTableDetails}>
						<TableDetails
							showTableDetailsHandler={toggleTableDetails}
							showTableDetails={showTableDetails}
							saveBookingHandler={props.saveBookingHandler}
							token={props.token}
							floorList={props.floorList}
							selectedTableData={bookingsOnTable}
							tableDetailsData={tableDetailsData}
							selectedBooking={null}
							selectedShift={props.selectedShift}
							isChangingTable={props.isChangingTable}
							selectedTableId={props.selectedTableId}
							setIsChangingTable={props.setIsChangingTable}
							setShowTableDetails={setShowTableDetails}
							setSelectedTableId={props.setSelectedTableId}
							// selectedBookingHandler={setselectedBooking}
						/>
					</TableDetailsWrapper>

					<SubContainer>
						<HeaderRow
							id="timeline-header"
							style={{ position: '-webkit-sticky', position: 'sticky', top: '0px', zIndex: 4 }}
						>
							<span
								style={{
									position: '-webkit-sticky',
									position: 'sticky',
									left: '0px',
									top: 0,
									zIndex: 3,
								}}
							>
								<h4>Table</h4>
								<HeaderText style={{ right: 10 }}>Max</HeaderText>
							</span>
							{hours.length > 0
								? hours.map((time, key) => {
										if (key === 0) {
											return (
												<HeaderBlock key={key}>
													<HeaderTime style={{ left: 3 }}>
														{moment(time, 'hh').format('LT')}
													</HeaderTime>
												</HeaderBlock>
											);
										} else {
											return (
												<HeaderBlock key={key}>
													<HeaderTime>{moment(time, 'hh').format('LT')}</HeaderTime>
													<LineIndicator />
												</HeaderBlock>
											);
										}
								  })
								: ''}
						</HeaderRow>
						{floors.length > 0
							? floors.map((floor, key) => {
									return (
										<CollapsableFloor
											key={key}
											floor={floor}
											index={key}
											bookings={bookingList}
											toggleTableDetails={toggleTableDetails}
											bookingClickHandler={handleBookingClick}
											changeTable={changeTable}
										/>
									);
							  })
							: ''}
					</SubContainer>
					{isLoading ? (
						<div
							style={{
								position: 'absolute',
								top: 0,
								left: 0,
								height: '100%',
								width: '100%',
								zIndex: 6,
							}}
						>
							<DotLoader />
						</div>
					) : (
						''
					)}
				</MainContainer>
			</Col>
		</Row>
	);
}

export default TimeLine;
