import { up, only, down } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';

export const useBreakpoints = () => {
	const isSm = useBreakpoint(down('sm'));
	const isMd = useBreakpoint(only('md'));
	const isLg = useBreakpoint(only('lg'));
	const isXl = useBreakpoint(up('xl'));

	return {
		isSm,
		isMd,
		isLg,
		isXl,
	};
};
