import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Col, Form, Button, Spinner } from 'react-bootstrap';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import image2 from '../assets/images/stripe.png';
import countryList from '../constants/countries';
import {
	// useDispatch,
	useSelector,
} from 'react-redux';
import axios from 'axios';
import { apiUrl } from '../utils/Utility';
// import { userActions } from '../actions/userActions';

const Box = styled.div`
	padding: 30px;
	margin: auto;
	background: #ffffff;
	border-radius: 10px;
`;

const PaymentWrap = styled.div`
	border: 1px solid #ccc;
	border-radius: 5px;
	margin-bottom: 1rem;
`;

const Divider = styled.hr`
	margin: 0;
	border: 0;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

const FormRow = styled.div`
	padding: 0.5rem;
`;

const FormInput = styled.input`
	border: none !important;
	box-shadow: none !important;
	color: #000000 !important;
`;

const FormSelect = styled.select`
	border: none !important;
	box-shadow: none !important;
	color: #000000 !important;
`;

const CardForm = ({ addPayment = true, addCouponModal }) => {
	const stripe = useStripe();
	const elements = useElements();

	const [isLoading, setIsLoading] = useState(false);
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [billAddress, setBillAddress] = useState('');
	const [billCity, setBillCity] = useState('');
	const [billState, setBillState] = useState('');
	const [billZip, setBillZip] = useState('');
	const [billCountry, setBillCountry] = useState('Australia');

	// const dispatch = useDispatch();
	const userReducers = useSelector(({ userReducers }) => userReducers);

	const handleSubmit = async (event) => {
		setIsLoading(true);
		// Block native form submission.
		event.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not loaded yet. Make sure to disable
			// form submission until Stripe.js has loaded.
			return;
		}

		// const billingPayment = {
		// 	name: name,
		// 	email: email,
		// 	address: billAddress,
		// 	city: billCity,
		// 	state: billState,
		// 	zip: billZip,
		// 	country: billCountry,
		// };

		const cardElement = elements.getElement(CardElement);

		// const { error, paymentMethod } = await stripe.createPaymentMethod({
		// 	type: 'card',
		// 	card: cardElement,
		// });
		const { error, token } = await stripe.createToken(cardElement);
		if (error) {
			//console.log('[error]', error);
			alert(error.message);
			setIsLoading(false);
		} else {
			try {
				await axios({
					url: `${apiUrl}/api/subscribe`,
					method: 'POST',
					data: {
						user_id: userReducers.user.userId,
						token: token.id,
						card: token.card,
						name: name,
						email: email,
						addPayment: addPayment,
					},
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${userReducers.token}`,
					},
				}).then((resp) => {
					if (resp.data.status_code === 200) {
						window.location.reload();
					}
				});
			} catch (err) {
				setIsLoading(false);
				console.log(err);
			}
		}
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		setName(userReducers.billingPayment.name);
		setEmail(userReducers.billingPayment.name);
	}, []);

	return (
		<Box className="box-payment box-bill">
			<div>
				<img src={image2} width={159} height={20} style={{ marginRight: 20, marginBottom: 10 }} />
			</div>

			<PaymentWrap>
				<FormRow className="row align-items-center">
					<Col md={2}>
						<span className="sp">Name</span>
					</Col>
					<Col md={10}>
						<Form.Group style={{ marginBottom: 0 }} controlId="formName">
							<FormInput
								type="text"
								value={name}
								onChange={(e) => setName(e.target.value)}
								placeholder="Fullname"
								className="form-control"
							/>
						</Form.Group>
					</Col>
				</FormRow>
				<Divider />

				<FormRow className="row align-items-center">
					<Col md={2}>
						<span className="sp">Email</span>
					</Col>
					<Col md={10}>
						<Form.Group style={{ marginBottom: 0 }} controlId="formEmail">
							<FormInput
								type="text"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								className="form-control"
								placeholder="Email address"
							/>
						</Form.Group>
					</Col>
				</FormRow>
				<Divider />

				<FormRow className="row align-items-center">
					<Col md={2}>
						<span className="sp">Address</span>
					</Col>
					<Col md={10}>
						<Form.Group style={{ marginBottom: 0 }} controlId="formAddress">
							<FormInput
								type="text"
								value={billAddress}
								onChange={(e) => setBillAddress(e.target.value)}
								className="form-control"
								placeholder="Complete address"
							/>
						</Form.Group>
					</Col>
				</FormRow>
				<Divider />

				<FormRow className="row align-items-center">
					<Col md={2}>
						<span className="sp">City</span>
					</Col>
					<Col md={10}>
						<Form.Group style={{ marginBottom: 0 }} controlId="formCity">
							<FormInput
								value={billCity}
								onChange={(e) => setBillCity(e.target.value)}
								className="form-control"
								type="text"
								placeholder="City"
							/>
						</Form.Group>
					</Col>
				</FormRow>
				<Divider />

				<FormRow className="row align-items-center">
					<Col md={2}>
						<span className="sp">State</span>
					</Col>
					<Col md={5}>
						<Form.Group style={{ marginBottom: 0 }} controlId="formState">
							<FormInput
								type="text"
								value={billState}
								onChange={(e) => setBillState(e.target.value)}
								className="form-control"
								placeholder="State"
							/>
						</Form.Group>
					</Col>
					<Col md={2}>
						<span className="sp">ZIP</span>
					</Col>
					<Col md={3}>
						<Form.Group style={{ marginBottom: 0 }} controlId="formZip">
							<FormInput
								type="text"
								value={billZip}
								onChange={(e) => setBillZip(e.target.value)}
								className="form-control"
								placeholder="Zip"
							/>
						</Form.Group>
					</Col>
				</FormRow>
				<Divider />

				<FormRow className="row align-items-center">
					<Col md={2}>
						<span className="sp">Country</span>
					</Col>
					<Col md={10}>
						<Form.Group style={{ marginBottom: 0 }} controlId="formSelect">
							<FormSelect
								value={billCountry}
								onChange={(e) => setBillCountry(e.target.value)}
								as="select"
								className="form-control"
								custom
							>
								{countryList.map((item) => (
									<option key={item.name} value={item.name}>
										{item.name}
									</option>
								))}
							</FormSelect>
						</Form.Group>
					</Col>
				</FormRow>
			</PaymentWrap>

			<div style={{ marginBottom: '10px' }}>
				<h3>Payment Method *</h3>
			</div>

			<PaymentWrap>
				<FormRow className="row align-items-center">
					<Col md={2}>
						<span style={{ marginTop: 7 }} className="sp">
							Card
						</span>
					</Col>
					<Col md={10}>
						<CardElement
							options={{
								hidePostalCode: true,
								style: {
									base: {
										fontSize: '16px',
										color: '#424770',
										height: '100px',
										'::placeholder': {
											color: '#B3B3B3',
										},
									},
									invalid: {
										color: '#9e2146',
									},
								},
							}}
						/>
					</Col>
				</FormRow>
			</PaymentWrap>

			<div style={{ margin: 'auto', textAlign: 'center' }}>
				<Button onClick={handleSubmit} className="btn-pay" disable={isLoading}>
					{isLoading ? (
						<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
					) : (
						<>PAY</>
					)}
				</Button>
			</div>
			<div style={{ margin: 'auto', textAlign: 'center', paddingTop: '10px' }}>
				<Button onClick={addCouponModal} className="btn-coupon" disable={isLoading}>
					ADD COUPON
				</Button>
			</div>
		</Box>
	);
};

export default CardForm;
