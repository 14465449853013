import axios from 'axios';
import { isError } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { apiUrl } from 'utils/Utility';

const LiteCardLandingPage = (props) => {
	const params = props.match.params;
	const [bookingId, setBookingId] = useState(null);
	// const data = {
	// 	downloadId: atob(params.downloadId),
	// };

	const getPass = async () => {
		try {
			const response = await axios({
				method: 'POST',
				url: `${apiUrl}/api/widget/download_litecard`,
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${atob(params.id)}`,
				},
				data: {
					downloadId: atob(params.downloadId),
				},
			});

			if (response) {
				const { status } = response;
				const data = response.data.data;

				if (status == 200) {
					switch (params.type) {
						case 'googlePass':
							window.location.replace(data.downloadUri.googleLink);
							break;

						case 'applePass':
							window.location.replace(data.downloadUri.appleLink);
							break;

						default:
							break;
					}
				}
			}
		} catch (err) {
			console.log('Error: ', err);
		}
	};

	useEffect(() => {
		getPass();
	}, []);

	return (
		<Container fluid>
			<Row>
				<Col
					fluid
					style={{
						textAlign: 'center',
					}}
				>
					<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
				</Col>
			</Row>
		</Container>
	);
};

export default LiteCardLandingPage;
