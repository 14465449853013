import React, { useRef, useEffect } from 'react';
import { List } from 'react-virtualized';
import plusIcon from 'assets/images/booking/create-booking/icon-add-white.png';
import styled from 'styled-components';

const Wrapper = styled.div`
	position: absolute;
	right: 75px;
	top: 220px;
	display: flex;
	flex-direction: column;
	width: 215px;
	padding: 0;
	min-height: 37px;
	max-height: 560px;
	box-shadow: 2px 3px 10px #1c1c1e4d;
	font-size: 14px;
	font-weight: 200;
	overflow-y: auto;
	color: black;
	z-index: 1;
	transition: ease 500ms;
	background: #ffffff;

	.ReactVirtualized__Grid.ReactVirtualized__List {
		z-index: 5;
		::-webkit-scrollbar {
			width: 8px;
			background-color: transparent;
		}
		::-webkit-scrollbar-thumb {
			width: 8px;
			border-right: 4px white solid;
			background-color: #70707133;
		}

		::-webkit-scrollbar-corner {
			display: none;
		}
	}
`;

const WagePayListContainer = styled.div`
	display: flex;
	align-items: center;
	width: 215px;
	padding: 0 15px;
	height: 37px;
	box-shadow: 2px 3px 10px #1c1c1e4d;
	font-size: 14px;
	font-weight: 200;
	cursor: pointer;
	color: #ffffff;
	background: #ff7383;
	z-index: 1;

	img {
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-o-user-select: none;
		user-select: none;
		margin-right: 5px;
	}
`;

const ListItem = styled.div`
	display: flex;
	align-items: center;
	min-height: 35px;
	width: 100%;
	padding: 10px 15px;
	font-size: 14px;
	font-weight: 480;
	color: #656565;
	cursor: pointer;
	user-select: none;
	background: #ffffff;
`;

function WageItemListContainer(props) {
	const wrapperRef = useRef(null);
	useOuteSideClick(wrapperRef);

	function useOuteSideClick(ref) {
		useEffect(() => {
			function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target)) {
					handleClose();
				}
			}
			document.addEventListener('mousedown', handleClickOutside);
			return () => {
				document.removeEventListener('mousedown', handleClickOutside);
			};
		}, [ref]);
	}

	const handleClose = () => {
		props.setShowAddWageButton(false);
	};

	const addWageItemClick = () => {
		props.setIsAddingWageItem(true);
		handleClose();
	};
	// console.log(props.wageItems);

	const handleClickWageItem = (item) => {
		props.wageItemClickCallback(item);
	};

	return (
		<Wrapper ref={wrapperRef}>
			<WagePayListContainer onClick={addWageItemClick}>
				<img src={plusIcon} width={20} height={20} alt="add-icon" /> Create new wage item
			</WagePayListContainer>
			<List
				width={215}
				height={props.wageItems.length * 37}
				rowHeight={37}
				rowCount={props.wageItems.length}
				rowRenderer={({ key, index, style }) => {
					let newStyle = { ...style };
					const wageItem = props.wageItems[index];
					if (props.selectedItem) {
						if (props.selectedItem.includes(wageItem.id)) {
							newStyle = { ...style, color: '#f46587' };
						}
					}
					return (
						<ListItem onClick={() => handleClickWageItem(wageItem)} style={newStyle} key={key}>
							{wageItem.name} - {wageItem.rate}.0
						</ListItem>
					);
				}}
			/>
		</Wrapper>
	);
}

export default WageItemListContainer;
