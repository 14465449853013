import React, { useState, useEffect, useRef } from 'react';
import { useDrop } from 'react-dnd';
import { Spinner } from 'react-bootstrap';
import DraggableItem from '../SubComponents/DraggableItem';
import {
	getRadWidth,
	getRadHeight,
	tableNamePositionLeft,
	tableNamePositionLeftTranslate,
	tableNamePositionMarginLeft,
	tableNamePositionMarginTop,
	getTableWidth,
	getTableHeight,
	getPositionTop,
	getPositionLeft,
	radToDeg,
	getTableImg,
} from '../../../../../../utils/Utility';
import { ITEMTYPE } from '../../../../../../constants/enum';
import DragCloneItem from './DragCloneItem';

function DragNdrop(props) {
	const [tableList, setTableList] = useState([]);
	const [tableId, settableId] = useState(-1);

	const [{ isOver }, drop] = useDrop(() => ({
		accept: ['newTable', 'existingTable'],
		drop: (item, monitor) => [
			handleIdCheck(item.type, item.id, monitor.getDifferenceFromInitialOffset()),
		],
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
		}),
	}));

	const handleIdCheck = (type, id, diffFromInitOffset) => {
		if (type === ITEMTYPE.newTable) {
			props.handleAddTable(id);
		} else {
			moveTablePosition(id, diffFromInitOffset);
			props.selectedTableIdCallBack(id);
			settableId(id);
		}
	};

	const selectedTablehandler = (table) => {
		props.selectedTableIdCallBack(table.id === 0 ? table.uuid : table.id);
		handleSelectedTable(table.id === 0 ? table.uuid : table.id);
	};

	const moveTablePosition = (id, posDiff) => {
		let list = props.stateRef.current;
		// console.log(id);
		if (Number.isInteger(id)) {
			const idx = list.findIndex((e) => id === e.id);
			const tablePos = {
				x: list[idx].pos_x,
				y: list[idx].pos_y,
			};
			const newPos = {
				x: tablePos.x + posDiff.x,
				y: tablePos.y + posDiff.y,
			};

			list[idx].pos_x = newPos.x;
			list[idx].pos_y = newPos.y;
			// setTableList({ ...list[idx], pos_x: newPos.x, pos_y: newPos.y });
			setTableList(list);
			props.handleMoveTable(list);
			props.settableList(list);
		} else {
			const idx = list.findIndex((e) => id === e.uuid);
			const tablePos = {
				x: list[idx].pos_x,
				y: list[idx].pos_y,
			};

			const newPos = {
				x: tablePos.x + posDiff.x,
				y: tablePos.y + posDiff.y,
			};

			list[idx].pos_x = newPos.x;
			list[idx].pos_y = newPos.y;
			props.handleMoveTable(list);
			setTableList(list);
			props.settableList(list);
		}
	};

	const handleSelectedTable = (id) => {
		if (tableId === -1) {
			settableId(id);
		} else {
			if (id === tableId) {
				settableId(-1);
			} else {
				settableId(id);
			}
		}
	};

	const tableChecker = (id, uuid, selectedId) => {
		if (Number.isInteger(selectedId)) {
			if (id === selectedId) {
				return 'img-filter';
			} else {
				return;
			}
		} else {
			if (uuid === selectedId) {
				return 'img-filter';
			} else {
				return;
			}
		}
	};

	useEffect(() => {
		setTableList(props.tableList);
		// settableId(-1);
	}, [props.tableList]);

	useEffect(() => {
		settableId(-1);
		// settableId(
		// 	-1, setTimeout(() => {

		// 	}, timeout)
		// );
	}, [props.monitor]);

	return (
		<div ref={drop} id={props.id} className={props.className}>
			{tableList.length > 0
				? tableList.map((table) => (
						<DraggableItem
							key={Math.random()}
							id={table.id === 0 ? table.uuid : table.id}
							className="tfp-card-item"
							style={{
								position: 'absolute',
								top: table.pos_y,
								left: table.pos_x,
								width: getRadWidth(table.rotate_deg, table.table_type),
								height: getRadHeight(table.rotate_deg, table.table_type),
								color: 'white',
								fontSize: 10,
								cursor: 'pointer',
								zIndex: 1,
							}}
						>
							<div
								onClick={(e) => selectedTablehandler(table)}
								key={Math.random()}
								style={{
									position: 'relative',
									width: getRadWidth(table.rotate_deg, table.table_type),
									height: getRadHeight(table.rotate_deg, table.table_type),
									zIdzIndex: 1,
								}}
							>
								<span
									key={Math.random()}
									style={{
										position: 'absolute',
										zIndex: 1,
										left: tableNamePositionLeft(table.table_type),
										transform: `translateX(${tableNamePositionLeftTranslate(table.table_type)})`,
										marginLeft: tableNamePositionMarginLeft(table.table_type, table.rotate_deg),
										marginTop: tableNamePositionMarginTop(table.table_type, table.rotate_deg),
									}}
								>
									{table.name}
								</span>

								<div
									key={Math.random()}
									style={{
										width: getTableWidth(table.table_type),
										height: getTableHeight(table.table_type),
										transformOrigin: 'top left',
										position: 'absolute',
										top: getPositionTop(table.rotate_deg, table.table_type),
										left: getPositionLeft(table.rotate_deg, table.table_type),
										transform: `rotate(${radToDeg(table.rotate_deg)}deg)`,
										zIndex: 0,
									}}
								>
									<img
										draggable="false"
										key={Math.random()}
										src={getTableImg(table.table_type)}
										width={`${getTableWidth(table.table_type)}`}
										height={`${getTableHeight(table.table_type)}`}
										className={tableChecker(table.id, table.uuid, tableId)}
										alt="checker"
									/>
								</div>
							</div>
						</DraggableItem>
				  ))
				: // <div
				  // 	style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}
				  // >
				  // 	<Spinner
				  // 		as="span"
				  // 		animation="border"
				  // 		size="md"
				  // 		role="status"
				  // 		aria-hidden="false"
				  // 		style={{ border: '0.25em solid #ff7192', borderRightColor: '#f8f9fa03' }}
				  // 	/>
				  // </div>
				  ''}
			<DragCloneItem />
		</div>
	);
}

export default DragNdrop;
