import React, { useEffect, useState } from 'react';
import Sidebar from 'react-sidebar';
import { Container, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';

import SideMenu from './SideMenu';

import logowhite from '../assets/images/logo-white.png';
import logoblack from '../assets/images/logo.png';

function withDashboardApp(WrappedComponent) {
	return function (props) {
		const mql = window.matchMedia(`(min-width: 800px)`);

		const location = useLocation();

		const history = useHistory();
		const [sidebarOpen, setSidebarOpen] = useState(true);
		const [sidebarDocked, setSidebarDocked] = useState(mql.matches);

		const goToHomePage = () => history.push('/');

		const mediaQueryChanged = () => {
			setSidebarDocked(mql.matches);
			setSidebarOpen(false);
		};

		const toggleSidebar = (e) => {
			setSidebarOpen(!sidebarOpen);
		};

		useEffect(() => {
			window.scrollTo(0, 0);
			mql.addListener(mediaQueryChanged);
			// console.log(mql.matches);
			// if (!mql.matches) {
			// 	setSidebarOpen(false);
			// }
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [mql]);

		// useEffect(() => {
		// 	console.log(cookie.get('token'));
		// }, [cookie]);

		return (
			<Container fluid>
				<Row style={{ margin: 0 }}>
					<Sidebar
						style={{ backgroundColor: '#222A2F' }}
						sidebarClassName="sidebar"
						sidebar={
							<div>
								<div style={{ width: 150, margin: 'auto' }}>
									<button type="button" onClick={goToHomePage}>
										<img
											alt="logo"
											src={logowhite}
											width="150"
											height="44"
											className="d-inline-block align-top"
											style={{ verticalAlign: 'middle' }}
										/>
									</button>
								</div>
								<SideMenu />
							</div>
						}
						transitions={false}
						open={sidebarOpen}
						docked={sidebarDocked}
						onSetOpen={() => setSidebarOpen(!sidebarOpen)}
					>
						<div
							style={
								location.pathname === '/dashboard/booking/user-roles'
									? { background: '#F7F7F7', minHeight: '100%' }
									: location.pathname !== '/dashboard/booking' &&
									  location.pathname !== '/dashboard/booking/table-planning' &&
									  location.pathname !== '/dashboard/booking/guest-book' &&
									  location.pathname !== '/dashboard/booking/report' &&
									  location.pathname !== '/dashboard/booking/restaurant-profile'
									? { background: '#F7F7F7', padding: 30, minHeight: '100%' }
									: { background: '#222A2F', minHeight: '100%' }
							}
						>
							<div id="page-content-wrapper">
								<Row
									id="mob-d"
									style={{
										marginTop: 20,
										marginBottom: 20,
										marginLeft: 10,
										marginRight: 10,
										justifyContent: 'space-between',
									}}
								>
									<div style={{ width: 150 }}>
										<button type="button" onClick={goToHomePage}>
											<img
												alt="logo"
												src={logoblack}
												width="150"
												height="44"
												className="d-inline-block align-top"
												style={{ verticalAlign: 'middle' }}
											/>
										</button>
									</div>
									<button
										onClick={toggleSidebar}
										aria-controls="basic-navbar-nav"
										type="button"
										aria-label="Toggle navigation"
										className="navbar-toggler collapsed navbar-light"
									>
										<span className="navbar-toggler-icon"></span>
									</button>
								</Row>
								<WrappedComponent />
							</div>
						</div>
					</Sidebar>
				</Row>
			</Container>
		);
	};
}

export default withDashboardApp;
