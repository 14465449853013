import React, { useState } from 'react';
import MuiButton from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.black[800],
	},
});

const DialogTitle = withStyles((theme) => ({
	root: {
		textAlign: 'center',
		color: '#9b9b9b',
		fontSize: '0.5em',
	},
}))(MuiDialogTitle);
const Button = withStyles((theme) => ({
	root: {
		fontSize: '1em',
		color: '#9e9eef',
		borderTop: '3px solid #0000003d;',
	},
}))(MuiButton);

function AlertDialog(props) {
	const handleClose = () => {
		props.setAlertHandler(false);
	};

	return (
		<div>
			<Dialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={props.alertHandler}
			>
				<DialogTitle id="customized-dialog-title">
					<div
						style={{
							fontWeight: 'bold',
						}}
					>
						{props.dialogAlertMessage.title}
					</div>
					{props.dialogAlertMessage.body}
				</DialogTitle>

				<Button onClick={handleClose}>OK</Button>
			</Dialog>
		</div>
	);
}
export default AlertDialog;
