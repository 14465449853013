import React, { useState, useEffect, useRef } from 'react';
import {
	Chart,
	CategoryScale,
	LinearScale,
	BarController,
	BarElement,
	LineController,
	LineElement,
	PointElement,
	Tooltip,
	Filler,
	TimeScale,
	TimeSeriesScale,
} from 'chart.js';
import { GRAPHDATA } from 'constants/enum';
import styled from 'styled-components';
import 'chartjs-adapter-moment';
import moment from 'moment';
import { filterShifts } from 'utils/Utility';

Chart.register(
	CategoryScale,
	LinearScale,
	BarController,
	BarElement,
	LineController,
	LineElement,
	PointElement,
	TimeScale,
	// TimeSeriesScale,
	Filler,
	Tooltip,
);

const Container = styled.div`
	position: relative;
	width: 100%;
	height: 305px;
	backgound: #222a2f;
	box-shadow: inset 5px 5px 10px #1a2024, inset -5px -5px 10px #2a343a;
	border-radius: 10px;
`;

function BarGraph(props) {
	const chartContainer = useRef(null);
	const [restartChart, setrestartChart] = useState(false);
	const [chartInstance, setChartInstance] = useState(null);
	const [chartConfig, setChartConfig] = useState(null);
	const labelsData = [];

	for (let i = 0; i <= 6; i++) {
		// console.log(i);
		let date = { n: moment(props.selectedDate).subtract(i, 'days'), int: i };
		date = {
			...date,
			n: date.n.format('dddd DD MMM'),
			dateMatch: date.n.format('YYYY-MM-DD'),
			compareDate: date.n.subtract(1, 'months').format('YYYY-MM-DD'),
		};
		labelsData.push(date);
		if (i === 6) {
			labelsData.sort().reverse();
		}
	}

	const labels = labelsData.map((e) => e.n);
	const dateMatch = labelsData.map((e) => e.dateMatch);
	const compareDateMatch = labelsData.map((e) => e.compareDate);
	const compareType =
		props.compareType === 1 ? 'Last 4 weeks' : 'This week last year average covers';
	// console.log(props.compareType);

	const weekDataChecker = (date, data, opt) => {
		// opt = 1 for booking, 2 for walkIns
		let int = 0;
		if (opt === 1) {
			if (data !== undefined) {
				data.booking.dateRanges.map((e) => {
					if (e.date.includes(date)) {
						return ++int;
					}
				});
			}
		} else if (opt === 2) {
			if (data !== undefined) {
				data.walkIns.dateRanges.map((e) => {
					if (e.date.includes(date)) {
						return ++int;
					}
				});
			}
		}
		return int;
	};

	const dayDataChecker = (array, shifts) => {
		let data = [];
		let prevHour = 0;
		let prevData;
		if (array.length > 0 && shifts.length > 0) {
			for (let i = 0; i <= array.length - 1; i++) {
				const [date, time] = array[i].start.split(' ');
				const [hours, minutes, seconds] = time.split(':');
				let formatedtime = moment().set('hour', hours).startOf('hour').format('HH') + ':00:00';
				let xData = `${moment(date).format('YYYY-MM-DD')} ${formatedtime}`; //new Date()
				if (i === 0) {
					// const totalSeconds = +hours * 60 * 60 + +minutes * 60 + +seconds;
					prevData = {
						x: xData,
						y: array[i].party_size,
					};
					let indexData = {
						x: `${moment(date)
							.set('hour', shifts[0].start_time / 3600)
							.format('YYYY-MM-DD HH:00:00')}`,
						y: 0,
					};
					data.push(indexData);
					data.push(prevData);
				}
				if (i > prevHour) {
					prevHour = i - 1;
					let idx = data.findIndex((e) => e.x === prevData.x);
					const [_date, _time] = data[idx].x.split(' ');
					const [_hours, minutes] = _time.split(':');
					if (hours === _hours) {
						let yData = data[idx].y + array[i].party_size;
						let newData = {
							x: xData,
							y: yData,
						};
						data.splice(idx, 1, newData);
						prevData = newData;
					} else {
						let newData = {
							x: xData,
							y: array[i].party_size,
						};
						data.push(newData);
						prevData = newData;
					}
					if (i === array.length - 1) {
						let lastData = {
							x: `${moment(date)
								.set('hour', shifts[1].end_time / 3600)
								.format('YYYY-MM-DD HH:00:00')}`,
							y: 0,
						};
						data.push(lastData);
					}
				}
			}
		} else {
			return data;
		}
		return data;
	};

	const shiftDataChecker = (array, shift) => {
		let data = [];
		let prevInstance = 0;
		let prevData;
		if (shift === undefined) {
			return data;
		}
		const startTime = moment(props.selectedDate).set('seconds', shift.start_time).format('H');
		const endTime = moment(props.selectedDate).set('seconds', shift.end_time).format('H');
		const test = array.filter((e) => {
			const [date, time] = e.start.split(' ');
			const [h, m, s] = time.split(':');
			// if (h >= startTime && h <= endTime) {
			// 	console.log(e);
			// }
		});
		if (array.length > 0) {
			for (let i = 0; i <= array.length - 1; i++) {
				const [date, time] = array[i].start.split(' ');
				const [h, m, s] = time.split(':');
				let formatedtime = moment().set('hour', h).startOf('hour').format('HH') + ':00:00';
				let xData = `${moment(date).format('YYYY-MM-DD')} ${formatedtime}`; //new Date()
				if (i === 0) {
					prevData = {
						x: `${moment(date)
							.set('hour', shift.start_time / 3600)
							.format('YYYY-MM-DD HH:00:00')}`,
						y: 0,
					};
					data.push(prevData);
				}
				if (h >= startTime && h <= endTime) {
					if (i > prevInstance) {
						prevInstance = i - 1;

						let idx = data.findIndex((e) => e.x === prevData.x);
						const [_date, _time] = data[idx].x.split(' ');
						const [_hours, minutes] = _time.split(':');
						if (h === _hours) {
							let yData = data[idx].y + array[i].party_size;
							let newData = {
								x: xData,
								y: yData,
							};
							data.splice(idx, 1, newData);
							prevData = newData;
						} else {
							let newData = {
								x: xData,
								y: array[i].party_size,
							};
							data.push(newData);
							prevData = newData;
						}
					}
				}
				if (i === array.length - 1) {
					let lastData = {
						x: `${moment(date)
							.set('hour', shift.end_time / 3600)
							.format('YYYY-MM-DD HH:00:00')}`,
						y: 0,
					};
					data.push(lastData);
				}
			}
		}
		// console.log(data);
		return data;
	};

	const createChartConfig = (reports, type) => {
		switch (type) {
			case GRAPHDATA.week:
				setChartConfig({
					type: 'bar',
					data: {
						labels: labels,
						datasets: [
							{
								label: 'WalkIns-Current',
								data: [
									weekDataChecker(dateMatch[0], reports.bookingRangeReports, 2),
									weekDataChecker(dateMatch[1], reports.bookingRangeReports, 2),
									weekDataChecker(dateMatch[2], reports.bookingRangeReports, 2),
									weekDataChecker(dateMatch[3], reports.bookingRangeReports, 2),
									weekDataChecker(dateMatch[4], reports.bookingRangeReports, 2),
									weekDataChecker(dateMatch[5], reports.bookingRangeReports, 2),
									weekDataChecker(dateMatch[6], reports.bookingRangeReports, 2),
								],
								backgroundColor: '#FF8585',
								borderRadius: 5,
								categoryPercentage: 0.4,
								stack: 'Stack 0',
								order: 1,
							},
							{
								label: 'Booking-Current',
								data: [
									weekDataChecker(dateMatch[0], reports.bookingRangeReports, 1),
									weekDataChecker(dateMatch[1], reports.bookingRangeReports, 1),
									weekDataChecker(dateMatch[2], reports.bookingRangeReports, 1),
									weekDataChecker(dateMatch[3], reports.bookingRangeReports, 1),
									weekDataChecker(dateMatch[4], reports.bookingRangeReports, 1),
									weekDataChecker(dateMatch[5], reports.bookingRangeReports, 1),
									weekDataChecker(dateMatch[6], reports.bookingRangeReports, 1),
								],
								backgroundColor: '#C76467',
								categoryPercentage: 0.4,
								stack: 'Stack 0',
								order: 0,
							},
							{
								label: `${'WalkIns-' + compareType}`,
								data: [
									weekDataChecker(compareDateMatch[0], reports.compareRangeReports, 2),
									weekDataChecker(compareDateMatch[1], reports.compareRangeReports, 2),
									weekDataChecker(compareDateMatch[2], reports.compareRangeReports, 2),
									weekDataChecker(compareDateMatch[3], reports.compareRangeReports, 2),
									weekDataChecker(compareDateMatch[4], reports.compareRangeReports, 2),
									weekDataChecker(compareDateMatch[5], reports.compareRangeReports, 2),
									weekDataChecker(compareDateMatch[6], reports.compareRangeReports, 2),
								],
								backgroundColor: '#FF8585',
								borderRadius: 5,
								categoryPercentage: 0.4,
								stack: 'Stack 1',
								order: 1,
							},
							{
								label: `${'Booking-' + compareType}`,
								data: [
									weekDataChecker(compareDateMatch[0], reports.compareRangeReports, 1),
									weekDataChecker(compareDateMatch[1], reports.compareRangeReports, 1),
									weekDataChecker(compareDateMatch[2], reports.compareRangeReports, 1),
									weekDataChecker(compareDateMatch[3], reports.compareRangeReports, 1),
									weekDataChecker(compareDateMatch[4], reports.compareRangeReports, 1),
									weekDataChecker(compareDateMatch[5], reports.compareRangeReports, 1),
									weekDataChecker(compareDateMatch[6], reports.compareRangeReports, 1),
								],
								backgroundColor: '#C76467',
								categoryPercentage: 0.4,
								stack: 'Stack 1',
								order: 0,
							},
						],
					},
					options: {
						maintainAspectRatio: false,
						scales: {
							x: {
								stacked: true,
								grid: {
									display: false,
								},
								ticks: {
									padding: 1,
								},
							},
							y: {
								grid: {
									drawBorder: false,
									color: '#2f363b',
									grace: '5%',
								},
								ticks: {
									padding: 10,
								},
							},
						},
					},
				});
				break;
			case GRAPHDATA.day:
				let compareDate = moment(props.selectedDate);
				// console.log(props.shifts);
				if (props.compareType === 1) {
					compareDate = compareDate.subtract(1, 'months').format('YYYY-MM-DD');
				} else {
					compareDate = compareDate.subtract(1, 'year').format('YYYY-MM-DD');
				}
				// console.log(compareDate);
				const comWalkInsRange = reports.compareRangeReports.walkIns.dateRanges.filter(
					(e) => e.date === compareDate,
				);
				const comBookingRange = reports.compareRangeReports.booking.dateRanges.filter(
					(e) => e.date === compareDate,
				);
				const crntWalkInsRange = reports.bookingRangeReports.walkIns.dateRanges.filter(
					(e) => e.date === props.selectedDate,
				);
				const crntBookingRange = reports.bookingRangeReports.booking.dateRanges.filter(
					(e) => e.date === props.selectedDate,
				);
				const shifts = filterShifts(props.shifts, moment(props.selectedDate).isoWeekday());
				// console.log(
				// 	moment().set('second', shifts[0].start_time).startOf('hour').format('hh:mm:ss'),
				// );

				setChartConfig({
					type: 'line',
					data: {
						datasets: [
							{
								label: 'Current Booking',
								data: dayDataChecker(crntBookingRange, shifts).map((e) => {
									const [date, time] = e.x.split(' ');
									const [hour, min, sec] = time.split(':');
									let data = {
										x: moment(props.selectedDate).set('hour', parseInt(hour)).set('minute', 0),
										y: e.y,
									};
									return data;
								}),
								fill: true,
								borderColor: 'rgb(199, 100, 103, 1)',
								borderWidth: 1,
								backgroundColor: 'rgb(199, 100, 103, 1)',
								order: 3,
							},
							{
								label: 'CompareRange Booking',
								data: dayDataChecker(comBookingRange, shifts).map((e) => {
									const [date, time] = e.x.split(' ');
									const [hour, min, sec] = time.split(':');
									let data = {
										x: moment(props.selectedDate).set('hour', parseInt(hour)).set('minute', 0),
										y: e.y,
									};
									return data;
								}),
								fill: false,
								borderColor: '#8D2A2A',
								borderDash: [5, 5],
								borderWidth: 1,
								order: 1,
							},
							{
								label: 'Current Walk-in',
								data: dayDataChecker(crntWalkInsRange, shifts).map((e) => {
									const [date, time] = e.x.split(' ');
									const [hour, min, sec] = time.split(':');
									let data = {
										x: moment(props.selectedDate).set('hour', parseInt(hour)).set('minute', 0),
										y: e.y,
									};
									return data;
								}),
								fill: true,
								borderColor: 'rgb(255, 133, 133, 0.8)',
								borderWidth: 1,
								backgroundColor: 'rgb(255, 133, 133, 0.8)',
								order: 2,
							},
							{
								label: 'CompareRange Walk-in',
								data: dayDataChecker(comWalkInsRange, shifts).map((e) => {
									const [date, time] = e.x.split(' ');
									const [hour, min, sec] = time.split(':');
									let data = {
										x: moment(props.selectedDate).set('hour', parseInt(hour)).set('minute', 0),
										y: e.y,
									};
									return data;
								}),
								fill: false,
								borderColor: '#FF977C',
								borderDash: [5, 5],
								borderWidth: 1.5,
								order: 0,
							},
						],
					},
					options: {
						tension: 0.35,
						maintainAspectRatio: false,
						elements: {
							point: {
								radius: 0,
							},
						},
						scales: {
							x: {
								offset: true,
								grid: {
									display: false,
								},
								type: 'time',
								time: {
									unit: 'hour',
									displayFormats: {
										hour: 'LT',
									},
								},
								min: new Date(`${props.selectedDate}T11:00:00`),
								max: new Date(`${props.selectedDate}T22:00:00`),
								ticks: {
									padding: 1,
								},
							},
							y: {
								grid: {
									drawBorder: false,
									color: '#2f363b',
									grace: '5%',
								},
								ticks: {
									padding: 10,
								},
							},
						},
					},
				});
				break;
			case GRAPHDATA.shift:
				let selectedDate = moment(props.selectedDate);
				if (props.compareType === 1) {
					selectedDate = selectedDate.subtract(1, 'months').format('YYYY-MM-DD');
				} else {
					selectedDate = selectedDate.subtract(1, 'year').format('YYYY-MM-DD');
				}
				// console.log(compareDate);
				const compareWR = reports.compareRangeReports.walkIns.dateRanges.filter(
					(e) => e.date === selectedDate,
				);
				const compareBR = reports.compareRangeReports.booking.dateRanges.filter(
					(e) => e.date === selectedDate,
				);
				const currentWR = reports.bookingRangeReports.walkIns.dateRanges.filter(
					(e) => e.date === props.selectedDate,
				);
				const currentBR = reports.bookingRangeReports.booking.dateRanges.filter(
					(e) => e.date === props.selectedDate,
				);
				setChartConfig({
					type: 'line',
					data: {
						datasets: [
							{
								label: 'Current Booking',
								data: shiftDataChecker(currentBR, props.currentShift).map((e) => {
									const [date, time] = e.x.split(' ');
									const [hour, min, sec] = time.split(':');
									let data = {
										x: moment(props.selectedDate).set('hour', parseInt(hour)).set('minute', 0),
										y: e.y,
									};
									return data;
								}),
								fill: true,
								borderColor: 'rgb(199, 100, 103, 1)',
								borderWidth: 1,
								backgroundColor: 'rgb(199, 100, 103, 1)',
								order: 3,
							},
							{
								label: 'CompareRange Booking',
								data: shiftDataChecker(compareBR, props.currentShift).map((e) => {
									const [date, time] = e.x.split(' ');
									const [hour, min, sec] = time.split(':');
									let data = {
										x: moment(props.selectedDate).set('hour', parseInt(hour)).set('minute', 0),
										y: e.y,
									};
									return data;
								}),
								fill: false,
								borderColor: '#8D2A2A',
								borderDash: [5, 5],
								borderWidth: 1,
								order: 1,
							},
							{
								label: 'Current Walk-in',
								data: shiftDataChecker(currentWR, props.currentShift).map((e) => {
									const [date, time] = e.x.split(' ');
									const [hour, min, sec] = time.split(':');
									let data = {
										x: moment(props.selectedDate).set('hour', parseInt(hour)).set('minute', 0),
										y: e.y,
									};
									return data;
								}),
								fill: true,
								borderColor: 'rgb(255, 133, 133, 0.8)',
								borderWidth: 1,
								backgroundColor: 'rgb(255, 133, 133, 0.8)',
								order: 2,
							},
							{
								label: 'CompareRange Walk-in',
								data: shiftDataChecker(compareWR, props.currentShift).map((e) => {
									const [date, time] = e.x.split(' ');
									const [hour, min, sec] = time.split(':');
									let data = {
										x: moment(props.selectedDate).set('hour', parseInt(hour)).set('minute', 0),
										y: e.y,
									};
									return data;
								}),
								fill: false,
								borderColor: '#FF977C',
								borderDash: [5, 5],
								borderWidth: 1.5,
								order: 0,
							},
						],
					},
					options: {
						tension: 0.35,
						maintainAspectRatio: false,
						elements: {
							point: {
								radius: 0,
							},
						},
						scales: {
							x: {
								offset: true,
								grid: {
									display: false,
								},
								type: 'time',
								time: {
									unit: 'hour',
									displayFormats: {
										hour: 'LT',
									},
								},
								min: new Date(
									`${props.selectedDate}T${moment(props.selectedDate)
										.hour(props.currentShift.start_time / 3600)
										.startOf('hour')
										.format('HH:mm:ss')}`,
								),
								max: new Date(
									`${props.selectedDate}T${moment(props.selectedDate)
										.hour(props.currentShift.end_time / 3600)
										.startOf('hour')
										.format('HH:mm:ss')}`,
								),
								ticks: {
									padding: 1,
								},
							},
							y: {
								grid: {
									drawBorder: false,
									color: '#2f363b',
									grace: '5%',
								},
								ticks: {
									padding: 10,
								},
							},
						},
					},
				});
				break;
		}
	};

	useEffect(() => {
		if (chartContainer && chartContainer.current) {
			if (chartInstance !== null) {
				chartInstance.destroy();
				setChartInstance(null);
				setrestartChart(true);
			} else {
				if (props.reports && props.selectedGraph) {
					if (chartConfig !== null) {
						if (restartChart) {
							createChartConfig(props.reports, props.selectedGraph);
							setrestartChart(false);
						} else {
							let newChartInstance = new Chart(chartContainer.current, chartConfig);
							// console.log(chartConfig);
							setChartInstance(newChartInstance);
						}
					} else {
						createChartConfig(props.reports, props.selectedGraph);
					}
				}
			}
		}
	}, [
		props.reports,
		props.currentShift,
		props.selectedGraph,
		props.selectedDate,
		chartConfig,
		restartChart,
	]);

	return (
		<Container>
			<div style={{ position: 'relative', height: 305, width: '100%' }}>
				<canvas height={305} width={1008} ref={chartContainer} />
			</div>

			{/* <Bar options={options} data={data}/> */}
		</Container>
	);
}

export default BarGraph;
