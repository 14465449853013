export const TABLETYPE = {
	singleTable: 1,
	twoSingleTable: 2,
	threeSingleTable: 3,
	singlePairTable: 4,
	twoSinglePairTable: 5,
	threeSinglePairTable: 6,
	fourSinglePairTable: 7,
	fourPersonSingleTable: 8,
	fourPersonRoundTable: 9,
	sixPersonRoundTable: 10,
	eightPersonRoundTable: 11,
	tenPersonRoundTable: 12,
	halfSeatRoundTable: 13,
	twoPersonRoundTable: 14,
};

export const BOOKINGSTATUS = {
	unconfirmed: 0,
	all: 1,
	billed: 2,
	unbill: 3,
	seated: 4,
	unseat: 5,
	finished: 6,
	noShow: 7,
	cancelled: 8,
	seatNotWaitList: 9,
	waitList: 10,
	overTime: 11,
	upcoming: 12,
	late: 13,
	needAttention: 14,
	partiallySeated: 15,
};

export const BOOKINGTYPE = {
	inhouse: 1,
	phone: 2,
	experience: 3,
	widget: 0,
};

export const RECURRINGTYPE = {
	none: -1,
	annually: 0,
	monthly: 1,
	weekly: 2,
};

export const RECURRINGVALUE = {
	none: 0,
	everyDayOfMonth: 1,
	everyDateOfMonth: 2,
	everyNumWeekOfMonth: 3,
	everyMonthDateOfYear: 4,
	everyWeekOfTheYear: 5,
};

export const ITEMTYPE = {
	newTable: 1,
	existingTable: 2,
};

export const GRAPHDATA = {
	week: '1',
	day: '2',
	shift: '3',
};

export const PAYBASIS = {
	hourly: 0,
	salary: 1,
};

export const PAYCYCLE = {
	weekly: 0,
	biWeekly: 1,
	monthly: 2,
};

export const EMPLOYEETYPE = {
	owner: 'owner',
	manager: 'manager',
	staff: 'staff',
};

export const WAGEPAYTYPE = {
	holiday: 1,
	weekend: 2,
};

export const WAGEWEEKENDTYPE = {
	saturday: 1,
	sunday: 2,
};

export const SHIFTTYPE = {
	shift: 1,
	requestedOff: 2,
	onLeave: 3,
};
