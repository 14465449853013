import axios from 'axios';
import { isError } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Container, Row, Form, Spinner } from 'react-bootstrap';
import { apiUrl } from 'utils/Utility';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import { Link, withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { FaRegUser, FaFacebook } from 'react-icons/fa';
import { BsUnlock } from 'react-icons/bs';

import Header from 'components/Revamp/Header';
import Footer from 'components/Revamp/Footer';
import { Formik } from 'formik';

const signUpSchema = Yup.object().shape({
	password: Yup.string().required('password required'),
	passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

const Spacer = styled.div`
	margin-top: ${(props) => props.propsMtop || 0};
	margin-bottom: ${(props) => props.propsMbottom || 0};
	margin-right: ${(props) => props.propsMright || 0};
	margin-left: ${(props) => props.propsMleft || 0};
	height: ${(props) => props.propsHeight};
`;

const Wrap = {
	LeftWrap: styled.div`
		background: transparent linear-gradient(148deg, #f5dba2 0%, #fa327e 100%) 0% 0% no-repeat
			padding-box;
		border-radius: 35px;
		height: auto;
		padding: 0rem;
		text-align: left;
	`,
	InWrap: styled.div`
		padding: 5rem;
	`,
	InnerWrap: styled.div`
		// background: hsla(0, 0%, 100%, 0.3); //white translucent background
		border-radius: 15px;
		height: 100%;
		position: relative;
	`,
	BotomAbs: styled.div`
		padding: 2rem;
		position: absolute;
		bottom: 0;
	`,
	TopAbs: styled.div`
		padding: 2rem;
		position: absolute;
		top: 25%;
		left: 0;
		bottom: 25%;
		right: 0;
	`,
	RightWrap: styled.div`
		padding: 4rem;
		${down('sm')} {
			padding: 2rem;
		}
	`,
	ImgLogo: styled.img`
		width: 200px;
		margin: 0 auto;
	`,
	Text: styled.div`
		color: ${(props) => props.propsColor};
		font-size: ${(props) => props.propsFontSize};
		text-align: ${(props) => props.propsAlign};
		font-weight: ${(props) => props.propsWeight};
	`,
	SubmitBtn: styled.button`
		background: transparent
			linear-gradient(119.22deg, #f76b1c 24.06%, #f9af46 77.87%, #fbda61 112.36%) 0% 0% no-repeat
			padding-box;
		border-radius: 9px;
		width: 100%;
		padding: 1rem;
		color: #ffffff;
		font-size: 20px;
	`,
	InputWrap: styled.div`
		border-radius: 9px;
		// padding: 1rem;
		background: #f6f6f6 !important;
		display: flex;
		align-items: center;
	`,
	IconWrap: styled.div`
		margin-right: 20px;
		margin-left: 20px;
	`,
	CustomInput: styled(Form.Control)`
		border: 0;
		background: inherit;
		&:focus {
			outline: 0;
			box-shadow: none;
			border-color: #ced4da;
			background: inherit;
		}
	`,
	SocialWrap: styled.div`
		display: flex;
		align-items: center;
	`,
	SocialBtn: styled.button`
		border: 1px solid #cccccc;
		border-radius: 9px;
		width: 100%;
		padding: 1rem;
		display: flex;
		align-items: center;
		justify-content: center;
	`,
};

const ResetPassword = (props) => {
	// const [verify, setVerify] = useState(false);
	const [message, setMessage] = useState('');

	const params = props.match.params;

	const onSubmit = async (values, { setStatus, setSubmitting }) => {
		setMessage('');
		try {
			const response = await axios({
				url: `${apiUrl}/api/submit-password`,
				method: 'POST',
				data: {
					reset_token: params.token,
					password: values.password,
					logout_all: false,
				},
			});

			if (response) {
				if (response.status == 200) {
					// setVerify(true);
					if (response.data.response != undefined) {
						if (!response.data.response) {
							setMessage(response.data.message);
						}
					} else {
						setMessage(response.data.data.message);
					}
				}
			}
		} catch (err) {
			// console.log(err.response);
			const { status, data } = err.response;
			setSubmitting(false);

			if (status === 422) {
				setStatus({
					success: false,
					message: data.message,
				});

				setMessage(data.message);
			}
		}
	};

	useEffect(() => {}, []);

	return (
		<div>
			<Header isHome isHideButton />

			<Container
				style={{
					height: '500px',
					marginTop: 30,
					textAlign: 'center',
				}}
			>
				<div>
					<Wrap.Text propsColor="#1D1D1F" propsFontSize="30px">
						Reset Password
					</Wrap.Text>

					<Spacer propsMbottom="15px" />

					<Wrap.Text propsColor="#adadad" propsFontSize="15px">
						Please enter new password
					</Wrap.Text>

					<Spacer propsMbottom="15px" />
				</div>

				<div>
					<Formik
						initialValues={{
							password: '',
							passwordConfirmation: '',
						}}
						validationSchema={signUpSchema}
						onSubmit={onSubmit}
					>
						{({ status, errors, touched, values, handleSubmit, handleChange, isSubmitting }) => {
							const errMessage = [];
							if (Object.keys(errors).length > 0 && Object.keys(touched).length > 0) {
								Object.values(errors).forEach((v) => {
									errMessage.push(v);
								});
							}

							return (
								<Form onSubmit={handleSubmit}>
									<Form.Row>
										<Form.Group as={Col} controlId="formBasicPassword">
											<Wrap.InputWrap>
												<Wrap.IconWrap>
													<BsUnlock />
												</Wrap.IconWrap>
												<Wrap.CustomInput
													type="password"
													name="password"
													value={values.password}
													placeholder="Password"
													onChange={handleChange}
												/>
											</Wrap.InputWrap>
										</Form.Group>
									</Form.Row>

									<Form.Row>
										<Form.Group as={Col} controlId="formBasicPassword">
											<Wrap.InputWrap>
												<Wrap.IconWrap>
													<BsUnlock />
												</Wrap.IconWrap>
												<Wrap.CustomInput
													type="password"
													name="passwordConfirmation"
													value={values.passwordConfirmation}
													placeholder="Confirm Password"
													onChange={handleChange}
												/>
											</Wrap.InputWrap>
										</Form.Group>
									</Form.Row>
									{errMessage.length > 0 ? (
										<Form.Row>
											<Form.Group as={Col} className="form-errors">
												<span className="text-danger">{`Error/s: ${errMessage.join(', ')}.`}</span>
											</Form.Group>
										</Form.Row>
									) : (
										<Fragment>
											{message != '' ? (
												<Form.Row>
													<Form.Group as={Col} className="text-center">
														<span className={'text-danger'}>{`${message}`}</span>
													</Form.Group>
												</Form.Row>
											) : (
												''
											)}
										</Fragment>
									)}
									<Wrap.SubmitBtn type="submit" disabled={isSubmitting}>
										{isSubmitting ? (
											<Spinner
												as="span"
												animation="border"
												size="sm"
												role="status"
												aria-hidden="true"
											/>
										) : (
											'Reset Password'
										)}
									</Wrap.SubmitBtn>
								</Form>
							);
						}}
					</Formik>
				</div>
			</Container>

			<Footer />
		</div>
	);
};

export default ResetPassword;
