import React from 'react';
import styled from 'styled-components';

const Div = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 200px;
	margin: 0 15px;
	border-radius: 0.5em;
	background: rgba(56, 63, 67, 0.48);
	border: 5px solid #222a2f;
	box-shadow: 5px 5px 10px #1a2024, -5px -5px 10px #2a343a;
	cursor: ${(props) => (props.status ? 'pointer' : 'default')};
	overflow: auto;
	::-webkit-scrollbar {
		width: 1px;
		height: 5px;
		background-color: #222a2f;
	}
	::-webkit-scrollbar-thumb {
		width: 1px;
		height: 1px;
		border-radius: 20px;
		background-color: #2a343a;
	}
	::-webkit-scrollbar-corner {
		display: none;
	}
`;

const ItemWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 200px;
	height: 100%;
	border-radius: 0.5em;
	background: ${(props) => props.color};
	// cursor: pointer;
	resize: verical;
	// z-index: 1;

	#del-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		background: #ffffffa6;
		border-radius: 0.8em;
		border: 1px solid #fffff;
		cursor: pointer;
		z-index: 1;
		&:hover {
			background: #ffffffde;
		}
	}

	img {
		-webkit-user-drag: none;
		-khtml-user-drag: none;
		-moz-user-drag: none;
		-o-user-drag: none;
		user-drag: none;
	}

	#n-resizer-container${(props) => props.identifier} {
		display: flex;
		justify-content: center;
		position: absolute;
		top: 0px;
		cursor: n-resize;
		height: 10px;
		width: 100%;
		z-index: 1;
	}

	#s-resizer-container${(props) => props.identifier} {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		position: absolute;
		bottom: 0px;
		cursor: s-resize;
		height: 10px;
		width: 100%;
		z-index: 1;
	}

	#nresizer {
		border-radius: 0.8em;
		background: white;
		width: 22px;
		height: 4px;
		cursor: grab;
	}

	#sresizer {
		border-radius: 0.8em;
		background: white;
		width: 22px;
		height: 4px;
	}

	#dragging {
		position: absolute;
		height: 70%;
		width: 100%;
		cursor: grab;
	}
`;

function SpecialDaysItem(props) {
	let resizing = false;
	let isNorth = true;
	let prevY = 0;
	let prevBot = 0;
	let prevTop = 0;
	let prevComputedpxl = 0;

	let pixel = [];
	let n = 10;
	for (let i = 0; i <= 922; i += n) {
		pixel.push(i);
	}

	const elm = document.getElementById(props.id + props.itemId);
	const mouseDown = (e) => {
		e.stopPropagation();
		resizing = true;
		document.addEventListener('mousemove', mouseMove);
		document.addEventListener('mouseup', mouseUp);
		if (e.target.id.includes('n-resize')) {
			console.log('yes');
			isNorth = true;
		} else if (e.target.id.includes('s-resize')) {
			console.log('no');
			isNorth = false;
		}
		prevY = e.clientY;
		prevBot = props.top + props.height;
		prevTop = props.top;
	};

	const mouseMove = (e) => {
		if (resizing) {
			let newY = e.clientY;
			if (isNorth) {
				resizeNorth(newY);
			} else {
				resizeSouth(newY);
			}
		}
	};

	const resizeNorth = (newY) => {
		let value = newY - prevY;
		let int = Math.abs(value);
		let pxl;
		pixel.filter((v) => {
			if (v === int) {
				if (value > 0) {
					pxl = -v;
				} else if (value < 0) {
					pxl = v;
				} else if (value === 0) {
					pxl = 0;
				}
			}
		});
		if (pxl !== undefined) {
			prevComputedpxl = pxl;
		}

		if (elm !== null) {
			elm.style.top = prevTop - prevComputedpxl + 'px';
			elm.style.height = props.height + prevComputedpxl + 'px';
		}
		let top = prevTop - prevComputedpxl;
		let height = props.height + prevComputedpxl;
		props.resizeCallbackHandler(top, top + height);
	};

	const resizeSouth = (newY) => {
		let value = newY - prevY;
		let int = Math.abs(value);
		let pxl;
		pixel.filter((v) => {
			if (v === int) {
				if (value > 0) {
					pxl = -v;
				} else if (value < 0) {
					pxl = v;
				} else if (value === 0) {
					pxl = 0;
				}
			}
		});

		if (pxl !== undefined) {
			prevComputedpxl = pxl;
		}
		if (elm !== null) {
			elm.style.height = props.height - prevComputedpxl + 'px';
			elm.style.bottom = prevBot - prevComputedpxl + 'px';
		}

		let bottom = prevBot - prevComputedpxl;
		props.resizeCallbackHandler(props.top, bottom);
	};

	const mouseUp = (e) => {
		e.stopPropagation();
		document.removeEventListener('mousemove', mouseMove);
		document.removeEventListener('mouseup', mouseUp);
		resizing = false;
	};

	// on drag

	let isDragging = false;
	let prevDy;

	const onDragStart = (e) => {
		e.stopPropagation();
		document.addEventListener('mousemove', onDragMove);
		document.addEventListener('mouseup', onDragEnd);
		// console.log(e.target.id);
		isDragging = true;
		prevDy = e.pageY;
		e.target.style.cursor = 'grabbing;';
	};

	function onDragMove(e) {
		if (isDragging) {
			let value = prevDy - e.clientY;
			let int = Math.abs(value);
			let pxl;
			pixel.filter((v) => {
				if (v === int) {
					if (value > 0) {
						pxl = -v;
					} else if (value < 0) {
						pxl = v;
					} else if (value === 0) {
						pxl = 0;
					}
				}
			});
			if (pxl !== undefined) {
				prevComputedpxl = pxl;
			}
			if (elm !== null) {
				elm.style.top = props.top + prevComputedpxl + 'px';
			}

			props.dragShiftCallback(
				props.top + prevComputedpxl,
				props.top + props.height + prevComputedpxl,
			);
		}
	}

	function onDragEnd(e) {
		e.stopPropagation();
		document.removeEventListener('mousemove', onDragMove);
		document.removeEventListener('mouseup', onDragEnd);
		e.target.style.cursor = 'grab;';
		// console.log('stop');
	}

	return (
		<Div
			status={props.status}
			id={props.id + props.itemId}
			style={
				props.zIndex !== undefined
					? {
							position: 'absolute',
							textAlign: 'center',
							top: `${props.top}px`,
							height: `${props.height}px`,
							zIndex: props.zIndex,
					  }
					: {
							position: 'absolute',
							textAlign: 'center',
							top: `${props.top}px`,
							height: `${props.height}px`,
					  }
			}
		>
			<ItemWrapper
				color={props.status ? props.color : ''}
				style={props.isShiftSelected ? { border: '1px solid white' } : {}}
				identifier={props.id}
			>
				{props.isShiftSelected ? (
					<>
						<div id={'n-resizer-container' + props.id} onMouseDown={mouseDown}>
							<div id="nresizer" />
						</div>

						<div
							id="dragging"
							style={{ position: 'absolute', height: '60%', width: '100%', cursor: 'grab' }}
							onMouseDown={onDragStart}
						></div>
						<div id={'s-resizer-container' + props.id} onMouseDown={mouseDown}>
							<div id="sresizer" />
						</div>
					</>
				) : (
					props.name
				)}
			</ItemWrapper>
		</Div>
	);
}

export default SpecialDaysItem;
