import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { ParallaxProvider } from 'react-scroll-parallax';
import 'bootstrap/dist/css/bootstrap.min.css';

import { GlobalStyle, theme } from './assets/css';
// TODO: migrating away from this Sass/SCSS approach, use styled-components, styled-breakpoints, etc instead
import './assets/scss/styles.scss';
import './assets/scss/responsive.scss';
import './assets/scss/booking.scss';
import './assets/scss/edit-booking.scss';
import './assets/scss/segmented-control.scss';
import './assets/scss/booking-calendar.scss';
import './assets/scss/party-size-selection.scss';
import './assets/scss/table-filter.scss';
import './assets/scss/booking-button.scss';
import './assets/scss/table-list.scss';
import './assets/scss/time-table.scss';
import './assets/scss/table-details.scss';
import './assets/scss/reservation-details/reservation-details.scss';
import './assets/scss/booking-employee-list.scss';
import './assets/scss/reservation-details/reservation-dropdown.scss';
import './assets/scss/reservation-details/reservation-toggle-switch.scss';
import './assets/scss/reservation-details/reservation-button.scss';
import './assets/scss/reservation-details/reservation-edit-booking-time-modal.scss';
import './assets/scss/reservation-details/reservation-edit-party-size-modal.scss';
import './assets/scss/reservation-details/reservation-edit-name-modal.scss';
import './assets/scss/select-booking-calendar.scss';
import './assets/scss/partial-party-size.scss';
import './assets/scss/booking-navbar/booking-navbar.scss';
import './assets/scss/table-layout-and-planning/table-layout-and-planning.scss';
import './assets/scss/table-layout-and-planning/Pages/SubComponents/setting-toggleSwitch.scss';
import './assets/scss/table-layout-and-planning/Pages/table-settings.scss';
import 'assets/scss/table-layout-and-planning/Pages/table-floor-plan.scss';

import store from './store/configStore';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
	<Fragment>
		<ReduxProvider store={store}>
			<ThemeProvider theme={theme}>
				<ParallaxProvider>
					<App />
					<GlobalStyle />
				</ParallaxProvider>
			</ThemeProvider>
		</ReduxProvider>
	</Fragment>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
