import React from 'react';
import styled from 'styled-components';

const Button = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: ${(props) => props.height};
	width: ${(props) => props.width};
	padding: 10px;
	margin: ${(props) => props.margin};
	box-shadow: 5px 5px 10px #1a2024, -6px -1px 10px #2a343a;
	cursor: pointer;
	border-radius: 10px;
	user-select: none;
	&:active {
		background: #1f262b;
		color: #ffffff;
		outline: none;
		outline-offset: none;
	}
`;

function TLPButton(props) {
	return (
		<Button
			height={props.height}
			width={props.width}
			margin={props.margin}
			onClick={props.onClick}
			style={props.style}
		>
			{props.label !== undefined ? props.label : ''}
			{props.icon !== undefined ? <img src={props.icon} width={22} height={22} /> : ''}
		</Button>
	);
}

export default TLPButton;
