import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
	position: relative;
	height: ${(props) => props.height}px;
	width: ${(props) => props.width}px;
	border-radius: 10px;
	background: #222a2f;
	z-index: 2;
	box-shadow: inset 5px 5px 10px #1a2024, inset -5px -5px 10px #2a343a;
	user-select: none;
	color: white;

	input {
		display: none;
	}

	label {
		width: 93px;
		height: 40px;
		text-align: center;
		display: inline-block;
		padding-top: 10px;
		z-index: 2;
		cursor: pointer;
	}
`;

const ControlBg = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	height: 95%;
	width: ${(props) => props.width}px;
	border-radius: 10px;
	background: #222a2f;
	box-shadow: 5px 5px 10px #1a2024, -5px -5px 10px #2a343a;
	z-index: -1;

	transform: translateX(${(props) => props.translateVal}px);
	transition: transform 0.2s ease-in-out;
`;

// PROPS VALUE:
//     width = int
//     height = int
//     labelWidth = int
//     labelHeight = int
//     optionCount = int
//     imgSrc = Array
//     labelContents = Array, based on sequence

function ReportsSegmentedControl(props) {
	const [selectedOption, setSelectedOption] = useState(props.selectedInit);

	const selectOption = (e) => {
		setSelectedOption(e.target.value);
		props.selectGraphCallback(e.target.value);
	};

	return (
		<div>
			{props.optionCount === 2 ? (
				''
			) : props.optionCount === 3 ? (
				<Container width={props.width} heigth={props.height}>
					<input
						id="rrsc1"
						type="radio"
						onChange={selectOption}
						value="1"
						checked={selectedOption === '1'}
						name="radioBtn"
					/>
					<label
						htmlFor="rrsc1"
						style={{
							width: props.labelWidth,
							height: props.labelHeight,
							display: 'inline-block',
							zIndex: 2,
							cursor: 'pointer',
							// paddingLeft: 15,
							// paddingTop: 7,
						}}
					>
						{props.labelContents[0]}
					</label>

					<input
						id="rrsc2"
						type="radio"
						onChange={selectOption}
						value="2"
						checked={selectedOption === '2'}
						name="radioBtn"
					/>
					<label
						htmlFor="rrsc2"
						style={{
							width: props.labelWidth,
							height: props.labelHeight,
							display: 'inline-block',
							zIndex: 2,
							cursor: 'pointer',
							// paddingLeft: 15,
							// paddingTop: 7,
						}}
					>
						{props.labelContents[1]}
					</label>

					<input
						id="rrsc3"
						type="radio"
						onChange={selectOption}
						value="3"
						checked={selectedOption === '3'}
						name="radioBtn"
					/>
					<label
						htmlFor="rrsc3"
						style={{
							width: props.labelWidth,
							height: props.labelHeight,
							display: 'inline-block',
							zIndex: 2,
							cursor: 'pointer',
							// paddingLeft: 15,
							// paddingTop: 7,
						}}
					>
						{props.labelContents[2]}
					</label>

					<ControlBg
						width={props.labelWidth}
						translateVal={
							selectedOption === '1'
								? 0
								: selectedOption === '2'
								? props.labelWidth
								: selectedOption === '3'
								? props.labelWidth * 2
								: 0
						}
					/>
				</Container>
			) : props.optionCount === 4 ? (
				''
			) : (
				''
			)}
			{/* <div id="bckgrnd" /> */}
		</div>
	);
}

export default ReportsSegmentedControl;
