import React, {useState} from 'react';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Row, Col } from 'react-bootstrap';

import { getEmployeeInitials } from '../../../../../utils/Utility'

import personIcon from '../../../../../assets/images/booking/create-booking/person-icon.png'
import btnClose from '../../../../../assets/images/booking/create-booking/button-close-icon.png'

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    paper: {
      width: '100%',
      height: '100%',
      backdropFilter: 'blur(10px)',
      outline: 'none'
    },
}));

function BookingEmployeeList(props) {

    const classes = useStyles();

    const handleCloseBookingEmployee = props.handleCloseBookingEmployee

    const selectEmployee = (item) => {
        props.selecteEmployee(item)
        handleCloseBookingEmployee()
    }

    return(
<       Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={props.createBookingEmployee}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={props.createBookingEmployee}>
                <div className={classes.paper}>
                    <div className='booking-employee-list-container'>
                        <div className='booking-employee-main-container'>
                            <Row>
                                <Col lg={6} md={6} sm={6}>
                                    <div>
                                        <img
                                            src={personIcon}
                                            width="24"
                                            height="24"
                                            className="d-inline"
                                            style={ { 
                                                textAlign: 'center',
                                                margin: '15px 20px',
                                                marginRight: 10
                                            }}
                                            alt="checker"
                                        />
                                        <label style={{
                                            fontWeight: 700,
                                            fontSize: 12,
                                            color: 'white'
                                        }}>BOOKING TAKEN BY</label>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={6}>
                                <div style={{
                                    textAlign: 'right'
                                }}>
                                        <img
                                            onClick={() => handleCloseBookingEmployee()}
                                            src={btnClose}
                                            width="30"
                                            height="30"
                                            className="d-inline"
                                            style={ { 
                                                textAlign: 'center',
                                                margin: '10px 20px',
                                                marginRight: 10,
                                                cursor: 'pointer'
                                            }}
                                            alt="checker"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{ height: '100%', padding: '0 20px', paddingBottom: 10 }}>
                                <Col lg={12} md={12} sm={12}>
                                    <div className='booking-employee-list-item-container'>
                                        {
                                            props.employees !== undefined ? props.employees.map(item => {
                                                return <div 
                                                            onClick={() => selectEmployee(item)}
                                                            key={Math.random()} 
                                                            className={ props.employee.id === item.id ? 'employee-list-item employee-list-item--selected' : 'employee-list-item' }>
                                                    <div
                                                        style={{ background: item.color }}
                                                        className='inner' key={Math.round()}>
                                                            { getEmployeeInitials(item) }
                                                    </div>
                                                </div>
                                            }) : ''
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    )
}

export default BookingEmployeeList