import React, { useState } from 'react';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Row, Col } from 'react-bootstrap';
import { TextareaAutosize } from '@material-ui/core';

import noteIcon from '../../../../../assets/images/booking/create-booking/note-icon.png';
import btnClose from '../../../../../assets/images/booking/create-booking/button-close-icon.png';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		width: '100%',
		height: '100%',
		backdropFilter: 'blur(10px)',
		outline: 'none',
	},
}));

function BookingNotes(props) {
	const classes = useStyles();

	const [note, setNote] = useState('');

	const handleCloseBookingNote = props.handleCloseBookingNote;

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={props.createBookingNote}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={props.createBookingNote}>
				<div className={classes.paper}>
					<div
						style={{
							width: '100%',
							height: '100%',
							position: 'relative',
						}}
					>
						<div
							style={{
								width: 544,
								height: 225,
								background: 'rgba(0, 0, 0, 0.6)',
								position: 'absolute',
								top: '50%',
								left: '50%',
								transform: 'translate(-50%, -50%)',
								borderRadius: 10,
							}}
						>
							<Row>
								<Col lg={6} md={6} sm={6}>
									<div>
										<img
											src={noteIcon}
											width="24"
											height="24"
											className="d-inline"
											style={{
												textAlign: 'center',
												margin: '15px 20px',
												marginRight: 10,
											}}
											alt="checker"
										/>
										<label
											style={{
												fontWeight: 700,
												fontSize: 12,
												color: 'white',
											}}
										>
											ADD NOTE
										</label>
									</div>
								</Col>
								<Col lg={6} md={6} sm={6}>
									<div
										style={{
											textAlign: 'right',
										}}
									>
										<img
											onClick={() => handleCloseBookingNote(note)}
											src={btnClose}
											width="30"
											height="30"
											className="d-inline"
											style={{
												textAlign: 'center',
												margin: '10px 20px',
												marginRight: 10,
												cursor: 'pointer',
											}}
											alt="checker"
										/>
									</div>
								</Col>
							</Row>
							<Row>
								<Col lg={12} sm={12} md={12}>
									<TextareaAutosize
										value={note}
										onChange={(e) => setNote(e.target.value)}
										style={{
											width: '100%',
											height: '100%',
											background: 'transparent',
											border: 'none',
											padding: '5px 22px',
											caretColor: 'white',
											outline: 'none',
											color: 'white',
										}}
										minRows={4}
										placeholder="Enter note…"
									/>
								</Col>
							</Row>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}

export default BookingNotes;
