import React, { useEffect, useState } from 'react';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import exitBtnIcon from '../../../../../../assets/images/booking/exit-btn-table-details.png';
import colorPickerIcon from 'assets/images/booking/color-picker-icon.png';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		width: '100%',
		height: '100%',
		backdropFilter: 'blur(10px)',
		outline: 'none',
	},
}));
const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
`;
const Content = styled.div`
	width: 466.5px;
	height: 385px;
	background: rgba(0, 0, 0, 0.6);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 10px;
	border-color: none;
	padding: 15px;
	color: white;
`;
const Header = styled.label`
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 16px;
	font-weight: bold;
	color: white;
	width: 100%;
	margin-bottom: 5px;
`;
const ImgWrapper = styled.div`
	display: inline-flex;
	align-content: center;
	justify-content: center;
	align-items: center;
	position: absolute;
	// background-color: #ffffffd9;
	border-radius: 27%;
	top: 10px;
	right: 0px;
	cursor: pointer;
	width: 30px;
	height: 36px;
	margin: 5px;
`;

const Item = styled.div`
	display: inline-flex;
	width: 80px;
	height: 80px;
	background: ${(props) => props.color};
	border-radius: 50%;
	border: ${(props) => props.theme.border};
	margin: 0.8em;
	padding-left: 2px;
`;

const theme = {
	selected: {
		border: '10px solid #ffffff',
	},
	default: {
		border: '10px solid #222a2f',
	},
};

function ShiftColourPicker(props) {
	const classes = useStyles();
	const [currentColor, setCurrentColor] = useState('');

	const handleCloseShiftColourPicker = () => {
		props.colorPickerCallback();
	};

	const getColor = (colour) => {
		setCurrentColor(colour);
		props.colorPickerCallback(colour);
	};

	useEffect(() => {
		setCurrentColor(props.initValue);
	}, [props.initValue]);
	// console.log('current:', currentColor);

	return (
		<Modal
			className={classes.modal}
			open={props.openColorPicker}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={props.openColorPicker}>
				<div className={classes.paper}>
					<Wrapper>
						<Content>
							<ImgWrapper onClick={handleCloseShiftColourPicker}>
								<img src={exitBtnIcon} width="12" height="12" alt="checker" />
							</ImgWrapper>
							<Header>
								<img
									src={colorPickerIcon}
									width="26"
									height="26"
									alt="Icon"
									style={{ marginTop: 5, marginRight: 5, marginBottom: 5 }}
								/>
								&nbsp; CHOOSE A COLOUR
							</Header>
							{/* <div style={{ padding: '5px' }}> */}
							<Item
								theme={currentColor === '#FE7D86' ? theme.selected : theme.default}
								color={'#FE7D86'}
								onClick={(e) => getColor('#FE7D86')}
							/>
							<Item
								theme={currentColor === '#FF977C' ? theme.selected : theme.default}
								color={'#FF977C'}
								onClick={(e) => getColor('#FF977C')}
							/>
							<Item
								theme={currentColor === '#C76467' ? theme.selected : theme.default}
								color={'#C76467'}
								onClick={(e) => getColor('#C76467')}
							/>
							<Item
								theme={currentColor === '#3F2427' ? theme.selected : theme.default}
								color={'#3F2427'}
								onClick={(e) => getColor('#3F2427')}
							/>
							<Item
								theme={currentColor === '#85FFBD' ? theme.selected : theme.default}
								color={'#85FFBD'}
								onClick={(e) => getColor('#85FFBD')}
							/>
							<Item
								theme={currentColor === '#7FD3A6' ? theme.selected : theme.default}
								color={'#7FD3A6'}
								onClick={(e) => getColor('#7FD3A6')}
							/>
							<Item
								theme={currentColor === '#538068' ? theme.selected : theme.default}
								color={'#538068'}
								onClick={(e) => getColor('#538068')}
							/>
							<Item
								theme={currentColor === '#2E8053' ? theme.selected : theme.default}
								color={'#2E8053'}
								onClick={(e) => getColor('#2E8053')}
							/>
							<Item
								theme={currentColor === '#85FFFF' ? theme.selected : theme.default}
								color={'#85FFFF'}
								onClick={(e) => getColor('#85FFFF')}
							/>
							<Item
								theme={currentColor === '#56C8DD' ? theme.selected : theme.default}
								color={'#56C8DD'}
								onClick={(e) => getColor('#56C8DD')}
							/>
							<Item
								theme={currentColor === '#13B6F2' ? theme.selected : theme.default}
								color={'#13B6F2'}
								onClick={(e) => getColor('#13B6F2')}
							/>
							<Item
								theme={currentColor === '#267DE3' ? theme.selected : theme.default}
								color={'#267DE3'}
								onClick={(e) => getColor('#267DE3')}
							/>
							{/* </div> */}
						</Content>
					</Wrapper>
				</div>
			</Fade>
		</Modal>
	);
}
export default ShiftColourPicker;
