import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function TicketQuantity(props) {
	const [ticketsNo, setTicketsNo] = useState('');
	const [hasError, setHasError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const data = props.experienceData;

	const handleClose = () => {
		props.setTicketQuantityOpen(false);
		// setOpen(false);
	};

	const onChange = (e) => {
		console.log(e.target.validity.valid);
		let number;
		if (e.target.validity.valid) {
			number = parseInt(e.target.value) || '';
			setTicketsNo(number);
		} else {
			return;
		}
		if (hasError) {
			setHasError(false);
			setErrorMessage('');
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		// props.setExpNoOfTickets(ticketsNo);
		props.handleTicketQuantitySubmit(ticketsNo);
		handleClose();
	};

	return (
		<div>
			<Dialog
				open={props.ticketQuantityOpen}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
				// className={classes.root}
			>
				{data === undefined ? (
					''
				) : (
					<DialogTitle id="form-dialog-title">{data.exp_name}</DialogTitle>
				)}

				<DialogContent>
					<DialogContentText>
						{data === undefined ? '' : <span>{data.exp_description}</span>}
					</DialogContentText>
					<TextField
						// error={hasError}
						helperText={'Please enter number of tickets'}
						autoFocus
						label="How many tickets?"
						value={ticketsNo}
						onInput={onChange}
						inputProps={{ inputMode: 'numeric', pattern: '[0-9]{0,2}' }}
						fullWidth
					/>
				</DialogContent>
				<DialogActions type="submit">
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
					<Button onClick={handleSubmit} color="primary">
						Buy
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
export default TicketQuantity;
