import { apiUrl } from './Utility';
import axios from 'axios';

// Get function parameters
// 1 data if any = Object / Array
// 2 API Link = String
// 3 Token = String
// 4 Adjacent functions = Array
// 5 boolean functions = Array

export const getDataFunction = async (params, link, token, cbFunctions, bool) => {
	try {
		const response = await axios({
			url: `${apiUrl}${link}`,
			method: 'POST',
			data: params,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});

		if (response) {
			const { status } = response;
			const { data } = response.data;
			if (status === 200) {
				if (cbFunctions) {
					cbFunctions.map((e) => {
						e();
					});
				}
				if (bool) {
					bool.map((e) => {
						e(false);
					});
				}
				return data;
			}
		}
	} catch (err) {
		console.log('Error: ', err);
	}
};

export const setDataFunction = async (params, link, token, cbFunctions, bool) => {
	try {
		const response = await axios({
			url: `${apiUrl}${link}`,
			method: 'POST',
			data: params,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		});

		if (response) {
			const { status } = response;
			const { data } = response.data;
			if (status === 200) {
				if (cbFunctions) {
					cbFunctions.map((e) => {
						e();
					});
				}
				if (bool) {
					bool.map((e) => {
						e(false);
					});
				}
				return data;
			}
		}
	} catch (err) {
		console.log('Error: ', err);
	}
};

export const setAvatar = async (params, link, token, callbackFnc) => {
	try {
		const response = await axios({
			url: `${apiUrl}${link}`,
			method: 'POST',
			data: params,
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${token}`,
			},
		});

		if (response) {
			const { status } = response;
			const { data } = response.data;
			if (status === 200) {
				console.log(data);
				callbackFnc();
			}
		}
	} catch (err) {
		console.log('Error: ', err);
	}
};
