import React, { useState } from 'react';
import { getFilterFloors } from '../../../../../utils/Utility';

function TableFilter(props) {
	const [selectedFloor, setSelectedFloor] = useState(props.floor[0]);
	const [openOptions, setOpenOptions] = useState(false);
	const [filterFloors, setFilterFloors] = useState([]);

	const displayOptions = () => {
		if (!openOptions) {
			setOpenOptions(true);
			setFilterFloors(getFilterFloors(selectedFloor.id, props.floor));
		} else {
			setOpenOptions(false);
			setFilterFloors([]);
		}
	};

	const selectedOption = (floor) => {
		setSelectedFloor(floor);

		setFilterFloors([]);
		setOpenOptions(false);
		props.selectedOption(floor);
	};

	return (
		<div
			className="table-filter"
			style={props.padRight !== undefined ? { paddingRight: props.padRight } : {}}
		>
			{filterFloors.map((floor) => (
				<div key={Math.random()} onClick={() => selectedOption(floor)} className="table-options">
					{floor.floor_name}
				</div>
			))}
			<div onClick={() => displayOptions()} className="table-options">
				{selectedFloor.floor_name}
			</div>
		</div>
	);
}

export default TableFilter;
