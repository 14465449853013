import React, { useState } from 'react';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DayPicker from 'react-day-picker';
import moment from 'moment';
import 'react-day-picker/lib/style.css';

import CalendarMonth from '../../CreatBooking/calendar-month-navigation/CalendarMonth';

import btnClose from 'assets/images/booking/create-booking/button-close-icon.png';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		width: '100%',
		height: '100%',
		backdropFilter: 'blur(10px)',
		outline: 'none',
	},
}));

function ReportsCalendarModal(props) {
	const classes = useStyles();
	const [month, setMonth] = useState(moment(props.selectedDate));

	const selectMonth = (selectedMonth) => {
		setMonth(selectedMonth);
	};

	const selectDate = (date) => {
		props.handleSelectDate(moment(date).format('YYYY-MM-DD'));
		props.toggleCalendar();
	};

	const dateArray = [];

	for (let i = 0; i <= 6; i++) {
		// console.log(i);
		let date = moment(props.selectedDate).subtract(i, 'days');
		dateArray.push(new Date(date));
	}

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={true}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={true}>
				<div className={classes.paper}>
					<div className="select-booking-calendar-container">
						<img
							onClick={() => props.toggleCalendar()}
							src={btnClose}
							width="30"
							height="30"
							className="d-inline"
							style={{
								textAlign: 'center',
								margin: '10px 20px',
								marginRight: 10,
								cursor: 'pointer',
								position: 'absolute',
								top: 10,
								right: 10,
							}}
							alt="checker"
						/>
						<div className="select-booking-calendar-main-container">
							<div
								style={{
									display: 'inline-block',
									marginBottom: 10,
								}}
							>
								<CalendarMonth month={month} selectMonth={selectMonth} />
							</div>
							<DayPicker
								month={new Date(month)}
								selectedDays={dateArray.map((e) => e)}
								onDayClick={selectDate}
								showOutsideDays={true}
								className="booking-calendar"
								disabledDays={props.disabledDays}
							/>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}

export default ReportsCalendarModal;
