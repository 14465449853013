import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Navbar, Nav, Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
// import { useHistory } from 'react-router-dom';

import TopBar from '../components/TopBar';

const emailSchema = Yup.object().shape({
	email: Yup.string().email('invalid email address').required('email address required'),
});

const Content = styled.div`
	display: block;
	background: var(--text-primary);
	padding-bottom: 204px;
	padding-top: 180px;
`;

const Box = styled.div`
	border-radius: 36px;
	background: var(--text-primary);
	box-shadow: 5px 5px 15px #00000080;
	padding: 46px;
`;

const Shadow = styled.div`
	border-radius: 36px;
	box-shadow: -5px -5px 15px #ffffff0d;
	width: 375px;
	margin: auto;
`;

const Highlight = styled.div`
	border-radius: 21px;
	box-shadow: -5px -5px 15px #ffffff14;
	margin-top: 26px;
	border: 2px solid var(--text-primary);
`;

const Footer = styled.div`
	display: block;
	background: var(--text-primary);
	padding-top: 42px;
`;

const ForgotPassword = () => {
	// const history = useHistory();

	const onSubmit = (values, { setSubmitting }) => {
		console.log(values);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Container fluid>
			<TopBar />
			<Content className="content">
				<Container className="home-title">
					<Row className="signup-form forget-form">
						<Col className="mr-auto ml-auto">
							<Shadow>
								<Box>
									<h2>Recover Password</h2>
									<Formik
										initialValues={{
											email: '',
										}}
										validationSchema={emailSchema}
										onSubmit={onSubmit}
									>
										{({
											status,
											errors,
											touched,
											values,
											handleSubmit,
											handleChange,
											isSubmitting,
										}) => {
											const errMessage = [];
											if (Object.keys(errors).length > 0 && Object.keys(touched).length > 0) {
												Object.values(errors).forEach((v) => {
													errMessage.push(v);
												});
											}

											return (
												<Form onSubmit={handleSubmit}>
													<Form.Row>
														<Form.Group as={Col} controlId="formBasicEmail" className="shadow-inp">
															<Form.Control
																className="input-signup"
																type="text"
																name="email"
																onChange={handleChange}
																value={values.email}
																placeholder="Enter email"
															/>
														</Form.Group>
													</Form.Row>

													{errMessage.length > 0 && (
														<Form.Row>
															<Form.Group as={Col} className="form-errors">
																<span className="text-danger">
																	{`Error/s: ${errMessage.join(', ')}.`}
																</span>
															</Form.Group>
														</Form.Row>
													)}

													<Highlight style={{ marginTop: 39, marginBottom: 30 }}>
														<Button type="submit" className="btn-signup">
															{isSubmitting ? (
																<Spinner
																	as="span"
																	animation="border"
																	size="sm"
																	role="status"
																	aria-hidden="true"
																/>
															) : (
																'Send email'
															)}
														</Button>
													</Highlight>
												</Form>
											);
										}}
									</Formik>
								</Box>
							</Shadow>
						</Col>
					</Row>
				</Container>
			</Content>
			<Footer>
				<Container>
					<Navbar expand="lg" className="footer main-navbar" style={{ marginTop: 100 }}>
						<Navbar id="basic-navbar-nav" style={{ margin: 'auto' }}>
							<Nav className="ml-auto mr-auto">
								<Nav.Link className="navbar-link" href="/">
									T & C
								</Nav.Link>
								<Nav.Link className="navbar-link" href="/">
									Privacy Policy
								</Nav.Link>
								<Nav.Link className="navbar-link" href="/">
									Contact
								</Nav.Link>
								<Nav.Link className="navbar-link" href="/">
									©2020 WABI
								</Nav.Link>
							</Nav>
						</Navbar>
					</Navbar>
				</Container>
			</Footer>
		</Container>
	);
};

export default ForgotPassword;
