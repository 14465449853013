import React, { useEffect, useState } from 'react';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		width: '100%',
		height: '100%',
		backdropFilter: 'blur(10px)',
		outline: 'none',
	},
}));

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	justify-content: center;
`;

const Content = styled.div`
	width: auto;
	height: auto;
	// background: rgba(0, 0, 0, 0.6);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 10px;
	border-color: none;
	padding: 20px;
	color: white;
`;

function ModalSpinner(props) {
	const classes = useStyles();

	return (
		<Modal
			className={classes.modal}
			open={props.isLoading}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={props.isLoading}>
				<div className={classes.paper}>
					<Wrapper>
						<Content>
							<Spinner
								as="span"
								animation="border"
								size="lg"
								role="status"
								aria-hidden="false"
								style={{ border: '0.25em solid #ff7192', borderRightColor: '#f8f9fa03' }}
							/>
						</Content>
					</Wrapper>
				</div>
			</Fade>
		</Modal>
	);
}
export default ModalSpinner;
