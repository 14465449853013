import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import exitIcon from 'assets/images/booking/exit-btn-table-details.png';
import partySizeIcon from 'assets/images/booking/section-party-size-icon.png';
import checkIcon from 'assets/images/booking/create-booking/check-icon.png';
import trashCanIcon from 'assets/images/booking/table-layout-planning/delete-icon.png';

const Content = styled.div`
	display: flex;
	height: ${(props) => (props.isOpen ? 130 : 50)}px;
	width: 322px;
	background-color: #3a444b;
	border-radius: 5px;
	margin: 5px 0;
	padding: 5px;
	transition: ease 300ms;

	#active-container {
		display: flex;
		width: 322px;
		border-radius: 5px;
		flex-direction: column;
		padding: 5px;
		cursor: pointer;
		transition: ease 300ms;
	}

	#default-container {
		display: flex;
		align-items: center;
		border-radius: 5px;
		width: 322px;
		padding: 0;
		margin: 0;
		cursor: pointer;
		color: #bcbcbc;
		text-overflow: ellipsis;
		transition: ease 300ms;
	}

	span {
		display: flex;
		flex-direction: column;
	}

	h4 {
		font-size: 16px;
		font-weight: 700;
		color: #ffffff;
	}

	input {
		width: 105px;
		height: 36px;
		border-radius: 10px;
		margin-right: 5px;
		padding: 5px;
	}

	label {
		font-size: 15px;
		font-weight: 700;
		color: #ffffff;
		margin: 0 0 0 15px;
	}

	#tc-row {
		position: relative;
		display: flex;
		flex-direction: row;
		padding: 10px 0;
		margin-top: 15px;
	}

	#confirmIcon-container {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		right: 42px;
		bottom: 10px;
		height: 36px;
		width: 36px;
		background: transparent linear-gradient(#f6d5a0c7 -55%, #fb2c7f);
		border-radius: 7px;

		&:hover {
			background-color: #ccccccb0;
		}
		&:active {
			background-color: #cccccc;
			outline: none;
			outline-offset: none;

			img {
				filter: contrast(9.5);
			}
		}
		img {
			// user-select: none;
			-webkit-user-drag: none;
			-khtml-user-drag: none;
			-moz-user-drag: none;
			-o-user-drag: none;
			user-drag: none;
		}
	}
	#closeButton-container {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		right: 0;
		bottom: 10px;
		height: 36px;
		width: 36px;
		background-color: #cccccc;
		border-radius: 7px;

		&:hover {
			background-color: #ccccccb0;
		}
		&:active {
			background-color: #cccccc;
			outline: none;
			outline-offset: none;
		}
		img {
			// user-select: none;
			-webkit-user-drag: none;
			-khtml-user-drag: none;
			-moz-user-drag: none;
			-o-user-drag: none;
			user-drag: none;
			filter: contrast(9.5);
		}
	}
`;

// const CombinedTableContainer = styled.div`
// 	// display: flex;
// 	// flex-direction: column;
// 	// height: 130px;
// 	// width: 322px;
// 	// background-color: #3a444b;
// 	// border-radius: 5px;
// 	// margin: 5px 0;
// 	// padding: 10px;
// 	// cursor: pointer;

// 	display: flex;
// 	height: 50px;
// 	width: 322px;
// 	background-color: #3a444b;
// 	border-radius: 5px;
// 	margin: 5px 0;
// 	padding: 5px;
// 	align-items: center;
// 	cursor: pointer;
// 	color: #bcbcbc;
// 	text-overflow: ellipsis;
// 	transition: ease 300ms;

// 	img {
// 		// user-select: none;
// 		-webkit-user-drag: none;
// 		-khtml-user-drag: none;
// 		-moz-user-drag: none;
// 		-o-user-drag: none;
// 		user-drag: none;
// 	}
// `;

// const ChildContainer = styled.div`
// 	position: absolute;
// 	top: 0px;
// 	transition: ease 200ms;
// `;

function CombinationCapacity(props) {
	const [isOpen, setIsOpen] = useState(false);
	const [editCapacity, seteditCapacity] = useState({ min: props.min, max: props.max });

	const toggling = () => {
		if (isOpen) {
			setIsOpen(!isOpen);
			props.openComponentCallback(props.ident);
		} else {
			setIsOpen(!isOpen);
			props.openComponentCallback(props.ident);
		}
	};

	const formatStringName = (string) => {
		let result = string.replaceAll('+', ' / ');
		return result;
	};

	const eventBubblehandler = (e) => {
		e.stopPropagation();
	};

	const handleChangeMin = (e) => {
		const number = e.target.validity.valid ? parseInt(e.target.value) : editCapacity.min;
		seteditCapacity({
			min: number,
			max: editCapacity.max,
		});
	};

	const handleChangeMax = (e) => {
		const number = e.target.validity.valid ? parseInt(e.target.value) : editCapacity.max;
		seteditCapacity({
			min: editCapacity.min,
			max: number,
		});
	};

	const handleConfirm = () => {
		let Ids = props.combined.combined_ids;
		let tableIds = Ids.split('|');
		tableIds.pop();
		tableIds.shift();
		tableIds = tableIds.map((e) => parseInt(e));
		props.combinedTablesCallback(props.combined.id, tableIds, editCapacity);
	};

	const handleDelete = () => {
		props.selectedIdCallback(props.combined);
	};
	useEffect(() => {
		if (!props.isOpen) {
			setIsOpen(false);
		}
	}, [props.isOpen]);

	return (
		<Content isOpen={isOpen} onClick={toggling}>
			{isOpen ? (
				<div id="active-container">
					<h4 style={{ color: '#ffffff' }}>{formatStringName(props.name)}</h4>
					<div id="tc-row">
						<span>
							<label>min</label>
							<input
								onClick={eventBubblehandler}
								type="text"
								pattern="[0-9]{1,2}"
								value={editCapacity.min}
								onChange={handleChangeMin}
							/>
						</span>
						<span>
							<label>max</label>
							<input
								onClick={eventBubblehandler}
								type="text"
								pattern="[0-9]{1,2}"
								value={editCapacity.max}
								onChange={handleChangeMax}
							/>
						</span>
						<div id="confirmIcon-container" onClick={handleConfirm}>
							<img src={checkIcon} width={12} height={12} />
						</div>
						<div id="closeButton-container" onClick={handleDelete}>
							<img src={trashCanIcon} width={16} height={19} />
						</div>
					</div>
				</div>
			) : (
				<div id="default-container">
					<div
						style={{
							fontSize: '16px',
							fontWeight: 700,

							height: 25,
							width: 230,
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
						}}
					>
						{formatStringName(props.name)}
					</div>

					<div
						key={Math.random()}
						style={{
							position: 'absolute',
							right: 10,
							display: 'flex',
						}}
					>
						<img src={partySizeIcon} width={18} height={18} style={{ filter: 'contrast(0.5)' }} />{' '}
						&nbsp;
						{editCapacity.min} - {editCapacity.max}
					</div>
				</div>
			)}
		</Content>
	);
}

export default CombinationCapacity;
