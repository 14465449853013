import React, { useState, useEffect } from 'react';
import { Modal, Backdrop, Fade, Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import moment from 'moment';
import { RECURRINGTYPE, RECURRINGVALUE } from 'constants/enum';

//Icons
import closeIcon from 'assets/images/booking/xIcon.png';
import arrowDownIcon from 'assets/images/booking/arrow-down-icon.png';
import checkIcon from 'assets/images/booking/create-booking/check-icon.png';

//Components
import CalendarMonth from 'pages/Dashboard/Booking/CreatBooking/calendar-month-navigation/CalendarMonth';
import BookingCalendar from 'pages/Dashboard/Booking/CreatBooking/booking-calendar/BookingCalendar';
import AlertDialog from 'pages/Dashboard/Booking/CreatBooking/booking-experience/AlertDialog';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		width: '100%',
		height: '100%',
		backdropFilter: 'blur(10px)',
		outline: 'none',
	},
}));

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	position: relative;
	user-select: none;

	#close-icon-wrapper {
		position: absolute;
		top: 30px;
		right: 30px;
		cursor: pointer;

		img {
			-webkit-user-drag: none;
			-khtml-user-drag: none;
			-moz-user-drag: none;
			-o-user-drag: none;
			user-drag: none;
		}
	}
`;

const Content = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 515px;
	height: auto;
	background: #222a2f;
	border-radius: 10px;
	padding: 20px;
	color: #bcbcbc;

	h3 {
		color: #ffffff;
		margin-bottom: 5px;
	}

	p {
		font-size: 14px;
		font-weight: 400;
		margin-bottom: 10px;
	}

	label {
		font-size: 14px;
		font-weight: 700;
	}

	#top-row-container {
		position: relative;
		display: flex;
		flex-direction: column;
		margin-bottom: 15px;

		#calendar-btn {
			display: flex;
			justify-content: center;
			width: 150px;
			height: 35px;
			margin: 5px;
			box-shadow: inset 5px 5px 10px #1a2024, inset -5px -5px 10px #2a343a;
			border-radius: 5px;
			padding: 10px;
			background: transparent;
			font-size: 0.8em;
			cursor: pointer;
		}
	}

	#selectShift-addBookingFee-container {
		display: flex;
		justify-content: space-between;
		height: 80px;
		margin-bottom: 30px;

		#select-shift {
			display: flex;
			flex-direction: column;

			#container {
				display: flex;
				flex-direction: row;
				width: 250px;
				height: 50px;
				overflow-x: auto;

				::-webkit-scrollbar {
					width: 2px;
					height: 5px;
					background-color: #222a2f;
				}
				::-webkit-scrollbar-thumb {
					width: 2px;
					height: 5px;
					border-radius: 20px;
					background-color: #757a7d33;
				}
				::-webkit-scrollbar-corner {
					display: none;
				}
			}
		}

		#add-booking-fee {
			display: flex;
			flex-direction: column;

			span {
				position: relative;
				display: flex;

				input {
					width: 100px;
					height: 35px;
					margin: 5px;
					text-align: start;
					box-shadow: inset 5px 5px 10px #1a2024, inset -5px -5px 10px #2a343a;
					border-radius: 5px;
					padding: 10px;
					background: transparent;
					color: #ffffff;
					font-size: 0.8em;
				}
			}
		}
	}

	#btm-btn-container {
		position: relative;
		display: flex;
		justify-content: center;
	}
`;

const Button = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	min-width: 100px;
	height: 40px;
	box-shadow: 5px 5px 10px #1a2024, -6px -1px 10px #2a343a;
	cursor: pointer;
	border-radius: 0.8em;
	user-select: none;
	color: #bcbcbc;
	font-size: 14px;
	font-weight: 700;
	background: #d6d4d8;
	padding: 0 5px;

	img {
		-webkit-user-drag: none;
		-khtml-user-drag: none;
		-moz-user-drag: none;
		-o-user-drag: none;
		user-drag: none;
	}

	&:active {
		color: #ffffff;
		outline: none;
		outline-offset: none;
	}
`;

const Daybutton = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50px;
	height: 40px;
	margin: 5px;
	box-shadow: 5px 5px 10px #1a2024, -6px -1px 10px #2a343a;
	cursor: pointer;
	border-radius: 0.8em;
	user-select: none;
	color: #bcbcbc;
	font-size: 14px;
	font-weight: 700;
	background: ${(props) => (props.active ? '#ff8585' : '#222a2f')};

	img {
		-webkit-user-drag: none;
		-khtml-user-drag: none;
		-moz-user-drag: none;
		-o-user-drag: none;
		user-drag: none;
	}

	&:hover {
		background: #fb8c8c;
	}

	&:active {
		background: #ff8585;
		color: #ffffff;
		outline: none;
		outline-offset: none;
	}
`;

const AssignExpBtn = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	width: 225px;
	padding: 10px;
	box-shadow: 5px 5px 10px #1a2024, -6px -1px 10px #2a343a;
	cursor: pointer;
	border-radius: 2em;
	border: 5px solid #222a2f;
	user-select: none;
	color: #bcbcbc;
	font-size: 14px;
	font-weight: 700;
	background: linear-gradient(189deg, #ff8585 0%, #ff7192 100%);

	&:hover {
		background: linear-gradient(90deg, #ff8585 0%, #ff7192 100%);
	}

	&:active {
		background: linear-gradient(189deg, #ff8585 0%, #ff7192 100%);
		color: #ffffff;
		outline: none;
		outline-offset: none;
	}
`;

const DeleteBtn = styled.div`
	position: absolute;
	bottom: 4px;
	right: 2px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 30px;
	width: 30px;
	box-shadow: 5px 5px 10px #1a2024, -6px -1px 10px #2a343a;
	cursor: pointer;
	border-radius: 0.8em;
	user-select: none;
	color: #bcbcbc;
	font-size: 14px;
	font-weight: 700;
	background: linear-gradient(0deg, #ff8585 0%, #ff7192 100%);

	img {
		-webkit-user-drag: none;
		-khtml-user-drag: none;
		-moz-user-drag: none;
		-o-user-drag: none;
		user-drag: none;
	}

	&:hover {
		background: linear-gradient(180deg, #ff8585 0%, #ff7192 100%);
	}

	&:active {
		background: linear-gradient(0deg, #ff8585 0%, #ff7192 100%);
		color: #ffffff;
		outline: none;
		outline-offset: none;
	}
`;

const SwitchContainer = styled.div`
	display: flex;
	position: relative;
	label {
		position: relative;
		display: inline-block;
		width: 85px;
		height: 35px;
	}

	span {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #fe6778;
		transition: 0.5s;
		border: 5px solid #222a2f;
	}

	span:before {
		//Circle bg & position
		position: absolute;
		content: '';
		top: 1px;
		left: 1px;
		background-color: #222a2f;
		transition: 0.5s;
	}

	label input {
		display: none;
	}

	input:checked + span {
		background-color: #85ffbd;
	}

	input:checked + span:before {
		transform: translateX(50px);
	}

	span {
		box-shadow: 7px 1px 7px #1a2024, -4px -1px 7px #2a343a,
			inset 0px 0px 20px 2px rgba(255, 255, 255, 0.1132);
		border-radius: 50px;
	}

	span:before {
		//Circle size
		width: 23px;
		height: 23px;
		border-radius: 50%;
	}
`;

function ToggleSwitch(props) {
	return (
		<SwitchContainer>
			<label>
				<input type="checkbox" onChange={props.onChange} checked={props.checked} />
				<span />
			</label>
		</SwitchContainer>
	);
}

const ExpCalendarWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #232628db;

	#close-icon-wrapper {
		position: absolute;
		top: 30px;
		right: 30px;
		cursor: pointer;

		img {
			-webkit-user-drag: none;
			-khtml-user-drag: none;
			-moz-user-drag: none;
			-o-user-drag: none;
			user-drag: none;
		}
	}
`;

function ExperienceCalendar(props) {
	const classes = useStyles();
	const today = moment(props.initVal).format('yyyy-MM-DD');
	const [month, setMonth] = useState(today);
	const [bookingDate, setBookingDate] = useState(today);

	const selectedDate = (date) => {
		let nDate = moment(date).format('YYYY-MM-DD');
		setBookingDate(nDate);
		props.callback(nDate);
	};

	const selectMonth = (selectedMonth) => {
		setMonth(selectedMonth);
	};

	const handleClose = () => {
		props.callback();
	};

	return (
		<div>
			<Modal
				hideBackdrop
				open={props.open}
				className={classes.modal}
				closeAfterTransition
				BackdropComponent={Backdrop}
				aria-labelledby="child-modal-title"
				aria-describedby="child-modal-description"
			>
				<Fade in={props.open} timeout={200}>
					<div className={classes.paper}>
						<ExpCalendarWrapper>
							<div id="close-icon-wrapper" onClick={handleClose}>
								{' '}
								<img src={arrowDownIcon} width={25} height={15} />
							</div>
							<div style={{ marginBottom: 15 }}>
								<CalendarMonth month={month} selectMonth={selectMonth} />
							</div>

							<div>
								<BookingCalendar
									bookingDate={bookingDate}
									month={month}
									selectedDate={selectedDate}
									// disabledDays={{ before: today }}
								/>
							</div>
						</ExpCalendarWrapper>
					</div>
				</Fade>
			</Modal>
		</div>
	);
}

const StyledDialog = styled(Dialog)`
	& > .MuiDialog-container > .MuiPaper-root {
		background-color: transparent;
		color: white;
		width: 403px;
		height: 190px;
	}

	#delete-alert-dialog-conent {
		display: flex;
		flex-direction: column;
		align-items: center;
		border-radius: 0.8em;
		height: 100%;
		width: 100%;
		padding: 40px 5px 10px 5px;
		font-size: 16px;
		font-weight: 500;
		background: rgba(0, 0, 0, 0.6);
		user-select: none;

		span {
			display: flex;
			flex-direction: row;

			p {
				color: #fe6b7c;
				font-weight: 700;
			}
		}
	}

	#dialog-btn-container {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	#dialog-cancel-btn {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 50px;
		height: 50px;
		border-radius: 10px;
		cursor: pointer;
		background: #cccccc;
		margin: 20px 10px;

		&:active {
			background-color: #e1d9d9;
			outline: none;
			outline-offset: none;
		}
		img {
			user-select: none;
		}
	}

	#dialog-confirm-btn {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 50px;
		height: 50px;
		border-radius: 10px;
		cursor: pointer;
		background: #f37d7d;
		margin: 20px 10px;

		&:active {
			background-color: #ff8585;
			outline: none;
			outline-offset: none;
		}
		img {
			user-select: none;
		}
	}
`;

function DeleteAlertDialog(props) {
	const handleClose = () => {
		props.toggleDeletePrompt(false);
	};

	const handleSubmit = () => {
		props.handleExpDelete();
		handleClose();
	};

	return (
		<div>
			<StyledDialog onClose={handleClose} open={props.confirmDelete}>
				<div className={props.classes.paper}>
					<div id="delete-alert-dialog-conent">
						<span>
							Delete &nbsp;
							<p>{props.name} &nbsp;</p> ?
						</span>
						<span>This will remove all the settings for this experience.</span>
						<div id="dialog-btn-container">
							<div id="dialog-cancel-btn" onClick={handleClose}>
								{' '}
								<img src={closeIcon} width={15} height={15} />
							</div>
							<div id="dialog-confirm-btn" onClick={handleSubmit}>
								{' '}
								<img src={checkIcon} width={18} height={14} />
							</div>
						</div>
					</div>
				</div>
			</StyledDialog>
		</div>
	);
}

function ExperienceSettingModal(props) {
	const classes = useStyles();
	const [startDateCalendar, setStartDateCalendar] = useState(false);
	const [endDateCalendar, setEndDateCalendar] = useState(false);
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [exp, setExp] = useState('');
	const [data, setData] = useState('');
	const [isRecurring, setIsRecurring] = useState(false);
	const [hasBookingFee, setHasBookingFee] = useState(false);
	const [recurringType, setRecurringType] = useState(-1);
	const [recurringValue, setRecurringValue] = useState(0);
	const [bookingFee, setBookingFee] = useState('');
	const [flatRate, setFlatRate] = useState('');
	const [dayOfweek, setDayOfweek] = useState([]);
	const [shiftId, setshiftId] = useState(0);
	const [daysOfWeek, setDaysOfWeek] = useState({
		mon: false,
		tue: false,
		wed: false,
		thu: false,
		fri: false,
		sat: false,
		sun: false,
	});

	const [alertHandler, setAlertHandler] = useState(false);
	const [dialogAlertMessage, setDialogAlertMessage] = useState('');

	const handleClose = () => {
		props.setOpenExp(false);
		setTimeout(() => {
			props.unmountSelectedExp();
			setRecurringType(-1);
			setRecurringValue(0);
			setDayOfweek([]);
			setshiftId(0);
			setDaysOfWeek({
				mon: false,
				tue: false,
				wed: false,
				thu: false,
				fri: false,
				sat: false,
				sun: false,
			});
		}, 500);
	};

	const toggleRecurring = () => {
		setIsRecurring(!isRecurring);
	};

	const toggleAddBookingFee = () => {
		setHasBookingFee(!hasBookingFee);
	};

	const handleBookingFee = (e) => {
		let price;
		if (e.target.validity.valid) {
			price = parseInt(e.target.value) || '';
		} else {
			return;
		}
		setBookingFee(price);
	};

	const hanldeFlatRate = (e) => {
		let price;
		if (e.target.validity.valid) {
			price = parseInt(e.target.value) || '';
		} else {
			return;
		}
		setFlatRate(price);
	};

	const openStartDateCalendar = () => {
		setStartDateCalendar(true);
	};

	const openEndDateCalendar = () => {
		setEndDateCalendar(true);
	};

	const startDateCallback = (val) => {
		if (val === undefined) {
			setStartDateCalendar(false);
		} else {
			setStartDate(val);
			setStartDateCalendar(false);
		}
	};

	const endDateCallback = (val) => {
		if (val === undefined) {
			setEndDateCalendar(false);
		} else {
			setEndDate(val);
			setEndDateCalendar(false);
		}
	};

	const ordinalSuffixChecker = (int) => {
		let value = int;
		if (int === 1) {
			return value + 'st';
		} else if (int === 2) {
			return value + 'nd';
		} else if (int === 3) {
			return value + 'rd';
		} else {
			return value + 'th';
		}
	};

	const changeRecurringType = (int) => {
		let newData = data;
		newData = { ...newData, recurring_type: int };

		setData(newData);
		setRecurringType(int);
	};

	const changeRecurringValue = (int) => {
		let newData = data;
		newData = { ...newData, recurring_value: int };

		setData(newData);
		setRecurringValue(int);
	};

	const daysOfWeekChecker = (array) => {
		let mon = false;
		let tue = false;
		let wed = false;
		let thu = false;
		let fri = false;
		let sat = false;
		let sun = false;

		if (array.includes(1)) {
			mon = true;
		}
		if (array.includes(2)) {
			tue = true;
		}
		if (array.includes(3)) {
			wed = true;
		}
		if (array.includes(4)) {
			thu = true;
		}
		if (array.includes(5)) {
			fri = true;
		}
		if (array.includes(6)) {
			sat = true;
		}
		if (array.includes(7)) {
			sun = true;
		}

		setDaysOfWeek({
			mon: mon,
			tue: tue,
			wed: wed,
			thu: thu,
			fri: fri,
			sat: sat,
			sun: sun,
		});
	};

	const changeDayOfWeek = (int) => {
		let days = dayOfweek;
		if (days.includes(int)) {
			let idx = days.findIndex((e) => e === int);
			days.splice(idx, 1);
		} else {
			days.push(int);
			days.sort();
		}

		let newData = data;
		newData = { ...newData, day_of_week: days, recurring_value: 0 };
		setDayOfweek(days);
		setData(newData);
		setRecurringValue(0);
		daysOfWeekChecker(days);
	};

	const selectEveryChecker = (val, date) => {
		if (val === RECURRINGVALUE.everyDayOfMonth) {
			let day = moment(date).format('dddd');
			let startDay = moment(date).startOf('day').isoWeek() + 1;
			let startMonth = moment(date).startOf('month').isoWeek();
			let weekNum;
			if (startMonth > startDay) {
				weekNum = startDay;
			} else {
				weekNum = startDay - startMonth;
			}
			return ordinalSuffixChecker(weekNum) + ' ' + day;
		}

		if (val === RECURRINGVALUE.everyDateOfMonth) {
			let int = moment(date).date();
			return ordinalSuffixChecker(int);
		}
	};

	const changeShifthandler = (id) => {
		let newData = data;
		newData = { ...newData, shift_id: id };
		setshiftId(id);
		setData(newData);
	};

	const toggleDeletePrompt = () => {
		setConfirmDelete(!confirmDelete);
	};

	const handleExpDelete = () => {
		let params = {
			id: exp.id,
		};
		props.expDelete(params);
		handleClose();
	};

	const handleSubmit = () => {
		let params;
		if (data.id) {
			//Existing exp
			params = {
				id: data.id,
				start_date: startDate,
				end_date: endDate ? endDate : null,
				booking_fee: hasBookingFee ? bookingFee : 0,
				flat_rate: hasBookingFee ? flatRate : 0,
				shift_id: shiftId,
				recurring_type: recurringType,
				recurring_value: recurringValue,
				day_of_week: RECURRINGTYPE.weekly === recurringType ? dayOfweek : [],
				experience_id: exp.id,
			};
		} else {
			//New exp
			params = {
				id: 0,
				start_date: startDate,
				end_date: endDate ? endDate : null,
				booking_fee: hasBookingFee ? bookingFee : 0,
				flat_rate: hasBookingFee ? flatRate : 0,
				shift_id: shiftId,
				recurring_type: recurringType,
				recurring_value: recurringValue,
				day_of_week: RECURRINGTYPE.weekly === recurringType ? dayOfweek : [],
				experience_id: exp.id,
			};
		}

		if (shiftId === undefined) {
			setDialogAlertMessage({
				title: 'No shift selected',
				body: <div>Shift is required</div>,
			});
			setAlertHandler(true);
			return;
		}

		if (params.start_date === undefined) {
			setDialogAlertMessage({
				title: 'No start date selected',
				body: <div>Start Date is required</div>,
			});
			setAlertHandler(true);
			return;
		}

		if (params.recurring_type === RECURRINGTYPE.weekly) {
			let selectedIdxDay = dayOfweek[0];
			let sdate = moment(startDate).day(selectedIdxDay).toDate();
			let start = moment(startDate).day();
			const startDay = moment(startDate).format('dddd');
			const formatedStartDay = moment(sdate).format('dddd');

			if (start > selectedIdxDay) {
				setDialogAlertMessage({
					title: 'Invalid Date and Day',
					body: (
						<div>
							Selected Start date is {startDate} {startDay} <br />
							Later than start of selected day {formatedStartDay}
						</div>
					),
				});
				setAlertHandler(true);
			} else {
				if (endDate !== null) {
					let lastIdxDay = dayOfweek[dayOfweek.length - 1];
					let edate = moment(endDate).day(lastIdxDay).toDate();
					let end = moment(endDate).day();
					const endDay = moment(endDate).format('dddd');
					const formatedEndDay = moment(edate).format('dddd');
					// console.log(lastIdxDay, edate, end, endDay, formatedEndDay);
					if (end < lastIdxDay) {
						setDialogAlertMessage({
							title: 'Invalid Date and Day',
							body: (
								<div>
									Selected End date is {endDate} {endDay} <br />
									further than end of selected day {formatedEndDay}
								</div>
							),
						});
						setAlertHandler(true);
					} else {
						// console.log('yes');
						props.expAssign(params);
						handleClose();
					}
				} else {
					// console.log('yes');
					props.expAssign(params);
					handleClose();
				}
			}
		} else {
			// console.log(params);
			props.expAssign(params);
			handleClose();
		}
	};

	// console.log({
	// 	start_date: startDate,
	// 	end_date: endDate,
	// 	recurring_type: recurringType,
	// 	recurring_value: recurringValue,
	// });

	useEffect(() => {
		setExp(props.selectedExp);
		if (props.expData !== null) {
			setData(props.expData);
			setStartDate(props.expData.start_date);
			setEndDate(props.expData.end_date);
			setshiftId(props.expData.shift_id);
			if (props.expData.recurring_type !== undefined) {
				setRecurringType(props.expData.recurring_type);
			}
			if (props.expData.day_of_week !== undefined) {
				if (props.expData.day_of_week.length > 0) {
					daysOfWeekChecker(props.expData.day_of_week);
					setDayOfweek(props.expData.day_of_week);
				}
			}
			if (props.expData.recurring_type !== -1) {
				setIsRecurring(true);
			} else {
				setIsRecurring(false);
			}

			if (props.expData.booking_fee > 0) {
				setHasBookingFee(true);
			}
		} else {
			setIsRecurring(false);
			return;
		}
	}, [props.selectedExp, props.expData]);

	return (
		<div>
			<Modal
				className={classes.modal}
				open={props.openExp}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Fade in={props.openExp}>
					<div className={classes.paper}>
						<Wrapper>
							<div id="close-icon-wrapper" onClick={handleClose}>
								{' '}
								<img src={arrowDownIcon} width={25} height={15} />
							</div>

							<DeleteAlertDialog
								name={exp.exp_name}
								confirmDelete={confirmDelete}
								toggleDeletePrompt={toggleDeletePrompt}
								handleExpDelete={handleExpDelete}
								classes={classes}
							/>

							<AlertDialog
								alertHandler={alertHandler}
								dialogAlertMessage={dialogAlertMessage}
								setAlertHandler={setAlertHandler}
							/>

							<Content>
								{exp !== undefined ? (
									<>
										<h3>{exp.exp_name}</h3>
										<p>{exp.exp_description}</p>
									</>
								) : (
									''
								)}
								{data !== undefined ? (
									<div id="top-row-container">
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between',
												marginBottom: 10,
											}}
										>
											<label>Selected Date:</label>
											<label>Is Recurring:</label>
										</div>

										<div style={{ display: 'flex' }}>
											<div
												onClick={openStartDateCalendar}
												id="calendar-btn"
												style={startDate ? { color: '#bcbcbc' } : { color: 'gray' }}
											>
												{startDate ? startDate : 'Start Date'}
											</div>
											<div
												onClick={openEndDateCalendar}
												id="calendar-btn"
												style={endDate ? { color: '#bcbcbc' } : { color: 'gray' }}
											>
												{endDate ? endDate : 'End Date'}
											</div>
											<div style={{ position: 'absolute', right: 0 }}>
												<ToggleSwitch checked={isRecurring} onChange={toggleRecurring} />
											</div>
										</div>
									</div>
								) : (
									''
								)}
								{isRecurring ? (
									<>
										{' '}
										<label>Select Recurring Type:</label>
										<div style={{ display: 'flex', marginBottom: 15 }}>
											<Button
												onClick={() => changeRecurringType(0)}
												style={
													data.recurring_type === 0
														? { background: '#ff8585', margin: 5 }
														: { background: '#d6d4d8', margin: 5 }
												}
											>
												Yearly
											</Button>
											<Button
												onClick={() => changeRecurringType(1)}
												style={
													data.recurring_type === 1
														? { background: '#ff8585', margin: 5 }
														: { background: '#d6d4d8', margin: 5 }
												}
											>
												Monthly
											</Button>
											<Button
												onClick={() => changeRecurringType(2)}
												style={
													data.recurring_type === 2
														? { background: '#ff8585', margin: 5 }
														: { background: '#d6d4d8', margin: 5 }
												}
											>
												Weekly
											</Button>
										</div>
										<label style={{ marginBottom: 15 }}>Select Every:</label>
										{recurringType === RECURRINGTYPE.annually ? (
											<div style={{ display: 'flex', marginBottom: 20 }}>
												<Button
													onClick={() => changeRecurringValue(4)}
													style={
														data.recurring_value === 4
															? { background: '#ff8585', margin: 5 }
															: { background: '#222a2f', margin: 5 }
													}
												>
													{selectEveryChecker(2, startDate)} Day of Month
												</Button>
											</div>
										) : recurringType === RECURRINGTYPE.monthly ? (
											<div style={{ display: 'flex', marginBottom: 20 }}>
												<Button
													onClick={() => changeRecurringValue(1)}
													style={
														data.recurring_value === 1
															? { background: '#ff8585', margin: 5 }
															: { background: '#222a2f', margin: 5 }
													}
												>
													{selectEveryChecker(1, startDate)} of Month
												</Button>
												<Button
													onClick={() => changeRecurringValue(2)}
													style={
														data.recurring_value === 2
															? { background: '#ff8585', margin: 5 }
															: { background: '#222a2f', margin: 5 }
													}
												>
													{selectEveryChecker(2, startDate)} Day of Month
												</Button>
											</div>
										) : recurringType === RECURRINGTYPE.weekly ? (
											<div style={{ display: 'flex', marginBottom: 20 }}>
												<Daybutton onClick={() => changeDayOfWeek(1)} active={daysOfWeek.mon}>
													Mon
												</Daybutton>
												<Daybutton onClick={() => changeDayOfWeek(2)} active={daysOfWeek.tue}>
													Tue
												</Daybutton>
												<Daybutton onClick={() => changeDayOfWeek(3)} active={daysOfWeek.wed}>
													Wed
												</Daybutton>
												<Daybutton onClick={() => changeDayOfWeek(4)} active={daysOfWeek.thu}>
													Thu
												</Daybutton>
												<Daybutton onClick={() => changeDayOfWeek(5)} active={daysOfWeek.fri}>
													Fri
												</Daybutton>
												<Daybutton onClick={() => changeDayOfWeek(6)} active={daysOfWeek.sat}>
													Sat
												</Daybutton>
												<Daybutton onClick={() => changeDayOfWeek(7)} active={daysOfWeek.sun}>
													Sun
												</Daybutton>
											</div>
										) : (
											''
										)}
									</>
								) : (
									''
								)}

								<div id="selectShift-addBookingFee-container">
									<div id="select-shift">
										<label>Select Shift:</label>
										<div id="container">
											{props.shifts.length > 0
												? props.shifts.map((shift) => {
														return (
															<Button
																onClick={() => changeShifthandler(shift.id)}
																key={shift.id}
																style={
																	shiftId === shift.id
																		? { background: '#ff8585', margin: '0 15px 0 0' }
																		: { background: '#222a2f', margin: '0 15px 0 0' }
																}
															>
																{shift.name}
															</Button>
														);
												  })
												: ''}
										</div>
									</div>
									<div id="add-booking-fee">
										<label style={{ marginLeft: 10 }}>Add Booking Fee: </label>
										<span>
											<input
												type="text"
												pattern="[0-9]{0,6}"
												placeholder="Booking Fee"
												value={bookingFee}
												onChange={(e) => handleBookingFee(e)}
											/>
											<input
												type="text"
												pattern="[0-9]{0,6}"
												placeholder="Flat Rate"
												value={flatRate}
												onChange={(e) => hanldeFlatRate(e)}
											/>
										</span>
									</div>
								</div>
								<div style={{ position: 'absolute', bottom: 135, right: 20 }}>
									<ToggleSwitch checked={hasBookingFee} onChange={toggleAddBookingFee} />
								</div>
								<div id="btm-btn-container">
									<AssignExpBtn onClick={handleSubmit}> Assign Experience</AssignExpBtn>
									<DeleteBtn onClick={toggleDeletePrompt}>
										<img src={closeIcon} width={12} height={12} />
									</DeleteBtn>
								</div>
								{/* Monthly = every date, every day of the choosen month, every numWeek of month */}
							</Content>
							<ExperienceCalendar
								open={startDateCalendar}
								initVal={startDate === null ? new Date() : startDate}
								callback={startDateCallback}
								key={'Start Date'}
							/>
							<ExperienceCalendar
								open={endDateCalendar}
								initVal={endDate === null ? new Date() : endDate}
								callback={endDateCallback}
								key={'End Date'}
							/>
						</Wrapper>
					</div>
				</Fade>
			</Modal>
		</div>
	);
}

export default ExperienceSettingModal;
