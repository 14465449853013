import React, { Component } from 'react';

import calendarIcon from 'assets/images/booking/reports/shift-occupancy-icon.png';
import coverTrendIcon from 'assets/images/booking/reports/cover-trend-icon.png';
import resevationIcon from 'assets/images/booking/reports/reservation-icon.png';
import turnTimeIcon from 'assets/images/booking/reports/turn-time-icon.png';
import personStarIcon from 'assets/images/booking/reports/first-time-visit-icon.png';
import personFrequencyIcon from 'assets/images/booking/reports/guest-frequency-icon.png';

export const ReportsNavData = [
	{
		title: 'SHIFT OCCUPANCY',
		icon: <img src={`${calendarIcon}`} width={22} height={22} />,
	},
	// {
	// 	title: 'COVER TREND',
	// 	icon: <img src={`${coverTrendIcon}`} width={22} height={22} />,
	// },
	// {
	// 	title: 'RESERVATION',
	// 	icon: <img src={`${resevationIcon}`} width={22} height={22} />,
	// },
	// {
	// 	title: 'TURN TIMES',
	// 	icon: <img src={`${turnTimeIcon}`} width={22} height={22} />,
	// },
	// {
	// 	title: 'FIRST TIME VISITS',
	// 	icon: <img src={`${personStarIcon}`} width={22} height={22} />,
	// },
	// {
	// 	title: 'GUEST FREQUENCY',
	// 	icon: <img src={`${personFrequencyIcon}`} width={22} height={22} />,
	// },
];
