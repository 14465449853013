import { createGlobalStyle } from 'styled-components';
import { up } from 'styled-breakpoints';

// Some basic resets, not sure where the OG dev pulled from
import './reset.css';

export const GlobalStyle = createGlobalStyle`
:root {
	--primary-color: #fe7d86;
	--primary-color-light: #ff8381;
	--text-primary: #222a2f;
	--text-secondary: #707070;
	--text-white: #fff;
  --background-white: #fff;
	--background-secondary: #f9f9fa;
}

body {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

  font-size: 0.9rem;

  ${up('md')} {
    font-size: 1rem;
  }
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1 {
	font-size: 3.25rem;
	font-weight: 600;
}

h2 {
	font-size: 3.25rem;
	font-weight: 600;
}

h3 {
	font-weight: 600;
	font-size: 1.25rem;
}

h5 {
	font-size: 3.25rem;
}

p {
	letter-spacing: -0.1px;
}

a {
	text-decoration: underline;
}
a:hover {
	color: var(--primary-color);
}

// TODO find me a home
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

`;
