import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Nav, Row, Col, Form, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { apiUrl } from '../../utils/Utility';
import withDashboardApp from '../../components/withDashboardApp';

import power from '../../assets/images/power.png';

const Box = styled.div`
	display: block;
	background: #ffffff;
	border-radius: 10px;
	box-shadow: 0px 3px 6px #00000029;
	width: 769px;
	padding: 38px 46px;
	margin: auto;
`;

const BoxInput = styled.div`
	display: block;
	background: #ffffff;
	border-radius: 30px;
	height: 60px;
	width: 100%;
	border: 1px solid #cccccc;
	margin-top: 47px;
	margin-bottom: 25px;
`;

function Widget() {
	const reducers = useSelector((state) => state.userReducers);
	const [btnTab1, setBtnTab1] = useState('w-tab1 active-tab');
	const [btnTab2, setBtnTab2] = useState('w-tab2');
	const base_url = window.location.origin;

	const script1 = `<script type='text/javascript' src='${apiUrl}/load?key=${reducers && reducers.user.widgetToken}'></script>`;
	const script2 = `https://www.opentable.com.au/r/santoni-pizza-reservations-hawthorn?restref=118175&lang=en-AU&ot_source=Restaurant%20website`;
	const disableTab = (e) => {
		setBtnTab1('w-tab1');
		setBtnTab2('w-tab2');
	};

	const handleTab1 = (e) => {
		disableTab();
		setBtnTab1('w-tab1 active-tab');
	};

	const handleTab2 = (e) => {
		disableTab();
		setBtnTab2('w-tab2 active-tab');
	};

	const handleCopy = (e) => {
		navigator.clipboard.writeText(script1);
	};

	const handleCopy2 = (e) => {
		navigator.clipboard.writeText(script2);
	};

	useEffect(() => {
		console.log(reducers);
	}, [reducers]);

	return (
		<section className="widget">
			<div className="grid-img">
				<Box>
					<h3>Reservation at Your Restaurant</h3>
					<Nav variant="tabs" defaultActiveKey="/home" style={{ marginTop: 26 }}>
						<Nav.Item>
							<Button onClick={handleTab1} className={btnTab1}>
								1. Find a table
							</Button>
						</Nav.Item>
						<Nav.Item>
							<Button onClick={handleTab2} className={btnTab2}>
								2. Your Details
							</Button>
						</Nav.Item>
					</Nav>
					<BoxInput>
						<Form.Group>
							<Row style={{ margin: 0 }}>
								<div className="w-select">
									<Form.Control as="select">
										<option>2 people</option>
										<option>2 people</option>
									</Form.Control>
								</div>
								<div className="w-select">
									<Form.Control as="select">
										<option>28 AUG</option>
										<option>29 AUG</option>
									</Form.Control>
								</div>
								<div className="w-select">
									<Form.Control as="select">
										<option>7.00pm</option>
										<option>8.00pm</option>
									</Form.Control>
								</div>
								<Button id="search-table">Search a Table</Button>
							</Row>
						</Form.Group>
					</BoxInput>

					<Row>
						<Col lg={2} md={2} sm={2} xs={2}>
							<Button className="btn-clock">6:00pm</Button>
						</Col>
						<Col lg={2} md={2} sm={2} xs={2}>
							<Button className="btn-clock">6:30pm</Button>
						</Col>
						<Col lg={2} md={2} sm={2} xs={2}>
							<Button className="btn-clock">7:00pm</Button>
						</Col>
						<Col lg={2} md={2} sm={2} xs={2}>
							<Button className="btn-clock">7:30pm</Button>
						</Col>
						<Col lg={2} md={2} sm={2} xs={2}>
							<Button className="btn-clock">8:00pm</Button>
						</Col>
						<Col lg={2} md={2} sm={2} xs={2}>
							<Button className="btn-clock">8:30pm</Button>
						</Col>
					</Row>
					<div style={{ width: 150, margin: 'auto' }}>
						<img
							src={power}
							width="144"
							height="25"
							className="d-inline-block align-top"
							style={{ verticalAlign: 'middle', marginTop: 30 }}
						/>
					</div>
				</Box>
			</div>

			<code>
				<p id="scr1">{String(script1)}</p>
			</code>
			<Row>
				<Col style={{ textAlign: 'right' }}>
					<Button onClick={handleCopy} className="btn-copy">
						COPY
					</Button>
				</Col>
			</Row>

			{/* <h4>MARKETING TRACKING</h4>
			<Form.Control id="sel-wid" as="select">
				<option>Website</option>
				<option>Apps</option>
			</Form.Control>
			<code>
				<p>{String(script2)}</p>
			</code>
			<Row>
				<Col style={{ textAlign: 'right' }}>
					<Button onClick={handleCopy2} className="btn-copy">
						COPY
					</Button>
				</Col>
			</Row>
			<p>
				Be sure to test this code. While we’ve done our best to design it for use in different
				website environments, we provide this code “as-is” and make no warranties, representations,
				covenants or guarantees with regard to the reservation tools, and will not be liable for any
				direct, indirect, incidental or consequential damages or for loss of profit, revenue, data,
				business or use arising out of your use of the reservation tools.
			</p> */}
		</section>
	);
}

export default withDashboardApp(Widget);
