import React, { Fragment } from 'react';
import { Link, withRouter, useLocation } from 'react-router-dom';
import { Media } from 'react-bootstrap';
import cookie from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../actions/userActions';

function SideMenu() {
	const location = useLocation();
	const dispatch = useDispatch();
	const reducers = useSelector((state) => state.userReducers);

	const logout = () => {
		cookie.remove('token');
		dispatch(userActions.logOutUser());
		dispatch(userActions.setLoading());
	};

	return (
		<Fragment>
			<Media style={{ marginTop: 30, marginLeft: 20 }}>
				<div className="w-avatar">D</div>
				<Media.Body style={{ marginTop: 7, marginLeft: 10 }}>
					<h5>{reducers.user.email}</h5>
				</Media.Body>
			</Media>
			<div className="divider-s" style={{ marginTop: 13, marginBottom: 28 }}></div>
			<ul className="list-unstyled">
				<Link to="/dashboard/booking">
					<div
						className={
							['/dashboard/booking', '/dashboard/booking/table-planning'].includes(
								location.pathname,
							)
								? 'shadow-list-active'
								: 'shadow-list'
						}
					>
						<Media className="list-db" as="li">
							<svg
								className="icon-db"
								xmlns="http://www.w3.org/2000/svg"
								width="16.537"
								height="16.537"
								viewBox="0 0 16.537 16.537"
							>
								<g
									id="Icon_feather-settings"
									data-name="Icon feather-settings"
									transform="translate(-0.5 -0.5)"
								>
									<path
										id="Path_4721"
										data-name="Path 4721"
										d="M17.465,15.482A1.982,1.982,0,1,1,15.482,13.5,1.982,1.982,0,0,1,17.465,15.482Z"
										transform="translate(-6.714 -6.714)"
										stroke={
											location.pathname === '/dashboard/booking' ? '#FFF' : 'var(--text-secondary)'
										}
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
									/>
									<path
										id="Path_4722"
										data-name="Path 4722"
										d="M13.658,10.751a1.09,1.09,0,0,0,.218,1.2l.04.04a1.322,1.322,0,1,1-1.87,1.87l-.04-.04a1.1,1.1,0,0,0-1.863.78v.112a1.322,1.322,0,1,1-2.643,0v-.059a1.09,1.09,0,0,0-.714-1,1.09,1.09,0,0,0-1.2.218l-.04.04a1.322,1.322,0,1,1-1.87-1.87l.04-.04a1.1,1.1,0,0,0-.78-1.863H2.822a1.322,1.322,0,1,1,0-2.643h.059a1.09,1.09,0,0,0,1-.714,1.09,1.09,0,0,0-.218-1.2l-.04-.04a1.322,1.322,0,1,1,1.87-1.87l.04.04a1.09,1.09,0,0,0,1.2.218h.053a1.09,1.09,0,0,0,.661-1V2.822a1.322,1.322,0,0,1,2.643,0v.059a1.1,1.1,0,0,0,1.863.78l.04-.04a1.322,1.322,0,1,1,1.87,1.87l-.04.04a1.09,1.09,0,0,0-.218,1.2v.053a1.09,1.09,0,0,0,1,.661h.112a1.322,1.322,0,1,1,0,2.643h-.059a1.09,1.09,0,0,0-1,.661Z"
										fill="none"
										stroke={
											location.pathname === '/dashboard/booking' ? '#FFF' : 'var(--text-secondary)'
										}
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
									/>
								</g>
							</svg>
							<Media.Body>
								<h4>Wabi Reservations</h4>
							</Media.Body>
						</Media>
					</div>
				</Link>
				<Link to="/dashboard/pos-settings">
					<div
						className={
							['/dashboard/pos-settings', '/dashboard/pos-settings/day-view-sales'].includes(
								location.pathname,
							)
								? 'shadow-list-active'
								: 'shadow-list'
						}
					>
						<Media className="list-db" as="li">
							<svg
								className="icon-db"
								xmlns="http://www.w3.org/2000/svg"
								width="16.537"
								height="16.537"
								viewBox="0 0 16.537 16.537"
							>
								<g
									id="Icon_feather-settings"
									data-name="Icon feather-settings"
									transform="translate(-0.5 -0.5)"
								>
									<path
										id="Path_4721"
										data-name="Path 4721"
										d="M17.465,15.482A1.982,1.982,0,1,1,15.482,13.5,1.982,1.982,0,0,1,17.465,15.482Z"
										transform="translate(-6.714 -6.714)"
										stroke={
											location.pathname === '/dashboard/booking' ? '#FFF' : 'var(--text-secondary)'
										}
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
									/>
									<path
										id="Path_4722"
										data-name="Path 4722"
										d="M13.658,10.751a1.09,1.09,0,0,0,.218,1.2l.04.04a1.322,1.322,0,1,1-1.87,1.87l-.04-.04a1.1,1.1,0,0,0-1.863.78v.112a1.322,1.322,0,1,1-2.643,0v-.059a1.09,1.09,0,0,0-.714-1,1.09,1.09,0,0,0-1.2.218l-.04.04a1.322,1.322,0,1,1-1.87-1.87l.04-.04a1.1,1.1,0,0,0-.78-1.863H2.822a1.322,1.322,0,1,1,0-2.643h.059a1.09,1.09,0,0,0,1-.714,1.09,1.09,0,0,0-.218-1.2l-.04-.04a1.322,1.322,0,1,1,1.87-1.87l.04.04a1.09,1.09,0,0,0,1.2.218h.053a1.09,1.09,0,0,0,.661-1V2.822a1.322,1.322,0,0,1,2.643,0v.059a1.1,1.1,0,0,0,1.863.78l.04-.04a1.322,1.322,0,1,1,1.87,1.87l-.04.04a1.09,1.09,0,0,0-.218,1.2v.053a1.09,1.09,0,0,0,1,.661h.112a1.322,1.322,0,1,1,0,2.643h-.059a1.09,1.09,0,0,0-1,.661Z"
										fill="none"
										stroke={
											location.pathname === '/dashboard/booking' ? '#FFF' : 'var(--text-secondary)'
										}
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
									/>
								</g>
							</svg>
							<Media.Body>
								<h4>POS SETTINGS</h4>
							</Media.Body>
						</Media>
					</div>
				</Link>
				<Link to="/dashboard">
					<div
						className={
							location.pathname === '/dashboard' ||
							location.pathname === '/dashboard/edit-profile' ||
							location.pathname === '/dashboard/edit-plan'
								? 'shadow-list-active'
								: 'shadow-list'
						}
					>
						<Media className="list-db" as="li">
							<svg
								className="icon-db"
								xmlns="http://www.w3.org/2000/svg"
								width="16.537"
								height="16.537"
								viewBox="0 0 16.537 16.537"
							>
								<g
									id="Icon_feather-settings"
									data-name="Icon feather-settings"
									transform="translate(-0.5 -0.5)"
								>
									<path
										id="Path_4721"
										data-name="Path 4721"
										d="M17.465,15.482A1.982,1.982,0,1,1,15.482,13.5,1.982,1.982,0,0,1,17.465,15.482Z"
										transform="translate(-6.714 -6.714)"
										stroke={
											location.pathname === '/dashboard' ||
											location.pathname === '/dashboard/edit-profile' ||
											location.pathname === '/dashboard/edit-plan'
												? '#FFF'
												: 'var(--text-secondary)'
										}
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
									/>
									<path
										id="Path_4722"
										data-name="Path 4722"
										d="M13.658,10.751a1.09,1.09,0,0,0,.218,1.2l.04.04a1.322,1.322,0,1,1-1.87,1.87l-.04-.04a1.1,1.1,0,0,0-1.863.78v.112a1.322,1.322,0,1,1-2.643,0v-.059a1.09,1.09,0,0,0-.714-1,1.09,1.09,0,0,0-1.2.218l-.04.04a1.322,1.322,0,1,1-1.87-1.87l.04-.04a1.1,1.1,0,0,0-.78-1.863H2.822a1.322,1.322,0,1,1,0-2.643h.059a1.09,1.09,0,0,0,1-.714,1.09,1.09,0,0,0-.218-1.2l-.04-.04a1.322,1.322,0,1,1,1.87-1.87l.04.04a1.09,1.09,0,0,0,1.2.218h.053a1.09,1.09,0,0,0,.661-1V2.822a1.322,1.322,0,0,1,2.643,0v.059a1.1,1.1,0,0,0,1.863.78l.04-.04a1.322,1.322,0,1,1,1.87,1.87l-.04.04a1.09,1.09,0,0,0-.218,1.2v.053a1.09,1.09,0,0,0,1,.661h.112a1.322,1.322,0,1,1,0,2.643h-.059a1.09,1.09,0,0,0-1,.661Z"
										fill="none"
										stroke={
											location.pathname === '/dashboard' ||
											location.pathname === '/dashboard/edit-profile' ||
											location.pathname === '/dashboard/edit-plan'
												? '#FFF'
												: 'var(--text-secondary)'
										}
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
									/>
								</g>
							</svg>
							<Media.Body>
								<h4>Profile settings</h4>
							</Media.Body>
						</Media>
					</div>
				</Link>

				<Link to="/dashboard/widget">
					<div
						className={
							location.pathname === '/dashboard/widget' ? 'shadow-list-active' : 'shadow-list'
						}
					>
						<Media className="list-db" as="li">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16.667"
								height="16.667"
								viewBox="0 0 16.667 16.667"
							>
								<path
									id="Path_4713"
									data-name="Path 4713"
									d="M13.167,2.5H16.5a.833.833,0,0,1,.833.833V16.667a.833.833,0,0,1-.833.833H1.5a.833.833,0,0,1-.833-.833V3.333A.833.833,0,0,1,1.5,2.5H4.833V.833H6.5V2.5h5V.833h1.667Zm2.5,5V4.167h-2.5V5.833H11.5V4.167h-5V5.833H4.833V4.167h-2.5V7.5Zm0,1.667H2.333v6.667H15.667ZM4,10.833H8.167v3.333H4Z"
									transform="translate(-0.667 -0.833)"
									fill={
										location.pathname === '/dashboard/widget' ? '#FFF' : 'var(--text-secondary)'
									}
								/>
							</svg>
							<Media.Body>
								<h4>Wabi Widget</h4>
							</Media.Body>
						</Media>
					</div>
				</Link>

				<Link to="/dashboard/message">
					<div
						className={
							location.pathname === '/dashboard/message' ? 'shadow-list-active' : 'shadow-list'
						}
					>
						<Media className="list-db" as="li">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16.667"
								height="16.667"
								viewBox="0 0 16.667 16.667"
							>
								<path
									id="Path_4713"
									data-name="Path 4713"
									d="M13.167,2.5H16.5a.833.833,0,0,1,.833.833V16.667a.833.833,0,0,1-.833.833H1.5a.833.833,0,0,1-.833-.833V3.333A.833.833,0,0,1,1.5,2.5H4.833V.833H6.5V2.5h5V.833h1.667Zm2.5,5V4.167h-2.5V5.833H11.5V4.167h-5V5.833H4.833V4.167h-2.5V7.5Zm0,1.667H2.333v6.667H15.667ZM4,10.833H8.167v3.333H4Z"
									transform="translate(-0.667 -0.833)"
									fill={
										location.pathname === '/dashboard/message' ? '#FFF' : 'var(--text-secondary)'
									}
								/>
							</svg>
							<Media.Body>
								<h4>Messages</h4>
							</Media.Body>
						</Media>
					</div>
				</Link>
			</ul>
			<button className="logout" type="button" onClick={() => logout()}>
				Log out
			</button>
		</Fragment>
	);
}

export default withRouter(SideMenu);
