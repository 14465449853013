import React, { useState, useEffect, Fragment } from 'react';
import { TableLayoutPlanningData } from './TableLayoutPlanningData';
import { Container, Row, Col, Media } from 'react-bootstrap';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';
import { apiUrl } from '../../../../utils/Utility';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

// Components
import withDashboardApp from 'components/withDashboardApp';
import BookingNavBar from '../BookingNavBar/BookingNavBar';
import TableSettings from './pages/TableSettings';
// import ModalSpinner from './pages/SubComponents/ModalSpinner';

// Icons
import saveIcon from '../../../../assets/images/booking/table-layout-planning/save-icon.png';
import TableFloorPlan from './pages/TableFloorPlan';
import TableCombination from './pages/TableCombination';
import TableSchedule from './pages/TableSchedule';
import TableSpecialDays from './pages/TableSpecialdays';
import TableExperience from './pages/TableExperience';

function TableLayoutandPlanning() {
	const history = useHistory();
	const reducers = useSelector((state) => state.userReducers);
	const [isInit, setIsInit] = useState(false);
	const [isSelected, setIsSelected] = useState(TableLayoutPlanningData[0]);
	// const [newFloors, setNewFloors] = useState([]);
	const [showNavbar, setShowNavbar] = useState(false);
	const [accSettingsData, setAccSettingsData] = useState(null);
	const [floors, setfloors] = useState(history.location.state.find((e) => e.floors));
	const [shifts, setShifts] = useState(history.location.state.find((e) => e.shifts));
	const [experienceList, setExperienceList] = useState(
		history.location.state.find((e) => e.experienceList),
	);
	const [isSaving, setisSaving] = useState(false);

	const toggleSelected = (item) => {
		if (item !== isSelected) {
			setIsSelected(item);
		}
		// console.log(isSelected);
	};

	// console.log(history.location.state.find((e) => e.experienceList));
	const toggleBookingNavBar = () => setShowNavbar(!showNavbar);

	const getAccountSettings = async () => {
		if (!isInit) {
			try {
				const response = await axios({
					url: `${apiUrl}/api/get_account_settings`,
					method: 'POST',
					data: {},
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${reducers.token}`,
					},
				});

				if (response) {
					const { status } = response;
					const { data } = response.data;
					if (status === 200) {
						setAccSettingsData(data.setting);
						// console.log(data);
					}
				}
			} catch (err) {
				console.log('Error: ', err);
			}
		} else {
			return;
		}
	};

	const getFloor = async () => {
		try {
			const response = await axios({
				url: `${apiUrl}/api/get_floors`,
				method: 'POST',
				data: {},
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${reducers.token}`,
				},
			});

			if (response) {
				const { status } = response;
				const { data } = response.data;
				const { floors } = data;
				if (status === 200) {
					setfloors(floors);
				}
			}
		} catch (err) {
			console.log('Error: ', err);
		}
	};

	const handleSubmit = () => {
		setisSaving(true);
	};

	// console.log(history);
	useEffect(() => {
		if (!isInit) {
			if (accSettingsData === null) {
				getAccountSettings();
				setIsInit(true);
			}
			// if (isLoading) {
			// 	setfloors([]);
			// 	getFloor();
			// 	setIsInit(true);
			// 	setisSaving(false);
			// }
		}
	}, [isInit]);

	return (
		<Fragment>
			<Container style={{ background: 'transparent' }} fluid>
				<BookingNavBar
					showNavbar={showNavbar}
					showNavbarHandler={setShowNavbar}
					data={history.location.state}
				/>
				<Row style={{ marginRight: 0, marginLeft: 0, justifyContent: 'space-between' }}>
					<Col
						className="menu-btn-container"
						style={{ marginTop: 30, marginBottom: 30 }}
						sm={1}
						md={1}
						lg={1}
					>
						<div
							style={{ marginLeft: 10 }}
							className="btn-menu btn-menu--margin-top"
							onClick={toggleBookingNavBar}
						>
							<Media className="list-db" as="li">
								<svg
									style={{ marginTop: 12, marginLeft: 11, marginRight: 11 }}
									xmlns="http://www.w3.org/2000/svg"
									width="18"
									height="15"
									viewBox="0 0 18 15"
								>
									<g id="Group_347" data-name="Group 347" transform="translate(-0.333)">
										<rect
											id="Rectangle_42"
											data-name="Rectangle 42"
											width="18"
											height="3"
											rx="1.5"
											transform="translate(0.333)"
											fill="#fff"
										/>
										<rect
											id="Rectangle_43"
											data-name="Rectangle 43"
											width="18"
											height="3"
											rx="1.5"
											transform="translate(0.333 6)"
											fill="#fff"
										/>
										<rect
											id="Rectangle_44"
											data-name="Rectangle 44"
											width="18"
											height="3"
											rx="1.5"
											transform="translate(0.333 12)"
											fill="#fff"
										/>
									</g>
								</svg>
							</Media>
						</div>
					</Col>
					<Col sm={7} md={7} lg={7} style={{ padding: 0, margin: 0 }}>
						<div className="tlp-nav-container">
							{TableLayoutPlanningData.map((item, key) => {
								return (
									<ul className="tlp-nav-menu" key={key}>
										<li className="tlp-nav-list" onClick={(e) => toggleSelected(item)}>
											<div className={isSelected === item ? 'tlp-nav-btn selected' : 'tlp-nav-btn'}>
												{item.icon}
												{isSelected === item ? (
													<span className="tlp-nav-text">{item.title}</span>
												) : (
													''
												)}
											</div>
										</li>
									</ul>
								);
							})}
						</div>
					</Col>
					<Col sm={4} md={4} lg={4}>
						{isSelected.title !== 'TABLE COMBINATION' &&
						isSelected.title !== 'SPECIAL DAYS' &&
						isSelected.title !== 'EXPERIENCE' ? (
							<div className="tlp-saveIcon-wrapper" onClick={handleSubmit}>
								<img src={saveIcon} width={24} height={24} />
							</div>
						) : (
							''
						)}
					</Col>
				</Row>
				<Row style={{ marginRight: 0, marginLeft: 0, justifyContent: 'space-between' }}>
					<div className="tlp-content-container">
						{isSelected.title === 'SETTINGS' ? (
							<TableSettings
								dataSettings={accSettingsData !== null ? accSettingsData : ''}
								isSaving={isSaving}
								setisSaving={setisSaving}
							/>
						) : isSelected.title === 'FLOOR PLAN' ? (
							<DndProvider backend={HTML5Backend}>
								<TableFloorPlan
									accSettingsData={accSettingsData}
									floors={floors.length > 0 ? floors : floors.floors}
									setfloors={setfloors}
									isSaving={isSaving}
									setisSaving={setisSaving}
									// setfloors={setfloors}
								/>
							</DndProvider>
						) : isSelected.title === 'TABLE COMBINATION' ? (
							<TableCombination
								floors={floors.length > 0 ? floors : floors.floors}
								getFloor={getFloor}
							/>
						) : isSelected.title === 'SCHEDULE' ? (
							<TableSchedule
								floors={floors.length > 0 ? floors : floors.floors}
								shifts={shifts.shifts}
							/>
						) : isSelected.title === 'SPECIAL DAYS' ? (
							<TableSpecialDays
								floors={floors.length > 0 ? floors : floors.floors}
								shifts={shifts.shifts}
							/>
						) : isSelected.title === 'EXPERIENCE' ? (
							<TableExperience shifts={shifts.shifts} experience={experienceList.experienceList} />
						) : (
							''
						)}
					</div>
					{/* </DndProvider> */}
				</Row>
			</Container>
		</Fragment>
	);
}

export default withDashboardApp(TableLayoutandPlanning);
