import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import AddGroupModal from './sub-components/Roster/AddGroupModal';
import Schedule from './sub-components/Roster/Schedule';

const useStyles = makeStyles((theme) => ({
	paper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: 170,
		background: '#f7f7f7',
		color: '#818181',
		cursor: 'pointer',
		userSelect: 'none',
		fontSize: 16,
		fontWeight: 700,
		boxShadow: '3px 3px 10px #1c1c1e4d',
		borderRadius: 10,
	},
}));

const Wrapper = styled.div`
	position: relative;
	height: 100%;
	width: 100%;
	overflow: hidden;

	// ::-webkit-scrollbar {
	// 	width: 10px;
	// 	height: 10px;
	// 	background-color: white;
	// }

	// ::-webkit-scrollbar-thumb {
	// 	width: 5px;
	// 	height: 5px;
	// 	border-radius: 20px;
	// 	background-color: #cccccc;
	// }

	// ::-webkit-scrollbar-corner {
	// 	display: none;
	// }
`;

const AddButton = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background: white;
	width: fit-content;
	height: 52px;
	color: #818181;
	font-size: 14px;
	font-weight: 700;
	padding: 5px 25px;
	border-radius: 2em;
	box-shadow: 0px 1px 10px #1c1c1e4d;
	margin: 10px 15px 15px 0;
	white-space: nowrap;
	user-select: none;
	cursor: pointer;

	&:active {
		background: #f2f2f2;
	}
`;

const TextContainer = styled.div`
	position: absolute;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	color: #818181;
	font-size: 14px;
	font-weight: 700;
`;

function Roster(props) {
	const classes = useStyles();
	const [isAddModalOpen, setIsAddModalOpen] = useState(false);
	const [isGroupSelected, setIsGroupSelected] = useState(false);
	const [selectedGroup, setSelectedGroup] = useState('');

	const handleAddRosterGrp = () => {
		setIsAddModalOpen(!isAddModalOpen);
	};

	const newRosterGrpCallback = (data) => {
		console.log(data);
		let params = {
			id: 0,
			group_name: data,
		};
		console.log(params);
		props.createGroupSchedule(params);
		setIsAddModalOpen(false);
	};

	const selectGroup = (data) => {
		if (data) {
			let params = {
				employee_group_schedule_id: data.id,
			};
			props.getScheduleDate(params);
		}
		setSelectedGroup(data);
		setIsGroupSelected(!isGroupSelected);
	};

	return (
		<Wrapper>
			<div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
				<AddButton onClick={handleAddRosterGrp}>Add New Roster Group</AddButton>
			</div>

			<AddGroupModal
				open={isAddModalOpen}
				closeHandler={setIsAddModalOpen}
				callback={newRosterGrpCallback}
				title="Please enter group name"
			/>

			<Grid container spacing={2} style={{ padding: '0 15px' }}>
				{props.groupSchedule.length > 0 ? (
					props.groupSchedule.map((e, key) => {
						return (
							<Grid item xs={3} key={key} onClick={() => selectGroup(e)}>
								<div className={classes.paper}>{e.group_name}</div>
							</Grid>
						);
					})
				) : (
					<TextContainer>No available Roster group</TextContainer>
				)}
			</Grid>
			<Schedule
				isUpdating={props.isUpdating}
				employees={props.employees}
				isGroupSelected={isGroupSelected}
				selectedGroup={selectedGroup}
				employeeShifts={props.employeeShifts}
				groupSchedule={props.groupSchedule}
				groupScheduleDate={props.groupScheduleDate}
				stations={props.stations}
				selectGroup={selectGroup}
				getDeletedSchedule={props.getDeletedSchedule}
				createScheduleDate={props.createScheduleDate}
				getEmployeeSchedule={props.getEmployeeSchedule}
				employmentRoles={props.employmentRoles}
				createStations={props.createStations}
				createEmployeeSchedule={props.createEmployeeSchedule}
			/>
		</Wrapper>
	);
}

export default Roster;
