import React, { useState } from 'react';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DayPicker from 'react-day-picker';
import moment from 'moment';
import 'react-day-picker/lib/style.css';

import CalendarMonth from '../../CreatBooking/calendar-month-navigation/CalendarMonth';

import arrowDown from 'assets/images/booking/arrow-down-icon.png';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		width: '100%',
		height: '100%',
		backdropFilter: 'blur(10px)',
		outline: 'none',
	},
}));

function UserRolesCalendar(props) {
	const classes = useStyles();
	const [month, setMonth] = useState(moment(new Date()));

	const selectMonth = (selectedMonth) => {
		setMonth(selectedMonth);
	};

	const selectDate = (date) => {
		props.handleCallback(moment(date).format('YYYY-MM-DD'));
		props.handleToggle();
	};

	return (
		<Modal
			aria-labelledby="user-roles-calendar"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={props.isOpen}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={props.isOpen}>
				<div className={classes.paper}>
					<div className="select-booking-calendar-container">
						<img
							onClick={() => props.handleToggle()}
							src={arrowDown}
							width={20}
							height={15}
							className="d-inline"
							style={{
								textAlign: 'center',
								margin: '10px 20px',
								marginRight: 10,
								cursor: 'pointer',
								position: 'absolute',
								top: 10,
								right: 10,
							}}
							alt="checker"
						/>
						<div className="select-booking-calendar-main-container">
							<div
								style={{
									display: 'inline-block',
									marginBottom: 10,
								}}
							>
								<CalendarMonth month={month} selectMonth={selectMonth} />
							</div>
							<DayPicker
								month={new Date(month)}
								selectedDays={new Date()}
								onDayClick={selectDate}
								showOutsideDays={true}
								className="booking-calendar"
								// disabledDays={props.disabledDays}
							/>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}

export default UserRolesCalendar;
