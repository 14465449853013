import React, { useState, useEffect, Fragment, useCallback, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import { apiUrl } from '../../utils/Utility';
import withDashboardApp from '../../components/withDashboardApp';

const Floor = () => {
    const reducers = useSelector((state) => state.userReducers);
    const [data, setData] = useState([]);
    const [subData, setSubData] = useState([]);
    const [floorData, setFloorData] = useState([]);
  
    const columns = [
      {
        name: 'Floor',
        selector: 'floor_name',
        sortable: true
      },
      {
        name: 'Date/Time Created',
        selector: 'created_at',
        sortable: true
      }
    ];
    
    const subAColumns = [
      {
        name: 'Table Name',
        selector: 'name',
        sortable: true
      },
      {
        name: 'Minimum Capacity',
        selector: 'capacity_min',
        sortable: true
      },
      {
        name: 'Maximum Capacity',
        selector: 'capacity_max',
        sortable: true
      }
    ];
    
    const subBColumns = [
      {
        name: 'Date',
        selector: 'block_date',
        sortable: true
      },
      {
        name: 'Start Time',
        selector: 'start_time',
        sortable: true
      },
      {
        name: 'End Time',
        selector: 'end_time',
        sortable: true
      }
    ];
   
    useEffect(() => {
	axios({
            url: `${apiUrl}/api/get_floors`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${reducers.token}`
            }
        })
        .then((resp) => {
            setData(resp.data.data.floors);
        })
        .catch((err) => {
            console.log(err);
        });
    }, []);
    
    useEffect(() => {
        if ( data.length > 0 ) {
          _.forEach(
            data,
            ({ floor_name, created_at, tables, combined_tables }) => {
                _.forEach(
                    tables,
                    ({ block_tables }, v) => {
                        if ( block_tables.length > 0 ) {
                            let tempBlockTables = [];
                            _.forEach(
                                block_tables,
                                ({date, start_time, end_time}) => {
                                    let hourStart = Math.floor(start_time / 3600);
                                    let minuteStart = (start_time % 3600) / 60;
                                    if ( minuteStart == 0 ) {
                                        minuteStart = "00";
                                    }
                                    let hourEnd = Math.floor(end_time / 3600);
                                    let minuteEnd = (end_time % 3600) / 60;
                                    if ( minuteEnd == 0 ) {
                                        minuteEnd = "00";
                                    }
                                    tempBlockTables.push({
                                        block_date : date,
                                        start_time : hourStart + ":" + minuteStart,
                                        end_time : hourEnd + ":" + minuteEnd
                                    });
                                });
                            tables[v].block_tables = tempBlockTables;
                        }
                    }
                )
                setFloorData((floorData) => [
                  ...floorData,
                  {
                    floor_name,
                    created_at : moment(created_at).format("YYYY-MM-DD HH:mm:ss"),
                    tables,
                    combined_tables
                  }
                ]);
            }
          );
        }
    }, [data]);
    
    const expandB =  (e) => {
        return (
            <div style={{ padding: '1rem 3rem' }}>
                <DataTable
                    title="Blocked"
                    columns={subBColumns}
                    data={e.data.block_tables}
                />
            </div>
        );
    }
    
    const expandA = (e) => {
        return (
            <div style={{ padding: '1rem 3rem' }}>
                <DataTable
                    title="Tables"
                    columns={subAColumns}
                    data={e.data.tables}
                    expandableRows
                    expandableRowsComponent={expandB}
                />
                <DataTable
                    title="Combined Tables"
                    columns={subAColumns}
                    data={e.data.combined_tables}
                />
            </div>
        );
    }
    
    return (
        <Fragment>
            <DataTable
                title="Floors"
                columns={columns}
                data={floorData}
                expandableRows
                expandableRowsComponent={expandA}
            />
        </Fragment>
    );
};

export default withDashboardApp(Floor);
