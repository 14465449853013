import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import axios from 'axios';
import { apiUrl, imgUrl } from 'utils/Utility';
import poweredByImage from '../../assets/images/power.png';
import moment from 'moment';
import { isMobile } from 'react-device-detect';

const EditBooking = (props) => {
	const params = props.match.params;
	const [booking, setBooking] = useState(null);

	const getBooking = async () => {
		try {
			const response = await axios({
				url: `${apiUrl}/api/widget/booking/${atob(params.bookingId)}/full`,
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${atob(params.id)}`,
				},
			});
			if (response) {
				const { status } = response;
				const { data } = response.data;
				if (status === 200) {
					setBooking(data.booking);
				}
			}
		} catch (err) {
			console.log('Error: ', err);
		}
	};

	const cancelBooking = async () => {
		if (booking.status == 0) {
			try {
				const response = await axios({
					url: `${apiUrl}/api/widget/booking/${atob(params.bookingId)}/cancel`,
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${atob(params.id)}`,
					},
				});

				if (response) {
					const { status } = response;
					const { data } = response.data;
					if (status === 200) {
						setBooking(data.booking);
					}
				}
			} catch (err) {
				console.log('Error: ', err);
			}
		}
	};

	useEffect(() => {
		getBooking();
		console.log(booking);
	}, []);

	return (
		<Container fluid className={!isMobile ? 'edit-booking' : 'edit-booking-small'}>
			{booking && (
				<section>
					<div className="header">
						<div className="restaurant-logo">
							<img src={imgUrl + booking.owner.business_profile.profile_pic} alt="Avatar" />
						</div>
						<div className="restaurant-name">
							<label>{booking.owner.business_profile.business_name}</label>
						</div>
					</div>
					<div className="middle">
						<div className="middle-title">Booking Details</div>
						<div className="booking-details">
							<Row
								style={{
									width: '100%',
								}}
							>
								<Col className="column-center">
									<span>Name: </span>
									{/* Enter Guest name here */}
									<span className="guest-name">
										{booking.guest.first_name + ' ' + booking.guest.last_name}
									</span>
								</Col>
								<Col className="column-center">
									<span>Booking Date: </span>
									{/* Enter Booking Date here */}
									<span className="guest-name">
										{moment(booking.booking_date).format('DD/MM/YYYY HH:mm A')}
									</span>
								</Col>
							</Row>
							<Row
								style={{
									width: '100%',
								}}
							>
								<Col className="column-center">
									<span>Contact Number: </span>
									{/* Enter Guest Contact Number here */}
									<span className="guest-name">{booking.guest.phone}</span>
								</Col>
								<Col className="column-center">
									<span>Booking Time: </span>
									{/* Enter Booking Time here */}
									<span className="guest-name">
										{moment(booking.booking_date).format('DD/MM/YYYY HH:mm A')}
									</span>
								</Col>
							</Row>
							<Row
								style={{
									width: '100%',
								}}
							>
								<Col className="column-center">
									<span>Email: </span>
									{/* Enter Guest Email here */}
									<span className="guest-name">{booking.guest.email}</span>
								</Col>
								<Col className="column-center">
									<span>Party Size: </span>
									{/* Enter Party Size here */}
									<span className="guest-name">{booking.party_size} person/s</span>
								</Col>
							</Row>
						</div>
						<div
							style={{
								width: '100%',
								textAlign: 'center',
								marginTop: 15,
							}}
						>
							<Button
								color="primary"
								style={{
									backgroundColor: '#fe7d86',
									borderColor: '#fe7d86',
								}}
								onClick={() => cancelBooking()}
							>
								{booking.status == 0 ? 'Cancel Booking' : 'Cancelled'}
							</Button>
						</div>
						<div
							style={{
								width: '100%',
								textAlign: 'center',
								marginTop: 15,
							}}
						>
							<img
								style={{
									display: 'initial',
								}}
								width={145}
								height={25}
								src={poweredByImage}
							></img>
						</div>
					</div>
				</section>
			)}
		</Container>
	);
};

export default EditBooking;
