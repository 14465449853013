import React, { useState, useEffect } from 'react';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BOOKINGTYPE } from '../../../../../constants/enum';
import styled from 'styled-components';

// Icons
import exitBtnTableIcon from '../../../../../assets/images/booking/exit-btn-table-details.png';
import bookingStatusPhone from '../../../../../assets/images/booking/phone-icon-with-name.png';
import bookingStatusWalkIn from '../../../../../assets/images/booking/walk-in-icon-with-name.png';
import bookingStatusNetwork from '../../../../../assets/images/booking/network-icon-with-name.png';
import experienceIcon from '../../../../../assets/images/booking/experience-icon-with-name.png';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		width: '100%',
		height: '100%',
		backdropFilter: 'blur(10px)',
		outline: 'none',
		alignItems: 'center',
	},
}));

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	position: relative;
`;
const ButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
`;
const ButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 5px;
	cursor: pointer;
	border-radius: 10px;
	padding: 10px;
	height: 80px;
	width: 80px;
	background: black;
	&:active {
		background: linear-gradient(0deg, #ff8585 0%, #ff7192 100%);
	}
`;

function SelectBookingType(props) {
	const classes = useStyles();
	const [isSelected, setIsSelected] = useState(false);

	// const handleOpen = props.selectBookingOpen;
	const handleClose = () => {
		props.handleCloseSelectBookingType(false);
	};

	const handleSelectBooking = (value) => {
		props.updateSelectedBookingType(value);
		props.handleCloseSelectBookingType(false);
	};

	return (
		<div>
			<Modal
				className={classes.modal}
				open={props.selectBookingOpen}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={props.selectBookingOpen}>
					<div className={classes.paper}>
						<Wrapper>
							<label
								style={{
									fontSize: 30,
									fontWeight: 'bold',
									color: 'white',
									display: 'inline-flex',
									flexDirection: 'row',
									alignItems: 'center',
									paddingTop: '17px',
									paddingBottom: '17px',
								}}
							>
								SELECT A BOOKING TYPE
							</label>
							<div
								onClick={(e) => handleClose(e)}
								style={{ position: 'absolute', right: 15, top: 15, cursor: 'pointer' }}
							>
								<img src={exitBtnTableIcon} width="15" height="15" alt="checker" />
							</div>
							<ButtonContainer>
								<ButtonWrapper onClick={(e) => handleSelectBooking(BOOKINGTYPE.phone)}>
									<img src={bookingStatusPhone} width="48em" height="55em" alt="checker" />
								</ButtonWrapper>
								<ButtonWrapper onClick={(e) => handleSelectBooking(BOOKINGTYPE.inhouse)}>
									<img src={bookingStatusWalkIn} width="70em" height="50em" alt="checker" />
								</ButtonWrapper>
								<ButtonWrapper onClick={(e) => handleSelectBooking(BOOKINGTYPE.widget)}>
									<img src={bookingStatusNetwork} width="70em" height="50em" alt="checker" />
								</ButtonWrapper>
								<ButtonWrapper onClick={(e) => handleSelectBooking(BOOKINGTYPE.experience)}>
									<img src={experienceIcon} width="70em" height="50em" alt="checker" />
								</ButtonWrapper>
							</ButtonContainer>
						</Wrapper>
					</div>
				</Fade>
			</Modal>
		</div>
	);
}
export default SelectBookingType;
