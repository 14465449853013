import React, {useState, useEffect, Fragment} from 'react';
import { Media } from 'react-bootstrap';

import segmentBooking from '../../../assets/images/booking/segment-booking.png';
import segmentBookingSelected from '../../../assets/images/booking/segment-booking-selected.png';
import segmentStatus from '../../../assets/images/booking/segment-status.png';
import segmentStatusSelected from '../../../assets/images/booking/segment-status-selected.png';
import segmentCategory from '../../../assets/images/booking/segment-categorized-booking.png';
import segmentCategorySelected from '../../../assets/images/booking/segment-categorized-selected.png';
import segmentNotification from '../../../assets/images/booking/segment-notification.png';
import segmentNotificationSelected from '../../../assets/images/booking/segment-notification-selected.png';

const SegmentedControl = (props) => {

    const [selectedOption, setSelectedOption] = useState('1');

    const selectOption = (e) => {
        setSelectedOption(e.target.value)
        props.selectedOption(e.target.value);
    }

    return (
        <div className='segmented-control'>
            <input id="rad1" type="radio" onChange={selectOption} value='1' checked={selectedOption === "1"} name="radioBtn"/>
            <label
                htmlFor="rad1"
                style={{ width: 82, height: 50, display: 'inline-block', zIndex:2, cursor: 'pointer', paddingLeft:15, paddingTop: 7 }}
            >
                <img
                    
                    src={selectedOption === "1" ? segmentBookingSelected :segmentBooking}
                    width="20"
                    height="20"
                    className="d-inline"
                    style={{ marginTop: 9, marginLeft: 15, marginRight:15 }}
                    alt="checker"
                />
            </label>
            <input id="rad2" onChange={selectOption} type="radio" value='2' checked={selectedOption === "2"} name="radioBtn"/>
            <label
                htmlFor="rad2"
                style={{ width: 82, height: 50, display: 'inline-block', zIndex:2, cursor: 'pointer', paddingLeft:15, paddingTop: 7 }}
            >
                <img
            
                    src={selectedOption === "2" ? segmentStatusSelected : segmentStatus}
                    width="20"
                    height="20"
                    className="d-inline"
                    style={{ marginTop: 9, marginLeft: 15, marginRight:15 }}
                    alt="checker"
                />
            </label>
            <input id="rad3" onChange={selectOption} type="radio" value='3' checked={selectedOption === "3"} name="radioBtn"/>
            <label
                htmlFor="rad3"
                style={{ width: 82, height: 50, display: 'inline-block', zIndex:2, cursor: 'pointer', paddingLeft:15, paddingTop: 7 }}
            >
                <img
                    
                    src={selectedOption === "3" ? segmentCategorySelected : segmentCategory}
                    width="20"
                    height="20"
                    className="d-inline"
                    style={{ marginTop: 9, marginLeft: 15, marginRight:15 }}
                    alt="checker"
                />
            </label>
            <input id="rad4" onChange={selectOption} type="radio" value='4' checked={selectedOption === "4"} name="radioBtn"/>
            <label
                htmlFor="rad4"
                style={{ width: 82, height: 50, display: 'inline-block', zIndex:2, cursor: 'pointer', paddingLeft:15, paddingTop: 7 }}
            >
                <img
                    
                    src={selectedOption === "4" ? segmentNotificationSelected : segmentNotification}
                    width="20"
                    height="20"
                    className="d-inline"
                    style={{ marginTop: 9, marginLeft: 15, marginRight:15 }}
                    alt="checker"
                />
            </label>
            <div id="bckgrnd"></div>
        </div>
    );
};

export default SegmentedControl;