import React from 'react';
import cx from 'classnames';
import LoaderStyle from '../assets/scss/Loader.module.scss';

const Loader = ({ isFixed }) => {
	return (
		<div className={cx(LoaderStyle.ldsRing, isFixed && LoaderStyle.isFixed)}>
			<div />
			<div />
			<div />
			<div />
		</div>
	);
};

export default Loader;
