import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	position: relative;
	margin-bottom: 10px;
`;

const Selected = styled.select`
	display: flex;
	justify-content: center;
	width: 90%;
	height: 100%;
	border: 0px;
	outline: 0px;
	border-radius: 10px;
	text-align: center;
	cursor: pointer;
	background: #505050;
	font-size: 15px;
	color: #d1d1d1;

	::-webkit-scrollbar {
		width: 5px;
		height: 5px;
		background-color: #efefef;
	}
	::-webkit-scrollbar-thumb {
		width: 1px;
		height: 1px;

		border-radius: 20px;
		background-color: #b3b3b3;
	}

	::-webkit-scrollbar-corner {
		display: none;
	}
`;

const Option = styled.option`
	font-size: 15px;
	background: #efefef;
	color: #6a3636;
`;

function TimePicker(props) {
	const hArray = [];
	for (let i = 1; i <= 12; i++) {
		if (i === 12) {
			let obj = {
				time: i,
				value: 12,
			};
			hArray.push(obj);
		} else {
			let obj = {
				time: i,
				value: i * 3600,
			};
			hArray.push(obj);
		}
	}

	const mArray = [];
	for (let i = 0; i <= 60; i++) {
		let obj = {
			time: i,
			value: i * 60,
		};
		mArray.push(obj);
	}
	const [hour, setHour] = useState(0);
	const [minute, setMinute] = useState(0);
	const [period, setPeriod] = useState(0);

	const periodData = [
		{
			title: 'AM',
			value: 0,
		},
		{
			title: 'PM',
			value: 43200,
		},
	];

	const hourChange = (int) => {
		setHour(int);
		handleCallback(int, minute, period);
	};

	const minuteChange = (int) => {
		setMinute(int);
		console.log(int);
		handleCallback(hour, int, period);
	};

	const changePeriod = (int) => {
		setPeriod(int);
		let val = int;
		if (hour === 0) {
			val = 0;
		}
		// console.log(hour);
		handleCallback(hour, minute, val);
	};

	const handleCallback = (h, m, p) => {
		if (props.id === 0) {
			let time = p > 0 ? h + p + m : h + m;
			props.timePickerCallback(time, 0);
		} else if (props.id === 1) {
			let time = p > 0 ? h + p + m : h + m;
			props.timePickerCallback(time, 1);
		} else if (props.id === 2) {
			let time = h + m;
			props.timePickerCallback(time, 2);
		}
	};

	useEffect(() => {
		if (props.id === 0) {
			let hour = Math.floor(props.initVal / 3600);
			let currentHour = hour > 12 ? (hour - 12) * 3600 : hour === 12 ? hour : hour * 3600;
			let currentMinutes = Math.floor((props.initVal % 3600) / 60) * 60;
			let currentPeriod = props.initVal >= 43200 ? 43200 : 0;
			setHour(currentHour);
			setMinute(currentMinutes);
			setPeriod(currentPeriod);
		} else if (props.id === 1) {
			let hour = Math.floor(props.initVal / 3600);
			let currentHour = hour > 12 ? (hour - 12) * 3600 : hour === 12 ? hour : hour * 3600;
			let currentMinutes = Math.floor((props.initVal % 3600) / 60) * 60;
			let currentPeriod = props.initVal >= 43200 ? 43200 : 0;
			setHour(currentHour);
			setMinute(currentMinutes);
			setPeriod(currentPeriod);
		} else if (props.id === 2) {
			let currentHour = props.initVal;
			let currentMinutes = Math.floor((props.initVal % 3600) / 60);
			setHour(currentHour);
			setMinute(currentMinutes);
		}
	}, [props.initVal]);

	return (
		<Wrapper>
			<Selected value={hour} onChange={(e) => hourChange(parseInt(e.target.value))}>
				<Option>0</Option>
				{hArray.length > 0 ? (
					hArray.map((h, key) => {
						return (
							<Option key={key} value={h.value}>
								{h.time}
							</Option>
						);
					})
				) : (
					<></>
				)}
			</Selected>

			<Selected value={minute} onChange={(e) => minuteChange(parseInt(e.target.value))}>
				{mArray.length > 0 ? (
					mArray.map((m, key) => {
						return (
							<Option value={m.value} key={key}>
								{moment().minute(m.time).format('mm')}
							</Option>
						);
					})
				) : (
					<></>
				)}
			</Selected>
			{props.id !== 2 ? (
				<Selected value={period} onChange={(e) => changePeriod(parseInt(e.target.value))}>
					{periodData.map((p, key) => {
						return (
							<Option key={key} value={p.value}>
								{p.title}
							</Option>
						);
					})}
				</Selected>
			) : (
				<></>
			)}
		</Wrapper>
	);
}

export default TimePicker;
