import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';

import leftArrow from '../../../../../assets/images/booking/create-booking/left-arrow.png';
import rightArrow from '../../../../../assets/images/booking/create-booking/right-arrow.png';

function CalendarMonth(props) {
	const [selectedMonth, setSelectedMonth] = useState(props.month);
	const [selectedMonthStr, setSelectedMonthStr] = useState(moment(selectedMonth).format('MMMM'));
	const [selectedYearStr, setSelectedYearStr] = useState(moment(selectedMonth).format('YYYY'));

	const prevMonth = () => {
		const previousMonth = moment(selectedMonth).subtract(1, 'month');
		setSelectedMonth(previousMonth);
		setSelectedMonthStr(previousMonth.format('MMMM'));
		setSelectedYearStr(previousMonth.format('YYYY'));
		props.selectMonth(previousMonth);
	};

	const nextMonth = () => {
		const nextMonth = moment(selectedMonth).add(1, 'month');
		setSelectedMonth(nextMonth);
		setSelectedMonthStr(nextMonth.format('MMMM'));
		setSelectedYearStr(nextMonth.format('YYYY'));
		props.selectMonth(nextMonth);
	};

	return (
		<div
			style={{
				width: 420,
				height: 60,
				display: 'flex',
				justifyContent: 'flex-end',
				userSelect: 'none',
			}}
		>
			<div
				style={{
					background: 'rgba(0, 0, 0, 0.6)',
					borderRadius: 10,
					width: 276,
					height: 60,
					marginRight: 11,
					position: 'relative',
				}}
			>
				<div
					style={{
						width: 40,
						height: 60,
						// left: 0,
						// top: 16,
						position: 'absolute',
						cursor: 'pointer',
						padding: '16px 16px',
					}}
					onClick={() => prevMonth()}
				>
					<img src={leftArrow} width="6" height="11" className="d-inline" alt="checker" />
				</div>
				<label
					style={{
						margin: '16px 0',
						color: 'white',
						justifyContent: 'center',
						display: 'flex',
					}}
				>
					{selectedMonthStr}
				</label>
				<div
					style={{
						width: 40,
						height: 60,
						right: 2,
						top: 0,
						position: 'absolute',
						cursor: 'pointer',
						padding: '16px 16px',
					}}
					onClick={() => nextMonth()}
				>
					<img src={rightArrow} width="6" height="11" className="d-inline" alt="checker" />
				</div>
			</div>
			<div
				style={{
					background: 'rgba(0, 0, 0, 0.6)',
					borderRadius: 10,
					width: 132,
					height: 60,
					textAlign: 'center',
				}}
			>
				<label
					style={{
						margin: '16px 0',
						color: 'white',
					}}
				>
					{selectedYearStr}
				</label>
			</div>
		</div>
	);
}

export default CalendarMonth;
