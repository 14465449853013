import React, { useState, useEffect } from 'react';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Styled from 'styled-components';
import btnClose from '../../../../../assets/images/booking/create-booking/button-close-icon.png';

const Container = Styled.div`
	width: 100%;
	height: 100%;
	position: relative;
`;

const MainContainer = Styled.div`
    width: 544px;
    height: 313px;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    color: white;
	overflow-y: scroll;
	overflow-x: hidden;
`;

const Grid = Styled.div`
	// height: 313px;
	// width: 544px;
    display: grid;
	grid-template-columns: auto auto;
	grid-template-rows: auto auto; 
	gap: 15px 10px;
	padding: 15px 15px
	
`;

const ItemWrapper = Styled.div`
	padding: 10px;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 130px;
	user-select: none;
	justify-items: center;
	background: linear-gradient(0deg, #ff8585 0%, #ff7192 100%);
	border-radius: 10px;
	cursor: pointer;
`;

const GridHeader = Styled.div`
	font-size: 1.1em;
`;

const GridContent = Styled.div`
	padding: 10px 0;
	font-size: 0.8em;
	text-overflow: ellipsis;
`;

const GridFooter = Styled.div`
	display:flex;
	justify-content: space-between;
`;

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		width: '100%',
		height: '100%',
		backdropFilter: 'blur(10px)',
		outline: 'none',
	},
}));

function BookingExperience(props) {
	const classes = useStyles();
	const handleClose = () => {
		props.handleCloseBookingExperience();
		// props.setTicketQuantityOpen(false);
	};
	// const [selectedExperience, setSelectedExperience] = useState(null);

	const selectExperience = (id) => {
		// setSelectedExperience(id);
		props.expGetAssign(id);
		props.handleCloseBookingExperience();
		props.setTicketQuantityOpen(true);
	};

	// console.log(props.experienceList);

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={props.bookingExperience}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={props.bookingExperience}>
				<div className={classes.paper}>
					<Container>
						<div
							style={{
								textAlign: 'right',
							}}
						>
							<img
								onClick={() => handleClose()}
								src={btnClose}
								width="48"
								height="48"
								className="d-inline"
								style={{
									textAlign: 'center',
									margin: '10px 20px',
									marginRight: 10,
									cursor: 'pointer',
								}}
								alt="checker"
							/>
						</div>
						<MainContainer>
							<Grid>
								{props.experienceList.length > 0
									? props.experienceList.map((id) => {
											return (
												<ItemWrapper key={Math.random()} onClick={() => selectExperience(id.id)}>
													<GridHeader>
														{id.exp_name}
														{/* {id.id} */}
													</GridHeader>
													<GridContent>{id.exp_description}</GridContent>
													<GridFooter>
														<span>${id.price}.00</span>
														<span>{id.no_of_ticket} Tickets</span>
													</GridFooter>
												</ItemWrapper>
											);
									  })
									: ''}
							</Grid>
						</MainContainer>
					</Container>
				</div>
			</Fade>
		</Modal>
	);
}

export default BookingExperience;
