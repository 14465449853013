import React, { useState, useEffect } from 'react';

function BookingButton(props) {
	const [isSelected, setIsSelected] = useState(props.isSelected ?? false);
	const width = props.width ?? 60;
	const height = props.height ?? 60;
	const buttonOnly = props.buttonOnly ?? false;
	const alwaysSelected = props.alwaysSelected ?? false;
	const canSelect = props.canSelect ?? true;
	const value = props.value;

	const buttonClicked = () => {
		if (canSelect) {
			setIsSelected(!isSelected);
		}
		if (buttonOnly) {
			if (!canSelect) {
				props.clickHandler(value);
			} else {
				props.isSelectedHandler(!isSelected);
			}
		} else {
			props.isSelectedHandler(!isSelected);
		}
	};

	useEffect(() => {
		setIsSelected(props.isSelected);
	}, [props.isSelected]);

	return (
		// let buttonType = props.buttonOnly
		<div
			onClick={() => buttonClicked()}
			style={{
				width: width,
				height: height,
			}}
			className={
				!buttonOnly
					? props.isSelected
						? 'booking-button booking-button--selected'
						: 'booking-button'
					: alwaysSelected
					? 'booking-button booking-button--icon-button booking-button--selected'
					: props.isSelected
					? 'booking-button booking-button--icon-button booking-button--selected'
					: 'booking-button booking-button--icon-button'
			}
		>
			{props.icon ? (
				<img
					src={props.icon}
					width={props.imgWidth === null ? '24' : props.imgWidth}
					height="24"
					className="d-inline"
					style={
						buttonOnly
							? {
									textAlign: 'center',
									margin: '5px 0',
							  }
							: { marginTop: 16, marginLeft: 22 }
					}
					alt="checker"
				/>
			) : (
				''
			)}
			{props.titleName ? (
				<label style={buttonOnly ? { margin: '5px 0', cursor: 'pointer' } : { cursor: 'pointer' }}>
					{props.titleName}
				</label>
			) : (
				''
			)}
		</div>
	);
}

export default BookingButton;
