import React, { useState, useRef, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import arrowDownIcon from '../../../../../assets/images/booking/arrow-down-icon.png';
import reservationLockTableIcon from '../../../../../assets/images/booking/reservation-lock-table-icon.png';
import { BOOKINGSTATUS } from '../../../../../constants/enum';
import { getBookingStatusColor } from '../../../../../utils/Utility';

const DropDownContainer = styled('div')`
	z-index: 5;
	display: inline-block;
	position: relative;
	width: inherit;
	height: 50px;
	border: 0.5px solid #bcbcbc;
	border-radius: 5px;
`;

const DropDownHeader = styled('div')`
	display: inline-flex;
	// flex-direction: row;
	height: 100%;
	width: 100%;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
	position: relative;
	padding: 0 0 0 0;
	margin: 0 0 0 10px;
	color: white;
`;

const DropDownListContainer = styled('div')``;

const DropDownList = styled('ul')`
	position: absolute;
	width: 100%;
	height: 50px;
	right: 0.5px;
	top: 0px;
	padding: 0;
`;

const ListItem = styled('li')`
	// z-index: 999;
	position: relative;
	display: flex;
	// width: inherit;
	height: 50px;
	left: 0px;
	background: #ffffff;
	list-style: none;
	cursor: pointer;
	color: black;
	align-items: center;
	justify-content: flex-start;
	margin: 0;
	padding: 0 0 0 10px;
	border-bottom: 0.5px solid black;
	font-size: 16px;
	color: #000000ba;
`;

function ReservationDropDown(props) {
	const [isOpen, setIsOpen] = useState(false);
	const wrapperRef = useRef(null);
	const toggling = () => {
		if (props.selectionDisabled) {
			return;
		}

		setIsOpen(!isOpen);
	};

	const setSelectedOption = (item) => {
		setIsOpen(!isOpen);
		props.setSelectedOption(item);
	};

	const handleClickOutside = (event) => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			setIsOpen(false);
		}
	};
	useEffect(() => {
		window.addEventListener('click', handleClickOutside);
		return () => {
			window.removeEventListener('click', handleClickOutside);
		};
	}, []);

	// console.log(wrapperRef);

	return (
		<DropDownContainer ref={wrapperRef}>
			<DropDownHeader onClick={toggling}>
				{!props.hideLeftIcon ? (
					<img
						style={{ marginRight: 10 }}
						src={props.selectedOption.icon}
						width="20"
						height="20"
						alt="checker"
					/>
				) : (
					''
				)}
				<span
					style={
						props.selectedOption.value === BOOKINGSTATUS.overTime
							? {
									color: getBookingStatusColor(props.selectedOption.value),
									margin: 0,
									maxWidth: '72%',
									whiteSpace: 'nowrap',
									textOverflow: 'ellipsis',
									overflow: 'hidden',
							  }
							: props.selectedOption.value === BOOKINGSTATUS.finished
							? {
									color: '#999999',
									margin: 0,
									maxWidth: '72%',
									whiteSpace: 'nowrap',
									textOverflow: 'ellipsis',
									overflow: 'hidden',
							  }
							: props.selectedOption.value === BOOKINGSTATUS.cancelled
							? {
									color: '#707070',
									margin: 0,
									maxWidth: '72%',
									whiteSpace: 'nowrap',
									textOverflow: 'ellipsis',
									overflow: 'hidden',
							}
							: props.bookingStatus === 8
							? {
									color: '#707070',
									margin: 0,
									maxWidth: '72%',
									whiteSpace: 'nowrap',
									textOverflow: 'ellipsis',
									overflow: 'hidden',
							}
							: {
									color: 'white',
									margin: 0,
									maxWidth: '72%',
									whiteSpace: 'nowrap',
									textOverflow: 'ellipsis',
									overflow: 'hidden',
							  }
					}
				>
					{props.selectedOption == null
						? '- No Selected Status -'
						: typeof props.selectedOption === 'object'
						? props.selectedOption.name
						: props.selectedOption}
				</span>
				<div className="imgContainer">
					{props.rightIcon == '' ? '' : (
					<img
						src={props.rightIcon}
						width="18"
						height={props.hideLeftIcon ? 18 : 10}
						alt="checker"
					/>
					)}
				</div>
			</DropDownHeader>

			{isOpen && (
				<DropDownListContainer>
					<DropDownList>
						{props.options.map((item) => (
							<ListItem onClick={() => setSelectedOption(item)}>
								{item.icon !== undefined ? (
									<img
										style={{ marginRight: 10 }}
										src={item.icon}
										width="20"
										height="20"
										alt="checker"
									/>
								) : (
									''
								)}
								{typeof item === 'object' ? item.name : item}
							</ListItem>
						))}
					</DropDownList>
				</DropDownListContainer>
			)}
		</DropDownContainer>
	);
}

export default ReservationDropDown;
