import React, { useEffect, useState } from 'react';
import btnUpArrowIcon from '../../../assets/images/booking/up-arrow-icon.png';
import styled from 'styled-components';

const DropDownContainer = styled('div')`
	//   width: 10.5em;
	//   margin: 0 auto;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
`;

const DropDownHeader = styled('div')`
	padding: 8px 15px;
	color: white;
	margin-right: 10px;
	border-top-left-radius: 10px;
	font-size: 14px;
	background: rgba(0, 0, 0, 0.8);
	height: 40px;
	cursor: pointer;
`;

const DropDownListContainer = styled('div')``;

const DropDownList = styled('ul')`
	padding: 0;
	margin: 0;
	padding-left: 1em;
	padding-right: 1em;
	background: rgba(0, 0, 0, 0.8);v
	color: white;
	font-size: 14px;
	position: relative;
	top: -195px;
	right: 10px;
	z-index: 999;
	&:first-child {
		padding-top: 0.8em;
	}
	&:last-child {
		padding-bottom: 0.8em;
	}
`;

const ListItem = styled('li')`
	list-style: none;
	cursor: pointer;
	margin-bottom: 0.8em;
`;

function FloorDropDown(props) {
	const [isOpen, setIsOpen] = useState(false);

	const [selectedFloor, setSelectedFloor] = useState(props.selectedFloor);
	const [floors, setFloors] = useState(props.floors);

	const toggling = () => setIsOpen(!isOpen);

	const selectFloorHandler = (floor) => {
		if (!isOpen) {
			return;
		}
		props.selectFloorCallBack(floor);
		setSelectedFloor(floor);
		setIsOpen(!isOpen);
	};

	// useEffect(() => {
	//     if (selectedFloor) {
	//         console.log('selectedFloor: ', selectedFloor.floor_name)
	//     }
	// }, [selectedFloor, isOpen])

	return (
		<DropDownContainer>
			<DropDownHeader onClick={toggling}>
				{selectedFloor == null ? '- No Selected Floor -' : selectedFloor.floor_name}
				<img
					src={btnUpArrowIcon}
					width="9"
					height="5"
					className="d-inline"
					alt="checker"
					style={{ marginLeft: 20 }}
				/>
			</DropDownHeader>
			{isOpen && (
				<DropDownListContainer>
					<DropDownList>
						{floors.map((option) => (
							<ListItem
								style={{ color: option.id === selectedFloor.id ? '#FE7D86' : 'white' }}
								onClick={() => selectFloorHandler(option)}
								key={Math.random()}
							>
								{option.floor_name}
							</ListItem>
						))}
					</DropDownList>
				</DropDownListContainer>
			)}
		</DropDownContainer>
	);
}

export default FloorDropDown;
