import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';

const BoxContainer = styled.div`
	display: flex;
	height: 50px;
	width: 100%;
	background: #222a2f;
	box-shadow: inset 5px 5px 10px #1a2024, inset -5px -5px 10px #2a343a;
	justify-content: center;
	align-items: center;
`;

const DropDown = styled.select`
	display: flex;
	wordwrap: normal;
	width: 90%;
	height: 60%;
	background: #7070702b;
	color: white;
	appearance: none;
	border: 0px;
	outline: 0px;
	border-radius: 10px;
	text-align: center;
	cursor: pointer;
`;

const DropdownOptions = styled.option`
	background: #707070;
`;

function PickerComponent(props) {
	const [selectedOption, setSelectedOption] = useState(props.initValue),
		handleChange = (option) => {
			setSelectedOption(option);
			props.updateValue(option);
		};

	// console.log(props.initValue);

	return (
		<Container fluid>
			<BoxContainer style={props.style !== undefined ? props.style : {}}>
				<DropDown value={selectedOption} onChange={(e) => handleChange(e.target.value)}>
					{props.data.length > 0 ? (
						props.data.map((d, key) => {
							return (
								<DropdownOptions key={key} value={d.value}>
									{d.option}
								</DropdownOptions>
							);
						})
					) : (
						<DropdownOptions>Please add options</DropdownOptions>
					)}
					{/* {options} */}
				</DropDown>
			</BoxContainer>
		</Container>
	);
}

export default PickerComponent;
