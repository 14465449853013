import React, { Component } from 'react';

function ToggleSwitch(props) {
	return (
		<div className="toggle-switch-container">
			<label className="switch">
				<input type="checkbox" className="checkbox" />
				<span className="slider round" />
			</label>
		</div>
	);
}
export default ToggleSwitch;
