import React from 'react';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';

import newGuestIcon from 'assets/images/booking/guest-book/add-guest-icon.png';
import closeIcon from 'assets/images/booking/exit-btn-table-details.png';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		width: '100%',
		height: '100%',
		backdropFilter: 'blur(10px)',
		outline: 'none',
	},
}));

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
`;
const Content = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 630px;
	height: 370px;
	background: rgba(0, 0, 0, 0.6);
	border-radius: 10px;
	padding: 20px;
	color: white;
	user-select: none;
	// margin-left: 200px;

	img {
		user-select: none;
		-webkit-user-drag: none;
		-khtml-user-drag: none;
		-moz-user-drag: none;
		-o-user-drag: none;
		user-drag: none;
	}

	#add-new-guest-header {
		position: relative;
		display: flex;
		align-items: center;
		flex-direction: row;
		width: 100%;
		margin-bottom: 10px;

		h4 {
			font-size: 16px;
			font-weight: 700;
			margin-left: 15px;
		}
	}

	#add-new-guest-footer {
		display: flex;
		flex-direction: row;
		width: 100%;
		justify-content: space-between;
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		-webkit-text-fill-color: white;
		transition: background-color 5000s ease-in-out 0s;
	}
`;

const StlyedSpan = styled.span`
	position: relative;
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	margin: 15px 0;
	border-bottom: 1px solid ${(props) => (props.hasError ? '#dc3545' : '#cccccc')};
	padding-bottom: 10px;
`;

const Label = styled.label`
	font-size: 16px;
	font-weight: 700;
	margin-left: 15px;
`;

const Input = styled.input`
	width: 75%;
	text-align: start;
	background: transparent;
	color: white;
	font-size: 1em;
	transition: 2ms;
	cursor: default;
`;

const ErrorMsgContainer = styled.div`
	position: absolute;
	top: -7px;
	left: 150px;
	font-size: 10px;
	font-weight: 700;
	color: #dc3545;
`;

const CloseButton = styled.div`
	position: absolute;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 36px;
	width: 36px;
	background: #cccccc;
	border-radius: 0.8em;
	cursor: pointer;
	&:hover {
		background: #b5b5b5;
		img {
			filter: brightness(1.5);
		}
	}
	&:active {
		background: #ffffff;
		img {
			filter: brightness(3.5);
		}
	}
`;

const SubmitButton = styled.button`
	position: absolute;
	right: 15px;
	bottom: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 36px;
	width: 101px;
	background: #fe7d86;
	border-radius: 0.8em;
	font-size: 16px;
	cursor: pointer;
	color: white;
	&:hover {
		background: #ef747d;
	}
	&:active {
		background: #fe7d86d6;
	}
`;
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const formSchema = Yup.object().shape({
	firstName: Yup.string().required('This field is required!'),
	lastName: Yup.string().required('This field is required!'),
	phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
	email: Yup.string().email('Invalid email format'),
});

function AddNewGuestModal(props) {
	const classes = useStyles();
	const handleClose = () => {
		props.setNewGuestOpen(false);
	};

	const onSubmit = (data) => {
		let params = {
			phone: data.phoneNumber,
			company: data.company,
			last_name: data.lastName,
			first_name: data.firstName,
			email: data.email,
		};
		props.addGuest(params);
		handleClose();
	};

	return (
		<Modal
			className={classes.modal}
			open={props.newGuestOpen}
			closeAfterTransition
			BackdropComponent={Backdrop}
			// BackdropProps={{
			// 	timeout: 500,
			// }}
		>
			<Fade in={props.newGuestOpen}>
				<div className={classes.paper}>
					<Wrapper>
						<Content>
							<div id="add-new-guest-header">
								<img src={newGuestIcon} width={26} height={26} />
								<h4>ADD A NEW GUEST</h4>
								<CloseButton onClick={handleClose}>
									<img src={closeIcon} width={12} height={12} />
								</CloseButton>
							</div>
							<Formik
								initialValues={{
									firstName: '',
									lastName: '',
									phoneNumber: '',
									email: '',
									company: '',
								}}
								validationSchema={formSchema}
								onSubmit={onSubmit}
							>
								{({ values, errors, handleSubmit, handleChange, touched, setFieldValue }) => {
									return (
										<Form onSubmit={handleSubmit}>
											<StlyedSpan hasError={errors.firstName && touched.firstName}>
												<Field as={Label}>First Name</Field>
												{touched.firstName ? (
													<ErrorMsgContainer>{errors.firstName}</ErrorMsgContainer>
												) : (
													''
												)}
												<Field as={Input} name="firstName" placeholder="Enter guest first name" />
											</StlyedSpan>
											<StlyedSpan hasError={errors.lastName && touched.lastName}>
												<Field as={Label}>Last Name</Field>
												{touched.lastName ? (
													<ErrorMsgContainer>{errors.lastName}</ErrorMsgContainer>
												) : (
													''
												)}
												<Field as={Input} name="lastName" placeholder="Enter guest last name" />
											</StlyedSpan>
											<StlyedSpan hasError={errors.phoneNumber && touched.lastName}>
												<Field as={Label}>Phone Number</Field>
												<ErrorMsgContainer>{errors.phoneNumber}</ErrorMsgContainer>
												<Field
													onChange={(e) => {
														e.preventDefault();
														const { value } = e.target;
														const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
														console.log(value);
														if (regex.test(value.toString())) {
															setFieldValue('phoneNumber', value);
														} else if (value.length === 0) {
															setFieldValue('phoneNumber', value);
														}
													}}
													as={Input}
													name="phoneNumber"
													placeholder="Enter guest phone number (optional)"
												/>
											</StlyedSpan>
											<StlyedSpan hasError={errors.email && touched.email}>
												<Field as={Label}>Email Address</Field>
												{touched.email ? <ErrorMsgContainer>{errors.email}</ErrorMsgContainer> : ''}
												<Field
													as={Input}
													name="email"
													placeholder="Enter guest email address (optional)"
												/>
											</StlyedSpan>
											<div id="add-new-guest-footer">
												<Field as={Label}>Company</Field>
												<Field
													as={Input}
													name="company"
													placeholder="Enter guest company (optional)"
												/>
											</div>
											<Field as={SubmitButton} type="submit">
												SUBMIT
											</Field>
										</Form>
									);
								}}
							</Formik>
						</Content>
					</Wrapper>
				</div>
			</Fade>
		</Modal>
	);
}

export default AddNewGuestModal;
