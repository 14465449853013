import React, { useState, useEffect, useMemo, Fragment } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { apiUrl } from '../../utils/Utility';
import withDashboardApp from '../../components/withDashboardApp';
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import detailIcon from '../../assets/images/detail-icon.png';
import axios from 'axios';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  Sidebar,
  Search,
  ConversationList,
  Conversation,
  ConversationHeader
} from "@chatscope/chat-ui-kit-react";

import { Row, Col, Media, ButtonGroup, Button } from 'react-bootstrap';

const Box = styled.div`
	display: block;
	background: #ffffff;
	border-radius: 5px;
	box-shadow: 0px 3px 6px #00000029;
	padding: 35px;
`;

function Sms() {
    const reducers = useSelector((state) => state.userReducers);
    const [selectedGuest, setSelectedGuest] = useState(null);
    const [search, setSearch] = useState("");
    const [guestList, setGuestList] = useState([]);
    const [smsList, setSmsList] = useState([]);
    const [messageLoading, setMessageLoading] = useState(false);
    const [category, setCategory] = useState("recent");
    
    useEffect(() => {
        let api = "";
        if ( category === "recent" ) {
            api = "api/guests/recent";
        } else {
            api = "api/guests";
        }
        setSelectedGuest(null);
        setSmsList([]);
	axios({
            url: `${apiUrl}/${api}`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${reducers.token}`
            }
        })
        .then((resp) => {
            setGuestList(resp.data.data.guests);
        })
        .catch((err) => {
            console.log(err);
        });
    }, [category]);
    
    const getMessages = (guest) => {
        axios({
            url: `${apiUrl}/api/sms/list`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${reducers.token}`
            },
            data: {
                guest_id : guest.id
            }
        })
        .then((resp) => {
            setSmsList(resp.data.data.messages);
            setSelectedGuest(guest);
            setMessageLoading(false);
        })
        .catch((err) => {
            console.log(err);
        });
    }
    
    const sendMessage = (message) => {
        axios({
            url: `${apiUrl}/api/sms`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${reducers.token}`
            },
            data: {
                guest : selectedGuest,
                body  : message
            }
        })
        .then((resp) => {
            if ( resp.data.data.sent_successfully === true ) {
                getMessages(selectedGuest);
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }
    
    const filteredGuest = useMemo(() => {
            let filterGuest = guestList;

            if (search) {
                filterGuest = filterGuest.filter(
                    (guest) =>
                            (((guest.first_name ? guest.first_name : "").toLowerCase() + " " + (guest.last_name ? guest.last_name : "").toLowerCase()).includes(search.toLowerCase())),
                );
            }

            return filterGuest;
    }, [guestList, search]);

    return (
        <Fragment>
            <Row>
                <Col>
                    <Box className="box-db">
                    <div style={{ position: "relative", "min-height": "550px" }}>
                        <MainContainer responsive>
                            <Sidebar position="left" scrollable={false} style={{ "height": "800px", "overflow": "auto" }}>
                                <ButtonGroup aria-label="Category">
                                    <Button variant={category == "recent" ? "primary" : "secondary"} onClick={() => setCategory("recent")}>Recent</Button>
                                    <Button variant={category == "all" ? "primary" : "secondary"} onClick={() => setCategory("all")}>Guest List</Button>
                                </ButtonGroup>
                                <Search placeholder="Search Guest..." 
                                        onChange={(v) => {
                                            setSearch(v);
                                            setSelectedGuest(null);
                                            setSmsList([]);
                                        }} 
                                        onClearClick={() => setSearch("")}
                                />
                                {filteredGuest.map((guest) => (
                                <Conversation active={selectedGuest && guest.id === selectedGuest.id ? true : false} 
                                                name={guest.first_name + " " + guest.last_name} 
                                                info={guest.phone} 
                                                onClick={() => {
                                                    setMessageLoading(true);
                                                    getMessages(guest);
                                                    setSelectedGuest(null);
                                                    setSmsList([]);
                                                }}>
                                </Conversation>
                                ))}
                            </Sidebar>
                              <ChatContainer style={{ position: "relative", "height": "800px" }}>
                                {selectedGuest && (
                                <ConversationHeader>
                                    <ConversationHeader.Content userName={selectedGuest.first_name + " " + selectedGuest.last_name} info={selectedGuest.phone} />
                                </ConversationHeader>
                                )}
                                <MessageList loading={messageLoading} style={{ "mix-height": "800px" }}>
                                {smsList.map((sms) => (
                                  <Message
                                    model={{
                                        message: sms.message,
                                        sentTime: sms.time,
                                        direction: sms.direction
                                    }}
                                  />
                                ))}
                                </MessageList>
                                {selectedGuest && (
                                <MessageInput placeholder="Send SMS" attachButton={false} onSend={(e) => sendMessage(e)}/>
                                )}
                              </ChatContainer>
                        </MainContainer>
                    </div>
                    </Box>
                </Col>
            </Row>
        </Fragment>
    );
}

export default withDashboardApp(Sms);
