import React, { useState, useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';
import SelectBookingCalendar from '../BookingCalendar/SelectBookingCalendar';
import { createGuestParameters } from 'utils/RequestBuilder';
import DotLoader from '../TableLayoutPlanning/pages/SubComponents/DotLoader';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 10px;
	margin-right: 10px;
	opacity: ${(props) => (props.isProfileActive ? '1px' : '0')};
	transition: ease-in-out 100ms;
	overflow-y: auto;
	overflow-x: hidden;

	h4 {
		margin: 15px 0;
		font-size: 14px;
		font-weight: 700;
		color: #bcbcbc;
	}

	::-webkit-scrollbar {
		width: 5px;
		height: 5px;
		background-color: #222a2f;
	}
	::-webkit-scrollbar-thumb {
		width: 1px;
		height: 1px;

		border-radius: 20px;
		background-color: #2a343a;
	}

	::-webkit-scrollbar-corner {
		display: none;
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		-webkit-text-fill-color: white;
		transition: background-color 5000s ease-in-out 0s;
	}
`;

const StyledForm = styled(Form)`
	StlyedSpan {
		display: flex;
		justify-content: space-between;
		background: #222a2f;
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		-webkit-text-fill-color: white;
		transition: background-color 5000s ease-in-out 0s;
	}
`;

const StlyedSpan = styled.span`
	position: relative;
	display: flex;
	justify-content: space-between;
	background: #222a2f;
`;

const Label = styled.label`
	margin: 10px 0;
	font-size: 16px;
	font-weight: 700;
	color: #707070;
`;

const Input = styled.input`
	text-align: start;
	background: #222a2f;
	width: 75%;
	color: white;
	padding: 10px;
	font-size: 1em;
	transition: 200ms;
	cursor: text;

	&:focus {
		width: 100%;
		text-align: start;
		box-shadow: inset 5px 5px 10px #1a2024, inset -5px -5px 10px #2a343a;
		border-radius: 5px;
		background: #222a2f;
		padding: 10px;
		transition: 200ms;
	}
`;

const Button = styled.button`
	text-align: start;
	background: transparent;
	width: 75%;
	color: white;
	padding: 10px;
	font-size: 1em;
	transition: 200ms;
	cursor: text;
`;

const ErrMsgContainer = styled.div`
	position: absolute;
	top: 0;
	left: 13px;
	font-size: 10px;
	font-weight: 400;
	color: #dc3545;
`;

const formSchema = Yup.object().shape({
	email: Yup.string().email('Invalid email format'),
});

function GuestBookProfileTab(props) {
	const [openBirthdateCalendar, setOpenBirthdateCalendar] = useState(false);
	const [openAnnivCalendar, setOpenAnnivCalendar] = useState(false);
	const [isTyping, setIsTyping] = useState({
		phone: false,
		email: false,
		company: false,
		birthday: false,
		anniversary: false,
		address: false,
		cityTown: false,
		state: false,
		postCode: false,
		country: false,
	});
	const [hasError, sethasError] = useState({
		phone: false,
		email: false,
		company: false,
		birthday: false,
		anniversary: false,
		address: false,
		cityTown: false,
		state: false,
		postCode: false,
		country: false,
	});
	const [errorMessage, setErrorMessage] = useState({
		phone: '',
		email: '',
	});

	const handleFocus = (e) => {
		setIsTyping({ ...isTyping, [e.target.name]: true });
	};

	const handleBlur = (e, value, isValid, error) => {
		e.preventDefault();
		let guest = props.selectedGuest;
		setIsTyping({ ...isTyping, [e.target.name]: false });
		if (e.target.name === 'email') {
			if (isValid) {
				sethasError({ ...hasError, email: false });
				setErrorMessage({ ...errorMessage, email: '' });
				guest = { ...guest, email: value };
			} else {
				sethasError({ ...hasError, email: true });
				setErrorMessage({ ...errorMessage, email: error.email });
				return;
			}
		}
		props.setProfileTabIsLoading(true);
		props.guestUpdate(createGuestParameters(guest));
	};

	const changePhoneNum = (e) => {
		let number;
		if (e.target.validity.valid) {
			number = e.target.value;
			let guest = props.selectedGuest;
			guest = { ...guest, phone: number };
			sethasError({ ...hasError, phone: false });
			setErrorMessage({ ...errorMessage, phone: '' });
			props.setSelectedGuest(guest);
		} else {
			sethasError({ ...hasError, phone: true });
			setErrorMessage({ ...errorMessage, phone: 'Invalid phone number' });
			return;
		}
	};

	const toggleCalendar = (type) => {
		if (type === 'birthday') {
			setOpenBirthdateCalendar(true);
		} else if (type === 'anniversary') {
			setOpenAnnivCalendar(true);
		}
	};

	const updateCompany = (e) => {
		let company = e.target.value;
		let guest = props.selectedGuest;
		guest = { ...guest, company: company };
		props.setSelectedGuest(guest);
	};

	const birthdateCallback = (date) => {
		let guest = props.selectedGuest;
		guest = { ...guest, birthday: date };
		props.guestUpdate(createGuestParameters(guest));
		props.setSelectedGuest(guest);
		setOpenBirthdateCalendar(false);
	};

	const annivdateCallback = (date) => {
		let guest = props.selectedGuest;
		guest = { ...guest, anniversary: date };
		props.guestUpdate(createGuestParameters(guest));
		props.setSelectedGuest(guest);
		setOpenAnnivCalendar(false);
	};

	const updateAddress = (e) => {
		let guest = props.selectedGuest;
		guest = { ...guest, address: e.target.value };
		props.setSelectedGuest(guest);
	};

	const updateCity = (e) => {
		let guest = props.selectedGuest;
		guest = { ...guest, city: e.target.value };
		props.setSelectedGuest(guest);
	};

	const updateState = (e) => {
		let guest = props.selectedGuest;
		guest = { ...guest, state: e.target.value };
		props.setSelectedGuest(guest);
	};

	const updatePostal = (e) => {
		let guest = props.selectedGuest;
		guest = { ...guest, postal: e.target.value };
		props.setSelectedGuest(guest);
	};

	const updateCountry = (e) => {
		let guest = props.selectedGuest;
		guest = { ...guest, country: e.target.value };
		props.setSelectedGuest(guest);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
	};

	return (
		<Container isProfileActive={props.selectedTab}>
			{props.profileTabIsLoading ? <DotLoader /> : ''}

			<h4>CONTACT</h4>
			<StlyedSpan>
				{isTyping.phone ? '' : <Label>Phone Number</Label>}
				{hasError.phone ? <ErrMsgContainer>{errorMessage.phone}</ErrMsgContainer> : ''}
				<Input
					id="formikInput"
					style={hasError.phone ? { border: '1px solid #dc3545', borderRadius: '0.8em' } : {}}
					value={props.selectedGuest.phone !== null ? props.selectedGuest.phone : ''}
					type="text"
					pattern="[\+\0-9]{0,18}"
					name="phone"
					onFocus={handleFocus}
					onBlur={handleBlur}
					onChange={changePhoneNum}
				/>
			</StlyedSpan>
			<Formik
				enableReinitialize
				initialValues={{
					email: props.selectedGuest.email !== null ? props.selectedGuest.email : '',
				}}
				validationSchema={formSchema}
				onSubmit={handleSubmit}
			>
				{({ values, errors, isValid }) => {
					return (
						<StyledForm onSubmit={(handleBlur, handleSubmit)}>
							<StlyedSpan>
								{isTyping.email ? '' : <Field as={Label}>Email Address</Field>}
								{hasError.email ? <ErrMsgContainer>{errorMessage.email}</ErrMsgContainer> : ''}
								<Field
									style={
										hasError.email ? { border: '1px solid #dc3545', borderRadius: '0.8em' } : {}
									}
									name="email"
									as={Input}
									onFocus={handleFocus}
									onBlur={(e) => handleBlur(e, values.email, isValid, errors)}
								/>
							</StlyedSpan>
						</StyledForm>
					);
				}}
			</Formik>
			<StlyedSpan>
				{isTyping.company ? '' : <Label as={Label}>Company</Label>}
				<Input
					value={props.selectedGuest.company !== null ? props.selectedGuest.company : ''}
					name="company"
					as={Input}
					onFocus={handleFocus}
					onBlur={handleBlur}
					onChange={updateCompany}
				/>
			</StlyedSpan>
			<h4>SPECIAL DAYS</h4>
			<StlyedSpan>
				{isTyping.birthday ? '' : <Label>Birthday</Label>}
				<Button name="birthday" onClick={(e) => toggleCalendar(e.target.name)}>
					{props.selectedGuest.birthday !== null ? props.selectedGuest.birthday : ''}
				</Button>
				<SelectBookingCalendar
					selectedDate={
						props.selectedGuest.birthday !== null ? props.selectedGuest.birthday : new Date()
					}
					openBookingCalendar={openBirthdateCalendar}
					setSelectedDateHandler={birthdateCallback}
					handleCloseSelectedBookingCalendar={setOpenBirthdateCalendar}
				/>
			</StlyedSpan>
			<StlyedSpan>
				{isTyping.anniversary ? '' : <Label>Anniversary</Label>}
				<Button name="anniversary" onClick={(e) => toggleCalendar(e.target.name)}>
					{props.selectedGuest.anniversary !== null ? props.selectedGuest.anniversary : ''}
				</Button>
				<SelectBookingCalendar
					selectedDate={
						props.selectedGuest.anniversary !== null ? props.selectedGuest.anniversary : new Date()
					}
					openBookingCalendar={openAnnivCalendar}
					setSelectedDateHandler={annivdateCallback}
					handleCloseSelectedBookingCalendar={setOpenAnnivCalendar}
				/>
			</StlyedSpan>
			<h4>ADDRESS</h4>
			<StlyedSpan>
				{isTyping.address ? '' : <Label as={Label}>Address</Label>}
				<Input
					name="address"
					value={props.selectedGuest.address !== null ? props.selectedGuest.address : ''}
					onChange={updateAddress}
					onFocus={handleFocus}
					onBlur={handleBlur}
				/>
			</StlyedSpan>
			<StlyedSpan>
				{isTyping.cityTown ? '' : <Label as={Label}>City / Town</Label>}
				<Input
					name="cityTown"
					value={props.selectedGuest.city !== null ? props.selectedGuest.city : ''}
					onChange={updateCity}
					onFocus={handleFocus}
					onBlur={handleBlur}
				/>
			</StlyedSpan>
			<StlyedSpan>
				{isTyping.state ? '' : <Label as={Label}>State</Label>}
				<Input
					name="state"
					value={props.selectedGuest.state !== null ? props.selectedGuest.state : ''}
					onChange={updateState}
					onFocus={handleFocus}
					onBlur={handleBlur}
				/>
			</StlyedSpan>
			<StlyedSpan>
				{isTyping.postCode ? '' : <Label as={Label}>Postal Code</Label>}
				<Input
					name="postCode"
					value={props.selectedGuest.postal !== null ? props.selectedGuest.postal : ''}
					onChange={updatePostal}
					onFocus={handleFocus}
					onBlur={handleBlur}
				/>
			</StlyedSpan>
			<StlyedSpan>
				{isTyping.country ? '' : <Label as={Label}>Country</Label>}
				<Input
					name="country"
					value={props.selectedGuest.country !== null ? props.selectedGuest.country : ''}
					onChange={updateCountry}
					onFocus={handleFocus}
					onBlur={handleBlur}
				/>
			</StlyedSpan>
		</Container>
	);
}

export default GuestBookProfileTab;
