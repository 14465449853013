import React, { useState, useLayoutEffect } from 'react';
import { Container, Tabs, Tab, Box, Grid, Link } from '@material-ui/core';
import Signin from '../../sections/auth/sign-in';
import Signup from '../../sections/auth/sign-up';
import { MainWrapper, ContainerWrapper } from '../../styles/auth';
// assets
import WabiLogo from 'assets/images/logo.png';

// React Icons
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';

function Home() {
	const [activeTab, setActiveTab] = useState(0);

	const handleTabChange = (event, newValue) => {
		setActiveTab(newValue);
	};

	useLayoutEffect(() => {
		MainWrapper();
	}, []);

	const handleBackClick = () => {
		window.location.href = process.env.REACT_APP_WEB_STATIC;
	};

	return (
		<Grid
			style={{ minHeight: '100vh' }}
			container
			direction="row"
			justifyContent="center"
			alignItems="center"
		>
			<Container style={ContainerWrapper} className="responsive-container">
				<Box>
					<Box
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							marginBottom: 40,
							marginTop: 20,
						}}
					>
						<img
							src={WabiLogo}
							alt="Company Logo"
							style={{ width: 200, margin: 0, cursor: 'pointer' }}
							onClick={handleBackClick}
						/>
					</Box>
					<Tabs value={activeTab} onChange={handleTabChange} centered>
						<Tab style={{ padding: '10px', color: '#000000' }} label="LOGIN" />
						<Tab style={{ padding: '10px', color: '#000000' }} label="REGISTER" />
					</Tabs>
					{activeTab === 0 ? <Signin /> : <Signup />}

					<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						<HiOutlineArrowNarrowLeft style={{ fontSize: 14, marginRight: 10 }} />
						<Link component="button" style={{ color: 'gray' }} onClick={handleBackClick}>
							Back to Home
						</Link>
					</Box>
				</Box>
			</Container>
		</Grid>
	);
}

export default Home;
