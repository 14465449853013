import React, { useState } from 'react';
import { TableRow, TableCell, Paper } from '@material-ui/core';
import { array } from 'yup';
import { SHIFTTYPE } from 'constants/enum';
import styled from 'styled-components';
import moment from 'moment';

const StyledTableCell = styled(TableCell)`
	&& {
		height: 70px;
		margin: 0;
		padding: 1px;
		border-right: 0.1px solid #e3e3e3;
		cursor: pointer;
	}
`;

const ShiftHeader = styled.div`
	width: 100%;
	height: 10px;
	font-size: 14px;
	font-weight: 750;
	margin: 20px 10px 10px 10px;
`;

const ULcontainer = styled.div`
	position: relative;
	height: 90%;
	min-width: 120px;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	padding: 0 3px;
	margin: 10px 0;
`;

const StyledUL = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 2em;
	color: #413636;
	font-size: 10px;
	font-weight: 750;
	padding: 5px 10px;

	span {
		white-space: nowrap;
	}
`;

function DayViewRow(props) {
	const renderChecker = (emplShifts, date, shift) => {
		let start = shift.start_time;
		let end = shift.end_time;

		if (array.length > 0) {
			const shiftItem = emplShifts.map((e) => {
				const empl = props.employees.find((empl) => empl.id === e.employee_id);
				const floor = props.floors.find((flr) => flr.id === e.floor_id);
				if (e.type === SHIFTTYPE.shift && e.date === date) {
					const startTime = moment(e.date).seconds(e.start_time).format('hh:mm A');
					const endTime = moment(e.date)
						.seconds(e.end_time > end ? end : e.end_time)
						.format('hh:mm A');

					if (e.end_time > start && e.end_time < end) {
						// const startTime = e.start_time < start ? start : e.start_time;
						// const endTime = e.end_time < end ? end : e.end_time;
						return (
							<StyledUL style={{ background: empl.color }} key={e.id === 0 ? e.uuid : e.id}>
								<span>
									{empl.first_name} - {floor.floor_name}
								</span>
								<span>
									{startTime} - {endTime}
								</span>
							</StyledUL>
						);
					} else {
						return;
					}
				} else {
					return;
				}
			});
			return shiftItem;
		}
	};
	return (
		<TableRow style={{ minHeight: 88 }}>
			<StyledTableCell key={1}>
				<ShiftHeader>{props.shift.name}</ShiftHeader>
				<ULcontainer>{renderChecker(props.employeeShifts, props.date, props.shift)}</ULcontainer>
			</StyledTableCell>
			<StyledTableCell key={2}>
				<ShiftHeader style={{ color: 'transparent' }}>{props.shift.name}</ShiftHeader>
				<ULcontainer>
					{renderChecker(
						props.employeeShifts,
						moment(props.date).add(1, 'days').format('YYYY-MM-DD'),
						props.shift,
					)}
				</ULcontainer>
			</StyledTableCell>
			<StyledTableCell key={3}>
				<ShiftHeader style={{ color: 'transparent' }}>{props.shift.name}</ShiftHeader>
				<ULcontainer>
					{renderChecker(
						props.employeeShifts,
						moment(props.date).add(2, 'days').format('YYYY-MM-DD'),
						props.shift,
					)}
				</ULcontainer>
			</StyledTableCell>
			<StyledTableCell key={4}>
				<ShiftHeader style={{ color: 'transparent' }}>{props.shift.name}</ShiftHeader>
				<ULcontainer>
					{renderChecker(
						props.employeeShifts,
						moment(props.date).add(3, 'days').format('YYYY-MM-DD'),
						props.shift,
					)}
				</ULcontainer>
			</StyledTableCell>
			<StyledTableCell key={5}>
				<ShiftHeader style={{ color: 'transparent' }}>{props.shift.name}</ShiftHeader>
				<ULcontainer>
					{renderChecker(
						props.employeeShifts,
						moment(props.date).add(4, 'days').format('YYYY-MM-DD'),
						props.shift,
					)}
				</ULcontainer>
			</StyledTableCell>
			<StyledTableCell key={6}>
				<ShiftHeader style={{ color: 'transparent' }}>{props.shift.name}</ShiftHeader>
				<ULcontainer>
					{renderChecker(
						props.employeeShifts,
						moment(props.date).add(5, 'days').format('YYYY-MM-DD'),
						props.shift,
					)}
				</ULcontainer>
			</StyledTableCell>
			<StyledTableCell key={7}>
				<ShiftHeader style={{ color: 'transparent' }}>{props.shift.name}</ShiftHeader>
				<ULcontainer>
					{renderChecker(
						props.employeeShifts,
						moment(props.date).add(6, 'days').format('YYYY-MM-DD'),
						props.shift,
					)}
				</ULcontainer>
			</StyledTableCell>
		</TableRow>
	);
}

export default DayViewRow;
