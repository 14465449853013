import React, { useState } from 'react';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import styled from 'styled-components';
import moment from 'moment';

//Icon
import specialDayIcon from 'assets/images/booking/table-layout-planning/special-days-logo.png';
import BookingCalendar from 'pages/Dashboard/Booking/CreatBooking/booking-calendar/BookingCalendar';
import CalendarMonth from 'pages/Dashboard/Booking/CreatBooking/calendar-month-navigation/CalendarMonth';
import xIcon from 'assets/images/booking/exit-btn-table-details.png';
import checkIcon from 'assets/images/booking/create-booking/check-icon.png';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		width: '100%',
		height: '100%',
		backdropFilter: 'blur(10px)',
		outline: 'none',
	},
}));

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	user-select: none;

	img {
		// user-select: none;
		-webkit-user-drag: none;
		-khtml-user-drag: none;
		-moz-user-drag: none;
		-o-user-drag: none;
		user-drag: none;
	}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 420px;
	height: 630px;
	// background: rgba(0, 0, 0, 0.6);
	position: absolute;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 10px;
	border-color: none;
	color: white;

	h4 {
		font-size: 22px;
		font-weight: 700;
		margin-bottom: 28px;
	}

	span {
		height: 15px;
	}
`;

const InputContainer = styled.div`
	display: flex;
	flex-direction: row;
	width: 420px;
	height: 60px;
	background: rgba(0, 0, 0, 0.6);
	border-radius: 10px;
	padding: 15px;

	input {
		width: 100%;
		background: transparent;
		margin-left: 10px;
		color: white;
	}
`;

const ButtonWrapper = styled.div`
	margin-top: 10px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 50px;
	width: 100%;
`;

const CancelButton = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
	width: 50px;
	background: #cccccc;
	border-radius: 7px;
	margin: 0 5px;

	&:hover {
		background-color: #dbdada;
	}

	&:active {
		background-color: #dbdada;
		outline: none;
		outline-offset: none;
		img {
			filter: contrast(9.5);
		}
	}
`;

const ConfirmButton = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
	width: 50px;
	background: transparent linear-gradient(#ff8585 10%, #ff4b75);
	border-radius: 7px;
	margin: 0 5px;

	&:hover {
		background: transparent linear-gradient(#ff8585 10%, #ef6f8d);
	}

	&:active {
		background-color: #cccccc;
		outline: none;
		outline-offset: none;

		img {
			filter: contrast(0.5);
		}
	}
`;

function SetSpecialDateModal(props) {
	const today = moment(new Date()).format('yyyy-MM-DD');
	const [month, setMonth] = useState(Date());
	const [bookingDate, setBookingDate] = useState(today);
	const [name, setName] = useState('');
	const [hasError, setHasError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const classes = useStyles();

	const handleClose = () => {
		props.setModalOpen(false);
	};

	const onChange = (value) => {
		setName(value);
		setHasError(false);
	};

	const handleSave = () => {
		let newSpeciaDay = {
			deleted: false,
			is_recurring: true,
			type: 1,
			name: name,
			end_date: moment(bookingDate).format('yyyy-MM-DD'),
			id: 0,
			shifts: [],
			start_date: moment(bookingDate).format('yyyy-MM-DD'),
			status: 1,
		};
		if (!name) {
			setHasError(true);
			setErrorMessage('Special Day Name is required');
		} else {
			props.newSpeciaDayCallback(newSpeciaDay);
			handleClose();
			setName('');
			setBookingDate(today);
		}
	};

	const selectedDate = (date) => {
		let nDate = moment(date).format('YYYY-MM-DD');
		setBookingDate(nDate);
	};

	const selectMonth = (selectedMonth) => {
		setMonth(selectedMonth);
	};

	return (
		<div>
			<Modal
				className={classes.modal}
				open={props.isOpen}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={props.isOpen}>
					<div className={classes.paper}>
						{hasError ? (
							<Alert
								style={{
									position: 'absolute',
									width: '100%',
								}}
								severity="error"
							>
								<AlertTitle>Error</AlertTitle>
								{errorMessage}
							</Alert>
						) : (
							''
						)}

						<Wrapper>
							<Content>
								<h4>SET A SPECIAL DATE</h4>
								<InputContainer>
									<img src={specialDayIcon} width={24} height={24} />
									<input
										value={name}
										onChange={(e) => onChange(e.target.value)}
										placeholder="Name special day"
									/>
								</InputContainer>
								<span />
								<CalendarMonth month={month} selectMonth={selectMonth} />
								<span />
								<BookingCalendar
									bookingDate={bookingDate}
									month={month}
									selectedDate={selectedDate}
									// disabledDays={{ before: today }}
								/>
								<ButtonWrapper>
									<CancelButton onClick={handleClose}>
										<img src={xIcon} width={12} height={12} />
									</CancelButton>
									<ConfirmButton onClick={handleSave}>
										<img src={checkIcon} width={11.6} height={8.2} />
									</ConfirmButton>
								</ButtonWrapper>
							</Content>
						</Wrapper>
					</div>
				</Fade>
			</Modal>
		</div>
	);
}

export default SetSpecialDateModal;
