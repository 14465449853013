import React from 'react';

export const sideNavData = [
	{ id: 0, title: 'Team' },
	{ id: 1, title: 'Roster' },
	// { id: 2, title: 'Timesheet' },
	// { id: 3, title: 'Budget and Targets' },
	// { id: 4, title: 'Employee Insights' },
	// { id: 5, title: 'Staff Access' },
	// { id: 6, title: 'Cost & Forcast' },
	// { id: 7, title: 'Staff History' },
	// { id: 8, title: 'Settings' },
];
