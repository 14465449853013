import React, { Component } from 'react';
import floorPlanIcon from '../../../../assets/images/booking/table-layout-planning/floor-plan-logo.png';
import tableCombinationIcon from '../../../../assets/images/booking/table-layout-planning/table-combination-logo.png';
import settingsIcon from '../../../../assets/images/booking/table-layout-planning/settings-logo.png';
import scheduleIcon from '../../../../assets/images/booking/table-layout-planning/schedule-logo.png';
import specialDaysIcon from '../../../../assets/images/booking/table-layout-planning/special-days-logo.png';
import experienceIcon from '../../../../assets/images/booking/create-booking/booking-option-experience-icon.png';

export const TableLayoutPlanningData = [
	{
		title: 'FLOOR PLAN',
		icon: <img src={`${floorPlanIcon}`} width={22} height={22} />,
	},
	{
		title: 'TABLE COMBINATION',
		icon: <img src={`${tableCombinationIcon}`} width={22} height={22} />,
	},
	{
		title: 'SETTINGS',
		icon: <img src={`${settingsIcon}`} width={22} height={22} />,
	},
	{
		title: 'SCHEDULE',
		icon: <img src={`${scheduleIcon}`} width={22} height={22} />,
	},
	{
		title: 'SPECIAL DAYS',
		icon: <img src={`${specialDaysIcon}`} width={22} height={22} />,
	},
	{
		title: 'EXPERIENCE',
		icon: <img src={`${experienceIcon}`} width={22} height={22} />,
	},
];
