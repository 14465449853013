// React and Material-UI Imports
import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';

//Material UI
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// React Icons
import { HiOutlineLockClosed, HiOutlineMail, HiOutlineUser } from 'react-icons/hi';

// Formik and Yup Imports
import { Formik, Field, ErrorMessage, Form } from 'formik';
import * as Yup from 'yup';

// Axios and Utility Functions
import axios from 'axios';
import { apiUrl } from 'utils/Utility';

const useStyles = makeStyles((theme) => ({
	focusedInput: {
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: 'pink',
		},
	},
}));

const signupSchema = Yup.object().shape({
	username: Yup.string().required('Username is required'),
	email: Yup.string().email('Invalid email address').required('Email address is required'),
	password: Yup.string().required('Password is required'),
	passwordConfirmation: Yup.string()
		.oneOf([Yup.ref('password'), null], 'Passwords must match')
		.required('Confirm Password is required'),
});

const Signup = () => {
	const classes = useStyles();
	const [openDialog, setOpenDialog] = useState(false);

	const handleCloseDialog = (event, reason) => {
		if (reason !== 'backdropClick') {
			setOpenDialog(false);
		}
	};

	const onSubmit = async (values, { setFieldError, setSubmitting, resetForm }) => {
		try {
			const response = await axios({
				url: `${apiUrl}/api/register`,
				method: 'POST',
				data: {
					username: values.username,
					email: values.email,
					password: values.password,
				},
			});

			if (response.status === 200) {
				setOpenDialog(true);
				resetForm();
			}
		} catch (err) {
			const { status, data } = err.response;
			const errorStatusCodes = [401, 404, 422];
			setSubmitting(false);

			if (errorStatusCodes.includes(status)) {
				setFieldError('passwordConfirmation', data.message);
			}
		}
	};

	return (
		<Container>
			<Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
				<Formik
					initialValues={{ username: '', email: '', password: '', passwordConfirmation: '' }}
					validationSchema={signupSchema}
					onSubmit={onSubmit}
				>
					{({ isSubmitting }) => (
						<Form>
							<Typography variant="h5" style={{ color: '#000000', marginTop: 20 }}>
								Create Account
							</Typography>
							<Typography
								style={{
									color: 'gray',
									wordWrap: 'break-word',
									fontSize: 12,
									marginTop: 20,
									marginBottom: 20,
								}}
							>
								Follow the instructions to make it easier to register and you will be able to
								explore inside.
							</Typography>
							<Field
								type="text"
								name="username"
								id="username"
								as={TextField}
								fullWidth
								required
								margin="normal"
								label="Username"
								autoFocus
								variant="outlined"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<HiOutlineUser />
										</InputAdornment>
									),
								}}
								InputLabelProps={{
									classes: {
										focused: classes.focusedInput,
									},
								}}
							/>
							<ErrorMessage name="username" component="div" style={{ color: 'red' }} />
							<Field
								type="text"
								name="email"
								id="email"
								as={TextField}
								fullWidth
								required
								autoComplete="email"
								margin="normal"
								label="Email Address"
								variant="outlined"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<HiOutlineMail />
										</InputAdornment>
									),
								}}
								InputLabelProps={{
									classes: {
										focused: classes.focusedInput,
									},
								}}
							/>
							<ErrorMessage name="email" component="div" style={{ color: 'red' }} />
							<Field
								type="password"
								name="password"
								id="password"
								autoComplete="current-password"
								as={TextField}
								fullWidth
								required
								margin="normal"
								label="Password"
								variant="outlined"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<HiOutlineLockClosed />
										</InputAdornment>
									),
								}}
								InputLabelProps={{
									classes: {
										focused: classes.focusedInput,
									},
								}}
							/>
							<ErrorMessage name="password" component="div" style={{ color: 'red' }} />
							<Field
								type="password"
								name="passwordConfirmation"
								id="passwordConfirmation"
								autoComplete="current-password"
								as={TextField}
								fullWidth
								required
								margin="normal"
								label="Confirm Password"
								variant="outlined"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<HiOutlineLockClosed />
										</InputAdornment>
									),
								}}
								InputLabelProps={{
									classes: {
										focused: classes.focusedInput,
									},
								}}
							/>
							<ErrorMessage name="passwordConfirmation" component="div" style={{ color: 'red' }} />
							<Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								disabled={isSubmitting}
								style={{ marginTop: 20, marginBottom: 20, padding: 20 }}
							>
								{isSubmitting ? (
									<Spinner
										as="span"
										animation="border"
										size="sm"
										role="status"
										aria-hidden="true"
									/>
								) : (
									'Create Account'
								)}
							</Button>
						</Form>
					)}
				</Formik>
			</Box>
			<Dialog open={openDialog} onClose={handleCloseDialog}>
				<DialogTitle>Registration Successful</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Verification sent to your provided email address. Please verify your email before you
						can log in to your account.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseDialog} variant="contained" color="primary">
						OK
					</Button>
				</DialogActions>
			</Dialog>
		</Container>
	);
};

export default Signup;
