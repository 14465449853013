import React, { useState } from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

function BookingCalendar(props) {
	const handleDayClick = (day, modifiers = {}) => {
		if (modifiers.disabled) {
			return;
		}
		// setSelectedDate(modifiers.selected ? undefined : day)
		props.selectedDate(day);
	};

	return (
		<DayPicker
			month={new Date(props.month)}
			selectedDays={[new Date(props.bookingDate)]}
			onDayClick={handleDayClick}
			showOutsideDays={true}
			className="booking-calendar"
			disabledDays={props.disabledDays}
		/>
	);
}

export default BookingCalendar;
