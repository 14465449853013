import React, { useState, useEffect, useRef } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import axios from 'axios';
import styled from 'styled-components';

//utilities
import {
	getRadHeight,
	getRadWidth,
	tableNamePositionLeft,
	tableNamePositionLeftTranslate,
	tableNamePositionMarginLeft,
	tableNamePositionMarginTop,
	getPositionTop,
	getPositionLeft,
	radToDeg,
	getTableImg,
	getTableWidth,
	getTableHeight,
	apiUrl,
} from 'utils/Utility';

//components
import TableCombinationFloorPicker from './SubComponents/TableCombinationFloorPicker';
import CombinationDropDown from './SubComponents/CombinationDropDown';
import DotLoader from './SubComponents/DotLoader';

//Icons
import exitIcon from 'assets/images/booking/exit-btn-table-details.png';
import trashIcon from 'assets/images/booking/table-layout-planning/delete-icon.png';
import checkIcon from 'assets/images/booking/create-booking/check-icon.png';

const Wrapper = styled.div`
	display: inline-flex;
	height: fit-content;
	width: 100%;
	padding: 10px;
`;

const StyledContainer = styled(Container)`
	// display: inline-flex;
	// height: 100%;
	// width: 100%;
	// justify-content: center;
	// overflow: hidden;
`;

const SaveButtonContainer = styled.div`
	position: absolute;
	top: 0px;
	right: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #222a2f;
	box-shadow: 1px 1px 10px #1a2024, 1px 1px 10px #2a343a;
	border-radius: 50px;
	height: 50px;
	width: 50px;
	margin: 30px 0;
	cursor: pointer;
	user-select: none;

	&:hover {
		background: #1f262b;
		color: #ffffff;
		outline: none;
		outline-offset: none;
	}

	&:active {
		background: #222a2f;
		color: #ffffff;
		outline: none;
		outline-offset: none;
	}

	img {
		user-select: none;
		-webkit-user-drag: none;
		-khtml-user-drag: none;
		-moz-user-drag: none;
		-o-user-drag: none;
		user-drag: none;
	}
`;

const GridContainer = styled.div`
	position: relative;
	display: flex;
	height: 612px;
	min-width: 690px;
	width: auto;
	margin-bottom: 5px;
	overflow: auto;
	// background-size: 16.2px 17px;
	// background-image: linear-gradient(to right, #343643 1px, transparent 1px),
	// 	linear-gradient(to bottom, #343643 1px, transparent 1px);
	border: 1px solid #343643;
	border-radius: 10px;
	::-webkit-scrollbar {
		width: 5px;
		height: 5px;
		background-color: #222a2f;
	}
	::-webkit-scrollbar-thumb {
		width: 5px;
		height: 5px;
		border-radius: 20px;
		background-color: #757a7d33;
	}
	::-webkit-scrollbar-corner {
		display: none;
	}
`;

const TableGrid = styled.div`
	position: absolute;
	width: 139%;
	height: 130%;
	background-size: 16.2px 17px;
	background-image: linear-gradient(to right, #343643 1px, transparent 1px),
		linear-gradient(to bottom, #343643 1px, transparent 1px);

	#card-item {
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Old versions of Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none; /* Non-prefixed version, currently
										supported by Chrome, Edge, Opera and Firefox */
		cursor: pointer;
	}
`;

const MenuContainer = styled.div`
	position: relative;
	display: inline-flex;
	flex-direction: column;
	height: 665px;
	width: 337px;
	margin-left: 10px;
	user-select: none;

	h4 {
		font-size: 16px;
		font-weight: 700;
		color: #bcbcbc;
	}

	p {
		font-size: 12px;
		color: #bcbcbc;
		margin: 5px 0;
	}

	#combinationWrapper {
		padding: 0 5px 10px 5px;
		position: relative;
		overflow-y: auto;
		::-webkit-scrollbar {
			width: 5px;
			height: 5px;
			background-color: #222a2f;
		}
		::-webkit-scrollbar-thumb {
			width: 1px;
			height: 1px;
			border-radius: 20px;
			background-color: #2a343a;
		}
		::-webkit-scrollbar-corner {
			display: none;
		}
	}
`;

const LineSeparator = styled.div`
	// position: absolute;
	width: 100%;
	height: 3px;
	border-radius: 25px;
	background: #222a2f;
	box-shadow: inset 1px 1px 2px #1a2024, inset -1px -1px 2px #2a343a;
`;

const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 130px;
	width: 322px;
	background-color: #3a444b;
	border-radius: 5px;
	padding: 10px;
	margin-bottom: 10px;
	overflow: auto;

	::-webkit-scrollbar {
		width: 5px;
		height: 5px;
		background-color: #222a2f;
	}
	::-webkit-scrollbar-thumb {
		width: 1px;
		height: 1px;
		border-radius: 20px;
		background-color: #2a343a;
	}
	::-webkit-scrollbar-corner {
		display: none;
	}

	span {
		display: flex;
		flex-direction: column;
	}
	h4 {
		font-size: 16px;
		font-weight: 700;
		color: #ffffff;
		margin-bottom: 8px;
	}
	input {
		width: 105px;
		height: 36px;
		border-radius: 10px;
		margin-right: 5px;
		padding: 5px;
	}
	label {
		font-size: 15px;
		font-weight: 700;
		color: #ffffff;
		margin: 0 0 0 15px;
	}
	#tc-row {
		position: relative;
		display: flex;
		flex-direction: row;
		padding: 0 0 10px 0;
		margin-top: 15px;
	}

	#confirmIcon-container {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		right: 42px;
		bottom: 10px;
		height: 36px;
		width: 36px;
		background: transparent linear-gradient(#f6d5a0c7 -55%, #fb2c7f);
		border-radius: 7px;

		&:hover {
			background: transparent linear-gradient(#ff8585 10%, #ef6f8d);
		}

		&:active {
			background-color: #cccccc;
			outline: none;
			outline-offset: none;

			img {
				filter: contrast(0.5);
			}
		}
		img {
			// user-select: none;
			-webkit-user-drag: none;
			-khtml-user-drag: none;
			-moz-user-drag: none;
			-o-user-drag: none;
			user-drag: none;
		}
	}

	#closeButton-container {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		right: 0;
		bottom: 10px;
		height: 36px;
		width: 36px;
		background-color: #cccccc;
		border-radius: 7px;

		&:hover {
			background-color: #dbdada;
		}

		&:active {
			background-color: #dbdada;
			outline: none;
			outline-offset: none;
			img {
				filter: contrast(9.5);
			}
		}

		img {
			// user-select: none;
			-webkit-user-drag: none;
			-khtml-user-drag: none;
			-moz-user-drag: none;
			-o-user-drag: none;
			user-drag: none;
		}
	}
`;

const TableImg = styled.img`
	${(props) => (props.selected ? 'filter: hue-rotate(181deg) brightness(1) invert(1)' : '')};
`;

const DeleteIcon = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	border-radius: 10px;
	cursor: pointer;
	margin-top: 5px;
	background-color: #222a2f;
	box-shadow: 5px 5px 10px #1a2024, -6px -1px 10px #2a343a;
	&:hover {
		background-color: #1f262b;
	}
	&:active {
		background-color: #222a2f;
		outline: none;
		outline-offset: none;
	}
	img {
		user-select: none;
		-webkit-user-drag: none;
		-khtml-user-drag: none;
		-moz-user-drag: none;
		-o-user-drag: none;
		user-drag: none;
	}
`;

const Footer = styled.div`
	display: flex;
	flex-direction: row;
	height: 45px;
	position: relative;
`;

function TableCombination(props) {
	const reducers = useSelector((state) => state.userReducers);
	const [floors, setFloors] = useState(props.floors);
	const [tableList, setTableList] = useState(floors[0].tables);
	const [selectedFloor, setSelectedFloor] = useState(floors[0]);
	const [capacity, setCapacity] = useState({ min: '', max: '' });
	const [tablesName, setTablesName] = useState([]);
	const [ids, setIds] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const floorState = useRef();
	floorState.current = selectedFloor;

	const selectFloor = (floor) => {
		setSelectedFloor(floor);
		setTableList(floor.tables);
		setIds([]);
		setTablesName([]);
		setCapacity({ min: '', max: '' });
	};

	const selectTable = (table) => {
		let tIds = ids;
		let names = tablesName;
		if (tIds.includes(table.id)) {
			let idx = tIds.findIndex((e) => e === table.id);
			let nameIdx = names.findIndex((e) => e === table.id);
			tIds.splice(idx, 1);
			tIds.sort();
			names.splice(nameIdx, 1);
			names.sort();
			setIds(tIds);
			setTablesName(names);
			setCapacity({ min: '', max: '' });
			// console.log(id, names);
		} else {
			tIds.push(table.id);
			tIds.sort();
			names.push(table.name);
			names.sort();
			setIds(tIds);
			setTablesName(names);
			setCapacity({ min: '', max: '' });
			// console.log(id, names);
		}
	};

	const formatStringName = (value) => {
		let name = value.toString();
		let result = name.replaceAll(',', ' / ');
		return result;
	};

	const handleChangeMin = (e) => {
		let number = e.target.validity.valid ? e.target.value : capacity.min;
		setCapacity({
			min: number,
			max: capacity.max,
		});
	};

	const handleChangeMax = (e) => {
		let number = e.target.validity.valid ? e.target.value : capacity.max;
		setCapacity({
			min: capacity.min,
			max: number,
		});
	};

	const combinedTablesCallback = (id, combinedIds, newCapacity) => {
		let params = {
			id: id,
			table_ids: combinedIds,
			capacity_min: newCapacity.min,
			capacity_max: newCapacity.max,
		};
		console.log(params);
		handleSubmit(params);
	};

	const handleAddCombinedTables = () => {
		if (ids.length === 0) {
			return;
		}

		if (capacity.min.length === 0) {
			return;
		}

		if (capacity.max.length === 0) {
			return;
		}

		let params = {
			table_ids: ids,
			capacity_min: capacity.min,
			capacity_max: capacity.max,
		};

		setIds([]);
		setTablesName([]);
		setCapacity({ min: '', max: '' });
		handleSubmit(params);
	};

	const handleCancel = () => {
		setIds([]);
		setTablesName([]);
		setCapacity({ min: '', max: '' });
	};

	const removeCombinedTablesOnFloor = () => {
		let tables = selectedFloor.combined_tables;
		let length = tables.length;
		// let idx = selectedFloor.combined_tables.findIndex()
		for (let idx = 0; idx <= length - 1; idx++) {
			let combined = tables[idx];
			handleUnmerge(combined);
		}
	};

	const handleSubmit = async (params) => {
		setIsLoading(true);
		try {
			const response = await axios({
				url: `${apiUrl}/api/merge_tables`,
				method: 'POST',
				data: params,
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${reducers.token}`,
				},
			});

			if (response) {
				const { status } = response;
				const { data } = response.data;
				if (status === 200) {
					console.log(data);
					props.getFloor();
				}
			}
		} catch (err) {
			console.log('Error: ', err);
		}
	};

	const handleUnmerge = async (combined) => {
		setIsLoading(true);
		let params = { id: combined.id };
		try {
			const response = await axios({
				url: `${apiUrl}/api/unmerge_tables`,
				method: 'POST',
				data: params,
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${reducers.token}`,
				},
			});

			if (response) {
				const { status } = response;
				const { data } = response.data;
				if (status === 200) {
					console.log(data);
					props.getFloor();
				}
			}
		} catch (err) {
			console.log('Error: ', err);
		}
		console.log('yes');
	};

	useEffect(() => {
		if (isLoading) {
			if (props.floors !== undefined) {
				if (props.floors.length > 0) {
					let floor = floorState.current;
					let selected = props.floors.find((e) => e.id === floor.id);
					setFloors(props.floors);
					setSelectedFloor(selected);
					setIsLoading(false);
				}
			}
		}
	}, [props.floors]);
	// console.log(selectedFloor);

	return (
		// <Wrapper>
		<StyledContainer fluid>
			<Row>
				<Col sm={8} md={8} lg={8} style={{ marginLeft: 40, padding: 0 }}>
					<GridContainer>
						<TableGrid>
							{tableList.length > 0
								? tableList.map((table) => (
										<div
											onClick={(e) => selectTable(table)}
											key={table.id}
											id="card-item"
											style={{
												position: 'absolute',
												top: table.pos_y,
												left: table.pos_x,
												width: getRadWidth(table.rotate_deg, table.table_type),
												height: getRadHeight(table.rotate_deg, table.table_type),
												color: 'white',
												fontSize: 10,
												cursor: 'pointer',
												zIndex: 1,
											}}
										>
											<div
												// onClick={(e) => selectedTablehandler(table)}
												key={Math.random()}
												style={{
													position: 'relative',
													width: getRadWidth(table.rotate_deg, table.table_type),
													height: getRadHeight(table.rotate_deg, table.table_type),
													zIdzIndex: 1,
												}}
											>
												<span
													key={Math.random()}
													style={{
														position: 'absolute',
														zIndex: 1,
														left: tableNamePositionLeft(table.table_type),
														transform: `translateX(${tableNamePositionLeftTranslate(
															table.table_type,
														)})`,
														marginLeft: tableNamePositionMarginLeft(
															table.table_type,
															table.rotate_deg,
														),
														marginTop: tableNamePositionMarginTop(
															table.table_type,
															table.rotate_deg,
														),
													}}
												>
													{table.name}
												</span>

												<div
													key={Math.random()}
													style={{
														width: getTableWidth(table.table_type),
														height: getTableHeight(table.table_type),
														transformOrigin: 'top left',
														position: 'absolute',
														top: getPositionTop(table.rotate_deg, table.table_type),
														left: getPositionLeft(table.rotate_deg, table.table_type),
														transform: `rotate(${radToDeg(table.rotate_deg)}deg)`,
														zIndex: 0,
													}}
												>
													<TableImg
														draggable="false"
														key={Math.random()}
														src={getTableImg(table.table_type)}
														width={`${getTableWidth(table.table_type)}`}
														height={`${getTableHeight(table.table_type)}`}
														selected={ids.includes(table.id)}
														alt="checker"
													/>
												</div>
											</div>
										</div>
								  ))
								: ''}
						</TableGrid>
					</GridContainer>

					<Footer>
						<div
							style={{
								position: 'absolute',
								right: 270,
							}}
						>
							<DeleteIcon onClick={removeCombinedTablesOnFloor}>
								<img src={trashIcon} width={20} height={22} key={Math.random()} />
							</DeleteIcon>
						</div>
						<TableCombinationFloorPicker
							floors={floors}
							selectedFloor={selectedFloor}
							selectFloorCallBack={selectFloor}
						/>
						{isLoading ? (
							<div
								style={{
									display: 'flex',
									position: 'absolute',
									top: '0',
									height: '100%',
									width: '100%',
									right: 340,
									background: 'black',
								}}
							>
								<DotLoader />
							</div>
						) : (
							''
						)}
					</Footer>
				</Col>
				<Col sm={3} md={3} lg={3} style={{ margin: 0, padding: 0 }}>
					<MenuContainer id="Menu-container">
						<div
							style={{
								position: '-webkit-sticky',
								position: 'sticky',
								top: 0,
								width: 330,
								zIndex: 1,
								padding: '5px 5px 0 5px ',
								background: '#222a2f',
							}}
						>
							<h4>Table Combination</h4>
							<p>
								Choose more than 1 table and set the minimum and &nbsp; maximum party size for a
								table combination.
							</p>
							<div>
								<LineSeparator style={{ margin: '5px 0 ' }} />
							</div>

							<InputContainer>
								{/* <div style={{ height: 40, overflowY: 'auto' }}> */}
								{tablesName.length > 0 ? (
									<div id="h4Container">
										<h4>{formatStringName(tablesName)}</h4>
									</div>
								) : (
									<h4>Choose your tables</h4>
								)}
								{/* </div> */}

								<div
									id="tc-row"
									style={{
										position: '-webkit-sticky',
										position: 'sticky',
										bottom: 0,
										background: '#3a444b',
									}}
								>
									<span>
										<label>min</label>
										<input
											type="text"
											pattern="[0-9]{1,2}"
											value={capacity.min}
											onChange={handleChangeMin}
										/>
									</span>
									<span>
										<label>max</label>

										<input
											type="text"
											pattern="[0-9]{1,2}"
											value={capacity.max}
											onChange={handleChangeMax}
										/>
									</span>
									{ids.length > 0 ? (
										<div id="confirmIcon-container" onClick={handleAddCombinedTables}>
											<img src={checkIcon} width={12} height={12} />
										</div>
									) : (
										''
									)}

									<div id="closeButton-container" onClick={handleCancel}>
										<img src={exitIcon} width={12} height={12} />
									</div>
								</div>
							</InputContainer>
						</div>

						<div id="combinationWrapper">
							{floors.map((floor, key) => {
								return (
									<CombinationDropDown
										key={key}
										name={floor.floor_name}
										combined={floor.combined_tables}
										selectedIdCallback={handleUnmerge}
										combinedTablesCallback={combinedTablesCallback}
									/>
								);
							})}
						</div>
					</MenuContainer>
				</Col>
			</Row>
		</StyledContainer>
		// </Wrapper>
	);
}

export default TableCombination;
