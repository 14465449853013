import React, { useEffect, useState } from 'react';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		width: 'inherit',
		height: 'inherit',
		backdropFilter: 'blur(10px)',
		outline: 'none',
	},
}));

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	display: inline-flex;
	justify-content: center;
	z-index: 5;
	// background-color: black;
`;

const Content = styled.div`
	display: inline-flex;
	width: auto;
	height: auto;
	// background: rgba(0, 0, 0, 0.6);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 10px;
	border-color: none;
	padding: 20px;
	color: white;

	#loading {
		width: 24px;
		height: 24px;
		margin: 0 5px;
		background-color: #fe7d86;
		border-radius: 50%;
		display: inline-block;
		animation-name: dots;
		animation-duration: 700ms;
		animation-iteration-count: infinite;
		animation-timing-function: ease;
	}

	#loading:nth-child(2) {
		background-color: #fe7d86;
		animation-delay: 750ms;
	}
	#loading:nth-child(3) {
		background-color: #fe7d86;
		animation-delay: 800ms;
	}
	@keyframes dots {
		50% {
			opacity: 1;
			transform: scale(0.1) translateY(10px);
		}
	}
`;

function DotLoader(props) {
	const classes = useStyles();

	return (
		// <Modal
		// 	className={classes.modal}
		// 	open={true}
		// 	closeAfterTransition
		// 	BackdropComponent={Backdrop}
		// 	BackdropProps={{
		// 		timeout: 500,
		// 	}}
		// >
		// 	<Fade in={true}>
		// <div className={classes.paper}>
		<div>
			<Wrapper>
				<Content>
					<span id="loading"></span>
					<span id="loading"></span>
					<span id="loading"></span>
				</Content>
			</Wrapper>
		</div>
		// 	</Fade>
		// </Modal>
	);
}
export default DotLoader;
