import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import xIcon from 'assets/images/booking/exit-btn-table-details.png';
import { imgUrl } from 'utils/Utility';
import { SHIFTTYPE, WAGEPAYTYPE, WAGEWEEKENDTYPE } from 'constants/enum';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		outline: 'none',
	},
}));

// const StyledBackdrop = withStyles({
// 	root: {
// 		background: 'rgb(255 255 255 / 30%);',
// 		backdropFilter: 'blur(10px)',
// 	},
// })(Backdrop);

const Wrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 85vh;
	height: 60vh;
	background: #2b2b2be8;
	border-radius: 10px;
	box-shadow: 1px 4px 10px #1c1c1e4d;
`;

const CloseIcon = styled.div`
	position: absolute;
	right: 30px;
	bottom: 20px
	display: flex;
	margin-top: 10px;
	cursor: pointer;

	img {
		user-select: none;
		-webkit-user-drag: none;
		-khtml-user-drag: none;
		-moz-user-drag: none;
		-o-user-drag: none;
		user-drag: none;
	}
`;

const StyledRow = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	width: 100%;
`;

const StyledCol = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100%;

	::-webkit-scrollbar {
		width: 3px;
		height: 3px;
		background-color: transparent;
	}
	::-webkit-scrollbar-thumb {
		width: 1px;
		height: 1px;

		border-radius: 20px;
		background-color: #b3b3b3;
	}

	::-webkit-scrollbar-corner {
		display: none;
	}
`;

const Header = styled.div`
	color: #ffffff;
	font-size: 25px;
	font-weight: 750;
`;

const FloorContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: fit-content;
	margin: 0 0 20px 0;

	h4 {
		font-size: 15px;
		font-weight: 750;
		color: #ffffff;
		margin-bottom: 10px;
	}

	#employee-avatar-container {
		display: grid;
		grid-template-columns: repeat(6, 53px);
		row-gap: 10px;
	}
`;

const EmplAvatar = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
	width: 50px;
	border-radius: 50%;
	background: ${(props) => props.bgColor};
	font-size: 16px;
	font-weight: 750;

	img {
		border-radius: 50%;
		-webkit-user-drag: none;
		-khtml-user-drag: none;
		-moz-user-drag: none;
		-o-user-drag: none;
		user-drag: none;
	}
`;

const RoleDetailsContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
`;

const DetailsContainer = styled.div`
	display: flex;
	flex-direction: row;
	height: 60px;
	width: 100%;
	border: 1px solid #ffffff;
	border-radius: 1.5em;

	span {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding-top: 5px;
	}

	label {
		font-size: 16px;
		font-weight: 550;
		color: #ffffff;
		text-align: center;
		margin: 0;
	}
`;

const Footer = styled(DetailsContainer)`
	position: absolute;
	bottom: 10px;
	max-width: 439px;
`;

function BreakDownModal(props) {
	const classes = useStyles();
	const history = useHistory();
	const floors = history.location.state.find((e) => e.floors).floors;

	const handleClose = () => {
		props.setBreakDownModalHandler({ date: null, boolean: false });
	};

	const renderEmplIconsPerFloor = (date, shifts, floors, employees) => {
		if (date && shifts.length > 0) {
			const filteredByDate = shifts.filter((e) => e.date === date);
			const filteredByShiftType = filteredByDate.filter((e) => e.type === SHIFTTYPE.shift);
			const filteredEmployees = employees.filter((empl) =>
				filteredByShiftType.map((e) => e.employee_id).includes(empl.id),
			);
			const renderFloor = floors.map((floor, key) => {
				const floorIds = filteredByShiftType.map((e) => e.floor_id);
				if (floorIds.includes(floor.id)) {
					return (
						<FloorContainer key={key}>
							<h4>{floor.floor_name}</h4>
							<div id="employee-avatar-container">
								{filteredEmployees.length > 0
									? filteredEmployees.map((e, key) => {
											if (e.photo) {
												return (
													<EmplAvatar key={key} bgColor={e.color}>
														<img
															src={`${imgUrl + e.photo}`}
															width={50}
															height={50}
															alt="empl-avatar"
														/>
													</EmplAvatar>
												);
											} else {
												return (
													<EmplAvatar key={key} bgColor={e.color}>
														{e.first_name.charAt(0)}
														{e.last_name.charAt(0)}
													</EmplAvatar>
												);
											}
									  })
									: ''}
							</div>
						</FloorContainer>
					);
				} else {
					return (
						<FloorContainer key={key}>
							<h4>{floor.floor_name}</h4>
						</FloorContainer>
					);
				}
			});
			return renderFloor;
		} else if (shifts.length) {
			const filteredByShiftType = shifts.filter((e) => e.type === SHIFTTYPE.shift);
			const filteredEmployees = employees.filter((empl) =>
				filteredByShiftType.map((e) => e.employee_id).includes(empl.id),
			);
			const renderFloor = floors.map((floor, key) => {
				const floorIds = filteredByShiftType.map((e) => e.floor_id);
				if (floorIds.includes(floor.id)) {
					return (
						<FloorContainer key={key}>
							<h4>{floor.floor_name}</h4>
							<div id="employee-avatar-container">
								{filteredEmployees.length > 0
									? filteredEmployees.map((e, key) => {
											if (e.photo) {
												return (
													<EmplAvatar key={key} bgColor={e.color}>
														<img
															src={`${imgUrl + e.photo}`}
															width={50}
															height={50}
															alt="empl-avatar"
														/>
													</EmplAvatar>
												);
											} else {
												return (
													<EmplAvatar key={key} bgColor={e.color}>
														{e.first_name.charAt(0)}
														{e.last_name.charAt(0)}
													</EmplAvatar>
												);
											}
									  })
									: ''}
							</div>
						</FloorContainer>
					);
				} else {
					return (
						<FloorContainer key={key}>
							<h4>{floor.floor_name}</h4>
						</FloorContainer>
					);
				}
			});
			return renderFloor;
		}
	};

	const renderStationBreakdown = (date, shifts, stations) => {
		if (date) {
			if (shifts.length > 0) {
				const filteredByDate = shifts.filter((e) => e.date === date);
				const filteredByShiftType = filteredByDate.filter(
					(shift) => shift.type === SHIFTTYPE.shift,
				);
				const roleDetails = stations.map((station, key) => {
					const stationIds = filteredByShiftType.map((e) => e.station_id);
					const filteredStations = props.stations.filter((station) =>
						filteredByShiftType.map((shift) => shift.station_id).includes(station.id),
					);
					if (stationIds.includes(station.id)) {
						return (
							<RoleDetailsContainer key={key}>
								<Header style={{ fontSize: 15, marginBottom: 7 }}>{station.name}</Header>
								<DetailsContainer>
									<span>
										<label>{getStaffCount(filteredByShiftType, station.id)}</label>
										<label>Staff</label>
									</span>
									<span>
										<label>{getStationTotalShift(filteredByShiftType, station.id)}</label>
										<label>Total Shifts</label>
									</span>
									<span>
										{getStationTotalHours(filteredByShiftType, station.id)}
										<label>Total Hours</label>
									</span>
									<span>
										<label>${getSationTotalCost(filteredByShiftType, station.id)}</label>
										<label>Est Cost</label>
									</span>
								</DetailsContainer>
							</RoleDetailsContainer>
						);
					} else if (stationIds.includes(null)) {
						return (
							<RoleDetailsContainer key={key}>
								<Header style={{ fontSize: 15, marginBottom: 7 }}>No Role</Header>
								<DetailsContainer>
									<span>
										<label>{getStaffCount(filteredByShiftType, null)}</label>
										<label>Staff</label>
									</span>
									<span>
										<label>{getStationTotalShift(filteredByShiftType, null)}</label>
										<label>Total Shifts</label>
									</span>
									<span>
										{getStationTotalHours(filteredByShiftType, null)}
										<label>Total Hours</label>
									</span>
									<span>
										<label>${getSationTotalCost(filteredByShiftType, null)}</label>
										<label>Est Cost</label>
									</span>
								</DetailsContainer>
							</RoleDetailsContainer>
						);
					}
				});
				return roleDetails;
			}
		} else {
			if (stations.length > 0) {
				const roleDetails = stations.map((station, key) => {
					const stationIds = props.employeeShifts.map((e) => e.station_id);
					if (stationIds.includes(station.id)) {
						return (
							<RoleDetailsContainer key={key}>
								<Header style={{ fontSize: 15, marginBottom: 7 }}>{station.name}</Header>
								<DetailsContainer>
									<span>
										<label>{getStaffCount(props.employeeShifts, station.id)}</label>
										<label>Staff</label>
									</span>
									<span>
										<label>{getStationTotalShift(props.employeeShifts, station.id)}</label>
										<label>Total Shifts</label>
									</span>
									<span>
										{getStationTotalHours(props.employeeShifts, station.id)}
										<label>Total Hours</label>
									</span>
									<span>
										<label>{getSationTotalCost(props.employeeShifts, station.id)}</label>
										<label>Est Cost</label>
									</span>
								</DetailsContainer>
							</RoleDetailsContainer>
						);
					} else if (stationIds.includes(null)) {
						return (
							<RoleDetailsContainer key={key}>
								<Header style={{ fontSize: 15, marginBottom: 7 }}>No Role</Header>
								<DetailsContainer>
									<span>
										<label>{getStaffCount(props.employeeShifts, null)}</label>
										<label>Staff</label>
									</span>
									<span>
										<label>{getStationTotalShift(props.employeeShifts, null)}</label>
										<label>Total Shifts</label>
									</span>
									<span>
										{getStationTotalHours(props.employeeShifts, null)}
										<label>Total Hours</label>
									</span>
									<span>
										<label>${getSationTotalCost(props.employeeShifts, null)}</label>
										<label>Est Cost</label>
									</span>
								</DetailsContainer>
							</RoleDetailsContainer>
						);
					}
				});
				return roleDetails;
			}
		}
	};

	const getStaffCount = (shifts, stationId) => {
		let count = 0;
		if (shifts.length > 0) {
			if (props.breakDownModalHandler.date !== null) {
				const dateFilteredShifts = shifts.filter(
					(e) => e.date === props.breakDownModalHandler.date,
				);
				const filteredShiftByType = dateFilteredShifts.filter((e) => e.type === SHIFTTYPE.shift);
				const filteredShifts = filteredShiftByType.filter((e) => e.station_id === stationId);
				let emplIds = [];
				filteredShifts.map((e) => {
					let id = e.employee_id;
					if (!emplIds.includes(id)) {
						emplIds.push(id);
					}
				});
				count = emplIds.length;
			} else {
				const filteredShiftByType = shifts.filter((e) => e.type === SHIFTTYPE.shift);
				const filteredShifts = filteredShiftByType.filter((e) => e.station_id === stationId);
				let emplIds = [];
				filteredShifts.map((e) => {
					let id = e.employee_id;
					if (!emplIds.includes(id)) {
						emplIds.push(id);
					}
				});
				count = emplIds.length;
			}
		}

		return count;
	};

	const getStationTotalShift = (shifts, stationId) => {
		let shiftsCount = 0;
		if (shifts.length > 0) {
			if (props.breakDownModalHandler.date !== null) {
				const dateFilteredShifts = shifts.filter(
					(e) => e.date === props.breakDownModalHandler.date,
				);
				const filteredShifts = dateFilteredShifts.filter((e) => e.station_id === stationId);
				const refilteredShifts = filteredShifts.filter((e) => e.type === SHIFTTYPE.shift);
				shiftsCount = refilteredShifts.length;
			} else {
				const filteredShifts = shifts.filter((e) => e.station_id === stationId);
				const refilteredShifts = filteredShifts.filter((e) => e.type === SHIFTTYPE.shift);
				shiftsCount = refilteredShifts.length;
			}
		}
		return shiftsCount;
	};

	const getStationTotalHours = (shifts, stationId) => {
		let Hours = 0;
		let Minutes = 0;
		if (shifts.length > 0) {
			if (props.breakDownModalHandler.date !== null) {
				const dateFilteredShifts = shifts.filter(
					(e) => e.date === props.breakDownModalHandler.date,
				);
				const filteredShifts = dateFilteredShifts.filter((e) => e.station_id === stationId);
				const refilteredShifts = filteredShifts.filter((e) => e.type === SHIFTTYPE.shift);
				let hour = 0;
				let minute = 0;
				refilteredShifts.map((e) => {
					const diff = e.end_time - e.start_time;
					const h = Math.floor(diff / 3600);
					const m = Math.floor((diff - h * 3600) / 60);
					hour = Math.abs(h) + hour;
					minute = Math.abs(m) + minute;
				});
				Hours = hour;
				Minutes = minute;
			} else {
				const filteredShifts = shifts.filter((e) => e.station_id === stationId);
				const refilteredShifts = filteredShifts.filter((e) => e.type === SHIFTTYPE.shift);
				let hour = 0;
				let minute = 0;
				refilteredShifts.map((e) => {
					const diff = e.end_time - e.start_time;
					const h = Math.floor(diff / 3600);
					const m = Math.floor((diff - h * 3600) / 60);
					hour = Math.abs(h) + hour;
					minute = Math.abs(m) + minute;
				});
				Hours = hour;
				Minutes = minute;
			}
		}
		return (
			<label>
				{Hours} h {Minutes > 0 ? moment().minute(Minutes).format('mm') : ''}{' '}
				{Minutes > 0 ? 'm' : ''}
			</label>
		);
	};

	const getSationTotalCost = (shifts, stationId) => {
		let estCost = 0;
		if (shifts.length > 0) {
			if (props.breakDownModalHandler.date !== null) {
				const filteredByDateShifts = shifts.filter(
					(e) => e.date === props.breakDownModalHandler.date,
				);
				const filteredByStation = filteredByDateShifts.filter((e) => e.station_id === stationId);
				const filteredByShiftType = filteredByStation.filter((e) => e.type === SHIFTTYPE.shift);
				let rate = 0;
				let emplRate = 0;
				filteredByShiftType.map((shift) => {
					const dateOfYear = moment(shift.date).dayOfYear();
					const dayOfWeek = moment(shift.date).isoWeekday();
					const employee = props.employees.find((empl) => empl.id === shift.employee_id);
					if (employee.pay_wages_item.length > 0) {
						emplRate = checkPayWageRate(employee, dateOfYear, dayOfWeek);
					} else {
						emplRate = employee.rate ? parseInt(employee.rate) : 0;
					}

					const diff = shift.end_time - shift.start_time;
					const h = Math.floor(diff / 3600);
					const m = Math.floor((diff - h * 3600) / 60);
					rate = emplRate * h + rate;
				});
				estCost = rate;
				return Math.round((estCost * 100) / 100).toFixed(2);
			} else {
				const filteredByStation = shifts.filter((e) => e.station_id === stationId);
				const filteredByShiftType = filteredByStation.filter((e) => e.type === SHIFTTYPE.shift);
				let rate = 0;
				let emplRate = 0;
				filteredByShiftType.map((shift) => {
					const dateOfYear = moment(shift.date).dayOfYear();
					const dayOfWeek = moment(shift.date).isoWeekday();
					const employee = props.employees.find((empl) => empl.id === shift.employee_id);
					if (employee.pay_wages_item.length > 0) {
						emplRate = checkPayWageRate(employee, dateOfYear, dayOfWeek);
					} else {
						emplRate = employee.rate ? parseInt(employee.rate) : 0;
					}

					const diff = shift.end_time - shift.start_time;
					const h = Math.floor(diff / 3600);
					const m = Math.floor((diff - h * 3600) / 60);
					rate = emplRate * h + rate;
				});
				estCost = rate;
				return Math.round((estCost * 100) / 100).toFixed(2);
			}
		}
	};

	const getFooterDetails = (date, shifts) => {
		let Hours = 0;
		let Minutes = 0;
		let TotalShifts = 0;
		let TotalEstCost = 0;
		if (shifts.length > 0) {
			if (date) {
				const filteredByDate = shifts.filter((e) => e.date === date);
				const filteredByShiftType = filteredByDate.filter((e) => e.type === SHIFTTYPE.shift);
				const filteredByEmployee = filteredByShiftType.filter((shift) =>
					props.selectedEmployees.map((e) => e.id).includes(shift.employee_id),
				);
				let hour = 0;
				let minute = 0;
				let rate = 0;
				let emplRate = 0;
				filteredByEmployee.map((e) => {
					const dateOfYear = moment(e.date).dayOfYear();
					const dayOfWeek = moment(e.date).isoWeekday();
					const employee = props.employees.find((empl) => empl.id === e.employee_id);
					if (employee.pay_wages_item.length > 0) {
						emplRate = checkPayWageRate(employee, dateOfYear, dayOfWeek);
					} else {
						emplRate = employee.rate ? parseInt(employee.rate) : 0;
					}
					const diff = e.end_time - e.start_time;
					const h = Math.floor(diff / 3600);
					const m = Math.floor((diff - h * 3600) / 60);
					hour = h + hour;
					minute = m + minute;
					rate = emplRate * h + rate;
				});
				Hours = hour;
				Minutes = minute;
				TotalShifts = filteredByEmployee.length;
				TotalEstCost = rate;
			} else {
				const filteredByShiftType = shifts.filter((e) => e.type === SHIFTTYPE.shift);
				const filteredByEmployee = filteredByShiftType.filter((shift) =>
					props.selectedEmployees.map((e) => e.id).includes(shift.employee_id),
				);
				let hour = 0;
				let minute = 0;
				let rate = 0;
				let emplRate = 0;
				filteredByEmployee.map((e) => {
					const dateOfYear = moment(e.date).dayOfYear();
					const dayOfWeek = moment(e.date).isoWeekday();
					const employee = props.employees.find((empl) => empl.id === e.employee_id);
					if (employee.pay_wages_item.length > 0) {
						emplRate = checkPayWageRate(employee, dateOfYear, dayOfWeek);
					} else {
						emplRate = employee.rate ? parseInt(employee.rate) : 0;
					}
					const diff = e.end_time - e.start_time;
					const h = Math.floor(diff / 3600);
					const m = Math.floor((diff - h * 3600) / 60);
					hour = h + hour;
					minute = m + minute;
					rate = emplRate * h + rate;
				});
				Hours = hour;
				Minutes = minute;
				TotalShifts = filteredByEmployee.length;
				TotalEstCost = rate;
			}
		}

		return (
			<Footer>
				<span>
					<label>{TotalShifts}</label>
					<label>Total Shifts</label>
				</span>
				<span>
					<label>
						{Hours} h {Minutes > 0 ? moment().minute(Minutes).format('mm') : ''}{' '}
						{Minutes > 0 ? 'm' : ''}
					</label>
					<label>Total Hours</label>
				</span>
				<span>
					<label>${Math.round((TotalEstCost * 100) / 100).toFixed(2)}</label>
					<label>Est Cost</label>
				</span>
			</Footer>
		);
	};

	const checkPayWageRate = (empl, dateOfYear, dayOfWeek) => {
		let rate = 0;
		empl.pay_wages_item.map((wage) => {
			if (wage.wage_type === WAGEPAYTYPE.holiday) {
				const wageDateOfYear = moment(wage.holiday_date).dayOfYear();
				if (wageDateOfYear === dateOfYear) {
					rate = wage.rate;
					return rate;
				} else {
					rate = empl.rate ? parseInt(empl.rate) : 0;
					return rate;
				}
			} else if (wage.wage_type === WAGEPAYTYPE.weekend) {
				const wageDayOfWeek = wage.wage_week_end_type === WAGEWEEKENDTYPE ? 6 : 7;
				if (wageDayOfWeek === dayOfWeek) {
					rate = wage.rate;
					return rate;
				} else {
					rate = empl.rate ? parseInt(empl.rate) : 0;
					return rate;
				}
			}
		});
		return rate;
	};

	return (
		<Modal
			aria-labelledby="break-down-modal"
			aria-describedby="child-modal"
			className={classes.modal}
			open={props.breakDownModalHandler.boolean}
			onClose={handleClose}
			keepMounted
			closeAfterTransition
			BackdropProps={{
				style: {
					background: 'rgb(255 255 255 / 30%)',
					backdropFilter: 'blur(10px)',
				},
			}}
		>
			<div className={classes.paper}>
				<Wrapper>
					<StyledRow style={{ padding: '15px 15px 5px 15px' }}>
						{props.breakDownModalHandler.date ? (
							<Header>
								Breakdown | {moment(props.breakDownModalHandler.date).format('dddd DD MMM')}
							</Header>
						) : (
							<Header>Breakdown</Header>
						)}
						<CloseIcon onClick={handleClose}>
							<img src={xIcon} width={10} height={12} alt="close-icon" />
						</CloseIcon>
					</StyledRow>
					<StyledRow style={{ padding: '10px 20px 20px 20px', height: '90%' }}>
						<StyledCol style={{ minWidth: 320, marginRight: 10, overflowY: 'auto' }}>
							{renderEmplIconsPerFloor(
								props.breakDownModalHandler.date,
								props.employeeShifts,
								floors,
								props.selectedEmployees,
							)}
						</StyledCol>
						<StyledCol style={{ width: '100%', height: '100%' }}>
							{renderStationBreakdown(
								props.breakDownModalHandler.date,
								props.employeeShifts,
								props.stations,
							)}
							{/* {props.stations.length > 0
								? props.stations.map((station, key) => {
										const stationIds = props.employeeShifts.map((e) => e.station_id);

										if (stationIds.includes(station.id)) {
											return (
												<RoleDetailsContainer key={key}>
													<Header style={{ fontSize: 15, marginBottom: 7 }}>{station.name}</Header>
													<DetailsContainer>
														<span>
															<label>{getStaffCount(props.employeeShifts, station.id)}</label>
															<label>Staff</label>
														</span>
														<span>
															<label>
																{getStationTotalShift(props.employeeShifts, station.id)}
															</label>
															<label>Total Shifts</label>
														</span>
														<span>
															<label>
																{getStationTotalHours(props.employeeShifts, station.id)} h
															</label>
															<label>Total Hours</label>
														</span>
														<span>
															<label>$0</label>
															<label>Est Cost</label>
														</span>
													</DetailsContainer>
												</RoleDetailsContainer>
											);
										} else {
											return;
										}
								  })
								: ''}  */}
							{getFooterDetails(props.breakDownModalHandler.date, props.employeeShifts)}
						</StyledCol>
					</StyledRow>
				</Wrapper>
			</div>
		</Modal>
	);
}

export default BreakDownModal;
