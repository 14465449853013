import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
	position: relative;
	display: flex;
	height: 18px;
	width: auto;
	border-radius: 10px;
	background: #ffffff;
	z-index: 2;
	box-shadow: 0px 1px 10px #1c1c1e4d;
	user-select: none;
	color: white;

	input {
		display: none;
	}

	label {
		display: inline-block;
		width: 52px;
		height: 17px;
		text-align: center;
		z-index: 2;
		padding-top: 3px;
		cursor: pointer;
		color: #c67575;
		white-space: nowrap;
		font-size: 8px;
		font-weight: 550;
	}
`;

const ControlBg = styled.div`
	position: absolute;
	left: 0;
	top: 0.5px;
	height: 17px;
	width: 52px;
	border-radius: 10px;
	background: #ffd9d9;
	// box-shadow: 5px 5px 10px #1a2024, -5px -5px 10px #2a343a;
	z-index: -1;

	transform: translateX(${(props) => props.translateVal}px);
	transition: transform 200ms ease-in-out;
`;

const Line = styled.div`
	display: inline-block;
	height: 10px;
	border: 0.5px solid #c67575;
	margin: 4px 0;
`;

function RosterSegmentControl(props) {
	const [selectedOption, setSelectedOption] = useState('1');

	const selectOption = (e) => {
		setSelectedOption(e.target.value);
		props.selectedOptionCallback(e.target.value);
	};

	return (
		<div>
			<Container>
				<input
					id="rrsc1"
					type="radio"
					onChange={selectOption}
					value="1"
					checked={selectedOption === '1'}
					name="radioBtn"
				/>
				<label htmlFor="rrsc1">{props.labelContents[0]}</label>
				<Line />
				<input
					id="rrsc2"
					type="radio"
					onChange={selectOption}
					value="2"
					checked={selectedOption === '2'}
					name="radioBtn"
				/>
				<label htmlFor="rrsc2">{props.labelContents[1]}</label>
				<Line />
				<input
					id="rrsc3"
					type="radio"
					onChange={selectOption}
					value="3"
					checked={selectedOption === '3'}
					name="radioBtn"
				/>
				<label htmlFor="rrsc3">{props.labelContents[2]}</label>
				<Line />
				<input
					id="rrsc4"
					type="radio"
					onChange={selectOption}
					value="4"
					checked={selectedOption === '4'}
					name="radioBtn"
				/>
				<label htmlFor="rrsc4">{props.labelContents[3]}</label>

				<ControlBg
					translateVal={
						selectedOption === '1'
							? 0
							: selectedOption === '2'
							? 53
							: selectedOption === '3'
							? 53 * 2
							: selectedOption === '4'
							? 53 * 3
							: ''
					}
				/>
			</Container>
		</div>
	);
}

export default RosterSegmentControl;
