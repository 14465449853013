import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import arrowDownIcon from 'assets/images/booking/arrow-down-icon.png';
import { BOOKINGSTATUS } from 'constants/enum';

const Container = styled.div`
	position: relative;
	display: inline-flex;
	justify-content: center;
	// align-items: center;
	height: 85%;
	width: 100%;
`;

const GraphWrapper = styled.div`
	position: relative;
	display: inline-flex;
	justify-content: center;
	height: 85%;
`;

const OverLay = styled(GraphWrapper)`
	position: absolute;
	background: transparent;
	padding-top: 10px;
	width: ${(props) => props.width}px;
	overflow: hidden;
`;

const Graph = styled.div`
	position: relative;
	display: flex;
	height: 55.5px;

	#line1 {
		width: 9px;
		height: 40px;
		border-left: 4px solid white;
		// margin-right: 1px;
	}

	#line2 {
		width: 9px;
		height: 40px;
		border-left: 2px solid white;
	}

	#line3 {
		width: 9px;
		height: 40px;
		border-left: 2px solid white;
	}

	#line4 {
		width: 9px;
		height: 40px;
		border-left: 2px solid white;
	}

	#line5 {
		width: 9px;
		height: 40px;
		border-left: 4px solid white;
	}

	#spacer {
		width: 19px;
		height: 40px;
		border-left: 4px solid white;
	}

	h4 {
		position: absolute;
		font-size: 12px;
		left: -2px;
		bottom: -15px;
	}
`;

const StyledH4 = styled.div`
	font-size: 12px;
	position: absolute;
	right: 0px;
	bottom: -6.8px;
`;

const ImgContainer = styled.div`
	position: absolute;
	bottom: 10px;
	img {
		user-select: none;
		-webkit-user-drag: none;
		-khtml-user-drag: none;
		-moz-user-drag: none;
		-o-user-drag: none;
		user-drag: none;
	}
`;

const BookingIndicator = styled.div`
	position: absolute;
	left: 0;
	height: 21px;
`;

function TimeGraph({ shift, bookingsOnTable }) {
	const [overlayWidth, setOverlayWidth] = useState(0);
	let end = shift ? moment(new Date()).startOf('day').second(shift.end_time) : '';
	let start = shift ? moment(new Date()).startOf('day').second(shift.start_time) : '';
	let diff = shift ? Math.floor(shift.end_time / 3600 - shift.start_time / 3600) + 1 : 0; //end.diff(start, 'hour')
	let time = shift ? start.format('h') : '';
	const returnValue = [];

	for (let i = 0; i <= diff; i++) {
		if (diff > 9) {
			if (i === 0) {
				returnValue.push(
					<Graph id="graph" key={i}>
						<span id="spacer" />
						<h4>{time}</h4>
					</Graph>,
				);
			} else if (i === diff) {
				time = start ? start.add(1, 'h').format('h') : '';
				let endTime = end ? end.add(1, 'h').format('h') : '';
				if (time === endTime) {
					endTime = end ? end.add(1, 'h').format('h') : '';
				}

				returnValue.push(
					<Graph id="graph" key={i}>
						<span id="spacer" />
						<span
							id="line5"
							style={{ background: 'linear-gradient(to right, #ffffff 90%, transparent 10%)' }}
						/>
						<h4>{time}</h4>
						<StyledH4>{endTime}</StyledH4>
					</Graph>,
				);
			} else {
				time = start ? start.add(1, 'h').format('h') : '';
				returnValue.push(
					<Graph id="graph" key={i}>
						<span id="spacer" />
						{/* <span id="line5" /> */}
						<h4>{time}</h4>
					</Graph>,
				);
			}
		} else {
			if (i === 0) {
				returnValue.push(
					<Graph id="graph" key={i}>
						<span id="line1" />
						<span id="line2" />
						<span id="line3" />
						<span id="line4" />
						{/* <span id="line5" /> */}
						<h4>{time}</h4>
					</Graph>,
				);
			} else if (i === diff) {
				time = start ? start.add(1, 'h').format('h') : '';
				let endTime = end ? end.add(1, 'h').format('h') : '';
				if (time === endTime) {
					endTime = end ? end.add(1, 'h').format('h') : '';
				}

				returnValue.push(
					<Graph id="graph" key={i}>
						<span id="line1" />
						<span id="line2" />
						<span id="line3" />
						<span id="line4" />
						<span
							id="line5"
							style={{ background: 'linear-gradient(to right, #ffffff 90%, transparent 10%)' }}
						/>
						<h4>{time}</h4>
						<StyledH4>{endTime}</StyledH4>
					</Graph>,
				);
			} else {
				time = start ? start.add(1, 'h').format('h') : '';
				returnValue.push(
					<Graph id="graph" key={i}>
						<span id="line1" />
						<span id="line2" />
						<span id="line3" />
						<span id="line4" />
						{/* <span id="line5" /> */}
						<h4>{time}</h4>
					</Graph>,
				);
			}
		}
	}

	const getBookingPosition = (booking) => {
		let position;
		if (diff > 9) {
			position = ((booking.start_time - shift.start_time) / 900) * 4.8;
		} else {
			position = ((booking.start_time - shift.start_time) / 900 + 3) * 9;
		}
		return position;
	};

	const getBookingWidth = (booking) => {
		let width = ((booking.end_time - booking.start_time) / 900) * (diff > 9 ? 4.8 : 9);
		return width;
	};

	useEffect(() => {
		const element = document.getElementById('graph-wrapper');
		if (element !== null) {
			const width = element.getBoundingClientRect().width;
			setOverlayWidth(width);
		}
	}, [shift]);

	return (
		<Container>
			<GraphWrapper id="graph-wrapper">{returnValue}</GraphWrapper>
			<OverLay width={overlayWidth}>
				{bookingsOnTable.length > 0
					? bookingsOnTable.map((booking, key) => {
							// console.log(booking.start_time, booking.end_time);
							if (booking.status === BOOKINGSTATUS.seated) {
								return (
									<BookingIndicator
										id="booking-ndr"
										key={key}
										style={{
											background: '#fe7d86',
											left: getBookingPosition(booking),
											width: getBookingWidth(booking),
										}}
									/>
								);
							} else {
								return (
									<BookingIndicator
										id="booking-ndr"
										key={key}
										style={{
											background: 'white',
											left: getBookingPosition(booking),
											width: getBookingWidth(booking),
										}}
									/>
								);
							}
					  })
					: ''}
			</OverLay>
			<ImgContainer>
				<img src={arrowDownIcon} width={18} height={10.35} />
			</ImgContainer>
		</Container>
	);
}

export default TimeGraph;
