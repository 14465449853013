export const createBookingParameters = (
	shiftId, // Int
	type, // Int
	reservationNote, // String
	table_ids, // Int
	table_lock, // Boolean
	guest, // Guest Object
	bookingTaken, // Employee Object
	status, // Int
	startDate, // String
	endDate, // String
	partySize, // Int
	expId, // Int
	expNoOfTickets, // Int
) => {
	let params = {
		shift_id: shiftId,
		id: 0,
		type: type,
		reservation_note: reservationNote,
		table_ids: table_ids,
		table_lock: table_lock,
		guest: guest,
		booking_taken: bookingTaken,
		status: status,
		start_date: startDate,
		end_date: endDate,
		party_size: partySize,
		partial_seated: 0,
	};

	if (expId > 0) {
		params = { ...params, experience_id: expId, experience_no_of_tickets: expNoOfTickets };
	}

	return params;
};

export const createTableParameters = (floorId, tables) => {
	let params = {};
	params = {
		floor_id: floorId,
		tables: tables.map((e) => {
			return {
				block_tables: e.block_tables,
				branch_id: e.branch_id,
				can_rotate: e.can_rotate,
				capacity_max: e.capacity_max,
				capacity_min: e.capacity_min,
				created_at: e.created_at,
				floor_id: e.floor_id,
				id: e.id,
				is_combied: e.is_combined,
				name: e.name,
				pos_x: e.pos_x,
				pos_y: e.pos_y,
				rotate_deg: e.rotate_deg,
				table_type: e.table_type,
				updated_at: e.updated_at,
				widget_is_non_reservable: e.widget_is_non_reservable,
			};
		}),
	};
	return params;
};

export const createFloorParameters = (floors) => {
	let params = {
		floors: floors.map((e) => {
			return {
				id: e.id,
				floor_name: e.floor_name,
			};
		}),
	};

	return params;
};

export const createGuestParameters = (guest) => {
	return {
		special_relationship: guest.special_relationship,
		address: guest.address,
		food_drink_preference: guest.food_drink_preference,
		id: parseInt(guest.id),
		phone: parseInt(guest.phone),
		general_note: guest.general_note,
		city: guest.city,
		birthdate: guest.birthdate,
		anniversary: guest.anniversary,
		tags: [],
		company: guest.company,
		last_name: guest.last_name,
		postal: guest.postal,
		seating_preference: guest.seating_preference,
		state: guest.state,
		first_name: guest.first_name,
		email: guest.email,
	};
};

export const createSmsParameters = (guest, smsTemplate, id) => {
	let params = {
		guest: {
			special_relationship: guest.special_relationship,
			address: guest.address,
			food_drink_preference: guest.food_drink_preference,
			id: parseInt(guest.id),
			phone: '+' + parseInt(guest.phone),
			general_note: guest.general_note,
			city: guest.city,
			birthdate: guest.birthdate,
			anniversary: guest.anniversary,
			tags: [],
			company: guest.company,
			last_name: guest.last_name,
			postal: guest.postal,
			seating_preference: guest.seating_preference,
			state: guest.state,
			first_name: guest.first_name,
			email: guest.email,
		},
		body: smsTemplate.body,
		sms_template: {
			body: smsTemplate.body,
			name: smsTemplate.name,
			id: id,
		},
	};
	return params;
};
