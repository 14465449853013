import React, { useEffect, useState } from 'react';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import exitBtnIcon from '../../../../../../assets/images/booking/exit-btn-table-details.png';
import floorIcon from 'assets/images/booking/table-layout-planning/floor-plan-logo.png';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		width: '100%',
		height: '100%',
		backdropFilter: 'blur(10px)',
		outline: 'none',
	},
}));
const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
`;
const Content = styled.div`
	width: 544px;
	height: 123px;
	background: rgba(0, 0, 0, 0.6);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 10px;
	border-color: none;
	padding: 20px;
	color: white;
`;
const Header = styled.label`
	display: flex;
	flex-direction: row;
	align-items: center;
	align-items: center;
	font-size: 16px;
	font-weight: bold;
	color: white;
	width: 100%;
	margin-bottom: 10px;
`;
const ImgWrapper = styled.div`
	display: inline-flex;
	align-content: center;
	justify-content: center;
	align-items: center;
	position: absolute;
	background-color: #ffffffd9;
	border-radius: 27%;
	top: 15px;
	right: 15px;
	cursor: pointer;
	width: 36px;
	height: 36px;
	margin: 5px;
`;
const Input = styled.input`
	width: 100%;
	height: 40px;
	background: rgba(0, 0, 0, 0);
	border-bottom: 1px solid #343643;
	color: white;
	padding: 10px;
`;

function AddSectionModal(props) {
	const classes = useStyles();
	const [sectionName, setsectionName] = useState('');

	const handleClose = () => {
		props.handleAddSection(sectionName);
		setsectionName('');
	};

	// console.log(sectionName);

	return (
		<Modal
			className={classes.modal}
			open={props.openAddSection}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={props.openAddSection}>
				<div className={classes.paper}>
					<Wrapper>
						<Content>
							<ImgWrapper onClick={handleClose}>
								<img src={exitBtnIcon} width="12" height="12" alt="checker" />
							</ImgWrapper>
							<Header>
								<img
									src={floorIcon}
									width="26"
									height="26"
									alt="Icon"
									style={{ marginTop: 5, marginRight: 5, marginBottom: 5 }}
								/>
								&nbsp; ADD NEW SECTION
							</Header>

							<Input
								type="text"
								placeholder="Enter new section name"
								onChange={(e) => setsectionName(e.target.value)}
								value={sectionName}
							/>
						</Content>
					</Wrapper>
				</div>
			</Fade>
		</Modal>
	);
}
export default AddSectionModal;
