import React, { useState } from 'react';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PartySizeSelection from '../party-size-selection/PartySizeSelection';

import btnClose from '../../../../../assets/images/booking/create-booking/button-close-icon.png';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		width: '100%',
		height: '100%',
		backdropFilter: 'blur(10px)',
		outline: 'none',
	},
}));

function PartialPartySize(props) {
	const classes = useStyles();

	const selectedPartySize = (value) => {
		props.partyPartySizeHandler(value);
		props.handleCloseSelectedBookingCalendar(false);
	};

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={props.openPartialPartySize}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={props.openPartialPartySize}>
				<div className={classes.paper}>
					<div className="partial-party-size-container">
						<img
							onClick={() => props.handleCloseSelectedBookingCalendar(false)}
							src={btnClose}
							width="30"
							height="30"
							className="d-inline"
							style={{
								textAlign: 'center',
								margin: '10px 20px',
								marginRight: 10,
								cursor: 'pointer',
								position: 'absolute',
								top: 10,
								right: 10,
							}}
							alt="checker"
						/>
						<div className="partial-party-size-main-container">
							<div>
								<label
									style={{
										fontWeight: 700,
										fontSize: 16,
										color: 'white',
									}}
								>
									PARTIAL PARTY SEATED
								</label>
							</div>
							<PartySizeSelection selectedPartySize={selectedPartySize} partySize={12} />
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}

export default PartialPartySize;
