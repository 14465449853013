import React, { useState, useRef, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import { PAYBASIS, WAGEPAYTYPE, WAGEWEEKENDTYPE } from 'constants/enum';
import { List } from 'react-virtualized';
import styled from 'styled-components';

import xIcon from 'assets/images/booking/exit-btn-table-details.png';
import coloredXicon from 'assets/images/booking/user-roles/colored-x-icon.png';
import UserRolesCalendar from '../UserRolesCalendar';
import AlertDialog from '../../../CreatBooking/booking-experience/AlertDialog';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		outline: 'none',
	},
}));

const StyledBackdrop = withStyles({
	root: {
		background: 'rgb(255 255 255 / 30%);',
		backdropFilter: 'blur(10px)',
	},
})(Backdrop);

// const StyledDialog = styled(Dialog)`
// 	& > .MuiDialog-container > .MuiDialog-scrollPaper {
// 		width: 100%;
// 	}

// 	& > .MuiDialog-container > .MuiPaper-root {
// 		border-radius: 10px;
// 		padding: 0;
// 		margin: 0;
// 	}
// `;
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 807px;
	height: 596px;
	background: #f5f5f5;
	border-radius: 10px;
	box-shadow: 0px 1px 10px #1c1c1e4d;
`;

const Container = styled.div`
	position: relative;
	width: 807px;
	height: 536px;
	padding: 15px 20px;
	user-select: none;
	overflow-y: auto;
	overflow-x: hidden;
	transition: ease 250ms;

	::-webkit-scrollbar {
		width: 1px;
		height: 5px;
		background-color: transparent;
	}
	::-webkit-scrollbar-thumb {
		width: 1px;
		height: 1px;
		border-radius: 20px;
		background-color: transparent;
	}
`;

const Footer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 60px;
	width: 100%;
`;

const StyledButton = styled.div`
	display: flex;
	min-width: 180px;
	height: 50px;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	font-weight: 700;
	box-shadow: 2px 3px 10px #1c1c1e4d;
	border-radius: 1.5em;
	margin: 5px;
	color: #656565;
	transition: ease 250ms;
	margin-bottom: 30px;
	user-select: none;
	cursor: pointer;

	&:hover {
		background: #1c1c1e05;
	}
`;

const Title = styled.h4`
	font-size: 20px;
	font-weight: 700;
	margin-bottom: 25px;
	color: #656565;
`;

const StyledInputContainer = styled.div`
	position: relative;
	width: 100%;
	height: 40px;
	background: #ffffff;
	box-shadow: 2px 3px 10px #1c1c1e4d;
	border-radius: 1em;
	margin: 10px 0;
	padding-right: 50px;
	padding-top: 5px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	transition: ease 250ms;

	label {
		margin-left: 20px;
		font-size: 14px;
		font-weight: 700;
		color: #1c1c1e;
		white-space: nowrap;
	}

	input {
		text-align: end;
		width: 50%;
		margin-left: 20px;
		font-size: 14px;
		font-weight: 500;
		transition: ease 250ms;

		::placeholder,
		::-webkit-input-placeholder {
			color: #7d7d7d9e;
		}
		:-ms-input-placeholder {
			color: #7d7d7d9e;
		}

		// &:focus {
		// 	text-align: start;
		// }
	}

	#styled-date-holder {
		text-align: end;
		width: 50%;
		margin-left: 20px;
		font-size: 14px;
		font-weight: 500;
		white-space: nowrap;
		cursor: pointer;
		transition: ease 250ms;
	}
`;

const StyledBtnGroup = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	label {
		font-size: 14px;
		font-weight: 700;
		color: #505050;
	}

	#btn-row {
		position: relative;
		display: flex;
		flex-direction: row;
		overflow: hidden;
	}

	button {
		display: flex;
		min-width: 130px;
		height: 35px;
		justify-content: center;
		align-items: center;
		font-size: 12px;
		font-weight: 500;
		box-shadow: 2px 3px 10px #1c1c1e4d;
		border-radius: 1.5em;
		margin: 5px;
		color: #505050;
		transition: ease 250ms;
		&:hover {
			background: #1c1c1e05;
		}
	}
`;

const StyledRow = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	margin-top: 25px;
`;

const StyledCol = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
`;

const StyledAddBtn = styled.div`
	position: absolute;
	right: 0;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: fit-content;
	padding: 0 15px;
	height: 25px;
	border-radius: 5px;
	box-shadow: 2px 3px 10px #1c1c1e4d;
	font-size: 14px;
	font-weight: 700;
	cursor: pointer;
	color: #707071;
`;

const EmployeeItem = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	height: 50px;
	font-size: 14px;
	font-weight: 700;
	color: #2c2c2c;
	margin: 5px 0;

	#initials-container {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 25px;
		width: 25px;
		border-radius: 50%;
		background: #7979d1;
		margin: 0 10px 0 20px;
		font-size: 12px;
		font-weight: 900;
	}

	#item-img-container {
		position: absolute;
		right: 15px;
		cursor: pointer;

		img {
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-o-user-select: none;
			user-select: none;
		}
	}
`;

const ListContainer = styled.div`
	position: absolute;
	width: 180px;
	min-height: fit-content;
	max-height: 590px;
	bottom: -15px;
	right: -15px;
	z-index: 1;
	box-shadow: 2px 3px 10px #1c1c1e4d;
	border-radius: 5px;
	background: white;
	padding: 0 10px;

	.ReactVirtualized__Grid.ReactVirtualized__List {
		::-webkit-scrollbar {
			// width: 15px;
			// height: 5px;
			// background-color: transparent;
			display: none;
		}
		::-webkit-scrollbar-thumb {
			// width: 10px;
			// height: 20px;
			// border-radius: 20px;
			// background-color: transparent;
			display: none;
		}

		::-webkit-scrollbar-corner {
			display: none;
		}
	}

	#list-wrapper {
		position: relative;
		height: inherit;
		width: inherit;
	}
`;

const ListItem = styled.div`
	display: flex;
	align-items: center;
	min-height: 35px;
	width: 100%;
	margin: 5px;
	font-size: 12px;
	font-weight: 500;
	color: #656565;
	cursor: pointer;
	user-select: none;
`;

function CreateWagePayItem(props) {
	const classes = useStyles();
	const [name, setName] = useState('');
	const [nameOnPaySlip, setNameOnPaySlip] = useState('');
	const [employees, setEmployees] = useState([]);
	const [isEmployeesSelected, setIsEmployeesSelected] = useState(false);
	const [rate, setRate] = useState('');
	const [payBasis, setPayBasis] = useState(PAYBASIS.hourly);
	const [type, setType] = useState(WAGEPAYTYPE.weekend);
	const [weekEnd, setWeekEnd] = useState(WAGEWEEKENDTYPE.saturday);
	const [holidayDate, setHolidayDate] = useState('');
	const [employeeList, setEmployeeList] = useState(props.employeeList);
	const [isListOpen, setIsListOpen] = useState(false);
	const [isCalendarOpen, setIsCalendarOpen] = useState(false);
	const [hasError, setHasError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	const wrapperRef = useRef(null);
	useOuteSideClick(wrapperRef);

	function useOuteSideClick(ref) {
		useEffect(() => {
			function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target)) {
					setIsListOpen(false);
				}
			}
			document.addEventListener('mousedown', handleClickOutside);
			return () => {
				document.removeEventListener('mousedown', handleClickOutside);
			};
		}, [ref]);
	}

	const handleClose = () => {
		props.setIsAddingWageItem(false);
	};

	const onChangeHandler = (e, int) => {
		if (int === 0) {
			setName(e.target.value);
		}
		if (int === 1) {
			setNameOnPaySlip(e.target.value);
		}
		if (int === 2) {
			let rate;
			if (e.target.validity.valid) {
				rate = e.target.value;
				if (!rate) {
					setRate('');
				} else {
					setRate(parseInt(rate));
				}
			}
		}
	};

	const payBasisClick = (int) => {
		setPayBasis(int);
	};

	const wageTypeClick = (int) => {
		setType(int);
	};

	const weekEndClick = (int) => {
		setWeekEnd(int);
	};

	const toggleEmployeeList = () => {
		setIsListOpen(!isListOpen);
	};

	const handleClickEmployee = (emp) => {
		let newArray = employees;
		let ids = newArray.map((e) => e.id);
		if (ids.includes(emp.id)) {
			let idx = newArray.findIndex((e) => e.id === emp.id);
			newArray.splice(idx, 1);
		} else {
			newArray.push(emp);
		}
		setEmployees(newArray);
		setIsListOpen(false);
		const timer = setTimeout(() => {
			setIsListOpen(true);
		}, 0);
		return () => clearTimeout(timer);
	};

	const handleRemoveEmployee = (empl) => {
		let newArray = employees;
		let idx = newArray.findIndex((e) => e.id === empl.id);
		newArray.splice(idx, 1);
		setEmployees(newArray);
		setIsEmployeesSelected(true);
		const timer = setTimeout(() => {
			setIsEmployeesSelected(false);
		}, 0);
		return () => clearTimeout(timer);
	};

	const toggleCalendar = () => {
		setIsCalendarOpen(!isCalendarOpen);
	};

	const handleDateCallback = (date) => {
		setHolidayDate(date);
	};

	const handleSubmit = () => {
		if (!name) {
			setHasError(true);
			setErrorMessage({
				title: 'Error',
				body: 'Name field is required',
			});
		} else if (!nameOnPaySlip) {
			setHasError(true);
			setErrorMessage({
				title: 'Error',
				body: 'Name on pay slip field is required',
			});
		} else if (!rate) {
			setHasError(true);
			setErrorMessage({
				title: 'Error',
				body: 'Rate field is required',
			});
		} else if (type === WAGEPAYTYPE.holiday && !holidayDate) {
			setHasError(true);
			setErrorMessage({
				title: 'Error',
				body: 'Holiday date field is required',
			});
		} else if (employees.length === 0) {
			setHasError(true);
			setErrorMessage({
				title: 'Error',
				body: 'Select one or more employee',
			});
		} else {
			let data = {
				id: 0,
				name: name,
				name_for_payslip: nameOnPaySlip, // name_in_pay_slip
				rate: rate,
				pay_basis: payBasis,
				wage_week_end_type: weekEnd,
				wage_type: type,
				holiday_date: holidayDate,
				employees: employees.map((e) => e.id), //  emp_ids
			};
			props.newWagePayItemCallback(data);
			handleClose();
		}
	};

	return (
		<Modal
			aria-labelledby="create-wage-pay-item"
			aria-describedby="create-wage-pay-item-description"
			className={classes.modal}
			open={props.isAddingWageItem}
			onClose={handleClose}
			hideBackdrop
			// closeAfterTransition
			// BackdropComponent={StyledBackdrop}
			// BackdropProps={{
			// 	timeout: 500,
			// }}
		>
			<Fade in={props.isAddingWageItem}>
				<div className={classes.paper}>
					<Wrapper>
						<Container>
							<Title>Create wage pay item</Title>
							<div
								onClick={handleClose}
								style={{ position: 'absolute', right: 20, top: 20, zIndex: 1, cursor: 'pointer' }}
							>
								<img src={xIcon} height={12} width={12} alt="close-icon" />
							</div>

							<StyledRow>
								<StyledInputContainer style={{ marginRight: 5 }}>
									<label>Name</label>
									<input
										placeholder="Enter Name"
										value={name}
										onChange={(e) => onChangeHandler(e, 0)}
									/>
								</StyledInputContainer>
								<StyledInputContainer style={{ marginLeft: 5 }}>
									<label>Name on payslip</label>
									<input
										placeholder="Enter value"
										value={nameOnPaySlip}
										onChange={(e) => onChangeHandler(e, 1)}
									/>
								</StyledInputContainer>
							</StyledRow>

							<StyledInputContainer>
								<label>Rate $</label>
								<input
									placeholder="Enter salary"
									type="text"
									pattern="[\+\0-9]{0,18}"
									value={rate}
									onChange={(e) => onChangeHandler(e, 2)}
								/>
							</StyledInputContainer>

							<StyledRow>
								<StyledBtnGroup>
									<label>Pay Basis</label>
									<div id="btn-row">
										<button
											onClick={() => payBasisClick(PAYBASIS.hourly)}
											style={
												payBasis === PAYBASIS.hourly
													? { background: '#f46587', color: 'white' }
													: {}
											}
										>
											Hourly
										</button>
										<button
											onClick={() => payBasisClick(PAYBASIS.salary)}
											style={
												payBasis === PAYBASIS.salary
													? { background: '#f46587', color: 'white' }
													: {}
											}
										>
											Salary
										</button>
									</div>
								</StyledBtnGroup>
								<StyledBtnGroup>
									<label>Type</label>
									<div id="btn-row">
										<button
											onClick={() => wageTypeClick(WAGEPAYTYPE.holiday)}
											style={
												type === WAGEPAYTYPE.holiday
													? { background: '#f46587', color: 'white' }
													: {}
											}
										>
											Holiday
										</button>
										<button
											onClick={() => wageTypeClick(WAGEPAYTYPE.weekend)}
											style={
												type === WAGEPAYTYPE.weekend
													? { background: '#f46587', color: 'white' }
													: {}
											}
										>
											Weekends
										</button>
									</div>
								</StyledBtnGroup>
							</StyledRow>

							{type === WAGEPAYTYPE.weekend ? (
								<StyledBtnGroup>
									<label>Weekend</label>
									<div id="btn-row">
										<button
											onClick={() => weekEndClick(WAGEWEEKENDTYPE.saturday)}
											style={
												weekEnd === WAGEWEEKENDTYPE.saturday
													? { background: '#f46587', color: 'white' }
													: {}
											}
										>
											Saturday
										</button>
										<button
											onClick={() => weekEndClick(WAGEWEEKENDTYPE.sunday)}
											style={
												weekEnd === WAGEWEEKENDTYPE.sunday
													? { background: '#f46587', color: 'white' }
													: {}
											}
										>
											Sunday
										</button>
									</div>
								</StyledBtnGroup>
							) : (
								<StyledInputContainer style={{ marginTop: 30, marginBottom: 34 }}>
									<label>Holiday Date</label>
									<div
										id="styled-date-holder"
										style={holidayDate ? { color: 'black' } : { color: '#7d7d7d9e' }}
										onClick={toggleCalendar}
									>
										{holidayDate ? holidayDate : 'Select Holiday'}
									</div>
								</StyledInputContainer>
							)}

							<StyledRow style={{ marginTop: 30 }}>
								<Title style={{ fontSize: 15 }}>Employee using this pay item</Title>
								<StyledAddBtn onClick={toggleEmployeeList}>
									Add Employees
									<div style={{ position: 'relative' }}>
										{isListOpen ? (
											<ListContainer ref={wrapperRef}>
												<div id="list-wrapper">
													<List
														width={180}
														height={employeeList.length * 35}
														rowHeight={35}
														rowCount={employeeList.length}
														rowRenderer={({ key, index, style }) => {
															let newStyle = { ...style };
															const empl = employeeList[index];
															if (employees.includes(empl)) {
																newStyle = { ...style, color: '#f46587' };
															}
															return (
																<ListItem
																	key={key}
																	onClick={() => handleClickEmployee(empl)}
																	style={newStyle}
																>
																	{empl.first_name} {empl.last_name}
																</ListItem>
															);
														}}
													/>
												</div>
											</ListContainer>
										) : (
											''
										)}
									</div>
								</StyledAddBtn>
							</StyledRow>

							<StyledCol>
								{employees.length > 0 ? (
									employees.map((e, key) => {
										return (
											<EmployeeItem key={key}>
												<div id="initials-container" style={{ background: `${e.color}` }}>
													{e.first_name.charAt(0).toUpperCase()}
													{e.last_name.charAt(0).toUpperCase()}
												</div>
												{e.first_name} {e.last_name}
												<div id="item-img-container" onClick={() => handleRemoveEmployee(e)}>
													<img src={coloredXicon} height={15} width={15} alt="close-icon" />
												</div>
											</EmployeeItem>
										);
									})
								) : (
									<StyledRow style={{ justifyContent: 'center' }}>
										<Title style={{ fontSize: 16 }}>No selected employees</Title>
									</StyledRow>
								)}
							</StyledCol>
						</Container>

						<UserRolesCalendar
							isOpen={isCalendarOpen}
							handleToggle={toggleCalendar}
							handleCallback={handleDateCallback}
						/>
						<Footer>
							<StyledButton onClick={handleSubmit}>Create Wage Item</StyledButton>
						</Footer>
						<AlertDialog
							alertHandler={hasError}
							dialogAlertMessage={errorMessage}
							setAlertHandler={setHasError}
						/>
					</Wrapper>
				</div>
			</Fade>
		</Modal>
	);
}
export default CreateWagePayItem;
