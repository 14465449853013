import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getDataFunction, setDataFunction, setAvatar } from 'utils/APImanager';

// Sub components and Data
import styled from 'styled-components';
import { sideNavData } from './sub-components/NavData';
import { apiUrl, imgUrl } from 'utils/Utility';
import withDashboardApp from 'components/withDashboardApp';
import DotLoader from '../TableLayoutPlanning/pages/SubComponents/DotLoader';
import TeamGrid from './TeamGrid';

// Icons
import userRolesIcon from 'assets/images/booking/user-roles/user-profile-icon.png';
import wabiIcon from 'assets/images/booking/user-roles/wabi-icon.png';
import Roster from './Roster';
// import addUserIcon from 'assets/images/booking/user-roles/add-user-icon.png';

const Wrapper = styled.div`
	position: relative;
	display: flex;
	width: 100%;
	height: 100vh;
	background: transparent;
	overflow: hidden;

	#user-roles-content {
		display: flex;
		flex-direction: row;
	}
`;

const SidePane = styled.div`
	// position: absolute;
	display: flex;
	flex-direction: column;
	min-height: 734px;
	height: 100%;
	min-width: 274px;
	background: #ffffff;
	box-shadow: -5px -5px 10px #2a343a;
`;

const RestoDetails = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	height: 110px;
	width: 100%;
	border-bottom: 2px solid #c6c6c8;
	padding-left: 10px;

	#profile-pic {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 55px;
		width: 55px;
		border-radius: 50%;
		margin-right: 15px;
		box-shadow: -1px 1px 3px #2a343a;

		img {
			border-radius: 50%;
			aspect-ratio: auto 4/3;
		}
	}

	#det-container {
		display: flex;
		flex-direction: column;
		h6 {
			font-size: 12px;
			font-weight: 700;
			color: #1c1c1e;
			margin-bottom: 7px;
		}

		p {
			width: 150px;
			font-size: 12px;
			color: #1c1c1e;
			line-height: 1.2;
		}
	}

	#icon-wrapper {
		display: flex;
		position: absolute;
		right: 10px;
		top: 10px;
	}
`;

const ListWrapper = styled.div`
	height: 100%;
	width: 100%;
	// list-style-type: none;
	// padding: 5px;
	margin-top: 50px;
`;

const ListItem = styled.div`
	display: flex;
	align-items: center;
	height: 40px;
	padding-left: 40px;
	color: #1c1c1e;
	font-size: 12px;
	font-weight: 700;
	cursor: pointer;
	transition: ease 200ms;
`;

const RightPane = styled.div`
	position: relative;
	// left: 50px;
	width: 100%;
	height: 100%;
	padding: 10px;

	#cs-container {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		background: #ffffffb8;
		font-size: 25px;
		font-weight: 700;
		color: #7b7b80;
	}
`;

function UserRoles() {
	const reducers = useSelector((state) => state.userReducers);
	// const [showNavbar, setShowNavbar] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [isUpdating, setIsUpdating] = useState(false);
	const [isCardUpdating, setIsCardUpdating] = useState(false);
	const [businessProfile, setBusinessProfile] = useState(null);
	const [selectedNav, setSelectedNav] = useState(sideNavData[0]);
	const [employees, setEmployees] = useState([]);
	const [employmentContract, setEmploymentContract] = useState([]);
	const [employmentRoles, setEmploymentRoles] = useState([]);
	const [wageItems, setWageItems] = useState([]);
	const [employeeReports, setEmployeeReports] = useState([]);
	const [groupSchedule, setGroupSchedule] = useState([]);
	const [groupScheduleDate, setgroupScheduleDate] = useState([]);
	const [employeeShifts, setEmployeeShifts] = useState([]);
	const [stations, setStations] = useState([]);

	const handleSelectOption = (option) => {
		setSelectedNav(option);
	};

	//GET Functions
	const getBusinessProfile = async () => {
		setIsLoading(true);
		const link = '/api/get_business_profile';
		const data = await getDataFunction(
			'',
			link,
			reducers.token,
			[getEmployees, getEmploymentContract, getEmploymentRoles, getWageItems, getGroupSchedule],
			[],
		);
		if (data) {
			setBusinessProfile(data.business_profile);
		}
	};

	const getEmployees = async () => {
		const link = '/api/get_employees';
		const data = await getDataFunction('', link, reducers.token, [], [setIsLoading, setIsUpdating]);
		if (data) {
			setEmployees(data.employees);
		}
	};

	const getEmploymentContract = async () => {
		const link = '/api/employment_contract';
		const data = await getDataFunction('', link, reducers.token, [], [setIsUpdating]);
		if (data) {
			setEmploymentContract(data.employment_contract);
		}
	};

	const getEmploymentRoles = async () => {
		const link = '/api/employment_role';
		const data = await getDataFunction('', link, reducers.token, [], [setIsUpdating]);
		if (data) {
			setEmploymentRoles(data.employment_role);
		}
	};

	const getWageItems = async () => {
		const link = '/api/get_wage_items';
		const data = await getDataFunction('', link, reducers.token, [], [setIsUpdating]);
		if (data) {
			setWageItems(data);
		}
	};

	const getEmployeeReports = async (id) => {
		setIsCardUpdating(true);
		let params = { employee_id: id };
		const link = '/api/get_employee_report';
		const data = await getDataFunction(params, link, reducers.token, [], [setIsCardUpdating]);
		if (data) {
			// console.log(data);
			setEmployeeReports(data);
		}
	};

	const getGroupSchedule = async (id) => {
		setIsLoading(true);
		let params = { employee_id: id };
		const link = '/api/get_group_schedule';
		const data = await getDataFunction(params, link, reducers.token, [], [setIsLoading]);
		if (data) {
			setGroupSchedule(data);
		}
	};

	const getDeletedSchedule = async (id) => {
		setIsLoading(true);
		let params = { roster_id: id };
		const link = '/api/get_deleted_schedules';
		const data = await getDataFunction(params, link, reducers.token, [], [setIsLoading]);
		if (data) {
			console.log(data);
		}
	};

	const getScheduleDate = async (params) => {
		setIsLoading(true);
		const link = '/api/get_schedule_date';
		const data = await setDataFunction(params, link, reducers.token, [], [setIsLoading]);
		setgroupScheduleDate(data);
	};

	const getEmployeeSchedule = async (params) => {
		setIsLoading(true);
		const link = '/api/get_employee_schedule';
		const data = await getDataFunction(
			params,
			link,
			reducers.token,
			[getStations],
			[setIsLoading, setIsUpdating],
		);
		if (data) {
			setEmployeeShifts(data.employee_shifts);
		}
	};

	const getStations = async () => {
		setIsLoading(true);
		const link = '/api/get_stations';
		const data = await setDataFunction('', link, reducers.token, [], [setIsLoading]);
		setStations(data);
	};

	//ADD and UPDATE Functions

	const addEmploymentContract = async (params) => {
		setIsUpdating(true);
		const link = '/api/employment_contract/add';
		const data = await setDataFunction(params, link, reducers.token, [getEmploymentContract]);
	};

	const addEmployementRole = async (params) => {
		setIsUpdating(true);
		const link = '/api/employment_role/add';
		const data = await setDataFunction(params, link, reducers.token, [getEmploymentRoles], []);
		console.log(data);
	};

	const uploadStaffAvatar = async (params) => {
		setIsUpdating(true);
		const link = '/api/employee_upload_photo';
		const data = await setAvatar(params, link, reducers.token, getEmployees);
		if (data) {
			// console.log(data);
		}
	};

	const addNewStaff = async (params) => {
		setIsLoading(true);
		const link = '/api/set_staffs';
		const data = await setDataFunction(params, link, reducers.token, [getEmployees], []);
		console.log(data);
	};

	const updateEmployeeDetails = async (params) => {
		setIsUpdating(true);
		const link = '/api/update_employee_details';
		const data = await setDataFunction(params, link, reducers.token, [getEmployees], []);
		console.log(data);
	};

	const addWageItem = async (params) => {
		setIsUpdating(true);
		const link = '/api/set_wage_item';
		const data = await setDataFunction(params, link, reducers.token, [], []);
		console.log(data);
	};

	const deleteStaff = async (params) => {
		setIsUpdating(true);
		const link = '/api/delete_staffs';
		const data = await setDataFunction(params, link, reducers.token, [getEmployees], []);
		console.log(data);
	};

	const createGroupSchedule = async (params) => {
		setIsLoading(true);
		const link = '/api/create_group_schedule';
		const data = await setDataFunction(params, link, reducers.token, [getGroupSchedule], []);
		console.log(data);
	};

	const createScheduleDate = async (params) => {
		setIsLoading(true);
		const link = '/api/create_schedule_date';
		const data = await setDataFunction(
			params,
			link,
			reducers.token,
			[getScheduleDate],
			[setIsLoading],
		);
		console.log(data);
	};

	const createStations = async (params) => {
		setIsLoading(true);
		const link = '/api/create_station';
		const data = await setDataFunction(params, link, reducers.token, [getStations], [setIsLoading]);
		console.log(data);
	};

	const createEmployeeSchedule = async (params) => {
		setIsUpdating(true);
		const link = '/api/create_employee_schedule';
		const data = await setDataFunction(params, link, reducers.token, [getEmployeeSchedule], []);
		console.log(data);
	};

	useEffect(() => {
		getBusinessProfile();
	}, []);

	return (
		<Fragment>
			<Wrapper>
				<SidePane>
					<RestoDetails>
						{businessProfile !== null ? (
							<>
								<div id="profile-pic">
									<img
										src={imgUrl + businessProfile.profile_pic}
										width={40}
										height={40}
										alt="icon"
									/>
								</div>
								<div id="det-container">
									<h6>{businessProfile.business_name}</h6>
									<p>{businessProfile.address}</p>
								</div>
								<div id="icon-wrapper">
									{/* <img src={wabiIcon} width={68} height={18} alt="icon" /> */}
									<img src={wabiIcon} width={57} height={16} alt="icon" />
								</div>
							</>
						) : (
							''
						)}
					</RestoDetails>
					<ListWrapper>
						{sideNavData.map((e, key) => {
							return (
								<ListItem
									key={key}
									onClick={() => handleSelectOption(e)}
									style={selectedNav.id === e.id ? { backgroundColor: '#f4f4f4' } : {}}
								>
									{e.title}
								</ListItem>
							);
						})}
					</ListWrapper>
				</SidePane>
				<RightPane>
					{isLoading ? (
						<div style={{ position: 'absolute', right: '50%', top: '50%' }}>
							<DotLoader />
						</div>
					) : (
						''
					)}
					{/* <div id="cs-container">COMING SOON</div> */}
					{selectedNav.id === 0 ? (
						<TeamGrid
							employees={employees}
							employmentContract={employmentContract}
							employmentRoles={employmentRoles}
							wageItems={wageItems}
							employeeReports={employeeReports}
							isUpdating={isUpdating}
							isCardUpdating={isCardUpdating}
							addNewStaff={addNewStaff}
							addEmploymentContract={addEmploymentContract}
							addEmployementRole={addEmployementRole}
							addWageItem={addWageItem}
							updateEmployeeDetails={updateEmployeeDetails}
							getEmployeeReports={getEmployeeReports}
							deleteStaff={deleteStaff}
							uploadStaffAvatar={uploadStaffAvatar}
						/>
					) : (
						<Roster
							isUpdating={isUpdating}
							employees={employees}
							groupSchedule={groupSchedule}
							groupScheduleDate={groupScheduleDate}
							employeeShifts={employeeShifts}
							employmentRoles={employmentRoles}
							stations={stations}
							createGroupSchedule={createGroupSchedule}
							getScheduleDate={getScheduleDate}
							getDeletedSchedule={getDeletedSchedule}
							createScheduleDate={createScheduleDate}
							getEmployeeSchedule={getEmployeeSchedule}
							createStations={createStations}
							createEmployeeSchedule={createEmployeeSchedule}
						/>
					)}
				</RightPane>
			</Wrapper>
		</Fragment>
	);
}

export default withDashboardApp(UserRoles);

// const NavContainer = styled.div`
// 	display: flex;
// 	color: white;
// 	font-family: sans-serif;
// 	font-weight: 800;
// 	margin: 30px 0 0 0px;
// 	user-select: none;
// `;

// const NavButtonWrapper = styled.div`
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	box-shadow: 5px 5px 10px #1a2024, -5px -5px 10px #2a343a;
// 	border-radius: 5px;
// 	padding: 0 12px;
// 	height: 50px;
// 	width: 161px;
// 	transition: 250ms;
// 	cursor: pointer;
// 	font-size: 15px;
// 	font-weight: bold;

// 	&:active {
// 		background: #1f262b;
// 		color: #ffffff;
// 		outline: none;
// 		outline-offset: none;
// 	}

// 	span {
// 		margin-left: 10px;
// 		white-space: nowrap;
// 		overflow: hidden;
// 	}
// `;

// const ListWrapper = styled.div`
// 	display: flex;
// 	height: 642px;
// 	width: 320px;
// 	margin: 0 15px;
// 	padding: 3px;
// `;

// const Content = styled.div`
// 	display: flex;
// 	width: 682px;
// 	height: 647px;
// `;

{
	/* <Fragment>
			<BookingNavBar
				showNavbar={showNavbar}
				showNavbarHandler={setShowNavbar}
				data={history.location.state}
			/>
			<Row style={{ marginRight: 0, marginLeft: 0, justifyContent: 'space-between' }}>
				<Col
					className="menu-btn-container"
					style={{ marginTop: 30, marginBottom: 15 }}
					sm={1}
					md={1}
					lg={1}
				>
					<div
						style={{ marginLeft: 10 }}
						className="btn-menu btn-menu--margin-top"
						onClick={toggleBookingNavBar}
					>
						<Media className="list-db" as="li">
							<svg
								style={{ marginTop: 12, marginLeft: 11, marginRight: 11 }}
								xmlns="http://www.w3.org/2000/svg"
								width="18"
								height="15"
								viewBox="0 0 18 15"
							>
								<g id="Group_347" data-name="Group 347" transform="translate(-0.333)">
									<rect
										id="Rectangle_42"
										data-name="Rectangle 42"
										width="18"
										height="3"
										rx="1.5"
										transform="translate(0.333)"
										fill="#fff"
									/>
									<rect
										id="Rectangle_43"
										data-name="Rectangle 43"
										width="18"
										height="3"
										rx="1.5"
										transform="translate(0.333 6)"
										fill="#fff"
									/>
									<rect
										id="Rectangle_44"
										data-name="Rectangle 44"
										width="18"
										height="3"
										rx="1.5"
										transform="translate(0.333 12)"
										fill="#fff"
									/>
								</g>
							</svg>
						</Media>
					</div>
				</Col>
				<Col sm={7} md={7} lg={7} style={{ padding: 0, margin: 0 }}>
					<NavContainer>
						<NavButtonWrapper>
							{userRoles.icon}
							<span>{userRoles.title}</span>
						</NavButtonWrapper>
					</NavContainer>
				</Col>
				<Col sm={4} md={4} lg={4}>
					<div className="tlp-saveIcon-wrapper">
						<img src={addUserIcon} width={22} height={22} alt="add user" />
					</div>
				</Col>
			</Row>
			<Container style={{ background: 'transparent' }} fluid>
				<Wrapper>
					<div id="user-roles-content">
						<ListWrapper>
							<ListHeaderDropDown title="Owner" />
						</ListWrapper>

						<Content></Content>
					</div>
				</Wrapper>
			</Container>
</Fragment> */
}
