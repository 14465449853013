import React, { useState } from 'react';

function PartySizeSelection(props) {
	const [selectedOption, setSelectedOption] = useState(0);

	const partySize = props.partySize;

	const selectOption = (e) => {
		let option = parseInt(e.target.value);
		setSelectedOption(option);
		props.selectedPartySize(option);
	};

	return (
		<div className="party-size-selection">
			{Array.from(Array(partySize), (e, i) => {
				return (
					<div
						key={Math.random()}
						className={
							selectedOption === i + 1
								? 'party-size-item party-size-item--selected'
								: 'party-size-item'
						}
					>
						<input
							key={Math.random()}
							onChange={selectOption}
							id={i + 1 + '-rad'}
							type="radio"
							value={i + 1}
							checked={selectedOption === i + 1}
							name="radioBtn"
						/>
						<label key={Math.random()} htmlFor={i + 1 + '-rad'}>
							{i + 1}
						</label>
					</div>
				);
			})}
		</div>
	);
}

export default PartySizeSelection;
