import React from 'react';

export const turnTimeData = [
	{
		option: '1 h 0m',
		value: 3600,
	},
	{
		option: '1 h 15m',
		value: 4500,
	},
	{
		option: '1 h 30m',
		value: 5400,
	},
	{
		option: '1 h 45m',
		value: 6300,
	},
	{
		option: '2 h 0m',
		value: 7200,
	},
	{
		option: '2 h 15m',
		value: 8100,
	},
	{
		option: '2 h 30m',
		value: 9000,
	},
	{
		option: '2 h 45m',
		value: 9900,
	},
	{
		option: '3 h 0m',
		value: 10800,
	},
	{
		option: '3 h 15m',
		value: 11700,
	},
	{
		option: '3 h 30m',
		value: 12600,
	},
	{
		option: '3 h 45m',
		value: 13500,
	},
];
export const minutesOption = [
	{
		option: 'No Holding',
		value: 0,
	},
	{
		option: '5 minutes',
		value: 300,
	},
	{
		option: '10 minutes',
		value: 600,
	},
	{
		option: '20 minutes',
		value: 1200,
	},
	{
		option: '25 minutes',
		value: 1500,
	},
	{
		option: '30 minutes',
		value: 1800,
	},
];

export const largePartyOption = [
	{
		option: 5 + '+',
		value: 5,
	},
	{
		option: 6 + '+',
		value: 6,
	},
	{
		option: 7 + '+',
		value: 7,
	},
	{
		option: 8 + '+',
		value: 8,
	},
	{
		option: 9 + '+',
		value: 9,
	},
	{
		option: 10 + '+',
		value: 10,
	},
	{
		option: 11 + '+',
		value: 11,
	},
	{
		option: 12 + '+',
		value: 12,
	},
];

export const bookingSizeOption = [
	{
		option: 1,
		value: 1,
	},
	{
		option: 2,
		value: 2,
	},
	{
		option: 3,
		value: 3,
	},
	{
		option: 4,
		value: 4,
	},
	{
		option: 5,
		value: 5,
	},
	{
		option: 6,
		value: 6,
	},
	{
		option: 7,
		value: 7,
	},
	{
		option: 8,
		value: 8,
	},
	{
		option: 9,
		value: 9,
	},
	{
		option: 10,
		value: 10,
	},
	{
		option: 11,
		value: 11,
	},
	{
		option: 12,
		value: 12,
	},
];

export const bookingSizeOption2 = [
	{
		option: 1,
		value: 1,
	},
	{
		option: 2,
		value: 2,
	},
	{
		option: 3,
		value: 3,
	},
	{
		option: 4,
		value: 4,
	},
	{
		option: 5,
		value: 5,
	},
	{
		option: 6,
		value: 6,
	},
	{
		option: 7,
		value: 7,
	},
	{
		option: 8,
		value: 8,
	},
	{
		option: 9,
		value: 9,
	},
	{
		option: 10,
		value: 10,
	},
	{
		option: 11,
		value: 11,
	},
	{
		option: 12,
		value: 12,
	},
];
