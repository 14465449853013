import react, { useState } from 'react';
import { BookingNavBarData } from './BookingNavBarData';
import { useHistory } from 'react-router-dom';
import sampleImg from '../../../../assets/images/booking/booking-navbar/sample-img.png';
import exitLogo from '../../../../assets/images/booking/booking-navbar/exit-btn-logo.png';

function BookingNavBar(props) {
	const history = useHistory();

	const toggleShowNavBar = () => props.showNavbarHandler(!props.showNavbar);

	return (
		<div
			className={
				props.showNavbar === true ? 'bookingNavbar-container active' : 'bookingNavbar-container'
			}
		>
			<div style={{ marginTop: '20px' }} onClick={toggleShowNavBar}>
				<img src={exitLogo} width="70px" height="70px" alt="checker" />
			</div>
			<div className="navbar-header">
				<img src={sampleImg} width="100px" height="100px" alt="checker" />
				<span>SANTONIO PIZZA</span>
			</div>
			<ul className="sidebar-list" onClick={toggleShowNavBar}>
				{BookingNavBarData.map((item, key) => {
					return (
						<li
							className={history.location.pathname === item.link ? 'nav-text active' : 'nav-text'}
							key={key}
							onClick={() => {
								history.push(item.link, props.data);
							}}
						>
							<div style={{ flex: '20%', display: 'Grid', placeItems: 'center' }}>{item.icon}</div>
							<div style={{ flex: '80%' }}>{item.title} </div>
						</li>
					);
				})}
			</ul>
		</div>
	);
}

export default BookingNavBar;
