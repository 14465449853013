import React from 'react';
import { getShiftIndex, filterShifts } from '../../../../utils/Utility';
import moment from 'moment';

function ShiftSelector(props) {
	const selectShift = () => {
		const filteredShiftsData = filterShifts(props.shifts, moment(props.selectedDate).isoWeekday());
		let idx = getShiftIndex('id', props.selectedShift.id, filteredShiftsData);
		if (idx !== -1) {
			props.setSelectedDateChanged(true);
			if (idx === filteredShiftsData.length - 1) {
				props.selectedShiftHandler(filteredShiftsData[0]);
			} else {
				props.selectedShiftHandler(filteredShiftsData[idx + 1]);
			}
		}
	};

	return (
		<div style={{ margin: '0 15px' }}>
			<div className="bg-date-shift" onClick={() => selectShift()}>
				<label>{props.selectedShift == null ? 'No shift' : props.selectedShift.name}</label>
			</div>
		</div>
	);
}

export default ShiftSelector;
