import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { apiUrl } from 'utils/Utility';
import { Container, Col } from 'react-bootstrap';
import styled from 'styled-components';
import axios from 'axios';
import moment from 'moment';

//Icons
import saveIcon from 'assets/images/booking/table-layout-planning/save-icon.png';
import addIcon from 'assets/images/booking/create-booking/icon-add-white.png';
import rightArrow from 'assets/images/booking/right-arrow.png';

//Components
import { turnTimeData } from './SubComponents/PickerComponentData';
import SpecialDaysTimeTable from './SubComponents/SpecialDaysTimeTable';
import SpecialDaysToggleSwitch from './SubComponents/SpecialDaysToggleSwitch';
import DotLoader from './SubComponents/DotLoader';
import TableScheduleSwitch from './SubComponents/TableScheduleSwitch';
import SetSpecialDateModal from './SubComponents/SetSpecialDateModal';
import PickerComponent from './SubComponents/PickerComponent';

const Wrapper = styled.div`
	display: inline-flex;
	height: fit-content;
	width: 100%;
	padding: 10px;
`;

const StyledContainer = styled(Container)`
	display: inline-flex;
	height: 100%;
	width: 100%;
	overflow: hidden;
`;

const ListContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 320px;
	height: 100%;
	// background: black;
	margin-right: 15px;
	padding: 5px;
`;

const Details = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	min-width: 682px;
	height: 650px;
	background: #222a2f;
	box-shadow: 5px 5px 10px #1a2024, -5px -5px 10px #2a343a;
	border-radius: 0.8em;
	margin-right: 5px;
	overflow: hidden;
`;

const DetContent = styled.div`
	position: absolute;
	display: flex;
	padding-top: 10px;
	height: 100%;
	width: 100%;
	opacity: ${(props) => (props.isSelected ? '' : '0')};
	flexdirection: row;
	transition: ease-in-out 100ms;
`;

const Controls = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 230px;
	height: 97%;
	padding: 10px 15px 0 15px;
	border-top-left-radius: 0.8em;
	border-bottom-left-radius: 0.8em;
	user-select: none;

	h4 {
		font-size: 19px;
		font-weight: 600;
		margin-bottom: 10px;
	}
	p {
		font-size: 12px;
		font-weight: 500;
		color: #999999;
		margin-bottom: 40px;
	}
`;

const DaysContainer = styled.div`
	position: relative;
	display: flex;
	height: 70px;
	width: 314px;
	background: ${(props) => (props.isSelected ? '#6C7D88' : '#3a444b')};
	border-radius: 5px;
	user-select: none;
	cursor: pointer;
	margin: 2.5px 0;
	&:hover {
		background: #6d828f;
	}

	&:active {
		// cursor: wait;
		background: #6c7d88;
	}

	#colored-span {
		position: absolute;
		top: 0;
		width: 7px;
		height: 100%;
		background: ${(props) => (props.status ? '#85ffbd' : '#fe7d86')};
		border-radius: 5px;
	}

	h4 {
		font-size: 18px;
		font-weight: bold;
		color: #ffffff;
		// margin-bottom: 2px;
	}

	p {
		font-size: 12px;
		color: #bcbcbc;
	}
`;

const AddShiftButton = styled.div`
	position: absolute;
	bottom: 265px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	width: 160px;
	padding: 10px;
	box-shadow: 5px 5px 10px #1a2024, -6px -1px 10px #2a343a;
	cursor: pointer;
	border-radius: 10px;
	user-select: none;
	color: #bcbcbc;
	font-size: 14px;
	font-weight: 700;

	img {
		-webkit-user-drag: none;
		-khtml-user-drag: none;
		-moz-user-drag: none;
		-o-user-drag: none;
		user-drag: none;
	}

	&:hover {
		background: #1f262b73;
	}

	&:active {
		background: #1f262b;
		color: #ffffff;
		outline: none;
		outline-offset: none;
	}

	span {
		margin-right: 10px;
	}
`;

const DeleteButton = styled.div`
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	width: 195px;
	bottom: 0;
	padding: 10px;
	box-shadow: 5px 5px 10px #1a2024, -6px -1px 10px #2a343a;
	cursor: pointer;
	border-radius: 10px;
	user-select: none;
	color: #bcbcbc;
	font-size: 14px;
	font-weight: 700;

	img {
		-webkit-user-drag: none;
		-khtml-user-drag: none;
		-moz-user-drag: none;
		-o-user-drag: none;
		user-drag: none;
	}

	&:hover {
		background: #1f262b73;
	}

	&:active {
		background: #1f262b;
		color: #ffffff;
		outline: none;
		outline-offset: none;
	}
`;

const AddDayButton = styled.div`
	position: absolute;
	top: 30px;
	right: 70px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
	width: 50px;
	padding: 10px;
	box-shadow: 5px 5px 10px #1a2024, -6px -1px 10px #2a343a;
	cursor: pointer;
	border-radius: 50%;
	user-select: none;
	color: #bcbcbc;
	font-size: 14px;
	font-weight: 700;

	img {
		-webkit-user-drag: none;
		-khtml-user-drag: none;
		-moz-user-drag: none;
		-o-user-drag: none;
		user-drag: none;
	}

	&:hover {
		background: #1f262b73;
	}

	&:active {
		background: #1f262b;
		color: #ffffff;
		outline: none;
		outline-offset: none;
	}
`;

const SaveButton = styled.div`
	position: absolute;
	top: 30px;
	right: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
	width: 50px;
	padding: 10px;
	box-shadow: 5px 5px 10px #1a2024, -6px -1px 10px #2a343a;
	cursor: pointer;
	border-radius: 50%;
	user-select: none;
	color: #bcbcbc;
	font-size: 14px;
	font-weight: 700;

	img {
		-webkit-user-drag: none;
		-khtml-user-drag: none;
		-moz-user-drag: none;
		-o-user-drag: none;
		user-drag: none;
	}

	&:hover {
		background: #1f262b73;
	}

	&:active {
		background: #1f262b;
		color: #ffffff;
		outline: none;
		outline-offset: none;
	}
`;

const ShiftDetails = styled.div`
	display: flex;
	width: 300px;
	height: 100%;
	flex-direction: column;
	width: 270px;
	height: 100%;
	user-select: none;
	margin: 0 30px 0 0;

	#fragment {
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
		color: #bcbcbc;
		padding: 0 15px;
		width: 100%;
		height: 20px;
		margin-bottom: 10px;

		h4 {
			font-size: 16px;
			font-weight: 700;
		}

		label {
			margin-top: 0px;
			color: #707070;
			font-size: 16px;
			font-weight: bold;
			padding: 5px;
		}

		input {
			text-align: end;
			width: 100%;
			background: transparent;
			color: white;
			padding: 5px;
			font-size: 1em;
			transition: 2ms;
			cursor: default;
		}

		input:focus {
			text-align: start;
			box-shadow: inset 5px 5px 10px #1a2024, inset -5px -5px 10px #2a343a;
			border-radius: 5px;
			padding: 5px;
			transition: 2ms;
		}
	}

	p {
		padding: 5px 20px;
		font-size: 12px;
	}
`;

const LineSeparator = styled.div`
	width: 230px;
	height: 3px;
	border-radius: 25px;
	background: #222a2f;
	box-shadow: inset 1px 1px 2px #1a2024, inset -1px -1px 2px #2a343a;
`;

const SwitchWrapper = styled.div`
	max-height: 165px;
	overflow-y: auto;
	::-webkit-scrollbar {
		width: 3px;
		height: 3px;
		background-color: #222a2f;
	}
	::-webkit-scrollbar-thumb {
		width: 1px;
		height: 1px;
		border-radius: 20px;
		background-color: #2a343a;
	}
`;

const SwitchContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin: 15px 20px;
	label {
		width: 69px;
		height: 30px;
	}
	span {
		width: 69px;
		height: 30px;
		box-shadow: 0px 0px 4px #1a2024, -3px -1px 5px #2a343a,
			inset 0px 0px 20px 2px rgb(255 255 255 / 11%);
		border-radius: 50px;
		:before {
			width: 20px;
			height: 19px;
			top: -0.3px;
			left: 1.9px;
		}
	}
	input:checked + span:before {
		transform: translateX(38px);
	}
	h4 {
		font-size: 14px;
		font-weight: 700;
	}
`;

const PickerWrapper = styled.div`
	min-width: 249px;
	justify-content: center;
	margin: 15px 5px 0 1px;

	select {
		background: #222a2f;
	}
`;

const DeleteShiftButton = styled.div`
	position: absolute;
	bottom: 18px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	width: 98%;
	padding: 10px;
	box-shadow: 5px 5px 10px #1a2024, -6px -1px 10px #2a343a;
	cursor: pointer;
	border-radius: 10px;
	user-select: none;
	color: #bcbcbc;
	font-size: 14px;
	font-weight: 700;
	background: #222a2f;

	img {
		-webkit-user-drag: none;
		-khtml-user-drag: none;
		-moz-user-drag: none;
		-o-user-drag: none;
		user-drag: none;
	}

	&:hover {
		background: #1f262b73;
	}

	&:active {
		background: #1f262b;
		color: #ffffff;
		outline: none;
		outline-offset: none;
	}

	span {
		margin-right: 10px;
	}
`;

function TableSpecialDays(props) {
	const reducers = useSelector((state) => state.userReducers);
	const [floors, setFloors] = useState(props.floors);
	const [isInit, setIsInit] = useState(false);
	const [specialDays, setSpecialDays] = useState([]);
	const [selectedDay, setSelectedDay] = useState('');
	const [status, setStatus] = useState(0);
	const [recurring, setRecurring] = useState(0);
	const [isTyping, setIsTyping] = useState(false);
	const [isDaySelected, setIsDaySelected] = useState(false);
	const [selectedShift, setselectedShift] = useState('');
	const [isShiftSelected, setIsShiftSelected] = useState(false);
	const [shiftName, setShiftName] = useState('');
	const [modalOpen, setModalOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const handleUpdateSpDays = (day) => {
		let days = specialDays;
		let idx = days.findIndex((e) => e.id === selectedDay.id);
		days.splice(idx, 1, day);
		setSpecialDays(days);
		// console.log(days);
	};

	const handleSelectDay = (day) => {
		if (!isDaySelected) {
			setSelectedDay(day);
			setStatus(day.status);
			setRecurring(day.is_recurring);
			setselectedShift(day.shifts);
			setIsDaySelected(true);
		} else {
			if (day.id !== selectedDay.id) {
				setSelectedDay(day);
				setStatus(day.status);
				setRecurring(day.is_recurring);
				setselectedShift(day.shifts);
				setIsShiftSelected(false);
			} else {
				setSelectedDay('');
				setIsDaySelected(false);
			}
		}
	};

	const handleAddSpecialDay = () => {
		setModalOpen(!modalOpen);
	};

	const handleDeleteSpecialDay = () => {
		let days = specialDays;
		let day = selectedDay;
		let idx = specialDays.findIndex((e) => e.id === day.id);
		day = { ...day, deleted: true };
		days.splice(idx, 1, day);
		let params = {
			special_days: days.map((e) => {
				return {
					deleted: e.deleted !== undefined ? e.deleted : false,
					is_recurring: e.is_recurring,
					type: e.type,
					name: e.name,
					end_date: moment(e.end_date).format('yyyy-MM-DD'),
					id: e.id,
					shifts: e.shifts,
					start_date: moment(e.start_date).format('yyyy-MM-DD'),
					status: e.status,
				};
			}),
		};
		setSpecialDays(days);
		setSelectedDay('');
		setIsDaySelected(false);
		setIsLoading(true);
		setIsShiftSelected(false);
		saveSpecialDays(params);
		console.log(params);
	};

	const newSpeciaDayCallback = (data) => {
		let days = specialDays;
		days.push(data);
		let params = {
			special_days: days.map((e) => {
				return {
					deleted: e.deleted !== undefined ? e.deleted : false,
					is_recurring: e.is_recurring,
					type: e.type,
					name: e.name,
					end_date: moment(e.end_date).format('yyyy-MM-DD'),
					id: e.id,
					shifts: e.shifts,
					start_date: moment(e.start_date).format('yyyy-MM-DD'),
					status: e.status,
				};
			}),
		};
		console.log(params);
		saveSpecialDays(params);
	};

	const handleSubmit = () => {
		let days = specialDays;
		let params = {
			special_days: days.map((e) => {
				return {
					deleted: e.deleted !== undefined ? e.deleted : false,
					is_recurring: e.is_recurring,
					type: e.type,
					name: e.name,
					end_date: moment(e.end_date).format('yyyy-MM-DD'),
					id: e.id,
					shifts: e.shifts,
					start_date: moment(e.start_date).format('yyyy-MM-DD'),
					status: e.status,
				};
			}),
		};
		setIsLoading(true);
		setIsDaySelected(false);
		setIsShiftSelected(false);
		// console.log(specialDays);
		saveSpecialDays(params);
	};

	const handleStatusChange = () => {
		let day = selectedDay;
		let stat;
		if (status > 0) {
			stat = 0;
			setStatus(0);
		} else {
			stat = 1;
			setStatus(1);
		}
		day = { ...day, status: stat };
		setSelectedDay(day);
		handleUpdateSpDays(day);
	};

	const handleRecurringChange = () => {
		let day = selectedDay;
		let rec;
		if (recurring > 0) {
			rec = 0;
			setRecurring(0);
		} else {
			rec = 1;
			setRecurring(1);
		}
		day = { ...day, is_recurring: rec };
		setSelectedDay(day);
		handleUpdateSpDays(day);
	};

	const selectShiftCallback = (shift) => {
		if (shift !== undefined) {
			setselectedShift(shift);
			setShiftName(shift.name);
			isShiftSelectedHandler(shift);
		} else {
			isShiftSelectedHandler(shift);
		}
	};

	const isShiftSelectedHandler = (shift) => {
		if (shift !== undefined) {
			setIsShiftSelected(true);
		} else {
			setIsShiftSelected(false);
		}
	};

	const handleAddShift = () => {
		let day = selectedDay;
		let shifts = selectedDay.shifts;
		let newShiftDefault = {
			shift_color: '#FE7D86',
			end_time: 28800,
			floors: [1],
			turn_time: 7200,
			name: 'New Shift',
			id: 0,
			flow_controls: [],
			start_time: 21600,
		};
		shifts.push(newShiftDefault);
		day = { ...day, shifts: shifts };
		setSelectedDay(day);
		handleUpdateSpDays(day);
		setIsShiftSelected(false);
		setselectedShift(newShiftDefault);
	};

	const handleInput = (name) => {
		setShiftName(name);
	};

	const handleShiftNameUpdate = () => {
		if (isTyping) {
			setIsTyping(false);
			let day = selectedDay;
			let shift = selectedShift;
			let idx = day.shifts.findIndex((e) => e.id === shift.id);
			shift = { ...shift, name: shiftName };
			day.shifts.splice(idx, 1, shift);
			setselectedShift(shift);
			handleUpdateSpDays(day);
			setIsShiftSelected(false);
		} else {
			setIsTyping(true);
		}
	};

	const handleShiftFloorChange = (check, id) => {
		let day = selectedDay;
		let shift = selectedShift;
		if (check) {
			shift.floors.push(id);
		} else {
			let idx = shift.floors.findIndex((e) => e === id);
			shift.floors.splice(idx, 1);
		}

		// console.log(shift.id);
		setselectedShift(shift);
		let idx = day.shifts.findIndex((e) => e.id === shift.id);
		day.shifts.splice(idx, 1, shift);
		setSelectedDay(day);
		setIsShiftSelected(false);
		handleUpdateSpDays(day);
		// console.log(day);
	};

	const handleTurnTime = (int) => {
		let day = selectedDay;
		let shift = selectedShift;
		let idx = day.shifts.findIndex((e) => e.id === shift.id);
		shift = { ...shift, turn_time: parseInt(int) };
		day.shifts.splice(idx, 1, shift);
		// setIsShiftSelected(false);
		setSelectedDay(day);
		setselectedShift(shift);
		handleUpdateSpDays(day);
	};

	const updateSpecialDayShiftTime = (shift) => {
		let day = selectedDay;
		let idx = day.shifts.findIndex((e) => e.id === shift.id);
		day.shifts.splice(idx, 1, shift);
		setselectedShift(shift);
		setSelectedDay(day);
		handleUpdateSpDays(day);
	};

	const deleteShift = () => {
		let day = selectedDay;
		let shift = selectedShift;
		let idx = day.shifts.findIndex((e) => e.id === shift.id);
		shift = { ...shift, deleted: true };
		day.shifts.splice(idx, 1, shift);
		setSelectedDay(day);
		let days = specialDays;
		let Didx = days.findIndex((e) => e.id === selectedDay.id);
		days.splice(Didx, 1, day);
		setSpecialDays(days);
		setIsShiftSelected(false);
		let params = {
			special_days: days.map((e) => {
				return {
					deleted: e.deleted !== undefined ? e.deleted : false,
					is_recurring: e.is_recurring,
					type: e.type,
					name: e.name,
					end_date: moment(e.end_date).format('yyyy-MM-DD'),
					id: e.id,
					shifts: e.shifts,
					start_date: moment(e.start_date).format('yyyy-MM-DD'),
					status: e.status,
				};
			}),
		};
		saveSpecialDays(params);
		setIsLoading(true);
	};

	const getSpecialDay = async () => {
		try {
			const response = await axios({
				url: `${apiUrl}/api/special_days`,
				method: 'POST',
				data: {},
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${reducers.token}`,
				},
			});

			if (response) {
				const { status } = response;
				const { data } = response.data;
				const { special_days } = data;
				const day = special_days[0];
				if (status === 200) {
					if (special_days.length > 0) {
						setSpecialDays(special_days);
						setSelectedDay(day);
					}

					setIsInit(true);
					setIsLoading(false);
					setIsDaySelected(false);
				}
			}
		} catch (err) {
			console.log('Error: ', err);
		}
	};

	const saveSpecialDays = async (params) => {
		try {
			const response = await axios({
				url: `${apiUrl}/api/special_day/bulk_update`,
				method: 'POST',
				data: params,
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${reducers.token}`,
				},
			});

			if (response) {
				const { status } = response;
				const { data } = response.data;
				if (status === 200) {
					console.log(data);
					getSpecialDay();
				}
			}
		} catch (err) {
			console.log('Error: ', err);
		}
	};

	useEffect(() => {
		if (!isInit) {
			getSpecialDay();
		}
	}, [isInit]);

	useEffect(() => {
		if (!selectedDay.status) {
			if (isShiftSelected) {
				setIsShiftSelected(!isShiftSelected);
			}
		}
	}, [selectedDay]);

	return (
		<StyledContainer fluid>
			<Col sm={3} md={3} lg={3} style={{ margin: '0 5px 0 30px', padding: 0 }}>
				<ListContainer>
					{specialDays.length > 0 ? (
						specialDays.map((days) => {
							return (
								<DaysContainer
									key={days.id}
									onClick={() => handleSelectDay(days)}
									isSelected={isDaySelected ? (selectedDay.id === days.id ? true : false) : false}
									status={days.status > 0 ? true : false}
								>
									<span id="colored-span" />
									<div style={{ margin: '0 5px', padding: 10 }}>
										<h4>{days.name}</h4>
										<p>{moment(days.start_date, 'YYYY/MM/DD').format('dddd, DD MMMM YYYY')}</p>
										<p>Repeat Annually</p>
									</div>
								</DaysContainer>
							);
						})
					) : (
						<div
							style={{
								position: 'relative',
								width: '100%',
								height: '647px',
								justifyContent: 'center',
							}}
						>
							{isInit ? (
								<h4 style={{ textAlign: 'center', userSelect: 'none' }}>
									{' '}
									No available Special Day
								</h4>
							) : (
								<DotLoader />
							)}
						</div>
					)}
				</ListContainer>
			</Col>

			<Col sm={9} md={9} lg={9} style={{ margin: 0, padding: '10px 40px 10px 40px' }}>
				<Details>
					{isLoading ? (
						<div style={{ position: 'relative', width: '100%', height: '647px' }}>
							<DotLoader />
						</div>
					) : (
						''
					)}

					<DetContent isSelected={isDaySelected}>
						<Controls>
							<h4>Status</h4>
							<SpecialDaysToggleSwitch
								checked={status > 0 ? true : false}
								onChange={handleStatusChange}
								tvalue={'OPEN'}
								fvalue={'CLOSED'}
							/>
							{status ? (
								<p>This day is Open and accepting &nbsp; visits.</p>
							) : (
								<p>This day is Closed and not accepting visits.</p>
							)}

							<h4>Frequency</h4>
							<SpecialDaysToggleSwitch
								checked={recurring > 0 ? true : false}
								onChange={handleRecurringChange}
								tvalue={'REPEAT'}
								fvalue={'ONCE'}
							/>

							{recurring ? (
								<p>This special day will repeat annually.</p>
							) : (
								<p>This special day will be archived after the day.</p>
							)}

							<AddShiftButton onClick={handleAddShift}>
								<span>
									<img src={addIcon} width={12} height={12} />
								</span>
								Add new shift
							</AddShiftButton>
							<DeleteButton onClick={handleDeleteSpecialDay}>Delete Special Day</DeleteButton>
						</Controls>

						<SpecialDaysTimeTable
							selectShiftCallback={selectShiftCallback}
							selectedShift={selectedShift}
							shifts={selectedDay.shifts}
							status={selectedDay.status}
							isShiftSelected={isShiftSelected}
							updateSpecialDayShiftTime={updateSpecialDayShiftTime}
						/>
						{/* <div style={{ position: 'relative', width: 'fit-content', overflow: 'hidden' }}> */}
						<ShiftDetails
							style={
								isShiftSelected
									? { position: 'relative', right: 0 }
									: { position: 'absolute', right: '-100%' }
							}
						>
							{isShiftSelected ? (
								<>
									<span id="fragment">
										<h4>SHIFT DETAILS</h4>
										<div
											style={{ position: 'absolute', right: '10px', cursor: 'pointer' }}
											onClick={() => setIsShiftSelected(false)}
										>
											<img src={rightArrow} width={9.2} height={16} />
										</div>
									</span>

									<span style={{ display: 'flex', justifyContent: 'center', height: '5px' }}>
										<LineSeparator />
									</span>

									<span id="fragment" style={{ marginTop: 15 }}>
										{!isTyping ? <label>NAME</label> : ''}

										<input
											type="Text"
											onFocus={handleShiftNameUpdate}
											onBlur={handleShiftNameUpdate}
											onChange={(e) => handleInput(e.target.value)}
											value={shiftName}
											style={{ cursor: 'pointer' }}
										/>
									</span>

									<p>
										Shift time:{' '}
										{moment(new Date())
											.startOf('day')
											.seconds(selectedShift.start_time)
											.format('HH:mm')}{' '}
										-{' '}
										{moment(new Date())
											.startOf('day')
											.seconds(selectedShift.end_time)
											.format('HH:mm')}{' '}
									</p>

									<span
										id="fragment"
										style={{
											display: 'flex',
											justifyContent: 'center',
											margin: '20px 0 10px 0',
											height: '5px',
										}}
									>
										<LineSeparator />
									</span>

									<span
										id="fragment"
										style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}
									>
										<h4 style={{ color: '#707070' }}>DINNING AREA</h4>
									</span>

									<SwitchWrapper>
										{floors.length > 0
											? floors.map((e) => {
													return (
														<SwitchContainer key={Math.random()}>
															<h4>{e.floor_name}</h4>
															<TableScheduleSwitch
																// key={Math.random()}
																selectedShift={selectedShift}
																identifier={e.id}
																toggleSwitchCallback={handleShiftFloorChange}
															/>
														</SwitchContainer>
													);
											  })
											: ''}
									</SwitchWrapper>

									<span
										style={{
											display: 'flex',
											justifyContent: 'center',
											margin: '0 0 5px 0',
											height: '5px',
										}}
									>
										<LineSeparator />
									</span>

									<span id="fragment" style={{ flexDirection: 'column', marginTop: 15 }}>
										<h4>TURN TIME</h4>
									</span>

									<PickerWrapper>
										<PickerComponent
											data={turnTimeData}
											updateValue={handleTurnTime}
											initValue={selectedShift.turn_time}
											style={{ borderRadius: '10px', width: '100%' }}
										/>
									</PickerWrapper>
								</>
							) : (
								''
							)}

							<DeleteShiftButton onClick={deleteShift}>Delete Shift</DeleteShiftButton>
						</ShiftDetails>
						{/* </div> */}
					</DetContent>
				</Details>
			</Col>
			<SetSpecialDateModal
				newSpeciaDayCallback={newSpeciaDayCallback}
				isOpen={modalOpen}
				setModalOpen={setModalOpen}
			/>
			<AddDayButton onClick={handleAddSpecialDay}>
				<img src={addIcon} width={22} height={22} />{' '}
			</AddDayButton>
			<SaveButton onClick={handleSubmit}>
				<img src={saveIcon} width={22} height={22} />{' '}
			</SaveButton>
		</StyledContainer>
	);
}

export default TableSpecialDays;
