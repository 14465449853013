import React, { useState } from 'react';
import styled from 'styled-components';
import { createGuestParameters } from 'utils/RequestBuilder';
import DotLoader from '../TableLayoutPlanning/pages/SubComponents/DotLoader';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 10px;
	margin-right: 10px;
	opacity: ${(props) => (props.isNotesActive ? '1px' : '0')};
	transition: ease-in-out 100ms;

	h4 {
		margin: 15px 0;
		font-size: 14px;
		font-weight: 700;
		color: #bcbcbc;
	}

	span {
		position: relative;
		display: flex;
		justify-content: space-between;
		background: #222a2f;
	}
`;

const Label = styled.label`
	margin: 10px 0;
	font-size: 16px;
	font-weight: 700;
	color: #707070;
`;

const Input = styled.input`
	text-align: start;
	background: #222a2f;
	width: 75%;
	color: white;
	padding: 10px;
	font-size: 1em;
	transition: 200ms;
	cursor: text;

	&:focus {
		width: 100%;
		text-align: start;
		box-shadow: inset 5px 5px 10px #1a2024, inset -5px -5px 10px #2a343a;
		border-radius: 5px;
		background: #222a2f;
		padding: 10px;
		transition: 200ms;
	}
`;

function GuestBookNotesTab(props) {
	const [isTyping, setIsTyping] = useState({
		address: false,
		cityTown: false,
		state: false,
		postCode: false,
		country: false,
	});

	const handleFocus = (e) => {
		setIsTyping({ ...isTyping, [e.target.name]: true });
	};

	const handleBlur = (e) => {
		let guest = props.selectedGuest;
		setIsTyping({ ...isTyping, [e.target.name]: false });
		props.setProfileTabIsLoading(true);
		props.guestUpdate(createGuestParameters(guest));
	};

	const updateAddress = (e) => {
		let guest = props.selectedGuest;
		guest = { ...guest, address: e.target.value };
		props.setSelectedGuest(guest);
	};

	const updateCity = (e) => {
		let guest = props.selectedGuest;
		guest = { ...guest, city: e.target.value };
		props.setSelectedGuest(guest);
	};

	const updateState = (e) => {
		let guest = props.selectedGuest;
		guest = { ...guest, state: e.target.value };
		props.setSelectedGuest(guest);
	};

	const updatePostal = (e) => {
		let guest = props.selectedGuest;
		guest = { ...guest, postal: e.target.value };
		props.setSelectedGuest(guest);
	};

	const updateCountry = (e) => {
		let guest = props.selectedGuest;
		guest = { ...guest, country: e.target.value };
		props.setSelectedGuest(guest);
	};

	return (
		<Container isNotesActive={props.selectedTab}>
			{props.profileTabIsLoading ? <DotLoader /> : ''}
			<h4>ADDRESS</h4>
			<span>
				{isTyping.address ? '' : <Label as={Label}>Address</Label>}
				<Input
					name="address"
					value={props.selectedGuest.address !== null ? props.selectedGuest.address : ''}
					onChange={updateAddress}
					onFocus={handleFocus}
					onBlur={handleBlur}
				/>
			</span>
			<span>
				{isTyping.cityTown ? '' : <Label as={Label}>City / Town</Label>}
				<Input
					name="cityTown"
					value={props.selectedGuest.city !== null ? props.selectedGuest.city : ''}
					onChange={updateCity}
					onFocus={handleFocus}
					onBlur={handleBlur}
				/>
			</span>
			<span>
				{isTyping.state ? '' : <Label as={Label}>State</Label>}
				<Input
					name="state"
					value={props.selectedGuest.state !== null ? props.selectedGuest.state : ''}
					onChange={updateState}
					onFocus={handleFocus}
					onBlur={handleBlur}
				/>
			</span>
			<span>
				{isTyping.postCode ? '' : <Label as={Label}>Postal Code</Label>}
				<Input
					name="postCode"
					value={props.selectedGuest.postal !== null ? props.selectedGuest.postal : ''}
					onChange={updatePostal}
					onFocus={handleFocus}
					onBlur={handleBlur}
				/>
			</span>
			<span>
				{isTyping.country ? '' : <Label as={Label}>Country</Label>}
				<Input
					name="country"
					value={props.selectedGuest.country !== null ? props.selectedGuest.country : ''}
					onChange={updateCountry}
					onFocus={handleFocus}
					onBlur={handleBlur}
				/>
			</span>
		</Container>
	);
}

export default GuestBookNotesTab;
