import react, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { List, ListItem } from '@material-ui/core';
import axios from 'axios';
import {
	apiUrl,
	getTimeOnly,
	getFullname,
	getGuestPhoneNum,
	getTableSize,
	getFloorName,
	getBookingStatusColor,
} from '../../../../utils/Utility';
import { BOOKINGSTATUS, BOOKINGTYPE } from '../../../../constants/enum';
import NumberFormat from 'react-number-format';
// Icons
import ExitBtn from '../../../../assets/images/booking/exit-btn-table-details.png';
import noteIcon from '../../../../assets/images/booking/create-booking/note-icon.png';
import billedIcon from '../../../../assets/images/booking/billed-icon.png';
import widgetIcon from '../../../../assets/images/booking/widget-icon.png';
import experienceIcon from '../../../../assets/images/booking/create-booking/booking-option-experience-icon.png';
import phoneIcon from '../../../../assets/images/booking/create-booking/booking-status-phone.png';

//Component
import TimeGraph from './TimeGraph';

function TableDetails(props) {
	const [block, setBlock] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	const toggleShow = (booking) => {
		if (props.selectedBookingHandler !== undefined) {
			if (props.selectedBooking === null) {
				props.selectedBookingHandler(booking);
			} else {
				if (props.selectedBooking.id === booking.id) {
					props.selectedBookingHandler(null);
				} else {
					props.selectedBookingHandler(booking);
				}
			}
		}
	};

	const changeTableHandler = () => {
		if (props.isChangingTable) {
			props.setIsChangingTable(!props.isChangingTable);
		} else {
			let table_ids = [];
			table_ids = props.selectedBooking.table.map((e) => e.id);
			props.setSelectedTableId(table_ids);
			props.setIsChangingTable(!props.isChangingTable);
		}
	};

	const handleClick = () => {
		if (block) {
			setBlock(false);
		} else {
			setBlock(true);
		}
	};

	const changeTableSubmit = (item) => {
		let booking = props.selectedBooking;
		if (booking.table_ids === undefined) {
			booking['table_ids'] = booking.table.map((e) => e.id);
		}

		if (item.length <= 0) {
			booking = { ...booking, table_ids: booking.table_ids };
		} else {
			booking = { ...booking, table_ids: item };
		}

		updateBooking(booking);
		console.log(booking);
	};

	const toggleCancel = () => {
		if (isSaving) {
			return;
		}
		props.setIsChangingTable(false);
		props.setSelectedTableId([0]);
	};

	const updateBooking = async (booking) => {
		if (isSaving) {
			return;
		}
		setIsSaving(true);
		try {
			const response = await axios({
				url: `${apiUrl}/api/booking/update`,
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${props.token}`,
				},
				data: booking,
			});

			if (response) {
				const { status } = response;
				const { data } = response.data;
				if (status === 200) {
					props.saveBookingHandler();
					props.setShowTableDetails(false);
					props.selectedBookingHandler(null);
					props.setIsChangingTable(false);
					props.setSelectedTableId(0);
				}
			}
		} catch (err) {}
	};

	return (
		<Col
			lg={12}
			md={12}
			sm={6}
			className={
				!props.showTableDetails
					? 'table-details-container'
					: 'table-details-container table-details-container--active'
			}
		>
			<div className="close-btn-wrapper" onClick={props.showTableDetailsHandler}>
				<img src={ExitBtn} width="15" height="15" alt="checker" />
			</div>
			{props.selectedTableData.length > 0 ? (
				<Col lg={12} md={12} sm={6} className="col1">
					<Row>
						<div
							className={
								props.selectedTableData.map((e) => e.status).includes(4) ? 'dot dot--seated' : 'dot'
							}
						/>
						<p>{props.selectedTableData[0].table[0].name}</p>
					</Row>
				</Col>
			) : (
				<Col lg={12} md={12} sm={6} className="col1">
					<Row>
						<div
							className={
								props.selectedTableData.map((e) => e.status).includes(4) ? 'dot dot--seated' : 'dot'
							}
						/>
						<p>{props.tableDetailsData.name}</p>
					</Row>
				</Col>
			)}

			<Col className="col2">
				<div className="graph-wrapper">
					<h4>SCHEDULE</h4>
					<TimeGraph shift={props.selectedShift} bookingsOnTable={props.selectedTableData} />
				</div>
			</Col>

			<div className="contentWrapper">
				{props.selectedTableData.length > 0 ? (
					props.selectedTableData.map((item, key) => (
						<List style={{ paddingTop: 0 }} key={key}>
							<ListItem
								key={Math.random()}
								alignItems="flex-start"
								className={
									props.selectedBooking !== null
										? props.selectedBooking.id == item.id
											? 'col3 col3--selected'
											: 'col3'
										: 'col3 timeDisplay span1 cxInfo span-name'
								}
								onClick={() => toggleShow(item)}
							>
								<div className="timeDisplay">
									<span className="span1">{getTimeOnly(item.start_date)}</span>
									{/* <span className="span2">PM</span> */}
								</div>

								<div className="cxInfo">
									<span className="span-name">{getFullname(item.guest)}</span>
									<NumberFormat
										value={getGuestPhoneNum(item.guest)}
										displayType={'text'}
										format="#### #### ####"
										className="span-no"
									/>
									<span className="span-guest">
										{getTableSize(item) + ' / ' + getFloorName(item.floor_id, props.floorList)}
									</span>
								</div>

								<div className="table-no-info">
									<span
										style={{
											color: getBookingStatusColor(item.status),
											border: '1px solid ' + getBookingStatusColor(item.status),
										}}
									>
										{' '}
										{item.table[0].name}
									</span>
								</div>

								<div className="noteIcon-wrapper">
									{item.reservation_note !== null ? (
										<img src={noteIcon} width="18" height="18" className="d-inline" alt="checker" />
									) : (
										''
									)}

									{item.status === BOOKINGSTATUS.billed ? (
										<img
											src={billedIcon}
											width="18"
											height="18"
											className="d-inline"
											alt="checker"
										/>
									) : (
										''
									)}

									{item.type === BOOKINGTYPE.widget ? (
										<img
											src={widgetIcon}
											width="18"
											height="18"
											className="d-inline"
											alt="checker"
										/>
									) : (
										''
									)}

									{item.type === BOOKINGTYPE.experience ? (
										<img
											src={experienceIcon}
											width="18"
											height="18"
											className="d-inline"
											alt="checker"
										/>
									) : (
										''
									)}

									{item.type === BOOKINGTYPE.phone ? (
										<img
											src={phoneIcon}
											width="18"
											height="18"
											className="d-inline"
											alt="checker"
										/>
									) : (
										''
									)}
								</div>
							</ListItem>
						</List>
					))
				) : (
					<div className="btn-walk-in-wrapper">
						{/* Removed Temporary - Not functional buttons */}
						{/* <span className="btn-walk-in1">Seat Walk-In</span>
						<span className="btn-walk-in2">Partially Seat Walk-in</span> */}
					</div>
				)}
			</div>

			<Col className="footer">
				{props.selectedBooking === null ? (
					<div>
						{/* <div className="btn-control">Make Reservation</div>
						<div className="btn-control">Block Table by Time</div>
						<div
							className={block ? 'btn-control btn-control--selected' : 'btn-control'}
							onClick={handleClick}
						>
							{block ? 'Unblock' : 'Block Seat Manually'}
						</div> */}
					</div>
				) : (
					<div className="seated-control" style={{ display: 'none;' }}>
						{props.isChangingTable ? (
							<div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center' }}>
								<div
									className="seated btn-control"
									onClick={(e) => changeTableSubmit(props.selectedTableId)}
								>
									Change Assignment
								</div>
								<div className="seated btn-control" onClick={(e) => toggleCancel()}>
									Cancel
								</div>
							</div>
						) : (
							<div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center' }}>
								{/* <div className="seated btn-control">Switch Table</div> */}
								<div className="seated btn-control" onClick={(e) => changeTableHandler()}>
									Change Table
								</div>
							</div>
						)}
					</div>
				)}
			</Col>

			{/* <Col className="footer-shadow">
				<div className="btn-shadow">Shadow</div>
				<div className="btn-shadow">Shadow</div>
				<div className="btn-shadow">Shadow</div>
			</Col> */}
		</Col>
	);
}
export default TableDetails;
