import React, { useState, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import { Nav, Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import withDashboardApp from '../../components/withDashboardApp';

import checkmark from '../../assets/images/checkmark.png';

const Content = styled.div`
	display: block;
	background: #f7f7f7;
	padding-bottom: 40px;
	padding-top: 0px;
`;

function EditPlan() {
	const history = useHistory();
	const [btnTab1, setBtnTab1] = useState('btn-tab1 active-tab');
	const [btnTab2, setBtnTab2] = useState('btn-tab2');
	const [pck, setPck] = useState('monthly');
	// const [paymentModalShow, setPaymentModalShow] = useState(false);
	const [changePlan, setChangePlan] = useState(false);
	const [suspendPlan, setSuspendPlan] = useState(false);

	const handleChangePlan = () => setChangePlan(!changePlan);
	const handleSuspend = () => setSuspendPlan(!suspendPlan);

	const goToDashboard = () => history.push('/dashboard');

	const handleTab1 = () => {
		disableTab();
		setPck('monthly');
		setBtnTab1('btn-tab1 active-tab');
	};

	const handleTab2 = () => {
		disableTab();
		setPck('annualy');
		setBtnTab2('btn-tab2 active-tab');
	};

	const disableTab = () => {
		setBtnTab1('btn-tab1');
		setBtnTab2('btn-tab2');
	};

	// const handleStart = () => {
	// 	setPaymentModalShow(true);
	// };

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Fragment>
			<Container fluid>
				<p className="go-back">
					<button type="button" onClick={goToDashboard}>
						Go Back
					</button>
				</p>
				<Content id="pricing" className="content">
					<Container className="seventh-content why-title">
						<Nav variant="tabs" defaultActiveKey="/home">
							<Nav.Item>
								<Button onClick={handleTab1} className={btnTab1}>
									Monthly Plan
								</Button>
							</Nav.Item>
							<Nav.Item>
								<Button onClick={handleTab2} className={btnTab2}>
									Annual Plan
								</Button>
							</Nav.Item>
						</Nav>
						<Row id="row-p">
							<Col lg={4} md={12}></Col>
							<Col lg={4} md={12}>
								<div className="price-cont">
									<div className="price-plan">
										<h4>WABI LITE</h4>
										<span>For small restaurants & Cafes</span>
										<ul>
											<li>
												<img
													src={checkmark}
													width="18"
													height="18"
													className="d-inline"
													style={{ marginRight: 7 }}
													alt="checker"
												/>
												Lorem ipsum dolor sit amet
											</li>
											<li>
												<img
													src={checkmark}
													width="18"
													height="18"
													className="d-inline"
													style={{ marginRight: 7 }}
													alt="checker"
												/>
												At vero eos et accusam et just
											</li>
											<li>
												<img
													src={checkmark}
													width="18"
													height="18"
													className="d-inline"
													style={{ marginRight: 7 }}
													alt="checker"
												/>
												Stet clita kasd gubergren
											</li>
											<li>
												<img
													src={checkmark}
													width="18"
													height="18"
													className="d-inline"
													style={{ marginRight: 7 }}
													alt="checker"
												/>
												Sed diam nonumy eirmod
											</li>
										</ul>
										<hr />
										{pck === 'monthly' && (
											<span>
												<span className="price-tag">$89</span> /month
											</span>
										)}
										{pck === 'annualy' && (
											<span>
												<span className="price-tag">$900</span> + Free Tablet
											</span>
										)}
										<Button
											onClick={handleChangePlan}
											className="btn-start"
											style={{ paddingTop: 16, paddingBottom: 16, marginTop: 20 }}
										>
											GET STARTED
										</Button>
									</div>
								</div>
							</Col>
							<Col lg={4} md={12}></Col>
						</Row>
						<p className="suspend" onClick={handleSuspend}>
							Suspend my plan
						</p>
					</Container>
				</Content>
			</Container>

			<Modal
				size="md"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				className="modal-pay"
				show={changePlan}
				onHide={handleChangePlan}
			>
				<Modal.Body className="cpc">
					<div className="confirm-plan">
						<h3>Please confirm the following change.</h3>
						<p>You plan will be change to [Plan name], $99/Month from 11/12/2020</p>
					</div>
					<Row>
						<Col sm="6" xs="12">
							<Button className="btn-start btn-cancel" onClick={handleChangePlan}>
								Cancel
							</Button>
						</Col>
						<Col sm="6" xs="12">
							<Button className="btn-start btn-confirm">Confirm</Button>
						</Col>
					</Row>
				</Modal.Body>
			</Modal>

			<Modal
				size="md"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				className="modal-pay"
				show={suspendPlan}
				onHide={handleSuspend}
			>
				<Modal.Body className="cpc">
					<div className="confirm-plan">
						<h3>Are you sure you want to suspend your Wabi plan?</h3>
						<p>
							You subscription will end on 11/12/2020. You can also resubscribe by logging in again.
						</p>
					</div>
					<Row>
						<Col sm="6" xs="12">
							<Button className="btn-start btn-cancel" onClick={handleSuspend}>
								Cancel
							</Button>
						</Col>
						<Col sm="6" xs="12">
							<Button className="btn-start btn-confirm">Confirm</Button>
						</Col>
					</Row>
				</Modal.Body>
			</Modal>
		</Fragment>
	);
}

export default withDashboardApp(EditPlan);
