import Header from 'components/Revamp/Header';
import Footer from 'components/Revamp/Footer';
import { Col, Container, Row } from 'react-bootstrap';

import styled from 'styled-components';
import { down } from 'styled-breakpoints';

const HomePageHero = {
	Wrapper: styled.div`
		background-color: #f5f5f7;
		padding-top: 5.5rem;
		padding-bottom: 5.5rem;
	`,

	TextWrap: styled.div`
		position: relative;
		${down('sm')} {
			padding: 2rem;
		}
	`,
	Button: styled.div`
		position: absolute;
		right: 45%;
		bottom: 10px;
		${down('sm')} {
			position: unset;
			text-align: center;
			margin-top: 1rem;
			margin-bottom: 1rem;
		}
	`,
	Text: styled.div`
		font-family: sofia-pro;
		font-size: 45px;
		font-weight: 600;
		margin-top: 2rem;
		color: #1d1d1f;
	`,
	GradientWrap: styled.div`
		font-size: 50px;
		font-weight: 600;
		font-family: sofia-pro;
		text-align: center;
		margin-top: 15rem;
		margin-bottom: 10rem;
	`,
};

const Privacy = () => {
	return (
		<div>
			<Header isHome />
			<HomePageHero.Wrapper>
				<Container className="fluid-max-width" style={{ padding: '0 2rem' }} fluid>
					<p>
						<h1>Privacy Policy</h1>
					</p>
					<p>&nbsp;</p>
					<p>
						<span style={{ fontWeight: '400' }}>
							This Privacy Policy describes how Wabi Pty Ltd. and its subsidiaries (collectively,
							&ldquo;
						</span>
						<strong>Wabi</strong>
						<span style={{ fontWeight: '400' }}>,&rdquo; &ldquo;</span>
						<strong>we</strong>
						<span style={{ fontWeight: '400' }}>,&rdquo; &ldquo;</span>
						<strong>our</strong>
						<span style={{ fontWeight: '400' }}>&rdquo; or &ldquo;</span>
						<strong>us</strong>
						<span style={{ fontWeight: '400' }}>
							&rdquo;) collect, use, process and share personal information. This Policy applies to
							visitors and users (individually, &ldquo;
						</span>
						<strong>you</strong>
						<span style={{ fontWeight: '400' }}>
							&rdquo;) of Wabi&rsquo;s websites, applications, social media accounts, and other
							services (collectively, our &ldquo;
						</span>
						<strong>Services</strong>
						<span style={{ fontWeight: '400' }}>
							&rdquo;). Please note that your use of our Services is additionally subject to the
							Wabi&nbsp;Terms of Use.
						</span>
					</p>
					<p>
						<span style={{ fontWeight: '400' }}>
							Wabi helps connect diners and restaurants. This involves sharing personal information
							with third parties, including restaurants, restaurant groups, and restaurant
							affiliates, amongst others. In some cases, these third parties may collect personal
							information directly from you and share it with us.
						</span>
					</p>
					<p>
						<span style={{ fontWeight: '400' }}>
							For more information about the information we share with third parties and the
							recipients of such information, please refer to the&nbsp;How We Share Your
							Information&nbsp;section of this Policy. Please note that this Policy does not
							otherwise cover the use of personal information by third parties or their services,
							and we do not take responsibility for their privacy practices.
						</span>
					</p>
					<p>
						<span style={{ fontWeight: '400' }}>
							You may have certain rights or choices that relate to your personal information,
							including choices about how we share your personal information with others. For more
							information, please refer to the&nbsp;Your Choices and Rights&nbsp;section of this
							Policy.
						</span>
					</p>
					<ol>
						<p>&nbsp;</p>
						<li>
							<strong>Overview</strong>
						</li>
						<hr></hr>
					</ol>
					<table style={{ marginLeft: '10px' }} border="1" cellpadding="10px">
						<tbody>
							<tr>
								<td>
									<p>
										<strong>Information We Collect and Use</strong>
									</p>
								</td>
								<td>
									<p>
										<span style={{ fontWeight: '400' }}>
											When you use or visit our Services, we collect information directly from you
											(e.g., when you search for or make an online reservation). We may also
											generate information about you (e.g., information about your device while you
											use our mobile application). In some cases, we also obtain personal
											information from third parties (e.g., restaurants, business partners, our
											group companies, or other third parties).&nbsp;&nbsp;
										</span>
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>
										<strong>How We Use Your Information</strong>
									</p>
								</td>
								<td>
									<p>
										<span style={{ fontWeight: '400' }}>
											We use your information to provide our Services, tailor your experience, show
											you bookings and transactions made through Wabi and our group companies, send
											you marketing communications, provide you with more relevant advertising and
											offers that may be of interest to you, and for other purposes described
											below.&nbsp;&nbsp;
										</span>
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>
										<strong>How We Share Your Information</strong>
									</p>
								</td>
								<td>
									<p>
										<span style={{ fontWeight: '400' }}>
											We share your information with restaurants and restaurant affiliates; with our
											group companies); with our service providers (including payment processors);
											with other business partners (including advertising partners); with social
											networking services; with third-party services or applications you use to log
											into your Wabi account; and others as described below. We share information to
											provide and improve our Services, for our own marketing purposes, to
											facilitate offers and advertising from others that may interest you, and for
											other purposes as described below.&nbsp;&nbsp;
										</span>
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>
										<strong>How We Store and Protect Your Information</strong>
									</p>
								</td>
								<td>
									<p>
										<span style={{ fontWeight: '400' }}>
											We maintain technical, administrative, and physical security measures designed
											to protect your information. We may retain your information for as long as
											your account is active and for a period of time thereafter to allow you to
											re-activate your account without loss of information, and as necessary for
											purposes described below.&nbsp;
										</span>
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>
										<strong>Cookies</strong>
									</p>
								</td>
								<td>
									<p>
										<span style={{ fontWeight: '400' }}>
											We use cookies and similar technologies to help provide our Services, offer
											you a more personalised user experience and market our and third parties'
											products and services to you.&nbsp;&nbsp;
										</span>
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>
										<strong>Your Choices and Rights</strong>
									</p>
								</td>
								<td>
									<p>
										<span style={{ fontWeight: '400' }}>
											You have choices about your information, and in some circumstances, you may be
											able to opt-out of receiving marketing communications, change your
											communication preferences, access and update your information, update location
											sharing options, exercise choices regarding cookies, and/or opt-out of certain
											sharing of information with third parties (including our group companies,
											restaurant groups and restaurant affiliates, and other business
											partners).&nbsp;&nbsp;These choices and other rights you may have are
											described in more detail below.&nbsp;&nbsp;
										</span>
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>
										<strong>International Transfers of Information</strong>
									</p>
								</td>
								<td>
									<p>
										<span style={{ fontWeight: '400' }}>
											We operate internationally, and information about you may be transferred to,
											or accessed by, entities located in countries outside of your home country.
											&nbsp;To protect your information, any such international transfers will be
											made in accordance with applicable law.&nbsp;&nbsp;
										</span>
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>
										<strong>Links to Other Websites</strong>
									</p>
								</td>
								<td>
									<p>
										<span style={{ fontWeight: '400' }}>
											Our Services may contain links to other websites or services that are not
											owned or controlled by Wabi, including links to websites of restaurants and
											restaurant affiliates, our group companies, and other business partners
											(including advertisers).&nbsp;
										</span>
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>
										<strong>Children</strong>
									</p>
								</td>
								<td>
									<p>
										<span style={{ fontWeight: '400' }}>
											Our Services are not directed at or intended for use by children, nor do we
											knowingly collect information from children under 16 years of age.&nbsp;&nbsp;
										</span>
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>
										<strong>Changes to This Policy</strong>
									</p>
								</td>
								<td>
									<p>
										<span style={{ fontWeight: '400' }}>
											We update this Policy from time to time, and changes will be effective when
											posted (as identified in the Policy).&nbsp;&nbsp;
										</span>
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>
										<strong>How to Contact Us</strong>
									</p>
								</td>
								<td>
									<p>
										<span style={{ fontWeight: '400' }}>
											If you have any questions about this Policy or the way your personal
											information has been used, please contact us at&nbsp;
										</span>
										<a href="mailto:privacy@opentable.com">
											<span style={{ fontWeight: '400' }}>privacy@Wabi.com</span>
										</a>
										<span style={{ fontWeight: '400' }}>
											&nbsp;or using the contact details set out below.&nbsp;&nbsp;
										</span>
									</p>
								</td>
							</tr>
						</tbody>
					</table>
					<p>&nbsp;</p>
					<ol start="2">
						<p>&nbsp;</p>
						<li>
							<strong>Information we collect and use</strong>
						</li>
						<hr></hr>
					</ol>
					<p>
						<span style={{ fontWeight: '400' }}>
							&ldquo;Personal information&rdquo; is data that identifies, relates to, describes, can
							be used to contact, or could reasonably be linked directly or indirectly to you. For
							purposes of this Policy, there is no meaningful distinction between the terms
							&ldquo;personal information&rdquo; and &ldquo;personal data.&rdquo;
						</span>
					</p>
					<p>
						<strong>Personal Information We Collect Directly From You.&nbsp;</strong>
						<span style={{ fontWeight: '400' }}>
							As you visit or use our Services, we collect the following categories of personal
							information directly from you:
						</span>
					</p>
					<ul>
						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Personal details</strong>
							<span style={{ fontWeight: '400' }}>&nbsp;(such as your name and birthday)</span>
						</li>
						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Contact information</strong>
							<span style={{ fontWeight: '400' }}>
								&nbsp;(such as email address, postal address, phone number)
							</span>
						</li>
						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Dining information</strong>
							<span style={{ fontWeight: '400' }}>
								&nbsp;(such as primary dining city; current and past restaurant reservation details;
								dining preferences; favourite restaurants; special restaurant requests; dining
								activity, including frequency, restaurants, restaurant type, meal type, and
								cancellations)
							</span>
						</li>
						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Account information</strong>
							<span style={{ fontWeight: '400' }}>
								&nbsp;(such as account settings and passwords)
							</span>
						</li>
						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Social media data&nbsp;</strong>
							<span style={{ fontWeight: '400' }}>
								(if you choose to link your Wabi account with a social media account, we may collect
								personal information such as name, age, gender, photograph, and other personal
								information relating to your social media account)
							</span>
						</li>
						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Billing information</strong>
							<span style={{ fontWeight: '400' }}>
								&nbsp;(such as credit, debit, or other payment card information, as may be required
								to use certain Services)
							</span>
						</li>
						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Your contacts&nbsp;</strong>
							<span style={{ fontWeight: '400' }}>
								(such as contact information of people you add to, or notify of, your restaurant
								reservations through our Services)
							</span>
						</li>
						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Your preferences</strong>
							<span style={{ fontWeight: '400' }}>
								&nbsp;(including survey and marketing responses)
							</span>
						</li>
						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Photos of you&nbsp;</strong>
							<span style={{ fontWeight: '400' }}>
								(such as if you add a photo of yourself to your profile, upload photos to a review
								or restaurant&rsquo;s profile, or link your social media account to your Wabi
								account)
							</span>
						</li>
						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Communications you send us</strong>
							<span style={{ fontWeight: '400' }}>
								&nbsp;(such as customer support and other requests you make through our Services and
								through restaurants)
							</span>
						</li>
						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Restaurant reviews and content</strong>
							<span style={{ fontWeight: '400' }}>
								&nbsp;(including reviews, photos, an alias of your choosing that you can use when
								submitting reviews, and other information you provide through our Services)
							</span>
						</li>
						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Promotion information&nbsp;</strong>
							<span style={{ fontWeight: '400' }}>
								(if you choose to participate in a contest, sweepstakes, or similar campaign, we
								will collect any information you provide in relation to such activity, such as
								photos, images, captions, or other content, in accordance with the terms provided at
								that time)
							</span>
						</li>
					</ul>
					<p>&nbsp;</p>
					<ul>
						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Other information you may provide</strong>
							<span style={{ fontWeight: '400' }}>
								&nbsp;(including other information you provide about yourself or others through our
								Services or to which you provide us with access via third-party platforms)
							</span>
						</li>
					</ul>
					<p>
						<span style={{ fontWeight: '400' }}>
							You may choose not to provide some of the personal information described above. Please
							note, however, that many of our Services require some personal information to operate,
							so if you choose not to provide the personal information necessary to operate and
							provide you with a particular Service or feature of that Service, you may not be able
							to use that Service or feature.
						</span>
					</p>
					<p>
						<span style={{ fontWeight: '400' }}>
							We do not proactively collect sensitive personal information, such as health-related
							information. However, our Services include text boxes that are designed for you to
							describe certain details about your dining preferences that you want us or restaurants
							to know. Please be aware that information you freely submit in these boxes may reveal
							to us or to the restaurants or restaurant affiliates with which we or they share
							information (as detailed in the&nbsp;How We Share Your Information&nbsp;section,
							below) certain information that may be considered sensitive personal information under
							applicable law (for example, about any allergies or dietary restrictions). We do not
							use this information for the purpose of marketing or advertising products to you.
							Sensitive personal information you voluntarily submit is processed on the basis of
							your consent, which you may revoke at any time by contacting us at the details set out
							in the&nbsp;How to Contact Us&nbsp;section below.
						</span>
					</p>
					<p>
						<strong>Personal Information Generated by Us.&nbsp;</strong>
						<span style={{ fontWeight: '400' }}>
							As you use our Services, we generate certain personal information about you, including
							through automatic data collection and by inferences based on the information we
							collect about you and your activity.&nbsp;&nbsp;We may automatically collect
							information about your interactions with the Services or communications you receive
							(such as email) using certain technologies, such as cookies, web beacons and other
							technologies. We generate the following categories of personal information about you:
						</span>
					</p>
					<ol type="a">
						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Device information.&nbsp;</strong>
							<span style={{ fontWeight: '400' }}>
								When you visit or use our Services, we automatically collect certain information
								about your device (e.g., your mobile device, computer, or tablet), including
								information about your hardware and software, device configuration, and nearby
								networks. Such data may include data about your device operating systems, browsers,
								and other software installed on your device; device identifiers, such as IP address,
								IMEI number, MAC address, or other device identifier; country of origin, region and
								language settings; and information about domain servers and wireless or network
								access points near your device.
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Usage and performance information.&nbsp;</strong>
							<span style={{ fontWeight: '400' }}>
								We also automatically collect personal information about your usage of the Services,
								including information about your searches or interactions with features of our
								Services; sites or restaurant pages visited; booking path; access times; and
								performance of our Services.
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Location information.&nbsp;</strong>
							<span style={{ fontWeight: '400' }}>
								If you use our Services, we automatically collect generic location information about
								you (such as city or neighbourhood)&nbsp;or, with your consent, precise geographic
								location data from your mobile device when the app is running and when it is not
								running, depending on the choices you make when you are asked to consent to our
								collection of location information. For example, we may receive this information
								when you select restaurant search locations, enter your local dining city in your
								account profile, when you are in proximity to certain beacons, choose to publish
								your location in reviews you leave for restaurants on the Services, or in your
								comments or other communications with us. We may use and store this information to
								provide and improve features of our Services, for example, to tailor our Services on
								a needs-based manner to better facilitate your requests and to provide you with more
								relevant content about restaurants in your dining city or cities you visit. Please
								see the&nbsp;Your Rights and Choices&nbsp;section below for more information about
								how to adjust your preferences, including those related to location information.
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Inferences about you.&nbsp;</strong>
							<span style={{ fontWeight: '400' }}>
								We combine the information we collect, generate, or otherwise obtain to draw
								inferences about your preferences and interests in order to provide and personalise
								our Services and tailor the offers we and our partners provide to you.
							</span>
						</li>
					</ol>
					<p>
						<strong>Personal Information We obtain from Third Parties.&nbsp;</strong>
						<span style={{ fontWeight: '400' }}>
							We may also receive certain categories of personal information from third parties,
							such as third-party websites, applications, social media networks, and services (which
							may include publicly-available sources; each of these is a &ldquo;
						</span>
						<strong>third-party platform</strong>
						<span style={{ fontWeight: '400' }}>
							&rdquo;), our group companies, restaurants, and other third parties, including
							individuals who have added you as a guest to their reservation. If you are an existing
							Wabi customer, we will combine this information with information we collect through
							our Services and use and share it for the purposes described below. The categories of
							personal information we may obtain from third parties include:
						</span>
					</p>
					<ol type="a">
						<li style={{ fontWeight: '400' }} aria-level="2">
							<strong>Your name</strong>
						</li>
						<p>&nbsp;</p>
						<li style={{ fontWeight: '400' }} aria-level="2">
							<strong>Email address or other contact information</strong>
						</li>
						<p>&nbsp;</p>
						<li style={{ fontWeight: '400' }} aria-level="2">
							<strong>Social media data,&nbsp;</strong>
							<span style={{ fontWeight: '400' }}>
								if you connect to our Services using a social media third-party platform or interact
								with Wabi by liking or commenting on our social media pages or content, or if any of
								your social media data is available to the public, we may receive information from
								that social media third-party platform or a third-party service about those
								interactions and your profile
							</span>
						</li>
						<p>&nbsp;</p>
						<li style={{ fontWeight: '400' }} aria-level="2">
							<strong>Browsing, usage and advertising details,&nbsp;</strong>
							<span style={{ fontWeight: '400' }}>
								including how you use our Services and/or third-party websites, applications, and
								services; and information relating to your interactions with our advertising and
								marketing materials
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="2">
							<strong>Purchase Information,&nbsp;</strong>
							<span style={{ fontWeight: '400' }}>
								including information from point-of-sale devices at certain participating
								restaurants (such as items ordered, bill total, and time spent at the restaurant),
								information about travel accommodations and bookings you make through our group
								companies (for example, if you book a hotel stay through one of our group companies,
								we may receive the general location of your accommodations and use that information
								to provide nearby restaurant recommendations); or purchase information for other
								business partners and affiliates, such as food delivery partners
							</span>
						</li>
						<li style={{ fontWeight: '400' }} aria-level="2">
							<strong>Information from restaurants,&nbsp;</strong>
							<span style={{ fontWeight: '400' }}>
								including information that certain participating restaurants provide us about diners
								(for example, if you eat at a hotel restaurant as a hotel guest, restaurants are
								able to flag that you are a guest at that hotel, or if you eat at a chain of
								restaurants, those restaurants can flag whether you have a loyalty card or other
								information about interactions with those restaurants)
							</span>
						</li>
					</ol>
					<p>&nbsp;</p>
					<ol start="3">
						<p>&nbsp;</p>
						<li>
							<strong>How we use your information</strong>
						</li>
						<hr></hr>
					</ol>
					<ol type="a">
						<p>
							<span style={{ fontWeight: '400' }}>
								We use your personal information for the following purposes (&ldquo;
							</span>
							<strong>Purposes</strong>
							<span style={{ fontWeight: '400' }}>&rdquo;), to:</span>
						</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Provide the Services</strong>
							<span style={{ fontWeight: '400' }}>
								, which includes providing you with the services, products, and functionality
								offered through our Services and fulfilling your requests, including but not limited
								to: making reservations, reviewing restaurants, paying for services or events,
								joining waitlists, and notifying people you add to or notify of your restaurant
								reservations through the Services
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Authenticate your account credentials and identify you</strong>
							<span style={{ fontWeight: '400' }}>
								, as necessary to log you in to the Services and ensure the security of your account
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Communicate with you</strong>
							<span style={{ fontWeight: '400' }}>
								&nbsp;about your account or use of our Services, products, and/or functionality;
								respond to, or follow up on, your comments and questions; and otherwise provide
								customer service
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Send you marketing communications</strong>
							<span style={{ fontWeight: '400' }}>
								, including communicating with you about services or products offered by Wabi, our
								group companies, or our business partners and other marketing communications that we
								believe you would be interested in, as permitted by law
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>
								Operate and improve our Services and develop new products and services
							</strong>
							<span style={{ fontWeight: '400' }}>
								, including using analytics to better understand how you use our Services for
								purposes of product, website, application and service development and to enhance the
								user experience
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Show your purchases and bookings</strong>
							<span style={{ fontWeight: '400' }}>
								&nbsp;made through the websites, applications, and services of our group companies
								on your account page
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Process and deliver contest entries and rewards</strong>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Authenticate your credit or debit card account information</strong>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Provide services, products, and information to restaurants,</strong>
							<span style={{ fontWeight: '400' }}>
								&nbsp;including restaurant groups and restaurant affiliates
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Tailor your experience with our Services,</strong>
							<span style={{ fontWeight: '400' }}>
								&nbsp;such as by making inferences or combining different pieces of information we
								have collected about you to suggest restaurants that you may be interested in or
								otherwise tailor our Services to you according to your preferences or restrictions.
								For example, if you frequently search for or book Italian restaurants or restaurants
								with outdoor seating in your home city, we may prioritise Italian restaurants with
								outdoor seating in your search results when you look for reservations in another
								city&nbsp;
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Provide you more relevant advertising on and off our Services,&nbsp;</strong>
							<span style={{ fontWeight: '400' }}>
								including to show you personalised offers on and off our Services. For example, if
								you frequently search for or book reservations at Italian restaurants in a
								particular city, we may display advertising for a local Italian restaurant to you on
								our Services or work with our business partners to display advertisements for local
								Italian restaurants to you on other websites you visit
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>
								Protect against, investigate, and deter fraudulent, unauthorised, or illegal
								activity
							</strong>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Comply with our policies, procedures and legal obligations</strong>
							<span style={{ fontWeight: '400' }}>
								, including complying with law enforcement or government authority requests,
								addressing litigation-related issues, and exercising rights or obligations conferred
								by law
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>
								As otherwise consented to by you and as required or permitted by applicable law
							</strong>
							<span style={{ fontWeight: '400' }}>
								. If you give your consent to any further use of personal information, you can
								withdraw that consent at any time by contacting us using the details set out below.
							</span>
						</li>
					</ol>
					<p>
						<span style={{ fontWeight: '400' }}>
							You have choices about your personal information, and in some circumstances, you may
							have the right to opt-out or object to our uses of your personal information for these
							Purposes. For more information, or to exercise these or other rights (where
							available), see the&nbsp;Your Choices and Rights&nbsp;section below.
						</span>
						<strong>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						</strong>
					</p>
					<p>
						<strong>Electronic Communications.&nbsp;</strong>
						<span style={{ fontWeight: '400' }}>
							Consistent with the above Purposes and as permitted by applicable law, we may
							communicate with you via electronic messages, including email, text message, or mobile
							push notification to:
						</span>
					</p>
					<ul>
						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Send you information relating to our products and Services.&nbsp;</strong>
							<span style={{ fontWeight: '400' }}>
								This may include reservation and waitlist confirmations, reminders and updates,
								receipts, technical notices, updates, security alerts, and support and
								administrative messages.
							</span>
						</li>
					</ul>
					<p>&nbsp;</p>
					<ul>
						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Send you marketing communications.&nbsp;</strong>
							<span style={{ fontWeight: '400' }}>
								&nbsp;In other situations, subject to the&nbsp;Your Choices and Rights&nbsp;section
								below and applicable law, we may communicate with you about contests, offers,
								promotions, rewards, upcoming events, and other news about products and Services
								offered by Wabi, our group companies, restaurants, and other business partners.
							</span>
						</li>
					</ul>
					<p>
						<span style={{ fontWeight: '400' }}>
							With your consent, where required, we may contact you at the mobile phone number that
							you provide to us by way of direct dial calls, auto dialled and pre-recorded message
							calls, text messages and push notifications in connection with the above
							Purposes.&nbsp;
						</span>
					</p>
					<p>
						<strong>Processing Bases and Consequences.&nbsp;</strong>
						<span style={{ fontWeight: '400' }}>
							When we process your personal information, we rely on the following legal bases:
						</span>
					</p>
					<ol type="a">
						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Performance of the contract we have with you&nbsp;</strong>
							<span style={{ fontWeight: '400' }}>
								(such as if you use our Services to make a restaurant reservation, we will use your
								information to carry out our obligation to complete and administer your reservation
								by sharing your information with the appropriate restaurant).
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Compliance with legal obligations to which we are subject</strong>
							<span style={{ fontWeight: '400' }}>
								&nbsp;(such as tax obligations, and when we are obliged to comply with lawful
								requests from competent authorities such as law enforcement).
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>To serve our legitimate interests&nbsp;</strong>
							<span style={{ fontWeight: '400' }}>
								(such as tailoring your experience with our Services, carrying out online
								advertising, and for fraud detection), provided that such processing does not
								outweigh your rights and freedoms. The processing may also be pursuant to other
								applicable legal bases for data processing especially provisions set out under local
								law.
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<span style={{ fontWeight: '400' }}>
								Where we use personal information to meet our legitimate interests, we take steps to
								ensure that your rights with respect to your personal information are not infringed.
								You can contact us using the details set out in the&nbsp;How to Contact
								Us&nbsp;section below for more information about the steps we take to ensure these
								rights are not infringed. You also have the right to object to such processing as
								described in the&nbsp;Your Choices and Rights&nbsp;section below.
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Consent.&nbsp;</strong>
							<span style={{ fontWeight: '400' }}>
								To the extent that a legal ground described above would not apply to processing of
								your personal information by us, we will seek your consent for such specific purpose
								in accordance with applicable law (such as sending direct marketing messages by
								electronic means, like email, without an exception from the requirement to obtain
								consent).
							</span>
						</li>
					</ol>
					<p>
						<strong>Aggregate Information.&nbsp;</strong>
						<span style={{ fontWeight: '400' }}>
							We aggregate personal information collected directly from you, information generated
							about you by us, and information obtained from third parties (with your consent, where
							required) with personal information collected about other users in order to produce
							general statistics that cannot be linked to you or any other specific user.
							Information that has been aggregated and de-identified is no longer considered
							&ldquo;personal information&rdquo; and may be subsequently used for any purpose.
						</span>
					</p>
					<p>
						<strong>Anonymised Information.</strong>
						<span style={{ fontWeight: '400' }}>
							&nbsp;We may process information that cannot be linked to you or any other specific
							user using any means available to us, either because it was collected anonymously or
							has been subsequently anonymised. Information that is anonymous or has been anonymised
							is no longer considered &ldquo;personal information&rdquo; and may be subsequently
							used for any purpose.
						</span>
					</p>
					<ol start="4">
						<p>&nbsp;</p>
						<li>
							<strong>How we share your information</strong>
						</li>
						<hr></hr>
					</ol>
					<p>
						<span style={{ fontWeight: '400' }}>
							We disclose the personal information we collect (or otherwise generate or obtain) as
							follows:
						</span>
					</p>
					<ol type="a">
						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>With restaurants and restaurant affiliates.</strong>
							<span style={{ fontWeight: '400' }}>
								&nbsp;We share your information with restaurants and their affiliates
							</span>
							<strong>&nbsp;</strong>
							<span style={{ fontWeight: '400' }}>
								to provide the Services (such as connecting diners with restaurants to make an
								online reservation and sharing your dining activity and history, preferences,
								requests, restrictions, and other information with the restaurant) and for the
								restaurant or restaurant group&rsquo;s own purposes, which may include marketing or
								advertising purposes
							</span>
							<strong>.</strong>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>With our group companies.&nbsp;</strong>
							<span style={{ fontWeight: '400' }}>
								We share your information with our group companies, including but not limited to for
								the Purposes described above, to provide you with integrated products and services,
								and for our and their marketing purposes.
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>With other business partners.</strong>
							<span style={{ fontWeight: '400' }}>
								&nbsp;We share information with other third-party business partners for their own
								marketing purposes, including sharing with online advertisers or advertising
								technology (&ldquo;
							</span>
							<strong>ad tech</strong>
							<span style={{ fontWeight: '400' }}>
								&rdquo;) companies to provide you with targeted advertising and marketing
								communications, where permitted under law.
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>With social networking services.</strong>
							<span style={{ fontWeight: '400' }}>
								&nbsp;We share (or facilitate your sharing of) your information with social
								networking services when you use our Services to connect and share your information
								publicly or with friends, or when you use our Services to connect with us on, share
								on, or use third-party social networking platforms and services.
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>
								Third-party services or applications you use to log into your account.&nbsp;
							</strong>
							<span style={{ fontWeight: '400' }}>
								If you use a third-party service or application (e.g., Facebook) to log into your
								Wabi account, we share certain personal information with that third party, such as
								your name and email address.
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Reviews you submit.&nbsp;</strong>
							<span style={{ fontWeight: '400' }}>
								If you provide us with a review of your restaurant reservation, you authorise us to
								publish it on all our Services under the screenname you provided, to aggregate your
								review with other reviews, and to share it with restaurants.
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Messages and other communications you submit to restaurants.&nbsp;</strong>
							<span style={{ fontWeight: '400' }}>
								If you submit a message or other communication to a restaurant through our Services,
								you authorise us to share your message or communications with the relevant
								restaurant, and you authorise the restaurant to respond to you through our Services.
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>To process payments.</strong>
							<span style={{ fontWeight: '400' }}>
								&nbsp;We require credit or debit card information to make payments at certain
								restaurants, to secure your reservation, purchase tickets to events, or purchase
								other products or services, in which case we share your payment information with
								restaurants, third-party payment processors, and other third-party service providers
								(such as fraud detection services).
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>With other service providers.</strong>
							<span style={{ fontWeight: '400' }}>
								&nbsp;We share information with third-party vendors, consultants, and other service
								providers who perform services or functions on our behalf.
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>In the event of a corporate transaction.</strong>
							<span style={{ fontWeight: '400' }}>
								&nbsp;We may disclose or transfer your information to a third party if we sell,
								transfer, divest, or disclose all or a portion of our business or assets to another
								company in connection with or during negotiation of any merger, financing,
								acquisition, bankruptcy, dissolution, transaction, or proceeding.
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>To protect rights and property.</strong>
							<span style={{ fontWeight: '400' }}>
								&nbsp;We may disclose your information to third parties when we believe in good
								faith that disclosure is necessary to protect our rights, our property, the
								integrity of the Services, personal safety, or the interests of you or any other
								person, and to detect, prevent and/or otherwise address fraud, risk management,
								security or technical issues.
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>To comply with and as required by law.</strong>
							<span style={{ fontWeight: '400' }}>
								&nbsp;We may disclose your personal information to government authorities or other
								relevant third parties in order to comply with applicable legal requirements,
								judicial proceedings, court orders, legal process, or lawful requests from
								governmental authorities.
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Aggregate information.</strong>
							<span style={{ fontWeight: '400' }}>
								&nbsp;We share aggregate statistical data for the improvement of our Services. We
								may also share aggregate or de-identified information with third parties at our
								discretion.
							</span>
						</li>
						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>At your request.</strong>
							<span style={{ fontWeight: '400' }}>
								&nbsp;We also share your information as directed or requested by you, or subject to
								your consent.
							</span>
						</li>
					</ol>
					<p>
						<span style={{ fontWeight: '400' }}>
							In some circumstances, you may have the right to opt-out or object to our sharing of
							your information with certain third parties. For more information, or to exercise
							these or other rights, see the&nbsp;Your Choices and Rights&nbsp;section below.
						</span>
					</p>
					<p>
						<strong>
							Sharing with Restaurants and Restaurant Affiliates&nbsp;as a controller of your
							personal information.&nbsp;
						</strong>
						<span style={{ fontWeight: '400' }}>
							When you make a request through our Services or through a restaurant, such as a
							restaurant reservation, joining a restaurant waitlist, making a payment to a
							restaurant through our Services, purchasing an event ticket, or if you are a guest of
							the person making the request, we necessarily provide certain categories of your
							information to the restaurant to facilitate that request. For requests to dine, this
							information may include your name, profile, time and date of visit, party size, your
							phone number, your dining preferences, guest information, any special requests,
							information or comments that you choose to submit (if any), and your email address.
							Your information is provided to the restaurant, just as it would be if you contacted
							the restaurant directly. If you provide a mobile phone number in connection with your
							request, restaurants may send you text messages regarding your request. Some
							restaurants also require you to provide credit or debit card account information to
							secure your reservation. When you make a reservation at a restaurant that is part of a
							restaurant group, we may also share additional information about your dining activity
							at that restaurant or restaurant group in the past, your dining preferences and/or
							information that we collect from you and third parties that may be relevant to that
							reservation.
						</span>
					</p>
					<p>
						<span style={{ fontWeight: '400' }}>
							In addition to providing you with more customised Services, we may receive certain
							information from participating restaurants from their Point of Sale terminals that we
							analyse to provide aggregate information to the restaurant about their customers.
						</span>
					</p>
					<p>
						<span style={{ fontWeight: '400' }}>
							We also share with restaurants summary reports of feedback from diners. If you provide
							comments about a restaurant through our Services, these comments may be shared with or
							may be accessible by that restaurant, and the restaurant may respond to you. We will
							not tie your comments with other information that can identify you directly, but a
							restaurant may be able to tell who you are from your comments, particularly if you
							give your name in the comments or provide contact information, such as an email
							address.
						</span>
					</p>
					<p>
						<strong>
							Sharing with Restaurants and Restaurant Affiliates as a processor or service provider
							on their behalf.&nbsp;
						</strong>
						<span style={{ fontWeight: '400' }}>
							We act as a processor or service provider for Restaurants and Restaurant Affiliates in
							certain circumstances as described above. In providing these services, we may, at the
							direction of the restaurant, share certain of your information with the
							restaurant&rsquo;s affiliated restaurants (such as affiliated brands), restaurant
							group and/or restaurants with the same brand or parent-brand (collectively, such
							restaurant&rsquo;s &ldquo;
						</span>
						<strong>restaurant group</strong>
						<span style={{ fontWeight: '400' }}>
							&rdquo;), or other entities associated with the restaurant (such as the parent entity
							of the restaurant group or affiliated hotels) and/or their service providers
							(collectively, the associated entities and service providers, the restaurant&rsquo;s "
						</span>
						<strong>restaurant affiliates</strong>
						<span style={{ fontWeight: '400' }}>
							") under Wabi&rsquo;s programs for the following purposes:
						</span>
					</p>
					<ol type="a">
						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Customised services</strong>
							<span style={{ fontWeight: '400' }}>
								: restaurants may share your information (such as meal or seating preferences or
								special occasions) with their restaurant affiliates and restaurant group to enhance
								the hospitality the restaurant group provides you when you dine with them (such as
								trying to seat you by a window, if you previously expressed a preference for window
								seating).
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Operations &amp; Service Improvements</strong>
							<span style={{ fontWeight: '400' }}>
								: to support operations, improve the restaurant&rsquo;s table and shift planning,
								improve their hospitality services, including supporting a loyalty program you have
								chosen to participate in.
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Marketing</strong>
							<span style={{ fontWeight: '400' }}>
								: as permitted by applicable law, to perform analytics and tailor marketing to you.
							</span>
						</li>
					</ol>
					<p>
						<span style={{ fontWeight: '400' }}>
							For more information, please feel free to contact the restaurants with which you dine
							or book reservations, or contact us using the contact information listed in
							the&nbsp;How to Contact Us&nbsp;section below. To learn more about your choices
							related to how we share your information with restaurant groups and restaurant
							affiliates under our programs, please see the&nbsp;Your Choices and Rights section
							below.&nbsp;
						</span>
					</p>
					<p>
						<strong>Sharing with Our Business Partners.&nbsp;</strong>
						<span style={{ fontWeight: '400' }}>
							We share your information with other third-party business partners for their own
							marketing purposes. These third parties include online advertisers or ad tech
							companies, who may provide you with targeted advertising and marketing communications,
							where permitted under law. The information we share includes information collected
							through your use of our Services (e.g., bookings, reservations, or other purchases)
							and information we collect about you through the use of cookies and similar
							technologies (e.g., information about the websites you visit; information about your
							searches, including the cities or neighbourhoods you search in, the type of restaurant
							or cuisine you searched for, price range, intended dining date, and the number of
							diners).
						</span>
					</p>
					<p>
						<span style={{ fontWeight: '400' }}>
							For example, if you frequently book reservations at Italian restaurants, we use that
							information to help a particular Italian-themed restaurant group offer you a
							promotion.
						</span>
					</p>
					<p>
						<span style={{ fontWeight: '400' }}>
							To learn more about your choices related to how we share your information with our
							business partners, please see the&nbsp;Your Choices and Rights&nbsp;section below.
						</span>
					</p>
					<p>
						<strong>Sharing with Social Networking Services.&nbsp;</strong>
						<span style={{ fontWeight: '400' }}>
							&nbsp;&nbsp;Our Services allow you to connect and share your actions, comments,
							content, and information publicly or with friends. Our Services may also allow you to
							connect with us on, share on, and use third-party platforms, including those on which
							Wabi has a presence. Please be mindful of your personal privacy needs and the privacy
							needs of others as you choose whom to connect with and what to share and make public.
							We cannot control the privacy or security of information you choose to make public or
							share with others. Wabi also does not control the privacy practices of third-party
							platforms. Please contact those sites and services directly to learn about their
							privacy practices.
						</span>
					</p>
					<p>
						<strong>Sharing to Process Payment Information.&nbsp;</strong>
						<span style={{ fontWeight: '400' }}>
							To use certain Services (such as to make reservations at certain restaurants; to make
							payments to certain restaurants or to secure reservations; and to purchase tickets to
							events, or other products or services), we require credit or debit card account
							information. When you submit your credit or debit card account information through our
							Services, we share that information with restaurants, third-party payment processors,
							and other third-party service providers (including, but not limited to, vendors who
							provide fraud detection services to us and other third parties) to the extent
							necessary to meet our contractual obligations to you (e.g., to secure your reservation
							or make a payment to a restaurant where required), to meet our legitimate interests in
							preventing fraud and other misuse of our platforms, or with your consent where this is
							required by law. In particular:
						</span>
					</p>
					<ol type="a">
						<li style={{ fontWeight: '400' }} aria-level="1">
							<span style={{ fontWeight: '400' }}>
								When you use a credit or debit card to secure a reservation through our Services, we
								provide your credit or debit card account information (including card number and
								expiration date, but excluding the CVV number) to our third-party payment service
								providers and the applicable restaurant.
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<span style={{ fontWeight: '400' }}>
								When you initially provide your credit or debit card account information through our
								Services in order to use our restaurant payment services, we provide your credit or
								debit card account information to our third-party payment service providers. As
								explained in the Wabi Terms of Use, these third parties may store your credit or
								debit card account information so you can use our restaurant payment services
								through our Services in the future, to the extent permitted by local law.
							</span>
						</li>

						<p>&nbsp;</p>

						<li style={{ fontWeight: '400' }} aria-level="1">
							<span style={{ fontWeight: '400' }}>
								For information about the security of your credit or debit card account information,
								see the&nbsp;How We Store and Protect Your Information&nbsp;section below.
							</span>
						</li>
					</ol>
					<p>&nbsp;</p>
					<p>
						<strong>Sharing with Other Service Providers.&nbsp;</strong>
						<span style={{ fontWeight: '400' }}>
							We share information with third-party vendors, consultants, and other service
							providers who perform services or functions on our behalf (e.g., hosting or operating
							our Services, data collection, reporting, ad response measurement, site analytics,
							data analysis, delivering marketing messages and advertisements, processing credit
							card payments, and providing fraud detection services). We do not authorise these
							third parties to use or disclose your information for purposes other than for which it
							has been provided. We require these third parties to maintain and implement security
							measures to protect your information from unauthorised access or processing.
						</span>
					</p>
					<ol start="5">
						<p>&nbsp;</p>
						<li>
							<strong>How we store and protect your information&nbsp;</strong>
						</li>
						<hr></hr>
					</ol>
					<p>
						<span style={{ fontWeight: '400' }}>
							Wabi maintains commercially-reasonable technical, administrative, and physical
							security measures designed to protect your information from loss, misuse, unauthorised
							access, disclosure, alteration, and destruction.
						</span>
					</p>
					<p>
						<span style={{ fontWeight: '400' }}>
							When your credit or debit card account information is being transmitted to our
							Services or through our Services, it will be protected by cryptographic protocols. To
							be clear, Wabi does not itself store your credit or debit card account information,
							and we do not have direct control over or responsibility for your credit or debit card
							account information. We use third party payment processors that are the controllers of
							your credit card information. Our contracts with third parties that receive your
							credit or debit card account information require them to keep it secure and
							confidential.
						</span>
					</p>
					<p>
						<span style={{ fontWeight: '400' }}>
							However, we cannot guarantee that transmissions of your credit or debit card account
							information or your other information will always be secure or that unauthorised third
							parties will never be able to defeat the security measures taken by Wabi or our
							third-party service providers. Except to the extent that liability cannot be excluded
							or limited due to applicable law, we assume no liability or responsibility for
							disclosure of your information due to errors in transmission, unauthorised third-party
							access, or other causes beyond our control.
						</span>
					</p>
					<p>
						<span style={{ fontWeight: '400' }}>
							You play an important role in keeping your information secure. You should not share
							your user name, password, or other security information for your Wabi account with
							anyone. If we receive instructions using your user name and password, we will assume
							you have authorised the instructions. If you have reason to believe that your
							interaction with us is no longer secure (e.g., if you feel that the security of any
							account you might have with us has been compromised), please contact us immediately as
							detailed in the&nbsp;How to Contact Us&nbsp;section below.
						</span>
					</p>
					<p>
						<strong>Retention.&nbsp;</strong>
						<span style={{ fontWeight: '400' }}>
							We may retain your personal information for as long as your account is active and for
							a period of time thereafter to allow you to re-activate your account without loss of
							information. We may also retain your personal information as necessary to:
						</span>
					</p>
					<ol type="a">
						<li style={{ fontWeight: '400' }} aria-level="1">
							<span style={{ fontWeight: '400' }}>
								Maintain logs and business records for analysis, security, and/or audit purposes
							</span>
						</li>
						<li style={{ fontWeight: '400' }} aria-level="1">
							<span style={{ fontWeight: '400' }}>
								Comply with record retention requirements under the law
							</span>
						</li>
						<li style={{ fontWeight: '400' }} aria-level="1">
							<span style={{ fontWeight: '400' }}>
								Deal with any complaints regarding the Services; and
							</span>
						</li>
						<li style={{ fontWeight: '400' }} aria-level="1">
							<span style={{ fontWeight: '400' }}>
								Comply with our legal obligations, protect or defend our rights, resolve disputes
								and enforce our contracts
							</span>
						</li>
					</ol>
					<ol start="6">
						<p>&nbsp;</p>
						<li>
							<strong>Cookies</strong>
						</li>
						<hr></hr>
					</ol>
					<p>
						<span style={{ fontWeight: '400' }}>
							When you use or visit the Services, we collect information about your usage and
							activity using cookies, web beacons, and other technologies. Third parties may also
							view, edit, or set their own cookies. We and our third-party service providers, our
							group companies, and other business partners may also place web beacons for these
							third parties. The use of these technologies by third parties is subject to their own
							privacy policies and is not covered by this Policy, except as required by law.&nbsp;
						</span>
					</p>
					<ol start="7">
						<p>&nbsp;</p>
						<li>
							<strong>Your choices and rights</strong>
						</li>
						<hr></hr>
					</ol>
					<p>
						<strong>Email.&nbsp;</strong>
						<span style={{ fontWeight: '400' }}>
							If you no longer want to receive marketing and promotional emails from Wabi, you may
							click on the &ldquo;unsubscribe&rdquo; link in such emails to opt-out of future
							marketing email communications. If you have an Wabi account, you may also opt-out of
							marketing emails in your account settings. Please note that even if you opt-out of
							receiving marketing communications from one or all of our Services, we will still send
							you service-related communications, such as confirmations of any future reservations
							you make.
						</span>
					</p>
					<p>
						<strong>Push Notifications (on Mobile Devices).&nbsp;</strong>
						<span style={{ fontWeight: '400' }}>
							You can use the settings on your mobile device to enable or turn off mobile push
							notifications from Wabi.
						</span>
					</p>
					<p>
						<strong>Text Messages.&nbsp;</strong>
						<span style={{ fontWeight: '400' }}>
							If you no longer want to receive text messages from Wabi, reply&nbsp;
						</span>
						<strong>STOP</strong>
						<span style={{ fontWeight: '400' }}>
							&nbsp;(or as otherwise instructed) to the text message.&nbsp;&nbsp;If you have an Wabi
							account, you may also adjust your account settings to opt-out of text messages.
						</span>
					</p>
					<p>
						<strong>Cookies and Interest-Based Advertising.&nbsp;</strong>
						<span style={{ fontWeight: '400' }}>
							To exercise choices regarding cookies set through our websites or Services. We
							currently do not employ technology that recognises &ldquo;do-not-track&rdquo; signals
							from your browser.
						</span>
					</p>
					<p>
						<strong>Application Location.</strong>
						<span style={{ fontWeight: '400' }}>
							&nbsp;As explained in more detail in the&nbsp;Information We Collect and
							Use&nbsp;section above, we collect information about your location if you enable
							location services through the settings in your mobile device, or with your consent, as
							may be required by law. You can change the privacy settings of your device at any time
							to turn off the sharing of this location information with our Services. If you choose
							to turn off location services, this could affect certain features of our Services. If
							you have specific questions about the privacy settings of your device, we suggest you
							contact the manufacturer of your device or your mobile service provider for help.
						</span>
					</p>
					<p>
						<strong>
							&nbsp;Choices about the collection of information from restaurant point of sale
							terminals.&nbsp;
						</strong>
						<span style={{ fontWeight: '400' }}>
							As described above, if you make a booking with certain participating restaurants
							through our Services we will receive information about your dining experience from the
							restaurant's point of sale terminal. You can opt-out of us receiving this information
							via the booking confirmation page or your Wabi account preferences.
						</span>
					</p>
					<p>
						<strong>Sharing with third parties.&nbsp;</strong>
						<span style={{ fontWeight: '400' }}>
							As described above in the&nbsp;How We Share Your Information
						</span>
						<strong>&nbsp;</strong>
						<span style={{ fontWeight: '400' }}>
							section, we share your information with third parties for their own purposes.
						</span>
					</p>
					<p>
						<span style={{ fontWeight: '400' }}>
							You can opt-out of us sharing your information with:
						</span>
					</p>
					<ol type="a">
						<li style={{ fontWeight: '400' }} aria-level="1">
							<strong>Other business partners</strong>
							<span style={{ fontWeight: '400' }}>
								, who use information for their own marketing purposes.
							</span>
						</li>
					</ol>
					<p>
						<span style={{ fontWeight: '400' }}>
							You can opt-out of such sharing by changing your Wabi account preferences. You may
							also send such opt-out requests to us as described in the&nbsp;How to Contact
							Us&nbsp;section below.&nbsp;
						</span>
					</p>
					<p>
						<strong>Access and Correction of Your Information.&nbsp;</strong>
						<span style={{ fontWeight: '400' }}>
							If you have created an online account with us and would like to update the information
							you have provided to us, you can access your account to view and update your
							information. You may also contact us as described in the&nbsp;How to Contact Us
						</span>
						<strong>&nbsp;</strong>
						<span style={{ fontWeight: '400' }}>section below.</span>
					</p>
					<p>
						<strong>Legal Rights.&nbsp;</strong>
						<span style={{ fontWeight: '400' }}>
							Where applicable under local law, you may have the following rights regarding your
							personal information: the right to access personal information we hold, and in some
							situations, the right to have that personal information corrected or updated, erased,
							restricted, or delivered to you or a third party in a usable electronic format (the
							right to data portability). Where applicable, you may also object to how we use your
							personal information if the legal basis for processing that information is our
							legitimate interest. Where we are using your personal information on the basis of your
							consent, and where applicable under local law, you have the right to withdraw that
							consent at any time. Where you have granted consent to receive direct marketing
							communications from us, and where applicable under local law, you may withdraw that
							consent at any time.
						</span>
					</p>
					<p>
						<span style={{ fontWeight: '400' }}>
							If you wish to exercise legal rights you may have under applicable law, please submit
							your request to&nbsp;
						</span>
						<span style={{ fontWeight: '400' }}>privacy@Wabi.com.</span>
						<span style={{ fontWeight: '400' }}>
							&nbsp;&nbsp;So that we can better process your request, please provide the email you
							use to log into your Wabi account. If you do not have an Wabi account, please provide
							the email you used to make requests or to use our Services.
						</span>
					</p>
					<p>
						<span style={{ fontWeight: '400' }}>
							Where applicable, you may also have the right to register a complaint to your local
							data protection authority. For residents of Australia, if you are not satisfied with
							the outcome of your complaint after first contacting us, you may wish to contact the
							Office of the Australian Information Commissioner; for more information, please refer
							to&nbsp;
						</span>
						<a href="http://www.oaic.gov.au/">
							<span style={{ fontWeight: '400' }}>http://www.oaic.gov.au</span>
						</a>
						<span style={{ fontWeight: '400' }}>.</span>
					</p>
					<ol start="8">
						<p>&nbsp;</p>
						<li>
							<strong>International transfers of information</strong>
						</li>
						<hr></hr>
					</ol>
					<p>
						<span style={{ fontWeight: '400' }}>
							Information about you will be transferred to, or accessed by, entities located around
							the world as described in this Policy. Some of these entities may be located in
							countries (such as the United States) that do not provide an equivalent level of
							protection for personal information as your home country.
						</span>
					</p>
					<p>
						<span style={{ fontWeight: '400' }}>
							&nbsp;We have put in place safeguards to provide adequate protection for transfers of
							certain information, in accordance with applicable legal requirements. For more
							information on the appropriate safeguards in place, or to request a copy of these
							safeguards, please contact us using the contact details listed in the&nbsp;How to
							Contact Us&nbsp;section below.
						</span>
					</p>
					<ol start="9">
						<p>&nbsp;</p>
						<li>
							<strong>Links to other websites</strong>
						</li>
						<hr></hr>
					</ol>
					<p>
						<span style={{ fontWeight: '400' }}>
							Our Services contain links to other websites or services that are not owned or
							controlled by Wabi, including links to websites of restaurants and restaurant
							affiliates and our advertisers, our group companies, and other business partners. This
							Policy only applies to information collected by our Services. We have no control over
							these third party websites, and your use of third party websites and features are
							subject to privacy policies posted on those websites. We are not responsible or liable
							for the privacy or business practices of any third party websites linked to our
							Services. Your use of third parties' websites linked to our Services is at your own
							risk, so we encourage you to read the privacy policies of any linked third party
							websites when you leave one of our Services.
						</span>
					</p>
					<ol start="10">
						<p>&nbsp;</p>
						<li>
							<strong>Children</strong>
						</li>
						<hr></hr>
					</ol>
					<p>
						<span style={{ fontWeight: '400' }}>
							Our Services are not directed at or intended for use by children. We do not knowingly
							collect information from, children under 16 years of age. If you become aware that
							your child or any child under your care has provided us with information without your
							consent, please contact us at using the contact details listed in the&nbsp;How to
							Contact Us&nbsp;section below.
						</span>
					</p>
					<ol start="11">
						<p>&nbsp;</p>
						<li>
							<strong>Changes to this policy</strong>
						</li>
						<hr></hr>
					</ol>
					<p>
						<span style={{ fontWeight: '400' }}>
							Except to the extent limited by applicable law, we will update this Privacy Policy
							from time to time to reflect changes in our privacy practices, legal requirements, and
							other factors by prominently posting notice of the update on our Services. Changes to
							our Privacy Policy will be effective when posted and the new effective date will be
							identified.&nbsp;
						</span>
					</p>
					<p>
						<span style={{ fontWeight: '400' }}>
							If we make any changes to the Privacy Policy that materially impact previously
							collected personal information about you, we will make reasonable efforts to provide
							notice and obtain consent to any such changes as may be required by law.
						</span>
					</p>
					<p>
						<span style={{ fontWeight: '400' }}>
							To request a copy of this Policy, or to request a copy of the Privacy Policy in place
							at the time you signed up for an account, please contact us at the details below.
						</span>
					</p>
					<ol start="12">
						<p>&nbsp;</p>
						<li>
							<strong>How to contact us</strong>
						</li>
						<hr></hr>
					</ol>
					<p>
						<span style={{ fontWeight: '400' }}>
							If you have any questions about this Policy or the way in which your personal
							information has been used, please contact us by email at&nbsp;
						</span>
						<span style={{ fontWeight: '400' }}>privacy@Wabi.com&nbsp;</span>
						<span style={{ fontWeight: '400' }}> by postal mail at:</span>
					</p>
					<p>
						<span style={{ fontWeight: '400' }}>&nbsp;</span>
					</p>
					<p>
						<span style={{ fontWeight: '400' }}>Wabi Pty Ltd</span>
					</p>
					<p>
						<span style={{ fontWeight: '400' }}>
							634 glenferrie road Hawthorn, 3122, Melbourne, VIC, Australia
						</span>
					</p>
					<p>
						<span style={{ fontWeight: '400' }}>
							You can also contact your local Wabi directly. You can identify the Wabi company that
							hosts a particular Service and that is responsible for your information{' '}
						</span>
						<a href="mailto:wabi@wabify.com" style={{ fontWeight: '400' }}>
							here
						</a>
						.
					</p>
					<p>
						<br />
						<br />
						<br />
						<br />
						<br />
					</p>
				</Container>
			</HomePageHero.Wrapper>
			<Footer />
		</div>
	);
};

export default Privacy;
