import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import SpecialDaysItem from './SpecialDaysItem';

const Wrapper = styled.div`
	position: relative;
	display: inline-flex;
	width: 90%;
	height: 97%;
`;

const TableContainer = styled.table`
	height: 577px;
	width: 100%;
	overflow: auto;
	user-select: none;
	// margin-top: 15px;
	border-radius: 0.8em;
	background: transparent;
`;

const Column = styled.td`
	border-left: 1px solid #3a444b;
	border-right: 1px solid #3a444b;
	color: #222a2f;
	user-select: none;
	justify-content: center;
	background: transparent;
`;

const Rows = styled.tr`
	// width: 122px;
	height: 40px;
	border-top: 1px solid #3a444b;
	border-bottom: 1px solid #3a444b;
	color: #222a2f;
	user-select: none;
	background: transparent;
`;

const TimeContainer = styled.ul`
	color: #707070;
	height: auto;
	width: 65px;
	padding: 28px 0 0 0;
	list-style-type: none;
	user-select: none;
	background: #222a2f;
	// box-shadow: inset 7px 0px 6px -1px #1a2024, inset 1px -3px 5px 0px #2a343a;
	li {
		list-style-type: none;
		height: 40px;
		text-align: end;
		padding-right: 10px;
	}
`;

const ScrollableWrapper = styled.div`
	display: flex;
	position: relative;
	min-width: 184px;
	width: 100%;
	height: 100%;
	// justify-content: center;
	overflow-x: auto;
	background: transparent;
	border-radius: 0.5em;
	user-select: none;

	::-webkit-scrollbar {
		width: 1px;
		height: 1px;
		background-color: #222a2f;
	}
	::-webkit-scrollbar-thumb {
		width: 1px;
		height: 1px;
		border-radius: 20px;
		background-color: #757a7d33;
	}
	::-webkit-scrollbar-corner {
		display: none;
	}
`;

const ShiftField = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	min-width: 184px;
	height: 100%;
	user-select: none;
	background: transparent;

	#item-container {
		margin-left: 65px;
		justify-content: center;
		flex-direction: column;
		padding: 0 20px;

		span {
			position: relative;
			display: flex;
			justify-content: center;
			width: 90%;
			height: fit-content;
			cursor: pointer;
		}
	}
`;

function SpecialDaysTimeTable(props) {
	const [shifts, setshifts] = useState([]);
	const [selectedShift, setSelectedShift] = useState('');
	const hours = 20;
	const formatedHours = [];
	const tableRows = [];

	for (let i = -2; i <= hours; i++) {
		formatedHours.push(moment(i * 3600 * 1000).format('HH:mm'));
	}

	for (let i = 0; i <= 22; i++) {
		tableRows.push(
			<Rows id={'row' + i} key={Math.random()}>
				<Column key={Math.random()} />
			</Rows>,
		);
	}

	const getItemHeight = (top, seconds) => {
		let int = 10;
		let count = -1;
		for (let time = 18000; time <= seconds; time += 900) {
			++count;
			if (time === seconds) {
				// let newCount = count * 2;
				let pixel = int * count;
				pixel = pixel - top;
				return pixel;
			}
		}
	};

	const getTopPosition = (seconds) => {
		let int = 10;
		let count = -1;

		for (let time = 18000; time <= seconds; time += 900) {
			++count;
			if (time === seconds) {
				// let newCount = count * 2;
				let pixel = int * count;
				return pixel;
			}
		}
	};

	const selectShift = (shift, e) => {
		e.stopPropagation();
		if (!props.status) {
			return;
		}
		setSelectedShift(shift);
		props.selectShiftCallback(shift);
	};

	const convertPixeltoTime = (pixel) => {
		let int = 900;
		let count = -1;

		for (let pxl = 0; pxl <= pixel; pxl += 10) {
			++count;
			if (pxl === pixel) {
				let seconds = int * count;
				return seconds + 18000;
			}
		}
	};

	const resizeCallbackHandler = (top, bottom) => {
		let north = convertPixeltoTime(top);
		let south = convertPixeltoTime(bottom);
		let shift = props.selectedShift;
		let newShifts = shifts;
		let idx = newShifts.findIndex((e) => e.id === shift.id);
		shift = { ...shift, start_time: north, end_time: south };
		newShifts.splice(idx, 1, shift);
		setSelectedShift(shift);
		props.updateSpecialDayShiftTime(shift);
	};

	const dragShiftCallback = (top, bottom) => {
		let north = convertPixeltoTime(top);
		let south = convertPixeltoTime(bottom);
		let shift = props.selectedShift;
		let newShifts = shifts;
		let idx = newShifts.findIndex((e) => e.id === shift.id);
		shift = { ...shift, start_time: north, end_time: south };
		newShifts.splice(idx, 1, shift);
		setSelectedShift(shift);
		props.updateSpecialDayShiftTime(shift);
	};

	useEffect(() => {
		if (props.shifts) {
			setshifts(props.shifts);
		}
	}, [props.shifts]);

	return (
		<Wrapper>
			<ScrollableWrapper
				style={
					props.isShiftSelected
						? { width: '90%', transition: 'ease 250ms' }
						: { width: '100%', transition: 'ease 250ms' }
				}
			>
				<div style={{ position: '-webkit-sticky', position: 'sticky', left: '0px', zIndex: 2 }}>
					<TimeContainer>
						{/* <Button onClick={getTopPosition} style={{ color: 'black' }} /> */}
						{formatedHours.length > 0
							? formatedHours.map((t, key) => {
									return <li key={key}>{t}</li>;
							  })
							: ''}
					</TimeContainer>
				</div>
				<ShiftField style={{ position: 'absolute' }}>
					<div id="item-container" style={{ height: '921px', width: '100%', minWidth: '80%' }}>
						{shifts.length > 0
							? shifts.map((shift, key) => {
									return (
										<span key={key} onClick={(e) => selectShift(shift, e)}>
											<SpecialDaysItem
												status={props.status}
												isShiftSelected={
													props.isShiftSelected
														? selectedShift.id === shift.id
															? true
															: false
														: false
												}
												color={shift.shift_color}
												id={shift.id}
												key={shift.id}
												name={shift.name}
												top={getTopPosition(shift.start_time)}
												height={getItemHeight(getTopPosition(shift.start_time), shift.end_time)}
												itemId={shift.name}
												resizeCallbackHandler={resizeCallbackHandler}
												dragShiftCallback={dragShiftCallback}
											/>
										</span>
									);
							  })
							: ''}
					</div>
				</ShiftField>
				<TableContainer>
					<tbody style={{ borderColor: 'none' }}>{tableRows}</tbody>
				</TableContainer>
			</ScrollableWrapper>
		</Wrapper>
	);
}

export default SpecialDaysTimeTable;
