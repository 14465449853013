import React, { useState } from 'react';
import styled from 'styled-components';
import plusIcon from 'assets/images/booking/create-booking/icon-add-white.png';
import { SHIFTTYPE } from 'constants/enum';

const Wrapper = styled.div`
	position: relative;
	display: inline-flex;
	justify-content: space-between;
	width: 100%;
	height: 100%;
`;

const DropDown = styled.ul`
	position: absolute;
	display: block;
	left: -40px;
	bottom: -40px;
	flex-direction: column;
	width: 121%;
	height: 100%;
	background: transparent;
	color: #ffffff;
	font-size: 10px;
	font-weight: 550;
	list-style-type: none;
	user-select: none;
	z-index: 2;
`;

const DropDownOptions = styled.li`
	// position: absolute;
	width: 100%;
	left: 0;
	background: #ffffff;
	color: black;
	display: flex;
	white-space: pre;
	padding: 7px;
	cursor: pointer;
	z-index: 2;

	img {
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-o-user-select: none;
		user-select: none;
		margin-right: 5px;
	}
`;

const Container = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	cursor: pointer;
	width: 80%;
	user-select: none;

	p {
		white-space: nowrap;
		font-size: 10px;
		font-weight: 550;
	}
`;

function DropDownPicker(props) {
	const [selected, setSelected] = useState('');
	const [id, setId] = useState(props.initVal ? props.initVal : '');
	const [isOpen, setIsOpen] = useState(false);

	const toggle = () => {
		setIsOpen(!isOpen);
	};

	const handleSelect = (val) => {
		setSelected(val);
		setId(val.id);
		if (props.id === 0) {
			props.dropDownCallback(val.id, 0);
		} else if (props.id === 1) {
			props.dropDownCallback(val.id, 1);
		} else if (props.id === 2) {
			props.dropDownCallback(val.id, 2);
		}
	};

	const addStation = () => {
		props.setOpenAddStationModal(true);
		console.log('clicked');
	};

	const checkTitle = (val) => {
		let title;
		switch (val) {
			case SHIFTTYPE.shift:
				title = 'Shift';
				break;
			case SHIFTTYPE.requestedOff:
				title = 'Requested Off';
				break;
			case SHIFTTYPE.onLeave:
				title = 'On Leave';
				break;
		}
		return title;
	};

	const getInitialFloor = (id, data) => {
		if (id) {
			const initState = data.find((e) => e.id === id);
			return initState.floor_name;
		} else {
			return 'Select Floor';
		}
	};

	const getInitialStation = (id, data) => {
		if (props.id === 1 && data.length > 0) {
			let initState = data.find((e) => e.id === id);
			// console.log(initState);
			return initState.name;
		}
	};

	return (
		<Wrapper>
			<label>{props.label}</label>
			{props.id === 0 ? (
				<Container onClick={toggle}>
					{isOpen ? (
						<DropDown>
							{props.data.length > 0
								? props.data.map((floor, key) => {
										return (
											<DropDownOptions
												onClick={() => handleSelect(floor)}
												key={key}
												style={id === floor.id ? { color: '#ff7383' } : {}}
											>
												{floor.floor_name}
											</DropDownOptions>
										);
								  })
								: ''}
						</DropDown>
					) : (
						<></>
					)}
					{props.initVal ? (
						<p>{getInitialFloor(props.initVal, props.data)}</p>
					) : selected.floor_name ? (
						<p>{selected.floor_name}</p>
					) : (
						<p>Select Floor</p>
					)}
				</Container>
			) : props.id === 1 ? (
				<Container onClick={toggle}>
					{isOpen ? (
						<DropDown>
							{props.data.length > 0 ? (
								props.data.map((station, key) => {
									if (key === 0) {
										return (
											<div key={key}>
												<DropDownOptions
													onClick={() => addStation()}
													style={{ background: '#ff7383', color: '#ffffff' }}
												>
													<img src={plusIcon} width={15} height={15} alt="add icon" />
													Add Station
												</DropDownOptions>
												<DropDownOptions
													onClick={() => handleSelect(station)}
													style={id === station.id ? { color: '#ff7383' } : {}}
												>
													{station.name}
												</DropDownOptions>
											</div>
										);
									} else {
										return (
											<DropDownOptions
												onClick={() => handleSelect(station)}
												key={key}
												style={id === station.id ? { color: '#ff7383' } : {}}
											>
												{station.name}
											</DropDownOptions>
										);
									}
								})
							) : (
								<DropDownOptions
									onClick={() => addStation()}
									key={-1}
									style={{ background: '#ff7383', color: '#ffffff' }}
								>
									<img src={plusIcon} width={15} height={15} alt="add icon" />
									Add Station
								</DropDownOptions>
							)}
						</DropDown>
					) : (
						<></>
					)}
					{props.initVal ? (
						<p>{getInitialStation(props.initVal, props.data)}</p>
					) : selected.name ? (
						<p>{selected.name}</p>
					) : (
						<p></p>
					)}
				</Container>
			) : (
				<Container onClick={toggle}>
					{isOpen ? (
						<DropDown>
							{props.data.length > 0
								? props.data.map((type, key) => {
										return (
											<DropDownOptions
												onClick={() => handleSelect(type)}
												key={key}
												style={id === type.id ? { color: '#ff7383' } : {}}
											>
												{type.type_name}
											</DropDownOptions>
										);
								  })
								: ''}
						</DropDown>
					) : (
						<></>
					)}

					{selected.name ? <p>{selected.name}</p> : <p>{checkTitle(props.initVal)}</p>}
				</Container>
			)}
		</Wrapper>
	);
}

export default DropDownPicker;
