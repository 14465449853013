import React, {useState} from 'react';

import { getFloorName } from '../../../../../utils/Utility';

import partySizeIcon from '../../../../../assets/images/booking/create-booking/part-size-icon.png'

function TableList(props) {

    const selectedTable = (table) => {
        if (props.isMultipleTable) {
            let tables = props.selectedTables !== null ? [].concat(props.selectedTables) : []
            if (tables !== null) {
                if (tables.length !== 0) {
                    const index = tables.findIndex(e => e.id === table.id)
                    if (index !== -1) {
                        tables.splice(index, 1)
                        props.selectedTableHandler(tables)
                    } else {
                        props.selectedTableHandler([...tables, table])
                    }
                } else {
                    props.selectedTableHandler([...tables, table])
                }
            } else {
                props.selectedTableHandler([table])
            }
        } else {
            props.selectedTableHandler([table])
        }
    }

    return (
        <div className="table-list">
            {
                props.tables.length === 0 ?
                    <label>No available table</label> :
                    props.tables.map(item => {
                        return <div 
                                    key={Math.random()}
                                    className={ props.selectedTables !== null ? props.selectedTables.filter(table => table.id === item.id)[0] !== undefined ? 'table-list-item table-list-item--selected' : 'table-list-item' : 'table-list-item'}
                                    onClick={() => selectedTable(item)}>
                                        <div key={Math.random()} className='list-left'>{ item.name }</div>
                                        <div key={Math.random()} className='list-center'>
                                            <label key={Math.random()}>{ getFloorName(item.floor_id, props.floors) }</label>
                                        </div>
                                        <div key={Math.random()} className='list-right'>
                                            <label key={Math.random()}>{ item.capacity_min + '-' + item.capacity_max }</label>
                                            <img
                                                 key={Math.random()}
                                                src={partySizeIcon}
                                                width="20"
                                                height="20"
                                                className="d-inline"
                                                style={{ marginLeft: 10, marginTop: -1 }}
                                                alt="checker"
                                            />
                                        </div>
                                </div>
                    })
            }
        </div>
    )
}

export default TableList;