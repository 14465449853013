import React, { useState } from 'react';
import MuiButton from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { ButtonGroup } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

const DialogTitle = withStyles((theme) => ({
	root: {
		color: '#9b9b9b',
		fontSize: '0.5em',
		padding: '15px 20px',
	},
}))(MuiDialogTitle);

const Button = withStyles((theme) => ({
	root: {
		fontSize: '1em',
		color: '#9e9eef',
		border: '1px solid #e6e6e6;',
		width: '100%',
		textTransform: 'unset',
	},
}))(MuiButton);

const StyledInput = styled.input`
	width: 300px;
	height: 40px;
	box-shadow: inset 1px 2px 3px #1c1c1e4d;
	border-radius: 10px;
	padding: 5px;
`;

function AddGroupModal(props) {
	const [value, setValue] = useState('');

	const onChange = (e) => {
		setValue(e.target.value);
	};

	const handleClose = () => {
		props.closeHandler(!props.open);
		setValue('');
	};

	const submit = () => {
		props.callback(value);
		setTimeout(() => {
			setValue('');
		}, 1000);
	};

	return (
		<div>
			<Dialog onClose={handleClose} aria-labelledby="add-group-modal" open={props.open}>
				<DialogTitle id="add-group-modal">
					<div
						style={{
							fontWeight: 500,
							fontSize: 14,
							marginBottom: 25,
						}}
					>
						{props.title}
					</div>
					<StyledInput value={value} onChange={onChange} />
				</DialogTitle>
				<ButtonGroup>
					<Button style={{ color: '#e6e6e6' }} onClick={handleClose}>
						Cancel
					</Button>
					<Button onClick={submit}>Create</Button>
				</ButtonGroup>
			</Dialog>
		</div>
	);
}

export default AddGroupModal;
