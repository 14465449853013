import React, { useState } from 'react';
import { TableRow, TableCell, Paper } from '@material-ui/core';
import styled from 'styled-components';

const StyledTableCell = styled(TableCell)`
	&& {
		height: 70px;
		margin: 0;
		padding: 1px;
		border-right: 0.1px solid #e3e3e3;
		cursor: pointer;
	}
`;

const ShiftHeader = styled.div`
	width: 100%;
	height: 10px;
	font-size: 14px;
	font-weight: 750;
	margin: 10px;
`;

const Container = styled.div`
	position: relative;
	height: 90%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	padding: 0 3px;
	margin: 10px 0;
`;

const TeamRow = styled.div`
	display: flex;
	flex-direction: row;
`;

function TeamView(props) {
	return (
		<TableRow style={{ minHeight: 88 }}>
			<StyledTableCell>
				<ShiftHeader>{props.shift.name}</ShiftHeader>
				<Container></Container>
			</StyledTableCell>
			<StyledTableCell>
				<ShiftHeader style={{ color: 'transparent' }}>{props.shift.name}</ShiftHeader>
			</StyledTableCell>
			<StyledTableCell>
				<ShiftHeader style={{ color: 'transparent' }}>{props.shift.name}</ShiftHeader>
			</StyledTableCell>
			<StyledTableCell>
				<ShiftHeader style={{ color: 'transparent' }}>{props.shift.name}</ShiftHeader>
			</StyledTableCell>
			<StyledTableCell>
				<ShiftHeader style={{ color: 'transparent' }}>{props.shift.name}</ShiftHeader>
			</StyledTableCell>
			<StyledTableCell>
				<ShiftHeader style={{ color: 'transparent' }}>{props.shift.name}</ShiftHeader>
			</StyledTableCell>
			<StyledTableCell>
				<ShiftHeader style={{ color: 'transparent' }}>{props.shift.name}</ShiftHeader>
			</StyledTableCell>
		</TableRow>
	);
}

export default TeamView;
