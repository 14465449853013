import React, { useState, useEffect } from 'react';
import { TableRow, TableCell, Paper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';
import ShiftPopover from './ShiftPopover';
import { imgUrl } from 'utils/Utility';
import moment from 'moment';
import { SHIFTTYPE, WAGEPAYTYPE, WAGEWEEKENDTYPE } from 'constants/enum';

const StyledPaper = styled(Paper)`
	&& {
		display: flex;
		align-items: center;
		justify-content: center;
		outline: none;
		background: white;
		font-size: 12px;
		font-weight: 550;
		color: #413636;
		user-select: none;
		margin: 0;
		padding: 3px;
		border-right: 0.1px solid #413636;
		border-radius: 0;
	}
`;

const StyledTableCell = styled(TableCell)`
	&& {
		height: 70px;
		margin: 0;
		padding: 1px;
		border-right: 0.1px solid #e3e3e3;
		cursor: pointer;
	}
`;

const EmployeeContainer = styled.div`
	position: relative;
	display: flex;
	width: 125px;
	height: 100%;
	flex-direction: column;
	box-shadow: 0px 1px 2px #1c1c1e4d;
	padding: 2px;
	user-select: none;

	#header {
		display: inline-flex;
		flex-direction: row;
		justify-content: space-evenly;
		font-size: 15px;
		font-weight: 700;
		color: #413636;
		margin-bottom: 37px;
	}

	#initials-container {
		min-width: 40px;
		min-height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
	}

	#name-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	ul {
		position: absolute;
		bottom: 0;
		display: flex;
		flex-direction: column;
		margin: 3px;
		padding: 2px;
		font-size: 11px;
		font-weight: 700;
		width: 95%;
		color: #413636;
		border-radius: 10px;
	}

	li {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	h4 {
		font-size: 11px;
		font-weight: 700;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		width: 75px;
	}

	img {
		border-radius: 50%;
		-webkit-user-drag: none;
		-khtml-user-drag: none;
		-moz-user-drag: none;
		-o-user-drag: none;
		user-drag: none;
	}
`;

const InitialsContainer = styled.div`
	display: flex;
	min-width: 40px;
	min-height: 40px;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
`;

const ShiftItemContainer = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 124px;
	width: auto;
	height: 100%;
`;

const ShiftItem = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	max-width: 124px;
	width: auto;
	height: 40px;
	border-radius: 10px;
	background: #fed3d4;
	box-shadow: 1px 2px 4px #1c1c1e4d;
	color: #b1777c;
	font-size: 9px;
	font-weight: 750;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	padding: 4px;
	margin: 1px;
`;

function EmployeeSchedule(props) {
	const history = useHistory();
	const [anchor, setAnchor] = useState(null);
	const [shiftItemAnchor, setShiftItemAnchor] = useState(null);
	const [employeeShifts, setEmployeeShifts] = useState(props.employeeShifts);
	const [floors, setFloors] = useState(history.location.state.find((e) => e.floors));
	const [employeeRole, setEmployeeRole] = useState(
		props.employmentRoles.find((e) => e.id === props.employee.employment_role_id),
	);
	const [shiftCopy, setShiftCopy] = useState(null);
	// const [totalHours, setTotalHours] = useState(0);
	// const [totalRate, setTotalRate] = useState(0);

	const openPopover = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setAnchor(e.currentTarget);
	};

	const openShiftItemPopover = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setShiftItemAnchor(e.currentTarget);
		// console.log(e.currentTarget);
	};

	const closePopover = () => {
		setAnchor(null);
	};

	const closeShiftItemPopover = () => {
		setShiftItemAnchor(null);
	};

	const handleClickFirstCell = () => {
		props.selectEmployeeCallback(props.employee);
	};

	const addEmployeeSchedule = (shift) => {
		let shifts = employeeShifts;
		let newShift = {
			...shift,
			uuid: Math.random().toString(36).substring(2, 5) + Math.random().toString(36).substring(2, 5),
		};
		shifts.push(newShift);
		setEmployeeShifts(shifts);
		props.employeeShiftsCallback(shifts);
		props.handleAddEmployeeShift(shifts);
	};

	const copyShift = (item) => {
		setShiftCopy(item);
	};

	const updateEmployeeShiftCallback = (shift) => {
		if (shift.uuid) {
			const newShiftsArray = employeeShifts;
			let idx = newShiftsArray.findIndex((e) => e.uuid === shift.uuid);
			newShiftsArray.splice(idx, 1, shift);
			// console.log(newShiftsArray);
			setEmployeeShifts(newShiftsArray);
			props.handleUpdateShift(newShiftsArray);
		} else {
			const newShiftsArray = employeeShifts;
			let idx = newShiftsArray.findIndex((e) => e.id === shift.id);
			newShiftsArray.splice(idx, 1, shift);
			// console.log(newShiftsArray);
			setEmployeeShifts(newShiftsArray);
			props.handleUpdateShift(newShiftsArray);
		}
	};

	const deleteShiftItem = (shift) => {
		if (shift.id !== 0) {
			const newShiftsArray = employeeShifts;
			let idx = newShiftsArray.findIndex((e) => e.id === shift.id);
			let item = { ...newShiftsArray[idx], deleted: true };
			newShiftsArray.splice(idx, 1, item);
			// console.log(newShiftsArray);
			setEmployeeShifts(newShiftsArray);
			props.handleDeleteShift(newShiftsArray);
		} else {
			const newShiftsArray = employeeShifts;
			let idx = newShiftsArray.findIndex((e) => e.uuid === shift.uuid);
			let item = { ...newShiftsArray[idx], deleted: true };
			newShiftsArray.splice(idx, 1, item);
			// console.log(newShiftsArray);
			setEmployeeShifts(newShiftsArray);
			props.handleDeleteShift(newShiftsArray);
		}
	};

	const computeTotalHours = (array) => {
		let hour = 0;
		let minute = 0;
		let date = moment().startOf('minute');
		if (array) {
			const filteredShifts = array.filter((e) => e.employee_id === props.employee.id);
			if (filteredShifts.length > 0) {
				filteredShifts.map((e) => {
					const diff = e.end_time - e.start_time;
					const h = Math.floor(diff / 3600);
					const m = Math.floor((diff % 3600) / 60);
					hour = h + hour;
					minute = minute + m;
					date = e.start_date;
				});
			}
		}

		// console.log(hour);
		return `${hour} h ${moment(date).minute(minute).format('mm')} m`;
	};

	const computeTotalRate = (array) => {
		let rate = 0;
		let emplRate = 0;

		//Main function for base rate computation
		if (array) {
			const filteredShifts = array.filter((e) => e.employee_id === props.employee.id);
			if (filteredShifts.length > 0) {
				filteredShifts.map((e) => {
					const dateOfYear = moment(e.date).dayOfYear();
					const dayOfWeek = moment(e.date).isoWeekday();
					const employee = props.employee;
					if (employee.pay_wages_item.length > 0) {
						emplRate = checkPayWageRate(employee, dateOfYear, dayOfWeek);
					} else {
						emplRate = employee.rate ? parseInt(employee.rate) : 0;
					}
					const diff = e.end_time - e.start_time;
					const hour = Math.floor(diff / 3600);
					rate = emplRate > 0 ? emplRate * hour + rate : rate;

					if (props.employee.pay_wages_item.length > 0) {
						props.employee.pay_wages_item.map((e) => {
							if (e.wage_type === WAGEPAYTYPE.weekend) {
							} else if (e.wage_type === WAGEPAYTYPE.holiday) {
							}
						});
					}
				});
			}
		}

		return (Math.round(rate * 100) / 100).toFixed(2);
	};

	const checkPayWageRate = (empl, dateOfYear, dayOfWeek) => {
		let rate = 0;
		empl.pay_wages_item.map((wage) => {
			if (wage.wage_type === WAGEPAYTYPE.holiday) {
				const wageDateOfYear = moment(wage.holiday_date).dayOfYear();
				if (wageDateOfYear === dateOfYear) {
					rate = wage.rate;
					return rate;
				} else {
					rate = empl.rate ? parseInt(empl.rate) : 0;
					return rate;
				}
			} else if (wage.wage_type === WAGEPAYTYPE.weekend) {
				const wageDayOfWeek = wage.wage_week_end_type === WAGEWEEKENDTYPE.saturday ? 6 : 7;
				if (wageDayOfWeek === dayOfWeek) {
					rate = wage.rate;
					return rate;
				} else {
					rate = empl.rate ? parseInt(empl.rate) : 0;
					return rate;
				}
			}
		});
		return rate;
	};

	const employeeShiftChecker = (array, date) => {
		if (array) {
			const filteredShifts = array.filter((e) => e.employee_id === props.employee.id);
			const shiftItem =
				filteredShifts.length > 0 ? (
					filteredShifts.map((shift) => {
						if (shift.date === date) {
							if (!shift.deleted) {
								if (shift.type === SHIFTTYPE.shift) {
									const floor = floors.floors.find((e) => e.id === shift.floor_id);
									const mStart = moment(shift.date).seconds(shift.start_time);
									const mEnd = moment(shift.date).seconds(shift.end_time);
									const start = shift.start_time;
									const end = shift.end_time;
									const diff = end - start;
									const h = Math.floor(diff / 3600);
									const m = Math.floor((diff % 3600) / 60);

									return (
										<ShiftItem
											key={shift.id === 0 ? shift.uuid : shift.id}
											id={shift.id === 0 ? shift.uuid : shift.id}
											onClick={openShiftItemPopover}
										>
											<span>
												{mStart.format('hh:mm A')} &nbsp;
												{mEnd.format('hh:mm A')}
											</span>
											<span>{floor ? floor.floor_name : ''}</span>
											<span>
												Total Hours: {h} h {moment(shift.date).seconds(m).format('mm')} m
											</span>
											<ShiftPopover
												date={date}
												id={shift.id === 0 ? shift.uuid : shift.id}
												initVal={shift}
												shiftCopy={shiftCopy}
												copyShift={copyShift}
												floors={floors.floors}
												anchor={shiftItemAnchor}
												employee={props.employee}
												stations={props.stations}
												employeeRole={employeeRole}
												deleteShiftItem={deleteShiftItem}
												closePopover={closeShiftItemPopover}
												createStations={props.createStations}
												addEmployeeSchedule={addEmployeeSchedule}
												updateEmployeeShiftCallback={updateEmployeeShiftCallback}
											/>
										</ShiftItem>
									);
								} else if (shift.type === SHIFTTYPE.requestedOff) {
									return (
										<ShiftItem
											id={shift.id === 0 ? shift.uuid : shift.id}
											key={shift.id === 0 ? shift.uuid : shift.id}
											onClick={openShiftItemPopover}
											style={{
												background: '#636363',
												color: '#d3d3d3',
												justifyContent: 'center',
												alignItems: 'center',
											}}
										>
											Requested Off
											<ShiftPopover
												date={date}
												id={shift.id === 0 ? shift.uuid : shift.id}
												initVal={shift}
												shiftCopy={shiftCopy}
												copyShift={copyShift}
												floors={floors.floors}
												anchor={shiftItemAnchor}
												employee={props.employee}
												stations={props.stations}
												employeeRole={employeeRole}
												deleteShiftItem={deleteShiftItem}
												closePopover={closeShiftItemPopover}
												createStations={props.createStations}
												addEmployeeSchedule={addEmployeeSchedule}
												updateEmployeeShiftCallback={updateEmployeeShiftCallback}
											/>
										</ShiftItem>
									);
								} else if (shift.type === SHIFTTYPE.onLeave) {
									return (
										<ShiftItem
											id={shift.id === 0 ? shift.uuid : shift.id}
											key={shift.id === 0 ? shift.uuid : shift.id}
											onClick={openShiftItemPopover}
											style={{
												background: '#d2e9ff',
												color: '#6b80af',
												justifyContent: 'center',
												alignItems: 'center',
											}}
										>
											On Leave
											<ShiftPopover
												date={date}
												id={shift.id === 0 ? shift.uuid : shift.id}
												initVal={shift}
												shiftCopy={shiftCopy}
												copyShift={copyShift}
												floors={floors.floors}
												anchor={shiftItemAnchor}
												employee={props.employee}
												stations={props.stations}
												employeeRole={employeeRole}
												deleteShiftItem={deleteShiftItem}
												closePopover={closeShiftItemPopover}
												createStations={props.createStations}
												addEmployeeSchedule={addEmployeeSchedule}
												updateEmployeeShiftCallback={updateEmployeeShiftCallback}
											/>
										</ShiftItem>
									);
								}
							} else {
								return;
							}
						}
					})
				) : (
					<></>
				);
			return shiftItem;
		}
	};

	return (
		<TableRow style={{ minHeight: 88 }}>
			<StyledTableCell style={{ width: 130 }} onClick={handleClickFirstCell}>
				<EmployeeContainer>
					<div id="header">
						{props.employee.photo ? (
							<img
								src={`${imgUrl + props.employee.photo}`}
								width={40}
								height={40}
								alt="empl-avatar"
							/>
						) : (
							<InitialsContainer style={{ background: props.employee.color }}>
								{props.employee.first_name.charAt(0)} {props.employee.last_name.charAt(0)}
							</InitialsContainer>
						)}
						<div id="name-container">
							<h4>{props.employee.first_name}</h4>
							<h4>{props.employee.last_name}</h4>
						</div>
					</div>
					<ul style={{ background: props.employee.color }}>
						{props.employmentRoles.map((role) => {
							// console.log(role);
							if (role.id === props.employee.employment_role_id) {
								return <li key={role.id}>{role.title}</li>;
							}
						})}
						<li>
							{computeTotalHours(employeeShifts)} h - ${computeTotalRate(employeeShifts)}
						</li>
					</ul>
				</EmployeeContainer>
			</StyledTableCell>
			<StyledTableCell id={1} style={{ width: 124 }} onClick={openPopover}>
				<ShiftItemContainer>
					{employeeShiftChecker(employeeShifts, props.selectedScheduleDate.start_date)}
				</ShiftItemContainer>
				<ShiftPopover
					id={1}
					anchor={anchor}
					shiftCopy={shiftCopy}
					copyShift={copyShift}
					floors={floors.floors}
					employee={props.employee}
					stations={props.stations}
					employeeRole={employeeRole}
					closePopover={closePopover}
					createStations={props.createStations}
					addEmployeeSchedule={addEmployeeSchedule}
					date={props.selectedScheduleDate.start_date}
				/>
			</StyledTableCell>
			<StyledTableCell id={2} style={{ width: 124 }} onClick={openPopover}>
				<ShiftItemContainer>
					{employeeShiftChecker(
						employeeShifts,
						moment(props.selectedScheduleDate.start_date).add(1, 'day').format('YYYY-MM-DD'),
					)}
				</ShiftItemContainer>
				<ShiftPopover
					id={2}
					anchor={anchor}
					shiftCopy={shiftCopy}
					copyShift={copyShift}
					floors={floors.floors}
					employee={props.employee}
					stations={props.stations}
					employeeRole={employeeRole}
					closePopover={closePopover}
					createStations={props.createStations}
					addEmployeeSchedule={addEmployeeSchedule}
					date={moment(props.selectedScheduleDate.start_date).add(1, 'day').format('YYYY-MM-DD')}
				/>
			</StyledTableCell>
			<StyledTableCell id={3} style={{ width: 124 }} onClick={openPopover}>
				<ShiftItemContainer>
					{employeeShiftChecker(
						employeeShifts,
						moment(props.selectedScheduleDate.start_date).add(2, 'day').format('YYYY-MM-DD'),
					)}
				</ShiftItemContainer>
				<ShiftPopover
					id={3}
					anchor={anchor}
					shiftCopy={shiftCopy}
					copyShift={copyShift}
					floors={floors.floors}
					employee={props.employee}
					stations={props.stations}
					employeeRole={employeeRole}
					closePopover={closePopover}
					createStations={props.createStations}
					addEmployeeSchedule={addEmployeeSchedule}
					date={moment(props.selectedScheduleDate.start_date).add(2, 'day').format('YYYY-MM-DD')}
				/>
			</StyledTableCell>
			<StyledTableCell id={4} style={{ width: 124 }} onClick={openPopover}>
				<ShiftItemContainer>
					{employeeShiftChecker(
						employeeShifts,
						moment(props.selectedScheduleDate.start_date).add(3, 'day').format('YYYY-MM-DD'),
					)}
				</ShiftItemContainer>
				<ShiftPopover
					id={4}
					anchor={anchor}
					shiftCopy={shiftCopy}
					copyShift={copyShift}
					floors={floors.floors}
					employee={props.employee}
					stations={props.stations}
					employeeRole={employeeRole}
					closePopover={closePopover}
					createStations={props.createStations}
					addEmployeeSchedule={addEmployeeSchedule}
					date={moment(props.selectedScheduleDate.start_date).add(3, 'day').format('YYYY-MM-DD')}
				/>
			</StyledTableCell>
			<StyledTableCell id={5} style={{ width: 124 }} onClick={openPopover}>
				<ShiftItemContainer>
					{employeeShiftChecker(
						employeeShifts,
						moment(props.selectedScheduleDate.start_date).add(4, 'day').format('YYYY-MM-DD'),
					)}
				</ShiftItemContainer>
				<ShiftPopover
					id={5}
					anchor={anchor}
					shiftCopy={shiftCopy}
					copyShift={copyShift}
					floors={floors.floors}
					employee={props.employee}
					stations={props.stations}
					employeeRole={employeeRole}
					closePopover={closePopover}
					createStations={props.createStations}
					addEmployeeSchedule={addEmployeeSchedule}
					date={moment(props.selectedScheduleDate.start_date).add(4, 'day').format('YYYY-MM-DD')}
				/>
			</StyledTableCell>
			<StyledTableCell id={6} style={{ width: 124 }} onClick={openPopover}>
				<ShiftItemContainer>
					{employeeShiftChecker(
						employeeShifts,
						moment(props.selectedScheduleDate.start_date).add(5, 'day').format('YYYY-MM-DD'),
					)}
				</ShiftItemContainer>
				<ShiftPopover
					id={6}
					anchor={anchor}
					shiftCopy={shiftCopy}
					copyShift={copyShift}
					floors={floors.floors}
					employee={props.employee}
					stations={props.stations}
					employeeRole={employeeRole}
					closePopover={closePopover}
					createStations={props.createStations}
					addEmployeeSchedule={addEmployeeSchedule}
					date={moment(props.selectedScheduleDate.start_date).add(5, 'day').format('YYYY-MM-DD')}
				/>
			</StyledTableCell>
			<StyledTableCell id={7} style={{ width: 124 }} onClick={openPopover}>
				<ShiftItemContainer>
					{employeeShiftChecker(
						employeeShifts,
						moment(props.selectedScheduleDate.start_date).add(6, 'day').format('YYYY-MM-DD'),
					)}
				</ShiftItemContainer>
				<ShiftPopover
					id={7}
					anchor={anchor}
					shiftCopy={shiftCopy}
					copyShift={copyShift}
					floors={floors.floors}
					employee={props.employee}
					stations={props.stations}
					employeeRole={employeeRole}
					closePopover={closePopover}
					createStations={props.createStations}
					addEmployeeSchedule={addEmployeeSchedule}
					date={moment(props.selectedScheduleDate.start_date).add(6, 'day').format('YYYY-MM-DD')}
				/>
			</StyledTableCell>
		</TableRow>
		// {/* <StyledPaper elevation={0}>

		// </StyledPaper>
		// <StyledDayContainer elevation={0}></StyledDayContainer>
		// <StyledDayContainer elevation={0}></StyledDayContainer>
		// <StyledDayContainer elevation={0}></StyledDayContainer>
		// <StyledDayContainer elevation={0}></StyledDayContainer>
		// <StyledDayContainer elevation={0}></StyledDayContainer>
		// <StyledDayContainer elevation={0}></StyledDayContainer>
		// <StyledDayContainer elevation={0} style={{ border: 'none' }}></StyledDayContainer> */}
	);
}

export default EmployeeSchedule;
