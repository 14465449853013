import withDashboardApp from 'components/withDashboardApp';
import React, { useState, useEffect, Fragment } from 'react';
import { Container, Media, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Sidebar from 'react-sidebar';
import styled from 'styled-components';
import { imgUrl } from 'utils/Utility';

// import logoblack from '../assets/images/logo.png';
import logoblack from '../../../assets/images/logo.png';
import DailyViewSales from './DailyViewSales/DailyViewSales';

function POSSettings() {
	const useMountEffect = (fun) => useEffect(fun, []);
	const reducers = useSelector((state) => state.userReducers);
	const profilePic = `${imgUrl}${reducers.business_profile.profile_pic}`;

	useMountEffect(() => {
		console.log('Effect');
	});

	return (
		<Fragment>
			<Container style={{ backgroundColor: 'white' }} fluid>
				<Row>
					<Sidebar
						style={{ backgroundColor: '#222A2F' }}
						sidebarClassName="sidebar-pos-settings"
						sidebar={
							<div>
								<div
									style={{
										borderBottom: '1px solid #e0e0e0',
										paddingBottom: 16,
									}}
								>
									<div
										style={{
											width: 280,
											margin: 'auto',
											textAlign: 'right',
										}}
									>
										<button type="button">
											<img
												alt="logo"
												src={logoblack}
												width="80"
												height="24"
												className="d-inline-block align-top"
												style={{ verticalAlign: 'middle' }}
											/>
										</button>
									</div>
									<div>
										<Media style={{ marginTop: 30, marginLeft: 0 }}>
											<div className="w-avatar">
												<img
													className="clip-circle"
													src={profilePic}
													width="40px"
													height="40px"
													alt=""
												/>
											</div>
											<Media.Body style={{ marginTop: 6, marginLeft: 10 }}>
												<h5 className="business-name">{reducers.business_profile.business_name}</h5>
												<h5 className="business-address">{reducers.business_profile.address}</h5>
											</Media.Body>
										</Media>
									</div>
								</div>
								<div
									style={{
										marginTop: 30,
									}}
									className="menus"
								>
									<div className="menu-item">Day View Sales</div>
								</div>
							</div>
						}
						transitions={false}
						open={true}
						docked={true}
					>
						<DailyViewSales></DailyViewSales>
					</Sidebar>
				</Row>
			</Container>
		</Fragment>
	);
}

export default withDashboardApp(POSSettings);
