import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Backdrop } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import xIcon from 'assets/images/booking/exit-btn-table-details.png';

const StyledBackdrop = withStyles({
	root: {
		background: 'rgb(255 255 255 / 30%);',
		backdropFilter: 'blur(10px)',
	},
})(Backdrop);

const StyledDialog = styled(Dialog)`
	& > .MuiDialog-container > .MuiPaper-root {
		border-radius: 10px;
	}
`;

const Container = styled.div`
	position: relative;
	width: 545px;
	height: 180px;
	padding: 15px 20px;
	user-select: none;
`;

const Title = styled.h4`
	font-size: 20px;
	font-weight: 700;
	margin-bottom: 25px;
	color: #505050;
`;

const StyledNameInput = styled.div`
	position: relative;
	width: 100%;
	height: 40px;
	background: #ffffff;
	box-shadow: 2px 3px 10px #1c1c1e4d;
	border-radius: 1em;
	margin: 10px 0;
	padding-right: 50px;
	padding-top: 5px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	label {
		margin-left: 20px;
		font-size: 14px;
		font-weight: 700;
		color: #1c1c1e;
		white-space: nowrap;
	}

	input {
		text-align: end;
		width: 50%;
		margin-left: 20px;
		font-size: 14px;
		font-weight: 500;
		// z-index: 99;
		// border: 1px solid red;

		&:focus {
			text-align: start;
		}
	}
`;

const Savebutton = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
	height: 35px;
	background: #ffffff;
	box-shadow: 0px 1px 10px #1c1c1e4d;
	border-radius: 8px;
	margin: 20px 0 20px 40%;
	color: #6affc6;
	border: 1px solid #6affc6;
	font-size: 12px;
	font-weight: 700;
	cursor: pointer;

	&:hover {
		background: #ffffff00;
	}

	&:active {
		background: #ffffff;
	}
`;

function AddDialog(props) {
	const [title, setTitle] = useState('');
	// const [description, setDescription] = useState('');

	const handleClose = () => {
		props.handler(!props.isOpen);
	};

	const handleSave = () => {
		handleClose();
		let data = title;
		props.apiHandler(data);
		handleReset();
	};

	const handleOnChange = (e) => {
		setTitle(e.target.value);
	};

	const handleReset = () => {
		setTitle('');
	};

	return (
		<StyledDialog
			// onClose={handleClose}
			aria-labelledby="customized-dialog-title"
			BackdropComponent={StyledBackdrop}
			open={props.isOpen}
		>
			<Container>
				<div
					onClick={handleClose}
					style={{ position: 'absolute', right: 20, top: 20, zIndex: 1, cursor: 'pointer' }}
				>
					<img src={xIcon} height={15} width={15} alt="close-icon" />
				</div>
				<Title>{props.title}</Title>
				<StyledNameInput>
					<label>{props.label}</label>
					<input
						type="text"
						value={title}
						onChange={handleOnChange}
						placeholder={props.placeholder}
					/>
				</StyledNameInput>
				<Savebutton onClick={handleSave}>Save</Savebutton>
			</Container>
		</StyledDialog>
	);
}

export default AddDialog;
