import React, { useState, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import FloorDropDown from './FloorDropDown';
import { Nav, Container, Row, Col, Button, Spinner } from 'react-bootstrap';
import withDashboardApp from '../../../components/withDashboardApp';
import { useDispatch, useSelector } from 'react-redux';
import { List, ListItem, Modal, Backdrop, Fade } from '@material-ui/core';
import { Media } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { DebounceInput } from 'react-debounce-input';
import axios from 'axios';
import {
	apiUrl,
	getCurrentShift,
	getTimeOnly,
	getFullname,
	getBookingStatusColor,
	getFloorName,
	getTableSize,
	getGuestPhoneNum,
	sortBookingByStatus,
	sortBookingBySection,
	getNameStatusColor,
	getTableImg,
	getTableHeight,
	getTableWidth,
	radToDeg,
	getRadHeight,
	getRadWidth,
	getPositionTop,
	getPositionLeft,
	tableNamePositionMarginLeft,
	tableNamePositionMarginTop,
	tableNamePositionLeft,
	tableNamePositionLeftTranslate,
	getBookingOnTable,
	getLabelBookingColor,
	getBookingProgressStatusColo,
	getBookingOnTableByIndex,
	getBookingTimeOnly,
	getAllTime,
	hasConflictBooking,
	getBookingStatusOptions,
	getBookingTableOptions,
	checkIfBookingIsOvertime,
	filterShifts,
	getBookingsOnTable,
} from '../../../utils/Utility';
import { formatDate } from 'react-day-picker/moment';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import SegmentedControl from '../Booking/SegmentedControl';
import CreateBooking from './CreatBooking/Createbooking';
import SelectBookingCalendar from './BookingCalendar/SelectBookingCalendar';

import searchIcon from '../../../assets/images/booking/search-icon.png';
import createBookingIcon from '../../../assets/images/booking/create-booking-icon.png';
import sectionPartySizeIcon from '../../../assets/images/booking/section-party-size-icon.png';
import sectionTableIcon from '../../../assets/images/booking/section-table-icon.png';
import btnTimelineIcon from '../../../assets/images/booking/btn-timeline-icon.png';
import btnTimelineSelectedIcon from '../../../assets/images/booking/btn-timeline-selected-icon.png';
import btnUpArrowIcon from '../../../assets/images/booking/up-arrow-icon.png';
import TableDetails from './TableDetails/TableDetails';
import ReservationDetails from './CreatBooking/reservation-details/ReservationDetails';
import conflictIcon from '../../../assets/images/booking/conflict-icon.png';

import BookingList from './BookingList';
import BookingCalendarArrow from './BookingCalendarArrow/BookingCalendarArrow';
import ShiftSelector from './ShiftSelector/ShiftSelector';
import { BOOKINGSTATUS } from '../../../constants/enum';
import ShiftIndicator from './CreatBooking/shift-indicator/shift-indicator';
import { isArray, set } from 'lodash';
import CustomModal from '../../../components/CustomModal';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import BookingNavBar from './BookingNavBar/BookingNavBar';
import TimeLine from './TimelineView/Timeline';
import WaitList from './TableViewWaitList/WaitList';

const Content = styled.div`
	display: block;
	background: #f7f7f7;
	padding-bottom: 40px;
	padding-top: 0px;
`;

function Booking() {
	const reducers = useSelector((state) => state.userReducers);
	const [isInit, setIsInit] = useState(false);
	const [selectedOption, setSelectedOption] = useState('1');
	const [selectedOptionName, setSelectedOptionName] = useState('Sort By: Time');
	const [keyword, setKeyword] = useState('');
	const [selectedShift, setSelectedShift] = useState(null);
	const [selectedDate, setSelectedDate] = useState(formatDate(moment(), 'YYYY-MM-DD'));
	const [selectedDateChanged, setSelectedDateChanged] = useState(false);
	const [dateChangeHandler, setDateChangeHandler] = useState(false);
	const [selectedFloor, setSelectedFloor] = useState(null);
	const [timeList, setTimeList] = useState([]);
	const [floorList, setFloorList] = useState([]);
	const [bookingsList, setBookingsList] = useState([]);
	const [tableList, setTableList] = useState([]);
	const [filteredBookingList, setFilteredBookingList] = useState([]);
	const [sectionFilteredBookingList, setSectionFilteredBookingList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [showTimeline, setShowTimeline] = useState(false);
	const [createBookingOpen, setCreateBookingOpen] = useState(false);
	const [openBookingCalendar, setOpenBookingCalendar] = useState(false);
	const [employeesData, setEmployeesData] = useState([]);
	const [shiftsData, setShiftsData] = useState([]);
	const [timeoutHandler, setTimeoutHandler] = useState(null);
	const [selectedBooking, setSelectedBooking] = useState(null);
	const [showTableDetails, setShowTableDetails] = useState(false);
	const [experienceList, setExperienceList] = useState([]);
	const [experienceShiftId, setExperienceShiftId] = useState(0);
	const [selectedTableData, setSelectedTableData] = useState([]);
	const [isChangingTable, setIsChangingTable] = useState(false);
	const [tableDetailsData, setTableDetailsData] = useState([]);
	const [selectedTableId, setSelectedTableId] = useState([0]);
	const [showNavbar, setShowNavbar] = useState(false);
	const [viewState, setViewState] = useState(1); // 1 = table view availability, 2 = timeline view, 3 = table view waitlist

	let shifts = [];
	let copyBookings = [];
	let experiences = [];

	const selectedSegment = (index) => {
		setSelectedOption(index);
		setFilteredBookingList(
			sortBookingByStatus(
				bookingsList.filter((e) => e.guest),
				index,
				keyword,
			),
		);

		switch (index) {
			case '1':
				setSelectedOptionName('Sort By: Time');
				break;
			case '2':
				setSelectedOptionName('Sort By: Status');
				break;

			case '4':
				setSelectedOptionName('Sort By: Alerts');
				break;
			default:
				setSelectedOptionName(' ');
				// sectionFilteredBookingList(sortBookingBySection(bookingsList));
				break;
		}
	};

	const selectFloorCallBack = (floor) => {
		console.log('Floor: ', floor);
		setIsLoading(true);
		setTableList([]);
		setSelectedFloor(floor);
	};

	const createBookingSuccess = () => {
		setIsLoading(true);
		getFloor();
	};

	const handleOpenCreateBooking = () => {
		if (!createBookingOpen) {
			setCreateBookingOpen(true);
		}
	};

	const handleCloseCreateBooking = () => {
		if (createBookingOpen) {
			setCreateBookingOpen(false);
		}
	};

	const handleOpenSelectedBookingCalendar = () => {
		if (!openBookingCalendar) {
			setOpenBookingCalendar(true);
		}
	};

	const handleCloseSelectedBookingCalendar = () => {
		if (openBookingCalendar) {
			setOpenBookingCalendar(false);
		}
	};

	const selectedBookingDate = (date) => {
		setSelectedDate(moment(date).format('yyyy-MM-DD'));
		setDateChangeHandler(true);
		const filteredShiftsData = filterShifts(shiftsData, moment(date).isoWeekday());
		if (experienceShiftId == 0) {
			setTimeList(getAllTime(filteredShiftsData, moment(date).format('yyyy-MM-DD')));
			// console.log(timeList);
			// console.log(filteredShiftsData);
		} else {
			setTimeList(
				getAllTime(
					filteredShiftsData.find((e) => e.id == experienceShiftId),
					moment(date).format('yyyy-MM-DD'),
				),
			);
		}
	};

	const saveBookingHandler = () => {
		setIsLoading(true);
		getFloor();
	};

	const getFloor = async () => {
		try {
			const response = await axios({
				url: `${apiUrl}/api/get_floors`,
				method: 'POST',
				data: {},
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${reducers.token}`,
				},
			});

			if (response) {
				const { status } = response;
				const { data } = response.data;
				const { floors } = data;
				if (status === 200) {
					setFloorList(floors);

					setSelectedFloor(floors[0]);

					getShift();
				}
			}
		} catch (err) {
			console.log('Error: ', err);
		}
	};

	const getEmployees = async () => {
		try {
			const response = await axios({
				url: `${apiUrl}/api/get_employees`,
				method: 'POST',
				data: {},
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${reducers.token}`,
				},
			});

			if (response) {
				const { status } = response;
				const { data } = response.data;
				const { employees } = data;

				if (status === 200) {
					setEmployeesData(employees);
				}
			}
		} catch (err) {
			console.log('Error: ', err);
		}
	};

	const getShift = async () => {
		try {
			const response = await axios({
				url: `${apiUrl}/api/get_shifts`,
				method: 'POST',
				data: {},
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${reducers.token}`,
				},
			});

			if (response) {
				const { status } = response;
				const { data } = response.data;
				if (status === 200) {
					shifts = data.shifts;
					const filteredShifts = filterShifts(shifts, moment(selectedDate).isoWeekday());
					setTimeList(getAllTime(filteredShifts, selectedDate));
					setShiftsData(shifts);
					getBookingList();

					getEmployees();
					getExperienceList();
				}
			}
		} catch (err) {
			console.log('Error: ', err);
		}
	};

	const getExperienceList = async () => {
		try {
			const response = await axios({
				url: `${apiUrl}/api/experience/list`,
				method: 'POST',
				data: {},
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${reducers.token}`,
				},
			});

			if (response) {
				const { status } = response;
				const { data } = response.data;
				if (status === 200) {
					experiences = data.experiences;
					setExperienceList(data.experiences);
					// getExperienceAssign();
					// console.log(experienceList);
				}
			}
		} catch (err) {
			console.log('Error: ', err);
		}
	};

	const handleChange = (e) => {
		setKeyword(e.target.value);
		if (selectedOption !== 3) {
			setFilteredBookingList(
				sortBookingByStatus(
					bookingsList.filter((e) => e.guest),
					selectedOption,
					e.target.value,
				),
			);
		}
	};

	// useEffect(() => {
	// 	console.log(isLoading);
	// }, [selectedShift]);
	const getBookingList = async () => {
		let shiftId = 0;
		// console.log(dateChangeHandler);
		if (selectedShift === null) {
			const filteredShiftsData = filterShifts(shifts, moment(selectedDate).isoWeekday());
			const shift = getCurrentShift(filteredShiftsData.length == 0 ? [] : filteredShiftsData);
			setSelectedShift(shift);
			shiftId = shift !== undefined ? shift.id : 0;
		} else {
			if (selectedShift !== undefined) {
				if (dateChangeHandler === true) {
					const filteredShiftsData = filterShifts(shifts, moment(selectedDate).isoWeekday());
					const shift = getCurrentShift(filteredShiftsData.length == 0 ? [] : filteredShiftsData);
					setSelectedShift(shift);
					shiftId = shift !== undefined ? shift.id : 0;
					setDateChangeHandler(false);
				} else {
					shiftId = selectedShift.id;
				}
			} else {
				setDateChangeHandler(false);
				const filteredShiftsData = filterShifts(shifts, moment(selectedDate).isoWeekday());
				const shift = getCurrentShift(filteredShiftsData.length == 0 ? [] : filteredShiftsData);
				setSelectedShift(shift);
				shiftId = shift !== undefined ? shift.id : 0;
			}
		}

		if (shiftId === 0) {
			// Do something here if current time has no available shift
			console.log('Currently no shift available');
			if (timeoutHandler !== undefined) {
				clearTimeout(timeoutHandler);
				setTimeoutHandler(null);
			}

			setTimeoutHandler(
				setTimeout(
					function () {
						//Start the timer
						setIsLoading(true);
						getFloor();
					}.bind(this),
					30000,
				),
			);
		}
		try {
			const response = await axios({
				url: `${apiUrl}/api/bookings`,
				method: 'POST',
				data: {
					date: selectedDate,
					shift_id: shiftId,
				},
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${reducers.token}`,
				},
			});

			if (response) {
				const { status } = response;
				const { data } = response.data;
				const { bookings } = data;
				if (status === 200) {
					setIsLoading(false);
					let bookingList = bookings.filter((e) => e.guest);
					bookingList = bookingList.map((e) => {
						if (
							checkIfBookingIsOvertime(e.start_date, e.end_date) &&
							e.status !== BOOKINGSTATUS.finished &&
							e.status !== BOOKINGSTATUS.cancelled &&
							e.status !== BOOKINGSTATUS.noShow
						) {
							let currentDate = moment().date();
							if (currentDate > e.start_date) {
								return { ...e, status: BOOKINGSTATUS.overTime };
							} else {
								return e;
							}
						}
						return e;
					});
					setBookingsList(
						bookingList.length == 0 ? [] : bookingList, //|| bookingsList[0] == undefined
					);
				}
			}
		} catch (err) {}
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		if (reducers) {
			// if (isLoading) { return }
			if (bookingsList.length == 0) {
				if (tableList.length == 0) {
					let _tables = [];

					// Need to clear the table lsit
					if (selectedFloor !== undefined) {
						if (selectedFloor !== null) {
							selectedFloor.tables.map((_table) => {
								_tables = [..._tables, _table];
							});
							if (_tables.length > 0) {
								setTableList(_tables);
							}
						}
					}
				}

				if (!isLoading && isInit) {
					if (timeoutHandler !== undefined) {
						clearTimeout(timeoutHandler);
						setTimeoutHandler(null);
					}

					if (selectedDateChanged && !isLoading) {
						setIsLoading(true);
						setSelectedDateChanged(false);
						getFloor();
					}

					if (!isLoading) {
						if (selectedOption !== 3) {
							setFilteredBookingList(
								sortBookingByStatus(
									bookingsList.filter((e) => e.guest),
									selectedOption,
									keyword,
								),
							);
						} else {
							setSectionFilteredBookingList();
						}
					}

					setTimeoutHandler(
						setTimeout(
							function () {
								//Start the timer
								setIsLoading(true);
								// getFloor();
							}.bind(this),
							30000,
						),
					);
				} else if (!isLoading && !isInit) {
					setIsInit(true);
					setTimeout(
						function () {
							//Start the timer
							setIsLoading(true);
							getFloor();
						}.bind(this),
						1000,
					);
				}
			} else {
				if (selectedDateChanged) {
					setSelectedBooking(null);
					setIsLoading(true);
					getFloor();
					setSelectedDateChanged(false);
					return;
				}

				if (selectedOption !== 3) {
					setFilteredBookingList(
						sortBookingByStatus(
							bookingsList.filter((e) => e.guest),
							selectedOption,
							keyword,
						),
					);
				} else {
					setSectionFilteredBookingList();
				}

				if (tableList.length == 0) {
					let _tables = [];

					// Need to clear the table lsit
					if (selectedFloor !== undefined) {
						selectedFloor.tables.map((_table) => {
							_tables = [..._tables, _table];
						});
						setTableList(_tables);
					}
				}

				if (!isLoading) {
					if (timeoutHandler !== undefined) {
						clearTimeout(timeoutHandler);
						setTimeoutHandler(null);
					}

					setTimeoutHandler(
						setTimeout(
							function () {
								//Start the timer to get all bookings
								setIsLoading(true);
								getFloor();
							}.bind(this),
							30000,
						),
					);
				}
			}
		}
	}, [
		isInit,
		selectedFloor,
		bookingsList,
		selectedOption,
		keyword,
		tableList,
		floorList,
		selectedDate,
		selectedShift,
		selectedBooking,
	]);

	const showTableDetailsHandler = (item) => {
		if (showTableDetails) {
			if (isChangingTable) {
				changeTableIdHandler(item);
			} else {
				if (item.id === undefined) {
					setShowTableDetails(!showTableDetails);
					changeTableIdHandler(item);
				} else {
					if (selectedTableId[0] === item.id) {
						setShowTableDetails(!showTableDetails);
						changeTableIdHandler(item);
					} else {
						setShowTableDetails(showTableDetails);
						getSelectedTableData(item);
						changeTableIdHandler(item);
					}
				}
			}
		} else {
			setShowTableDetails(!showTableDetails);
			changeTableIdHandler(item);
			getSelectedTableData(item);
		}
		// console.log(item.id);
	};

	const getSelectedTableData = (val) => {
		let data = [];
		data = getBookingsOnTable(val.id, bookingsList);
		if (data !== null) {
			setSelectedTableData(data);
			setTableDetailsData([]);
		} else if (data === null) {
			setSelectedTableData([]);
			setTableDetailsData(val);
		}
	};

	const changeTableIdHandler = (value) => {
		if (selectedTableId[0] > 0) {
			if (isChangingTable) {
				if (selectedTableId.length >= 1) {
					if (selectedTableId.includes(value.id)) {
						//unselect table
						let table_ids = selectedTableId.filter((e) => e !== value.id);
						setSelectedTableId(table_ids);
					} else {
						//select additional table
						let table_ids = [];
						table_ids = [...selectedTableId].concat(value.id);
						setSelectedTableId(table_ids);
					}
				}
			} else {
				if (selectedTableId[0] === value.id) {
					let table_ids = [];
					table_ids = [0];
					setSelectedTableId(table_ids);
				} else {
					let table_ids = [];
					table_ids = [value.id];
					setSelectedTableId(table_ids);
				}
			}
		} else {
			//select new single table
			let table_ids = [];
			table_ids = [value.id];
			setSelectedTableId(table_ids);
		}
	};
	// Availability Modal
	const [availabilityModal, setAvailabilityModal] = useState(false);
	const [bookingDate, setBookingDate] = useState(new Date());
	const [floors, setFloors] = useState([]);
	const [tables, setTables] = useState([]);
	const [timeslots, setTimeslots] = useState([]);
	const [partySizes, setPartySizes] = useState([]);
	const [checking, setChecking] = useState(false);
	const [table, setTable] = useState(null);
	const [floor, setFloor] = useState(null);
	const [timeslot, setTimeslot] = useState(null);
	const [partySize, setPartySize] = useState(null);

	useEffect(() => {
		axios({
			url: `${apiUrl}/api/get_floors`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${reducers.token}`,
			},
		})
			.then((resp) => {
				let floorsData = [];
				for (var key in resp.data.data.floors) {
					if (resp.data.data.floors.hasOwnProperty(key)) {
						const floorData = {
							value: resp.data.data.floors[key].id,
							label: resp.data.data.floors[key].floor_name,
							tables: resp.data.data.floors[key].tables,
							combined_tables: resp.data.data.floors[key].combined_tables,
						};
						floorsData.push(floorData);
					}
				}
				setFloors(floorsData);
			})
			.catch((err) => {
				console.log(err);
			});
		axios({
			method: 'GET',
			url: `${apiUrl}/api/partysizes`,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${reducers.token}`,
			},
		})
			.then((resp) => {
				let sizesData = [];
				for (var key in resp.data.data.partySizes) {
					if (resp.data.data.partySizes.hasOwnProperty(key)) {
						const sizeData = {
							value: resp.data.data.partySizes[key],
							label: resp.data.data.partySizes[key],
						};
						sizesData.push(sizeData);
					}
				}
				setPartySizes(sizesData);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	useEffect(() => {
		axios({
			method: 'GET',
			url: `${apiUrl}/api/timeslots?date=${formatDate(bookingDate, 'YYYY-MM-DD')}`,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${reducers.token}`,
			},
		})
			.then((response) => {
				setTimeslots(response.data.data.availableTimeSlots);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [bookingDate]);

	const setTableData = (floor) => {
		setTable(null);
		setFloor(floor);
		let tablesData = [];
		if (floor.hasOwnProperty('tables')) {
			for (var key in floor.tables) {
				if (floor.tables.hasOwnProperty(key)) {
					const tableData = {
						value: floor.tables[key].id,
						label: floor.tables[key].name,
					};
					tablesData.push(tableData);
				}
			}
		}
		if (floor.hasOwnProperty('combined_tables')) {
			for (var key in floor.combined_tables) {
				if (floor.combined_tables.hasOwnProperty(key)) {
					const tableData = {
						value: floor.combined_tables[key].combined_ids,
						label: floor.combined_tables[key].name,
					};
					tablesData.push(tableData);
				}
			}
		}
		setTables(tablesData);
	};

	const checkAvailability = async () => {
		if (table && floor && timeslot && partySize) {
			setChecking(true);
			axios({
				method: 'POST',
				url: `${apiUrl}/api/table/check`,
				data: {
					date: formatDate(bookingDate, 'YYYY-MM-DD'),
					table_id: table.value,
					floor_id: floor.value,
					timeslot: timeslot.value,
					partySize: partySize.value,
				},
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${reducers.token}`,
				},
			})
				.then((response) => {
					setChecking(false);
					alert(response.data.data.message);
				})
				.catch((error) => {
					setChecking(false);
					console.log(error);
				});
		} else {
			alert('Please fillup all details.');
		}
	};

	const toggleBookingNavBar = () => setShowNavbar(!showNavbar);

	const changeView = (int) => {
		setViewState(int);
	};

	const GuestBookData = [
		{ selectedShift: selectedShift },
		{ employeesData: employeesData },
		{ timeList: timeList },
		{ reducers: reducers },
		{ floors: floorList },
		{ shifts: shiftsData },
		{ experienceList: experienceList == undefined ? false : experienceList },
		{ selectedDate: selectedDate },
	];

	return (
		<Fragment>
			<Container style={{ background: 'transparent' }} fluid>
				<BookingNavBar
					showNavbar={showNavbar}
					showNavbarHandler={setShowNavbar}
					data={GuestBookData}
				/>
				<Row style={{ marginRight: 0, marginLeft: 0, justifyContent: 'space-between' }}>
					<Col
						className="menu-btn-container"
						sm={4}
						md={4}
						lg={4}
						style={{ marginTop: 30, marginBottom: 30 }}
					>
						<div
							style={{ marginLeft: 10 }}
							className="btn-menu btn-menu--margin-top"
							onClick={toggleBookingNavBar}
						>
							<Media className="list-db" as="li">
								<svg
									style={{ marginTop: 12, marginLeft: 11, marginRight: 11 }}
									xmlns="http://www.w3.org/2000/svg"
									width="18"
									height="15"
									viewBox="0 0 18 15"
								>
									<g id="Group_347" data-name="Group 347" transform="translate(-0.333)">
										<rect
											id="Rectangle_42"
											data-name="Rectangle 42"
											width="18"
											height="3"
											rx="1.5"
											transform="translate(0.333)"
											fill="#fff"
										/>
										<rect
											id="Rectangle_43"
											data-name="Rectangle 43"
											width="18"
											height="3"
											rx="1.5"
											transform="translate(0.333 6)"
											fill="#fff"
										/>
										<rect
											id="Rectangle_44"
											data-name="Rectangle 44"
											width="18"
											height="3"
											rx="1.5"
											transform="translate(0.333 12)"
											fill="#fff"
										/>
									</g>
								</svg>
							</Media>
						</div>
						<div
							className={
								viewState === 1
									? 'btn-toggle btn-toggle--selected btn-toggle--margin-left'
									: 'btn-toggle btn-toggle--margin-left'
							}
							onClick={() => changeView(1)}
						>
							<div className="bg-icon-container">
								<Media className="list-db" as="li">
									<svg
										style={{ marginTop: 9, marginLeft: 9 }}
										xmlns="http://www.w3.org/2000/svg"
										width="22"
										height="22"
										viewBox="0 0 22 22"
									>
										<defs>
											<clipPath id="clip-path">
												<rect
													id="Rectangle_1613"
													data-name="Rectangle 1613"
													width="22"
													height="22"
													transform="translate(14 14)"
													stroke="#fff"
													strokeWidth={1}
												/>
											</clipPath>
										</defs>
										<g
											id="Mask_Group_267"
											data-name="Mask Group 267"
											transform="translate(-14 -14)"
											clipPath="url(#clip-path)"
										>
											<g id="Table" transform="translate(13.988 13.988)">
												<path
													id="Path_4771"
													data-name="Path 4771"
													d="M21.1,6.449A3.524,3.524,0,0,0,20.993,1.6l-.559-.573A3.524,3.524,0,0,0,15.588.925L13.434,2.89a.379.379,0,1,0,.511.555l.59-.546,4.59,4.59-.546.6a.379.379,0,0,0,0,.524h0a.379.379,0,0,0,.533-.026ZM15.1,2.387l.982-.881a2.44,2.44,0,0,1,.383-.291L20.83,5.576a2.44,2.44,0,0,1-.291.383l-.9.982Z"
												/>
												<path
													id="Path_4772"
													data-name="Path 4772"
													d="M15.575,21.1a3.524,3.524,0,0,0,4.845-.106l.559-.559a3.524,3.524,0,0,0,.106-4.845l-1.951-2.154a.379.379,0,1,0-.555.511l.546.59-4.59,4.59-.6-.546a.379.379,0,0,0-.524,0h0a.379.379,0,0,0,.026.533ZM19.636,15.1l.9.982a2.44,2.44,0,0,1,.291.383L16.469,20.83a2.44,2.44,0,0,1-.383-.291l-.982-.9Z"
												/>
												<path
													id="Path_4773"
													data-name="Path 4773"
													d="M.925,15.575a3.524,3.524,0,0,0,.106,4.845l.559.559a3.524,3.524,0,0,0,4.845.106l2.141-1.965a.379.379,0,1,0-.511-.555l-.577.559L2.9,14.536l.546-.6a.379.379,0,0,0,0-.524h0a.379.379,0,0,0-.533.026ZM6.92,19.636l-.982.9a2.44,2.44,0,0,1-.383.291L1.194,16.469a2.44,2.44,0,0,1,.291-.383l.9-.982Z"
												/>
												<path
													id="Path_4774"
													data-name="Path 4774"
													d="M6.449.925A3.524,3.524,0,0,0,1.6,1.031l-.573.559A3.524,3.524,0,0,0,.925,6.435L2.89,8.589a.379.379,0,1,0,.555-.511L2.9,7.488,7.488,2.9l.6.546a.379.379,0,0,0,.524,0h0a.379.379,0,0,0-.026-.533ZM2.387,6.92l-.881-.982a2.44,2.44,0,0,1-.291-.383L5.576,1.194a2.44,2.44,0,0,1,.383.291l.982.9Z"
												/>
												<path
													id="Path_4775"
													data-name="Path 4775"
													d="M11.012,17.654a1.987,1.987,0,0,1-1.418-.586L4.955,12.43a2.009,2.009,0,0,1,0-2.837L9.594,4.955a2.009,2.009,0,0,1,2.837,0l4.638,4.638a2.009,2.009,0,0,1,0,2.837L12.43,17.068A1.987,1.987,0,0,1,11.012,17.654Zm0-12.4a1.132,1.132,0,0,0-.8.326L5.576,10.215a1.136,1.136,0,0,0,0,1.595l4.638,4.638a1.163,1.163,0,0,0,1.595,0l4.638-4.638a1.136,1.136,0,0,0,0-1.595L11.809,5.576a1.132,1.132,0,0,0-.8-.326Z"
												/>
											</g>
										</g>
									</svg>
								</Media>
							</div>
						</div>

						<div
							className={
								viewState === 2
									? 'btn-toggle btn-toggle--selected btn-toggle--margin-left'
									: 'btn-toggle btn-toggle--margin-left'
							}
							onClick={() => changeView(2)}
						>
							<div className="bg-icon-container">
								<Media>
									<svg
										style={{ marginTop: 9, marginLeft: 9 }}
										xmlns="http://www.w3.org/2000/svg"
										width="22"
										height="22"
										viewBox="0 0 22 22"
									>
										<defs>
											<clipPath id="clip-path">
												<rect
													id="Rectangle_798"
													data-name="Rectangle 798"
													width="22"
													height="22"
													transform="translate(14 14)"
													fill="none"
													stroke="#fff"
													strokeWidth={1}
												/>
											</clipPath>
										</defs>
										<g
											id="Mask_Group_178"
											data-name="Mask Group 178"
											transform="translate(-14 -14)"
											clipPath="url(#clip-path)"
										>
											<g id="Time" transform="translate(14 14)">
												<path
													id="Path_350"
													data-name="Path 350"
													d="M19.5,0H2.5A2.5,2.5,0,0,0,0,2.5V19.5A2.5,2.5,0,0,0,2.5,22H19.5A2.5,2.5,0,0,0,22,19.5V2.5A2.5,2.5,0,0,0,19.5,0ZM21.12,2.5V4.8H15.105V.88h4.4A1.624,1.624,0,0,1,21.12,2.5ZM6.895,13.3H.88V5.685H6.895Zm.88-1.157h6.45v8.98H7.775Zm6.45-2.288H7.775V5.685h6.45ZM7.775,4.8V.88h6.45V4.8ZM2.5.88H6.9V4.8H.88V2.5A1.624,1.624,0,0,1,2.5.88ZM.88,19.5V15.585H6.895V21.12h-4.4A1.624,1.624,0,0,1,.88,19.5Zm20.24,0A1.624,1.624,0,0,1,19.5,21.12H15.1V19.092H21.12Zm0-2.693H15.105V9.478H21.12ZM15.105,7.19v-1.5H21.12v1.5Z"
												/>
												<path
													id="Path_351"
													data-name="Path 351"
													d="M2.424,3.252H5.337a.44.44,0,0,0,0-.88H2.424a.44.44,0,0,0,0,.88Z"
												/>
												<path
													id="Path_352"
													data-name="Path 352"
													d="M12.483,2.38H9.574a.44.44,0,0,0,0,.88h2.908a.44.44,0,0,0,0-.88Z"
												/>
												<path
													id="Path_353"
													data-name="Path 353"
													d="M16.685,3.26H19.6a.44.44,0,1,0,0-.88H16.685a.44.44,0,1,0,0,.88Z"
												/>
											</g>
										</g>
									</svg>
								</Media>
							</div>
						</div>

						<div
							className={
								viewState === 3
									? 'btn-toggle btn-toggle--selected btn-toggle--margin-left'
									: 'btn-toggle btn-toggle--margin-left'
							}
							onClick={() => changeView(3)}
						>
							<div className="bg-icon-container">
								<Media>
									<svg
										style={{ marginTop: 9, marginLeft: 10 }}
										xmlns="http://www.w3.org/2000/svg"
										width="22"
										height="22"
										viewBox="0 0 22 22"
									>
										<defs>
											<clipPath id="clip-path">
												<rect
													id="Rectangle_798"
													data-name="Rectangle 798"
													width="22"
													height="22"
													transform="translate(14 14)"
													fill="none"
													stroke="#fff"
													strokeWidth={1}
												/>
											</clipPath>
										</defs>
										<g
											id="Mask_Group_180"
											data-name="Mask Group 180"
											transform="translate(-14 -14)"
											clipPath="url(#clip-path)"
										>
											<g id="Wait_list" data-name="Wait list" transform="translate(12.898 14)">
												<path
													id="Path_358"
													data-name="Path 358"
													d="M20.156,13.495H20.9v-.924a.493.493,0,0,0-.493-.493H15.228a.493.493,0,0,0-.493.493v.924h.748V15.3a1.962,1.962,0,0,0,.66,1.47,1.98,1.98,0,0,0-.66,1.474v2.336h-.748v.924a.493.493,0,0,0,.493.493h5.183a.493.493,0,0,0,.493-.493v-.924h-.748V18.247a1.984,1.984,0,0,0-.664-1.474,1.967,1.967,0,0,0,.664-1.47ZM16.72,19.923a.264.264,0,0,1-.528,0v-1.76a.264.264,0,1,1,.528,0Zm0-4.55a.264.264,0,1,1-.528,0v-1.6a.264.264,0,0,1,.528,0Z"
												/>
												<path
													id="Path_359"
													data-name="Path 359"
													d="M4.18,20.359V1.641A.757.757,0,0,1,4.937.88H17.063a.757.757,0,0,1,.757.761v8.822h.88v-8.8A1.637,1.637,0,0,0,17.063,0H4.937A1.637,1.637,0,0,0,3.3,1.641V20.359A1.637,1.637,0,0,0,4.937,22h7.59v-.88H4.937a.757.757,0,0,1-.757-.761Z"
												/>
												<rect
													id="Rectangle_810"
													data-name="Rectangle 810"
													width="10.186"
													height="1.188"
													rx="0.123"
													transform="translate(5.909 5.245)"
												/>
												<rect
													id="Rectangle_811"
													data-name="Rectangle 811"
													width="10.186"
													height="1.188"
													rx="0.123"
													transform="translate(5.909 9.258)"
												/>
												<rect
													id="Rectangle_812"
													data-name="Rectangle 812"
													width="6.455"
													height="1.188"
													rx="0.123"
													transform="translate(5.909 13.504)"
												/>
											</g>
										</g>
									</svg>
								</Media>
							</div>
						</div>
						{/* <div className="btn-toggle btn-toggle--margin-left">
							<div className="bg-icon-container">
								<Button onClick={() => setAvailabilityModal(true)}>Check Availability</Button>
							</div>
						</div> */}
					</Col>
					<Col
						className="menu-btn-container"
						sm={6}
						md={6}
						lg={6}
						style={{
							textAlign: 'center',
							marginTop: 30,
							marginBottom: 30,
						}}
					>
						<div
							style={{
								width: '100%',
								marginTop: 10,
								marginBottom: 10,
								display: 'inline-flex',
								justifyContent: 'center',
							}}
						>
							{/* <Media className='btn-arrow'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="7.656" height="13.314" viewBox="0 0 7.656 13.314">
                                    <path id="Union_5" data-name="Union 5" d="M6.364,13.435.707,7.778a1,1,0,0,1,0-1.414L6.364.707A1,1,0,0,1,7.778,2.121l-4.95,4.95,4.95,4.95a1,1,0,1,1-1.414,1.415Z" transform="translate(-0.414 -0.414)" fill="#bcbcbc"/>
                                </svg>
                            </Media> */}
							<BookingCalendarArrow
								arrowType="left"
								setSelectedDateChanged={setSelectedDateChanged}
								selectedDate={selectedDate}
								setSelectedDate={setSelectedDate}
								setDateChangeHandler={setDateChangeHandler}
							/>
							<div style={{ margin: '0 15px' }}>
								<div className="bg-date-shift" onClick={() => handleOpenSelectedBookingCalendar()}>
									<label>{formatDate(selectedDate, 'dddd, DD MMM')}</label>
								</div>
								<SelectBookingCalendar
									selectedDate={selectedDate}
									openBookingCalendar={openBookingCalendar}
									setSelectedDateHandler={setSelectedDate}
									setSelectedDateChanged={setSelectedDateChanged}
									handleCloseSelectedBookingCalendar={handleCloseSelectedBookingCalendar}
									setDateChangeHandler={setDateChangeHandler}
								/>
							</div>
							<div style={{ margin: '0 15px' }}>
								{/* <div className="bg-shift-indicator">
									<div
										className={
											selectedShift !== undefined ? 'shift-indicator' : 'shift-indicator no-shift'
										}
									></div>setSelectedDateChanged
								</div> */}
								<ShiftIndicator
									selectedShift={selectedShift}
									setSelectedDateChanged={setSelectedDateChanged}
									selectedDate={selectedDate}
									setSelectedDate={setSelectedDate}
								/>
							</div>
							{/* <div style={{ margin: '0 15px' }}>
                                <div className='bg-date-shift'>
                                    <label>{ selectedShift == null ? 'No shift' : selectedShift.name }</label>
                                </div>
                            </div> */}
							<ShiftSelector
								setSelectedDateChanged={setSelectedDateChanged}
								selectedDateChanged={selectedDateChanged}
								selectedShiftHandler={setSelectedShift}
								selectedShift={selectedShift}
								shifts={shiftsData}
								selectedDate={selectedDate}
							/>
							{/* <Media className='btn-arrow'>
                                <svg id="Arrow" xmlns="http://www.w3.org/2000/svg" width="7.656" height="13.314" viewBox="0 0 7.656 13.314">
                                    <path id="Union_6" data-name="Union 6" d="M6.364,13.435.707,7.778a1,1,0,0,1,0-1.414L6.364.707A1,1,0,0,1,7.778,2.121l-4.95,4.95,4.95,4.95a1,1,0,1,1-1.414,1.415Z" transform="translate(8.071 13.728) rotate(180)" fill="#bcbcbc"/>
                                </svg>
                            </Media> */}
							<BookingCalendarArrow
								arrowType="right"
								setSelectedDateChanged={setSelectedDateChanged}
								selectedDate={selectedDate}
								setSelectedDate={setSelectedDate}
								setDateChangeHandler={setDateChangeHandler}
							/>
						</div>
					</Col>
					<Col
						className="menu-btn-container"
						sm={2}
						md={2}
						lg={2}
						style={{ marginTop: 30, marginBottom: 30, display: 'flex', justifyContent: 'flex-end' }}
					>
						{/* <div> */}
						<div
							className="btn-menu btn-menu--big-size btn-menu--margin-left btn-menu--margin-top"
							onClick={() => handleOpenCreateBooking()}
						>
							<img
								src={createBookingIcon}
								width="22"
								height="22"
								className="d-inline"
								style={{ marginTop: 13, marginLeft: 15 }}
								alt="checker"
							/>
							{createBookingOpen ? (
								<CreateBooking
									selectedShift={selectedShift}
									employeesData={employeesData}
									timeList={timeList}
									reducers={reducers}
									floor={floorList}
									shifts={shiftsData}
									experienceList={experienceList == undefined ? false : experienceList}
									createBookingOpen={createBookingOpen}
									selectedDate={selectedDate}
									createBookingSuccess={createBookingSuccess}
									setSelectedDate={setSelectedDate}
									selectedBookingDate={selectedBookingDate}
									handleCloseCreateBooking={(e) => handleCloseCreateBooking(e)}
									setExperienceShift={setExperienceShiftId}
								/>
							) : (
								''
							)}
						</div>
						{/* </div> */}
					</Col>
				</Row>

				{viewState === 1 ? (
					<Row style={{ marginRight: 0, marginLeft: 0 }}>
						<Col sm={4} md={4} lg={4}>
							<TableDetails
								showTableDetailsHandler={showTableDetailsHandler}
								showTableDetails={showTableDetails}
								saveBookingHandler={saveBookingHandler}
								token={reducers.token}
								floorList={floorList}
								selectedTableData={selectedTableData}
								tableDetailsData={tableDetailsData}
								selectedBooking={selectedBooking}
								isChangingTable={isChangingTable}
								selectedTableId={selectedTableId}
								selectedShift={selectedShift}
								setIsChangingTable={setIsChangingTable}
								setShowTableDetails={setShowTableDetails}
								setSelectedTableId={setSelectedTableId}
								selectedBookingHandler={setSelectedBooking}
							/>

							<div className="booking-list-container">
								<div className="bg-search-bar">
									{/* <input 
                                    onChange={(e) => {handleChange(e)}}
                                    type="text" 
                                    name="name" 
                                    style={{ width:'100%', background: 'transparent', marginLeft:10 }}/> */}
									<DebounceInput
										debounceTimeout={500}
										type="text"
										name="name"
										style={{ width: '100%', background: 'transparent', marginLeft: 10 }}
										onChange={(event) => handleChange(event)}
									></DebounceInput>
									<img
										src={searchIcon}
										width="22"
										height="22"
										className="d-inline"
										style={{ marginTop: 9, marginLeft: 15, marginRight: 15, filter: 'lighten' }}
										alt="checker"
									/>
								</div>
							</div>
							<div
								style={{
									color: 'white',
									height: 24,
									fontWeight: 500,
									marginLeft: 15,
									marginTop: 10,
									marginBottom: 10,
								}}
							>
								{selectedOptionName}
							</div>
							<div className="scroll-bar-list">
								<BookingList
									selectedOption={selectedOption}
									filteredBookingList={filteredBookingList}
									floorList={floorList}
									bookingsList={bookingsList}
									keyword={keyword}
									selectedBooking={selectedBooking}
									selectedBookingHandler={setSelectedBooking}
								/>
								<div className="booking-list-container-footer">
									<SegmentedControl selectedOption={selectedSegment}></SegmentedControl>
								</div>
							</div>
						</Col>

						<Col sm={8} md={8} lg={8}>
							{selectedBooking !== null ? (
								!isChangingTable ? (
									<ReservationDetails
										floor={floorList}
										token={reducers.token}
										selectedBooking={selectedBooking}
										setSelectedBooking={setSelectedBooking}
										statusOption={getBookingStatusOptions(selectedBooking.status)}
										tableOptions={getBookingTableOptions(selectedBooking.table_lock)}
										saveBookingHandler={saveBookingHandler}
										experienceList={experienceList}
										shifts={shiftsData}
										// selectedExpData={selectedExpData}
									/>
								) : (
									''
								)
							) : (
								''
							)}
							<div className="table-floor-container">
								<div className="table-floor-separator"></div>
								<div className="table-floor">
									{tableList.length > 0
										? tableList.map((table) => (
												<div
													key={Math.random()}
													className="table-container"
													style={{
														position: 'absolute',
														top: table.pos_y,
														left: table.pos_x,
														color: 'white',
														fontSize: 10,
													}}
												>
													<div
														onClick={(e) => showTableDetailsHandler(table)}
														key={Math.random()}
														style={{
															position: 'relative',
															width: getRadWidth(table.rotate_deg, table.table_type),
															height: getRadHeight(table.rotate_deg, table.table_type),
														}}
													>
														<span
															key={Math.random()}
															style={{
																position: 'absolute',
																zIndex: 1,
																left: tableNamePositionLeft(table.table_type),
																transform: `translateX(${tableNamePositionLeftTranslate(
																	table.table_type,
																)})`,
																marginLeft: tableNamePositionMarginLeft(
																	table.table_type,
																	table.rotate_deg,
																),
																marginTop: tableNamePositionMarginTop(
																	table.table_type,
																	table.rotate_deg,
																),
															}}
														>
															{table.name}
														</span>
														{hasConflictBooking(
															null,
															tableList,
															getBookingOnTable(table.id, bookingsList),
														) ? (
															<img
																src={conflictIcon}
																width="18"
																height="18"
																className="d-inline"
																alt="checker"
																style={{
																	position: 'absolute',
																	marginLeft: tableNamePositionMarginLeft(
																		table.table_type,
																		table.rotate_deg,
																	),
																	left: tableNamePositionLeft(table.table_type),
																	transform: `translateX(${tableNamePositionLeftTranslate(
																		table.table_type,
																	)})`,
																	marginTop:
																		tableNamePositionMarginTop(table.table_type, table.rotate_deg) +
																		15,
																	zIndex: 1,
																}}
															/>
														) : (
															''
														)}

														{!showTimeline ? (
															getBookingOnTable(table.id, bookingsList) == null ? (
																''
															) : (
																<div
																	key={Math.random()}
																	style={{
																		position: 'absolute',
																		zIndex: 1,
																		left: tableNamePositionLeft(table.table_type),
																		transform: `translateX(${tableNamePositionLeftTranslate(
																			table.table_type,
																		)})`,
																		marginLeft: tableNamePositionMarginLeft(
																			table.table_type,
																			table.rotate_deg,
																		),
																		bottom:
																			tableNamePositionMarginTop(
																				table.table_type,
																				table.rotate_deg,
																			) + 25,
																		maxWidth: 'min-content',
																		color: getLabelBookingColor(
																			getBookingOnTable(table.id, bookingsList) === null
																				? null
																				: getBookingOnTable(table.id, bookingsList).status,
																		),
																	}}
																>
																	{getFullname(
																		getBookingOnTable(table.id, bookingsList) === null
																			? null
																			: getBookingOnTable(table.id, bookingsList).guest,
																	)}
																</div>
															)
														) : (
															''
														)}

														{!showTimeline ? (
															getBookingOnTable(table.id, bookingsList) == null ? (
																''
															) : (
																<div
																	key={Math.random()}
																	style={{
																		position: 'absolute',
																		zIndex: 1,
																		left: tableNamePositionLeft(table.table_type),
																		transform: `translateX(${tableNamePositionLeftTranslate(
																			table.table_type,
																		)})`,
																		marginLeft: tableNamePositionMarginLeft(
																			table.table_type,
																			table.rotate_deg,
																		),
																		bottom:
																			tableNamePositionMarginTop(
																				table.table_type,
																				table.rotate_deg,
																			) + 10,
																		color: getLabelBookingColor(
																			getBookingOnTable(table.id, bookingsList) === null
																				? null
																				: getBookingOnTable(table.id, bookingsList).status,
																		),
																	}}
																>
																	{getTimeOnly(
																		getBookingOnTable(table.id, bookingsList).start_date,
																	)}
																</div>
															)
														) : (
															''
														)}
														{!showTimeline ? (
															getBookingOnTable(table.id, bookingsList) == null ? (
																''
															) : (
																<div
																	key={Math.random()}
																	style={{
																		position: 'absolute',
																		zIndex: 1,
																		width: '100%',
																		left: 0, //tableNamePositionLeft(table.table_type),
																		// transform: `translateX(${ tableNamePositionLeftTranslate(table.table_type) })`,
																		marginLeft: 0, //tableNamePositionMarginLeft(table.table_type, table.rotate_deg) - 5,
																		bottom: 2,
																		height: 6,
																		background: getBookingStatusColor(
																			getBookingOnTable(table.id, bookingsList) === null
																				? null
																				: getBookingOnTable(table.id, bookingsList).status,
																		),
																		borderRadius: 3,
																	}}
																>
																	<div
																		key={Math.random()}
																		style={{
																			background: getBookingProgressStatusColo(
																				getBookingOnTable(table.id, bookingsList) === null
																					? null
																					: getBookingOnTable(table.id, bookingsList).status,
																			),
																			width: '45%',
																			height: 6,
																			borderRadius: 3,
																		}}
																	></div>
																</div>
															)
														) : (
															''
														)}
														{showTimeline ? (
															<div
																key={Math.random()}
																style={{
																	position: 'absolute',
																	zIndex: 2,
																	height: '100%',
																	width: 60,
																	top: '50%',
																	left: '50%',
																	transform: `translate(-50%, -23%)`,
																}}
															>
																<div
																	key={Math.random()}
																	style={{
																		background: 'rgba(0, 0, 0, 0.6)',
																		height: 15,
																		marginBottom: 5,
																		borderRadius: 2,
																		textAlign: 'center',
																	}}
																>
																	{getBookingTimeOnly(
																		getBookingOnTableByIndex(table.id, bookingsList, 0),
																	) ?? ''}
																</div>
																<div
																	key={Math.random()}
																	style={{
																		background: 'rgba(0, 0, 0, 0.6)',
																		height: 15,
																		marginBottom: 5,
																		borderRadius: 2,
																		textAlign: 'center',
																	}}
																>
																	{getBookingTimeOnly(
																		getBookingOnTableByIndex(table.id, bookingsList, 1),
																	) ?? ''}
																</div>
																<div
																	key={Math.random()}
																	style={{
																		background: 'rgba(0, 0, 0, 0.6)',
																		height: 15,
																		marginBottom: 5,
																		borderRadius: 2,
																		textAlign: 'center',
																	}}
																>
																	{getBookingTimeOnly(
																		getBookingOnTableByIndex(table.id, bookingsList, 2),
																	) ?? ''}
																</div>
															</div>
														) : (
															''
														)}
														<div
															key={Math.random()}
															style={{
																width: getTableWidth(table.table_type),
																height: getTableHeight(table.table_type),
																transformOrigin: 'top left',
																position: 'absolute',
																top: getPositionTop(table.rotate_deg, table.table_type),
																left: getPositionLeft(table.rotate_deg, table.table_type),
																transform: `rotate(${radToDeg(table.rotate_deg)}deg)`,
															}}
														>
															<img
																draggable="false"
																key={Math.random()}
																src={getTableImg(table.table_type)}
																width={`${getTableWidth(table.table_type)}`}
																height={`${getTableHeight(table.table_type)}`}
																className={
																	selectedTableId.length >= 1 && selectedTableId.includes(table.id)
																		? 'table-floor table-container img-filter'
																		: 'table-floor table-container'
																}
																alt="checker"
															/>
														</div>
													</div>
												</div>
										  ))
										: ''}
								</div>
								<div className="table-floor-bottom">
									{selectedFloor !== null ? (
										<FloorDropDown
											selectedFloor={selectedFloor}
											floors={floorList}
											selectFloorCallBack={selectFloorCallBack}
										></FloorDropDown>
									) : (
										''
									)}
									<div className="btn-timeline" onClick={(e) => setShowTimeline(!showTimeline)}>
										<img
											src={!showTimeline ? btnTimelineIcon : btnTimelineSelectedIcon}
											width="25"
											height="25"
											className="d-inline"
											alt="checker"
										/>
									</div>
								</div>
							</div>
						</Col>
					</Row>
				) : viewState === 2 ? (
					<TimeLine
						showTableDetailsHandler={showTableDetailsHandler}
						saveBookingHandler={saveBookingHandler}
						token={reducers.token}
						bookingList={bookingsList}
						floorList={floorList}
						bookingsList={bookingsList}
						selectedTableData={selectedTableData}
						tableDetailsData={tableDetailsData}
						selectedBooking={selectedBooking}
						isChangingTable={isChangingTable}
						selectedTableId={selectedTableId}
						selectedShift={selectedShift}
						setIsChangingTable={setIsChangingTable}
						setShowTableDetails={setShowTableDetails}
						setSelectedTableId={setSelectedTableId}
						selectedBookingHandler={setSelectedBooking}
					/>
				) : viewState === 3 ? (
					<WaitList
						floorList={floorList}
						tableList={tableList}
						bookingsList={bookingsList}
						showTimeline={showTimeline}
						selectedTableId={selectedTableId}
						selectedFloor={selectedFloor}
						selectFloorCallBack={selectFloorCallBack}
						setShowTimeline={setShowTimeline}
					/>
				) : (
					''
				)}
			</Container>
			<CustomModal
				isOpen={availabilityModal}
				handleClose={() => setAvailabilityModal(false)}
				hasClose
			>
				<div>
					<Row>
						<Col>Date</Col>
						<Col>
							<DatePicker
								selected={bookingDate}
								onChange={(date) => setBookingDate(date)}
								minDate={moment().toDate()}
							/>
						</Col>
					</Row>
					<Row>
						<Col>Floors</Col>
						<Col>
							<Select options={floors} onChange={(e) => setTableData(e)} />
						</Col>
					</Row>
					<Row>
						<Col>Table</Col>
						<Col>
							<Select options={tables} onChange={(e) => setTable(e)} value={table} />
						</Col>
					</Row>
					<Row>
						<Col>Time</Col>
						<Col>
							<Select options={timeslots} onChange={(e) => setTimeslot(e)} />
						</Col>
					</Row>
					<Row>
						<Col>Party Size</Col>
						<Col>
							<Select options={partySizes} onChange={(e) => setPartySize(e)} />
						</Col>
					</Row>
					<Row>
						<Col></Col>
						<Col>
							<Button onClick={() => checkAvailability()} color="primary" disabled={checking}>
								{checking ? (
									<Spinner
										as="span"
										animation="border"
										size="sm"
										role="status"
										aria-hidden="true"
									/>
								) : (
									'Check Availability'
								)}
							</Button>
						</Col>
					</Row>
				</div>
			</CustomModal>
		</Fragment>
	);
}

export default withDashboardApp(Booking);
