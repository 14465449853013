import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import arrowDownIcon from 'assets/images/booking/arrow-down-icon.png';
import CombinationCapacity from './CombinationCapacity';

const FloorButton = styled.div`
	positon: relative;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: 5px 0;
	height: 40px;
	width: 322px;
	backgound-color: #222a2f;
	border-radius: 10px;
	box-shadow: 5px 5px 10px #1a2024, -5px -5px 10px #2a343a;
	cursor: pointer;
	color: #bcbcbc;
	font-weight: 700;

	&:hover {
		background: #1f262b;
	}
	&:active {
		background: #222a2f;
		color: #ffffff;
	}
	#combination-dd-img {
		// user-select: none;
		-webkit-user-drag: none;
		-khtml-user-drag: none;
		-moz-user-drag: none;
		-o-user-drag: none;
		user-drag: none;
	}
`;

const MainContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-radius: 5px;
	padding: 5px 0;
	margin: 5px 0;
	width: 100%;
	height: fit-content;
`;

const ChildContainer = styled.div`
	position: absolute;
	display: inline;
	width: 100%;
	transition: ease 250ms;
`;

function CombinationDropDown(props) {
	const [isOpen, setIsOpen] = useState(false);
	const [openComponent, setOpenComponent] = useState([]);
	const [computedHeight, setComputedHeight] = useState(0);
	const toggling = () => {
		if (isOpen) {
			setIsOpen(!isOpen);
			setOpenComponent([]);
		} else {
			setIsOpen(!isOpen);
			let height = props.combined.length * 55;
			setComputedHeight(height);
		}
	};

	const openComponentCallback = (id) => {
		let ids = openComponent;
		let idx = ids.findIndex((e) => e === id);
		let height;
		if (openComponent.length > 0) {
			if (openComponent.includes(id)) {
				ids.splice(idx, 1);
				ids.sort();
				setOpenComponent(ids);
			} else {
				ids.push(id);
				ids.sort();
				setOpenComponent(ids);
			}
		} else {
			ids.push(id);
			ids.sort();
			setOpenComponent(ids);
		}

		let diff = props.combined.length - ids.length;
		height = diff * 55 + ids.length * 135;
		setComputedHeight(height);
	};

	useEffect(() => {
		if (isOpen) {
			if (props.combined.length === 0) {
				setIsOpen(false);
			} else {
				setIsOpen(false);
			}
		}
	}, [props.combined]);

	return (
		<MainContainer>
			<FloorButton isOpen={isOpen} onClick={toggling}>
				{props.name}{' '}
				<div style={{ position: 'absolute', right: `${isOpen ? 20 : 25}px` }}>
					<img
						id="combination-dd-img"
						src={arrowDownIcon}
						width={12}
						height={7}
						style={
							isOpen
								? { transition: '200ms', transform: 'rotate(180deg)' }
								: { transition: '200ms', transform: 'rotate(360deg)' }
						}
					/>
				</div>
			</FloorButton>
			<div
				style={{
					position: 'relative',
					overflow: 'hidden',
					height: `${isOpen ? computedHeight : 0}px`,
					transition: 'ease 200ms',
				}}
			>
				<ChildContainer style={isOpen ? { top: 0 } : { top: '-100%' }}>
					{props.combined.length > 0
						? props.combined.map((e) => {
								return (
									<CombinationCapacity
										isOpen={isOpen}
										min={e.capacity_min}
										max={e.capacity_max}
										key={e.id}
										name={e.name}
										ident={e.id}
										combined={e}
										openComponentCallback={openComponentCallback}
										selectedIdCallback={props.selectedIdCallback}
										combinedTablesCallback={props.combinedTablesCallback}
									/>
								);
						  })
						: ''}
				</ChildContainer>
			</div>
		</MainContainer>
	);
}

export default CombinationDropDown;
