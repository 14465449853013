import React, { useState, useEffect } from 'react';
import { Grid, AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css';
import styled from 'styled-components';
import { imgUrl } from 'utils/Utility';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 50px;
	overflow: hidden;
	z-index: 1;

	span {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	h4 {
		min-width: fit-content;
	}
`;

const Header = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 130px;
	height: 40px;
	background: #ffffff;
	user-select: none;
	box-shadow: 0px 1px 10px #1c1c1e4d;
	font-size: 14px;
	font-weight: bold;
	color: #404040;
	margin-left: 10px;
	cursor: pointer;

	&:active {
		background: #e6e6e6;
	}
`;

const ListContainer = styled.ul`
	position: absolute;
	display: flex;
	width: 130px;
	background: #ffffff;
	flex-direction: column;
	list-style-type: none;
	box-shadow: 0px 1px 10px #1c1c1e4d;
	margin-left: 48px;
	margin-top: 45px;
	padding: 10px;
	z-index: 1;
	color: #8d8d8d;
`;

const ListItem = styled.div`
	display: flex;
	align-items: center;
	width: 130px;
	height: 40px;
	cursor: pointer;
	color: #8d8d8d;
`;

const StyledGrid = styled(Grid)`
	::-webkit-scrollbar {
		width: 3px;
		height: 3px;
		background-color: transparent;
	}
	::-webkit-scrollbar-thumb {
		width: 1px;
		height: 1px;

		border-radius: 20px;
		background-color: #b3b3b3;
	}

	::-webkit-scrollbar-corner {
		display: none;
	}

	.ReactVirtualized__Grid__innerScrollContainer {
		margin-bottom: 10px;
		padding-bottom: 100px;
	}
`;

const StyledEmployeeContainer = styled.div`
	background: #efefef;
	width: 150px;
	height: 40px;
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	box-shadow: 0px 1px 10px #1c1c1e4d;
	border-radius: 1.5em;
	cursor: pointer;
	padding: 5px;

	img {
		border-radius: 50%;
		-webkit-user-drag: none;
		-khtml-user-drag: none;
		-moz-user-drag: none;
		-o-user-drag: none;
		user-drag: none;
	}

	span {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 30px;
		width: 30px;
		border-radius: 50%;
		font-size: 14px;
		font-weight: 700;
		color: #413636;
	}

	p {
		margin-left: 5px;
		font-size: 14px;
		font-weight: 700;
		color: #413636;
	}
`;

function GroupScheduleFilter(props) {
	const [showList, setShowList] = useState(false);
	const [selectedGroup, setSelectedGroup] = useState({ role: 'All', id: -1 });
	const [employees, setEmployees] = useState([]);

	const toggleClick = () => {
		setShowList(!showList);
	};

	const selectGroup = (data) => {
		setSelectedGroup(data);
		toggleClick();
	};

	useEffect(() => {
		filterEmployeeByRole();
	}, [props.employees, selectedGroup, props.selectedEmployees]);

	const filterEmployeeByRole = () => {
		let newArr = [...props.employees];
		let idx;
		if (props.selectedEmployees.length > 0) {
			newArr = newArr.filter((e) => !props.selectedEmployees.includes(e));
			// newArr.splice(idx, 1);
		}
		if (selectedGroup.id === -1) {
			setEmployees(newArr);
		} else {
			let filteredEmployees = newArr.filter((e) => e.employment_role_id === selectedGroup.id);
			setEmployees(filteredEmployees);
		}
	};

	const onEmployeeClick = (empl) => {
		if (props.segmentedOption !== '1') {
			return;
		}
		props.selectEmployeeCallback(empl);
	};

	// console.log(props.employmentRoles, employees);
	return (
		<Container>
			<span>
				<h4>Filter:</h4> <Header onClick={toggleClick}>{selectedGroup.role}</Header>{' '}
				<div style={{ height: 50, width: '100%', margin: '0 10px' }}>
					<AutoSizer>
						{({ height, width }) => (
							<StyledGrid
								height={height}
								width={width}
								columnCount={employees.length}
								columnWidth={170}
								rowCount={1}
								rowHeight={40}
								cellRenderer={({ columnIndex, key, style }) => {
									const employee = employees[columnIndex];
									// console.log(`${imgUrl + employee.photo}`);
									return (
										<div style={{ ...style, margin: '2px 5px' }} key={key}>
											<StyledEmployeeContainer onClick={() => onEmployeeClick(employee)}>
												{employee.photo ? (
													<>
														<img
															src={`${imgUrl + employee.photo}`}
															width={30}
															height={30}
															alt="employee-photo"
														/>

														<p>
															{employee.first_name} {employee.last_name}
														</p>
													</>
												) : (
													<>
														<span style={{ background: employee.color }}>
															{employee.first_name.charAt(0)}
															{employee.last_name.charAt(0)}
														</span>
														<p>
															{employee.first_name} {employee.last_name}
														</p>
													</>
												)}
											</StyledEmployeeContainer>
										</div>
									);
								}}
							/>
						)}
					</AutoSizer>
				</div>
			</span>

			<ListContainer
				style={
					showList
						? { opacity: 1, visibility: 'visible', transition: 'opacity 200ms' } // transition: 'scale(1) translate(0, 0)'
						: { opacity: 0, visibility: 'hidden', transition: 'opacity 200ms' } //transform: 'scale(0.0) translate(100%, 100%)'</Container>
				}
			>
				{props.employmentRoles.length > 0
					? props.employmentRoles.map((role, key) => {
							console.log();
							if (key === 0) {
								return (
									<div key={key}>
										<ListItem
											style={selectedGroup.id === -1 ? { color: '#f99eb4' } : {}}
											onClick={() => selectGroup({ role: 'All', id: -1 })}
										>
											All
										</ListItem>
										<ListItem
											style={selectedGroup.id === role.id ? { color: '#f99eb4' } : {}}
											onClick={() => selectGroup({ role: role.title, id: role.id })}
										>
											{role.title}
										</ListItem>
									</div>
								);
							} else {
								return (
									<ListItem
										style={selectedGroup.id === role.id ? { color: '#f99eb4' } : {}}
										onClick={() => selectGroup({ role: role.title, id: role.id })}
										key={key}
									>
										{role.title}
									</ListItem>
								);
							}
					  })
					: ''}
			</ListContainer>
		</Container>
	);
}

export default GroupScheduleFilter;
