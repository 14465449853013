import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { EMPLOYEETYPE, PAYBASIS, PAYCYCLE } from 'constants/enum';
import styled from 'styled-components';
import DraggableButton from './DraggableButton';
import plusIcon from 'assets/images/booking/create-booking/icon-add-white.png';
import UserRolesCalendar from '../UserRolesCalendar';

const Wrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
	user-select: none;

	#ur-slider {
		position: relative;
		height: 90%;
		width: 100%;
		overflow-x: hidden;
		overflow-y: auto;

		#ur-inner-slider {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: fit-content;
			display: flex;
			flex-direction: row;
			transition: ease 500ms;
			// pointer-events: none;
		}

		::-webkit-scrollbar {
			// width: 5px;
			// height: 5px;
			// background-color: transparent;
			display: none;
		}
		::-webkit-scrollbar-thumb {
			display: none;
			// width: 5px;
			// height: 5px;
			// border-radius: 20px;
			// background-color: transparent;
		}

		section {
			height: fit-content;
			width: 488px;
			margin: 30px;
		}
	}
`;

const StyledInputContainer = styled.div`
	position: relative;
	width: 100%;
	height: 40px;
	background: #ffffff;
	box-shadow: 2px 3px 10px #1c1c1e4d;
	border-radius: 1em;
	margin: 10px 0;
	padding-right: 50px;
	padding-top: 5px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	transition: ease 500ms;

	label {
		margin-left: 20px;
		font-size: 14px;
		font-weight: 700;
		color: #1c1c1e;
		white-space: nowrap;
	}

	input {
		text-align: end;
		width: 70%;
		margin-left: 20px;
		font-size: 14px;
		font-weight: 500;
		transition: ease 500ms;

		::placeholder,
		::-webkit-input-placeholder {
			color: #7d7d7d9e;
		}
		:-ms-input-placeholder {
			color: #7d7d7d9e;
		}

		&:focus {
			text-align: start;
		}
	}

	#styled-date-holder {
		text-align: end;
		width: 50%;
		margin-left: 20px;
		font-size: 14px;
		font-weight: 500;
		white-space: nowrap;
		cursor: pointer;
		transition: ease 500ms;
	}
`;

const Title = styled.h4`
	font-size: 20px;
	font-weight: 700;
	margin-bottom: 25px;
	color: #656565;
`;

const NavListButton = styled.ul`
	margin: 0;
	padding: 0;
	position: absolute;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 30px;
	list-style: none;
	transition: ease 200ms;

	li {
		height: 10px;
		width: 10px;
		border-radius: 50%;
		background: #7c7c7c;
		margin: 5px;
		cursor: pointer;
	}

	#esm-selected {
		background: #4594ff;
		transition: ease 500ms;
	}
`;

const StyledBtnGroup = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	label {
		font-size: 14px;
		font-weight: 700;
		color: #505050;
	}

	#btn-row {
		position: relative;
		display: flex;
		flex-direction: row;
		overflow: hidden;
	}

	button {
		display: flex;
		min-width: 130px;
		height: 35px;
		justify-content: center;
		align-items: center;
		font-size: 12px;
		font-weight: 500;
		box-shadow: 2px 3px 10px #1c1c1e4d;
		border-radius: 1.5em;
		margin: 5px;
		color: #505050;
		transition: ease 500ms;
		&:hover {
			background: #1c1c1e05;
		}
	}
`;

const AllocatedPayItems = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 548.14px;
	height: 50px;
	padding: 0 55px 15px 25px;
	border-bottom: 1px solid #505050;
	margin-bottom: 15px;

	h4 {
		width: 100%;
		font-size: 14px;
		font-weight: 700;
		color: #505050;
	}

	#awpi-delete-icon {
		position: absolute;
		right: 15px;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 30px;
		width: 30px;
		border-radius: 5px;
		box-shadow: 2px 3px 10px #1c1c1e4d;
		font-size: 14px;
		font-weight: 700;
		cursor: pointer;
	}
`;

const StyledIndicator = styled.div`
	position: absolute;
	width: 547px;
	height: 75%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #707071;
	font-size: 14px;
	font-weight: 700;
`;

const StyledAddBtn = styled.div`
	position: absolute;
	right: -30px;
	top: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: fit-content;
	padding: 0 15px;
	height: 30px;
	border-radius: 5px;
	box-shadow: 2px 3px 10px #1c1c1e4d;
	font-size: 14px;
	font-weight: 700;
	cursor: pointer;
	color: #707071;
`;

const WagePayListContainer = styled.div`
	position: absolute;
	right: -30px;
	top: 32px;
	display: flex;
	align-items: center;
	width: 200px;
	padding: 0 15px;
	height: 37px;
	box-shadow: 2px 3px 10px #1c1c1e4d;
	font-size: 14px;
	font-weight: 200;
	cursor: pointer;
	color: #ffffff;
	background: #ff7383;
	z-index: 1;

	img {
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-o-user-select: none;
		user-select: none;
		margin-right: 5px;
	}
`;

function EmployeeSegmentedInputs(props) {
	const [staff, setStaff] = useState(props.staff);
	const [addWagePay, setAddWagePay] = useState(false);
	const [isDateHiredCalendarOpen, setIsDateHiredCalendarOpen] = useState(false);

	let pressed = false;
	let startX;
	const sections = document.getElementById('section');
	const employeeTypeArray = [
		{
			id: 'owner',
			title: 'Owner',
		},
		{
			id: 'manager',
			title: 'Manager',
		},
		{
			id: 'staff',
			title: 'Staff',
		},
	];

	const onMouseDown = (e) => {
		if (e.target.toString() === '[object HTMLInputElement]') return;
		pressed = true;
		const innerSlider = document.getElementById('ur-inner-slider');
		window.addEventListener('mousemove', onMouseMove);
		window.addEventListener('mouseup', onMouseUp);
		startX = e.clientX - innerSlider.offsetLeft;
		// console.log(startX);
	};

	const onMouseMove = (e) => {
		if (!pressed) return;
		e.preventDefault();
		const innerSlider = document.getElementById('ur-inner-slider');
		innerSlider.style.left = `${e.clientX - startX}px`;
		checkBoundary(e.clientX - startX);
	};

	const onMouseUp = (e) => {
		e.preventDefault();
		// const innerSlider = document.getElementById('ur-inner-slider');
		window.removeEventListener('mousemove', onMouseMove);
		window.removeEventListener('mouseup', onMouseUp);
		checkScroll();
		pressed = false;
	};

	const checkScroll = () => {
		const innerSlider = document.getElementById('ur-inner-slider');
		const sections = document.querySelectorAll('section');
		const outer = document.getElementById('ur-slider');
		const scrollpx = Math.abs(innerSlider.offsetLeft);
		const navigation = document.getElementById('esm-navlist');
		sections.forEach((section, i) => {
			if (
				section.offsetLeft < scrollpx + section.offsetWidth / 2 &&
				scrollpx < section.offsetLeft + section.offsetWidth / 2
			) {
				resetNav();
				innerSlider.style.left = `-${i * 548}px`;
				navigation.children[i].setAttribute('id', 'esm-selected');
				outer.scrollTo({
					top: 0,
					behavior: 'smooth',
				});
			} else if (i === 5) {
				if (
					section.offsetLeft < scrollpx + section.offsetWidth / 2 &&
					!scrollpx < section.offsetLeft + section.offsetWidth / 2
				) {
					resetNav();
					innerSlider.style.left = `-${5 * 548}px`;
					navigation.children[i].setAttribute('id', 'esm-selected');
				}
			}

			// else {
			// 	innerSlider.style.left = `-${2740}px`;
			// }
		});
	};

	const checkFocus = (e) => {
		// const inputs = document.getElementsByTagName('input');
		// inputs.focus({ preventScroll: true });
		// console.log('put function here');
	};

	const navClick = (e, idx) => {
		e.preventDefault();
		e.stopPropagation();
		const innerSlider = document.getElementById('ur-inner-slider');
		const sections = document.querySelectorAll('section');
		const outer = document.getElementById('ur-slider');
		const navigation = document.getElementById('esm-navlist');
		sections.forEach((section, i) => {
			if (i === idx) {
				resetNav();
				innerSlider.style.left = `-${i * 548}px`;
				navigation.children[i].setAttribute('id', 'esm-selected');
				outer.scrollTo({
					top: 0,
					behavior: 'smooth',
				});
			}
		});
	};

	const resetNav = () => {
		const navigation = document.getElementById('esm-navlist');
		for (let i = 0; i < navigation.children.length; i++) {
			navigation.children[i].removeAttribute('id');
		}
	};

	const checkBoundary = (int) => {
		const innerSlider = document.getElementById('ur-inner-slider');
		const slider = document.getElementById('ur-slider');
		const outer = slider.getBoundingClientRect();
		const inner = innerSlider.getBoundingClientRect();
		if (parseInt(innerSlider.style.left) > 0) {
			innerSlider.style.left = '0px';
		} else if (Math.floor(inner.right) < Math.floor(outer.right - 52)) {
			if (Math.floor(int) > 2740) {
				innerSlider.style.left = `-${2740}px`;
			}
		}
	};

	const handleOnChange = (e, int) => {
		let employee = staff;
		if (int === 1) {
			employee = { ...employee, address: e.target.value };
		}
		if (int === 2) {
			let mobile = e.target.validity.valid ? e.target.value : '';
			// console.log(e.target.validity.valid);
			employee = { ...employee, mobile_no: parseInt(mobile) };
		}
		if (int === 5) {
			let number;
			if (e.target.validity.valid) {
				number = e.target.value;
				if (!number) {
					number = '';
				} else {
					employee = { ...employee, tax_no: parseInt(number) };
				}
			}
		}
		if (int === 6) {
			employee = { ...employee, bank_account: e.target.value };
		}
		if (int === 7) {
			employee = { ...employee, bank_name: e.target.value };
		}
		if (int === 8) {
			let qPin;
			if (e.target.validity.valid) {
				qPin = e.target.value;
				employee = { ...employee, quick_pin: qPin };
			}
		}
		if (int === 9) {
			let sPin;
			if (e.target.validity.valid) {
				sPin = e.target.value;
				employee = { ...employee, system_pin: sPin };
			}
		}
		if (int === 10) {
			let annualRate;
			if (e.target.validity.valid) {
				annualRate = e.target.value;
				if (!annualRate) {
					annualRate = '';
				} else {
					employee = { ...employee, annual_rate: parseInt(annualRate) };
				}
			}
		}
		if (int === 11) {
			let hourlyRate;
			if (e.target.validity.valid) {
				hourlyRate = e.target.value;
				if (!hourlyRate) {
					hourlyRate = '';
				} else {
					employee = { ...employee, rate: parseInt(hourlyRate) };
				}
			}
		}
		if (int === 12) {
			let hours;
			if (e.target.validity.valid) {
				hours = e.target.value;
				if (!hours) {
					hours = '';
				} else {
					employee = { ...employee, hours_in_pay_cycle: parseInt(hours) };
				}
			}
		}
		setStaff(employee);
		props.setStaff(employee);
	};

	const toggleDateHireCalendar = () => {
		setIsDateHiredCalendarOpen(!isDateHiredCalendarOpen);
	};

	const selectedDateHireCallBack = (date) => {
		let employee = staff;
		employee = { ...employee, date_hired: date };
		setStaff(employee);
		props.setStaff(employee);
	};

	const formSchema = Yup.object().shape({
		email: Yup.string().email('Invalid email format'),
	});

	const employeeTypeHandler = (data) => {
		let type;
		let employee = staff;
		switch (data) {
			case EMPLOYEETYPE.owner:
				if (staff.role === data) {
					type = '';
					employee = { ...employee, role: type };
				} else {
					type = 'owner';
					employee = { ...employee, role: type };
				}
				break;
			case EMPLOYEETYPE.manager:
				if (staff.role === data) {
					type = '';
					employee = { ...employee, role: type };
				} else {
					type = 'manager';
					employee = { ...employee, role: type };
				}
				break;
			case EMPLOYEETYPE.staff:
				if (staff.role === data) {
					type = '';
					employee = { ...employee, role: type };
				} else {
					type = 'staff';
					employee = { ...employee, role: type };
				}
				break;
		}
		setStaff(employee);
		props.setStaff(employee);
	};

	const addContractHandler = () => {
		props.addContractHandler();
	};

	const addRoleHandler = () => {
		props.addRoleHandler();
	};

	const contractHandleClick = (data) => {
		let employee = staff;
		employee = { ...employee, employment_contract_id: data };
		setStaff(employee);
		props.setStaff(employee);
		// console.log(employee);
	};

	const roleHandlerClick = (data) => {
		let employee = staff;
		employee = { ...employee, employment_role_id: data };
		setStaff(employee);
		props.setStaff(employee);
		// console.log(employee);
	};

	const payBasisClick = (int) => {
		let employee = staff;
		employee = { ...employee, pay_basis: int };
		setStaff(employee);
		props.setStaff(employee);
	};

	const payCycleClick = (int) => {
		let employee = staff;
		employee = { ...employee, pay_cycle: int };
		setStaff(employee);
		props.setStaff(employee);
		// console.log(int);
	};

	const addWagePayItem = (e) => {
		e.preventDefault();
		e.stopPropagation();
		props.setShowAddWageButton(true);
	};

	const removeWagePayItem = (item) => {
		let _staff = staff;
		let wagePayItems = _staff.pay_wages_item;
		let wagePayIds = _staff.pay_wages_item.map((e) => e.id);
		if (wagePayIds.includes(item.id)) {
			let idx = wagePayItems.findIndex((e) => e.id === item.id);
			wagePayItems.splice(idx, 1);
			_staff = { ..._staff, pay_wages_item: wagePayItems };
			setStaff(_staff);
			props.setStaff(_staff);
		}
	};

	const handleSubmit = (email) => {
		let employee = staff;
		employee = { ...employee, emailAddress: email };
		setStaff(employee);
		props.setStaff(employee);
	};

	// useEffect(() => {
	// 	if (props.isAdding) {
	// 		setStaff(props.staff);
	// 	} else {
	// 		setStaff(props.staff);
	// 	}
	// }, [props.isAdding, props.selectedEmployee]);

	return (
		<Wrapper>
			<div id="ur-slider">
				<div id="ur-inner-slider" onMouseDown={onMouseDown}>
					<section>
						<Title>Details Info</Title>
						<StyledInputContainer>
							<label>Home Address</label>
							<input
								placeholder="Enter Home Address"
								value={staff.address ? staff.address : ''}
								onChange={(e) => handleOnChange(e, 1)}
								onFocus={checkFocus}
							/>
						</StyledInputContainer>
						<StyledInputContainer>
							<label>Contact Number</label>
							<input
								type="number"
								placeholder="Enter Contact Number"
								value={staff.mobile_no ? staff.mobile_no : ''}
								min="0"
								onChange={(e) => handleOnChange(e, 2)}
							/>
						</StyledInputContainer>
						<Formik
							initialValues={{
								email: staff.email ? staff.email : '',
							}}
							validationSchema={formSchema}
						>
							{({ values, errors, handleChange, isValid }) => {
								return (
									<Form>
										<StyledInputContainer style={errors.email ? { border: '1px solid red' } : {}}>
											<label>Email Address</label>
											<div
												style={{
													position: 'absolute',
													right: 30,
													top: 0,
													fontSize: 10,
													fontWeight: 500,
													color: 'red',
													whiteSpace: 'nowrap',
												}}
											>
												{errors.email}
											</div>

											<input
												type="email"
												name="email"
												placeholder="Enter Email Address"
												value={values.email}
												onChange={handleChange}
												onBlur={() => (isValid ? handleSubmit(values.email) : handleSubmit(''))}
												// onFocus={checkFocus}
											/>
										</StyledInputContainer>
									</Form>
								);
							}}
						</Formik>

						<StyledInputContainer>
							<label>Date Hired</label>
							<div
								id="styled-date-holder"
								onClick={toggleDateHireCalendar}
								style={staff.date_hired ? { color: 'black' } : { color: '#7d7d7d9e' }}
							>
								{staff.date_hired ? staff.date_hired : 'YYYY-MM-DD'}
							</div>
							<UserRolesCalendar
								isOpen={isDateHiredCalendarOpen}
								handleToggle={toggleDateHireCalendar}
								handleCallback={selectedDateHireCallBack}
							/>
						</StyledInputContainer>
					</section>

					<section>
						<Title>Account Info</Title>
						<StyledInputContainer>
							<label>Tax Number</label>
							<input
								type="text"
								pattern="[\+\0-9]{0,18}"
								placeholder="Enter Tax Number"
								value={staff.tax_no ? staff.tax_no : ''}
								onChange={(e) => handleOnChange(e, 5)}
								// onFocus={checkFocus}
							/>
						</StyledInputContainer>
						<StyledInputContainer>
							<label>Bank Act</label>
							<input
								placeholder="Enter Bank Act"
								value={staff.bank_account ? staff.bank_account : ''}
								onChange={(e) => handleOnChange(e, 6)}
							/>
						</StyledInputContainer>
						<StyledInputContainer>
							<label>Bank Name</label>
							<input
								placeholder="Enter Bank Name"
								value={staff.bank_name ? staff.bank_name : ''}
								onChange={(e) => handleOnChange(e, 7)}
								// onFocus={checkFocus}
							/>
						</StyledInputContainer>
					</section>

					<section>
						<Title>Security Info</Title>
						<StyledInputContainer>
							<label>Quick Pin</label>
							<input
								type="text"
								pattern="[\+\0-9]{0,5}"
								placeholder="0"
								value={staff.quick_pin ? staff.quick_pin : ''}
								onChange={(e) => handleOnChange(e, 8)}
							/>
						</StyledInputContainer>
						<StyledInputContainer>
							<label>System Pin</label>
							<input
								type="text"
								pattern="[\+\0-9]{0,5}"
								placeholder="00000"
								value={staff.system_pin ? staff.system_pin : ''}
								onChange={(e) => handleOnChange(e, 9)}
							/>
						</StyledInputContainer>
					</section>

					<section>
						<Title>Employment Info</Title>
						<DraggableButton
							identifier={1}
							label={'Employee Type'}
							isDefault={true}
							selected={staff.role ? staff.role : ''}
							onClick={employeeTypeHandler}
							contents={employeeTypeArray}
						/>
						<DraggableButton
							identifier={2}
							label={'Contract'}
							isDefault={false}
							selected={staff.employment_contract_id ? staff.employment_contract_id : ''}
							addClick={addContractHandler}
							onClick={contractHandleClick}
							contents={props.employmentContract}
						/>
						<DraggableButton
							identifier={3}
							label={'Roles'}
							isDefault={false}
							selected={staff.employment_role_id ? staff.employment_role_id : ''}
							addClick={addRoleHandler}
							onClick={roleHandlerClick}
							contents={props.employmentRoles}
						/>
					</section>

					<section>
						<Title>Salary and Wages</Title>
						<StyledBtnGroup>
							<label>Pay Basis</label>
							<div id="btn-row">
								<button
									onClick={() => payBasisClick(PAYBASIS.hourly)}
									style={
										staff.pay_basis === PAYBASIS.hourly
											? { background: '#f46587', color: 'white' }
											: {}
									}
								>
									Hourly
								</button>
								<button
									onClick={() => payBasisClick(PAYBASIS.salary)}
									style={
										staff.pay_basis === PAYBASIS.salary
											? { background: '#f46587', color: 'white' }
											: {}
									}
								>
									Salary
								</button>
							</div>
						</StyledBtnGroup>
						<StyledBtnGroup>
							<label>Pay Cycle</label>
							<div id="btn-row">
								<button
									onClick={() => payCycleClick(PAYCYCLE.weekly)}
									style={
										staff.pay_cycle === PAYCYCLE.weekly
											? { background: '#f46587', color: 'white' }
											: {}
									}
								>
									Weekly
								</button>
								<button
									onClick={() => payCycleClick(PAYCYCLE.biWeekly)}
									style={
										staff.pay_cycle === PAYCYCLE.biWeekly
											? { background: '#f46587', color: 'white' }
											: {}
									}
								>
									Bi-Weekly
								</button>
								<button
									onClick={() => payCycleClick(PAYCYCLE.monthly)}
									style={
										staff.pay_cycle === PAYCYCLE.monthly
											? { background: '#f46587', color: 'white' }
											: {}
									}
								>
									Monthly
								</button>
							</div>
						</StyledBtnGroup>
						<StyledInputContainer
							style={
								staff.pay_basis === PAYBASIS.hourly
									? { margin: '20px 0', background: '#fafafa' }
									: { margin: '20px 0' }
							}
						>
							<label>Annual Salary $</label>
							<input
								disabled={staff.pay_basis === PAYBASIS.hourly ? true : false}
								type="text"
								pattern="[\+\0-9]{0,18}"
								placeholder="0.0"
								value={staff.annual_rate}
								onChange={(e) => handleOnChange(e, 10)}
							/>
						</StyledInputContainer>
						<StyledInputContainer
							style={
								staff.pay_basis === PAYBASIS.salary
									? { margin: '20px 0', background: '#fafafa' }
									: { margin: '20px 0' }
							}
						>
							<label>Hourly Rate $</label>
							<input
								disabled={staff.pay_basis === PAYBASIS.salary ? true : false}
								type="text"
								pattern="[\+\0-9]{0,18}"
								placeholder="0.0"
								value={staff.rate ? staff.rate : ''}
								onChange={(e) => handleOnChange(e, 11)}
							/>
						</StyledInputContainer>
						<StyledInputContainer style={{ margin: '20px 0' }}>
							<label>Hours in pay cycle</label>
							<input
								type="text"
								pattern="[\+\0-9]{0,18}"
								placeholder="0.0"
								value={staff.hours_in_pay_cycle ? staff.hours_in_pay_cycle : ''}
								onChange={(e) => handleOnChange(e, 12)}
							/>
						</StyledInputContainer>
					</section>

					<section style={{ margin: '30px 0' }}>
						<Title style={{ marginLeft: 25 }}>Allocated wage pay items</Title>
						{/* <WagePayListContainer>
							<img src={plusIcon} width={20} height={20} alt="add-icon" /> Create new wage item
						</WagePayListContainer> */}
						<StyledAddBtn onClick={addWagePayItem}>Add wage pay item</StyledAddBtn>

						{staff.pay_wages_item ? (
							staff.pay_wages_item.length > 0 ? (
								staff.pay_wages_item.map((e, key) => {
									return (
										<AllocatedPayItems key={key}>
											<h4>{e.name}</h4>
											<h4 style={{ textAlign: 'center' }}>
												{e.pay_basis === PAYBASIS.hourly ? 'Hourly' : 'Salary'}
											</h4>
											<h4 style={{ textAlign: 'right' }}>
												${(Math.round(e.rate * 100) / 100).toFixed(2)}
											</h4>
											<div id="awpi-delete-icon" onClick={() => removeWagePayItem(e)}>
												x
											</div>
										</AllocatedPayItems>
									);
								})
							) : (
								<StyledIndicator>No available wage pay items</StyledIndicator>
							)
						) : (
							<StyledIndicator>No available wage pay items</StyledIndicator>
						)}

						{/* {!isAdding ? (
							<AllocatedPayItems>
								<h4>Sunday Rate</h4>
								<h4>Hourly</h4>
								<h4>$40.00</h4>
								<div id="awpi-delete-icon">x</div>
							</AllocatedPayItems>
						) : (
							<StyledIndicator>No available wage pay items</StyledIndicator>
						)} */}
						{/* 
						<AllocatedPayItems>
							<h4>Sunday Rate</h4>
							<h4>Hourly</h4>
							<h4>$40.00</h4>
							<div id="awpi-delete-icon">x</div>
						</AllocatedPayItems> */}
					</section>
				</div>
			</div>
			<NavListButton id="esm-navlist">
				<li id="esm-selected" onClick={(e) => navClick(e, 0)} />
				<li onClick={(e) => navClick(e, 1)} />
				<li onClick={(e) => navClick(e, 2)} />
				<li onClick={(e) => navClick(e, 3)} />
				<li onClick={(e) => navClick(e, 4)} />
				<li onClick={(e) => navClick(e, 5)} />
			</NavListButton>
		</Wrapper>
	);
}

export default EmployeeSegmentedInputs;
