import React, { Fragment } from 'react';
import cx from 'classnames';
import styled from 'styled-components';
import cookie from 'js-cookie';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../actions';

import logoBlack from '../assets/images/logo.png';
import logoWhite from '../assets/images/logo-white.png';

const NavCont = styled.div`
	display: block;
	background: ${(props) => (props.isHome ? '#fff' : '#212a30')};
`;

const Topbar = ({ isHome = false }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const reducers = useSelector((state) => state.userReducers);

	const logout = () => {
		cookie.remove('token');
		dispatch(userActions.logOutUser());
		dispatch(userActions.setLoading());
	};

	return (
		<NavCont isHome={isHome}>
			<Container>
				<Navbar expand="lg" className={cx('main-navbar', isHome && 'is-home')}>
					<Link to="/" className="navbar-brand">
						<img
							alt="logo"
							src={isHome ? logoBlack : logoWhite}
							width="150"
							height="44"
							className="d-inline-block align-top"
						/>
					</Link>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="ml-auto">
							<Fragment>
								{!reducers.isAuth ? (
									<Fragment>
										<Link className="navbar-link nav-link" to="/signup">
											Sign Up
										</Link>
										<Link className="navbar-link nav-link" to="/login">
											Login
										</Link>
										<Button className="btn-start" onClick={() => history.push('/signup')}>
											Get Started
										</Button>
									</Fragment>
								) : (
									<Button className="btn-start" onClick={() => logout()}>
										Logout
									</Button>
								)}
							</Fragment>
						</Nav>
					</Navbar.Collapse>
				</Navbar>
			</Container>
		</NavCont>
	);
};

export default withRouter(Topbar);
