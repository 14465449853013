import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { apiUrl } from 'utils/Utility';

// Components
import SettingToggleSwitch from './SubComponents/SettingToggleSwitch';
import PickerComponent from './SubComponents/PickerComponent';
import {
	minutesOption,
	largePartyOption,
	bookingSizeOption,
	bookingSizeOption2,
} from './SubComponents/PickerComponentData';

// Icons
import addIcon from 'assets/images/booking/create-booking/icon-add-white.png';

function TableSettings(props) {
	// const [toggled, setToggled] = useState(false);
	const reducers = useSelector((state) => state.userReducers);
	const [largePartyCount, setLargePartyCount] = useState(props.dataSettings.large_party_count);
	const [onlineAvailability, setonlineAvailability] = useState(props.dataSettings.online_available);
	const [lateArrivals, setlateArrivals] = useState(props.dataSettings.late_arrivals);
	// const [sectionSelect, setsectionSelect] = useState(props.dataSettings.allow_guest_select_section);
	// const [seatSelect, setseatSelect] = useState(props.dataSettings.allow_guest_select_seat);
	// const [allowCancel, setallowCancel] = useState(
	// 	props.dataSettings.allow_guest_request_cancel_reservation,
	// );
	// const [allowEdit, setallowEdit] = useState(
	// 	props.dataSettings.allow_guest_request_edit_reservation,
	// );
	const [onlineBookingmin, setonlineBookingmin] = useState(
		props.dataSettings.online_available_booking_min,
	);
	const [onlineBookingMax, setonlineBookingMax] = useState(
		props.dataSettings.online_available_booking_max,
	);
	// const [allowCancelDuration, setallowCancelDuration] = useState(
	// 	props.dataSettings.allow_guest_request_cancel_reservation_duration,
	// );

	useEffect(() => {
		if (props.isSaving) {
			let settings = props.dataSettings;
			settings = {
				...settings,
				large_party_count: largePartyCount,
				online_available: onlineAvailability,
				late_arrivals: lateArrivals,
				online_available_booking_min: onlineBookingmin,
				online_available_booking_max: onlineBookingMax,
			};
			handleSaveData(settings);
		}
	}, [props.isSaving]);

	const handleSaveData = async (settings) => {
		if (!props.isSaving) {
			return;
		}
		try {
			const response = await axios({
				url: `${apiUrl}/api/set_account_settings`,
				method: 'POST',
				data: settings,
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${reducers.token}`,
				},
			});

			if (response) {
				const { status } = response;
				const { data } = response.data;
				if (status === 200) {
					console.log(data);
					props.setisSaving(false);
				}
			}
		} catch (err) {
			console.log('Error: ', err);
		}
	};

	return (
		<Container fluid>
			<div className="settings-grid-container">
				<div className="setting-party-size">
					<h3>Large Parties</h3>
					<div className="line-separator" />
					<label>What do you consider a large party?</label>
					<div className="component-container">
						<PickerComponent
							data={largePartyOption}
							initValue={largePartyCount}
							updateValue={setLargePartyCount}
						/>
					</div>
					<label style={{ fontSize: '12px' }}>
						*Large party will be called out for hosts in the shift notes
					</label>
				</div>

				<div className="setting-widget-note">
					<h3> Coming Soon</h3>
					<div className="line-separator" />
					<input placeholder="Enter header title here" disabled={true} />
					<textarea disabled={true} />
				</div>

				<div className="setting-online-availability">
					<h3>Online Availability</h3>
					<div className="line-separator" />
					<label>
						Customise the minimum and maximum party sizes you want to offer on WABI for every shift
						and special day.
					</label>
					<div className="component-container">
						<SettingToggleSwitch
							onChange={(event) => setonlineAvailability(event.target.checked)}
							checked={onlineAvailability}
						/>
					</div>
					<div className="line-separator" />
					<label>Accept online bookings for sizes between:</label>
					<div className="component-container" style={{ justifyContent: 'space-evenly' }}>
						<span
							style={{
								width: '160px',
								height: '40px',
							}}
						>
							{' '}
							<PickerComponent
								data={bookingSizeOption}
								initValue={onlineBookingmin}
								updateValue={setonlineBookingmin}
							/>
						</span>{' '}
						-{' '}
						<span
							style={{
								width: '160px',
								height: '40px',
							}}
						>
							<PickerComponent
								data={bookingSizeOption2}
								initValue={onlineBookingMax}
								updateValue={setonlineBookingMax}
							/>
						</span>
					</div>
				</div>

				<div className="setting-late-arrivals">
					<h3>Late Arrivals</h3>
					<div className="line-separator" />
					<label>Maintain original out time when parties are seated late.</label>

					<div className="component-container">
						<SettingToggleSwitch
							onChange={(event) => setlateArrivals(event.target.checked)}
							checked={lateArrivals}
						/>
					</div>
				</div>

				{/* <div className="setting-section-preference">
					<h3>Allow a customer to select a sections preference</h3>
					<div className="line-separator" />
					<div className="component-container1">
						<SettingToggleSwitch
							onChange={(event) => setsectionSelect(event.target.checked)}
							checked={sectionSelect}
						/>
					</div>
					<div className="line-separator" />
					<h3>Allow a customer to select their seat</h3>
					<label style={{ fontSize: '12px' }}>Allow guest to select a preferred seat</label>
					<div className="component-container">
						<SettingToggleSwitch
							onChange={(event) => setseatSelect(event.target.checked)}
							checked={seatSelect}
						/>
					</div>
				</div> */}

				{/* <div className="setting-custom-turntime">
					<span className="header">
						<h3>Widget Custom Turn Time</h3>
						<div className="img-wrapper">
							<img src={addIcon} width={15} height={15} />{' '}
						</div>
					</span>

					<div className="line-separator" />
					<label>
						You can add custom turn time depending on the party size selected on the widget.
					</label>
					<div className="component-container">
						<span>
							<label style={{ fontWeight: '500' }}>Party Size: 10</label>
							<label style={{ fontSize: '12px' }}>Turn Time: 4h 0m</label>
						</span>

						<SettingToggleSwitch
						// onChange={(event) => setSeatSelect(event.target.checked)}
						// checked={seatSelect}
						/>
					</div>

					<div className="line-separator" />
					<div className="component-container"></div>
				</div> */}

				{/* <div className="setting-request-cancel">
					<h3>Allow a customer to request cancel their reservation</h3>
					{''}
					<div className="line-separator" />
					<label>Select how long a customer has before they can cancel.</label>

					<div className="component-container" style={{ flexDirection: 'column' }}>
						<SettingToggleSwitch
							onChange={(event) => setallowCancel(event.target.checked)}
							checked={allowCancel}
						/>
						<PickerComponent
							data={minutesOption}
							initValue={allowCancelDuration}
							updateValue={setallowCancelDuration}
						/>
					</div>
				</div> */}

				{/* <div className="setting-edit-reservation">
					<h3>Allow a customer to request edit their reservation</h3>
					<div className="line-separator" />
					<label style={{ fontSize: '12px' }}>
						Maintain original out time when parties are seated late.
					</label>
					<div className="component-container">
						<SettingToggleSwitch
							onChange={(event) => setallowEdit(event.target.checked)}
							checked={allowEdit}
						/>
					</div>
				</div> */}
			</div>
		</Container>
	);
}

export default TableSettings;
