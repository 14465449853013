import React, { useState, useEffect, Fragment } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import { apiUrl } from '../../utils/Utility';
import withDashboardApp from '../../components/withDashboardApp';

const Shift = () => {
    const reducers = useSelector((state) => state.userReducers);
    const [data, setData] = useState([]);
    const [floors, setFloors] = useState([]);
    const [floorData, setFloorData] = useState([]);
    const [flowControlData, setFlowControl] = useState([]);
    const [shiftData, setShiftData] = useState([]);
    const [bookingDate, setBookingDate] = useState(new Date());
    
    const columns = [
      {
        name: 'Name',
        selector: 'shift_name',
        sortable: true
      },
      {
        name: 'Days of week',
        selector: 'day_of_week',
        sortable: true
      },
      {
        name: 'Start Time',
        selector: 'start_time',
        sortable: true
      },
      {
        name: 'End Time',
        selector: 'end_time',
        sortable: true
      },
      {
        name: 'Turn Time',
        selector: 'turn_time',
        sortable: true
      }
    ];
    
    const floorColumns = [
      {
        name: 'Floor',
        selector: 'floor_name',
        sortable: true
      },
      {
        name: 'Date/Time Created',
        selector: 'created_at',
        sortable: true
      }
    ];
    
    const tableColumns = [
      {
        name: 'Table Name',
        selector: 'name',
        sortable: true
      },
      {
        name: 'Minimum Capacity',
        selector: 'capacity_min',
        sortable: true
      },
      {
        name: 'Maximum Capacity',
        selector: 'capacity_max',
        sortable: true
      }
    ];
    
    const bookingsColums = [
      {
        name: 'Date',
        selector: 'date',
        sortable: true
      },
      {
        name: 'Guest',
        selector: 'guest',
        sortable: true
      },
      {
        name: 'Status',
        selector: 'status',
        sortable: true
      },
      {
        name: 'Size',
        selector: 'party_size',
        sortable: true
      },
      {
        name: 'Notes',
        selector: 'notes',
        sortable: true
      },
      {
        name: 'Start',
        selector: 'start_time',
        sortable: true
      },
      {
        name: 'End',
        selector: 'end_time',
        sortable: true
      }
    ];
    
    const blockTableColumns = [
      {
        name: 'Date',
        selector: 'block_date',
        sortable: true
      },
      {
        name: 'Start Time',
        selector: 'start_time',
        sortable: true
      },
      {
        name: 'End Time',
        selector: 'end_time',
        sortable: true
      }
    ];
    
    const combinedTableColumns = [
      {
        name: 'Table Name',
        selector: 'name',
        sortable: true
      },
      {
        name: 'Minimum Capacity',
        selector: 'capacity_min',
        sortable: true
      },
      {
        name: 'Maximum Capacity',
        selector: 'capacity_max',
        sortable: true
      }
    ];
    
    const flowControlColumns = [
      {
        name: 'Days of week',
        selector: 'days',
        sortable: true
      },
      {
        name: 'Start Time',
        selector: 'start_time',
        sortable: true
      },
      {
        name: 'End Time',
        selector: 'end_time',
        sortable: true
      },
      {
        name: 'Amount',
        selector: 'amount',
        sortable: true
      },
      {
        name: 'Status',
        selector: 'status',
        sortable: true
      }
    ];
    
    useEffect(() => {
	axios({
            url: `${apiUrl}/api/get_shifts`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${reducers.token}`
            }
        })
        .then((resp) => {
            setData(resp.data.data.shifts);
        })
        .catch((err) => {
            console.log(err);
        });
    }, []);
    
    useEffect(() => {
        if ( data.length > 0 ) {
          _.forEach(
            data,
            ({ name, day_of_week, start_time, end_time, turn_time, floors, flow_controls }) => {
                let stringDays = "";
                _.forEach(day_of_week, (day) => {
                    if ( day == 1 ) {
                        stringDays += "MON ";
                    } else if ( day == 2 ) {
                        stringDays += "TUES ";
                    } else if ( day == 3 ) {
                        stringDays += "WED ";
                    } else if ( day == 4 ) {
                        stringDays += "THURS ";
                    } else if ( day == 5 ) {
                        stringDays += "FRI ";
                    } else if ( day == 6 ) {
                        stringDays += "SAT ";
                    } else if ( day == 7 ) {
                        stringDays += "SUN ";
                    } 
                });
                let hourStart = Math.floor(start_time / 3600);
                let minuteStart = (start_time % 3600) / 60;
                if ( minuteStart == 0 ) {
                    minuteStart = "00";
                }
                let hourEnd = Math.floor(end_time / 3600);
                let minuteEnd = (end_time % 3600) / 60;
                if ( minuteEnd == 0 ) {
                    minuteEnd = "00";
                }
                let hourTurn = Math.floor(turn_time / 3600);
                let minuteTurn = (turn_time % 3600) / 60;
                if ( minuteTurn == 0 ) {
                    minuteTurn = "00";
                }
                let flowData = [];
                _.forEach(flow_controls, (flow_control) => {
                    let flowStringDays = "";
                    _.forEach(flow_control.day_of_week, (flowDay) => {
                        if ( flowDay == 1 ) {
                            flowStringDays += "MON ";
                        } else if ( flowDay == 2 ) {
                            flowStringDays += "TUES ";
                        } else if ( flowDay == 3 ) {
                            flowStringDays += "WED ";
                        } else if ( flowDay == 4 ) {
                            flowStringDays += "THURS ";
                        } else if ( flowDay == 5 ) {
                            flowStringDays += "FRI ";
                        } else if ( flowDay == 6 ) {
                            flowStringDays += "SAT ";
                        } else if ( flowDay == 7 ) {
                            flowStringDays += "SUN ";
                        } 
                    });
                    let flowHourStart = Math.floor(flow_control.start_time / 3600);
                    let flowMinuteStart = (flow_control.start_time % 3600) / 60;
                    if ( flowMinuteStart == 0 ) {
                        flowMinuteStart = "00";
                    }
                    let flowHourEnd = Math.floor(flow_control.end_time / 3600);
                    let flowMinuteEnd = (flow_control.end_time % 3600) / 60;
                    if ( flowMinuteEnd == 0 ) {
                        flowMinuteEnd = "00";
                    }
                    let flowDatum = {
                        days : flowStringDays,
                        start_time : flowHourStart + ":" + flowMinuteStart,
                        end_time : flowHourEnd + ":" + flowMinuteEnd,
                        amount : flow_control.amount,
                        status : flow_control.active ? "ACTIVE" : "INACTIVE"
                    };
                    flowData.push(flowDatum);
                });
                setShiftData((shiftData) => [
                    ...shiftData,
                    {
                      shift_name : name,
                      day_of_week : stringDays,
                      start_time : hourStart + ":" + minuteStart,
                      end_time : hourEnd + ":" + minuteEnd,
                      turn_time : hourTurn + ":" + minuteTurn,
                      floors : floors,
                      flow_controls : flowData
                    }
                ]);
            }
          );
        }
    }, [data]);
    
    
    useEffect(() => {
        if ( floors.length > 0 ) {
          _.forEach(
            floors,
            ({ floor_name, created_at, tables, combined_tables }) => {
                
                _.forEach(
                    combined_tables,
                    ({ bookings }, v) => {
                        if ( bookings.length > 0 ) {
                            let tempBookings = [];
                            
                            _.forEach(
                                bookings,
                                ({guest, status, party_size, start_time, end_time, notes, date}) => {
                                    let hourStart = Math.floor(start_time / 3600);
                                    let minuteStart = (start_time % 3600) / 60;
                                    if ( minuteStart == 0 ) {
                                        minuteStart = "00";
                                    }
                                    let hourEnd = Math.floor(end_time / 3600);
                                    let minuteEnd = (end_time % 3600) / 60;
                                    if ( minuteEnd == 0 ) {
                                        minuteEnd = "00";
                                    }
                                    let bookingStatus = "N/A";
                                    if ( status == 0 ) {
                                        bookingStatus = "unconfirmed";
                                    } else if ( status == 1 ) {
                                        bookingStatus = "all";
                                    } else if ( status == 2 ) {
                                        bookingStatus = "billed";
                                    } else if ( status == 3 ) {
                                        bookingStatus = "unbill";
                                    } else if ( status == 4 ) {
                                        bookingStatus = "seated";
                                    } else if ( status == 5 ) {
                                        bookingStatus = "unseat";
                                    } else if ( status == 6 ) {
                                        bookingStatus = "finished";
                                    } else if ( status == 7 ) {
                                        bookingStatus = "noShow";
                                    } else if ( status == 8 ) {
                                        bookingStatus = "cancelled";
                                    } else if ( status == 9 ) {
                                        bookingStatus = "seatNotWaitList";
                                    } else if ( status == 10 ) {
                                        bookingStatus = "waitList";
                                    } else if ( status == 11 ) {
                                        bookingStatus = "overTime";
                                    } else if ( status == 12 ) {
                                        bookingStatus = "upcoming";
                                    } else if ( status == 13 ) {
                                        bookingStatus = "late";
                                    } else if ( status == 14 ) {
                                        bookingStatus = "needAttention";
                                    } else if ( status == 15 ) {
                                        bookingStatus = "partiallySeated";
                                    } else if ( status == 16 ) {
                                        bookingStatus = "unfinished";
                                    }
                                    tempBookings.push({
                                        date,
                                        guest : guest ? guest.first_name + " " + guest.last_name : "N/A",
                                        status : bookingStatus,
                                        party_size,
                                        start_time : hourStart + ":" + minuteStart,
                                        end_time : hourEnd + ":" + minuteEnd,
                                        notes : notes ? notes : "N/A"
                                    });
                                });
                            combined_tables[v].bookings = tempBookings;
                        }
                    }
                );
                
                _.forEach(
                    tables,
                    ({ bookings, block_tables }, v) => {
                        if ( bookings.length > 0 ) {
                            let tempBookings = [];
                            
                            _.forEach(
                                bookings,
                                ({date, guest, status, party_size, start_time, end_time, notes}) => {
                                    let hourStart = Math.floor(start_time / 3600);
                                    let minuteStart = (start_time % 3600) / 60;
                                    if ( minuteStart == 0 ) {
                                        minuteStart = "00";
                                    }
                                    let hourEnd = Math.floor(end_time / 3600);
                                    let minuteEnd = (end_time % 3600) / 60;
                                    if ( minuteEnd == 0 ) {
                                        minuteEnd = "00";
                                    }
                                    let bookingStatus = "N/A";
                                    if ( status == 0 ) {
                                        bookingStatus = "unconfirmed";
                                    } else if ( status == 1 ) {
                                        bookingStatus = "all";
                                    } else if ( status == 2 ) {
                                        bookingStatus = "billed";
                                    } else if ( status == 3 ) {
                                        bookingStatus = "unbill";
                                    } else if ( status == 4 ) {
                                        bookingStatus = "seated";
                                    } else if ( status == 5 ) {
                                        bookingStatus = "unseat";
                                    } else if ( status == 6 ) {
                                        bookingStatus = "finished";
                                    } else if ( status == 7 ) {
                                        bookingStatus = "noShow";
                                    } else if ( status == 8 ) {
                                        bookingStatus = "cancelled";
                                    } else if ( status == 9 ) {
                                        bookingStatus = "seatNotWaitList";
                                    } else if ( status == 10 ) {
                                        bookingStatus = "waitList";
                                    } else if ( status == 11 ) {
                                        bookingStatus = "overTime";
                                    } else if ( status == 12 ) {
                                        bookingStatus = "upcoming";
                                    } else if ( status == 13 ) {
                                        bookingStatus = "late";
                                    } else if ( status == 14 ) {
                                        bookingStatus = "needAttention";
                                    } else if ( status == 15 ) {
                                        bookingStatus = "partiallySeated";
                                    } else if ( status == 16 ) {
                                        bookingStatus = "unfinished";
                                    }
                                    tempBookings.push({
                                        date,
                                        guest : guest ? guest.first_name + " " + guest.last_name : "N/A",
                                        status : bookingStatus,
                                        party_size,
                                        start_time : hourStart + ":" + minuteStart,
                                        end_time : hourEnd + ":" + minuteEnd,
                                        notes : notes ? notes : "N/A"
                                    });
                                });
                            tables[v].bookings = tempBookings;
                        }
                        if ( block_tables.length > 0 ) {
                            let tempBlockTables = [];
                            _.forEach(
                                block_tables,
                                ({date, start_time, end_time}) => {
                                    let hourStart = Math.floor(start_time / 3600);
                                    let minuteStart = (start_time % 3600) / 60;
                                    if ( minuteStart == 0 ) {
                                        minuteStart = "00";
                                    }
                                    let hourEnd = Math.floor(end_time / 3600);
                                    let minuteEnd = (end_time % 3600) / 60;
                                    if ( minuteEnd == 0 ) {
                                        minuteEnd = "00";
                                    }
                                    tempBlockTables.push({
                                        block_date : date,
                                        start_time : hourStart + ":" + minuteStart,
                                        end_time : hourEnd + ":" + minuteEnd
                                    });
                                });
                            tables[v].block_tables = tempBlockTables;
                        }
                    }
                )
                setFloorData((floorData) => [
                  ...floorData,
                  {
                    floor_name,
                    created_at : moment(created_at).format("YYYY-MM-DD HH:mm:ss"),
                    tables,
                    combined_tables
                  }
                ]);
            }
          );
        }
    }, [floors]);

    const viewFloors = (isOpen, e) => {
        if ( isOpen ) {
            axios({
                url: `${apiUrl}/api/view_floors`,
                method: 'POST',
                data: {
                    ids : e.floors,
                    date : moment(bookingDate).format("YYYY-MM-DD")
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${reducers.token}`
                }
            })
            .then((resp) => {
                setFloors(resp.data.data.floors);
            })
            .catch((err) => {
                console.log(err);
            });
        }
    };

    const expandBookingsAndBlockTables =  (e) => {
        return (
            <div style={{ padding: '1rem 3rem' }}>
                <DataTable
                    title="Bookings"
                    columns={bookingsColums}
                    data={e.data.bookings}
                />
                <DataTable
                    title="Blocked"
                    columns={blockTableColumns}
                    data={e.data.block_tables}
                />
            </div>
        );
    }
    
    const expandTables = (e) => {
        return (
            <div style={{ padding: '1rem 3rem' }}>
                <DataTable
                    title="Tables"
                    columns={tableColumns}
                    data={e.data.tables}
                    expandableRows
                    expandOnRowDoubleClicked={true}
                    expandableRowsComponent={expandBookingsAndBlockTables}
                />
                <DataTable
                    title="Combined Tables"
                    columns={combinedTableColumns}
                    data={e.data.combined_tables}
                    expandableRows
                    expandOnRowDoubleClicked={true}
                    expandableRowsComponent={expandBookingsAndBlockTables}
                />
            </div>
        );
    }

    const floorAndflowControls = (e) => {
        return (
            <div style={{ padding: '1rem 3rem' }}>
                <DataTable
                        title="Floors"
                        columns={floorColumns}
                        data={floorData}
                        expandableRows
                        expandOnRowDoubleClicked={true}
                        expandableRowsComponent={expandTables}
                />
                <DataTable
                    title="Flow Controls"
                    columns={flowControlColumns}
                    data={e.data.flow_controls}
                />
            </div>
        );
    }
    
    return (
        <Fragment>
            <DataTable
                title="Shifts"
                columns={columns}
                data={shiftData}
                expandableRows
                expandOnRowDoubleClicked={true}
                onRowExpandToggled={viewFloors}
                expandableRowsComponent={floorAndflowControls}
            />
        </Fragment>
    );
};

export default withDashboardApp(Shift);
