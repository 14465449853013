import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import ShiftItem from './ShiftItem';
import { isArray } from 'lodash';
import ModalSpinner from './ModalSpinner';
import DotLoader from './DotLoader';
// import { scheduleDays, TableHours } from './TableScheduleData';

const Container = styled.div`
	display: inline-block;
	// overflow: auto;
	box-shadow: inset 5px 5px 10px #1a2024, inset -5px -5px 10px #2a343a;
	border-radius: 0.5em;
	width: ${(props) => props.width};
	::-webkit-scrollbar {
		display: none;
	}

	::-webkit-scrollbar-thumb {
		width: 1px;
		height: 1px;
		border-radius: 20px;
		background-color: #2a343a;
	}
`;

const TableContainer = styled.table`
	height: 577px;
	overflow: auto;
	user-select: none;
	// margin-left: 27px;
	border-radius: 0.8em;
	background: transparent;
`;

const Column = styled.td`
	min-width: 130px;
	width: 220px;
	border-left: 1px solid #3a444b;
	border-right: 1px solid #3a444b;
	color: #222a2f;
	user-select: none;
	justify-content: center;
	background: transparent;
`;

const Rows = styled.tr`
	width: 122px;
	height: 40px;
	border-top: 1px solid #3a444b;
	border-bottom: 1px solid #3a444b;
	color: #222a2f;
	user-select: none;
	background: transparent;
`;

const TimeContainer = styled.ul`
	color: #707070;
	height: auto;
	width: 75px;
	padding: 70px 0 0 0;
	list-style-type: none;
	user-select: none;
	background: #222a2f;
	box-shadow: inset 7px 0px 6px -1px #1a2024, inset 1px -3px 5px 0px #2a343a;
	li {
		list-style-type: none;
		height: 40px;
		text-align: end;
		padding-right: 10px;
	}
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
	padding: 0;
	marigin: 0;
`;

const Col = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0;
	margin: 0;
`;

const ScrollableWrapper = styled.div`
	display: flex;
	position: relative;
	width: ${(props) => props.width};
	height: 575px;
	// justify-content: center;
	overflow-x: auto;
	background: transparent;
	border-radius: 0.5em;
	user-select: none;
	::-webkit-scrollbar {
		width: 5px;
		height: 5px;
		background-color: #222a2f;
	}
	::-webkit-scrollbar-thumb {
		width: 5px;
		height: 5px;
		border-radius: 20px;
		background-color: #757a7d33;
	}
	::-webkit-scrollbar-corner {
		display: none;
	}
`;

const TR = styled.tr`
	text-align: center;
	height: 40px;
	user-select: none;
	background: transparent;
	// vertical-align: bottom;
`;

const TH = styled.th`
	text-align: center;
	min-width: 130px;
	font-size: 12px;
	font-weight: 450;
	user-select: none;
	// justify-content: center;
	background: transparent;
	// vertical-align: bottom;
`;

const Header = styled.thead`
	height: 40px;
	width: 100%;
	padding: 0;
	margin: 0;
	color: #bcbcbc;
	user-select: none;
`;

// const ShiftItem = styled.div`
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	width: 100px;
// 	height: 195px;
// 	margin: 0 15px;
// 	border-radius: 0.8em;
// 	top: 150px;
// 	background: ${(props) => props.color};
// 	border: 5px solid #222a2f;
// 	box-shadow: 5px 5px 10px #1a2024, -5px -5px 10px #2a343a;
// `;

const ShiftField = styled.div`
	display: flex;
	flex-direction: row;
	top: 40px;
	height: 100%;
	// overflow: auto;
	user-select: none;
	border-radius: 0.8em;
	background: transparent;

	#item-container${(props) => props.itemNo} {
		// padding: 0 15px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		height: auto;
		span {
			width: 100%;
			height: fit-content;
			// background: #222a2f;
			cursor: pointer;
		}
	}
`;

function ScheduleTimeTable(props) {
	const [shifts, setShifts] = useState(props.shifts);
	const [selectedShift, setSelectedShift] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isShiftSelected, setIsShiftSelected] = useState(props.isShiftSelected);
	const [dayOfweekChecker, setDayOfweekChecker] = useState({
		mon: false,
		tue: false,
		wed: false,
		thu: false,
		fri: false,
		sat: false,
		sun: false,
	});
	const [columnWidth, setColumnWidth] = useState(200);
	const itemNo = [1, 2, 3, 4, 5, 6, 7];
	const hours = 20;
	const formatedHours = [];
	const tableRows = [];

	for (let i = -2; i <= hours; i++) {
		formatedHours.push(moment(i * 3600 * 1000).format('HH:mm'));
	}

	for (let i = 0; i <= 21; i++) {
		tableRows.push(
			<Rows id={'row' + i} key={Math.random()}>
				<Column key={Math.random()}></Column>
				<Column key={Math.random()}></Column>
				<Column key={Math.random()}></Column>
				<Column key={Math.random()}></Column>
				<Column key={Math.random()}></Column>
				<Column key={Math.random()}></Column>
				<Column key={Math.random()}></Column>
			</Rows>,
		);
	}

	useEffect(() => {
		function updateSize() {
			const item = document.getElementById('shd-table-head');
			if (isShiftSelected) {
				setColumnWidth(item.getBoundingClientRect().width - 0.5);
			} else {
				setColumnWidth(item.getBoundingClientRect().width);
			}
		}
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, [isShiftSelected]);

	const getTopPosition = (seconds) => {
		let int = 10;
		let count = -1;

		for (let time = 18000; time <= seconds; time += 900) {
			++count;
			if (time === seconds) {
				// let newCount = count * 2;
				let pixel = int * count;
				return pixel;
			}
		}
	};

	const getBottomPosition = (seconds) => {
		let int = 10;
		let count = -1;

		for (let time = 18000; time <= seconds; time += 900) {
			++count;
			if (time === seconds) {
				// let newCount = count * 2;
				let pixel = int * count;
				return pixel;
			}
		}
	};

	const getItemHeight = (top, seconds) => {
		let int = 10;
		let count = -1;
		for (let time = 18000; time <= seconds; time += 900) {
			++count;
			if (time === seconds) {
				// let newCount = count * 2;
				let pixel = int * count;
				pixel = pixel - top;
				return pixel;
			}
		}
	};

	const selectShift = (shift, id, e) => {
		e.stopPropagation();
		if (id !== undefined) {
			let elmnt = document.getElementById(`${id}`);
			setTimeout(() => {
				elmnt.scrollIntoView({ behavior: 'smooth', block: 'center' });
			}, 1);
		}

		setSelectedShift(shift);
		props.seletectShiftCallback(shift);
		checkDayOfWeekHandler(shift);
	};

	const checkDayOfWeekHandler = (shift) => {
		let day = shift.day_of_week;
		let mon = true;
		let tue = true;
		let wed = true;
		let thu = true;
		let fri = true;
		let sat = true;
		let sun = true;
		if (day.includes(1)) {
			mon = false;
		}
		if (day.includes(2)) {
			tue = false;
		}
		if (day.includes(3)) {
			wed = false;
		}
		if (day.includes(4)) {
			thu = false;
		}
		if (day.includes(5)) {
			fri = false;
		}
		if (day.includes(6)) {
			sat = false;
		}
		if (day.includes(7)) {
			sun = false;
		}
		setDayOfweekChecker({
			mon: mon,
			tue: tue,
			wed: wed,
			thu: thu,
			fri: fri,
			sat: sat,
			sun: sun,
		});
	};

	const handleRemoveDay = (id) => {
		// console.log(id);
		let newShifts = shifts;
		let shift = { ...selectedShift };

		if (id.includes('1')) {
			let idx = shift.day_of_week.findIndex((e) => e === 1);
			shift.day_of_week.splice(idx, 1);
			shift.day_of_week.sort();
			let _idx = newShifts.findIndex((e) => e.id === selectedShift.id);
			newShifts.splice(_idx, 1, shift);
			setShifts(newShifts);
			props.updateShifts(shift);
		}

		if (id.includes('2')) {
			let idx = shift.day_of_week.findIndex((e) => e === 2);
			shift.day_of_week.splice(idx, 1);
			shift.day_of_week.sort();
			let _idx = newShifts.findIndex((e) => e.id === selectedShift.id);
			newShifts.splice(_idx, 1, shift);
			setShifts(newShifts);
			props.updateShifts(shift);
		}

		if (id.includes('3')) {
			let idx = shift.day_of_week.findIndex((e) => e === 3);
			shift.day_of_week.splice(idx, 1);
			shift.day_of_week.sort();
			let _idx = newShifts.findIndex((e) => e.id === selectedShift.id);
			newShifts.splice(_idx, 1, shift);
			setShifts(newShifts);
			props.updateShifts(shift);
		}

		if (id.includes('4')) {
			let idx = shift.day_of_week.findIndex((e) => e === 4);
			shift.day_of_week.splice(idx, 1);
			shift.day_of_week.sort();
			let _idx = newShifts.findIndex((e) => e.id === selectedShift.id);
			newShifts.splice(_idx, 1, shift);
			setShifts(newShifts);
			props.updateShifts(shift);
		}

		if (id.includes('5')) {
			let idx = shift.day_of_week.findIndex((e) => e === 5);
			shift.day_of_week.splice(idx, 1);
			shift.day_of_week.sort();
			let _idx = newShifts.findIndex((e) => e.id === selectedShift.id);
			newShifts.splice(_idx, 1, shift);
			setShifts(newShifts);
			props.updateShifts(shift);
		}

		if (id.includes('6')) {
			let idx = shift.day_of_week.findIndex((e) => e === 6);
			shift.day_of_week.splice(idx, 1);
			shift.day_of_week.sort();
			let _idx = newShifts.findIndex((e) => e.id === selectedShift.id);
			newShifts.splice(_idx, 1, shift);
			setShifts(newShifts);
			props.updateShifts(shift);
		}

		if (id.includes('7')) {
			let idx = shift.day_of_week.findIndex((e) => e === 7);
			shift.day_of_week.splice(idx, 1);
			shift.day_of_week.sort();
			let _idx = newShifts.findIndex((e) => e.id === selectedShift.id);
			newShifts.splice(_idx, 1, shift);
			setShifts(newShifts);
			props.updateShifts(shift);
		}

		props.isShiftSelectedHandler();
	};

	const handleAddDay = (id) => {
		let newShifts = shifts;
		let shift = { ...selectedShift };

		if (id.includes('1')) {
			shift.day_of_week.unshift(1);
			shift.day_of_week.sort();
			let _idx = newShifts.findIndex((e) => e.id === selectedShift.id);
			newShifts.splice(_idx, 1, shift);
			setShifts(newShifts);
			props.updateShifts(shift);
		}

		if (id.includes('2')) {
			shift.day_of_week.unshift(2);
			shift.day_of_week.sort();
			let _idx = newShifts.findIndex((e) => e.id === selectedShift.id);
			newShifts.splice(_idx, 1, shift);
			setShifts(newShifts);
			props.updateShifts(shift);
		}

		if (id.includes('3')) {
			shift.day_of_week.unshift(3);
			shift.day_of_week.sort();
			let _idx = newShifts.findIndex((e) => e.id === selectedShift.id);
			newShifts.splice(_idx, 1, shift);
			setShifts(newShifts);
			props.updateShifts(shift);
		}

		if (id.includes('4')) {
			shift.day_of_week.unshift(4);
			shift.day_of_week.sort();
			let _idx = newShifts.findIndex((e) => e.id === selectedShift.id);
			newShifts.splice(_idx, 1, shift);
			setShifts(newShifts);
			props.updateShifts(shift);
		}

		if (id.includes('5')) {
			shift.day_of_week.unshift(5);
			shift.day_of_week.sort();
			let _idx = newShifts.findIndex((e) => e.id === selectedShift.id);
			newShifts.splice(_idx, 1, shift);
			setShifts(newShifts);
			props.updateShifts(shift);
		}

		if (id.includes('6')) {
			shift.day_of_week.unshift(6);
			shift.day_of_week.sort();
			let _idx = newShifts.findIndex((e) => e.id === selectedShift.id);
			newShifts.splice(_idx, 1, shift);
			setShifts(newShifts);
			props.updateShifts(shift);
		}

		if (id.includes('7')) {
			shift.day_of_week.unshift(7);
			shift.day_of_week.sort();
			let _idx = newShifts.findIndex((e) => e.id === selectedShift.id);
			newShifts.splice(_idx, 1, shift);
			setShifts(newShifts);
			props.updateShifts(shift);
		}

		props.isShiftSelectedHandler();
	};

	const convertPixeltoTime = (pixel) => {
		let int = 900;
		let count = -1;

		for (let pxl = 0; pxl <= pixel; pxl += 10) {
			++count;
			if (pxl === pixel) {
				let seconds = int * count;
				return seconds + 18000;
			}
		}
	};

	const resizeCallbackHandler = (top, bottom) => {
		let north = convertPixeltoTime(top);
		let south = convertPixeltoTime(bottom);
		let shift = selectedShift;
		let newShifts = shifts;
		let idx = newShifts.findIndex((e) => e.id === shift.id);
		shift = { ...shift, start_time: north, end_time: south };
		newShifts.splice(idx, 1, shift);
		setSelectedShift(shift);
		setShifts(newShifts);
		props.setSelectedShift(shift);
	};

	const dragShiftCallback = (top, bottom) => {
		let north = convertPixeltoTime(top);
		let south = convertPixeltoTime(bottom);
		let shift = selectedShift;
		let newShifts = shifts;
		let idx = newShifts.findIndex((e) => e.id === shift.id);
		shift = { ...shift, start_time: north, end_time: south };
		newShifts.splice(idx, 1, shift);
		setSelectedShift(shift);
		setShifts(newShifts);
		props.setSelectedShift(shift);
	};

	useEffect(() => {
		setIsShiftSelected(props.isShiftselected);
	}, [props.isShiftselected]);

	return (
		<Container width={props.isShiftselected ? '74%' : '100%'}>
			<div
				style={{
					display: 'flex',
					width: '75px',
					height: '46px',
					position: 'absolute',
					background: '#222a2f',
					boxShadow: 'inset 5px 5px 5px -1px #1a2024',
					borderTopLeftRadius: '10px',
					zIndex: 3,
				}}
			></div>
			<span
				style={{
					display: 'flex',
					flexDirection: 'row',
					position: 'relative',
					width: 'auto',
					justifyContent: 'center',
					top: '39px',
					zIndex: 3,
				}}
			>
				<div className="tsched-line-separator" />
			</span>
			{/* <DotLoader isLoading={props.isLoading} /> */}

			<ScrollableWrapper id="scroll-container" width={'100%'}>
				<div style={{ position: '-webkit-sticky', position: 'sticky', left: '0px', zIndex: 2 }}>
					<TimeContainer>
						{/* <Button onClick={getTopPosition} style={{ color: 'black' }} /> */}
						{formatedHours.length > 0
							? formatedHours.map((t, key) => {
									return <li key={key}>{t}</li>;
							  })
							: ''}
					</TimeContainer>
				</div>
				<Col>
					{/* {!isLoading ? ( */}
					<ShiftField id="field" style={{ position: 'absolute' }} itemNo={itemNo}>
						<div id="item-container1" style={{ height: '921px', width: columnWidth }}>
							{shifts.map((shift, key) => {
								if (shift.day_of_week !== undefined) {
									if (shift.day_of_week.includes(1)) {
										return (
											<span
												onClick={(e) =>
													selectShift(
														shift,
														shift.id + 'item-container1',
														// getItemHeight(getTopPosition(shift.start_time), shift.end_time),
														e,
													)
												}
												key={key}
											>
												<ShiftItem
													columnWidth={columnWidth}
													handleChangeDay={handleRemoveDay}
													day={dayOfweekChecker.mon}
													isShiftselected={
														isShiftSelected ? (selectedShift.id === shift.id ? true : false) : false
													}
													color={shift.shift_color}
													id={shift.id}
													key={key}
													containerId="item-container1"
													name={shift.name}
													top={getTopPosition(shift.start_time)}
													height={getItemHeight(getTopPosition(shift.start_time), shift.end_time)}
													resizeCallbackHandler={resizeCallbackHandler}
													dragShiftCallback={dragShiftCallback}
												/>
											</span>
										);
									}
								}
							})}
							{isShiftSelected ? (
								<>
									{dayOfweekChecker.mon ? (
										<span>
											<ShiftItem
												columnWidth={columnWidth}
												handleChangeDay={handleAddDay}
												isShiftselected={true}
												day={true}
												item="default"
												id={selectedShift.id}
												containerId="item-container1"
												name={selectedShift.name}
												top={getTopPosition(selectedShift.start_time)}
												height={getItemHeight(
													getTopPosition(selectedShift.start_time),
													selectedShift.end_time,
												)}
												resizeCallbackHandler={resizeCallbackHandler}
												dragShiftCallback={dragShiftCallback}
												zIndex={1}
											/>
										</span>
									) : (
										''
									)}
								</>
							) : (
								''
							)}
						</div>
						<div id="item-container2" style={{ height: '921px', width: columnWidth }}>
							{shifts.map((shift, key) => {
								if (shift.day_of_week !== undefined) {
									if (shift.day_of_week.includes(2)) {
										return (
											<span
												onClick={(e) => selectShift(shift, shift.id + 'item-container2', e)}
												key={key}
											>
												<ShiftItem
													columnWidth={columnWidth}
													handleChangeDay={handleRemoveDay}
													day={dayOfweekChecker.tue}
													isShiftselected={
														isShiftSelected ? (selectedShift.id === shift.id ? true : false) : false
													}
													color={shift.shift_color}
													id={shift.id}
													key={key}
													containerId="item-container2"
													name={shift.name}
													top={getTopPosition(shift.start_time)}
													height={getItemHeight(getTopPosition(shift.start_time), shift.end_time)}
													resizeCallbackHandler={resizeCallbackHandler}
													dragShiftCallback={dragShiftCallback}
												/>
											</span>
										);
									}
								}
							})}
							{isShiftSelected ? (
								<>
									{dayOfweekChecker.tue ? (
										<span>
											<ShiftItem
												columnWidth={columnWidth}
												handleChangeDay={handleAddDay}
												isShiftselected={true}
												day={true}
												item="default"
												id={selectedShift.id}
												containerId="item-container2"
												name={selectedShift.name}
												top={getTopPosition(selectedShift.start_time)}
												height={getItemHeight(
													getTopPosition(selectedShift.start_time),
													selectedShift.end_time,
												)}
												resizeCallbackHandler={resizeCallbackHandler}
												dragShiftCallback={dragShiftCallback}
												zIndex={1}
											/>
										</span>
									) : (
										''
									)}
								</>
							) : (
								''
							)}
						</div>
						<div id="item-container3" style={{ height: '921px', width: columnWidth }}>
							{shifts.map((shift, key) => {
								if (shift.day_of_week !== undefined) {
									if (shift.day_of_week.includes(3)) {
										return (
											<span
												onClick={(e) => selectShift(shift, shift.id + 'item-container3', e)}
												key={key}
											>
												<ShiftItem
													columnWidth={columnWidth}
													handleChangeDay={handleRemoveDay}
													day={dayOfweekChecker.wed}
													isShiftselected={
														isShiftSelected ? (selectedShift.id === shift.id ? true : false) : false
													}
													color={shift.shift_color}
													id={shift.id}
													key={key}
													containerId="item-container3"
													name={shift.name}
													top={getTopPosition(shift.start_time)}
													height={getItemHeight(getTopPosition(shift.start_time), shift.end_time)}
													resizeCallbackHandler={resizeCallbackHandler}
													dragShiftCallback={dragShiftCallback}
												/>
											</span>
										);
									}
								}
							})}
							{isShiftSelected ? (
								<>
									{dayOfweekChecker.wed ? (
										<span>
											<ShiftItem
												columnWidth={columnWidth}
												handleChangeDay={handleAddDay}
												isShiftselected={true}
												day={true}
												item="default"
												id={selectedShift.id}
												containerId="item-container3"
												name={selectedShift.name}
												top={getTopPosition(selectedShift.start_time)}
												height={getItemHeight(
													getTopPosition(selectedShift.start_time),
													selectedShift.end_time,
												)}
												resizeCallbackHandler={resizeCallbackHandler}
												dragShiftCallback={dragShiftCallback}
												zIndex={1}
											/>
										</span>
									) : (
										''
									)}
								</>
							) : (
								''
							)}
						</div>
						<div id="item-container4" style={{ height: '921px', width: columnWidth }}>
							{shifts.map((shift, key) => {
								if (shift.day_of_week !== undefined) {
									if (shift.day_of_week.includes(4)) {
										return (
											<span
												onClick={(e) => selectShift(shift, shift.id + 'item-container4', e)}
												key={key}
											>
												<ShiftItem
													columnWidth={columnWidth}
													handleChangeDay={handleRemoveDay}
													day={dayOfweekChecker.thu}
													isShiftselected={
														isShiftSelected ? (selectedShift.id === shift.id ? true : false) : false
													}
													color={shift.shift_color}
													id={shift.id}
													key={key}
													containerId="item-container4"
													name={shift.name}
													top={getTopPosition(shift.start_time)}
													height={getItemHeight(getTopPosition(shift.start_time), shift.end_time)}
													resizeCallbackHandler={resizeCallbackHandler}
													dragShiftCallback={dragShiftCallback}
												/>
											</span>
										);
									}
								}
							})}
							{isShiftSelected ? (
								<>
									{dayOfweekChecker.thu ? (
										<span>
											<ShiftItem
												columnWidth={columnWidth}
												handleChangeDay={handleAddDay}
												isShiftselected={true}
												day={true}
												item="default"
												id={selectedShift.id}
												containerId="item-container4"
												name={selectedShift.name}
												top={getTopPosition(selectedShift.start_time)}
												height={getItemHeight(
													getTopPosition(selectedShift.start_time),
													selectedShift.end_time,
												)}
												resizeCallbackHandler={resizeCallbackHandler}
												dragShiftCallback={dragShiftCallback}
												zIndex={1}
											/>
										</span>
									) : (
										''
									)}
								</>
							) : (
								''
							)}
						</div>
						<div id="item-container5" style={{ height: '921px', width: columnWidth }}>
							{shifts.map((shift, key) => {
								if (shift.day_of_week !== undefined) {
									if (shift.day_of_week.includes(5)) {
										return (
											<span
												onClick={(e) => selectShift(shift, shift.id + 'item-container5', e)}
												key={key}
											>
												<ShiftItem
													columnWidth={columnWidth}
													handleChangeDay={handleRemoveDay}
													day={dayOfweekChecker.fri}
													isShiftselected={
														isShiftSelected ? (selectedShift.id === shift.id ? true : false) : false
													}
													color={shift.shift_color}
													id={shift.id}
													key={key}
													containerId="item-container5"
													name={shift.name}
													top={getTopPosition(shift.start_time)}
													height={getItemHeight(getTopPosition(shift.start_time), shift.end_time)}
													resizeCallbackHandler={resizeCallbackHandler}
													dragShiftCallback={dragShiftCallback}
												/>
											</span>
										);
									}
								}
							})}
							{isShiftSelected ? (
								<>
									{dayOfweekChecker.fri ? (
										<span>
											<ShiftItem
												columnWidth={columnWidth}
												handleChangeDay={handleAddDay}
												isShiftselected={true}
												day={true}
												item="default"
												id={selectedShift.id}
												containerId="item-container5"
												name={selectedShift.name}
												top={getTopPosition(selectedShift.start_time)}
												height={getItemHeight(
													getTopPosition(selectedShift.start_time),
													selectedShift.end_time,
												)}
												resizeCallbackHandler={resizeCallbackHandler}
												dragShiftCallback={dragShiftCallback}
												zIndex={1}
											/>
										</span>
									) : (
										''
									)}
								</>
							) : (
								''
							)}
						</div>
						<div id="item-container6" style={{ height: '921px', width: columnWidth }}>
							{shifts.map((shift, key) => {
								if (shift.day_of_week !== undefined) {
									if (shift.day_of_week.includes(6)) {
										return (
											<span
												onClick={(e) => selectShift(shift, shift.id + 'item-container6', e)}
												key={key}
											>
												<ShiftItem
													columnWidth={columnWidth}
													handleChangeDay={handleRemoveDay}
													day={dayOfweekChecker.sat}
													isShiftselected={
														isShiftSelected ? (selectedShift.id === shift.id ? true : false) : false
													}
													color={shift.shift_color}
													id={shift.id}
													key={key}
													containerId="item-container6"
													name={shift.name}
													top={getTopPosition(shift.start_time)}
													height={getItemHeight(getTopPosition(shift.start_time), shift.end_time)}
													resizeCallbackHandler={resizeCallbackHandler}
													dragShiftCallback={dragShiftCallback}
												/>
											</span>
										);
									}
								}
							})}
							{isShiftSelected ? (
								<>
									{dayOfweekChecker.sat ? (
										<span>
											<ShiftItem
												columnWidth={columnWidth}
												handleChangeDay={handleAddDay}
												isShiftselected={true}
												day={true}
												item="default"
												id={selectedShift.id}
												containerId="item-container6"
												name={selectedShift.name}
												top={getTopPosition(selectedShift.start_time)}
												height={getItemHeight(
													getTopPosition(selectedShift.start_time),
													selectedShift.end_time,
												)}
												resizeCallbackHandler={resizeCallbackHandler}
												dragShiftCallback={dragShiftCallback}
												zIndex={1}
											/>
										</span>
									) : (
										''
									)}
								</>
							) : (
								''
							)}
						</div>
						<div id="item-container7" style={{ height: '921px', width: columnWidth }}>
							{shifts.map((shift, key) => {
								if (shift.day_of_week !== undefined) {
									if (shift.day_of_week.includes(7)) {
										return (
											<span
												onClick={(e) => selectShift(shift, shift.id + 'item-container7', e)}
												key={key}
											>
												<ShiftItem
													columnWidth={columnWidth}
													handleChangeDay={handleRemoveDay}
													day={dayOfweekChecker.sun}
													isShiftselected={
														isShiftSelected ? (selectedShift.id === shift.id ? true : false) : false
													}
													color={shift.shift_color}
													id={shift.id}
													key={key}
													containerId="item-container7"
													name={shift.name}
													top={getTopPosition(shift.start_time)}
													height={getItemHeight(getTopPosition(shift.start_time), shift.end_time)}
													resizeCallbackHandler={resizeCallbackHandler}
													dragShiftCallback={dragShiftCallback}
												/>
											</span>
										);
									}
								}
							})}
							{isShiftSelected ? (
								<>
									{dayOfweekChecker.sun ? (
										<span>
											<ShiftItem
												columnWidth={columnWidth}
												handleChangeDay={handleAddDay}
												isShiftselected={true}
												day={true}
												item="default"
												id={selectedShift.id}
												containerId="item-container7"
												name={selectedShift.name}
												top={getTopPosition(selectedShift.start_time)}
												height={getItemHeight(
													getTopPosition(selectedShift.start_time),
													selectedShift.end_time,
												)}
												resizeCallbackHandler={resizeCallbackHandler}
												dragShiftCallback={dragShiftCallback}
												zIndex={1}
											/>
										</span>
									) : (
										''
									)}
								</>
							) : (
								''
							)}
						</div>
					</ShiftField>
					{/* ) : (
						<ModalSpinner isLoading={isLoading} />
					)} */}

					<TableContainer>
						<Header
							style={{
								position: '-webkit-sticky',
								position: 'sticky',
								top: '0px',
								background: '#222a2f',
								zIndex: 2,
							}}
						>
							<TR>
								<TH id="shd-table-head">MON</TH>
								<TH>TUE</TH>
								<TH>WED</TH>
								<TH>THU</TH>
								<TH>FRI</TH>
								<TH>SAT</TH>
								<TH>SUN</TH>
							</TR>
						</Header>
						<tbody style={{ borderColor: 'none' }}>
							<Rows style={{ borderTop: 'none' }}>
								<Column></Column>
								<Column></Column>
								<Column></Column>
								<Column></Column>
								<Column></Column>
								<Column></Column>
								<Column></Column>
							</Rows>
							{tableRows}
						</tbody>
					</TableContainer>
				</Col>
			</ScrollableWrapper>
			{/* </Row> */}
		</Container>
	);
}

export default ScheduleTimeTable;
