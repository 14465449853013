import React, { useEffect, useState } from 'react';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import confirmIcon from 'assets/images/booking/create-booking/check-icon.png';
import cancelIcon from 'assets/images/booking/exit-btn-table-details.png';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		width: '100%',
		height: '100%',
		backdropFilter: 'blur(10px)',
		outline: 'none',
	},
}));
const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	justify-content: center;
`;
const Content = styled.div`
	width: 403px;
	height: 190px;
	background: rgba(0, 0, 0, 0.6);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 10px;
	border-color: none;
	padding: 20px;
	color: white;
`;
const Header = styled.label`
	align-items: center;
	font-size: 15px;
	color: white;
	// white-space: nowrap;
	span {
		display: flex;
		flex-direction: row;
		p {
			font-weight: bold;
		}
	}
`;
const ButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
	// height: 80px;
`;
const ButtonWrapper = styled.span`
	color: black;
	font-size: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50px;
	height: 50px;
	border-radius: 10px;
	margin: 40px 5px 10px 5px;
	cursor: pointer;
	background: ${(props) => props.default};
	&:active {
		background-color: ${(props) => props.color};
		outline: none;
		outline-offset: none;
	}
	img {
		user-select: none;
	}
`;

function DeleteSectionModal(props) {
	const classes = useStyles();
	const [value, setvalue] = useState(1);

	const handleCancel = () => {
		props.handleDeleteSection(null);
	};

	const handleConfirm = (data) => {
		props.handleDeleteSection(data);
	};
	// console.log(props.data);

	return (
		<Modal
			className={classes.modal}
			open={props.openDelFloorPrompt}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={props.openDelFloorPrompt}>
				<div className={classes.paper}>
					<Wrapper>
						<Content>
							{props.data !== null ? (
								<Header>
									<span>
										Are you sure you want to delete &nbsp;
										<p>{props.data.floor_name} &nbsp;?</p>
									</span>
									<span>This will remove all the table and the settings.</span>
								</Header>
							) : (
								<Header>No selected floor</Header>
							)}
							{props.data !== null ? (
								<ButtonContainer>
									<ButtonWrapper default={'#CCCCCC'} color={'#1f262b'} onClick={handleCancel}>
										<img src={cancelIcon} width="12" height="12" alt="Icon" />
									</ButtonWrapper>
									<ButtonWrapper
										default={'#FF8585'}
										color={'#1f262b'}
										onClick={() => handleConfirm(props.data)}
									>
										<img src={confirmIcon} width="12" height="12" alt="Icon" />
									</ButtonWrapper>
								</ButtonContainer>
							) : (
								<ButtonContainer>
									<ButtonWrapper default={'#CCCCCC'} color={'#1f262b'} onClick={handleCancel}>
										OK
									</ButtonWrapper>
								</ButtonContainer>
							)}
						</Content>
					</Wrapper>
				</div>
			</Fade>
		</Modal>
	);
}
export default DeleteSectionModal;
