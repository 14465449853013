import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Foot = {
	GradientWrapper: styled.div`
		background: transparent linear-gradient(97deg, #fb2c7f 0%, #f6d5a0 100%) 0% 0% no-repeat
			padding-box;
		padding: 4rem 2rem 8rem;
	`,
	Row: styled(Row)`
		max-width: ${(props) => props.propsMaxWidth || '525px'};
		margin: 0 auto;
	`,
	Link: styled(Link)`
		color: #ffffff;
		text-decoration: none;
		&:hover {
			color: #ffffff;
		}
	`,
	Text: styled.div`
		color: #ffffff;
		margin-top: ${(props) => props.propsMtop};
		font-size: ${(props) => props.propsFontSize};
	`,
};

const Spacer = styled.div`
	margin-top: ${(props) => props.propsMtop || 0};
	margin-bottom: ${(props) => props.propsMbottom || 0};
	margin-right: ${(props) => props.propsMright || 0};
	margin-left: ${(props) => props.propsMleft || 0};
	height: ${(props) => props.propsHeight};
`;

const Footer = () => {
	const date = new Date();
	const year = date.getFullYear();

	return (
		<Foot.GradientWrapper className="text-center">
			<Container>
				<Foot.Row>
					{/* <Col>
						<Foot.Link to="/">Home</Foot.Link>
					</Col> */}
					<Col>
						{/* <Foot.Link to="/signup">Sign Up</Foot.Link> */}
						<Foot.Link
							to={{ pathname: 'https://form.jotform.com/222493611354049' }}
							target="_blank"
						>
							Get in Touch
						</Foot.Link>
					</Col>
					<Col>
						<Foot.Link to="/terms-and-conditions">Terms of use</Foot.Link>
					</Col>
					<Col>
						<Foot.Link to="/privacy-policy">Privacy Policy</Foot.Link>
					</Col>
				</Foot.Row>
			</Container>

			<Spacer propsMtop="2rem" />

			<Container>
				<Foot.Row>
					<Col>
						<Foot.Text propsFontSize="12px">
							All Rights Reserved ©{year} Wabi Pty Ltd ACN 630 716 488
						</Foot.Text>
					</Col>
				</Foot.Row>
			</Container>

			{/* <Spacer propsMtop="2rem" />

			<Container>
				<Foot.Row propsMaxWidth="150px">
					<Col>
						<div className="d-flex align-items-center justify-content-center">
							<div>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24.064"
									height="24.064"
									viewBox="0 0 24.064 24.064"
								>
									<defs>
										<style>{`.a{fill:#fff;}`}</style>
									</defs>
									<path
										class="a"
										d="M21.485,2.25H2.578A2.578,2.578,0,0,0,0,4.828V23.735a2.578,2.578,0,0,0,2.578,2.578H9.95V18.133H6.566V14.282H9.95V11.347c0-3.338,1.987-5.182,5.031-5.182a20.5,20.5,0,0,1,2.982.26V9.7h-1.68a1.925,1.925,0,0,0-2.171,2.08v2.5h3.694l-.591,3.851h-3.1v8.181h7.372a2.578,2.578,0,0,0,2.578-2.578V4.828A2.578,2.578,0,0,0,21.485,2.25Z"
										transform="translate(0 -2.25)"
									/>
								</svg>
							</div>

							<Spacer propsMright="10px" />

							<div>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24.064"
									height="24.064"
									viewBox="0 0 24.064 24.064"
								>
									<defs>
										<style>{`.a{fill:#fff;}`}</style>
									</defs>
									<path
										class="a"
										d="M22.345,2.25H1.713A1.726,1.726,0,0,0,0,3.985V24.579a1.726,1.726,0,0,0,1.713,1.735H22.345a1.731,1.731,0,0,0,1.719-1.735V3.985A1.731,1.731,0,0,0,22.345,2.25ZM7.273,22.876H3.706V11.392H7.278V22.876ZM5.49,9.824A2.068,2.068,0,1,1,7.558,7.756,2.069,2.069,0,0,1,5.49,9.824ZM20.642,22.876H17.076V17.29c0-1.332-.027-3.046-1.853-3.046-1.858,0-2.143,1.45-2.143,2.949v5.683H9.513V11.392h3.422V12.96h.048a3.757,3.757,0,0,1,3.379-1.853c3.61,0,4.281,2.38,4.281,5.473Z"
										transform="translate(0 -2.25)"
									/>
								</svg>
							</div>
						</div>
					</Col>
				</Foot.Row>
			</Container> */}
		</Foot.GradientWrapper>
	);
};

export default Footer;
