import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid } from '@material-ui/core';
import { imgUrl } from 'utils/Utility';

import addIcon from 'assets/images/booking/user-roles/colored-plus-icon.png';

import EmployeeDetailsModal from './sub-components/TeamGrid/EmployeeDetailsModal';
import EmployeeModal from './sub-components/TeamGrid/EmployeeModal';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	container: {
		display: 'grid',
		gridTemplateColumns: 'repeat(12, 1fr)',
		padding: '10px',
	},
	paper: {
		padding: theme.spacing(1),
		textAlign: 'center',
		color: theme.palette.text.secondary,
		height: 176,
		width: '100%',
		margin: 5,
	},
}));

const StyledPaper = styled(Paper)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	// cursor: pointer;
	user-select: none;

	#e-pic-container {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 88px;
		width: 88px;
		border-radius: 50%;
		font-size: 33px;
		font-weight: 800;
		box-shadow: 0px 1px 2px #000000;
		cursor: pointer;

		img {
			aspect-ratio: auto 4/3;
			border-radius: 50%;
			z-index: 1;
			cursor: pointer;
		}
	}

	h6 {
		color: #000000;
		margin-top: 28px;
		font-size: 12px;
		font-weight: 700;
	}
`;

const StyledItem = styled.div`
	height: 30px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${(props) => props.color};
	border-radius: 5px;
	margin: 5px 5px;
	box-shadow: 0px 1px 2px #000000;
	font-size: 12px;
	font-weight: 700;
	cursor: pointer;
	user-select: none;
`;

const Wrapper = styled.div`
	height: 100%;
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;

	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
		background-color: white;
	}

	::-webkit-scrollbar-thumb {
		width: 5px;
		height: 5px;
		border-radius: 20px;
		background-color: #cccccc;
	}

	::-webkit-scrollbar-corner {
		display: none;
	}
`;

const AddButton = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 52px;
	width: 52px;
	border-radius: 50%;
	box-shadow: 0px 1px 2px #000000;
	margin: 10px 15px 15px 93%;
	cursor: pointer;
	background: white;

	&:active {
		background: #f2f2f2;
	}
`;

function TeamGrid(props) {
	const classes = useStyles();
	const [isEmployeeModalOpen, setIsEmployeeModalOpen] = useState(false);
	const [isEmployeeDetailsModalOpen, setIsEmployeeDetailsModalOpen] = useState(false);
	const [selectedEmployee, setSelectedEmployee] = useState('');
	const [isAdding, setIsAdding] = useState(false);

	const toggleEmployeeModal = () => {
		setIsEmployeeModalOpen(!isEmployeeModalOpen);
	};

	const toggleEmployeeDetailsModal = () => {
		setIsEmployeeDetailsModalOpen(!isEmployeeDetailsModalOpen);
	};

	const selectEmployeeHandler = (empl) => {
		setIsAdding(false);
		setSelectedEmployee(empl);
		props.getEmployeeReports(empl.id);
		toggleEmployeeModal();
	};

	const handleAddEmployee = () => {
		setIsAdding(true);
		toggleEmployeeDetailsModal();
	};

	useEffect(() => {
		if (isEmployeeModalOpen && isEmployeeDetailsModalOpen) {
			setIsEmployeeModalOpen(false);
		}
	}, [isEmployeeModalOpen, isEmployeeDetailsModalOpen]);

	return (
		<Wrapper>
			<AddButton onClick={handleAddEmployee}>
				<img src={addIcon} width={20} height={20} alt="add" />
			</AddButton>
			<Grid container spacing={2}>
				{props.employees.length > 0
					? props.employees.map((e, key) => {
							return (
								<Grid item xs={3} key={key}>
									<StyledPaper className={classes.paper}>
										{e.photo ? (
											<div id="e-pic-container" onClick={() => selectEmployeeHandler(e)}>
												<img src={imgUrl + e.photo} width={88} height={88} alt="profile-pic" />
											</div>
										) : (
											<div
												id="e-pic-container"
												style={{ background: `${e.color}` }}
												onClick={() => selectEmployeeHandler(e)}
											>
												{e.first_name.charAt(0).toUpperCase()}
												{e.last_name.charAt(0).toUpperCase()}
											</div>
										)}
										<h6>
											{e.first_name} {e.last_name}
										</h6>
									</StyledPaper>
									<StyledItem color={e.color} onClick={() => selectEmployeeHandler(e)}>
										{e.role.charAt(0).toUpperCase() + e.role.slice(1)}
									</StyledItem>
								</Grid>
							);
					  })
					: ''}
			</Grid>
			<EmployeeModal
				isEmployeeModalOpen={isEmployeeModalOpen}
				toggleEmployeeModal={toggleEmployeeModal}
				toggleEmployeeDetailsModal={toggleEmployeeDetailsModal}
				selectedEmployee={selectedEmployee}
				employeeReports={props.employeeReports}
				isCardUpdating={props.isCardUpdating}
				setIsAdding={setIsAdding}
			/>
			<EmployeeDetailsModal
				isEmployeeDetailsModalOpen={isEmployeeDetailsModalOpen}
				isEmployeeModalOpen={isEmployeeModalOpen}
				toggleEmployeeDetailsModal={toggleEmployeeDetailsModal}
				selectedEmployee={selectedEmployee}
				employmentContract={props.employmentContract}
				employmentRoles={props.employmentRoles}
				isAdding={isAdding}
				addNewStaff={props.addNewStaff}
				addEmploymentContract={props.addEmploymentContract}
				addEmployementRole={props.addEmployementRole}
				isUpdating={props.isUpdating}
				employeeList={props.employees}
				wageItems={props.wageItems}
				addWageItem={props.addWageItem}
				updateEmployeeDetails={props.updateEmployeeDetails}
				deleteStaff={props.deleteStaff}
				uploadStaffAvatar={props.uploadStaffAvatar}
			/>
		</Wrapper>
	);
}

export default TeamGrid;
