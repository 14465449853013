import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Col } from 'react-bootstrap';
import styled from 'styled-components';
import axios from 'axios';
import moment from 'moment';

//Utilities
import { apiUrl } from 'utils/Utility';

// Icons
import rightArrow from 'assets/images/booking/right-arrow.png';
import logowhite from 'assets/images/logo-white.png';
import ExperienceSettingModal from './SubComponents/ExperienceSettingModal';
import DotLoader from './SubComponents/DotLoader';

const Wrapper = styled.div`
	display: flex;
	overflow: hidden;
	height: 102%;
	width: 100%;
	margin: 0;
	padding: 0;
`;
const StyledContainer = styled(Container)`
	display: flex;
	overflow: hidden;
	margin: 10px 20px;
	padding: 0;
	box-shadow: 5px 5px 10px #1a2024, -5px -5px 10px #2a343a;
	border-radius: 0.8em;
	border-top: 10px solid #222a2f;
	border-right: 10px solid #222a2f;
	border-left: 10px solid #222a2f;

	::-webkit-scrollbar {
		width: 5px;
		height: 5px;
		background-color: #222a2f;
	}
	::-webkit-scrollbar-thumb {
		width: 1px;
		height: 1px;
		border-radius: 20px;
		background-color: #2a343a;
	}
`;
const ShiftContainer = styled.div`
	height: 575px;
	box-shadow: inset 5px 5px 10px #1a2024, inset -5px -5px 10px #2a343a;
	border-radius: 0.5em;
	::-webkit-scrollbar {
		display: none;
	}
	::-webkit-scrollbar-thumb {
		width: 1px;
		height: 1px;
		border-radius: 20px;
		background-color: #2a343a;
	}
	// z-index: 5;
`;
const ScrollableWrapper = styled.div`
	display: flex;
	position: relative;
	width: 100%;
	height: 575px;
	overflow: auto;
	padding: 0 0 5px 5px;
	user-select: none;

	::-webkit-scrollbar {
		width: 2px;
		height: 2px;
		background-color: #222a2f;
	}
	::-webkit-scrollbar-thumb {
		width: 2px;
		height: 2px;
		border-radius: 20px;
		background-color: #757a7d33;
	}
	::-webkit-scrollbar-corner {
		display: none;
	}
`;
const TableContainer = styled.div`
	display: grid;
	grid-template-columns: 70px auto auto auto auto auto auto auto;
	width: 100%;
	height: 105vh;
	overflow: hidden;
	user-select: none;
	grid-gap: 0;
	border-radius: 0.8em;
	background: transparent;
	padding: 0 10px 10px 10px;

	::-webkit-scrollbar {
		display: none;
	}
	::-webkit-scrollbar-thumb {
		display: none;
	}
	::-webkit-scrollbar-corner {
		display: none;
	}
`;

const GridItem = styled.div`
	display: flex;
	height: 40px;
	width: 100%;
	border-top: 1px solid #3a444b;
	border-left: 1px solid #3a444b;
	border-right: 1px solid #3a444b;
`;

const TimeItem = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-right: 10px;
	height: 40px;
	color: #707070;
	padding-top: 27px;
`;

const Column = styled.td`
	min-width: 134px;
	width: 100%;
	border-left: 1px solid #3a444b;
	border-right: 1px solid #3a444b;
	color: #222a2f;
	user-select: none;
	justify-content: center;
	background: transparent;
`;
const Rows = styled.tr`
	width: 122px;
	height: 40px;
	border-top: 1px solid #3a444b;
	border-bottom: 1px solid #3a444b;
	color: #222a2f;
	user-select: none;
	background: transparent;
`;
const TimeContainer = styled.ul`
	color: #707070;
	height: auto;
	width: 75px;
	padding: 70px 0 0 0;
	list-style-type: none;
	user-select: none;
	background: transparent;

	li {
		list-style-type: none;
		height: 40px;
		text-align: end;
		padding-right: 10px;
	}
`;
const TR = styled.tr`
	text-align: center;
	height: 40px;
	user-select: none;
	background: transparent;
	// vertical-align: bottom;
`;
const TH = styled.div`
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	background: #222a2f;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 70px;
	width: 100%;
	font-size: 12px;
	font-weight: 450;
	user-select: none;
	background: #222a2f;
	box-shadow: inset -1px 8px 1px -1px #1a2024;
	z-index: 2;
`;

const Header = styled.div`
	position: absolute;
	top: 0;
	display: grid;
	height: 70px;
	width: 100%;
	grid-template-columns: auto auto auto auto auto auto auto;
	padding: 0 10px 0 80px;
	color: #bcbcbc;
	user-select: none;
	// box-shadow: inset 8px 7px 6px -1px #1a2024;
	z-index: 2;
`;

const ShiftField = styled.div`
	display: flex;
	flex-direction: row;
	top: 77px;
	height: 100%;
	user-select: none;
	border-radius: 0.8em;
	background: transparent;

	#item-container {
		position: relative;
		display: flex;
		align-items: center;
		flex-direction: column;
	}
`;
const ExpContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	user-select: none;

	#fragment {
		display: flex;
		flex-direction: column;
		color: #bcbcbc;
		padding: 0 5px;
		width: 100%;
		height: 20px;

		h4 {
			font-size: 14px;
			font-weight: 700;
		}

		input {
			text-align: start;
			box-shadow: inset 5px 5px 10px #1a2024, inset -5px -5px 10px #2a343a;
			border-radius: 5px;
			padding: 10px;
			height: 35px;
			width: 100%;
			background: transparent;
			color: white;
			font-size: 0.8em;
		}

		textarea {
			height: 60px;
			padding: 10px;
			resize: none;
			font-size: 0.8em;
			background-color: #222a2f;
			color: rgba(247, 247, 247, 0.952);
			box-shadow: inset 5px 5px 10px #1a2024, inset -5px -5px 10px #2a343a;
			border-radius: 5px;
			border: none;
			outline: none;
			margin: 5px 0;

			::-webkit-scrollbar {
				width: 2px;
				height: 0px;
				background-color: #222a2f;
			}
			::-webkit-scrollbar-thumb {
				width: 2px;
				height: 2px;
				border-radius: 20px;
				background-color: #757a7d33;
			}
			::-webkit-scrollbar-corner {
				display: none;
			}
		}
	}

	#btm-input {
		display: flex;
		justify-content: space-around;
	}
`;
const AddExpButton = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 30px;
	margin: 5px 10px 35px 10px;
	box-shadow: 5px 5px 10px #1a2024, -6px -1px 10px #2a343a;
	cursor: pointer;
	border-radius: 0.8em;
	user-select: none;
	font-size: 12px;
	font-weight: 700;

	${(props) =>
		props.isActive
			? 'background: linear-gradient(189deg, #ff8585 0%, #ff7192 100%); color: #ffffff;'
			: 'background: #d6d4d8; color: #848284;'}

	&:hover {
		${(props) =>
			props.isActive ? 'background: linear-gradient(189deg, #ff8585 0%, #ff7192 100%)' : ''};
	}

	&:active {
		${(props) =>
			props.isActive
				? 'background: linear-gradient(189deg, #ff8585 0%, #ff7192 100%); color: #ffffff; outline: none; outline-offset: none;'
				: ''}
	}
`;

const LineSeparator = styled.div`
	width: 249px;
	height: 3px;
	border-radius: 25px;
	background: #222a2f;
	box-shadow: inset 1px 1px 2px #1a2024, inset -1px -1px 2px #2a343a;
`;
const ExpListContainer = styled.div`
	height: 300px;
	margin-left: 5px;
	overflow-y: auto;
	::-webkit-scrollbar {
		width: 2px;
		height: 0px;
		background-color: #222a2f;
	}
	::-webkit-scrollbar-thumb {
		width: 2px;
		height: 2px;
		border-radius: 20px;
		background-color: #757a7d33;
	}
	::-webkit-scrollbar-corner {
		display: none;
	}
`;
const ExpItem = styled.div`
	position: relative;
	height: 120px;
	margin: 10px 5px 20px 5px;
	box-shadow: 3px 3px 5px #1a2024, -3px -3px 5px #2a343a;
	border-radius: 0.3em;
	color: #bcbcbc;
	cursor: pointer;

	p {
		padding: 10px;
		font-size: 12px;
	}

	#btm-div {
		position: absolute;
		padding: 0 5px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		bottom: 5px;

		h4 {
			font-size: 12px;
			padding: 5px;
			font-weight: 700;
		}
	}
`;
const ShiftItemContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 50px;
	width: 80%;
	margin: 0 5px;
	border-radius: 0.5em;
	background: ${(props) => props.color};
	border: 5px solid #222a2f;
	box-shadow: 3px 3px 2px #1a2024, -2px -2px 1px #2a343a;
	overflow: auto;
`;

function ShiftItem(props) {
	return (
		<ShiftItemContainer
			color={props.color}
			style={{
				position: 'absolute',
				textAlign: 'center',
				top: `${props.top}px`,
				height: `${props.height}px`,
			}}
		>
			<div>{props.name}</div>
		</ShiftItemContainer>
	);
}

function TableExperience(props) {
	const reducers = useSelector((state) => state.userReducers);
	const [shifts, setShifts] = useState(props.shifts);
	const [expList, setExpList] = useState(props.experience);
	const [openExp, setOpenExp] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedExp, setSelectedExp] = useState('');
	const [expData, setExpData] = useState('');
	const [expName, setExpName] = useState('');
	const [expDescription, setExpDescription] = useState('');
	const [expPrice, setexpPrice] = useState('');
	const [ticketNo, setTicketNo] = useState('');
	const [gridItemRect, setgridItemRect] = useState([]);
	const ref = useRef();
	// const hours = 20;
	// const formatedHours = [];
	const tableRows = [];

	// for (let i = -2; i <= hours; i++) {
	// 	formatedHours.push(moment(i * 3600 * 1000).format('HH:mm'));
	// }
	let time = -2;
	for (let i = 0; i <= 183; i++) {
		if (i === 0) {
			tableRows.push(
				<TimeItem id={i} key={Math.random()}>
					{moment(time * 3600 * 1000).format('HH:mm')}
				</TimeItem>,
			);
			time = time++;
		} else if (i % 8 != 0) {
			if (i > 176 && 183 >= i) {
				tableRows.push(
					<GridItem
						id={'exp-grid-item' + i}
						key={Math.random()}
						style={{ borderBottom: '1px solid #3a444b' }}
					></GridItem>,
				);
			} else {
				tableRows.push(<GridItem id={'exp-grid-item' + i} key={Math.random()}></GridItem>);
			}
		} else {
			time = time + 1;
			tableRows.push(
				<TimeItem id={i} key={Math.random()}>
					{moment(time * 3600 * 1000).format('HH:mm')}
				</TimeItem>,
			);
		}
	}
	useEffect(() => {
		function updateSize() {
			const item = document.getElementById('exp-grid-item1');
			// console.log(item.getBoundingClientRect());
			setgridItemRect(item.getBoundingClientRect());
		}
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, []);

	const getTopPosition = (seconds) => {
		let int = 10.5;
		let count = -1;

		for (let time = 18000; time <= seconds; time += 900) {
			++count;
			if (time === seconds) {
				// let newCount = count * 2;
				let pixel = int * count;
				return pixel;
			}
		}
	};

	const getItemHeight = (top, seconds) => {
		let int = 10.5;
		let count = -1;
		for (let time = 18000; time <= seconds; time += 900) {
			++count;
			if (time === seconds) {
				// let newCount = count * 2;
				let pixel = int * count;
				pixel = pixel - top;
				return pixel;
			}
		}
	};

	const openExpHandler = (exp) => {
		setSelectedExp(exp);
		setIsLoading(true);
		expGetAssign(exp.id);
	};

	const priceOnChange = (e) => {
		let price;
		if (e.target.validity.valid) {
			price = parseInt(e.target.value) || '';
		} else {
			return;
		}
		setexpPrice(price);
	};

	const ticketOnChange = (e) => {
		let ticket;
		if (e.target.validity.valid) {
			ticket = parseInt(e.target.value) || '';
		} else {
			return;
		}
		setTicketNo(ticket);
	};

	const clearInputs = () => {
		setExpName('');
		setExpDescription('');
		setexpPrice('');
		setTicketNo('');
	};

	const unmountSelectedExp = () => {
		setSelectedExp('');
		setExpData('');
	};

	// FETCH
	const getExpList = async () => {
		try {
			const response = await axios({
				url: `${apiUrl}/api/experience/list`,
				method: 'POST',
				data: {},
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${reducers.token}`,
				},
			});

			if (response) {
				const { status } = response;
				const { data } = response.data;
				const experiences = data.experiences;
				if (status === 200) {
					setExpList(experiences);
					setIsLoading(false);
					// if (name !== undefined) {
					// 	let newExp = experiences.find((e) => e.exp_name === name);
					// 	let assignParams = {
					// 		flat_rate: 0,
					// 		recurring_value: 0,
					// 		experience_id: newExp.id,
					// 		id: 0,
					// 		booking_fee: 0,
					// 		recurring_type: -1,
					// 		shift_id: shifts[0].id,
					// 		start_date: moment(new Date()).format('yyyy-MM-DD'),
					// 		day_of_week: [],
					// 	};
					// 	expAssign(assignParams);
					// } else {
					// 	setIsLoading(false);
					// }
				}
			}
		} catch (err) {
			console.log('Error: ', err);
		}
	};

	const expGetAssign = async (id) => {
		try {
			const response = await axios({
				url: `${apiUrl}/api/experience/get_assign`,
				method: 'POST',
				data: { experience_id: id },
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${reducers.token}`,
				},
			});

			if (response) {
				const { status } = response;
				const { data } = response.data;
				const connection = data.connection;
				if (status === 200) {
					setExpData(connection);
					setIsLoading(false);
					setOpenExp(true);
				}
			}
		} catch (err) {
			console.log('Error: ', err);
		}
	};

	// CREATE | UPDATE
	const createNewExperience = async () => {
		if ([expName.length, expDescription.length, expPrice.length, ticketNo.length].includes(0)) {
			console.log('return');
			return;
		} else {
			let params = {
				exp_name: expName,
				exp_description: expDescription,
				price: expPrice,
				no_of_ticket: ticketNo,
			};

			setIsLoading(true);

			try {
				const response = await axios({
					url: `${apiUrl}/api/experience`,
					method: 'POST',
					data: params,
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${reducers.token}`,
					},
				});

				if (response) {
					const { status } = response;
					const { data } = response.data;
					if (status === 200) {
						console.log(data);
						getExpList(expName);
						clearInputs();
					}
				}
			} catch (err) {
				console.log('Error: ', err);
			}
		}
	};

	const expAssign = async (params) => {
		setIsLoading(true);
		try {
			const response = await axios({
				url: `${apiUrl}/api/experience/assign`,
				method: 'POST',
				data: params,
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${reducers.token}`,
				},
			});

			if (response) {
				const { status } = response;
				const { data } = response.data;
				if (status === 200) {
					console.log(data);
					getExpList();
				}
			}
		} catch (err) {
			console.log('Error: ', err);
		}
	};

	// DELETE
	const expDelete = async (params) => {
		setIsLoading(true);
		try {
			const response = await axios({
				url: `${apiUrl}/api/experience/delete`,
				method: 'POST',
				data: params,
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${reducers.token}`,
				},
			});

			if (response) {
				const { status } = response;
				const { data } = response.data;
				if (status === 200) {
					console.log(data);
					getExpList();
				}
			}
		} catch (err) {
			console.log('Error: ', err);
		}
	};

	return (
		<Wrapper>
			<StyledContainer fluid>
				<Col sm={9} md={9} lg={9} style={{ margin: 0, padding: 0 }}>
					<ShiftContainer>
						<div
							style={{
								display: 'flex',
								width: 105,
								height: 70,
								position: 'absolute',
								background: '#222a2f',
								boxShadow: 'inset 8px 7px 5px -1px #1a2024',
								borderTopLeftRadius: '10px',
								zIndex: 3,
							}}
						></div>
						<div
							style={{
								position: '-webkit-sticky',
								position: 'sticky',
								top: 0,
								width: '100%',
								zIndex: 3,
							}}
						>
							<Header>
								<TH>MON</TH>
								<TH>TUE</TH>
								<TH>WED</TH>
								<TH>THU</TH>
								<TH>FRI</TH>
								<TH>SAT</TH>
								<TH>SUN</TH>
							</Header>
						</div>
						<ScrollableWrapper>
							{/* <TimeContainer>
								{formatedHours.length > 0
									? formatedHours.map((t, key) => {
											return <li key={key}>{t}</li>;
									  })
									: ''}
							</TimeContainer> */}
							<TableContainer ref={ref}>
								<TH></TH>
								<TH></TH>
								<TH></TH>
								<TH></TH>
								<TH></TH>
								<TH></TH>
								<TH></TH>
								<TH></TH>
								{tableRows}
							</TableContainer>
							<ShiftField id="field" style={{ position: 'absolute' }}>
								<div
									id="item-container"
									style={{
										height: '97vh',
										width: gridItemRect.width,
										position: 'relative',
										padding: '0 20px',
										marginLeft: 80,
									}}
								>
									{shifts.length > 0
										? shifts.map((shift) => {
												if (shift.day_of_week !== undefined) {
													if (shift.day_of_week.includes(1)) {
														return (
															<ShiftItem
																key={shift.id}
																color={shift.shift_color}
																name={shift.name}
																top={getTopPosition(shift.start_time)}
																height={getItemHeight(
																	getTopPosition(shift.start_time),
																	shift.end_time,
																)}
															/>
														);
													}
												}
										  })
										: ''}
								</div>
								<div
									id="item-container"
									style={{
										height: '97vh',
										width: gridItemRect.width,
										position: 'relative',
										padding: '0 20px',
									}}
								>
									{shifts.length > 0
										? shifts.map((shift) => {
												if (shift.day_of_week !== undefined) {
													if (shift.day_of_week.includes(2)) {
														return (
															<ShiftItem
																key={shift.id}
																color={shift.shift_color}
																name={shift.name}
																top={getTopPosition(shift.start_time)}
																height={getItemHeight(
																	getTopPosition(shift.start_time),
																	shift.end_time,
																)}
															/>
														);
													}
												}
										  })
										: ''}
								</div>
								<div
									id="item-container"
									style={{
										height: '97vh',
										width: gridItemRect.width,
										position: 'relative',
										padding: '0 20px',
									}}
								>
									{shifts.length > 0
										? shifts.map((shift) => {
												if (shift.day_of_week !== undefined) {
													if (shift.day_of_week.includes(3)) {
														return (
															<ShiftItem
																key={shift.id}
																color={shift.shift_color}
																name={shift.name}
																top={getTopPosition(shift.start_time)}
																height={getItemHeight(
																	getTopPosition(shift.start_time),
																	shift.end_time,
																)}
															/>
														);
													}
												}
										  })
										: ''}
								</div>
								<div
									id="item-container"
									style={{
										height: '97vh',
										width: gridItemRect.width,
										position: 'relative',
										padding: '0 20px',
									}}
								>
									{shifts.length > 0
										? shifts.map((shift) => {
												if (shift.day_of_week !== undefined) {
													if (shift.day_of_week.includes(4)) {
														return (
															<ShiftItem
																key={shift.id}
																color={shift.shift_color}
																name={shift.name}
																top={getTopPosition(shift.start_time)}
																height={getItemHeight(
																	getTopPosition(shift.start_time),
																	shift.end_time,
																)}
															/>
														);
													}
												}
										  })
										: ''}
								</div>
								<div
									id="item-container"
									style={{
										height: '97vh',
										width: gridItemRect.width,
										position: 'relative',
										padding: '0 20px',
									}}
								>
									{shifts.length > 0
										? shifts.map((shift) => {
												if (shift.day_of_week !== undefined) {
													if (shift.day_of_week.includes(5)) {
														return (
															<ShiftItem
																key={shift.id}
																color={shift.shift_color}
																name={shift.name}
																top={getTopPosition(shift.start_time)}
																height={getItemHeight(
																	getTopPosition(shift.start_time),
																	shift.end_time,
																)}
															/>
														);
													}
												}
										  })
										: ''}
								</div>
								<div
									id="item-container"
									style={{
										height: '97vh',
										width: gridItemRect.width,
										position: 'relative',
										padding: '0 20px',
									}}
								>
									{shifts.length > 0
										? shifts.map((shift) => {
												if (shift.day_of_week !== undefined) {
													if (shift.day_of_week.includes(6)) {
														return (
															<ShiftItem
																key={shift.id}
																color={shift.shift_color}
																name={shift.name}
																top={getTopPosition(shift.start_time)}
																height={getItemHeight(
																	getTopPosition(shift.start_time),
																	shift.end_time,
																)}
															/>
														);
													}
												}
										  })
										: ''}
								</div>
								<div
									id="item-container"
									style={{
										height: '97vh',
										width: gridItemRect.width,
										position: 'relative',
										padding: '0 20px',
									}}
								>
									{shifts.length > 0
										? shifts.map((shift) => {
												if (shift.day_of_week !== undefined) {
													if (shift.day_of_week.includes(7)) {
														return (
															<ShiftItem
																key={shift.id}
																color={shift.shift_color}
																name={shift.name}
																top={getTopPosition(shift.start_time)}
																height={getItemHeight(
																	getTopPosition(shift.start_time),
																	shift.end_time,
																)}
															/>
														);
													}
												}
										  })
										: ''}
								</div>
							</ShiftField>
						</ScrollableWrapper>
					</ShiftContainer>
				</Col>

				<ExperienceSettingModal
					openExp={openExp}
					setOpenExp={setOpenExp}
					selectedExp={selectedExp}
					expData={expData}
					shifts={shifts}
					expDelete={expDelete}
					expAssign={expAssign}
					unmountSelectedExp={unmountSelectedExp}
				/>
				{isLoading ? (
					<div style={{ position: 'absolute', top: '41%', right: '51%' }}>
						<DotLoader />
					</div>
				) : (
					''
				)}
				<Col sm={3} md={3} lg={3} style={{ margin: 0, padding: 0 }}>
					<ExpContainer>
						<div id="fragment" style={{ marginBottom: 15 }}>
							<h4 style={{ marginBottom: 5 }}>Add Experience</h4>
							<span>
								<LineSeparator />
							</span>

							<div style={{ position: 'absolute', right: '10px' }}>
								<img src={rightArrow} width={9.2} height={16} />
							</div>
						</div>
						<div id="fragment" style={{ flexDirection: 'column', height: 150, marginBottom: 5 }}>
							<input
								value={expName}
								onChange={(e) => setExpName(e.target.value)}
								placeholder="Enter Experience Name"
							/>
							<textarea
								value={expDescription}
								onChange={(e) => setExpDescription(e.target.value)}
							/>
							<span id="btm-input">
								<input
									type="text"
									pattern="[0-9]{0,10}"
									value={expPrice}
									onChange={(e) => priceOnChange(e)}
									style={{ width: 100, marginRight: 8.5 }}
									placeholder="Price"
								/>
								<input
									type="text"
									pattern="[0-9]{0,10}"
									value={ticketNo}
									onChange={(e) => ticketOnChange(e)}
									style={{ width: 100, marginLeft: 8.5 }}
									placeholder="No. of tickets"
								/>
							</span>
						</div>
						<AddExpButton
							onClick={createNewExperience}
							isActive={
								[expName.length, expDescription.length, expPrice.length, ticketNo.length].includes(
									0,
								)
									? false
									: true
							}
						>
							Add Experience
						</AddExpButton>
						<div id="fragment">
							<h4>Select Experience:</h4>
						</div>
						<ExpListContainer>
							{expList.length > 0
								? expList.map((exp) => {
										return (
											<ExpItem key={exp.id} onClick={() => openExpHandler(exp)}>
												<div id="fragment">
													<h4
														style={{
															padding: 5,
															fontSize: 12,
															overflow: 'hidden',
															textOverflow: 'ellipsis',
															whiteSpace: 'nowrap',
															width: 160,
														}}
													>
														{exp.exp_name}
													</h4>
													<span style={{ position: 'absolute', right: 10, top: 10 }}>
														<img src={logowhite} width={40} height={10} />
													</span>
												</div>
												<span
													style={{
														height: 5,
														width: '100%',
														position: 'absolute',
														borderBottom: '1px solid #3a444b',
														marginTop: 5,
													}}
												/>
												<p>{exp.exp_description}</p>
												<div id="btm-div">
													<h4>${exp.price}.0</h4>
													<h4>{exp.no_of_ticket} tickets</h4>
												</div>
											</ExpItem>
										);
								  })
								: ''}
						</ExpListContainer>
					</ExpContainer>
				</Col>
			</StyledContainer>
		</Wrapper>
	);
}

export default TableExperience;
