import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Media } from 'react-bootstrap';
import { List } from 'react-virtualized';
import { DebounceInput } from 'react-debounce-input';
import { apiUrl, sortBookingBySection } from 'utils/Utility';
import styled from 'styled-components';
import axios from 'axios';

//Components
import withDashboardApp from 'components/withDashboardApp';
import BookingNavBar from '../BookingNavBar/BookingNavBar';
import SmsTextModal from './SmsTextModal';
import AddNewGuestModal from './AddNewGuestModal';
import EditNameModal from '../CreatBooking/reservation-details/EditNameModal';

//Icons
import searchIcon from 'assets/images/booking/search-icon.png';
import newGuestIcon from 'assets/images/booking/new-guest-icon.png';
import widgetIcon from 'assets/images/booking/widget-icon.png';
import guestBookIcon from 'assets/images/booking/guest-book-icon.png';
import personIcon from 'assets/images/booking/create-booking/person-icon.png';
import GuestAnalytics from './GuestAnalyticsTab';
import MakeBooking from './MakeBooking';
import GuestBookProfileTab from './GuestBookProfileTab';
import GuestBookNotesTab from './GuestBookNotesTab';
import { createGuestParameters } from 'utils/RequestBuilder';
import DotLoader from '../TableLayoutPlanning/pages/SubComponents/DotLoader';
import NumberFormat from 'react-number-format';

const Wrapper = styled.div`
	display: inline-flex;
	height: 100%;
	width: 100%;
	overflow: hidden;

	// img {
	// 	user-select: none;
	// 	-webkit-user-drag: none;
	// 	-khtml-user-drag: none;
	// 	-moz-user-drag: none;
	// 	-o-user-drag: none;
	// 	user-drag: none;
	// }
`;

const BgSearchBar = styled.div`
	position: relative;
	display: inline-flex;
	text-align: right;
	width: 314px;
	height: 50px;
	border-radius: 10px;
	background: #222a2f;
	box-shadow: inset 5px 5px 10px #1a2024, inset -5px -5px 10px #2a343a;

	#guest-book-search-icon {
		position: absolute;
		display: flex;
		width: 22px;
		height: 22px;
		top: 15px;
		right: 5px;
		img {
			user-select: none;
			-webkit-user-drag: none;
			-khtml-user-drag: none;
			-moz-user-drag: none;
			-o-user-drag: none;
			user-drag: none;
		}
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		transition: background-color 5000s ease-in-out 0s;
		-webkit-text-fill-color: white;
	}
`;

const BtnMenu = styled.div`
	width: 50px;
	height: 50px;
	max-width: 50vh;
	max-height: 50vh;
	border-radius: 103px;
	background: #222a2f;
	cursor: pointer;
	box-shadow: 4px 4px 8px #1a2024, -4px -4px 8px #2a343a;
	padding: 5px;
	margin-bottom: 20px;

	&:active {
		background: #1c2327;
	}

	&--big-size {
		width: 50px;
		height: 50px;
	}

	&--margin-top {
		margin-top: 5px;
	}

	&--margin-left {
		margin-left: 106px;
	}
`;

const NewGuestBtn = styled.div`
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 152px;
	height: 40px;
	top: 37px;
	right: 5px;
	border: 2px solid #bcbcbc;
	border-radius: 1.5em;
	color: #bcbcbc;
	cursor: pointer;
	font-size: 14px;
	font-weight: 700;
	user-select: none;

	&:hover {
		color: white;

		img {
			filter: brightness(3.5);
		}
	}

	img {
		user-select: none;
		-webkit-user-drag: none;
		-khtml-user-drag: none;
		-moz-user-drag: none;
		-o-user-drag: none;
		user-drag: none;
		margin-right: 5px;
	}
`;

const ListWrapper = styled.div`
	display: inline-flex;
	flex-direction: column;
	width: 314px;
	height: 662px;
	overflow: auto;
	margin-top: 10px;

	.ReactVirtualized__Grid.ReactVirtualized__List {
		::-webkit-scrollbar {
			width: 15px;
			height: 5px;
			background-color: #222a2f;
		}
		::-webkit-scrollbar-thumb {
			width: 10px;
			height: 20px;

			border-radius: 20px;
			background-color: #2a343a;
		}

		::-webkit-scrollbar-corner {
			display: none;
		}
	}

	#list-item {
		position: relative;
		display: inline-flex;
		flex-direction: row;
		width: 100%;
		min-height: 70px;
		background: #3a444b;
		border-radius: 5px;
		margin-bottom: 5px;
		cursor: pointer;
		user-select: none;

		#selected-indicator {
			display: block;
			height: 70px;
			width: 0;
			background: #fe7d86;
			opacity: 0;
			transition: all ease-in-out 200ms;

			&--selected {
				opacity: 1;
				width: 34px;
				background: #fe7d86;
				transition: all ease-in-out 200ms;
			}
		}

		#text-container {
			padding: 0 10px;

			h4 {
				font-size: 18px;
				font-weight: 700;
				color: #ffffff;
				margin: 10px 0;
			}

			p {
				font-size: 12px;
				color: #bcbcbc;
			}
		}

		#icon-container {
			position: absolute;
			display: inline-flex;
			height: 70px;
			right: 5px;
			flex-direction: column;
			justify-content: space-around;
		}
	}
`;

const LineSeparator = styled.div`
	position: absolute;
	width: 98%;
	height: 6px;
	border-radius: 25px;
	background: #222a2f;
	box-shadow: inset 1px 1px 2px #1a2024, inset -1px -1px 2px #2a343a;
	margin-top: 87px;
`;

const GuestDetailsWrapper = styled.div`
	display: inline-flex;
	height: 655px;
	width: 100%;
	margin-top: 92px;
	justify-content: center;
	user-select: none;

	p {
		margin-top: 20px;
		color: #cccccc;
		font-size: 14px;
	}
`;

const GuestDetailContainer = styled.div`
	position: relative;
	display: inline-flex;
	flex-direction: column;
	height: auto;
	width: 100%;
	overflow-y: auto;
	white-space: nowrap;

	::-webkit-scrollbar {
		width: 5px;
		height: 5px;
		background-color: #222a2f;
	}
	::-webkit-scrollbar-thumb {
		width: 1px;
		height: 1px;

		border-radius: 20px;
		background-color: #2a343a;
	}

	::-webkit-scrollbar-corner {
		display: none;
	}

	#guest-header {
		position: sticky;
		top: 5px;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		background: #222a2f;
		height: 65px;
		width: 98%;
		padding: 10px 0;
		z-index: 5;

		h4 {
			font-size: 26px;
			font-weight: 700;
			color: white;
			cursor: pointer;
		}

		#guest-btn-wrapper {
			display: flex;
			justify-content: space-evenly;
			width: 326px;

			#guest-btn-container {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 50px;
				width: 50px;
				border-radius: 50%;
				background: #222a2f;
				box-shadow: 4px 4px 8px #1a2024, -4px -4px 8px #2a343a;
				&:active {
					background: #1c2327;
				}

				#svg-btn-icon {
					fill: #bcbcbc;
					&:active {
						fill: #fe7d86;
					}

					&--selected {
						fill: #fe7d86;
					}
				}
			}
		}
	}

	#guest-notes-content {
		display: flex;
		flex-direction: column;
		margin-top: 10px;
		margin-right: 10px;
		opacity: ${(props) => (props.isNotesActive ? '' : '0')};
		transition: ease-in-out 100ms;

		h4 {
			margin: 15px 0;
			font-size: 14px;
			font-weight: 700;
			color: #bcbcbc;
		}

		span {
			display: flex;
			justify-content: space-between;

			label {
				margin: 10px 0;
				font-size: 16px;
				font-weight: 700;
				color: #707070;
			}

			input {
				text-align: end;
				background: transparent;
				width: 80%;
				color: white;
				padding: 10px;
				font-size: 1em;
				transition: 500ms
				cursor: text;
			}

			input:focus {
				width: 100%;
				text-align: start;
				box-shadow: inset 5px 5px 10px #1a2024, inset -5px -5px 10px #2a343a;
				border-radius: 5px;
				padding: 10px;
				transition: 500ms
			}
		}
	}
`;

const StyledCol = styled(Col)`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 30px;
	margin-bottom: 30px;
	padding: 0;
`;

const StyledDebounce = styled(DebounceInput)`
	height: 40px;
	width: 100%;
	margin: 10px 0;
	background: transparent;
	// border: 1px solid #999999;
	border-radius: 0.8em;
	color: #ffffff;
	padding: 10px 20px;
`;

function GuestBook() {
	const reducers = useSelector((state) => state.userReducers);
	const history = useHistory();
	const [guestList, setGuestList] = useState([]);
	const [filteredGuestHistory, setFilteredGuestHistory] = useState([]);
	const [guestHistory, setGuestHistory] = useState([]);
	const [filteredGuestList, setFilteredGuestList] = useState([]);
	const [templates, setTemplates] = useState('');
	const [keyword, setKeyword] = useState('');
	const [createBookingOpen, setCreateBookingOpen] = useState(false);
	const [isInit, setIsInit] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [profileTabIsLoading, setProfileTabIsLoading] = useState(false);
	const [sending, setSending] = useState(false);
	const [showNavbar, setShowNavbar] = useState(false);
	const [isGuestSelected, setIsGuestSelected] = useState(false);
	const [smsTextOpen, setSmsTextOpen] = useState(false);
	const [newGuestOpen, setNewGuestOpen] = useState(false);
	const [openEditName, setOpenEditName] = useState(false);
	const [selectedGuest, setSelectedGuest] = useState('');
	const [selectedTab, setSelectedTab] = useState(0); // 0= none , 1 = profile, 2 = notes, 3 = analytics
	const [alertHandler, setAlertHandler] = useState(false);
	const [dialogAlertMessage, setDialogAlertMessage] = useState('');

	const toggleBookingNavBar = () => setShowNavbar(!showNavbar);

	const searchBarOnChange = (value) => {
		let _keyword = value;
		setKeyword(_keyword);
		if (_keyword.length > 0) {
			setFilteredGuestList(handleFilter(guestList, _keyword));
		} else {
			setFilteredGuestList(guestList);
		}
	};

	const handleFilter = (list, key) => {
		let filteredList = list.filter((guest) => {
			let fullName = guest.first_name + ' ' + guest.last_name;
			return fullName.toLowerCase().includes(key.toLowerCase());
		});
		return filteredList;
	};

	const handleSelectGuest = (guest) => {
		if (!selectedGuest) {
			setSelectedGuest(guest);
			setIsGuestSelected(true);
			getGuestHistory(guest.id);
		} else {
			if (guest.id === selectedGuest.id) {
				setSelectedGuest('');
				setIsGuestSelected(false);
			} else {
				setSelectedGuest(guest);
				getGuestHistory(guest.id);
			}
		}
	};

	const handleChangeTab = (int) => {
		setSelectedTab(int);
	};

	const toggleSmsText = () => {
		setSmsTextOpen(true);
		getTemplates();
	};

	const toggleAddNewGuest = () => {
		setNewGuestOpen(true);
	};

	const toggleEditName = () => {
		setOpenEditName(!openEditName);
	};

	const handleCloseEditName = (guest) => {
		toggleEditName();
		guestUpdate(createGuestParameters(guest));
	};

	const makeNewBookingHandler = () => {
		setCreateBookingOpen(!createBookingOpen);
	};

	const getGuestList = async () => {
		try {
			const response = await axios({
				url: `${apiUrl}/api/guests`,
				method: 'POST',
				data: {},
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${reducers.token}`,
				},
			});

			if (response) {
				const { status } = response;
				const { data } = response.data;
				const guests = data.guests;
				if (status === 200) {
					setGuestList(guests);
					setFilteredGuestList(guests);
					setIsLoading(false);
					setSelectedTab(1);
					if (keyword.length > 0) {
						setFilteredGuestList(handleFilter(guests, keyword));
					}
				}
			}
		} catch (err) {
			console.log('Error: ', err);
		}
	};

	const getGuestHistory = async (id) => {
		try {
			const response = await axios({
				url: `${apiUrl}/api/guest/history`,
				method: 'POST',
				data: { id: id },
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${reducers.token}`,
				},
			});

			if (response) {
				const { status } = response;
				const { data } = response.data;
				const bookings = data.bookings;
				if (status === 200) {
					// console.log(bookings);
					setFilteredGuestHistory(sortBookingBySection(bookings), bookings);
					setGuestHistory(bookings);
				}
			}
		} catch (err) {
			console.log('Error: ', err);
		}
	};

	const guestUpdate = async (params) => {
		try {
			const response = await axios({
				url: `${apiUrl}/api/guest/update`,
				method: 'POST',
				data: params,
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${reducers.token}`,
				},
			});

			if (response) {
				const { status } = response;
				const { data } = response.data;
				if (status === 200) {
					console.log(data);
					getGuestList(true);
					setProfileTabIsLoading(false);
				}
			}
		} catch (err) {
			console.log('Error: ', err);
		}
	};

	const getTemplates = async () => {
		try {
			const response = await axios({
				url: `${apiUrl}/api/sms/templates`,
				method: 'POST',
				data: {},
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${reducers.token}`,
				},
			});

			if (response) {
				const { status } = response;
				const { data } = response.data;
				const smsTemplates = data.sms_templates;
				if (status === 200) {
					// console.log(smsTemplates);
					setTemplates(smsTemplates);
				}
			}
		} catch (err) {
			console.log('Error: ', err);
		}
	};

	const sendMessage = async (params) => {
		try {
			const response = await axios({
				url: `${apiUrl}/api/sms`,
				method: 'POST',
				data: params,
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${reducers.token}`,
				},
			});

			if (response) {
				const { status } = response;
				const { data } = response.data;
				if (status === 200) {
					console.log(data);
					setSending(false);
					setDialogAlertMessage({
						title: 'Success!',
						body: 'Message sent!',
					});
					setAlertHandler(true);
				}
			}
		} catch (err) {
			setDialogAlertMessage({
				title: 'Invalid phone number',
				body: 'Make sure country code is added in your phone number',
			});
			setAlertHandler(true);
			setSending(false);
			console.log('Error: ', err);
		}
	};

	const addGuest = async (params) => {
		try {
			const response = await axios({
				url: `${apiUrl}/api/guest/store`,
				method: 'POST',
				data: params,
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${reducers.token}`,
				},
			});

			if (response) {
				const { status } = response;
				const { data } = response.data;
				if (status === 200) {
					console.log(data);
					getGuestList();
				}
			}
		} catch (err) {
			console.log('Error: ', err);
		}
	};

	useEffect(() => {
		if (isInit) {
			getGuestList();
			setIsLoading(true);
			setIsInit(false);
		}
	}, []);

	return (
		<Wrapper>
			<BookingNavBar
				showNavbar={showNavbar}
				showNavbarHandler={setShowNavbar}
				data={history.location.state}
			/>
			<Container fluid style={{ width: '96%', height: '100%' }}>
				<Row>
					<Col
						className="menu-btn-container"
						style={{ marginTop: 30, marginBottom: 30, paddingRight: 0, flexDirection: 'column' }}
						sm={1}
						md={1}
						lg={1}
					>
						<BtnMenu onClick={toggleBookingNavBar}>
							<Media className="list-db" as="li">
								<svg
									style={{ marginTop: 12, marginLeft: 11, marginRight: 11 }}
									xmlns="http://www.w3.org/2000/svg"
									width="18"
									height="15"
									viewBox="0 0 18 15"
								>
									<g id="Group_347" data-name="Group 347" transform="translate(-0.333)">
										<rect
											id="Rectangle_42"
											data-name="Rectangle 42"
											width="18"
											height="3"
											rx="1.5"
											transform="translate(0.333)"
											fill="#fff"
										/>
										<rect
											id="Rectangle_43"
											data-name="Rectangle 43"
											width="18"
											height="3"
											rx="1.5"
											transform="translate(0.333 6)"
											fill="#fff"
										/>
										<rect
											id="Rectangle_44"
											data-name="Rectangle 44"
											width="18"
											height="3"
											rx="1.5"
											transform="translate(0.333 12)"
											fill="#fff"
										/>
									</g>
								</svg>
							</Media>
						</BtnMenu>
						{/* <div className="btn-toggle" style={{ marginBottom: 10 }}>
							<div className="bg-icon-container">
								<Media className="list-db" as="li">
									<svg
										style={{ marginTop: 9, marginLeft: 9 }}
										xmlns="http://www.w3.org/2000/svg"
										width="22"
										height="22"
										viewBox="0 0 22 22"
									>
										<defs>
											<clipPath id="clip-path">
												<rect
													id="Rectangle_1613"
													data-name="Rectangle 1613"
													width="22"
													height="22"
													transform="translate(14 14)"
													stroke="#fff"
													strokeWidth={1}
												/>
											</clipPath>
										</defs>
										<g
											id="Mask_Group_267"
											data-name="Mask Group 267"
											transform="translate(-14 -14)"
											clipPath="url(#clip-path)"
										>
											<g id="Table" transform="translate(13.988 13.988)">
												<path
													id="Path_4771"
													data-name="Path 4771"
													d="M21.1,6.449A3.524,3.524,0,0,0,20.993,1.6l-.559-.573A3.524,3.524,0,0,0,15.588.925L13.434,2.89a.379.379,0,1,0,.511.555l.59-.546,4.59,4.59-.546.6a.379.379,0,0,0,0,.524h0a.379.379,0,0,0,.533-.026ZM15.1,2.387l.982-.881a2.44,2.44,0,0,1,.383-.291L20.83,5.576a2.44,2.44,0,0,1-.291.383l-.9.982Z"
												/>
												<path
													id="Path_4772"
													data-name="Path 4772"
													d="M15.575,21.1a3.524,3.524,0,0,0,4.845-.106l.559-.559a3.524,3.524,0,0,0,.106-4.845l-1.951-2.154a.379.379,0,1,0-.555.511l.546.59-4.59,4.59-.6-.546a.379.379,0,0,0-.524,0h0a.379.379,0,0,0,.026.533ZM19.636,15.1l.9.982a2.44,2.44,0,0,1,.291.383L16.469,20.83a2.44,2.44,0,0,1-.383-.291l-.982-.9Z"
												/>
												<path
													id="Path_4773"
													data-name="Path 4773"
													d="M.925,15.575a3.524,3.524,0,0,0,.106,4.845l.559.559a3.524,3.524,0,0,0,4.845.106l2.141-1.965a.379.379,0,1,0-.511-.555l-.577.559L2.9,14.536l.546-.6a.379.379,0,0,0,0-.524h0a.379.379,0,0,0-.533.026ZM6.92,19.636l-.982.9a2.44,2.44,0,0,1-.383.291L1.194,16.469a2.44,2.44,0,0,1,.291-.383l.9-.982Z"
												/>
												<path
													id="Path_4774"
													data-name="Path 4774"
													d="M6.449.925A3.524,3.524,0,0,0,1.6,1.031l-.573.559A3.524,3.524,0,0,0,.925,6.435L2.89,8.589a.379.379,0,1,0,.555-.511L2.9,7.488,7.488,2.9l.6.546a.379.379,0,0,0,.524,0h0a.379.379,0,0,0-.026-.533ZM2.387,6.92l-.881-.982a2.44,2.44,0,0,1-.291-.383L5.576,1.194a2.44,2.44,0,0,1,.383.291l.982.9Z"
												/>
												<path
													id="Path_4775"
													data-name="Path 4775"
													d="M11.012,17.654a1.987,1.987,0,0,1-1.418-.586L4.955,12.43a2.009,2.009,0,0,1,0-2.837L9.594,4.955a2.009,2.009,0,0,1,2.837,0l4.638,4.638a2.009,2.009,0,0,1,0,2.837L12.43,17.068A1.987,1.987,0,0,1,11.012,17.654Zm0-12.4a1.132,1.132,0,0,0-.8.326L5.576,10.215a1.136,1.136,0,0,0,0,1.595l4.638,4.638a1.163,1.163,0,0,0,1.595,0l4.638-4.638a1.136,1.136,0,0,0,0-1.595L11.809,5.576a1.132,1.132,0,0,0-.8-.326Z"
												/>
											</g>
										</g>
									</svg>
								</Media>
							</div>
						</div>

						<div className="btn-toggle" style={{ marginBottom: 10 }}>
							<div className="bg-icon-container">
								<Media>
									<svg
										style={{ marginTop: 9, marginLeft: 9 }}
										xmlns="http://www.w3.org/2000/svg"
										width="22"
										height="22"
										viewBox="0 0 22 22"
									>
										<defs>
											<clipPath id="clip-path">
												<rect
													id="Rectangle_798"
													data-name="Rectangle 798"
													width="22"
													height="22"
													transform="translate(14 14)"
													fill="none"
													stroke="#fff"
													strokeWidth={1}
												/>
											</clipPath>
										</defs>
										<g
											id="Mask_Group_178"
											data-name="Mask Group 178"
											transform="translate(-14 -14)"
											clipPath="url(#clip-path)"
										>
											<g id="Time" transform="translate(14 14)">
												<path
													id="Path_350"
													data-name="Path 350"
													d="M19.5,0H2.5A2.5,2.5,0,0,0,0,2.5V19.5A2.5,2.5,0,0,0,2.5,22H19.5A2.5,2.5,0,0,0,22,19.5V2.5A2.5,2.5,0,0,0,19.5,0ZM21.12,2.5V4.8H15.105V.88h4.4A1.624,1.624,0,0,1,21.12,2.5ZM6.895,13.3H.88V5.685H6.895Zm.88-1.157h6.45v8.98H7.775Zm6.45-2.288H7.775V5.685h6.45ZM7.775,4.8V.88h6.45V4.8ZM2.5.88H6.9V4.8H.88V2.5A1.624,1.624,0,0,1,2.5.88ZM.88,19.5V15.585H6.895V21.12h-4.4A1.624,1.624,0,0,1,.88,19.5Zm20.24,0A1.624,1.624,0,0,1,19.5,21.12H15.1V19.092H21.12Zm0-2.693H15.105V9.478H21.12ZM15.105,7.19v-1.5H21.12v1.5Z"
												/>
												<path
													id="Path_351"
													data-name="Path 351"
													d="M2.424,3.252H5.337a.44.44,0,0,0,0-.88H2.424a.44.44,0,0,0,0,.88Z"
												/>
												<path
													id="Path_352"
													data-name="Path 352"
													d="M12.483,2.38H9.574a.44.44,0,0,0,0,.88h2.908a.44.44,0,0,0,0-.88Z"
												/>
												<path
													id="Path_353"
													data-name="Path 353"
													d="M16.685,3.26H19.6a.44.44,0,1,0,0-.88H16.685a.44.44,0,1,0,0,.88Z"
												/>
											</g>
										</g>
									</svg>
								</Media>
							</div>
						</div>

						<div className="btn-toggle" style={{ marginBottom: 10 }}>
							<div className="bg-icon-container">
								<Media>
									<svg
										style={{ marginTop: 9, marginLeft: 10 }}
										xmlns="http://www.w3.org/2000/svg"
										width="22"
										height="22"
										viewBox="0 0 22 22"
									>
										<defs>
											<clipPath id="clip-path">
												<rect
													id="Rectangle_798"
													data-name="Rectangle 798"
													width="22"
													height="22"
													transform="translate(14 14)"
													fill="none"
													stroke="#fff"
													strokeWidth={1}
												/>
											</clipPath>
										</defs>
										<g
											id="Mask_Group_180"
											data-name="Mask Group 180"
											transform="translate(-14 -14)"
											clipPath="url(#clip-path)"
										>
											<g id="Wait_list" data-name="Wait list" transform="translate(12.898 14)">
												<path
													id="Path_358"
													data-name="Path 358"
													d="M20.156,13.495H20.9v-.924a.493.493,0,0,0-.493-.493H15.228a.493.493,0,0,0-.493.493v.924h.748V15.3a1.962,1.962,0,0,0,.66,1.47,1.98,1.98,0,0,0-.66,1.474v2.336h-.748v.924a.493.493,0,0,0,.493.493h5.183a.493.493,0,0,0,.493-.493v-.924h-.748V18.247a1.984,1.984,0,0,0-.664-1.474,1.967,1.967,0,0,0,.664-1.47ZM16.72,19.923a.264.264,0,0,1-.528,0v-1.76a.264.264,0,1,1,.528,0Zm0-4.55a.264.264,0,1,1-.528,0v-1.6a.264.264,0,0,1,.528,0Z"
												/>
												<path
													id="Path_359"
													data-name="Path 359"
													d="M4.18,20.359V1.641A.757.757,0,0,1,4.937.88H17.063a.757.757,0,0,1,.757.761v8.822h.88v-8.8A1.637,1.637,0,0,0,17.063,0H4.937A1.637,1.637,0,0,0,3.3,1.641V20.359A1.637,1.637,0,0,0,4.937,22h7.59v-.88H4.937a.757.757,0,0,1-.757-.761Z"
												/>
												<rect
													id="Rectangle_810"
													data-name="Rectangle 810"
													width="10.186"
													height="1.188"
													rx="0.123"
													transform="translate(5.909 5.245)"
												/>
												<rect
													id="Rectangle_811"
													data-name="Rectangle 811"
													width="10.186"
													height="1.188"
													rx="0.123"
													transform="translate(5.909 9.258)"
												/>
												<rect
													id="Rectangle_812"
													data-name="Rectangle 812"
													width="6.455"
													height="1.188"
													rx="0.123"
													transform="translate(5.909 13.504)"
												/>
											</g>
										</g>
									</svg>
								</Media>
							</div>
						</div> */}
					</Col>
					<StyledCol sm={4} md={4} lg={4}>
						<BgSearchBar>
							<StyledDebounce
								debounceTimeout={500}
								type="text"
								name="name"
								value={keyword}
								onChange={(e) => searchBarOnChange(e.target.value)}
							></StyledDebounce>
							<div id="guest-book-search-icon">
								<img
									src={searchIcon}
									width="22"
									height="22"
									className="d-inline"
									alt="checker"
									style={{ filter: 'lighten' }}
								/>
							</div>
						</BgSearchBar>
						<ListWrapper>
							{isLoading ? <DotLoader /> : ''}
							<List
								width={314}
								height={662}
								rowHeight={75}
								rowCount={filteredGuestList.length}
								rowRenderer={({ key, index, style }) => {
									const guest = filteredGuestList[index];
									return (
										<div key={key} style={style} onClick={() => handleSelectGuest(guest)}>
											<div id="list-item">
												<div
													id={
														selectedGuest.id === guest.id
															? 'selected-indicator--selected'
															: 'selected-indicator'
													}
												/>
												<div id="text-container">
													<h4>
														{guest.first_name}
														&nbsp;
														{guest.last_name}
													</h4>

													<p>
														<NumberFormat
															value={guest.phone}
															displayType={'text'}
															format="#### #### ####"
														/>
													</p>
												</div>
												<div id="icon-container">
													<img src={widgetIcon} width={15} height={15} />
													<img src={guestBookIcon} width={18} height={18} />
												</div>
											</div>
										</div>
									);
								}}
							/>
						</ListWrapper>
					</StyledCol>
					<Col style={{ margin: 0, padding: 0 }} sm={7} md={7} lg={7}>
						<div style={{ position: 'relative' }}>
							<NewGuestBtn onClick={toggleAddNewGuest}>
								<img src={newGuestIcon} width={20} height={20} />
								New Guest
							</NewGuestBtn>
							<AddNewGuestModal
								newGuestOpen={newGuestOpen}
								setNewGuestOpen={setNewGuestOpen}
								addGuest={addGuest}
							/>
							<LineSeparator />
						</div>

						<GuestDetailsWrapper>
							{isGuestSelected ? (
								<GuestDetailContainer
									isNotesActive={selectedTab === 2 ? true : false}
									isAnalyticsActive={selectedTab === 3 ? true : false}
								>
									<EditNameModal
										guest={selectedGuest}
										openEditName={openEditName}
										personIcon={personIcon}
										handleCloseEditName={handleCloseEditName}
										modalTitle={'EDIT NAME'}
									/>
									<div id="guest-header">
										<h4 onClick={toggleEditName}>
											{selectedGuest.first_name}
											&nbsp;
											{selectedGuest.last_name}
										</h4>
										<div id="guest-btn-wrapper">
											<div id="guest-btn-container" onClick={() => handleChangeTab(1)}>
												<svg
													id={selectedTab === 1 ? 'svg-btn-icon--selected' : 'svg-btn-icon'}
													xmlns="http://www.w3.org/2000/svg"
													xmlnsXlink="http://www.w3.org/1999/xlink"
													width="26"
													height="26"
													viewBox="0 0 26 26"
												>
													<g transform="translate(-726 -72)" clipPath="url(#a)">
														<g transform="translate(725.22 72)">
															<path d="M6.12,12.21a3.772,3.772,0,0,0,.031.483h7.03A3.786,3.786,0,0,0,11.1,8.762a2.345,2.345,0,1,0-2.839,0A3.765,3.765,0,0,0,6.12,12.21Z" />
															<path d="M20.878,15.184H6.386a.52.52,0,0,0,0,1.04H20.878a.52.52,0,0,0,0-1.04Z" />
															<path d="M20.878,11.653H15.9a.52.52,0,1,0,0,1.04h4.976a.52.52,0,0,0,0-1.04Z" />
															<path d="M20.878,18.892H6.386a.52.52,0,0,0,0,1.04H20.878a.52.52,0,0,0,0-1.04Z" />
															<path d="M19.412,2.356,17.425.172h0L17.269,0H5.621a2.491,2.491,0,0,0-2.5,2.491V23.509A2.491,2.491,0,0,0,5.621,26H21.965a2.5,2.5,0,0,0,2.475-2.491V7.909ZM17.56,1.867l5.2,5.72H19.011A1.456,1.456,0,0,1,17.56,6.136Zm4.4,23.093H5.621A1.451,1.451,0,0,1,4.16,23.509V2.491A1.451,1.451,0,0,1,5.621,1.04h10.92v5.1a2.5,2.5,0,0,0,2.491,2.491H23.4V23.509a1.451,1.451,0,0,1-1.435,1.451Z" />
														</g>
													</g>
												</svg>
											</div>
											<div id="guest-btn-container" onClick={() => handleChangeTab(2)}>
												<svg
													id={selectedTab === 2 ? 'svg-btn-icon--selected' : 'svg-btn-icon'}
													xmlns="http://www.w3.org/2000/svg"
													xmlnsXlink="http://www.w3.org/1999/xlink"
													width="26"
													height="26"
													viewBox="0 0 26 26"
												>
													<g transform="translate(-806 -72)" clipPath="url(#a)">
														<g transform="translate(806.828 72.003)">
															<path d="M22.03,13.518a.52.52,0,1,0,0-1.04H20.99V5.9h1.04a.52.52,0,1,0,0-1.04H20.99V2.99A3.021,3.021,0,0,0,17.829,0H4.57a2.974,2.974,0,0,0-2.8,2.99V23.007A3,3,0,0,0,4.763,26H18.026a2.99,2.99,0,0,0,2.984-2.99V21.135h1.04a.52.52,0,1,0,0-1.04h-1.04V13.518ZM19.95,20.1H18.744a1.877,1.877,0,1,0,0,1.04h1.227v1.872a1.95,1.95,0,0,1-1.945,1.95H4.763a1.955,1.955,0,0,1-1.95-1.95V2.99A1.929,1.929,0,0,1,4.57,1.04H17.829a2.007,2.007,0,0,1,2.142,1.95V4.861H18.744a1.882,1.882,0,1,0,0,1.04h1.227v6.577H18.744a1.877,1.877,0,1,0,0,1.04h1.227Zm-3.021,1.04h.65a.821.821,0,0,1-.65.317.842.842,0,1,1,0-1.679.811.811,0,0,1,.65.322H16.95a.52.52,0,1,0,0,1.04Zm0-15.234h.65a.811.811,0,0,1-.629.338.842.842,0,1,1,0-1.679.822.822,0,0,1,.65.317h-.65a.52.52,0,1,0,0,1.04Zm.021,7.617h.65a.821.821,0,0,1-.65.317.837.837,0,0,1,0-1.674.821.821,0,0,1,.65.317h-.65a.52.52,0,1,0,0,1.04Z" />
															<path d="M12.052,5.132H4.679a.52.52,0,1,0,0,1.04h7.373a.52.52,0,1,0,0-1.04Z" />
															<path d="M12.052,10.253H4.679a.52.52,0,1,0,0,1.04h7.373a.52.52,0,1,0,0-1.04Z" />
															<path d="M12.052,15.38H4.679a.52.52,0,0,0,0,1.04h7.373a.52.52,0,1,0,0-1.04Z" />
															<path d="M10.206,20.5H4.679a.52.52,0,1,0,0,1.04h5.527a.52.52,0,1,0,0-1.04Z" />
														</g>
													</g>
												</svg>
											</div>
											<div id="guest-btn-container" onClick={() => handleChangeTab(3)}>
												<svg
													id={selectedTab === 3 ? 'svg-btn-icon--selected' : 'svg-btn-icon'}
													xmlns="http://www.w3.org/2000/svg"
													xmlnsXlink="http://www.w3.org/1999/xlink"
													width="26"
													height="26"
													viewBox="0 0 26 26"
												>
													<g transform="translate(-886 -72)" clipPath="url(#a)">
														<g transform="translate(885.179 70.352)">
															<path d="M5.452,15.394H1.659a.586.586,0,0,0-.6.586V27.648H6.033V15.98a.581.581,0,0,0-.581-.586Z" />
															<path d="M12.3,13.3H8.493a.581.581,0,0,0-.586.586V27.648h4.977V13.885A.581.581,0,0,0,12.3,13.3Z" />
															<path d="M19.154,11.125h-3.81a.581.581,0,0,0-.586.586V27.648h4.977V11.712a.581.581,0,0,0-.581-.586Z" />
															<path d="M25.989,9.008H22.2a.581.581,0,0,0-.586.586V27.648h4.977V9.594a.586.586,0,0,0-.6-.586Z" />
															<path d="M3.55,12.8a1.515,1.515,0,0,0,1.283-2.333L9.527,6.5a1.487,1.487,0,0,0,.874.282,1.521,1.521,0,0,0,1.294-.735l4.037,1.322a1.521,1.521,0,0,0,3.041-.05,1.161,1.161,0,0,0-.033-.282l4.424-2.665a1.515,1.515,0,1,0-.6-1.2,1.277,1.277,0,0,0,.028.254l-4.44,2.66a1.515,1.515,0,0,0-.907-.3,1.5,1.5,0,0,0-1.145.553L11.889,4.943a1.526,1.526,0,0,0-3.014.315,1.9,1.9,0,0,0,.033.31L3.932,9.8a1.565,1.565,0,0,0-.359-.05,1.526,1.526,0,0,0,0,3.047Z" />
														</g>
													</g>
												</svg>
											</div>
											<div id="guest-btn-container" onClick={toggleSmsText}>
												<svg
													id={selectedTab === 4 ? 'svg-btn-icon--selected' : 'svg-btn-icon'}
													xmlns="http://www.w3.org/2000/svg"
													xmlnsXlink="http://www.w3.org/1999/xlink"
													width="26"
													height="26"
													viewBox="0 0 26 26"
												>
													<g transform="translate(-726 -72)" clipPath="url(#a)">
														<g transform="translate(726 72)">
															<path d="M24.225,6.13h-8.2V2.939A2.939,2.939,0,0,0,13.082,0H2.939A2.939,2.939,0,0,0,0,2.939V23.061A2.939,2.939,0,0,0,2.939,26H13.082a2.939,2.939,0,0,0,2.939-2.939V19.575h8.2A1.775,1.775,0,0,0,26,17.8V7.9A1.775,1.775,0,0,0,24.225,6.13Zm-.816,1.053-8.168,5.056-8.7-5.056ZM14.983,23.061A1.885,1.885,0,0,1,13.1,24.947H2.939a1.891,1.891,0,0,1-1.885-1.885V2.939A1.891,1.891,0,0,1,2.939,1.053h.748A1.754,1.754,0,0,0,5.4,2.428h5.108a1.754,1.754,0,0,0,1.7-1.375h.879a1.885,1.885,0,0,1,1.885,1.885V6.13H6.277A1.775,1.775,0,0,0,4.5,7.9v9.9a1.775,1.775,0,0,0,1.775,1.775h8.689Zm9.98-5.266a.721.721,0,0,1-.737.727H6.277a.721.721,0,0,1-.721-.721V7.9a.358.358,0,0,1,0-.074L14.978,13.3h0l.09.037h.205a.448.448,0,0,0,.121,0h.042l.09-.042h0l9.316-5.761a.716.716,0,0,1,.105.369Z" />
															<path d="M7.936,24.173A1.617,1.617,0,1,0,6.32,22.556a1.617,1.617,0,0,0,1.617,1.617Z" />
														</g>
													</g>
												</svg>
											</div>
											<div id="guest-btn-container" onClick={makeNewBookingHandler}>
												<svg
													id={selectedTab === 5 ? 'svg-btn-icon--selected' : 'svg-btn-icon'}
													xmlns="http://www.w3.org/2000/svg"
													xmlnsXlink="http://www.w3.org/1999/xlink"
													width="26"
													height="26"
													viewBox="0 0 26 26"
												>
													<g transform="translate(-974 -56)" clipPath="url(#a)">
														<g transform="translate(974 56.26)">
															<path
																d="M8.248,5.978a.52.52,0,0,0,.52-.52V.82a.52.52,0,1,0-1.04,0V5.458A.52.52,0,0,0,8.248,5.978Z"
																transform="translate(-1.03 -0.3)"
															/>
															<path
																d="M23.166,2.946H19.88V4.813a1.487,1.487,0,1,1-2.2,0V2.946H8.32V4.813a1.487,1.487,0,1,1-2.194,0V2.946H2.834A2.834,2.834,0,0,0,0,5.78V23.3a2.834,2.834,0,0,0,2.834,2.834H23.166A2.834,2.834,0,0,0,26,23.3V5.78a2.834,2.834,0,0,0-2.834-2.834ZM24.96,23.3a1.8,1.8,0,0,1-1.794,1.794H2.834A1.8,1.8,0,0,1,1.04,23.3V8.978H24.96Z"
																transform="translate(0 -0.653)"
															/>
															<path
																d="M21.592,5.978a.52.52,0,0,0,.52-.52V.82a.52.52,0,1,0-1.04,0V5.458A.52.52,0,0,0,21.592,5.978Z"
																transform="translate(-2.81 -0.3)"
															/>
															<path
																d="M14,11.688a6.516,6.516,0,1,0,6.516,6.51A6.516,6.516,0,0,0,14,11.688Zm0,11.986A5.476,5.476,0,1,1,19.473,18.2,5.476,5.476,0,0,1,14,23.674Z"
																transform="translate(-0.998 -1.818)"
															/>
															<path
																d="M15.07,15.642H13.957v2.605H11.352V19.36h2.605v2.605H15.07V19.36h2.605V18.247H15.07Z"
																transform="translate(-1.514 -2.346)"
															/>
														</g>
													</g>
												</svg>
											</div>
										</div>
									</div>

									{selectedTab === 1 ? (
										<GuestBookProfileTab
											selectedGuest={selectedGuest}
											selectedTab={selectedTab}
											profileTabIsLoading={profileTabIsLoading}
											setProfileTabIsLoading={setProfileTabIsLoading}
											setSelectedGuest={setSelectedGuest}
											guestUpdate={guestUpdate}
										/>
									) : selectedTab === 2 ? (
										<GuestBookNotesTab
											selectedGuest={selectedGuest}
											selectedTab={selectedTab}
											profileTabIsLoading={profileTabIsLoading}
											setProfileTabIsLoading={setProfileTabIsLoading}
											setSelectedGuest={setSelectedGuest}
											guestUpdate={guestUpdate}
										/>
									) : selectedTab === 3 ? (
										<GuestAnalytics
											filteredGuestHistory={filteredGuestHistory}
											guestHistory={guestHistory}
										/>
									) : (
										''
									)}
									<SmsTextModal
										guest={selectedGuest}
										smsTextOpen={smsTextOpen}
										templates={templates}
										alertHandler={alertHandler}
										dialogAlertMessage={dialogAlertMessage}
										sending={sending}
										setSending={setSending}
										setAlertHandler={setAlertHandler}
										setSmsTextOpen={setSmsTextOpen}
										sendMessage={sendMessage}
									/>
								</GuestDetailContainer>
							) : (
								<p>No Guest selected</p>
							)}
							<MakeBooking
								reducers={reducers}
								createBookingOpen={createBookingOpen}
								history={history}
								makeNewBookingHandler={makeNewBookingHandler}
								guest={selectedGuest}
							/>
						</GuestDetailsWrapper>
					</Col>
				</Row>
			</Container>
		</Wrapper>
	);
}

export default withDashboardApp(GuestBook);
