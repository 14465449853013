import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import moment from 'moment';
import styled from 'styled-components';
import xIcon from 'assets/images/booking/exit-btn-table-details.png';
import ScheduleCalendarModal from '../TeamGrid/ScheduleCalendarModal';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		outline: 'none',
	},
}));

const StyledBackdrop = withStyles({
	root: {
		background: 'rgb(255 255 255 / 30%);',
		backdropFilter: 'blur(10px)',
	},
})(Backdrop);

const Wrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 480px;
	height: 260px;
	background: #fefefe;
	border-radius: 10px;
	box-shadow: 0px 1px 10px #1c1c1e4d;
	padding: 10px 15px;

	h4 {
		color: #5a5a5a;
		font-size: 20px;
		font-weight: 700;
		margin: 5px 0 15px 5px;
	}

	span {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: #fefefe;
		border-radius: 2em;
		width: 100%;
		height: 50px;
		margin: 5px 0;
		padding: 10px 20px;
		box-shadow: 2px 5px 10px #1c1c1e4d;

		button {
			width: 70%;
			color: #d5d5d5;
			text-align: end;
		}

		#alert-container {
			position: absolute;
			left: 150px;
			bottom: 0;
			display: flex;
			user-select: none;
			font-size: 12px;
			color: #ff3333;
		}
	}

	label {
		color: #222222;
		font-size: 16px;
		font-weight: 700;
		white-space: nowrap;
	}

	input {
		width: 70%;
		text-align: end;
		::placeholder,
		::-webkit-input-placeholder {
			color: #d5d5d5;
		}
		:-ms-input-placeholder {
			color: #d5d5d5;
		}

		&:focus {
			text-align: start;
		}
	}
`;

const SaveButton = styled.div`
	display: flex;
	width: 125px;
	height: 40px;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	font-weight: 700;
	color: #93ccbd;
	border: 2px solid #93ccbd;
	border-radius: 10px;
	box-shadow: 0px 3px 5px #1c1c1e4d;
	user-select: none;
	cursor: pointer;
`;

const ImgContainer = styled.div`
	position: absolute;
	right: 20px;
	top: 15px;
	cursor: pointer;

	img {
		-webkit-user-drag: none;
		-khtml-user-drag: none;
		-moz-user-drag: none;
		-o-user-drag: none;
		user-drag: none;
	}
`;

function CreateEmployeeScheduleDate(props) {
	const classes = useStyles();
	const [selectedDate, setSelectedDate] = useState('');
	const [initDate, setInitDate] = useState(moment().format('YYYY-MM-DD'));
	const [showCalendar, setShowCalendar] = useState(false);
	const [hasError, setHasError] = useState(false);
	const [weekNo, setWeekNo] = useState('');

	const handleClose = () => {
		// console.log('close');
		props.setShowCreateScheduleModal(false);
		clearState();
	};

	const onChange = (e) => {
		if (e.target.validity.valid) {
			let int = e.target.value;
			setWeekNo(int);
			setHasError(false);
		} else {
			setHasError(true);
		}
	};

	const openCalendar = () => {
		setShowCalendar(!showCalendar);
	};

	const handleSelectedDate = (date) => {
		setSelectedDate(date);
		setInitDate(date);
		setShowCalendar(false);
	};

	const onSave = () => {
		if (!selectedDate) {
			console.log('show error');
		} else if (!parseInt(weekNo) || parseInt(weekNo) < 0) {
			setHasError(true);
		} else {
			let data = {
				date: selectedDate,
				no_of_weeks: parseInt(weekNo),
			};
			props.newEmployeeScheduleCallback(data);
			props.setShowCreateScheduleModal(false);
			clearState();
		}
	};

	const clearState = () => {
		setSelectedDate('');
		setInitDate(moment().format('YYYY-MM-DD'));
		setWeekNo('');
	};

	return (
		<Modal
			aria-labelledby="create-employee-schedule-modal"
			aria-describedby="create-employee-schedule-modal-description"
			className={classes.modal}
			open={props.showCreateScheduleModal}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={StyledBackdrop}
			// BackdropProps={{
			// 	timeout: 500,
			// }}
		>
			<Fade in={props.showCreateScheduleModal}>
				<div className={classes.paper}>
					<Wrapper>
						<ImgContainer onClick={handleClose}>
							<img src={xIcon} width={15} height={15} alt="close-icon" />
						</ImgContainer>
						<h4>Create schedules</h4>
						<span>
							<label>Start Date</label>
							{selectedDate ? (
								<button onClick={openCalendar} style={{ color: 'black' }}>
									{selectedDate}
								</button>
							) : (
								<button onClick={openCalendar}>Select start date</button>
							)}
						</span>
						<span style={hasError ? { border: '1px solid #ff3333' } : {}}>
							<label>No. of Weeks</label>
							{hasError ? <div id="alert-container">Invalid number</div> : ''}
							<input
								type="text"
								pattern="[\+\0-9]{0,3}"
								value={weekNo}
								onChange={onChange}
								placeholder="Enter how many weeks"
							/>
						</span>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								width: '100%',
								marginTop: 20,
							}}
						>
							<SaveButton onClick={onSave}>Save</SaveButton>
						</div>
					</Wrapper>
					<ScheduleCalendarModal
						selectedDate={initDate}
						showCalendar={showCalendar}
						setShowCalendar={setShowCalendar}
						handleSelectedDate={handleSelectedDate}
					/>
				</div>
			</Fade>
		</Modal>
	);
}

export default CreateEmployeeScheduleDate;
