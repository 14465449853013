import React, { useState, useEffect } from 'react';
import { Popover } from '@material-ui/core';

import styled from 'styled-components';
import TimePicker from './TimePicker';
import moment from 'moment';
import DropDownPicker from './DropDownPicker';
import AddGroupModal from '../AddGroupModal';
import { SHIFTTYPE } from 'constants/enum';
import AlertDialog from 'pages/Dashboard/Booking/CreatBooking/booking-experience/AlertDialog';

const Wrapper = styled.div`
	display: flex;
	position: relative;
`;

const StyledPopover = styled(Popover)`
	& > .MuiPopover-paper {
		background: transparent;
	}
`;

const Contents = styled.div`
	margin-left: 30px;
	display: flex;
	flex-direction: column;
	width: 231px;
	height: fit-content;
	min-height: 302px;
	border-radius: 15px;
	background: #505050;
	color: #ffffff;
	padding: 10px 20px;

	#input-container {
		display: flex;
		flex-direction: column;
		align-item: center;
		margin: 5px 0;
		border-bottom: 0.1px solid #ffffff;

		span {
			// position: relative;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
		}

		label {
			white-space: nowrap;
			font-size: 10px;
			font-weight: 550;
		}

		input {
			display: flex;
			align-item: center;
			justify-content: flex-end;
			height: auto;
			background: transparent;
			width: 70%;
			color: #ffffff;
			font-size: 10px;
			font-weight: 550;
			text-align: right;
			padding-bottom: 5px;
			&:focus {
				text-align: start;
			}
		}

		button {
			display: flex;
			align-item: center;
			justify-content: flex-end;
			height: auto;
			background: transparent;
			width: 70%;
			color: #ffffff;
			font-size: 10px;
			font-weight: 550;
		}
	}
`;

const LeftArrow = styled.div`
	position: absolute;
	display: block;
	top: 45%;
	left: 0;
	width: 0;
	height: 0;
	border-top: 18px solid transparent;
	border-right: 30px solid #505050;
	border-bottom: 18px solid transparent;
`;

const Bar = styled.div`
	height: 100%;
	width: 6px;
	background: #ffd9d9;
	border-radius: 15px;
	margin-right: 5px;
`;

const AvatarAndNameContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 45px;
	font-size: 15px;
	font-weight: 700;
	user-select: none;
	padding-bottom: 5px;
	border-bottom: 0.1px solid #ffffff;

	#initials {
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 24px;
		min-width: 24px;
		border-radius: 50%;
		color: #000000;
		font-size: 12px;
		margin-right: 10px;
	}

	#column {
		display: flex;
		flex-direction: column;
		width: 115px;
		height: 33px;
	}

	p {
		margin-top: 3px;
		font-size: 10px;
		font-weight: 550;
	}

	#row {
		display: flex;
		flex-direction: row;
		// width: 100%;
	}

	h4 {
		font-size: 14px;
		font-weight: 700;
		height: 24px;
		line-height: 0.9em;
		// white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;

const Footer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin-bottom: 10px;

	h2 {
		font-size: 18px;
		font-weight: 700;
		color: #ffffff;
	}
`;

const StyledButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 35px;
	background: transparent;
	width: 70%;
	user-select: none;
	cursor: pointer;
	font-size: 14px;
	font-weight: 550;
	margin: 10px 15%;
	border-radius: 2em;
	background: #0e0e0c;

	&:active {
		background: #333333;
	}
`;

const CopyPasteButton = styled.div`
	// position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 15px;
	min-width: 60px;
	margin-left: 5px;
	// right: 0;
	background: #101010;
	font-size: 9px;
	color: #a3a3a3;
	border-radius: 2em;
	cursor: pointer;

	&:active {
		background: #121212;
	}
`;

function ShiftPopover(props) {
	const [anchor, setAnchor] = useState(null);
	const [floors, setFloors] = useState(props.floors);
	const [data, setData] = useState({
		date: props.date,
		employee_id: props.employee.id,
		day_of_week: moment(props.date).isoWeekday(),
		id: props.initVal ? props.initVal.id : 0,
		start_time: props.initVal ? props.initVal.start_time : 0,
		end_time: props.initVal ? props.initVal.end_time : 0,
		break_time: props.initVal ? props.initVal.break_time : 0,
		floor_id: props.initVal ? props.initVal.floor_id : '',
		station_id: props.initVal ? props.initVal.station_id : '',
		type: props.initVal ? props.initVal.type : SHIFTTYPE.shift,
		note: props.initVal ? props.initVal.note : '',
		uuid: props.initVal ? props.initVal.uuid : null,
	});
	const [startTimePickerOpen, setStartTimePickerOpen] = useState(false);
	const [endTimePickerOpen, setEndTimePickerOpen] = useState(false);
	const [breakTimePickerOpen, setBreakTimePickerOpen] = useState(false);
	const [openAddStationModal, setOpenAddStationModal] = useState(false);
	const [alertHandler, setAlertHandler] = useState(false);
	const [dialogAlertMessage, setdialogAlertMessage] = useState('');

	const handleClose = (e) => {
		e.preventDefault();
		e.stopPropagation();
		props.closePopover();
	};

	const copyShift = (e) => {
		let newData = { ...data, id: 0 };
		props.copyShift(newData);
		handleClose(e);
	};

	const pasteShift = (e) => {
		let shiftCopy = { ...props.shiftCopy, date: props.date };
		props.addEmployeeSchedule(shiftCopy);
		handleClose(e);
	};

	const buttonChecker = (copy) => {
		if (anchor) {
			if (props.initVal) {
				if (!copy) {
					return <CopyPasteButton onClick={copyShift}>Copy</CopyPasteButton>;
				} else {
					return <CopyPasteButton onClick={copyShift}>Copy</CopyPasteButton>;
				}
			} else {
				if (copy) {
					return <CopyPasteButton onClick={pasteShift}>Paste</CopyPasteButton>;
				} else {
					return <CopyPasteButton onClick={copyShift}>Copy</CopyPasteButton>;
				}
			}
		}
	};

	const dropDownCallback = (value, int) => {
		let newData = { ...data };
		if (int === 0) {
			newData = { ...data, floor_id: value };
			setData(newData);
		} else if (int === 1) {
			newData = { ...data, station_id: value };
			setData(newData);
		} else if (int === 2) {
			if (value !== SHIFTTYPE.shift) {
				newData = {
					...data,
					start_time: 0,
					end_time: 0,
					break_time: 0,
					floor_id: '',
					station_id: '',
					type: value,
				};
			} else {
				newData = { ...data, type: value };
			}
			setData(newData);
		}
	};

	const timePickerCallback = (value, int) => {
		let newData = { ...data };
		if (int === 0) {
			newData = { ...data, start_time: value };
			setData(newData);
		} else if (int === 1) {
			newData = { ...data, end_time: value };
			setData(newData);
		} else if (int === 2) {
			newData = { ...data, break_time: value };
			setData(newData);
		}
	};

	const notesOnChange = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setData({ ...data, notes: e.target.value });
	};

	const togglePickerButton = (order) => {
		if (data.type !== SHIFTTYPE.shift) {
			return;
		} else {
			if (order === 1) {
				setStartTimePickerOpen(!startTimePickerOpen);
			} else if (order === 2) {
				setEndTimePickerOpen(!endTimePickerOpen);
			} else {
				setBreakTimePickerOpen(!breakTimePickerOpen);
			}
		}
	};

	const typeData = [
		{ type_name: 'Shift', id: SHIFTTYPE.shift },
		{ type_name: 'Reqeusted Off', id: SHIFTTYPE.requestedOff },
		{ type_name: 'On Leave', id: SHIFTTYPE.onLeave },
	];

	const addStationCallback = (val) => {
		let params = {
			id: 0,
			name: val,
			floor_id: data.floor_id,
		};
		setOpenAddStationModal(false);
		handleResetState();
		props.createStations(params);
	};

	const handleSubmit = (e) => {
		if (!data.floor_id) {
			setAlertHandler(true);
			setdialogAlertMessage({
				title: 'Error',
				body: <div>Section field is required</div>,
			});
			return;
		} else {
			props.addEmployeeSchedule(data);
			handleClose(e);
		}
	};

	const handleDeleteShift = (e) => {
		setAnchor(null);
		props.deleteShiftItem(data);
		handleClose(e);
	};

	const handleResetState = () => {
		let newSet = {
			employee_id: props.employee.id,
			day_of_week: 0,
			start_time: 0,
			end_time: 0,
			break_time: 0,
			floor_id: floors[0].id,
			station_id: '',
			type: SHIFTTYPE.shift,
			note: '',
		};
		setData(newSet);
	};

	const computeHours = (start, end) => {
		const diff = Math.abs(end - start);
		const h = Math.floor(diff / 3600);
		return h;
	};

	const computeRate = (start, end) => {
		const diff = Math.abs(end - start);
		const h = Math.floor(diff / 3600);
		if (props.employee.rate) {
			const emplRate = Number(props.employee.rate) * h;
			const rate = Math.round((emplRate * 100) / 100).toFixed(2);
			return rate;
		} else {
			return Math.round((0 * 100) / 100).toFixed(2);
		}
	};

	useEffect(() => {
		if (props.anchor) {
			if (props.id) {
				if (props.id.toString() === props.anchor.id) {
					setAnchor(props.anchor);
				}
			} else {
				return;
			}
		} else {
			if (anchor) {
				if (props.updateEmployeeShiftCallback) {
					props.updateEmployeeShiftCallback(data);
				}
				setAnchor(null);
				setStartTimePickerOpen(false);
				setEndTimePickerOpen(false);
				setBreakTimePickerOpen(false);
			}
		}
	}, [props.anchor]);

	// useEffect(() => {
	// 	if (anchor && props.id) {
	// 		if (props.id.toString() === anchor.id && props.updateEmployeeShiftCallback) {
	// 			if (data.id === 0) {
	// 				props.updateEmployeeShiftCallback(data);
	// 			} else {
	// 				props.updateEmployeeShiftCallback(data);
	// 			}
	// 			console.log(props.id.toString(), anchor.id);
	// 		}
	// 	}
	// }, [data]);

	return (
		<Wrapper>
			<StyledPopover
				elevation={0}
				open={Boolean(anchor)}
				anchorEl={anchor}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'center',
					horizontal: 'left',
				}}
				onClose={handleClose}
			>
				<LeftArrow />
				<Contents>
					<AvatarAndNameContainer>
						<Bar />
						<div id="column">
							<div id="row">
								{false ? (
									<img />
								) : (
									<div id="initials" style={{ background: props.employee.color }}>
										{props.employee.first_name.charAt(0)}
										{props.employee.last_name.charAt(0)}
									</div>
								)}
								<h4>
									{props.employee.first_name} {props.employee.last_name}
								</h4>
							</div>
							{props.employeeRole ? (
								<p>{props.employeeRole.title}</p>
							) : (
								<p>{props.employee.role}</p>
							)}
						</div>
						{buttonChecker(props.shiftCopy)}
						{/* {anchor ? (
							anchor.id !== props.id ? (
								props.shiftCopy ? (
									<CopyPasteButton onClick={pasteShift}>Paste</CopyPasteButton>
								) : (
									<CopyPasteButton onClick={copyShift}>Copy</CopyPasteButton>
								)
							) : (
								<CopyPasteButton onClick={pasteShift}>Paste</CopyPasteButton>
							)
						) : (
							<CopyPasteButton onClick={copyShift}>Copy</CopyPasteButton>
						)} */}
					</AvatarAndNameContainer>

					<div
						id="input-container"
						style={
							data.type !== SHIFTTYPE.shift
								? { marginTop: 15, borderColor: '#9a9a9a' }
								: { marginTop: 15 }
						}
					>
						<span style={data.type !== SHIFTTYPE.shift ? { color: '#9a9a9a' } : {}}>
							<label>Start time</label>
							<button
								onClick={() => togglePickerButton(1)}
								style={data.type !== SHIFTTYPE.shift ? { color: '#9a9a9a', cursor: 'default' } : {}}
							>
								{data.start_time
									? moment(props.date).seconds(data.start_time).format('hh:mm A')
									: moment().hours(data.start_time).format('00:00 A')}
							</button>
						</span>
						{startTimePickerOpen ? (
							<TimePicker
								initVal={data.start_time}
								anchor={anchor}
								id={0}
								timePickerCallback={timePickerCallback}
							/>
						) : (
							<></>
						)}
					</div>

					<div
						id="input-container"
						style={data.type !== SHIFTTYPE.shift ? { borderColor: '#9a9a9a' } : {}}
					>
						<span style={data.type !== SHIFTTYPE.shift ? { color: '#9a9a9a' } : {}}>
							<label>End time</label>
							<button
								onClick={() => togglePickerButton(2)}
								style={data.type !== SHIFTTYPE.shift ? { color: '#9a9a9a', cursor: 'default' } : {}}
							>
								{data.end_time
									? moment(props.date).seconds(data.end_time).format('hh:mm A')
									: moment().hours(data.end_time).format('00:00 A')}
							</button>
						</span>
						{endTimePickerOpen ? (
							<TimePicker
								initVal={data.end_time}
								anchor={anchor}
								id={1}
								timePickerCallback={timePickerCallback}
							/>
						) : (
							<></>
						)}
					</div>
					<div
						id="input-container"
						style={data.type !== SHIFTTYPE.shift ? { borderColor: '#9a9a9a' } : {}}
					>
						<span style={data.type !== SHIFTTYPE.shift ? { color: '#9a9a9a' } : {}}>
							<label>Break time</label>
							<button
								onClick={() => togglePickerButton(3)}
								style={data.type !== SHIFTTYPE.shift ? { color: '#9a9a9a', cursor: 'default' } : {}}
							>
								{data.break_time > 0
									? moment(props.date).seconds(data.break_time).format('hh:mm')
									: '0.0'}
							</button>
						</span>
						{breakTimePickerOpen ? (
							<TimePicker
								disabled={true}
								initVal={data.break_time}
								anchor={anchor}
								id={2}
								timePickerCallback={timePickerCallback}
							/>
						) : (
							<></>
						)}
					</div>
					<div id="input-container">
						<span>
							<DropDownPicker
								data={floors}
								initVal={data.floor_id}
								label="Section"
								id={0}
								dropDownCallback={dropDownCallback}
							/>
						</span>
					</div>
					<div id="input-container">
						<span>
							<DropDownPicker
								data={props.stations.filter((e) => e.floor_id === data.floor_id)}
								initVal={data.station_id}
								label="Station"
								id={1}
								setOpenAddStationModal={setOpenAddStationModal}
								dropDownCallback={dropDownCallback}
							/>
						</span>
					</div>
					<div id="input-container">
						<span>
							<DropDownPicker
								data={typeData}
								initVal={data.type}
								label="Type"
								id={2}
								dropDownCallback={dropDownCallback}
							/>
						</span>
					</div>
					<div id="input-container">
						<span>
							<label>Notes</label>
							<input
								type="text"
								placeholder="Enter Notes"
								onChange={notesOnChange}
								value={data.notes}
							/>
						</span>
					</div>
					<Footer>
						<h2>Total Hours & Cost:</h2>
						<h2>
							{computeHours(data.start_time, data.end_time)} h - $
							{computeRate(data.start_time, data.end_time)}
						</h2>
					</Footer>
					{!props.initVal ? (
						<StyledButton onClick={handleSubmit} style={{ color: '#e3c3cb' }}>
							Add Shift
						</StyledButton>
					) : (
						<StyledButton onClick={handleDeleteShift} style={{ color: '#FF3B3B' }}>
							Delete shift
						</StyledButton>
					)}
				</Contents>
				<AddGroupModal
					open={openAddStationModal}
					closeHandler={setOpenAddStationModal}
					callback={addStationCallback}
					title="Please enter station name"
				/>
				<AlertDialog
					alertHandler={alertHandler}
					dialogAlertMessage={dialogAlertMessage}
					setAlertHandler={setAlertHandler}
				/>
			</StyledPopover>
		</Wrapper>
	);
}

export default ShiftPopover;
