import React, { useCallback, useEffect, useRef, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { debounce } from 'lodash';
import ToggleSwitch from '../../CreatBooking/reservation-details/toggleSwitch';
import ReservationDropDown from './ReservationDropDown';
import ReservationButton from './ReservationButton';
import EditBookingTimeModal from './EditBookingTimeModal';
import EditPartySizeTableModal from './EditPartySizeTableModal';
import sessionIcon from '../../../../../assets/images/booking/session-icon.png';
import BookingExperience from '../booking-experience/BookingExperience';

import {
	apiUrl,
	getBookingStatus,
	getBookingStatusButton,
	getBookingStatusColor,
	getBookingTakenFullname,
	getBookingType,
	getDisabledDays,
	getFullname,
	getGuestPhoneNum,
	getRemainingTime,
	getTimeStr,
	updateDateTime,
	getNearestAvailableDate,
	getAllTime,
	filterShifts,
} from '../../../../../utils/Utility';
// ICONS
import btnDownArrowIcon from '../../../../../assets/images/booking/down-arrow-icon.png';
import reservationLockTableIcon from '../../../../../assets/images/booking/reservation-lock-table-icon.png';
import unlockTableIcon from '../../../../../assets/images/booking/unlocked-table-icon.png';
import overtimeIcon from '../../../../../assets/images/booking/overtime-icon.png';
import calendarIcon from '../../../../../assets/images/booking/booking-details/icon-report-calendar.png';
import personIcon from '../../../../../assets/images/booking/create-booking/person-icon.png';
import exitBtnIcon from '../../../../../assets/images/booking/exit-btn-table-details.png';
import btnTimelineIcon from '../../../../../assets/images/booking/btn-timeline-icon.png';
import phoneIcon from '../../../../../assets/images/booking/reservation-btn-phone-icon.png';
import sectionPartyIcon from '../../../../../assets/images/booking/section-party-size-icon.png';
import bookingStatusPhoneIcon from '../../../../../assets/images/booking/create-booking/booking-status-phone.png';
import bookingStatusNetwork from '../../../../../assets/images/booking/create-booking/booking-status-network.png';
import bookingStatusWalkIn from '../../../../../assets/images/booking/create-booking/booking-status-walk-in.png';
import experienceIcon from '../../../../../assets/images/booking/create-booking/booking-option-experience-icon.png';

import axios from 'axios';
import NumberFormat from 'react-number-format';
import { BOOKINGSTATUS, BOOKINGTYPE } from '../../../../../constants/enum';
import moment from 'moment';
import SelectBookingCalendar from '../../BookingCalendar/SelectBookingCalendar';
import PartialPartySize from '../party-size/PartialPartySize';
import EditNameModal from './EditNameModal';
import TicketQuantity from '../booking-experience/TicketQuantity';
import AlertDialog from '../booking-experience/AlertDialog';
import SelectBookingType from './SelectBookingType';

function ReservationDetails(props) {
	const [bookingTimeOpen, setBookingTimeOpen] = useState(false);
	const [openCalendar, setOpenCalendar] = useState(false);
	const [guest, setGuest] = useState(props.selectedBooking.guest);
	const [isSaving, setIsSaving] = useState(false);
	const [partySizeOpen, setPartySizeOpen] = useState(false);
	const [openPartialPartySize, setOpenPartialPartySize] = useState(false);
	const [openEditName, setOpenEditName] = useState(false);
	const [selectBookingOpen, setSelectBookingOpen] = useState(false);

	const [timeList, setTimeList] = useState([]);

	// Booking Experience
	const [bookingExperience, setBookingExperience] = useState(false);
	const [alertHandler, setAlertHandler] = useState(false);
	const [expDaysOfWeek, setExpDaysOfWeek] = useState([]);
	const [expStartDate, setExpStartDate] = useState();
	const [expEndDate, setExpEndDate] = useState();
	const [expRecurringType, setExpRecurringType] = useState(-2);
	const [expRecurringValue, setExpRecurringValue] = useState(-1);
	const [expShiftId, setExpShiftId] = useState(0);
	const [expName, setExpName] = useState();
	const [expId, setExpId] = useState(0);
	// const [expNoOfTickets, setExpNoOfTickets] = useState(0);
	const [expHandler, setExpHandler] = useState(false);
	const [ticketQuantityOpen, setTicketQuantityOpen] = useState(false);
	const [hideShiftAlert, setHideShiftAlert] = useState(false);
	const [testDate, setTestDate] = useState();
	const [dialogAlertMessage, setdialogAlertMessage] = useState('');

	const debouncedSave = useCallback(
		debounce((val, type) => updateValue(val, type), 1500),
		[],
	);

	const updateValue = (val, type) => {
		let booking = props.selectedBooking;
		if (booking.table_ids === undefined) {
			booking['table_ids'] = booking.table.map((e) => e.id);
		}

		setIsSaving(true);

		switch (type) {
			case 1:
				booking = { ...booking, reservation_note: val };

				props.setSelectedBooking(booking);
				updateBooking(booking);
				break;
			case 2:
				setGuest({ ...guest, general_note: val });
				break;
			case 3:
				setGuest({ ...guest, special_relationship: val });
				break;
			case 4:
				setGuest({ ...guest, seating_preference: val });
				break;
			case 5:
				setGuest({ ...guest, food_drink_preference: val });
				break;
		}
	};

	const handleOnChange = (e, type) => {
		debouncedSave(e, type);
	};

	// const handleOnChangeName = (e, type) => {
	// 	debouncedSave(e, type);
	// };

	const handleOpenEditTimeBooking = () => {
		if (!bookingTimeOpen) {
			setBookingTimeOpen(true);
		}
	};

	const handleOpenEditPartySize = () => {
		if (!partySizeOpen) {
			setPartySizeOpen(true);
		}
	};

	const handleCloseEditPartySize = () => {
		if (partySizeOpen) {
			setPartySizeOpen(false);
		}
	};
	const handleSetOpenCalendar = () => {
		if (!openCalendar) {
			setOpenCalendar(true);
		}
	};

	const handleOpenEditName = () => {
		if (!openEditName) {
			setOpenEditName(true);
		}
	};

	const handleCloseEditName = (guest) => {
		if (openEditName) {
			setOpenEditName(false);
		}
		let booking = props.selectedBooking;
		booking = { ...booking, guest: guest };

		if (booking.table_ids === undefined) {
			booking['table_ids'] = booking.table.map((e) => e.id);
		}
		props.setSelectedBooking(booking);
		updateBooking(booking);
	};
	const editPartySizeTableHandle = (partySize, tables) => {
		let booking = props.selectedBooking;

		booking = { ...booking, party_size: partySize, table: tables };

		if (booking.table_ids === undefined) {
			booking['table_ids'] = booking.table.map((e) => e.id);
		}

		props.setSelectedBooking(booking);
		updateBooking(booking);
	};

	const partyPartySizeHandler = (partySize) => {
		let booking = props.selectedBooking;
		if (booking.table_ids === undefined) {
			booking['table_ids'] = booking.table.map((e) => e.id);
		}

		booking = { ...booking, status: BOOKINGSTATUS.partiallySeated, partial_seated: partySize };
		props.setSelectedBooking(booking);
		updateBooking(booking);
	};

	const selectedStatusOption = (option) => {
		if (option.value === BOOKINGSTATUS.partiallySeated) {
			setOpenPartialPartySize(true);
			return;
		}

		let booking = props.selectedBooking;
		if (booking.table_ids === undefined) {
			booking['table_ids'] = booking.table.map((e) => e.id);
		}

		booking = { ...booking, status: option.value };
		props.setSelectedBooking(booking);
		updateBooking(booking);
	};

	const selectedTimeHandler = (time) => {
		let booking = props.selectedBooking;
		if (booking.table_ids === undefined) {
			booking['table_ids'] = booking.table.map((e) => e.id);
		}
		booking = {
			...booking,
			start_time: time,
			end_time: time + booking.session_time,
			start_date: updateDateTime(booking.start_date, time),
			end_date: updateDateTime(booking.end_date, time + booking.session_time),
		};
		props.setSelectedBooking(booking);
		updateBooking(booking);
	};

	const updateSelectedDate = (date) => {
		setTestDate(date);
		let booking = props.selectedBooking;

		console.log(date);
		const filteredShifts = filterShifts(props.shifts, moment(date).isoWeekday());
		const nearestDateShift = filteredShifts.filter((item) => item.id === expShiftId);
		const expData = props.experienceList.filter((item) => item.id === expId);

		if (booking.table_ids === undefined) {
			booking['table_ids'] = booking.table.map((e) => e.id);
		}
		if (booking.experience_id > 0) {
			if (nearestDateShift.length > 0) {
				booking = {
					...booking,
					start_time: booking.start_time,
					end_time: booking.start_time + booking.session_time,
					start_date: updateDateTime(date, booking.start_time),
					end_date: updateDateTime(date, booking.end_time + booking.session_time),
				};
			} else {
				setExpName(expData[0].exp_name);
				setHideShiftAlert(true);
				return;
			}
		} else {
			booking = {
				...booking,
				start_time: booking.start_time,
				end_time: booking.start_time + booking.session_time,
				start_date: updateDateTime(date, booking.start_time),
				end_date: updateDateTime(date, booking.end_time + booking.session_time),
			};
		}

		props.setSelectedBooking(booking);
		updateBooking(booking);
	};

	const updateSelectedBookingType = (type) => {
		let booking = props.selectedBooking;
		if (booking.table_ids === undefined) {
			booking['table_ids'] = booking.table.map((e) => e.id);
		}
		switch (type) {
			case BOOKINGTYPE.inhouse:
				if (props.selectedBooking.experience_id > 0) {
					handleUnmountExp();
					booking = {
						...booking,
						type: type,
						experience_id: null,
						experience_no_of_tickets: null,
					};
				} else {
					booking = {
						...booking,
						type: type,
					};
				}
				break;
			case BOOKINGTYPE.phone:
				if (props.selectedBooking.experience_id > 0) {
					handleUnmountExp();
					booking = {
						...booking,
						type: type,
						experience_id: null,
						experience_no_of_tickets: null,
					};
				} else {
					booking = {
						...booking,
						type: type,
					};
				}
				break;
			case BOOKINGTYPE.widget:
				if (props.selectedBooking.experience_id > 0) {
					handleUnmountExp();
					booking = {
						...booking,
						type: type,
						experience_id: null,
						experience_no_of_tickets: null,
					};
				} else {
					booking = {
						...booking,
						type: type,
					};
				}
				break;
			case BOOKINGTYPE.experience:
				handleOpenBookingExperience();
				break;
		}

		props.setSelectedBooking(booking);
		updateBooking(booking);
	};

	const updateSelectedExperience = (value) => {
		let booking = props.selectedBooking;
		if (expShiftId > 0) {
			const shift = props.shifts.filter((item) => item.id === expShiftId)[0];
			const startTime = shift.start_time;
			const endTime = shift.end_time;
			const sessionTime = shift.session_time;

			let ticketNo = parseInt(value);
			let nearestDate = getNearestAvailableDate(
				expStartDate,
				expEndDate,
				expDaysOfWeek,
				expRecurringType,
				expRecurringValue,
			);

			console.log(nearestDate);
			let filteredShifts = filterShifts(props.shifts, moment(nearestDate).isoWeekday());
			let nearestDateShift = filteredShifts.filter((item) => item.id === expShiftId);
			let expData = props.experienceList.filter((item) => item.id === expId);

			if (nearestDateShift.length > 0) {
				// let newDateDaysOfWeek = moment(nearestDate).isoWeekday();
				// let filterShiftsDaysOfWeek = [filteredShifts[0].day_of_week];
				// let test = filterShiftsDaysOfWeek.includes(newDateDaysOfWeek);
				if (nearestDateShift[0].id === expShiftId) {
					if (booking.table_ids === undefined) {
						booking['table_ids'] = booking.table.map((e) => e.id);
					}
					if (expId === booking.experience_id) {
						if (ticketNo > 0) {
							booking = {
								...booking,
								experience_no_of_tickets: booking.experience_no_of_tickets + ticketNo,
							};
						} else {
							booking = {
								...booking,
								experience_no_of_tickets: booking.experience_no_of_tickets,
							};
						}
					} else {
						if (ticketNo > 0) {
							if (booking.experience_no_of_tickets === 0) {
								booking = {
									...booking,
									type: 3,
									status: BOOKINGSTATUS.upcoming,
									shift_id: expShiftId,
									experience_id: expId,
									experience_no_of_tickets: ticketNo,
									start_time: startTime,
									end_time: startTime + sessionTime,
									start_date: updateDateTime(nearestDate, booking.start_time),
									end_date: updateDateTime(nearestDate, endTime + sessionTime),
								};
							} else {
								booking = {
									...booking,
									status: BOOKINGSTATUS.upcoming,
									shift_id: expShiftId,
									experience_id: expId,
									experience_no_of_tickets: booking.experience_no_of_tickets + ticketNo,
									start_time: startTime,
									end_time: startTime + sessionTime,
									start_date: updateDateTime(nearestDate, startTime),
									end_date: updateDateTime(nearestDate, endTime + sessionTime),
								};
							}
						} else {
							booking = {
								...booking,
								status: BOOKINGSTATUS.upcoming,
								shift_id: expShiftId,
								experience_id: expId,
								experience_no_of_tickets: booking.experience_no_of_tickets,
								start_time: startTime,
								end_time: startTime + sessionTime,
								start_date: updateDateTime(nearestDate, startTime),
								end_date: updateDateTime(nearestDate, endTime + sessionTime),
							};
						}
						timeListHandler(nearestDate);
					}
					props.setSelectedBooking(booking);
					updateBooking(booking);
				}
			} else {
				setExpName(expData[0].exp_name);
				setHideShiftAlert(true);
				if (booking.experience_id > 0) {
					handleUnmountExp();
					expGetAssign(booking.experience_id);
				} else {
					handleUnmountExp();
				}
			}
		}
	};

	const selectedTableOption = (option) => {
		let booking = props.selectedBooking;
		if (booking.table_ids === undefined) {
			booking['table_ids'] = booking.table.map((e) => e.id);
		}

		switch (option) {
			case 'Lock booking table':
				booking = { ...booking, table_lock: 1 };
				break;
			case 'Unlock booking table':
				booking = { ...booking, table_lock: 0 };
				break;
			case 'Change table':
				console.log('Do change table here');
				break;
		}

		if (option !== 'Change table') {
			props.setSelectedBooking(booking);
			updateBooking(booking);
		}
	};

	const statusBtnOnClickHandler = (item) => {
		let booking = props.selectedBooking;
		booking = { ...booking, status: item.value };

		if (booking.table_ids === undefined) {
			booking['table_ids'] = booking.table.map((e) => e.id);
		}

		props.setSelectedBooking(booking);
		updateBooking(booking);
	};

	const updateBooking = async (booking) => {
		try {
			const response = await axios({
				url: `${apiUrl}/api/booking/update`,
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${props.token}`,
				},
				data: booking,
			});

			if (response) {
				const { status } = response;
				const { data } = response.data;
				if (status === 200) {
					props.saveBookingHandler();
				}
			}
		} catch (err) {}
	};

	// useEffect(() => {
	// 	console.log(
	// 		'DAYS OF WEEK:' + expDaysOfWeek,
	// 		'START DATE:' + expStartDate,
	// 		'END DATE:' + expEndDate,
	// 		'RECURRING TYPE:' + expRecurringType,
	// 		'RECURRING VALUE' + expRecurringValue,
	// 		'SHIFT ID' + expShiftId,
	// 	);
	// }, [expShiftId]);

	const handleCloseReservationDetails = () => {
		props.setSelectedBooking(null);
		setExpHandler(false);
		setExpDaysOfWeek([]);
		setExpStartDate('');
		setExpEndDate('');
		setExpRecurringType(-2);
		setExpRecurringValue(-1);
		setExpShiftId(0);
	};

	const handleUnmountExp = () => {
		setExpHandler(false);
		setExpDaysOfWeek([]);
		setExpStartDate('');
		setExpEndDate('');
		setExpRecurringType(-2);
		setExpRecurringValue(-1);
		setExpShiftId(0);
		setExpId(0);
		// setExpStatusHandler(0);
		// setExpNoOfTickets(0);
	};

	const handleCloseShiftAlert = () => {
		setHideShiftAlert(!hideShiftAlert);
	};

	const handleOpenBookingExperience = () => {
		setBookingExperience(true);
	};

	const handleCloseBookingExperience = () => {
		if (bookingExperience) {
			setBookingExperience(false);
		}
	};

	const handleTicketQuantitySubmit = (value) => {
		setTicketQuantityOpen(false);
		updateSelectedExperience(value);
	};

	const handleOpenSelectBookingType = () => {
		setSelectBookingOpen(true);
	};

	const disabledDays = (date) => {
		return getDisabledDays(
			date,
			expEndDate,
			expStartDate,
			expDaysOfWeek,
			expRecurringType,
			expRecurringValue,
			expHandler,
		);
	};

	const timeListHandler = (date) => {
		const filteredShiftsData = filterShifts(props.shifts, moment(date).isoWeekday());
		if (expShiftId > 0) {
			setTimeList(
				getAllTime(
					filteredShiftsData.find((e) => e.id == expShiftId),
					moment(date).format('yyyy-MM-DD'),
				),
			);
		} else {
			setTimeList(getAllTime(filteredShiftsData, moment(date).format('yyyy-MM-DD')));
		}
	};

	const experienceData = props.experienceList.filter(
		(item) => item.id === props.selectedBooking.experience_id,
	);

	const expGetAssign = async (id) => {
		setExpId(id);
		try {
			const response = await axios({
				url: `${apiUrl}/api/experience/get_assign`,
				method: 'POST',
				data: { experience_id: id },
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${props.token}`,
				},
			});

			if (response) {
				const { status } = response;
				const { data } = response.data;
				if (status === 200) {
					if (data.connection === null) {
						setdialogAlertMessage({
							title: 'Missing Details',
							body: (
								<div>
									Please enter details for 'No Shift ID <br />
									Experience' experience. (eg: Start Date, <br /> Shift,.. etc)
								</div>
							),
						});
						setAlertHandler(true);
					} else {
						// setExpStatusHandler(status);
						setExpDaysOfWeek(data.connection.day_of_week);
						setExpStartDate(data.connection.start_date);
						setExpEndDate(data.connection.end_date);
						setExpRecurringType(data.connection.recurring_type);
						setExpRecurringValue(data.connection.recurring_value);
						setExpShiftId(data.connection.shift_id);
						setExpHandler(true);
						// setExpStatusHandler(0);
					}
				}
			}
		} catch (err) {
			console.log('Error: ', err);
		}
	};

	useEffect(() => {
		if (guest && isSaving) {
			setIsSaving(false);
			let booking = props.selectedBooking;
			booking = { ...booking, guest: guest };

			props.setSelectedBooking(booking);
			updateBooking(booking);
		}
	}, [guest]);

	useEffect(() => {
		handleUnmountExp();
		if (props.selectedBooking.experience_id > 0) {
			// setExpNoOfTickets(props.selectedBooking.experience_no_of_tickets);
			expGetAssign(props.selectedBooking.experience_id);
			timeListHandler(props.selectedBooking.start_date);
		} else {
			handleUnmountExp();
		}
	}, [props.selectedBooking]);

	useEffect(() => {
		timeListHandler(props.selectedBooking.start_date); //timeListHandler(props.selectedBooking.start_date)
	}, [expShiftId]); // expShiftId

	return (
		<div className="reservation-det-wrapper">
			<div className="row1">
				<div onClick={handleOpenEditName} style={{ cursor: 'pointer' }}>
					{getFullname(props.selectedBooking.guest)}
				</div>
				<EditNameModal
					openEditName={openEditName}
					handleCloseEditName={(e) => handleCloseEditName(e)}
					modalTitle={'EDIT NAME'}
					personIcon={personIcon}
					guest={guest}
				/>
				<AlertDialog
					alertHandler={alertHandler}
					dialogAlertMessage={dialogAlertMessage}
					setAlertHandler={setAlertHandler}
				/>

				{props.selectedBooking.experience_id > 0 ? (
					<div
						onClick={handleOpenBookingExperience}
						style={{
							fontSize: '.7em',
							fontWeight: '400',
							position: 'absolute',
							right: 80,
							cursor: 'pointer',
						}}
					>
						{props.selectedBooking.experience_no_of_tickets}x {experienceData[0].exp_name}
					</div>
				) : (
					''
				)}
				{hideShiftAlert == true ? (
					<SweetAlert
						danger
						style={{ color: 'black', fontWeight: 'bold' }}
						title="Oops..."
						onConfirm={(e) => handleCloseShiftAlert()}
					>
						<span style={{ color: 'red' }}>"No shift available </span> for{' '}
						<span style={{ fontWeight: 'bold' }}>"{expName}"</span> Please select another experience
						or <span style={{ color: 'red' }}>edit shift for </span>
						<span style={{ fontWeight: 'bold' }}>"{expName}"</span>{' '}
						<span style={{ color: 'red' }}> on settings"</span>
					</SweetAlert>
				) : (
					''
				)}

				<BookingExperience
					bookingExperience={bookingExperience}
					handleCloseBookingExperience={(e) => handleCloseBookingExperience(e)}
					setTicketQuantityOpen={setTicketQuantityOpen}
					experienceList={props.experienceList}
					expGetAssign={expGetAssign}
				/>
				<TicketQuantity
					ticketQuantityOpen={ticketQuantityOpen}
					setTicketQuantityOpen={setTicketQuantityOpen}
					experienceData={props.experienceList.filter((item) => item.id === expId)[0]}
					// setExpNoOfTickets={setExpNoOfTickets}
					handleTicketQuantitySubmit={handleTicketQuantitySubmit}
				/>

				<div className="exit-btn-wrapper">
					<img
						src={exitBtnIcon}
						width="12"
						height="12"
						alt="checker"
						onClick={() => handleCloseReservationDetails()}
					/>
				</div>
			</div>
			<div className="row2">
				<div className="buttonWrapper">
					<img src={btnTimelineIcon} width="20" height="20" alt="checker" />
					<span className="header-text" onClick={() => handleOpenEditTimeBooking()}>
						{getTimeStr(props.selectedBooking.start_time)}
					</span>
					<EditBookingTimeModal
						bookingTimeOpen={bookingTimeOpen}
						setBookingTimeOpen={setBookingTimeOpen}
						selectedTimeHandler={selectedTimeHandler}
						timeList={timeList}
						modalTitle={'EDIT BOOKING TIME'}
						btnTimelineIcon={btnTimelineIcon}
					/>
				</div>
				<div className="buttonWrapper">
					<img src={sectionPartyIcon} width="20" height="20" alt="checker" />
					<span className="header-text" onClick={handleOpenEditPartySize}>{`${
						props.selectedBooking.party_size
					} ${props.selectedBooking.party_size > 1 ? 'guests' : 'guest'}`}</span>
					<EditPartySizeTableModal
						partySizeOpen={partySizeOpen}
						setPartySizeOpen={setPartySizeOpen}
						handleCloseEditPartySize={(e) => handleCloseEditPartySize(e)}
						editPartySizeTableHandle={editPartySizeTableHandle}
						modalTitle={'EDIT PARTY SIZE/TABLE'}
						sectionPartyIcon={sectionPartyIcon}
						floor={props.floor}
						selectedTables={props.selectedBooking.table}
						startDate={props.selectedBooking.start_date}
						partySize={props.selectedBooking.party_size}
						startTime={props.selectedBooking.start_time}
						token={props.token}
					/>
				</div>
				<div className="buttonWrapper">
					<img
						src={
							props.selectedBooking.status === BOOKINGSTATUS.overTime ? overtimeIcon : sessionIcon
						}
						width="20"
						height="20"
						alt="checker"
					/>
					<span
						className="header-text"
						style={
							props.selectedBooking.status === BOOKINGSTATUS.overTime
								? { color: getBookingStatusColor(props.selectedBooking.status) }
								: { color: 'white' }
						}
					>
						{getRemainingTime(
							props.selectedBooking.end_time,
							props.selectedBooking.status,
							Boolean(props.selectedBooking.no_limit),
						)}
					</span>
					{/* <EditBookingModal
						bookingTimeOpen={bookingTimeOpen}
						handleCloseEditTimeBooking={(e) => handleCloseEditTimeBooking(e)}
					/> */}
				</div>
				<div className="buttonWrapper" onClick={() => handleSetOpenCalendar()}>
					<img
						style={{ marginRight: 10 }}
						src={calendarIcon}
						width="20"
						height="20"
						alt="checker"
					/>
					<span>{moment(props.selectedBooking.start_date).format('MMMM Do, yyyy')}</span>
					<SelectBookingCalendar
						selectedDate={props.selectedBooking.start_date}
						openBookingCalendar={openCalendar}
						setSelectedDateHandler={updateSelectedDate}
						handleCloseSelectedBookingCalendar={setOpenCalendar}
						disabledDays={disabledDays}
						setBookingDate={props.setBookingDate}
					/>
				</div>

				<div className="buttonWrapper4">
					<img src={phoneIcon} width="18" height="18" alt="checker" style={{ marginRight: 10 }} />
					{/* <span className="num-text">{}</span> */}
					<NumberFormat
						className="num-text"
						value={getGuestPhoneNum(guest)}
						displayType={'text'}
						format="#### #### ####"
					/>
				</div>
			</div>
			<div className="row3">
				<div className="dropdown-wrapper">
					<ReservationDropDown
						selectedOption={getBookingStatus(props.selectedBooking.status)}
						options={props.statusOption}
						hideLeftIcon={false}
						rightIcon={props.selectedBooking.status == 8 ? '' : btnDownArrowIcon}
						setSelectedOption={selectedStatusOption}
						selectionDisabled={props.selectedBooking.status == 8 ? true : false}
					/>

					<PartialPartySize
						partyPartySizeHandler={partyPartySizeHandler}
						openPartialPartySize={openPartialPartySize}
						handleCloseSelectedBookingCalendar={setOpenPartialPartySize}
					/>
				</div>
				<div className="dropdown-wrapper">
					{/* FOR TABLE DROPDOWN */}
					<ReservationDropDown
						selectedOption={
							props.selectedBooking.table.length > 1
								? `${props.selectedBooking.table.map((e) => e.name).join(',')}`
								: `${props.selectedBooking.table[0].name}`
						}
						hideLeftIcon={true}
						options={props.tableOptions}
						selectionDisabled={true}
						rightIcon={
							props.selectedBooking.table_lock
								? reservationLockTableIcon
								: props.selectedBooking.status == 8
								? ''
								: unlockTableIcon
						}
						setSelectedOption={selectedTableOption}
						bookingStatus={props.selectedBooking.status}
					/>
				</div>
				<div className="dropdown-wrapper">
					<ReservationButton
						onClickHandler={statusBtnOnClickHandler}
						selectedBookingStatus={getBookingStatusButton(props.selectedBooking.status)}
					/>
				</div>
			</div>
			<div className="content">
				<span>RESERVATION NOTES</span>
				<span className="row-notes">
					<h6>Notes</h6>
					<input
						onChange={(e) => handleOnChange(e.target.value, 1)}
						value={props.selectedBooking.reservation_note}
					></input>
				</span>
				<span>GUEST NOTES</span>
				<span className="row">
					<h6>General Notes</h6>
					<input
						onChange={(e) => handleOnChange(e.target.value, 2)}
						value={guest.general_note}
					></input>
				</span>
				<span className="row">
					<h6>Special Relationship</h6>
					<input
						onChange={(e) => handleOnChange(e.target.value, 3)}
						value={guest.special_relationship}
					></input>
				</span>
				<span className="row">
					<h6>Seating Preference</h6>
					<input
						onChange={(e) => handleOnChange(e.target.value, 4)}
						value={guest.seating_preference}
					></input>
				</span>
				<span className="row">
					<h6>{'Food & Drink Preference'}</h6>
					<input
						onChange={(e) => handleOnChange(e.target.value, 5)}
						value={guest.food_drink_preference}
					></input>
				</span>
				<div className="row4">
					<SelectBookingType
						selectBookingOpen={selectBookingOpen}
						handleCloseSelectBookingType={setSelectBookingOpen}
						updateSelectedBookingType={updateSelectedBookingType}
					/>

					<div
						onClick={handleOpenSelectBookingType}
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'flex-start',
							alignItems: 'center',
							cursor: 'pointer',
						}}
					>
						{props.selectedBooking.type == 2 ? (
							<img
								src={bookingStatusPhoneIcon}
								width="25"
								height="25"
								alt="checker"
								style={{ margin: '20px 10px 20px 5px' }}
							/>
						) : props.selectedBooking.type == 1 ? (
							<img
								src={bookingStatusWalkIn}
								width="25"
								height="25"
								alt="checker"
								style={{ margin: '20px 10px 20px 5px' }}
							/>
						) : props.selectedBooking.type == 0 ? (
							<img
								src={bookingStatusNetwork}
								width="25"
								height="25"
								alt="checker"
								style={{ margin: '20px 10px 20px 5px' }}
							/>
						) : props.selectedBooking.type == 3 ? (
							<img
								src={experienceIcon}
								width="25"
								height="25"
								alt="checker"
								style={{ margin: '20px 10px 20px 5px' }}
							/>
						) : (
							''
						)}

						{getBookingType(props.selectedBooking)}
					</div>
				</div>
				<div className="row5">
					<ToggleSwitch /> Exclude party from pacing limit
				</div>
				<p>Booking taken by {getBookingTakenFullname(props.selectedBooking.booking_taken)}</p>
			</div>
		</div>
	);
}
export default ReservationDetails;
