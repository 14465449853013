import React, { useState, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Media } from 'react-bootstrap';
import styled from 'styled-components';
import axios from 'axios';
import moment from 'moment';

import { useHistory } from 'react-router-dom';

import { apiUrl, filterShifts, getCurrentShift } from '../../../../utils/Utility';
import { ReportsNavData } from './ReportsNavData';
import { GRAPHDATA } from 'constants/enum';

import BookingNavBar from '../BookingNavBar/BookingNavBar';
import withDashboardApp from 'components/withDashboardApp';
import DonutGraph from './sub-components/DonutGraph';
import ReportsSegmentedControl from './sub-components/ReportsSegmentedControl';
import BookingCalendarArrow from '../BookingCalendarArrow/BookingCalendarArrow';
import BarGraph from './sub-components/BarGraph';
import DotLoader from '../TableLayoutPlanning/pages/SubComponents/DotLoader';
import ReportsCalendarModal from './sub-components/ReportsCalendarModal';
import AlertDialog from '../CreatBooking/booking-experience/AlertDialog';

const NavContainer = styled.div`
	display: flex;
	color: white;
	font-family: sans-serif;
	font-weight: 800;
	margin: 30px 0 0 0px;
	user-select: none;
`;

const NavUL = styled.ul`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	z-index: 2;
`;

const NavLI = styled.li`
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2;
`;

const NavButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 5px 5px 10px #1a2024, -5px -5px 10px #2a343a;
	border-radius: 5px;
	padding: 0 12px;
	height: 50px;
	width: ${(props) => (props.selected ? 200 : 50)}px;
	transition: 250ms;
	cursor: pointer;
	font-size: 15px;
	font-weight: bold;

	&:active {
		background: #1f262b;
		color: #ffffff;
		outline: none;
		outline-offset: none;
	}

	span {
		margin-left: 10px;
		white-space: nowrap;
		overflow: hidden;
	}
`;

const DateRangeButton = styled.button`
	margin-left: 15px;
	color: #ff8585;
	text-decoration: underline;
	user-select: none;
`;

const StyledLabel = styled.label`
	margin: 0 15px;
	color: #707070;
	font-size: 1em;
	font-weight: 550;
	user-select: none;
`;

const CoverageContainer = styled.div`
	box-shadow: 5px 5px 10px #1a2024, -5px -5px 10px #2a343a;
	margin-left: 15px;
	border-radius: 5px;
	background: #222a2f;
	height: 180px;
	width: 245px;
	user-select: none;

	#coverage-details {
		display: flex;
		flex-direction: column;
		padding: 15px;

		h2 {
			color: #ffffff;
			font-size: 36px;
			font-weight: bold;
			margin-bottom: 5px;
		}

		p {
			font-size: 14px;
			font-weight: 550;
			color: #bcbcbc;
			margin-bottom: 10px;
		}

		h3 {
			color: #ffffff;
			font-size: 24px;
			font-weight: bold;
			margin-bottom: 5px;
		}
	}
`;

const DonutContainer = styled(CoverageContainer)`
	position: relative;
	display: flex;
	align-items: center;
	margin: 0;
	width: 482px;
`;

const BookedByContainer = styled(CoverageContainer)`
	display: flex;
	flex-direction: row;
	margin: 0;
	height: 100%;
	width: 260px;

	#bookedBy-labels {
		display: flex;
		flex-direction: column;
		height: 100%;
		color: #bcbcbc;
		margin: 0 30px 0 20px;

		p {
			font-size: 14px;
			font-weight: 550;
			margin: 20px 0;
		}

		h4 {
			font-size: 14px;
			font-weight: bold;
			margin-bottom: 10px;
		}
	}
`;

const BookedByATCL = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	margin: 0 20px;

	p {
		font-size: 14px;
		font-weight: 550;
		color: #bcbcbc;
		margin: 20px 0;
	}

	h4 {
		font-size: 14px;
		font-weight: bold;
		color: #ffffff;
		margin-bottom: 10px;
	}
`;

const StyledAtcl = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;

	p {
		font-size: 14px;
		font-weight: 550;
		color: #bcbcbc;
		margin-top: 20px;
	}

	h4 {
		font-size: 16px;
		font-weight: bold;
		color: #ffffff;
		margin-top: 22px;
	}
`;

const LegendsContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0 45px;
	padding-top: 58px;
	user-select: none;

	span {
		display: flex;
		flex-direction: row;
		aling-items: center;
		margin: 10px 0;
	}

	h4 {
		font-size: 14px;
		font-weight: bold;
		color: #bcbcbc;
	}

	#point {
		height: 16px;
		width: 16px;
		border-radius: 50%;
		margin-right: 10px;
	}
`;

const StyledH3 = styled.h3`
	font-size: 18px;
	font-weight: 700;
	color: #ffffff;
	user-select: none;
	margin-left: 10px;
	margin-bottom: 5px;
`;

const BgDateShift = styled.div`
	display: flex;
	justify-content: center;
	color: white;
	font-weight: 500;
	padding-top: 3px;
	height: 30px;
	width: 193px;
	border-radius: 15px;
	background: #222a2f;
	box-shadow: inset 5px 5px 10px #1a2024, inset -5px -5px 10px #2a343a;
	user-select: none;

	label {
		cursor: pointer;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;

const StyledCol = styled(Col)`
	display: flex;
	justify-content: center;
	padding: 0;
	margin: 0 15px;
`;

const LegendsContainer2 = styled(LegendsContainer)`
	flex-direction: row;
	margin: 0;
	padding: 0;
	span {
		margin: 10px;
		align-items: center;
	}
`;

const DateContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	width: 275px;
`;

function Reports() {
	const history = useHistory();
	const reducers = useSelector((state) => state.userReducers);
	const [showNavbar, setShowNavbar] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isSelected, setIsSelected] = useState(ReportsNavData[0]);
	const [shifts, setshifts] = useState(history.location.state.find((e) => e.shifts).shifts);
	const [compareType, setCompareType] = useState(1);
	const [isCalendarOpen, setIsCalendarOpen] = useState(false);
	const [reports, setReports] = useState('');
	const [selectedGraph, setselectedGraph] = useState('1');
	const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));
	const [bookingRangeReports, setBookingRangeReports] = useState('');
	const [compareRangeReports, setCompareRangeReports] = useState('');
	const [dateFormat, setdateFormat] = useState({
		dateRangeButton: '',
		dateCoverage: '',
		bgDateShift: '',
	});
	const [currentShift, setcurrentShift] = useState(shifts[0]);
	const [holdDate, setholdDate] = useState('');
	const [alertHandler, setAlertHandler] = useState(false);
	const [dialogAlertMessage, setDialogAlertMessage] = useState('');
	const toggleBookingNavBar = () => setShowNavbar(!showNavbar);

	const toggleSelected = (item) => {
		if (item !== isSelected) {
			setIsSelected(item);
		}
	};

	const handleSelectDate = (date, action) => {
		if (isCalendarOpen) {
			setSelectedDate(date);
			setholdDate(date);
		}
		if (selectedGraph === GRAPHDATA.week) {
			setSelectedDate(date);
			setholdDate(date);
		}
		if (selectedGraph === GRAPHDATA.day) {
			if (isCalendarOpen) {
				setSelectedDate(date);
				setholdDate(date);
			} else {
				let day = moment(holdDate);
				let newDate = moment(date);
				let subtractedDate = moment(holdDate).subtract(6, 'days');
				if (newDate <= day && newDate >= subtractedDate) {
					setSelectedDate(date);
					// console.log('set date', date);
				} else {
					setDialogAlertMessage({
						title: 'Out of date range',
						body: 'Next date is out of selected date range',
					});
					setAlertHandler(true);
				}
			}
		}

		if (selectedGraph === GRAPHDATA.shift) {
			if (isCalendarOpen) {
				setSelectedDate(date);
				setholdDate(date);
			} else {
				const filteredShift = filterShifts(shifts, moment(holdDate).isoWeekday());
				// console.log((filteredShift > 0) | 1);
				if (filteredShift.length > 0) {
					let shift;
					let day = moment(holdDate);
					let newDate = moment(date);
					let subtractedDate = moment(holdDate).subtract(6, 'days');
					let idx = filteredShift.findIndex((e) => e.id === currentShift.id);
					let intDay = day.date();
					let intNewDate = newDate.date();
					let intSubtractedDate = subtractedDate.date();
					if (filteredShift.length === 1) {
						if (intNewDate <= intDay && intNewDate >= intSubtractedDate) {
							shift = filteredShift[0];
							setcurrentShift(shift);
							setSelectedDate(date);
						} else {
							if (action === 'prev') {
								setDialogAlertMessage({
									title: 'Out of date range',
									body: 'Previous date is out of selected date range',
								});
								setAlertHandler(true);
							}
							if (action === 'next') {
								setDialogAlertMessage({
									title: 'Out of date range',
									body: 'Next date is out of selected date range',
								});
								setAlertHandler(true);
							}
						}
					} else {
						if (action === 'prev') {
							if (intNewDate <= intDay && intNewDate >= intSubtractedDate) {
								if (idx === 0) {
									const newIdx = idx + 1;
									shift = filteredShift[newIdx];
									setcurrentShift(shift);
									setSelectedDate(date);
								} else {
									const newIdx = idx - 1;
									shift = filteredShift[newIdx];
									setcurrentShift(shift);
									let formats = { ...dateFormat };
									formats = {
										...formats,
										bgDateShift: `${moment(selectedDate).format('ddd DD MMM')} | ${shift.name}`,
									};
									setdateFormat(formats);
								}
							} else {
								if (idx === 0) {
									setDialogAlertMessage({
										title: 'Out of date range',
										body: 'Previous date is out of selected date range',
									});
									setAlertHandler(true);
								} else {
									const newIdx = idx - 1;
									shift = filteredShift[newIdx];
									setcurrentShift(shift);
									let formats = { ...dateFormat };
									formats = {
										...formats,
										bgDateShift: `${moment(selectedDate).format('ddd DD MMM')} | ${shift.name}`,
									};
									setdateFormat(formats);
								}
							}
						}
						if (action === 'next') {
							if (intNewDate <= intDay && intNewDate >= intSubtractedDate) {
								if (idx === 0) {
									const newIdx = idx + 1;
									shift = filteredShift[newIdx];
									setcurrentShift(shift);
									let formats = { ...dateFormat };
									formats = {
										...formats,
										bgDateShift: `${moment(selectedDate).format('ddd DD MMM')} | ${shift.name}`,
									};
									setdateFormat(formats);
								} else {
									const newIdx = idx - 1;
									shift = filteredShift[newIdx];
									setcurrentShift(shift);
									setSelectedDate(date);
								}
							} else {
								if (idx === 1) {
									setDialogAlertMessage({
										title: 'Out of date range',
										body: 'Next date is out of selected date range',
									});
									setAlertHandler(true);
								} else {
									const newIdx = idx + 1;
									shift = filteredShift[newIdx];
									setcurrentShift(shift);
									let formats = { ...dateFormat };
									formats = {
										...formats,
										bgDateShift: `${moment(selectedDate).format('ddd DD MMM')} | ${shift.name}`,
									};
									setdateFormat(formats);
								}
							}
						}
					}
				} else {
					let formats = { ...dateFormat };
					formats = {
						...formats,
						bgDateShift: `${moment(selectedDate).format('ddd DD MMM')} | No Shift`,
					};
					setdateFormat(formats);
					// setSelectedDate(date);
				}
			}
		}
	};

	const changeCompareType = (int) => {
		let params = {
			startDate: moment(selectedDate).subtract(6, 'days').format('YYYY-MM-DD'),
			compareType: int,
			endDate: selectedDate,
		};
		setIsLoading(true);
		setCompareType(int);
		getShiftOccupancy(params);
	};

	const toggleCalendar = () => {
		setIsCalendarOpen(!isCalendarOpen);
	};

	const selectGraphCallback = (selected) => {
		setselectedGraph(selected);
		if (selected === GRAPHDATA.week) {
			// setSelectedDate()
			setSelectedDate(holdDate);
		}
	};

	const checkDateFormat = (type, date) => {
		let formats = dateFormat;
		switch (type) {
			case GRAPHDATA.week:
				formats = {
					...formats,
					dateRangeButton: `${moment(date).subtract(6, 'days').format('DD MMM YYYY')} -
						${moment(selectedDate).format('DD MMM YYYY')}`,
					dateCoverage: `${moment(date).subtract(6, 'days').format('DD MMM')} - ${moment(
						date,
					).format('DD MMM')} cover`,
					bgDateShift: `${moment(date).subtract(6, 'days').format('DD MMM')} - ${moment(
						date,
					).format('DD MMM')}`,
				};
				setdateFormat(formats);
				break;
			case GRAPHDATA.day:
				formats = {
					...formats,
					dateRangeButton: `${moment(holdDate).subtract(6, 'days').format('DD MMM YYYY')} -
						${moment(holdDate).format('DD MMM YYYY')}`,
					dateCoverage: `${moment(holdDate).subtract(6, 'days').format('DD MMM')} - ${moment(
						holdDate,
					).format('DD MMM')} cover`,
					bgDateShift: `${moment(date).format('dddd, DD MMM')}`,
				};
				setdateFormat(formats);
				break;
			case GRAPHDATA.shift:
				if (currentShift !== undefined) {
					const filteredShift = filterShifts(shifts, moment(holdDate).isoWeekday());
					if (filteredShift.length > 0) {
						// console.log('yes');
						formats = {
							...formats,
							dateRangeButton: `${moment(holdDate).subtract(6, 'days').format('DD MMM YYYY')} -
							${moment(holdDate).format('DD MMM YYYY')}`,
							dateCoverage: `${moment(holdDate).subtract(6, 'days').format('DD MMM')} - ${moment(
								holdDate,
							).format('DD MMM')} cover`,
							bgDateShift: `${moment(date).format('ddd DD MMM')} | ${currentShift.name}`,
						};
					} else {
						formats = {
							...formats,
							dateRangeButton: `${moment(holdDate).subtract(6, 'days').format('DD MMM YYYY')} -
								${moment(holdDate).format('DD MMM YYYY')}`,
							dateCoverage: `${moment(holdDate).subtract(6, 'days').format('DD MMM')} - ${moment(
								holdDate,
							).format('DD MMM')} cover`,
							bgDateShift: `${moment(date).format('ddd DD MMM')} | No Shift`,
						};
					}
				}
				setdateFormat(formats);
				break;
		}
	};

	const getShiftOccupancy = async (params) => {
		try {
			const response = await axios({
				url: `${apiUrl}/api/reports/shiftOccupancy`,
				method: 'POST',
				data: params,
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${reducers.token}`,
				},
			});

			if (response) {
				const { status } = response;
				const { data } = response.data;
				if (status === 200) {
					setReports(data);
					setBookingRangeReports(data.bookingRangeReports);
					setCompareRangeReports(data.compareRangeReports);
					setIsLoading(false);
				}
			}
		} catch (err) {
			console.log('Error: ', err);
		}
	};
	useEffect(() => {
		let params = {
			startDate: moment(selectedDate).subtract(6, 'days').format('YYYY-MM-DD'),
			compareType: 1,
			endDate: selectedDate,
		};
		setholdDate(selectedDate);
		setIsLoading(true);
		getShiftOccupancy(params);
	}, [holdDate]);

	useEffect(() => {
		checkDateFormat(selectedGraph, selectedDate);
	}, [selectedGraph, selectedDate]);

	return (
		<Fragment>
			<Container style={{ background: 'transparent' }} fluid>
				<BookingNavBar
					showNavbar={showNavbar}
					showNavbarHandler={setShowNavbar}
					data={history.location.state}
				/>
				{isLoading ? <DotLoader /> : ''}
				{isCalendarOpen ? (
					<ReportsCalendarModal
						selectedDate={holdDate}
						toggleCalendar={toggleCalendar}
						handleSelectDate={handleSelectDate}
					/>
				) : (
					''
				)}
				<AlertDialog
					alertHandler={alertHandler}
					dialogAlertMessage={dialogAlertMessage}
					setAlertHandler={setAlertHandler}
				/>

				<Row style={{ marginRight: 0, marginLeft: 0, justifyContent: 'space-between' }}>
					<Col
						className="menu-btn-container"
						style={{ marginTop: 30, marginBottom: 30 }}
						sm={1}
						md={1}
						lg={1}
					>
						<div
							style={{ marginLeft: 10 }}
							className="btn-menu btn-menu--margin-top"
							onClick={toggleBookingNavBar}
						>
							<Media className="list-db" as="li">
								<svg
									style={{ marginTop: 12, marginLeft: 11, marginRight: 11 }}
									xmlns="http://www.w3.org/2000/svg"
									width="18"
									height="15"
									viewBox="0 0 18 15"
								>
									<g id="Group_347" data-name="Group 347" transform="translate(-0.333)">
										<rect
											id="Rectangle_42"
											data-name="Rectangle 42"
											width="18"
											height="3"
											rx="1.5"
											transform="translate(0.333)"
											fill="#fff"
										/>
										<rect
											id="Rectangle_43"
											data-name="Rectangle 43"
											width="18"
											height="3"
											rx="1.5"
											transform="translate(0.333 6)"
											fill="#fff"
										/>
										<rect
											id="Rectangle_44"
											data-name="Rectangle 44"
											width="18"
											height="3"
											rx="1.5"
											transform="translate(0.333 12)"
											fill="#fff"
										/>
									</g>
								</svg>
							</Media>
						</div>
					</Col>
					<Col sm={7} md={7} lg={7} style={{ padding: 0, margin: 0 }}>
						<NavContainer>
							{ReportsNavData.map((item, key) => {
								return (
									<NavUL key={key}>
										<NavLI onClick={() => toggleSelected(item)}>
											<NavButtonWrapper selected={isSelected === item ? true : false}>
												{item.icon}
												{isSelected === item ? <span>{item.title}</span> : ''}
											</NavButtonWrapper>
										</NavLI>
									</NavUL>
								);
							})}
						</NavContainer>
					</Col>
					<Col sm={4} md={4} lg={4}>
						{/* <div className="tlp-saveIcon-wrapper"></div> */}
					</Col>
				</Row>
				<Row style={{ marginRight: 0, marginLeft: 0, justifyContent: 'space-between' }}>
					<Col>
						<DateRangeButton onClick={toggleCalendar}>{dateFormat.dateRangeButton}</DateRangeButton>
						<StyledLabel>Compare to</StyledLabel>
						{compareType === 1 ? (
							<DateRangeButton onClick={() => changeCompareType(2)} style={{ margin: 0 }}>
								Last 4 weeks
							</DateRangeButton>
						) : (
							<DateRangeButton onClick={() => changeCompareType(1)} style={{ margin: 0 }}>
								This week last year
							</DateRangeButton>
						)}
					</Col>
				</Row>
				<Row
					style={{ marginTop: 30, marginRight: 0, marginLeft: 0, justifyContent: 'space-between' }}
				>
					<StyledCol sm={3} md={3} lg={3} style={{ padding: 0, margin: 0 }}>
						<CoverageContainer>
							<div id="coverage-details">
								{bookingRangeReports ? <h2>{bookingRangeReports.totalCovers}</h2> : <h2> - </h2>}
								<p>{dateFormat.dateCoverage}</p>
								{compareRangeReports ? <h3>{compareRangeReports.totalCovers}</h3> : <h2>-</h2>}
								{compareType === 1 ? (
									<p>Last 4 weeks average covers</p>
								) : (
									<p>This week last year average covers</p>
								)}
							</div>
						</CoverageContainer>
					</StyledCol>
					<StyledCol sm={6} md={6} lg={6} style={{ padding: 0, margin: 0 }}>
						<DonutContainer>
							<DonutGraph
								bookingRangeReports={bookingRangeReports}
								// reportsBooking={bookingRangeReports.booking}
								// reportsWalkIns={bookingRangeReports.walkIns}
								selectedDate={selectedDate}
								isLoading={isLoading}
							/>
							<LegendsContainer>
								<span>
									<div id="point" style={{ background: '#C76467' }} /> <h4>BOOKING</h4>
								</span>
								<span>
									<div id="point" style={{ background: '#FF778D' }} /> <h4>WALK-INS</h4>
								</span>
							</LegendsContainer>
							<StyledAtcl>
								<p>ATCL</p>
								{bookingRangeReports.booking !== undefined ? (
									<h4>{bookingRangeReports.booking.actual}</h4>
								) : (
									<h4>-</h4>
								)}
								{bookingRangeReports.booking !== undefined ? (
									<h4>{bookingRangeReports.walkIns.actual}</h4>
								) : (
									<h4>-</h4>
								)}
							</StyledAtcl>
							<StyledAtcl style={{ marginLeft: 40 }}>
								<p>AVG</p>
								{bookingRangeReports.booking !== undefined ? (
									<h4>{bookingRangeReports.booking.avg}</h4>
								) : (
									<h4>-</h4>
								)}
								{bookingRangeReports.booking !== undefined ? (
									<h4>{bookingRangeReports.walkIns.avg}</h4>
								) : (
									<h4>-</h4>
								)}
							</StyledAtcl>
						</DonutContainer>
					</StyledCol>
					<StyledCol sm={3} md={3} lg={3} style={{ padding: 0, margin: 0 }}>
						<BookedByContainer>
							<div id="bookedBy-labels">
								<p>Booked by</p>
								<h4>WABI</h4>
								<h4>PHONE</h4>
								<h4>IN-HOUSE</h4>
								{/* <h4>NETWORK</h4> */}
							</div>
							{bookingRangeReports.bookedBy !== undefined ? (
								<BookedByATCL>
									<p>ATCL</p>
									<h4>{bookingRangeReports.bookedBy.wabi.actual}</h4>
									<h4>{bookingRangeReports.bookedBy.phone.actual}</h4>
									<h4>{bookingRangeReports.bookedBy.inHouse.actual}</h4>
									{/* <h4>{bookingRangeReports.bookedBy.network.actual}</h4> */}
								</BookedByATCL>
							) : (
								<BookedByATCL>
									<p>ATCL</p>
									<h4>-</h4>
									<h4>-</h4>
									<h4>-</h4>
									{/* <h4>{bookingRangeReports.bookedBy.network.actual}</h4> */}
								</BookedByATCL>
							)}

							{bookingRangeReports.bookedBy !== undefined ? (
								<BookedByATCL>
									<p>AVG</p>
									<h4>{bookingRangeReports.bookedBy.wabi.avg}</h4>
									<h4>{bookingRangeReports.bookedBy.phone.avg}</h4>
									<h4>{bookingRangeReports.bookedBy.inHouse.avg}</h4>
									{/* <h4>{bookingRangeReports.bookedBy.network.avg}</h4> */}
								</BookedByATCL>
							) : (
								<BookedByATCL>
									<p>AVG</p>
									<h4>-</h4>
									<h4>-</h4>
									<h4>-</h4>
									{/* <h4>{bookingRangeReports.bookedBy.network.avg}</h4> */}
								</BookedByATCL>
							)}
						</BookedByContainer>
					</StyledCol>
				</Row>
				<Row style={{ marginRight: 0, marginLeft: 0, margin: '20px 0 5px 0' }}>
					<Col style={{ padding: 0, margin: '0 15px' }}>
						<StyledH3>Booking V.S Walk-ins</StyledH3>
					</Col>
				</Row>
				<Row style={{ marginRight: 0, marginLeft: 0 }}>
					<StyledCol
						sm={3}
						md={3}
						lg={3}
						style={{ paddingLeft: 20, margin: 0, justifyContent: 'start' }}
					>
						<ReportsSegmentedControl
							width={280}
							height={40}
							labelHeight={40}
							labelWidth={93}
							optionCount={3}
							labelContents={['Week', 'Day', 'Shift']}
							selectGraphCallback={selectGraphCallback}
							selectedInit={selectedGraph}
						/>
					</StyledCol>
					<StyledCol
						sm={6}
						md={6}
						lg={6}
						style={{ justifyContent: 'space-evenly', alignItems: 'center', margin: 0 }}
					>
						<DateContainer>
							<BookingCalendarArrow
								arrowType="left"
								selectedDate={selectedDate}
								setSelectedDate={handleSelectDate}
							/>
							<BgDateShift onClick={toggleCalendar}>
								<label>{dateFormat.bgDateShift}</label>
							</BgDateShift>
							<BookingCalendarArrow
								arrowType="right"
								selectedDate={selectedDate}
								setSelectedDate={handleSelectDate}
							/>
						</DateContainer>
					</StyledCol>
					<StyledCol
						sm={3}
						md={3}
						lg={3}
						style={{ padding: 0, margin: 0, justifyContent: 'space-evenly' }}
					>
						<LegendsContainer2>
							<span>
								<div id="point" style={{ background: '#C76467' }} /> <h4>Booking</h4>
							</span>
							<span>
								<div id="point" style={{ background: '#FF778D' }} /> <h4>Walk-ins</h4>
							</span>
						</LegendsContainer2>
					</StyledCol>
				</Row>
				<Row style={{ margin: 0, padding: 20, justifyContent: 'center' }}>
					<BarGraph
						selectedDate={selectedDate}
						reports={reports}
						selectedGraph={selectedGraph}
						compareType={compareType}
						loaderHandler={setIsLoading}
						shifts={shifts}
						currentShift={currentShift}
					/>
				</Row>
			</Container>
		</Fragment>
	);
}

export default withDashboardApp(Reports);
