import moment from 'moment';
import {
	BOOKINGSTATUS,
	BOOKINGTYPE,
	TABLETYPE,
	RECURRINGTYPE,
	RECURRINGVALUE,
} from '../constants/enum';

// Table images
import singleTable from '../assets/images/booking/tables/single-table.png';
import twoSingleTable from '../assets/images/booking/tables/two-single-table.png';
import threeSingleTable from '../assets/images/booking/tables/three-single-table.png';
import singlePairTable from '../assets/images/booking/tables/single-pair-table.png';
import twoSinglePairTable from '../assets/images/booking/tables/two-single-pair-table.png';
import threeSinglePairTable from '../assets/images/booking/tables/three-single-pair-table.png';
import fourSinglePairTable from '../assets/images/booking/tables/four-single-pair-table.png';
import fourPersonSingleTable from '../assets/images/booking/tables/four-person-single-table.png';
import fourPersonRoundTable from '../assets/images/booking/tables/four-person-round-table.png';
import sixPersonRoundTable from '../assets/images/booking/tables/six-person-round-table.png';
import eightPersonRoundTable from '../assets/images/booking/tables/eight-person-round-table.png';
import tenPersonRoundTable from '../assets/images/booking/tables/ten-person-round-table.png';
import halfSeatRoundTable from '../assets/images/booking/tables/half-seat-round-table.png';
import twoPersonRoundTable from '../assets/images/booking/tables/two-person-round-table.png';

// Booking Status Icons
import bookingStatusBilled from '../assets/images/booking/booking-details/booking-status-billed-icon.png';
import bookingStatusCancelled from '../assets/images/booking/booking-details/booking-status-cancelled-icon.png';
import bookingStatusLate from '../assets/images/booking/booking-details/booking-status-late-icon.png';
import bookingStatusNoShow from '../assets/images/booking/booking-details/booking-status-no-show-icon.png';
import bookingStatusPartialSeated from '../assets/images/booking/booking-details/booking-status-partial-seated-icon.png';
import bookingStatusSeated from '../assets/images/booking/booking-details/booking-status-seated-icon.png';
import bookingStatusUnseat from '../assets/images/booking/booking-details/booking-status-unseat-icon.png';
import bookingStatusFinished from '../assets/images/booking/booking-details/booking-status-finished-icon.png';
import { capitalize, each, filter, forEach, isArray } from 'lodash';
import { date } from 'yup';

export const reactNode = process.env.REACT_APP_NODE;
export const apiUrl = process.env.REACT_APP_API_URL; //'https://staging-api.wabify.com'; // process.env.REACT_APP_API_URL; // 'https://qa-api.wabify.com'; 'https://staging.api.wabify.com'
export const stripeKey = process.env.REACT_APP_STRIPE_KEY; // 'pk_test_51HkK5eKgSTe7cdQ2pLYOU2VrSH8VyKIKqPxcxnzRcFlXsB21yMP6q122IeNpnIapVluwZlJ4NsDmGRe5qFUvNN08003E2TQHyo';

export const imgUrl = process.env.REACT_APP_IMG_URL; //'https://wabi-storage-staging.s3.ap-southeast-2.amazonaws.com/';

const _radToDeg = (rad) => {
	let deg = Math.ceil((rad * 180) / Math.PI);
	if (deg >= 360) {
		deg = deg - 360;
	}
	return deg;
};

export const buildRequestHeader = (api, params, token) => {
	return {
		url: `${apiUrl}/api/${api}`,
		method: 'POST',
		data: params,
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	};
};

export const getBookingTableOptions = (table_lock) => {
	if (table_lock) {
		return ['Unlock booking table', 'Change table'];
	} else {
		return ['Lock booking table', 'Change table'];
	}
};

export const getBookingStatusButton = (status) => {
	switch (status) {
		case BOOKINGSTATUS.waitList:
		case BOOKINGSTATUS.upcoming:
		case BOOKINGSTATUS.all:
		case BOOKINGSTATUS.late:
		case BOOKINGSTATUS.partiallySeated:
			return {
				title: 'Seat',
				value: BOOKINGSTATUS.seated,
			};
		case BOOKINGSTATUS.finished:
		case BOOKINGSTATUS.cancelled:
		case BOOKINGSTATUS.noShow:
			return {
				title: 'Revert',
				value: BOOKINGSTATUS.upcoming,
			};
		default:
			return {
				title: 'Finished',
				value: BOOKINGSTATUS.finished,
			};
	}
};

export const getBookingStatus = (status) => {
	switch (status) {
		case BOOKINGSTATUS.upcoming:
			return {
				name: 'Upcoming',
				icon: bookingStatusUnseat,
				value: BOOKINGSTATUS.upcoming,
			};
		case BOOKINGSTATUS.partiallySeated:
			return {
				name: 'Partial Seated',
				icon: bookingStatusPartialSeated,
				value: BOOKINGSTATUS.partiallySeated,
			};
		case BOOKINGSTATUS.late:
			return {
				name: 'Late',
				icon: bookingStatusLate,
				value: BOOKINGSTATUS.late,
			};
		case BOOKINGSTATUS.noShow:
			return {
				name: 'No Show',
				icon: bookingStatusNoShow,
				value: BOOKINGSTATUS.noShow,
			};
		case BOOKINGSTATUS.cancelled:
			return {
				name: 'Cancelled',
				icon: bookingStatusCancelled,
				value: BOOKINGSTATUS.cancelled,
			};
		case BOOKINGSTATUS.unconfirmed:
			return {
				name: 'Unconfirmed',
				icon: bookingStatusUnseat,
				value: BOOKINGSTATUS.unconfirmed,
			};
		case BOOKINGSTATUS.overTime:
			return {
				name: 'Overtime',
				icon: bookingStatusLate,
				value: BOOKINGSTATUS.overTime,
			};
		case BOOKINGSTATUS.seated:
			return {
				name: 'Seated',
				icon: bookingStatusSeated,
				value: BOOKINGSTATUS.seated,
			};
		case BOOKINGSTATUS.unseat:
			return {
				name: 'Unseat',
				icon: bookingStatusUnseat,
				value: BOOKINGSTATUS.unseat,
			};
		case BOOKINGSTATUS.billed:
			return {
				name: 'Billed',
				icon: bookingStatusBilled,
				value: BOOKINGSTATUS.billed,
			};
		case BOOKINGSTATUS.unbill:
			return {
				name: 'Unbilled',
				icon: bookingStatusUnseat,
				value: BOOKINGSTATUS.unbill,
			};
		case BOOKINGSTATUS.finished:
			return {
				name: 'Finished',
				icon: bookingStatusFinished,
				value: BOOKINGSTATUS.finished,
			};
	}
};

export const getBookingStatusOptions = (status) => {
	switch (status) {
		case BOOKINGSTATUS.upcoming:
			return [
				{
					name: 'Partial Seat',
					icon: bookingStatusPartialSeated,
					value: BOOKINGSTATUS.partiallySeated,
				},
				{
					name: 'Late',
					icon: bookingStatusLate,
					value: BOOKINGSTATUS.late,
				},
				{
					name: 'No Show',
					icon: bookingStatusNoShow,
					value: BOOKINGSTATUS.noShow,
				},
				{
					name: 'Cancelled',
					icon: bookingStatusCancelled,
					value: BOOKINGSTATUS.cancelled,
				},
			];
		case BOOKINGSTATUS.late:
			return [
				{
					name: 'Partial Seat',
					icon: bookingStatusPartialSeated,
					value: BOOKINGSTATUS.partiallySeated,
				},
				{
					name: 'No Show',
					icon: bookingStatusNoShow,
					value: BOOKINGSTATUS.noShow,
				},
				{
					name: 'Cancelled',
					icon: bookingStatusCancelled,
					value: BOOKINGSTATUS.cancelled,
				},
			];
		case BOOKINGSTATUS.needAttention:
			return [
				{
					name: 'Late',
					icon: bookingStatusLate,
					value: BOOKINGSTATUS.late,
				},
				{
					name: 'No Show',
					icon: bookingStatusNoShow,
					value: BOOKINGSTATUS.noShow,
				},
				{
					name: 'Cancelled',
					icon: bookingStatusCancelled,
					value: BOOKINGSTATUS.cancelled,
				},
			];
		case BOOKINGSTATUS.unconfirmed:
			return [
				{
					name: 'Cancel',
					icon: bookingStatusCancelled,
					value: BOOKINGSTATUS.cancelled,
				},
				{
					name: 'Confirm',
					icon: bookingStatusNoShow,
					value: BOOKINGSTATUS.upcoming,
				},
			];
		case BOOKINGSTATUS.overtime:
			return [
				{
					name: 'Billed',
					icon: bookingStatusBilled,
					value: BOOKINGSTATUS.billed,
				},
				{
					name: 'Unseat',
					icon: bookingStatusUnseat,
					value: BOOKINGSTATUS.upcoming,
				},
			];
		case BOOKINGSTATUS.billed:
			return [
				{
					name: 'Unbill',
					icon: bookingStatusUnseat,
					value: BOOKINGSTATUS.unbill,
				},
			];
		case BOOKINGSTATUS.seated:
			return [
				{
					name: 'Billed',
					icon: bookingStatusBilled,
					value: BOOKINGSTATUS.billed,
				},
				{
					name: 'Unseat',
					icon: bookingStatusUnseat,
					value: BOOKINGSTATUS.upcoming,
				},
			];
		case BOOKINGSTATUS.partiallySeated:
			return [
				{
					name: 'Seat',
					icon: bookingStatusSeated,
					value: BOOKINGSTATUS.seated,
				},
				{
					name: 'Unseat',
					icon: bookingStatusUnseat,
					value: BOOKINGSTATUS.upcoming,
				},
			];
		default:
			return [
				{
					name: 'Late',
					icon: bookingStatusLate,
					value: BOOKINGSTATUS.late,
				},
				{
					name: 'No Show',
					icon: bookingStatusNoShow,
					value: BOOKINGSTATUS.noShow,
				},
				{
					name: 'Cancelled',
					icon: bookingStatusCancelled,
					value: BOOKINGSTATUS.cancelled,
				},
			];
	}
};

export const getTableRawCapacity = (tableType) => {
	switch (tableType) {
		case TABLETYPE.singleTable:
			return {
				capacity_min: 1,
				capacity_max: 1,
			};
		case TABLETYPE.twoSingleTable:
			return {
				capacity_min: 1,
				capacity_max: 2,
			};
		case TABLETYPE.threeSingleTable:
			return {
				capacity_min: 1,
				capacity_max: 3,
			};
		case TABLETYPE.singlePairTable:
			return {
				capacity_min: 1,
				capacity_max: 2,
			};
		case TABLETYPE.twoSinglePairTable:
			return {
				capacity_min: 2,
				capacity_max: 4,
			};
		case TABLETYPE.threeSinglePairTable:
			return {
				capacity_min: 3,
				capacity_max: 6,
			};
		case TABLETYPE.fourSinglePairTable:
			return {
				capacity_min: 4,
				capacity_max: 8,
			};
		case TABLETYPE.fourPersonSingleTable:
			return {
				capacity_min: 2,
				capacity_max: 4,
			};
		case TABLETYPE.fourPersonRoundTable:
			return {
				capacity_min: 2,
				capacity_max: 4,
			};
		case TABLETYPE.sixPersonRoundTable:
			return {
				capacity_min: 3,
				capacity_max: 6,
			};
		case TABLETYPE.eightPersonRoundTable:
			return {
				capacity_min: 4,
				capacity_max: 8,
			};
		case TABLETYPE.tenPersonRoundTable:
			return {
				capacity_min: 6,
				capacity_max: 10,
			};
		case TABLETYPE.halfSeatRoundTable:
			return {
				capacity_min: 4,
				capacity_max: 8,
			};
		case TABLETYPE.twoPersonRoundTable:
			return {
				capacity_min: 1,
				capacity_max: 2,
			};
		default:
			break;
	}
};

export const radToDeg = (rad) => {
	return _radToDeg(rad);
};

export const hasConflictBooking = (table, tables, bookings) => {
	let conflictTables = [];

	if (bookings === undefined) {
		return false;
	}
	if (bookings === null) {
		return false;
	}

	if (tables != null) {
		tables.map((table) => {
			if (Array.isArray(bookings)) {
				const __bookings =
					bookings
						.filter((e) => e.table.some((c) => c.id === table.id))
						.sort((a, b) => a.start_time < b.start_time) ?? [];
				let prevBooking = null;
				for (let i = 0; i < __bookings.length; i++) {
					const booking = __bookings[i];
					if (i !== 0) {
						if (
							prevBooking.start_time <= booking.start_time &&
							prevBooking.end_time > booking.end_time
						) {
							conflictTables = [...conflictTables, booking];
						}
					}

					prevBooking = booking;
				}
			}
		});
	}

	if (table != null) {
		if (Array.isArray(bookings)) {
			const __bookings =
				bookings
					.filter((e) => e.table.some((c) => c.id === table.id))
					.sort((a, b) => a.start_time < b.start_time) ?? [];
			let prevBooking = null;
			for (let i = 0; i < __bookings.length; i++) {
				const booking = __bookings[i];
				if (i !== 0) {
					if (
						prevBooking.start_time <= booking.start_time &&
						prevBooking.end_time > booking.end_time
					) {
						conflictTables = [...conflictTables, booking];
					}
				}

				prevBooking = booking;
			}
		}
	}

	return conflictTables.length > 0 ? true : false;
};

export const getRadHeight = (rad, tableType) => {
	let radToDeg = _radToDeg(rad);
	let width = getTableWidth(tableType);
	let height = getTableHeight(tableType);

	switch (radToDeg) {
		case 0:
		case 180:
			return height;
		default:
			return width;
	}
};

export const getRadWidth = (rad, tableType) => {
	let radToDeg = _radToDeg(rad);
	let width = getTableWidth(tableType);
	let height = getTableHeight(tableType);

	switch (radToDeg) {
		case 0:
		case 180:
			return width;
		default:
			return height;
	}
};

export const getPositionTop = (rad, tableType) => {
	let radToDeg = _radToDeg(rad);
	let width = getTableWidth(tableType);
	let height = getTableHeight(tableType);

	switch (radToDeg) {
		case 0:
			return 0;
		case 90:
			return 0;
		case 180:
			return height;
		default:
			return width;
	}
};

export const getPositionLeft = (rad, tableType) => {
	let radToDeg = _radToDeg(rad);
	let width = getTableWidth(tableType);
	let height = getTableHeight(tableType);

	switch (radToDeg) {
		case 0:
			return 0;
		case 90:
			return height;
		case 180:
			return width;
		default:
			return 0;
	}
};

export const filterTableByFloor = (floorId, tables) => {
	return tables.filter((e) => e.floor_id === floorId);
};

export const getAllTablesOnFloors = (floors) => {
	let tables = [];

	floors.map((e) => {
		tables = tables.concat(e.tables);
	});

	return tables;
};

export const getAllTime = (shifts, selectedDate) => {
	let times = [];
	const currentHoursInSecs = moment().seconds() + moment().minutes() * 60 + moment().hours() * 3600;
	if (isArray(shifts)) {
		shifts.forEach((shift) => {
			let totalShiftHours = (shift.end_time - shift.start_time) / 900;
			let totalshiftByHalfHour = totalShiftHours;

			if (totalShiftHours > 1) {
				for (let i = 0; i <= totalshiftByHalfHour; i++) {
					const min = i * 900;
					const totalTime = shift.start_time + min;
					const currentDate = moment().format('yyyy-MM-DD');
					// const selectedDate = moment(selectedDate)
					if (currentDate === selectedDate) {
						if (totalTime >= currentHoursInSecs) {
							times = [...times, totalTime];
						}
					} else {
						times = [...times, totalTime];
					}
				}
			}
		});
	} else {
		let shift = shifts;
		if (shift !== undefined) {
			let totalShiftHours = (shift.end_time - shift.start_time) / 900;
			let totalshiftByHalfHour = totalShiftHours;

			// console.log(totalShiftHours);

			if (totalShiftHours > 1) {
				for (let i = 0; i <= totalshiftByHalfHour; i++) {
					const min = i * 900;
					const totalTime = shift.start_time + min;
					const currentDate = moment().format('yyyy-MM-DD');
					// const selectedDate = moment(selectedDate)
					if (currentDate === selectedDate) {
						if (totalTime >= currentHoursInSecs) {
							times = [...times, totalTime];
						}
					} else {
						times = [...times, totalTime];
					}
				}
			}
		}
	}

	return times.sort();
};

export const displayPickerHourMinutes = (d) => {
	d = Number(d);
	let h = Math.floor(d / 3600);
	let m = Math.floor((d % 3600) / 60);

	let hDisplay = h > 0 ? h : '0';
	let mDisplay = m > 0 ? m : '00';

	return hDisplay + ' h ' + mDisplay + ' m';
};

export const checkIfBookingIsOvertime = (start_date, end_time) => {
	const currentMoment = moment();
	const currentHoursInSecs =
		currentMoment.seconds() + currentMoment.minutes() * 60 + currentMoment.hours() * 3600;
	const momentStartDate = moment(start_date);
	const momentEndDate = moment(end_time);
	const endDateInSecs =
		momentEndDate.seconds() + momentEndDate.minutes() * 60 + momentEndDate.hours() * 3600;
	const remainingTime = endDateInSecs - currentHoursInSecs;

	if (momentStartDate.date() > currentMoment.date()) {
		return false;
	}

	if (momentEndDate.date() < currentMoment.date()) {
		return true;
	}

	if (remainingTime < 0) {
		return true;
	} else {
		return false;
	}
};

export const getRemainingTime = (end_time, status, no_limit) => {
	const currentHoursInSecs = moment().seconds() + moment().minutes() * 60 + moment().hours() * 3600;
	if (no_limit) {
		return '----';
	}

	if (status === BOOKINGSTATUS.seated) {
		const remainingSecs = end_time - currentHoursInSecs;

		return displayPickerHourMinutes(remainingSecs);
	} else {
		if (status === BOOKINGSTATUS.overTime) {
			const remainingSecs = end_time - currentHoursInSecs;

			return displayPickerHourMinutes(remainingSecs);
		} else {
			return '----';
		}
	}
};

export const getTimeStr = (d) => {
	d = Number(d);
	let h = Math.floor(d / 3600);
	let m = Math.floor((d % 3600) / 60);

	let hDisplay = h > 0 ? h : '';
	let mDisplay = m > 0 ? m : '00';
	return (
		(hDisplay > 12 ? hDisplay - 12 : hDisplay) + ':' + mDisplay + (hDisplay >= 12 ? ' PM' : ' AM')
	);
};

export const filterTimeFromCurrentTime = (timeList) => {
	const currentHoursInSecs = moment().seconds() + moment().minutes() * 60 + moment().hours() * 3600;
	return timeList.filter((e) => {
		return e > currentHoursInSecs;
	});
};

export const updateDateTime = (date, time) => {
	const _date = moment(date);
	const newHours = time / 3600;
	const newMinutes = (time % 3600) / 60;

	return _date.hours(newHours).minutes(newMinutes).format('yyyy-MM-DD HH:mm:ss');
};

export const capitalized = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

export const toCurrency = (num) => {
	return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const stringReplaced = (string) => {
	return string.replace(/[|_]/g, ' ');
};

export const getStringTime = (d) => {
	d = Number(d);
	let h = Math.floor(d / 3600);
	let m = Math.floor((d % 3600) / 60);

	let hDisplay;
	if (h > 0) {
		hDisplay = h < 10 ? '0' + h : h;
	} else {
		hDisplay = '';
	}
	let mDisplay = m > 0 ? m : '00';

	return hDisplay + ':' + mDisplay + ':00';
};

export const getCurrentShift = (shifts, date) => {
	const momentDate = moment(date);
	if (date === undefined) {
		const currentHoursInSecs =
			moment().seconds() + moment().minutes() * 60 + moment().hours() * 3600;
		const shift = shifts.filter(
			(e) => e.start_time <= currentHoursInSecs && e.end_time >= currentHoursInSecs,
		)[0];

		return shift === null || shift === undefined ? shifts[1] : shift;
	} else {
		if (momentDate.startOf('day').isSame(moment().startOf('day'))) {
			const currentHoursInSecs =
				moment().seconds() + moment().minutes() * 60 + moment().hours() * 3600;
			const shift = shifts.filter(
				(e) => e.start_time <= currentHoursInSecs && e.end_time >= currentHoursInSecs,
			)[0];

			return shift === null || shift === undefined ? shifts[0] : shift;
		} else {
			const _shifts = shifts.sort((a, b) => (a.start_time > b.start_time ? 1 : -1));

			return _shifts[0];
		}
	}
};

export const getShiftByTime = (time, shifts) => {
	const shift = shifts.filter((e) => e.start_time <= time && e.end_time >= time)[0];
	return shift === null || shift === undefined ? shifts[1] : shift;
};

export const createGuest = (guestName, phone) => {
	const nameArray = guestName.split(' ');
	return {
		first_name: nameArray[0],
		last_name: nameArray[1] !== undefined ? nameArray[1] : '',
		phone: phone,
		email: '',
		company: '',
		state: '',
		general_note: '',
		birthdate: '',
		anniversary: '',
		special_relationship: '',
		id: 0,
		address: '',
		city: '',
		food_drink_preference: '',
		postal: '',
		tags: [],
		seating_preference: '',
	};
};

export const tableNamePositionLeftTranslate = (tableType) => {
	switch (tableType) {
		case TABLETYPE.fourPersonRoundTable:
		case TABLETYPE.sixPersonRoundTable:
		case TABLETYPE.eightPersonRoundTable:
		case TABLETYPE.halfSeatRoundTable:
		case TABLETYPE.twoPersonRoundTable:
			return '-57%';
		default:
			return 0;
	}
};

export const getBookingOnTable = (tableId, bookings) => {
	if (bookings == null) {
		return null;
	}

	const booking = bookings.filter((booking) => {
		return (
			booking.table.some((table) => table.id === tableId) &&
			!(
				booking.status === BOOKINGSTATUS.finished ||
				booking.status === BOOKINGSTATUS.cancelled ||
				booking.status === BOOKINGSTATUS.noShow
			)
		);
	});

	return booking.length === 0 ? null : booking[0];
};

export const getBookingsOnTable = (tableId, bookings) => {
	if (bookings == null) {
		return null;
	}
	let booking = [];
	booking = bookings.filter((booking) => {
		return (
			booking.table.some((table) => table.id === tableId) &&
			!(
				booking.status === BOOKINGSTATUS.finished ||
				booking.status === BOOKINGSTATUS.cancelled ||
				booking.status === BOOKINGSTATUS.noShow
			)
		);
	});

	return booking.length === 0 ? null : booking;
};

export const getShiftIndex = (prop, value, shifts) => {
	for (var i = 0; i < shifts.length; i++) {
		if (shifts[i][prop] === value) {
			return i;
		}
	}
	return -1;
};

export const filterShifts = (shifts, selectedDate) => {
	return shifts.filter((i) => i.day_of_week.includes(selectedDate));
};

export const getBookingOnTableByIndex = (tableId, bookings, index) => {
	const _bookings = bookings.filter((booking) => {
		return (
			booking.table.some((table) => table.id === tableId) &&
			!(
				booking.status === BOOKINGSTATUS.finished ||
				booking.status === BOOKINGSTATUS.cancelled ||
				booking.status === BOOKINGSTATUS.noShow
			)
		);
	});

	return _bookings.length === 0
		? null
		: _bookings.sort((a, b) => a.start_time < b.start_time)[index] ?? null;
};

export const tableNamePositionLeft = (tableType) => {
	switch (tableType) {
		case TABLETYPE.fourPersonRoundTable:
		case TABLETYPE.sixPersonRoundTable:
		case TABLETYPE.eightPersonRoundTable:
		case TABLETYPE.halfSeatRoundTable:
		case TABLETYPE.twoPersonRoundTable:
			return '50%';
		default:
			return 5;
	}
};

export const tableNamePositionMarginTop = (tableType, rad) => {
	let radToDeg = _radToDeg(rad);
	// let width = getTableWidth(tableType)
	// let height = getTableHeight(tableType)

	switch (tableType) {
		case TABLETYPE.singleTable:
		case TABLETYPE.twoSingleTable:
		case TABLETYPE.threeSingleTable:
			switch (radToDeg) {
				case 90:
					return 5;
				case 180:
					return 10;
				case 270:
					return 10;
				default:
					break;
			}
			break;
		case TABLETYPE.singlePairTable:
		case TABLETYPE.twoSinglePairTable:
		case TABLETYPE.threeSinglePairTable:
		case TABLETYPE.fourSinglePairTable:
			switch (radToDeg) {
				case 90:
				case 270:
					return 10;
				case 180:
					return 7;
				case 0:
					return 2;
				default:
					break;
			}
			break;
		case TABLETYPE.fourPersonSingleTable:
			return 10;
		case TABLETYPE.fourPersonRoundTable:
		case TABLETYPE.sixPersonRoundTable:
		case TABLETYPE.eightPersonRoundTable:
		case TABLETYPE.halfSeatRoundTable:
		case TABLETYPE.twoPersonRoundTable:
		case TABLETYPE.tenPersonRoundTable:
			return 15;
		default:
			break;
	}
};

export const tableNamePositionMarginLeft = (tableType, rad) => {
	let radToDeg = _radToDeg(rad);
	// let width = getTableWidth(tableType)
	// let height = getTableHeight(tableType)

	switch (tableType) {
		case TABLETYPE.singleTable:
		case TABLETYPE.twoSingleTable:
		case TABLETYPE.threeSingleTable:
			switch (radToDeg) {
				case 90:
					return 5;
				case 180:
					return 7;
				case 270:
					return 0;
				default:
					break;
			}
			break;
		case TABLETYPE.singlePairTable:
		case TABLETYPE.twoSinglePairTable:
		case TABLETYPE.threeSinglePairTable:
		case TABLETYPE.fourSinglePairTable:
			switch (radToDeg) {
				case 90:
					return 0;
				case 270:
					return 9;
				case 180:
				case 0:
					return 8;
				default:
					break;
			}
			break;
		case TABLETYPE.fourPersonSingleTable:
			return 10;
		case TABLETYPE.fourPersonRoundTable:
		case TABLETYPE.sixPersonRoundTable:
		case TABLETYPE.eightPersonRoundTable:
		case TABLETYPE.halfSeatRoundTable:
		case TABLETYPE.twoPersonRoundTable:
			return 0;
		case TABLETYPE.tenPersonRoundTable:
			return 0;
		default:
			break;
	}
};

export const getBookingType = (booking) => {
	switch (booking.type) {
		case BOOKINGTYPE.phone:
			return 'Booked by Phone';
		case BOOKINGTYPE.widget:
			return `Booking made online ${moment(booking.created_at).format('MM/DD/yyyy hh:mm a')}`;
		case BOOKINGTYPE.inhouse:
			return 'Booked by In-House';
		case BOOKINGTYPE.experience:
			return 'Booked by Experience';
	}
};

// Get Booking time only
export const getTimeOnly = (date) => {
	return moment(date, 'yyyy-MM-dd hh:mm:ss').format('hh:mm A');
};

export const getBookingTimeOnly = (booking) => {
	if (booking === null) {
		return '';
	}
	return moment(booking.start_date, 'yyyy-MM-dd hh:mm:ss').format('hh:mm A');
};

// Get Table Size
export const getTableWidth = (tableType) => {
	switch (tableType) {
		case TABLETYPE.singleTable:
		case TABLETYPE.twoSingleTable:
		case TABLETYPE.threeSingleTable:
			return 89;
		case TABLETYPE.singlePairTable:
		case TABLETYPE.twoSinglePairTable:
		case TABLETYPE.threeSinglePairTable:
		case TABLETYPE.fourSinglePairTable:
		case TABLETYPE.fourPersonSingleTable:
			return 100;
		case TABLETYPE.fourPersonRoundTable:
		case TABLETYPE.sixPersonRoundTable:
		case TABLETYPE.eightPersonRoundTable:
		case TABLETYPE.halfSeatRoundTable:
		case TABLETYPE.twoPersonRoundTable:
			return 131;
		case TABLETYPE.tenPersonRoundTable:
			return 136;
		default:
			break;
	}
};

export const getTableHeight = (tableType) => {
	switch (tableType) {
		case TABLETYPE.singleTable:
			return 78;
		case TABLETYPE.twoSingleTable:
			return 156;
		case TABLETYPE.threeSingleTable:
			return 234;
		case TABLETYPE.singlePairTable:
			return 80;
		case TABLETYPE.twoSinglePairTable:
			return 160;
		case TABLETYPE.threeSinglePairTable:
			return 240;
		case TABLETYPE.fourSinglePairTable:
			return 320;
		case TABLETYPE.fourPersonSingleTable:
			return 100;
		case TABLETYPE.fourPersonRoundTable:
		case TABLETYPE.sixPersonRoundTable:
		case TABLETYPE.eightPersonRoundTable:
		case TABLETYPE.tenPersonRoundTable:
			return 131;
		case TABLETYPE.halfSeatRoundTable:
			return 146;
		case TABLETYPE.twoPersonRoundTable:
			return 116;
		default:
			break;
	}
};

// Get Table Img
export const getTableImg = (tableType) => {
	switch (tableType) {
		case TABLETYPE.singleTable:
			return singleTable;
		case TABLETYPE.twoSingleTable:
			return twoSingleTable;
		case TABLETYPE.threeSingleTable:
			return threeSingleTable;
		case TABLETYPE.singlePairTable:
			return singlePairTable;
		case TABLETYPE.twoSinglePairTable:
			return twoSinglePairTable;
		case TABLETYPE.threeSinglePairTable:
			return threeSinglePairTable;
		case TABLETYPE.fourSinglePairTable:
			return fourSinglePairTable;
		case TABLETYPE.fourPersonSingleTable:
			return fourPersonSingleTable;
		case TABLETYPE.fourPersonRoundTable:
			return fourPersonRoundTable;
		case TABLETYPE.sixPersonRoundTable:
			return sixPersonRoundTable;
		case TABLETYPE.eightPersonRoundTable:
			return eightPersonRoundTable;
		case TABLETYPE.tenPersonRoundTable:
			return tenPersonRoundTable;
		case TABLETYPE.halfSeatRoundTable:
			return halfSeatRoundTable;
		case TABLETYPE.twoPersonRoundTable:
			return twoPersonRoundTable;
		default:
			break;
	}
};

export const getBookingTakenFullname = (employee) => {
	if (employee == null) {
		return '--';
	}

	const firstName = employee.first_name;
	const lastName = employee.last_name == null ? ' ' : employee.last_name;

	return firstName + ' ' + lastName;
};

// Get Guest full name
export const getFullname = (guest) => {
	if (guest == null) {
		return 'Walk In';
	}

	const firstName = guest.first_name;
	const lastName = guest.last_name == null ? ' ' : guest.last_name;

	return firstName + ' ' + lastName;
};

// Get Employee Initials
export const getEmployeeInitials = (employee) => {
	if (employee === null || employee === undefined) {
		return '';
	}
	return employee.first_name.charAt(0) + employee.last_name.charAt(0);
};

// Get Table Name
export const getFloorName = (floorId, floors) => {
	const floor = floors.filter((e) => e.id === floorId)[0];
	if (floor) {
		return floor.floor_name;
	} else {
		return '';
	}
};

export const getFilterFloors = (floorId, floors) => {
	const _floors = floors.filter((e) => e.id !== floorId);
	return _floors;
};

// Get Table Size
export const getTableSize = (booking) => {
	const { party_size } = booking;

	return party_size === 1 ? party_size + ' guest' : party_size + ' guests';
};

// Get Guest Phone Number
export const getGuestPhoneNum = (guest) => {
	if (guest === null) {
		return ' ';
	}

	return guest.phone;
};

export const sectionStatus = [
	BOOKINGSTATUS.late,
	BOOKINGSTATUS.upcoming,
	BOOKINGSTATUS.seated,
	BOOKINGSTATUS.unconfirmed,
	BOOKINGSTATUS.partiallySeated,
	BOOKINGSTATUS.finished,
	BOOKINGSTATUS.cancelled,
	BOOKINGSTATUS.noShow,
];
export const sectionStatusName = [
	'Late',
	'Upcoming',
	'Seated',
	'Unconfirmed',
	'Partially Seated',
	'Finished',
	'Cancelled',
	'No Show',
];

// Sort booking by section status
export const sortBookingBySection = (bookings, keyword = '') => {
	let section = [];

	sectionStatus.map((val, idx) => {
		let statusName = sectionStatusName[idx];
		const _bookings = bookings.filter((o) => {
			if (o.guest == null) {
				return false;
			}
			let fullName = o.guest.first_name + ' ' + o.guest.last_name;
			return fullName.toLowerCase().includes(keyword.toLowerCase());
		});
		let bookingByStatus = _bookings.filter((item) => item.status === val);
		let bookingSortedByTime = bookingByStatus.sort((a, b) =>
			a.start_time > b.start_time ? 1 : -1,
		);
		let partySize = 0;
		bookingSortedByTime.forEach((item) => {
			partySize += item.party_size;
		});
		let tableCount = 0;
		bookingSortedByTime.forEach((item) => {
			tableCount += item.table.length;
		});

		let obj = {
			sectionName: statusName,
			bookings: bookingByStatus === null ? [] : bookingSortedByTime,
			totalPartySize: bookingByStatus === null ? 0 : partySize,
			totalTable: bookingByStatus === null ? 0 : tableCount,
		};

		section = [...section, obj];
		return section;
	});

	section = section.filter((item) => item.bookings.length !== 0);

	return section;
};

// Sort Booking by status
export const sortBookingByStatus = (bookings, option, keyword = '') => {
	const selectedOption = parseInt(option);
	const _bookings = bookings.filter((o) => {
		if (o.guest == null) {
			return false;
		}
		let fullName = o.guest.first_name + ' ' + o.guest.last_name;
		return fullName.toLowerCase().includes(keyword.toLowerCase());
	});
	const newBookings = [].concat(_bookings).sort((a, b) => a.start_time < b.start_time);

	switch (selectedOption) {
		case 1:
		case 2:
			const active = newBookings.filter(
				(item) =>
					item.status === BOOKINGSTATUS.seated ||
					item.status === BOOKINGSTATUS.upcoming ||
					item.status === BOOKINGSTATUS.waitList ||
					item.status === BOOKINGSTATUS.billed ||
					item.status === BOOKINGSTATUS.overTime ||
					item.status === BOOKINGSTATUS.late ||
					item.status === BOOKINGSTATUS.unseat ||
					item.status === BOOKINGSTATUS.unconfirmed ||
					item.status === BOOKINGSTATUS.partiallySeated,
			);

			const inactive = newBookings.filter(
				(item) =>
					item.status === BOOKINGSTATUS.finished ||
					item.status === BOOKINGSTATUS.noShow ||
					item.status === BOOKINGSTATUS.cancelled,
			);

			let newSortedBookings = [];
			newSortedBookings = newSortedBookings.concat(active, inactive);

			if (selectedOption === 2) {
				let sortedBookingByStatus = [];
				sortedBookingByStatus = sortedBookingByStatus.concat(
					newSortedBookings.sort((a, b) => (a.status > b.status ? 1 : -1)),
				);
				return sortedBookingByStatus;
			}

			return newSortedBookings;
		case 4:
			const alerts = newBookings.filter(
				(item) =>
					item.status === BOOKINGSTATUS.waitList ||
					item.status === BOOKINGSTATUS.needAttention ||
					item.status === BOOKINGSTATUS.late ||
					item.status === BOOKINGSTATUS.finished ||
					item.status === BOOKINGSTATUS.noShow ||
					item.status === BOOKINGSTATUS.cancelled ||
					item.status === BOOKINGSTATUS.unconfirmed,
			);

			// let sortedBookings = [];
			// sortedBookings = alerts.sort((a, b) => a.start_time > b.start_time ? 1 : -1);
			return alerts;

		default:
			break;
	}
};

// Get name status color
export const getNameStatusColor = (status) => {
	switch (status) {
		// unconfirmed
		case 0:
			return '#CBFFFD';
		// finished, no show, cancelled
		case 6:
		case 7:
		case 8:
			return '#707070';
		default:
			return '#ffffff';
	}
};

export const getLabelBookingColor = (status) => {
	if (status === null) {
		return;
	}
	switch (status) {
		// finished, no show, cancelled
		case BOOKINGSTATUS.finished:
		case BOOKINGSTATUS.noShow:
		case BOOKINGSTATUS.cancelled:
			return '#707070';
		// unconfirmed
		case BOOKINGSTATUS.unconfirmed:
			return '#CBFFFD';
		default:
			return '#FFFFFF';
	}
};

export const getBookingProgressStatusColo = (status) => {
	switch (status) {
		// seated, over-time
		case BOOKINGSTATUS.seated:
		case BOOKINGSTATUS.overTime:
			return '#C76467';
		// partially seated
		case BOOKINGSTATUS.partiallySeated:
			return '#A069F4';
		// billed
		case BOOKINGSTATUS.billed:
			return '#52D88F';
		// need attention
		case BOOKINGSTATUS.needAttention:
			return '#E88870';
		// late
		case BOOKINGSTATUS.late:
			return '#E88870';
		// finished, no show, cancelled
		case BOOKINGSTATUS.finished:
		case BOOKINGSTATUS.noShow:
		case BOOKINGSTATUS.cancelled:
			return '#707070';
		default:
			return '#E3E0E0';
	}
};

// Get booking status color
export const getBookingStatusColor = (status) => {
	switch (status) {
		// seated, over-time
		case BOOKINGSTATUS.seated:
			return '#FE7D86';
		case BOOKINGSTATUS.overTime:
			return '#FF977C';
		// partially seated
		case BOOKINGSTATUS.partiallySeated:
			return '#BD99F4';
		// billed
		case BOOKINGSTATUS.billed:
			return '#85FFBD';
		// need attention
		case BOOKINGSTATUS.needAttention:
			return '#FF977C';
		// late
		case BOOKINGSTATUS.late:
			return '#FF977C';
		// finished, no show, cancelled
		case BOOKINGSTATUS.finished:
		case BOOKINGSTATUS.noShow:
		case BOOKINGSTATUS.cancelled:
			return '#707070';
		// unconfirmed
		case BOOKINGSTATUS.unconfirmed:
			return '#CBFFFD';
		default:
			return '#FFFFFF';
	}
};

export const getDisabledDays = (
	date,
	expEndDate,
	expStartDate,
	expDaysOfWeek,
	expRecurringType,
	expRecurringValue,
	expHandler,
) => {
	if (expHandler == true) {
		let _date = moment(date).startOf('day').toDate();
		let currentDate = moment().startOf('day');
		let endDate = moment(expEndDate).startOf('day');
		let startDate = moment(expStartDate).startOf('day');
		let _endDate = moment(expEndDate).add(1, 'd');
		var disabledDays = false;
		if (expRecurringType === RECURRINGTYPE.weekly) {
			if (!endDate.isValid()) {
				// If `end_date` is null
				// Indefinite date
				let daysOfWeek = expDaysOfWeek;
				disabledDays =
					daysOfWeek.includes(moment(date).isoWeekday()) &&
					startDate.valueOf() <= date.getTime() &&
					currentDate.valueOf() <= date.getTime();
				return !disabledDays ? true : false;
			} else {
				// If `end_date` is not null
				// Definite date
				let daysOfWeek = expDaysOfWeek;
				if (date.getTime() <= _endDate.valueOf()) {
					disabledDays =
						daysOfWeek.includes(moment(date).isoWeekday()) &&
						startDate.valueOf() <= date.getTime() &&
						currentDate.valueOf() <= date.getTime() &&
						_endDate.valueOf() >= date.getTime();
				}
				return !disabledDays ? true : false;
			}
		}
		if (expRecurringType === RECURRINGTYPE.monthly) {
			let _expStartDateMonth = startDate.month();
			let _expStartDate = startDate.date();
			let dateMoment = moment(date);
			let dateMomentYear = dateMoment.year();
			let dateMomentMonth = dateMoment.month();
			let dateMomentDate = dateMoment.date();
			if (expRecurringValue === RECURRINGVALUE.everyDayOfMonth) {
				let expWeekNumber = startDate.week() - moment(expStartDate).startOf('month').week() + 1;
				let weekNumber = dateMoment.week() - moment(date).startOf('month').week() + 1;
				let daysOfWeek = startDate.isoWeekday();
				let daysOfWeekMoment = moment(date).isoWeekday();
				if (!endDate.isValid()) {
					if (expWeekNumber == weekNumber) {
						disabledDays =
							daysOfWeek == daysOfWeekMoment &&
							currentDate.valueOf() <= date.getTime() &&
							startDate.valueOf() <= date.getTime();
					}
				} else {
					if (expWeekNumber == weekNumber && date.getTime() <= _endDate.valueOf()) {
						disabledDays =
							daysOfWeek == daysOfWeekMoment && currentDate.valueOf() <= date.getTime();
					}
				}
				return !disabledDays ? true : false;
			}
			if (RECURRINGVALUE.everyDateOfMonth) {
				if (!endDate.isValid()) {
					disabledDays =
						dateMomentYear &&
						_expStartDateMonth << dateMomentMonth &&
						_expStartDate == dateMomentDate &&
						currentDate.valueOf() <= date.getTime();
				} else {
					if (_endDate.valueOf() >= date.getTime()) {
						disabledDays =
							dateMomentYear &&
							_expStartDateMonth << dateMomentMonth &&
							_expStartDate == dateMomentDate &&
							currentDate.valueOf() <= date.getTime();
					}
				}
				return !disabledDays ? true : false;
			}
		}
		if (expRecurringType === RECURRINGTYPE.annually) {
			let _expStartDateMonth = startDate.month();
			let _expStartDate = startDate.date();
			let dateMoment = moment(date);
			let dateMomentYear = dateMoment.year();
			let dateMomentMonth = dateMoment.month();
			let dateMomentDate = dateMoment.date();
			if (expRecurringValue === RECURRINGVALUE.everyMonthDateOfYear) {
				if (!endDate.isValid()) {
					let dateMomentYear = dateMoment.year();
					disabledDays =
						dateMomentYear &&
						_expStartDateMonth == dateMomentMonth &&
						_expStartDate == dateMomentDate &&
						currentDate.valueOf() <= date.getTime();
				} else {
					if (_endDate.valueOf() >= date.getTime()) {
						let dateMomentYear = dateMoment.year();
						disabledDays =
							dateMomentYear &&
							_expStartDateMonth == dateMomentMonth &&
							_expStartDate == dateMomentDate &&
							currentDate.valueOf() <= date.getTime();
					}
				}
			}
			return !disabledDays ? true : false;
		}
		if (expRecurringType === RECURRINGTYPE.none) {
			// let endOfStartDate = moment(expStartDate).endOf('day');
			if (!endDate.isValid()) {
				disabledDays =
					startDate.valueOf() <= date.getTime() && currentDate.valueOf() <= date.getTime();
			} else {
				if (_endDate.valueOf() >= date.getTime()) {
					disabledDays =
						startDate.valueOf() <= date.getTime() && currentDate.valueOf() <= date.getTime();
				}
			}
			return !disabledDays ? true : false;
		}
	} else {
		let currentDate = moment().startOf('day');
		var disabledDays = false;
		disabledDays = currentDate.valueOf() <= date.getTime();
		return !disabledDays ? true : false;
	}
};

export const getNearestAvailableDate = (
	expStartDate,
	expEndDate,
	expDaysOfWeek,
	expRecurringType,
	expRecurringValue,
) => {
	let start = moment(expStartDate).startOf('date');
	let current = moment(new Date()).startOf('date');
	let end = moment(expEndDate).startOf('date');

	if (expRecurringType === RECURRINGTYPE.weekly) {
		if (end.isValid()) {
			let startDay = start.dayOfYear();
			let endDay = end.dayOfYear();
			let currentDay = current.dayOfYear();
			if (startDay < currentDay) {
				for (let i = currentDay; i <= endDay; i++) {
					let newDate = moment().dayOfYear(i);
					let weekNumber = newDate.isoWeekday();
					if (expDaysOfWeek.includes(weekNumber)) {
						if (i === currentDay) continue;
						return newDate.toDate();
					}
				}
			} else {
				return expStartDate;
			}
		} else {
			let currentDay = current.dayOfYear() + 1;
			let currentWeek = current.dayOfYear() + 6;
			for (let i = currentDay; i <= currentWeek; i++) {
				let newDate = moment().dayOfYear(i);
				let weekNumber = newDate.isoWeekday();
				if (expDaysOfWeek.includes(weekNumber)) {
					return newDate.toDate();
				}
			}
		}
	}
	if (expRecurringType === RECURRINGTYPE.monthly) {
		if (expRecurringValue === RECURRINGVALUE.everyDayOfMonth) {
			if (end.isValid()) {
				let startWeekDefault = start.week() - start.startOf('month').week() + 1;
				let monthStart = start.month();
				let monthEnd = end.month();
				let monthNow = current.month();
				if (start < current) {
					// let diff = moment.duration(end.diff(current, 'months'));
					for (let i = monthStart; i <= monthEnd; i++) {
						let month = moment().month(i).day('Monday');
						// let month = moment().month(i);
						if (month.month() === monthNow) {
							return month.add(7, 'days').toDate();
							// month.week() - month.start('month').week()+1
						}
					}
				} else {
					return expStartDate;
				}
			} else {
				if (start < current) {
					let monthStart = start.month();
					let nextMonth = current.month() + 1;
					let monthNow = current.month();
					for (let i = monthStart; i <= nextMonth; i++) {
						let month = moment().month(i).day('Monday');
						if (month.month() === monthNow) {
							return month.add(7, 'days').toDate();
						}
					}
				} else {
					return expStartDate;
				}
			}
		}
		if (expRecurringValue === RECURRINGVALUE.everyDateOfMonth) {
			let currentMonth = current.month();
			let startDate = start.date(); // 1 - 31
			let endMonth = end.month();
			let nearestDate = {};
			if (end.isValid()) {
				if (start < current) {
					for (let i = currentMonth; i <= endMonth; i++) {
						nearestDate = moment().month(i).date(startDate).toDate();
					}
					return nearestDate;
				} else {
					return expStartDate;
				}
			} else {
				let nextMonth = current.month() + 1;
				if (start < current) {
					for (let i = currentMonth; i <= nextMonth; i++) {
						nearestDate = moment().month(i).date(startDate).toDate();
					}
					return nearestDate;
				} else {
					return expStartDate;
				}
			}
		}
	}
	if (expRecurringType === RECURRINGTYPE.annually) {
		if (expRecurringValue === RECURRINGVALUE.none) {
			if (end.isValid()) {
				let currentY = current.year();
				let nextD = current.date();
				let endY = end.year();
				if (start < current) {
					if (currentY <= endY) {
						return current.add(1, 'd');
					}
				} else {
					if (start < end) {
						return current.add('d');
					}
				}
			} else {
				if (start < current) {
					return current.add(1, 'd');
				} else {
					return expStartDate;
				}
			}
		}
		if (expRecurringValue === RECURRINGVALUE.everyMonthDateOfYear) {
			if (end.isValid()) {
				if (current < end) {
					return start.add(1, 'y').toDate();
				} else {
					return expStartDate;
				}
			} else {
				if (start < current) {
					return start.add(1, 'y').toDate();
				} else {
					return expStartDate;
				}
			}
		}
	}
	if (expRecurringType === RECURRINGTYPE.none) {
		if (end.isValid()) {
			if (start < current) {
				if (current < end) {
					return current.add(1, 'd').toDate();
				}
			} else {
				if (start > current) {
					return expStartDate;
				} else {
					return null;
				}
			}
		} else {
			if (start < current) {
				return current.add(1, 'd').toDate();
			} else {
				return expStartDate;
			}
		}
	}
};
