import React from 'react';
// import { useHistory } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { stripeKey } from '../utils/Utility';
import CardForm from './CardForm';

const stripePromise = loadStripe(stripeKey);

const CheckoutForm = ({addPayment = true, addCouponModal}) => {
	return (
		<Elements stripe={stripePromise}>
			<CardForm addPayment={addPayment} addCouponModal={addCouponModal}/>
		</Elements>
	);
};

export default CheckoutForm;
