import React, { useState } from 'react';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

import smsIcon from 'assets/images/booking/guest-book/sms-icon.png';
import closeIcon from 'assets/images/booking/exit-btn-table-details.png';
import plusIcon from 'assets/images/booking/create-booking/icon-add-white.png';
import { createSmsParameters } from 'utils/RequestBuilder';
import AlertDialog from '../CreatBooking/booking-experience/AlertDialog';
import DotLoader from '../TableLayoutPlanning/pages/SubComponents/DotLoader';
import { Alert } from 'react-bootstrap';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		width: '100%',
		height: '100%',
		backdropFilter: 'blur(10px)',
		outline: 'none',
	},
}));

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
`;
const Content = styled.div`
	display: flex;
	flex-direction: column;
	width: 630px;
	height: 370px;
	background: rgba(0, 0, 0, 0.6);
	border-radius: 10px;
	padding: 20px;
	color: white;
	// margin-left: 200px;

	img {
		user-select: none;
		-webkit-user-drag: none;
		-khtml-user-drag: none;
		-moz-user-drag: none;
		-o-user-drag: none;
		user-drag: none;
	}

	#sms-modal-header {
		position: relative;
		display: flex;
		flex-direction: row;
		width: 100%;
		margin-bottom: 20px;

		h4 {
			font-size: 16px;
			font-weight: 700;
			margin-left: 15px;
		}
	}

	#sms-modal-body {
		position: relative;
		display: flex;
		width: 100%;
		flex-direction: column;
		justify-content: center;

		h4 {
			position: absolute;
			font-size: 16px;
			font-weight: 700;
			margin-right: 30px;
		}

		span {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 10px;
		}

		p {
			margin-left: 50px;
		}

		input {
			height: 40px;
			width: 100%;
			margin: 10px 0;
			background: transparent;
			border: 1px solid #999999;
			border-radius: 0.8em;
			color: #ffffff;
			padding: 10px 20px;
		}

		textarea {
			height: 126px;
			width: 100%;
			margin: 10px 0;
			background: #111517;
			border: 1px solid #999999;
			border-radius: 0.8em;
			color: #ffffff;
			padding: 20px;
			resize: none;

			::-webkit-scrollbar {
				width: 5px;
				height: 5px;
				background-color: #222a2f;
			}
			::-webkit-scrollbar-thumb {
				width: 1px;
				height: 1px;

				border-radius: 20px;
				background-color: #2a343a;
			}

			::-webkit-scrollbar-corner {
				display: none;
			}
		}
	}
`;
const ModalFooter = styled.div`
	position: relative;
	display: inline-flex;
	align-items: center;
	height: 100%;

	span {
		margin-right: 15px;
		font-size: 16px;
		color: #bcbcbc;
		cursor: pointer;
	}

	#add-template {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 30px;
		width: 30px;
		border: 1px solid #bcbcbc;
		border-radius: 50%;
		cursor: pointer;
	}
`;

const CloseButton = styled.div`
	position: absolute;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 15px;
	width: 15px;
	cursor: pointer;

	img {
		&:hover {
			filter: brightness(1.5);
		}

		&:active {
			filter: brightness(3.5);
		}
	}
`;

const SendButton = styled.div`
	position: absolute;
	bottom: 2px;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 36px;
	width: 101px;
	background: #fe7d86;
	border-radius: 0.8em;
	font-size: 16px;
	cursor: pointer;
	&:hover {
		background: #ef747d;
	}
	&:active {
		background: #fe7d86d6;
	}
`;

const LoaderContainer = styled.div`
	position: absolute;
	left: 0;
	bottom: 50px;
	width: inherit;
`;

function SmsTextModal(props) {
	const classes = useStyles();
	const [selectedTemplate, setSelectedTemplate] = useState('');
	const [messageTitle, setMessageTitle] = useState('Custom Text');
	const [messageBody, setMessageBody] = useState('');
	const [alertHandler, setAlertHandler] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	const handleClose = () => {
		props.setSmsTextOpen(false);
	};

	const handleTitleChange = (e) => {
		setMessageTitle(e.target.value);
	};

	const handleMsg = (e) => {
		setMessageBody(e.target.value);
	};

	const selectTemplate = (template) => {
		setSelectedTemplate(template);
		setMessageTitle(template.name);
		setMessageBody(template.body);
	};

	const selectCustomText = () => {
		setMessageTitle('Custom Text');
		setMessageBody('');
	};

	const handleSubmit = () => {
		let template = {
			body: messageBody,
			name: messageTitle,
		};
		if (messageBody.length && messageTitle.length > 0) {
			props.sendMessage(createSmsParameters(props.guest, template, 0));
			props.setSending(true);
		} else {
			setErrorMessage({
				title: 'Invalid text format',
				body: 'Text body is required',
			});
			setAlertHandler(true);
			return;
		}
		// console.log(createSmsParameters(props.guest, template, 0));
	};

	return (
		<Modal
			className={classes.modal}
			open={props.smsTextOpen}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={props.smsTextOpen}>
				<div className={classes.paper}>
					<Wrapper>
						<Content>
							<AlertDialog
								alertHandler={props.alertHandler}
								dialogAlertMessage={props.dialogAlertMessage}
								setAlertHandler={props.setAlertHandler}
							/>
							<AlertDialog
								alertHandler={alertHandler}
								dialogAlertMessage={errorMessage}
								setAlertHandler={setAlertHandler}
							/>
							<div id="sms-modal-header">
								<img src={smsIcon} width={26} height={26} />
								<h4>SMS TEXT</h4>
								<CloseButton onClick={handleClose}>
									<img src={closeIcon} width={12} height={12} />
								</CloseButton>
							</div>

							<div id="sms-modal-body">
								<span>
									<h4>To:</h4>
									<p>
										{props.guest.first_name}&nbsp; {props.guest.last_name}
									</p>
									<p>
										<NumberFormat
											value={props.guest.phone}
											displayType={'text'}
											format="#### #### ####"
										/>
									</p>
								</span>

								<input
									value={messageTitle}
									onChange={handleTitleChange}
									placeholder="Enter Template title"
								/>
								{props.sending ? (
									<LoaderContainer>
										<DotLoader />
									</LoaderContainer>
								) : (
									''
								)}
								<textarea
									value={messageBody}
									onChange={handleMsg}
									placeholder="Enter Template Text"
								/>
							</div>

							<ModalFooter>
								<span onClick={selectCustomText}>Custom text</span>
								{props.templates.length > 0
									? props.templates.map((template, key) => {
											return (
												<span onClick={() => selectTemplate(template)} key={key}>
													{template.name}
												</span>
											);
									  })
									: ''}

								{/* <div id="add-template">
									<img src={plusIcon} width={12} height={12} />
								</div> */}
								<SendButton onClick={handleSubmit}>SEND</SendButton>
							</ModalFooter>
						</Content>
					</Wrapper>
				</div>
			</Fade>
		</Modal>
	);
}

export default SmsTextModal;
