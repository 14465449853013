export const MainWrapper = () => {
	document.body.style.minHeight = '100vh';
	document.body.style.minWidth = '100vw';
	document.body.style.background =
		'transparent linear-gradient(131deg, #fb247e 0%, #f5cf9e 100%) 0% 0% no-repeat';
	document.body.style.display = 'flex';
	document.body.style.flexDirection = 'column';
	if (window.innerWidth <= 600) {
		document.body.style.padding = '0';
	}
};

export const ContainerWrapper = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexDirection: 'row-reverse',
	width: '500px',
	height: 'auto',
	backgroundColor: 'white',
	boxShadow: '0 0 40px rgba(0,0,0,0.16)',
	margin: '0 auto',
	borderRadius: '12px',
	padding: '15px',
};
