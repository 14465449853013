import React, { useState, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import { Row, Col, Media } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import _ from 'lodash';
import { apiUrl } from '../../utils/Utility';

import withDashboardApp from '../../components/withDashboardApp';

import detailIcon from '../../assets/images/detail-icon.png';
import paymentIcon from '../../assets/images/payment.png';
import visa from '../../assets/images/visa.png';
import CustomModal from '../../components/CustomModal';
import CheckoutForm from '../../components/CheckoutForm';

const Box = styled.div`
	display: block;
	background: #ffffff;
	border-radius: 5px;
	box-shadow: 0px 3px 6px #00000029;
	height: 275px;
	padding: 35px;
`;

const Profile = () => {
	const reducers = useSelector((state) => state.userReducers);
	
	const [myCard, setMyCard] = useState(null);
	const [isOpen, setIsOpen] = useState(false);
	const getCard = async () => {
		await axios({
				url: `${apiUrl}/api/card`,
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${reducers.token}`,
				},
			})
			.then((resp) => {
				const {
					status,
					data: {card}
				} = resp;
				if (status === 200) {
					setMyCard(card);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
            getCard();
            if (reducers) {
                    // getPlan({ planId: reducers.user.planId });
            }
	}, [reducers]);

	return (
		<Fragment>
		<Row>
			<Col lg={6} md={12} sm={12}>
				<Box className="box-db">
					<Media>
						<img
							src={detailIcon}
							width="64"
							height="64"
							alt="details"
							className="d-inline-block align-top"
						/>
						<Media.Body>
							<h5>My Details</h5>
						</Media.Body>
					</Media>
					<hr />
					<Row>
						<Col lg={3} md={3} sm={3} xs={3} className="d1">
							<span>Email</span>
							<span>Password</span>
							<span>Plan</span>
						</Col>
						<Col lg={9} md={9} sm={9} xs={9} className="d2">
							<span>
								{reducers && reducers.user.email} &nbsp;&nbsp;
								<Link to="/dashboard/edit-profile">Edit</Link>
							</span>
							<Link to="/dashboard/edit-profile" style={{ marginBottom: 17 }}>
								Edit
							</Link>
							<span>
								<Fragment>
									$89/month
									&nbsp;&nbsp; <Link to="/dashboard/edit-plan">Edit</Link>
								</Fragment>
							</span>
						</Col>
					</Row>
				</Box>
			</Col>
			<Col lg={6} md={12} sm={12}>
				<Box className="box-db">
					<Media>
						<img
							src={paymentIcon}
							width="64"
							height="64"
							alt="payment"
							className="d-inline-block align-top"
						/>
						<Media.Body>
							<h5>Payment Method</h5>
						</Media.Body>
					</Media>
					<hr />
					<Row>
						<Col lg={3} md={3} sm={3} xs={3} className="d1">
							<img
								src={visa}
								width="40"
								height="20"
								alt="card"
								className="d-inline-block align-top"
							/>
						</Col>
						<Col lg={7} md={7} sm={7} xs={7} className="d2">
							<span>**** **** {myCard ? myCard.last4 : "****"}</span>
						</Col>
						<Col lg={2} md={2} sm={2} xs={2} className="d2">
							<a href="#" className="edit-a" onClick={() => setIsOpen(true)}>
								Edit
							</a>
						</Col>
					</Row>
					<hr style={{ marginTop: 0 }} />
				</Box>
			</Col>
		</Row>
		<CustomModal isOpen={isOpen} handleClose={setIsOpen} hasClose>
			<CheckoutForm addPayment={false}/>
		</CustomModal>
		</Fragment>
	);
};

export default withDashboardApp(Profile);
