import { put, takeLatest, all } from 'redux-saga/effects';
// import _ from 'lodash';
import axios from 'axios';
import { apiUrl } from '../utils/Utility';

function* getMeData(action) {
	let remoteData;
	yield axios.get('https://httpbin.org/json').then((resp) => {
		remoteData = resp.data;
	});

	yield put({ type: 'SET_ME_DATA', payload: remoteData });
}

function* getBusinessProfile({ payload }) {
	const { token } = payload;

	try {
		const response = yield axios({
			url: `${apiUrl}/api/get_business_profile`,
			method: 'POST',
			headers: { Authorization: `Bearer ${token}` },
		});

		if (response.status == 200) {
			const { business_profile } = response.data.data;
			const { address, business_name, profile_pic, phone_no, user_id } = business_profile;

			yield put({
				type: 'SET_BUSINESS_PROFILE',
				payload: {
					token,
					business_profile: {
						address: address,
						business_name: business_name,
						profile_pic: profile_pic,
						phone_no: phone_no,
						user_id: user_id,
					},
				},
			});
		}
	} catch (err) {
		yield put({ type: 'SET_LOADING' });
	}
}

function* getAllShifts({ payload }) {
	const { token } = payload;

	try {
		const response = yield axios({
			url: `${apiUrl}/api/get_shifts`,
			method: 'POST',
			headers: { Authorization: `Bearer ${token}` },
		});

		if (response.status == 200) {
			const { shifts } = response.data.data;
			yield put({
				type: 'SET_SHIFTS',
				payload: {
					shifts: shifts,
				},
			});
		}
	} catch (err) {
		yield put({ type: 'SET_LOADING' });
	}
}

function* getUser({ payload }) {
	const { token } = payload;

	try {
		const response = yield axios({
			url: `${apiUrl}/api/token_checker`,
			method: 'POST',
			headers: { Authorization: `Bearer ${token}` },
		});

		if (response.status === 200) {
			const { is_subscribe, on_trial, user } = response.data.data;

			yield put({
				type: 'SET_USER',
				payload: {
					token,
					user: {
						email: user.email,
						userId: user.id,
						stripeId: user.stripe_id,
						planId: user.plan_id,
						widgetToken: user.widget_token,
					},
					isSubscribed: is_subscribe,
					onTrial: on_trial,
				},
			});
		}
	} catch (err) {
		yield put({ type: 'SET_LOADING' });
	}
}

function* getAsyncDataWatcher() {
	yield takeLatest('GET_ME_DATA', getMeData);
	yield takeLatest('GET_USER', getUser);
	yield takeLatest('GET_BUSINESS_PROFILE', getBusinessProfile);
	yield takeLatest('GET_ALL_SHIFTS', getAllShifts);
}

export default function* rootSaga() {
	yield all([getAsyncDataWatcher()]);
}
