import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

const InstagramSuccess = (props) => {
	useEffect(() => {}, []);

	return (
		<Container fluid className={!isMobile ? 'edit-booking' : 'edit-booking-small'}></Container>
	);
};

export default InstagramSuccess;
