import React, { Fragment } from 'react';
import cx from 'classnames';
import styled from 'styled-components';
import cookie from 'js-cookie';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { Button, Container, Nav, Navbar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from 'actions';

// hooks
import { useBreakpoints } from 'hooks/useBreakpoints';

import logoBlack from 'assets/images/logo.png';
import logoWhite from 'assets/images/logo-white.png';

const NavCont = styled.div`
	display: block;
	background: ${(props) => (props.isHome ? '#fff' : '#212a30')};
`;

const NavAbs = styled(Nav)`
	position: absolute;
	width: auto;
	left: 50%;
	transform: translateX(-50%);
	text-align: center;
`;

const NavLink = styled(Link)`
	font-size: ${(props) => props.propsFontSize};
	font-weight: ${(props) => props.propsFontWeight};
`;

const Header = ({ isHome, isHideButton }) => {
	const bp = useBreakpoints();

	const history = useHistory();
	const dispatch = useDispatch();
	const reducers = useSelector((state) => state.userReducers);

	const logout = () => {
		cookie.remove('token');
		dispatch(userActions.logOutUser());
		dispatch(userActions.setLoading());
	};

	return (
		<NavCont isHome={isHome}>
			<Container className="fluid-max-width" fluid>
				<Navbar expand="lg" className={cx('main-navbar', isHome && 'is-home')}>
					<Link to="/" className="navbar-brand">
						<img
							alt="logo"
							src={isHome ? logoBlack : logoWhite}
							width="150"
							height="44"
							className="d-inline-block align-top"
						/>
					</Link>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="ml-auto">
							{/* <NavLink className="navbar-link nav-link" to="/dashboard">
								Dashboard
							</NavLink> */}

							<a className="navbar-link nav-link" href="#reservations">
								Reservations
							</a>
							<a className="navbar-link nav-link" href="#point-of-sale">
								Point of sale
							</a>
							<a className="navbar-link nav-link" href="#payments">
								Payments
							</a>
							<a className="navbar-link nav-link" href="#team-management">
								Team management
							</a>
							<a className="navbar-link nav-link" href="#about-us">
								About Us
							</a>
							{/* 							
							<NavLink className="navbar-link nav-link" to="#">
								Reservations
							</NavLink>
							<NavLink className="navbar-link nav-link" to="#">
								Point of sale
							</NavLink>
							<NavLink className="navbar-link nav-link" to="#">
								Payments
							</NavLink>
							<NavLink className="navbar-link nav-link" to="#">
								Team management
							</NavLink>
							<NavLink className="navbar-link nav-link" to="#">
								About Us
							</NavLink> */}
							{!isHideButton ? (
								!reducers.isAuth ? (
									<Fragment>
										{/* <NavLink className="navbar-link nav-link" to="/login">
                                                                            Already have an Account?
                                                                        </NavLink> */}
										<NavLink className="btn-start-outlined text-decoration-none" to="/login">
											Login
										</NavLink>
									</Fragment>
								) : (
									<Button className="btn-start-outlined" onClick={() => logout()}>
										Logout
									</Button>
								)
							) : (
								''
							)}
						</Nav>
					</Navbar.Collapse>
				</Navbar>
			</Container>
		</NavCont>
	);
};

export default withRouter(Header);
