import React from 'react';
import { Modal } from 'react-bootstrap';
import cx from 'classnames';
import CustModalStyle from '../assets/scss/CustomModal.module.scss';

function CustomModal({
	hasClose,
	hasHeader,
	header,
	isOpen,
	handleClose,
	bgColor,
	backdrop,
	customStyle,
	size,
	customClassName,
	bodyTransparent,
	children,
}) {
	return (
		<Modal
			show={isOpen}
			onHide={handleClose}
			backdrop={backdrop === '' ? true : backdrop}
			keyboard={backdrop === '' ? true : false}
			className={cx(customStyle, bodyTransparent && CustModalStyle.transparent)}
			size={size === '' ? 'md' : size}
			dialogClassName={customClassName}
			centered
		>
			{hasClose && (
				<button
					type="button"
					className={CustModalStyle.customClose}
					onClick={() => handleClose(false)}
				>
					<span aria-hidden="true">×</span>
				</button>
			)}
			{hasHeader && (
				<Modal.Header
					style={{ backgroundColor: bgColor }}
					closeButton={backdrop === 'static' ? false : true}
				>
					<Modal.Title>{header}</Modal.Title>
				</Modal.Header>
			)}
			<Modal.Body>{children}</Modal.Body>
		</Modal>
	);
}

export default CustomModal;
