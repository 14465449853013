import React, { useState } from 'react';
// import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useDispatch } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';

import CardForm from './CardForm';
import { userActions } from '../actions/userActions';
import { stripeKey } from '../utils/Utility';

const stripePromise = loadStripe(stripeKey);

const PaymentForm = (props) => {
	// const history = useHistory();
	const dispatch = useDispatch();
	const [hideAlert, setHideAlert] = useState(false);

	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			className="modal-pay"
		>
			<Modal.Body className="payment">
				<SweetAlert
					success
					show={hideAlert}
					title="success!"
					onConfirm={() => {
						dispatch(userActions.setSubscribed());
						// props.onHide();
						// setHideAlert(false);
						// history.push('/dashboard');
					}}
				/>
				<Elements stripe={stripePromise}>
					<CardForm setHideAlert={setHideAlert} plan={props.plan} />
				</Elements>
			</Modal.Body>
		</Modal>
	);
};

export default PaymentForm;
