import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
} from '@material-ui/core';
import moment from 'moment';
import styled from 'styled-components';
import EmployeeSchedule from './EmployeeSchedule';
import DayViewRow from './DayViewRow';
import TeamView from './TeamView';

// const useStyles = makeStyles((theme) => ({
// 	paper: {
// 		display: 'flex',
// 		alignItems: 'center',
// 		justifyContent: 'center',
// 		outline: 'none',
// 		background: 'white',
// 		fontSize: 12,
// 		fontWeight: 550,
// 		color: '#413636',
// 	},
// }));

const Wrapper = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	padding: 4px;
	// background: gray;
	border-radius: 5px;
	overflow: hidden;
`;

const StyledPaper = styled(Paper)`
	&& {
		margin: 0;
		padding: 0;

		::-webkit-scrollbar {
			width: 3px;
			height: 0px;
			background-color: transparent;
		}
		::-webkit-scrollbar-thumb {
			width: 1px;
			height: 0px;

			border-radius: 20px;
			background-color: #b3b3b3;
		}

		::-webkit-scrollbar-corner {
			display: none;
		}
	}
`;

const StyledTableCell = styled(TableCell)`
	&& {
		margin: 0;
		padding: 0;
		font-size: 12px;
		font-weight: 550;
		color: #413636;
	}

	#date-container {
		cursor: pointer;
		user-select: none;
		border-radius: 2em;

		&:active {
			background: #21252917;
		}
	}

	// input#session-date::-webkit-calendar-picker-indicator {
	// 	display: block;
	// 	top: 0;
	// 	left: 0;
	// 	background: #0000;
	// 	position: absolute;
	// 	transform: scale(12);
	// }
`;

function RosterCalendarGrid(props) {
	const history = useHistory();
	const shifts = history.location.state.find((e) => e.shifts).shifts;
	const floors = history.location.state.find((e) => e.floors).floors;
	const [selectedEmployees, setSelectedEmployees] = useState([]);
	const [employeeShifts, setEmployeeShifts] = useState([]);

	const checkEmployeeShifts = (shifts) => {
		let newArray = [];
		if (shifts.length > 0) {
			shifts.map((e) => {
				if (e.shifts.length > 0) {
					newArray = newArray.concat(e.shifts);
				}
			});
		}
		setEmployeeShifts(newArray);
		props.setEmployeeShifts(newArray);
	};

	const handleUpdateShift = (shifts) => {
		let newArray = [];
		let shiftsId = shifts.map((e) => e.id);
		let remaining = employeeShifts.filter((e) => !shiftsId.includes(e.id));
		newArray = newArray.concat(shifts);
		newArray = newArray.concat(remaining);
		setEmployeeShifts(newArray);
		props.setEmployeeShifts(newArray);
	};

	const handleDeleteShift = (shifts) => {
		// let newArray = [];
		// let shiftsId = shifts.map((e) => e.id);
		// let remaining = employeeShifts.filter((e) => !shiftsId.includes(e.id));
		// newArray = newArray.concat(shifts);
		// newArray = newArray.concat(remaining);
		setEmployeeShifts(shifts);
		props.setEmployeeShifts(shifts);
	};

	const handleAddEmployeeShift = (shifts) => {
		setEmployeeShifts(shifts);
	};

	const handleClickDateCell = (date) => {
		props.setBreakDownModalHandler({ date: date, boolean: true });
	};
	// console.log(employeeShifts);

	useEffect(() => {
		if (props.employeeShifts.length > 0) {
			checkEmployeeShifts(props.employeeShifts);
		}
	}, [props.employeeShifts]);

	useEffect(() => {
		setSelectedEmployees(props.selectedEmployees);
	}, [employeeShifts, props.selectedEmployees]);

	return (
		<Wrapper>
			{props.segmentedOption === '1' ? (
				<TableContainer component={StyledPaper} elevation={0}>
					<Table>
						<TableHead>
							<TableRow>
								<StyledTableCell style={{ cursor: 'default', width: 129 }}></StyledTableCell>
								<StyledTableCell align="center">
									<div
										id="date-container"
										onClick={() =>
											handleClickDateCell(
												moment(props.selectedScheduleDate.start_date).format('YYYY-MM-DD'),
											)
										}
									>
										{moment(props.selectedScheduleDate.start_date).format('ddd, MMM DD')}
									</div>
								</StyledTableCell>
								<StyledTableCell align="center">
									<div
										id="date-container"
										onClick={() =>
											handleClickDateCell(
												moment(props.selectedScheduleDate.start_date)
													.add(1, 'day')
													.format('YYYY-MM-DD'),
											)
										}
									>
										{moment(props.selectedScheduleDate.start_date)
											.add(1, 'day')
											.format('ddd, MMM DD')}
									</div>
								</StyledTableCell>
								<StyledTableCell align="center">
									<div
										id="date-container"
										onClick={() =>
											handleClickDateCell(
												moment(props.selectedScheduleDate.start_date)
													.add(2, 'day')
													.format('YYYY-MM-DD'),
											)
										}
									>
										{moment(props.selectedScheduleDate.start_date)
											.add(2, 'day')
											.format('ddd, MMM DD')}
									</div>
								</StyledTableCell>
								<StyledTableCell align="center">
									<div
										id="date-container"
										onClick={() =>
											handleClickDateCell(
												moment(props.selectedScheduleDate.start_date)
													.add(3, 'day')
													.format('YYYY-MM-DD'),
											)
										}
									>
										{moment(props.selectedScheduleDate.start_date)
											.add(3, 'day')
											.format('ddd, MMM DD')}
									</div>
								</StyledTableCell>
								<StyledTableCell align="center">
									<div
										id="date-container"
										onClick={() =>
											handleClickDateCell(
												moment(props.selectedScheduleDate.start_date)
													.add(4, 'day')
													.format('YYYY-MM-DD'),
											)
										}
									>
										{moment(props.selectedScheduleDate.start_date)
											.add(4, 'day')
											.format('ddd, MMM DD')}
									</div>
								</StyledTableCell>
								<StyledTableCell align="center">
									<div
										id="date-container"
										onClick={() =>
											handleClickDateCell(
												moment(props.selectedScheduleDate.start_date)
													.add(5, 'day')
													.format('YYYY-MM-DD'),
											)
										}
									>
										{moment(props.selectedScheduleDate.start_date)
											.add(5, 'day')
											.format('ddd, MMM DD')}
									</div>
								</StyledTableCell>
								<StyledTableCell align="center">
									<div
										id="date-container"
										onClick={() =>
											handleClickDateCell(
												moment(props.selectedScheduleDate.start_date)
													.add(6, 'day')
													.format('YYYY-MM-DD'),
											)
										}
									>
										{moment(props.selectedScheduleDate.start_date)
											.add(6, 'day')
											.format('ddd, MMM DD')}
									</div>
								</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{selectedEmployees.length > 0 ? (
								selectedEmployees.map((employee, key) => {
									return (
										<EmployeeSchedule
											key={key}
											stations={props.stations}
											employee={employee}
											employmentRoles={props.employmentRoles}
											handleDeleteShift={handleDeleteShift}
											handleUpdateShift={handleUpdateShift}
											handleAddEmployeeShift={handleAddEmployeeShift}
											selectEmployeeCallback={props.selectEmployeeCallback}
											createStations={props.createStations}
											employeeShifts={employeeShifts}
											selectedScheduleDate={props.selectedScheduleDate}
											employeeShiftsCallback={props.setEmployeeShifts}
										/>
									);
								})
							) : (
								<></>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			) : props.segmentedOption === '2' ? (
				<TableContainer component={StyledPaper} elevation={0}>
					<Table>
						<TableHead>
							<TableRow>
								<StyledTableCell align="center">
									<div
										id="date-container"
										onClick={() =>
											handleClickDateCell(
												moment(props.selectedScheduleDate.start_date).format('YYYY-MM-DD'),
											)
										}
									>
										{moment(props.selectedScheduleDate.start_date).format('ddd, MMM DD')}
									</div>
								</StyledTableCell>
								<StyledTableCell align="center">
									<div
										id="date-container"
										onClick={() =>
											handleClickDateCell(
												moment(props.selectedScheduleDate.start_date)
													.add(1, 'day')
													.format('YYYY-MM-DD'),
											)
										}
									>
										{moment(props.selectedScheduleDate.start_date)
											.add(1, 'day')
											.format('ddd, MMM DD')}
									</div>
								</StyledTableCell>
								<StyledTableCell align="center">
									<div
										id="date-container"
										onClick={() =>
											handleClickDateCell(
												moment(props.selectedScheduleDate.start_date)
													.add(2, 'day')
													.format('YYYY-MM-DD'),
											)
										}
									>
										{moment(props.selectedScheduleDate.start_date)
											.add(2, 'day')
											.format('ddd, MMM DD')}
									</div>
								</StyledTableCell>
								<StyledTableCell align="center">
									<div
										id="date-container"
										onClick={() =>
											handleClickDateCell(
												moment(props.selectedScheduleDate.start_date)
													.add(3, 'day')
													.format('YYYY-MM-DD'),
											)
										}
									>
										{moment(props.selectedScheduleDate.start_date)
											.add(3, 'day')
											.format('ddd, MMM DD')}
									</div>
								</StyledTableCell>
								<StyledTableCell align="center">
									<div
										id="date-container"
										onClick={() =>
											handleClickDateCell(
												moment(props.selectedScheduleDate.start_date)
													.add(4, 'day')
													.format('YYYY-MM-DD'),
											)
										}
									>
										{moment(props.selectedScheduleDate.start_date)
											.add(4, 'day')
											.format('ddd, MMM DD')}
									</div>
								</StyledTableCell>
								<StyledTableCell align="center">
									<div
										id="date-container"
										onClick={() =>
											handleClickDateCell(
												moment(props.selectedScheduleDate.start_date)
													.add(5, 'day')
													.format('YYYY-MM-DD'),
											)
										}
									>
										{moment(props.selectedScheduleDate.start_date)
											.add(5, 'day')
											.format('ddd, MMM DD')}
									</div>
								</StyledTableCell>
								<StyledTableCell align="center">
									<div
										id="date-container"
										onClick={() =>
											handleClickDateCell(
												moment(props.selectedScheduleDate.start_date)
													.add(6, 'day')
													.format('YYYY-MM-DD'),
											)
										}
									>
										{moment(props.selectedScheduleDate.start_date)
											.add(6, 'day')
											.format('ddd, MMM DD')}
									</div>
								</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{shifts.length > 0 ? (
								shifts.map((e, key) => {
									return (
										<DayViewRow
											key={key}
											shift={e}
											floors={floors}
											date={props.selectedScheduleDate.start_date}
											employees={props.employees}
											employeeShifts={employeeShifts}
											employmentRoles={props.employmentRoles}
										/>
									);
								})
							) : (
								<></>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			) : props.segmentedOption === '3' ? (
				<TableContainer component={StyledPaper} elevation={0}>
					<div
						style={{
							width: '100%',
							height: '90%',
							color: '#404040',
							fontSize: 30,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						COMING SOON
					</div>

					{/* <Table>
						<TableHead>
							<TableRow>
								<StyledTableCell align="center">
									<div
										id="date-container"
										onClick={() =>
											handleClickDateCell(
												moment(props.selectedScheduleDate.start_date).format('YYYY-MM-DD'),
											)
										}
									>
										{moment(props.selectedScheduleDate.start_date).format('ddd, MMM DD')}
									</div>
								</StyledTableCell>
								<StyledTableCell align="center">
									<div
										id="date-container"
										onClick={() =>
											handleClickDateCell(
												moment(props.selectedScheduleDate.start_date)
													.add(1, 'day')
													.format('YYYY-MM-DD'),
											)
										}
									>
										{moment(props.selectedScheduleDate.start_date)
											.add(1, 'day')
											.format('ddd, MMM DD')}
									</div>
								</StyledTableCell>
								<StyledTableCell align="center">
									<div
										id="date-container"
										onClick={() =>
											handleClickDateCell(
												moment(props.selectedScheduleDate.start_date)
													.add(2, 'day')
													.format('YYYY-MM-DD'),
											)
										}
									>
										{moment(props.selectedScheduleDate.start_date)
											.add(2, 'day')
											.format('ddd, MMM DD')}
									</div>
								</StyledTableCell>
								<StyledTableCell align="center">
									<div
										id="date-container"
										onClick={() =>
											handleClickDateCell(
												moment(props.selectedScheduleDate.start_date)
													.add(3, 'day')
													.format('YYYY-MM-DD'),
											)
										}
									>
										{moment(props.selectedScheduleDate.start_date)
											.add(3, 'day')
											.format('ddd, MMM DD')}
									</div>
								</StyledTableCell>
								<StyledTableCell align="center">
									<div
										id="date-container"
										onClick={() =>
											handleClickDateCell(
												moment(props.selectedScheduleDate.start_date)
													.add(4, 'day')
													.format('YYYY-MM-DD'),
											)
										}
									>
										{moment(props.selectedScheduleDate.start_date)
											.add(4, 'day')
											.format('ddd, MMM DD')}
									</div>
								</StyledTableCell>
								<StyledTableCell align="center">
									<div
										id="date-container"
										onClick={() =>
											handleClickDateCell(
												moment(props.selectedScheduleDate.start_date)
													.add(5, 'day')
													.format('YYYY-MM-DD'),
											)
										}
									>
										{moment(props.selectedScheduleDate.start_date)
											.add(5, 'day')
											.format('ddd, MMM DD')}
									</div>
								</StyledTableCell>
								<StyledTableCell align="center">
									<div
										id="date-container"
										onClick={() =>
											handleClickDateCell(
												moment(props.selectedScheduleDate.start_date)
													.add(6, 'day')
													.format('YYYY-MM-DD'),
											)
										}
									>
										{moment(props.selectedScheduleDate.start_date)
											.add(6, 'day')
											.format('ddd, MMM DD')}
									</div>
								</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{shifts.length > 0 ? (
								shifts.map((shift, key) => {
									console.log(floors);
									return (
										<TeamView
											key={key}
											shift={shift}
											floors={floors}
											employmentRoles={props.employmentRoles}
										/>
									);
								})
							) : (
								<></>
							)}
						</TableBody>
					</Table> */}
				</TableContainer>
			) : (
				<TableContainer component={StyledPaper} elevation={0}>
					<div
						style={{
							width: '100%',
							height: '90%',
							color: '#404040',
							fontSize: 30,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						COMING SOON
					</div>
					{/* <Table>
						<TableHead>
							<TableRow>
								<StyledTableCell align="center">
									<div
										id="date-container"
										onClick={() =>
											handleClickDateCell(
												moment(props.selectedScheduleDate.start_date).format('YYYY-MM-DD'),
											)
										}
									>
										{moment(props.selectedScheduleDate.start_date).format('ddd, MMM DD')}
									</div>
								</StyledTableCell>
								<StyledTableCell align="center">
									<div
										id="date-container"
										onClick={() =>
											handleClickDateCell(
												moment(props.selectedScheduleDate.start_date)
													.add(1, 'day')
													.format('YYYY-MM-DD'),
											)
										}
									>
										{moment(props.selectedScheduleDate.start_date)
											.add(1, 'day')
											.format('ddd, MMM DD')}
									</div>
								</StyledTableCell>
								<StyledTableCell align="center">
									<div
										id="date-container"
										onClick={() =>
											handleClickDateCell(
												moment(props.selectedScheduleDate.start_date)
													.add(2, 'day')
													.format('YYYY-MM-DD'),
											)
										}
									>
										{moment(props.selectedScheduleDate.start_date)
											.add(2, 'day')
											.format('ddd, MMM DD')}
									</div>
								</StyledTableCell>
								<StyledTableCell align="center">
									<div
										id="date-container"
										onClick={() =>
											handleClickDateCell(
												moment(props.selectedScheduleDate.start_date)
													.add(3, 'day')
													.format('YYYY-MM-DD'),
											)
										}
									>
										{moment(props.selectedScheduleDate.start_date)
											.add(3, 'day')
											.format('ddd, MMM DD')}
									</div>
								</StyledTableCell>
								<StyledTableCell align="center">
									<div
										id="date-container"
										onClick={() =>
											handleClickDateCell(
												moment(props.selectedScheduleDate.start_date)
													.add(4, 'day')
													.format('YYYY-MM-DD'),
											)
										}
									>
										{moment(props.selectedScheduleDate.start_date)
											.add(4, 'day')
											.format('ddd, MMM DD')}
									</div>
								</StyledTableCell>
								<StyledTableCell align="center">
									<div
										id="date-container"
										onClick={() =>
											handleClickDateCell(
												moment(props.selectedScheduleDate.start_date)
													.add(5, 'day')
													.format('YYYY-MM-DD'),
											)
										}
									>
										{moment(props.selectedScheduleDate.start_date)
											.add(5, 'day')
											.format('ddd, MMM DD')}
									</div>
								</StyledTableCell>
								<StyledTableCell align="center">
									<div
										id="date-container"
										onClick={() =>
											handleClickDateCell(
												moment(props.selectedScheduleDate.start_date)
													.add(6, 'day')
													.format('YYYY-MM-DD'),
											)
										}
									>
										{moment(props.selectedScheduleDate.start_date)
											.add(6, 'day')
											.format('ddd, MMM DD')}
									</div>
								</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{shifts.length > 0 ? (
								shifts.map((shift, key) => {
									return (
										<TeamView key={key} shift={shift} employmentRoles={props.employmentRoles} />
									);
								})
							) : (
								<></>
							)}
						</TableBody>
					</Table> */}
				</TableContainer>
			)}
		</Wrapper>
	);
}

export default RosterCalendarGrid;
