import React, { useEffect, useState } from 'react';
import btnUpArrowIcon from '../../../../../../assets/images/booking/up-arrow-icon.png';
import styled from 'styled-components';

const Wrapper = styled.div`
	display: flex;
	position: absolute;
	width: 256px;
	// height: ${(props) => (props.isOpen ? '340px' : '45px')};
	// background-color: white;
	align-items: flex-end;
	overflow: hidden;
`;
const DropDownContainer = styled.div`
	position: relative;
	width: 256px;
	// display: inline-block;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
`;

const DropUpContent = styled.div`
	display: block;
	position: absolute;
	// border-radius: 10px;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
	border-bottom: 1px solid #343643;
	background: rgba(0, 0, 0, 0.8);
	width: 256px;
	padding: 0;
	margin: 0;
	color: white;
	font-size: 14px;
	// bottom: -200px;
	right: 0px;
	transition: 200ms;
`;

const DropDownHeader = styled.button`
	padding: 10px 15px;
	text-align: start;
	width: 256px;
	color: white;
	// margin-right: 10px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	font-size: 14px;
	border-bottom: 1px solid #343643;
	cursor: pointer;
	transition: 200ms;

	img {
		position: absolute;
		right: 10px;
		top: 20px;
	}
`;

const ImgContainer = styled.div`
	display: flex;
	position: absolute;
	width: 240px;
	height: 38px;
	top: 0;
	// z-index: 5;
	img {
		position: absolute;
		right: 10px;
		top: 20px;
	}
`;
const DropUpContainer = styled.div`
	display: block;
	// background: rgba(0, 0, 0, 0.8);
	position: absolute;
	top: 31px;
	right: 0;
	// z-index: 0;
`;

const ListItem = styled.div`
	list-style: none;
	cursor: pointer;
	margin: 0.8em 0;
	padding: 0 0.8em;
`;

function TableCombinationFloorPicker(props) {
	const [isOpen, setIsOpen] = useState(false);
	const [selectedFloor, setSelectedFloor] = useState([]);

	let height = isOpen ? 45 * props.floors.length + 25 : 45;
	let bottom = isOpen ? 25 : -(30 * (props.floors.length + 2));

	const toggling = () => {
		if (isOpen) {
			setIsOpen(!isOpen);
		} else {
			setIsOpen(!isOpen);
		}
	};

	const selectFloorHandler = (floor) => {
		if (!isOpen) {
			return;
		}
		props.selectFloorCallBack(floor);
		setSelectedFloor(floor);
		setIsOpen(!isOpen);
	};

	// const floorChecker = (id, uuid, selectedId) => {
	// 	if (selectedfloor.uuid !== undefined) {
	// 	}
	// };

	useEffect(() => {
		setSelectedFloor(props.selectedFloor);
	}, [props.selectedFloor]);

	// console.log(isOpen);
	return (
		<Wrapper isOpen={isOpen} style={{ height: height, bottom: 0, right: 0 }}>
			<DropDownContainer>
				<DropDownHeader
					style={isOpen ? { background: 'rgba(0, 0, 0, 0.8)' } : { background: 'transparent' }}
				>
					{props.floors.length === 0 ? '- No Selected Floor -' : selectedFloor.floor_name}
					<ImgContainer onClick={toggling}>
						<img
							src={btnUpArrowIcon}
							width="9"
							height="5"
							alt="checker"
							style={
								isOpen
									? { transition: '200ms' }
									: { transition: '200ms', transform: 'rotate(180deg)' }
							}
						/>
					</ImgContainer>
					<DropUpContainer>
						<DropUpContent style={{ zIndex: 1, bottom: bottom }}>
							{props.floors.length !== 0
								? props.floors.map((option, key) => (
										<ListItem
											style={{
												color:
													selectedFloor !== undefined
														? selectedFloor.uuid !== undefined
															? option.uuid === selectedFloor.uuid
																? '#FE7D86'
																: ''
															: option.id === selectedFloor.id
															? '#FE7D86'
															: 'white'
														: '',
											}}
											onClick={() => selectFloorHandler(option)}
											key={Math.random()}
										>
											{option.floor_name}
										</ListItem>
								  ))
								: ''}
						</DropUpContent>
					</DropUpContainer>
				</DropDownHeader>
			</DropDownContainer>
		</Wrapper>
	);
}

export default TableCombinationFloorPicker;
