import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import styled from 'styled-components';
import moment from 'moment';

// Components
import RosterSegmentControl from './RosterSegmentControl';

// Icons
import xIcon from 'assets/images/booking/exit-btn-table-details.png';
import GroupScheduleFilter from './GroupScheduleFilter';
import RosterCalendarGrid from './RosterCalendarGrid';
import DotLoader from 'pages/Dashboard/Booking/TableLayoutPlanning/pages/SubComponents/DotLoader';
import BreakDownModal from './BreakDownModal';
import { WAGEPAYTYPE, WAGEWEEKENDTYPE } from 'constants/enum';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		outline: 'none',
	},
}));

const StyledBackdrop = withStyles({
	root: {
		background: 'rgb(255 255 255 / 30%);',
		backdropFilter: 'blur(10px)',
	},
})(Backdrop);

const Wrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100vh;
	height: 95vh;
	background: #fdfdfd;
	border-radius: 10px;
	box-shadow: 1px 4px 10px #1c1c1e4d;
`;

const StyledRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	width: 100%;
	padding: 20px;
`;

const StyledCol = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
`;

const FilterDropDownContainer = styled(StyledCol)`
	position: normal;
	padding: 0;
	margin-top: 30px;
	user-select: none;
`;

const CloseIcon = styled.div`
	position: absolute;
	left: 0;
	display: flex;
	margin-top: 3px;
	cursor: pointer;

	img {
		user-select: none;
		-webkit-user-drag: none;
		-khtml-user-drag: none;
		-moz-user-drag: none;
		-o-user-drag: none;
		user-drag: none;
	}
`;

const StyledButton = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 160px;
	height: 18px;
	font-size: 10px;
	font-weight: 700;
	color: #c67575;
	background: #ffffff;
	user-select: none;
	box-shadow: 1px 4px 10px #1c1c1e4d;
	margin: 0 10px;
	border-radius: 2em;
	cursor: pointer;

	&:active {
		background: #e6e6e6;
	}
`;

const SaveButton = styled(StyledButton)`
	background: #fbd4d4;
	color: #826160;

	&:active {
		background: #fbd0d0;
	}
`;

const WeeklyTotalContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	background: #ffd9d9;
	width: 178px;
	height: fit-content;
	padding: 5px 10px;
	user-select: none;
	cursor: pointer;
	box-shadow: 1px 4px 10px #1c1c1e4d;
	border-radius: 5px;

	h4 {
		font-size: 18px;
		font-weight: bold;
		color: #c67575;
	}

	p {
		font-size: 15px;
		font-weight: bold;
		white-space: nowrap;
		color: #c67575;
		padding-bottom: 3px;
	}
`;

function RosterItem(props) {
	const classes = useStyles();
	const [segmentedOption, setSegmentedOption] = useState('1');
	const [selectedEmployees, setSelectedEmployees] = useState([]);
	const [breakDownModalHandler, setBreakDownModalHandler] = useState({
		date: null,
		boolean: false,
	});
	const [employeeShifts, setEmployeeShifts] = useState([]);
	const [isTableDateSelected, setIsTableDateSelected] = useState(false);
	const [copyShiftErrorMsg, setCopyShiftErrorMsg] = useState(false);

	const handleClose = () => {
		props.setOpenScheduleDate(false);
	};

	const selectedOptionCallback = (option) => {
		setSegmentedOption(option);
	};

	const selectEmployeeCallback = (empl) => {
		let newArray = [...selectedEmployees];
		const ids = selectedEmployees.map((e) => e.id);
		if (ids.includes(empl.id)) {
			let idx = newArray.findIndex((e) => e.id === empl.id);
			newArray.splice(idx, 1);
		} else {
			newArray.push(empl);
		}
		setSelectedEmployees(newArray);
	};

	const saveAndPublish = () => {
		let params = {
			employee_schedule_date_id: props.selectedScheduleDate.id,
			employee: employeeShifts,
		};
		console.log(JSON.stringify(employeeShifts, null, 4));
		props.createEmployeeSchedule(params);
		// handleClose();
	};

	const openBreakDownModal = (date, bool) => {
		console.log(date, bool);
		let data = {
			date: date ? date : null,
			boolean: bool,
		};
		setBreakDownModalHandler(data);
	};

	const computeTotalHours = (array) => {
		if (array.length > 0) {
			let hour = 0;
			let minute = 0;
			array.map((e) => {
				const diff = e.end_time - e.start_time;
				const h = Math.floor(diff / 3600);
				const m = Math.floor((diff - h * 3600) / 60);
				hour = Math.abs(h) + hour;
				minute = Math.abs(m) + minute;
			});
			return (
				<p style={{ fontSize: 14, fontWeight: 'bold' }}>
					{hour} h {moment().minute(minute).format('mm')} m
				</p>
			);
		}
	};

	const computeTotalWages = (array) => {
		//Main function for base rate computation
		if (array.length > 0) {
			let rate = 0;
			let emplRate = 0;
			array.map((shift) => {
				const dateOfYear = moment(shift.date).dayOfYear();
				const dayOfWeek = moment(shift.date).isoWeekday();
				const employee = props.employees.find((empl) => empl.id === shift.employee_id);
				if (employee.pay_wages_item.length > 0) {
					emplRate = checkPayWageRate(employee, dateOfYear, dayOfWeek);
				} else {
					emplRate = employee.rate ? parseInt(employee.rate) : 0;
				}

				const diff = shift.end_time - shift.start_time;
				const h = Math.floor(diff / 3600);
				const m = Math.floor((diff - h * 3600) / 60);
				rate = emplRate * h + rate;
			});
			return <p style={{ fontSize: 14 }}>${(Math.round(rate * 100) / 100).toFixed(2)}</p>;
		}
	};

	const checkPayWageRate = (empl, dateOfYear, dayOfWeek) => {
		let rate = 0;
		empl.pay_wages_item.map((wage) => {
			if (wage.wage_type === WAGEPAYTYPE.holiday) {
				const wageDateOfYear = moment(wage.holiday_date).dayOfYear();
				if (wageDateOfYear === dateOfYear) {
					rate = wage.rate;
					return rate;
				} else {
					rate = empl.rate ? parseInt(empl.rate) : 0;
					return rate;
				}
			} else if (wage.wage_type === WAGEPAYTYPE.weekend) {
				const wageDayOfWeek = wage.wage_week_end_type === WAGEWEEKENDTYPE.saturday ? 6 : 7;
				if (wageDayOfWeek === dayOfWeek) {
					rate = wage.rate;
					return rate;
				} else {
					rate = empl.rate ? parseInt(empl.rate) : 0;
					return rate;
				}
			}
		});
		return rate;
	};

	useEffect(() => {
		if (props.employeeShifts.length > 0) {
			let ids = props.employeeShifts.map((e) => e.id);
			let selectedEmpls = props.employees.filter((e) => ids.includes(e.id));
			setSelectedEmployees(selectedEmpls);
			// console.log(selectedEmpls);
			// console.log('yes');
		} else {
			setSelectedEmployees([]);
		}
	}, [props.employeeShifts]);

	return (
		<Modal
			aria-labelledby="create-employee-schedule-modal"
			aria-describedby="create-employee-schedule-modal-description"
			className={classes.modal}
			open={props.openScheduleDate}
			onClose={handleClose}
			// keepMounted
			closeAfterTransition
			BackdropComponent={StyledBackdrop}
		>
			<Fade in={props.openScheduleDate}>
				<div className={classes.paper}>
					<Wrapper>
						<StyledRow>
							<StyledCol>
								<StyledRow style={{ padding: 0, position: 'relative' }}>
									<CloseIcon onClick={handleClose}>
										<img src={xIcon} width={10} height={12} alt="close-icon" />
									</CloseIcon>

									<RosterSegmentControl
										optionCount={4}
										labelContents={['Week view', 'Day view', 'Team view', 'Floor view']}
										selectedOptionCallback={selectedOptionCallback}
									/>
									<StyledButton>Copy all shifts</StyledButton>
									<SaveButton onClick={saveAndPublish}>Save and Publish</SaveButton>
								</StyledRow>
								<FilterDropDownContainer>
									{props.selectedScheduleDate ? (
										<h4
											style={{
												fontSize: 19,
												fontWeight: 700,
												color: '#413636',
												marginBottom: 10,
											}}
										>
											{moment(props.selectedScheduleDate.start_date).format('ddd, MMM DD')} -{' '}
											{moment(props.selectedScheduleDate.end_date).format('ddd, MMM DD')}
										</h4>
									) : (
										''
									)}

									<GroupScheduleFilter
										employmentRoles={props.employmentRoles}
										employees={props.employees}
										groupSchedule={props.groupSchedule}
										selectedEmployees={selectedEmployees}
										selectEmployeeCallback={selectEmployeeCallback}
										segmentedOption={segmentedOption}
									/>
								</FilterDropDownContainer>
							</StyledCol>

							<WeeklyTotalContainer
								onClick={() => openBreakDownModal(null, !breakDownModalHandler.boolean)}
							>
								<h4>Weekly Total</h4>
								<p>Total Hours</p>

								{computeTotalHours(employeeShifts)}

								<p>Est Total Wages</p>
								{computeTotalWages(employeeShifts)}
							</WeeklyTotalContainer>
							{/* {props.selectedScheduleDate ? <div>{props.selectedScheduleDate.start_date}</div> : ''} */}
						</StyledRow>

						<RosterCalendarGrid
							stations={props.stations}
							employeeShifts={props.employeeShifts}
							segmentedOption={segmentedOption}
							selectedEmployees={selectedEmployees}
							employmentRoles={props.employmentRoles}
							selectedScheduleDate={props.selectedScheduleDate}
							selectEmployeeCallback={selectEmployeeCallback}
							createStations={props.createStations}
							createEmployeeSchedule={props.createEmployeeSchedule}
							employees={props.employees}
							setEmployeeShifts={setEmployeeShifts}
							setBreakDownModalHandler={setBreakDownModalHandler}
						/>
						{props.isUpdating ? (
							<div style={{ position: 'absolute', height: '100%', width: '100%', zIndex: 5 }}>
								<DotLoader />
							</div>
						) : (
							''
						)}

						<BreakDownModal
							employeeShifts={employeeShifts}
							stations={props.stations}
							employees={props.employees}
							selectedEmployees={selectedEmployees}
							breakDownModalHandler={breakDownModalHandler}
							setBreakDownModalHandler={setBreakDownModalHandler}
						/>
					</Wrapper>
				</div>
			</Fade>
		</Modal>
	);
}

export default RosterItem;
