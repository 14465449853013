import moment from 'moment';
import React from 'react';
import { Media } from 'react-bootstrap';

function BookingCalendarArrow(props) {
	const nextDate = () => {
		props.setSelectedDate(moment(props.selectedDate).add(1, 'day').format('yyyy-MM-DD'), 'next');
		if (props.setDateChangeHandler && props.setSelectedDateChanged !== undefined) {
			props.setSelectedDateChanged(true);
			props.setDateChangeHandler(true);
		}
	};

	const prevDate = () => {
		props.setSelectedDate(
			moment(props.selectedDate).subtract(1, 'day').format('yyyy-MM-DD'),
			'prev',
		);
		if (props.setDateChangeHandler && props.setSelectedDateChanged !== undefined) {
			props.setSelectedDateChanged(true);
			props.setDateChangeHandler(true);
		}
	};

	return (
		<div>
			{props.arrowType === 'left' ? (
				<Media className="btn-arrow" onClick={() => prevDate()} style={{ cursor: 'pointer' }}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="7.656"
						height="13.314"
						viewBox="0 0 7.656 13.314"
					>
						<path
							id="Union_5"
							data-name="Union 5"
							d="M6.364,13.435.707,7.778a1,1,0,0,1,0-1.414L6.364.707A1,1,0,0,1,7.778,2.121l-4.95,4.95,4.95,4.95a1,1,0,1,1-1.414,1.415Z"
							transform="translate(-0.414 -0.414)"
							fill="#bcbcbc"
						/>
					</svg>
				</Media>
			) : (
				<Media className="btn-arrow" onClick={() => nextDate()} style={{ cursor: 'pointer' }}>
					<svg
						id="Arrow"
						xmlns="http://www.w3.org/2000/svg"
						width="7.656"
						height="13.314"
						viewBox="0 0 7.656 13.314"
					>
						<path
							id="Union_6"
							data-name="Union 6"
							d="M6.364,13.435.707,7.778a1,1,0,0,1,0-1.414L6.364.707A1,1,0,0,1,7.778,2.121l-4.95,4.95,4.95,4.95a1,1,0,1,1-1.414,1.415Z"
							transform="translate(8.071 13.728) rotate(180)"
							fill="#bcbcbc"
						/>
					</svg>
				</Media>
			)}
		</div>
	);
}

export default BookingCalendarArrow;
