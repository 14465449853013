import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { apiUrl } from '../../utils/Utility';

import withDashboardApp from '../../components/withDashboardApp';
import { userActions } from '../../actions';

const emailSchema = Yup.object().shape({
	email: Yup.string().email('invalid email address').required('email address required'),
});

const passwordSchema = Yup.object().shape({
	currentPassword: Yup.string().required('current password required'),
	newPassword: Yup.string()
		.min(6, 'password must not be less than 8 characters')
		.required('new password is required'),
	confirmPassword: Yup.string()
		.required('confirm password is required')
		.when('newPassword', {
			is: (val) => (val && val.length > 0 ? true : false),
			then: Yup.string().oneOf([Yup.ref('newPassword')], 'not match, please re-enter'),
		}),
});

const Box = styled.div`
	display: block;
	padding: 35px;
`;

const EditProfile = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const reducers = useSelector((state) => state.userReducers);

	const goToDashboard = () => history.push('/dashboard');

	// update email
	const onUpdateEmail = async (values, { setStatus, setSubmitting }) => {
		console.log(values);

		try {
			const response = await axios({
				url: `${apiUrl}/api/change_email`,
				method: 'POST',
				data: {
					new_email: values.email,
				},
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${reducers.token}`,
				},
			});

			if (response) {
				const { status_code, new_email, message } = response.data;

				if (status_code === 200) {
					const { userId, stripeId, planId } = reducers.user;

					setSubmitting(false);
					setStatus({
						success: true,
						message,
					});

					dispatch(
						userActions.updateUser({
							userId,
							email: new_email,
							stripeId,
							planId,
						}),
					);
				}
			}
		} catch (err) {
			console.log(err.response);
			const { status, data } = err.response;
			setSubmitting(false);

			if (status === 422) {
				setStatus({
					success: false,
					message: data.message,
				});
			}
		}
	};

	// update password
	const onResetPassword = async (values, { setSubmitting, setStatus, resetForm }) => {
		try {
			const response = await axios({
				url: `${apiUrl}/api/reset-password`,
				method: 'POST',
				data: {},
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${reducers.token}`,
				},
			});

			if (response) {
				const { status_code } = response.data;

				if (status_code === 200) {
					setSubmitting(false);
					resetForm();
					setStatus({
						success: true,
						message: 'Reset password sent to your email',
					});
				}
			}
		} catch (err) {
			const { status, data } = err.response;
			setSubmitting(false);

			if (status === 422) {
				setStatus({
					success: false,
					message: data.message,
				});
			}
		}
	};

	useEffect(() => {
		console.log(reducers);
	}, [reducers]);

	return (
		<div>
			<p className="go-back">
				<button type="button" onClick={goToDashboard}>
					Go Back
				</button>
			</p>
			<Row>
				<Col lg={6} md={12} sm={12}>
					<Box className="box-db edit-profile">
						<Formik
							initialValues={{
								email: reducers.user.email,
							}}
							validationSchema={emailSchema}
							onSubmit={onUpdateEmail}
						>
							{({ status, errors, touched, values, handleSubmit, handleChange, isSubmitting }) => {
								const errMessage = [];
								if (Object.keys(errors).length > 0 && Object.keys(touched).length > 0) {
									Object.values(errors).forEach((v) => {
										errMessage.push(v);
									});
								}

								return (
									<Form onSubmit={handleSubmit}>
										<p>Account Email</p>
										<Form.Row>
											<Form.Group as={Col} controlId="formBasicEmail" className="edit-inp">
												<Form.Control
													className="input-signup"
													type="text"
													name="email"
													value={values.email}
													onChange={handleChange}
													placeholder="Enter email"
													style={{ color: '#000000' }}
												/>
											</Form.Group>
										</Form.Row>

										{errMessage.length > 0 ? (
											<Form.Row>
												<Form.Group as={Col} className="form-errors">
													<span className="text-danger">
														{`Error/s: ${errMessage.join(', ')}.`}
													</span>
												</Form.Group>
											</Form.Row>
										) : (
											<Fragment>
												{status && (
													<Form.Row>
														<Form.Group as={Col} className="text-center">
															<span className={status.success ? 'text-success' : 'text-danger'}>
																{status.message}
															</span>
														</Form.Group>
													</Form.Row>
												)}
											</Fragment>
										)}

										<Button type="submit" className="btn-copy" disabled={isSubmitting}>
											{isSubmitting ? (
												<Spinner
													as="span"
													animation="border"
													size="sm"
													role="status"
													aria-hidden="true"
												/>
											) : (
												'SAVE'
											)}
										</Button>
									</Form>
								);
							}}
						</Formik>
					</Box>
				</Col>

				<Col lg={6} md={12} sm={12}>
					<Box className="box-db edit-profile">
						<Formik
							initialValues={{
								currentPassword: '',
								newPassword: '',
								confirmPassword: '',
							}}
							// validationSchema={passwordSchema}
							onSubmit={onResetPassword}
						>
							{({ status, errors, touched, values, handleSubmit, handleChange, isSubmitting }) => {
								const errMessage = [];
								if (Object.keys(errors).length > 0 && Object.keys(touched).length > 0) {
									Object.values(errors).forEach((v) => {
										errMessage.push(v);
									});
								}

								return (
									<Form onSubmit={handleSubmit}>
										<p>Reset Password</p>
										{/* <Form.Row>
											<Form.Group as={Col} controlId="formCurrent" className="edit-inp">
												<Form.Control
													className="input-signup"
													name="currentPassword"
													value={values.currentPassword}
													onChange={handleChange}
													type="password"
													placeholder="Enter your current password"
													style={{ color: '#000000' }}
												/>
											</Form.Group>
										</Form.Row>

										<Form.Row>
											<Form.Group as={Col} controlId="formNew" className="edit-inp">
												<Form.Control
													className="input-signup"
													name="newPassword"
													value={values.newPassword}
													onChange={handleChange}
													type="password"
													placeholder="Enter your new password"
													style={{ color: '#000000' }}
												/>
											</Form.Group>
										</Form.Row>

										<Form.Row>
											<Form.Group as={Col} controlId="formConfirm" className="edit-inp">
												<Form.Control
													className="input-signup"
													name="confirmPassword"
													value={values.confirmPassword}
													onChange={handleChange}
													type="password"
													placeholder="Confirm new password"
													style={{ color: '#000000' }}
												/>
											</Form.Group>
										</Form.Row>

										{errMessage.length > 0 ? (
											<Form.Row>
												<Form.Group as={Col} className="form-errors">
													<span className="text-danger">
														{`Error/s: ${errMessage.join(', ')}.`}
													</span>
												</Form.Group>
											</Form.Row>
										) : (
											<Fragment>
												{status && (
													<Form.Row>
														<Form.Group as={Col} className="text-center">
															<span className={status.success ? 'text-success' : 'text-danger'}>
																{status.message}
															</span>
														</Form.Group>
													</Form.Row>
												)}
											</Fragment>
										)} */}

										<Button type="submit" className="btn-copy" disabled={isSubmitting}>
											{isSubmitting ? (
												<Spinner
													as="span"
													animation="border"
													size="sm"
													role="status"
													aria-hidden="true"
												/>
											) : (
												'Reset Password'
											)}
										</Button>
										<Fragment>
											{status && (
												<Form.Row>
													<Form.Group as={Col} className="text-center">
														<span className={status.success ? 'text-success' : 'text-danger'}>
															{status.message}
														</span>
													</Form.Group>
												</Form.Row>
											)}
										</Fragment>
									</Form>
								);
							}}
						</Formik>
					</Box>
				</Col>
			</Row>
		</div>
	);
};

export default withDashboardApp(EditProfile);
